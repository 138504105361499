import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'shared/sidebar';
import NewTab from './newTab';

import './styles/filterPanelContainer.scss';
import { getTitle } from 'state/tabs/tab/title';
import {
  getIsLeftPanelShown,
  getIsNewTabShown,
  getIsResultPanelShown,
} from '../../state/ui';
import { setIsLeftPanelShown, setIsNewTabShown } from '../../state/ui/reducer';
import classnames from 'classnames';
import formViewsPlugin from '../../core/formViewsPlugin';
import { getFields, setFields } from '../../state/tabs/tab/fields/reducer';
import { getConfig } from '../../state/config';
import OutsideClickHandler from '../shared/outsideClickHandler';
import { set as setNotification } from 'state/notification/actions';
import { getTabSettings, setPreset } from '../../state/tabs/tab/preset/reducer';
import { getID } from '../../state/tabs/tab/id';
import { setResultState } from '../../state/tabs/tab/result/state/reducer';
import FormViewErrorHandler from './FormViewErrorHandler';
import refClient from '../../core/refClient';
import { fetchData } from '../../state/tabs/tab/response/actions';
import { SearchContext } from '../shared/searchUtils/searchContext';
import InputsSearch from './InputsSearch';
import {
  getTabParamsMapping,
  setTabParamsMapping,
} from '../../state/tabs/tab/paramsMapping/reducer';
import ParamsMapping from './ParamsMapping';

export class FilterPanelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
    };

    this.setSearch = this.setSearch.bind(this);
  }

  hideNewTab = () => {
    this.props.setIsNewTabShown(false);
  };

  toggleVisibility = () => {
    this.props.setIsLeftPanelShown(!this.props.isLeftPanelShown);
  };

  setSearch(value) {
    this.setState({ search: value ? value.toLowerCase() : value });
  }

  render() {
    const {
      title,
      isNewTabShown,
      isLeftPanelShown,
      fields,
      setFields,
      config,
      setNotification,
      settings,
      tabId,
      setResultState,
      request,
      setPreset,
      paramsMapping,
      setTabParamsMapping,
      isResultPanelShown,
    } = this.props;
    const { search } = this.state;

    const sidebarClasses = classnames('rf-filter-panel', {
      'rf-filter-panel_shown': isLeftPanelShown,
    });

    const inputConfigs = config[title].inputs;
    const inputs = inputConfigs.map((input, index) => {
      const { type, options } = input;
      const key = `${type}${tabId}${index}`;
      try {
        const { Component } = formViewsPlugin.get(type);

        return (
          <FormViewErrorHandler key={key} inputConfig={input}>
            <Component
              fields={fields}
              setFields={setFields}
              options={options}
              setNotification={setNotification}
              settings={settings}
              setResultState={setResultState}
              refClient={refClient}
              request={request}
              setPreset={setPreset}
            />
          </FormViewErrorHandler>
        );
      } catch (e) {
        return <FormViewErrorHandler key={key} inputConfig={input} error={e} />;
      }
    });    
    
    const btnClasses = classnames(
      'rf-filter-panel__toggler', {
        'rf-filter-panel_fadeout': !isLeftPanelShown,
      }
    );
    const el = (
      <>
        <div className={btnClasses} onClick={this.toggleVisibility} />
        {!paramsMapping && (
          <ParamsMapping
            paramsMapping={paramsMapping}
            setTabParamsMapping={setTabParamsMapping}
            isResultPanelShown={isResultPanelShown}
          />
        )}
        <Sidebar cssClasses={sidebarClasses}>
          {isNewTabShown ? (
            <NewTab />
          ) : (
            <SearchContext.Provider value={{ search }}>
              <InputsSearch setSearch={this.setSearch} />
              <div style={{ position: 'relative' }}>{inputs}</div>
            </SearchContext.Provider>
          )}
          {/* <Toggler
            rightArrow={!isLeftPanelShown}
            toggle={this.toggleVisibility}
          /> */}
        </Sidebar>
      </>
    );

    if (isNewTabShown) {
      return (
        <OutsideClickHandler
          outsideClick={this.hideNewTab}
          cssClasses="rf-filter-panel-wrapper"
        >
          {el}
        </OutsideClickHandler>
      );
    }

    return el;
  }
}

FilterPanelContainer.propTypes = {
  title: PropTypes.string.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  isLeftPanelShown: PropTypes.bool.isRequired,
  setIsLeftPanelShown: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setIsNewTabShown: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  tabId: PropTypes.number.isRequired,
  setResultState: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  setPreset: PropTypes.func.isRequired,
  setTabParamsMapping: PropTypes.func.isRequired,
  paramsMapping: PropTypes.bool.isRequired,
  isResultPanelShown: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    title: getTitle(state),
    tabId: getID(state),
    isNewTabShown: getIsNewTabShown(state),
    isLeftPanelShown: getIsLeftPanelShown(state),
    fields: getFields(state),
    config: getConfig(state),
    settings: getTabSettings(state),
    paramsMapping: getTabParamsMapping(state),
    isResultPanelShown: getIsResultPanelShown(state),
  };
}

const mapDispatchToProps = {
  setIsLeftPanelShown,
  setFields,
  setIsNewTabShown,
  setNotification,
  setResultState,
  request: fetchData,
  setPreset,
  setTabParamsMapping,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPanelContainer);
