import PropTypes from 'prop-types';
import React from 'react';
import utils from 'utils';
import { ExpandFormRow, FormRow, Input, Select } from '@here/ref-client-ui';
import MultiplePoints from 'shared/multiplePoints';
import BoundingBox from './BoundingBox';
import './regionDefinition.scss';
import { isCoordValidAndDefined } from '../../../utils/validation/coordsValidation';
import get from 'lodash/get';
import { getOpenapiUrl } from '../openapiHelpers';
import yamlLoad from '../checkboxes/yamlLoad';

const initState = {
  polygon: {
    outer: [],
  },
  autoCircle: {
    margin: 10000,
    maxRadius: null,
  },
};

const OPENAPI_PATHS = {
  description: 'components.schemas.RegionDefinition.description',
  world: 'components.schemas.World.description',
  autoCircle: 'components.schemas.AutoCircle.description',
  circle: 'components.schemas.Circle.description',
  boundingBox: 'components.schemas.BoundingBox.description',
  polygon: 'components.schemas.Polygon.description',
};

class RegionDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapi: null,
    };
  }
  componentDidMount() {
    const propsUrl = get(this.props, 'settings.url');
    const url = getOpenapiUrl(propsUrl, /.*\/v8\//, 'openapi');
    yamlLoad(url).then((openapi) => {
      if (!this.isUnmounted) {
        this.setState({ openapi });
      }
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeRegionType = (e) => {
    const {
      options: { key },
      setFields,
      fields: { profile },
      setNotification,
    } = this.props;
    const type = utils.extractData(e);
    const regionDefinition = { type, ...(initState[type] || {}) };
    const newFields = { [key]: regionDefinition };
    if (type !== 'world' && profile !== '') {
      newFields.profile = '';
      setNotification({
        message: 'Profile has been unset.',
        autoDismiss: 5,
      });
    }
    setFields(newFields);
  };

  onChangeCoord = (paramKey, e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const regionDefinition = fields[key] || {};
    const [lat, lng] = e.target.value.split(',').map(Number);
    setFields({
      regionDefinition: {
        ...regionDefinition,
        [paramKey]: { lat, lng },
      },
    });
  };

  onChangeBbox = (bounds) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      regionDefinition: {
        ...fields[key],
        boundingBox: bounds,
      },
    });
  };

  onChangePolygon = (outer) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      regionDefinition: { ...fields[key], outer },
    });
  };

  onChangeMargin = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      regionDefinition: { ...fields[key], margin: +e.target.value },
    });
  };

  onChangeRadius = ({ target: { value } }) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const radius = isFinite(+value) > 0 ? +value : value;
    setFields({
      regionDefinition: { ...fields[key], radius },
    });
  };

  onChangeMaxRadius = ({ target: { value } }) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const maxRadius = isFinite(+value) > 0 ? +value : value;
    setFields({
      regionDefinition: { ...fields[key], maxRadius },
    });
  };

  getRegionTypeFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { type } = fields[key] || {};
    switch (type) {
      case 'circle':
        return this.getCircleFields();
      case 'autoCircle':
        return this.getAutoCircleFields();
      case 'boundingBox':
        return this.getBoundingBoxFields();
      case 'polygon':
        return this.getPolygonFields();
      default:
        return null;
    }
  };

  getCircleFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { center = {}, radius = 0 } = fields[key] || {};
    const value = `${center.lat},${center.lng}`;
    return (
      <div>
        <FormRow>
          <Input
            label="Center"
            value={value}
            onBlur={this.onChangeCoord.bind(this, 'center')}
            isValid={isCoordValidAndDefined(value)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="Radius"
            value={radius}
            onBlur={this.onChangeRadius}
            isValid={isFinite(radius) && radius > 0}
            blurOnEnter
          />
        </FormRow>
      </div>
    );
  };

  getAutoCircleFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const regionDefinition = fields[key] || {};
    return (
      <>
        <FormRow>
          <Input
            label="Margin"
            value={regionDefinition.margin}
            onChange={this.onChangeMargin}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Max Radius"
            placeholder="Max radius"
            value={regionDefinition.maxRadius || ''}
            onChange={this.onChangeMaxRadius}
          />
        </FormRow>
      </>
    );
  };

  getBoundingBoxFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const bbox = fields[key] || {};
    let res = bbox.boundingBox;
    if (!res) {
      const {north, south, east, west} = bbox;
      res = [[north, east],[south, west]];
    }
    return (
      <BoundingBox
        bounds={res}
        onChange={this.onChangeBbox}
      />
    );
  };

  getPolygonFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const regionDefinition = fields[key] || {};
    return (
      <MultiplePoints
        label="Outer"
        points={regionDefinition.outer || []}
        onChange={this.onChangePolygon}
      />
    );
  };

  getTooltip = (key) => {
    const { openapi } = this.state;
    const tooltip = get(openapi, OPENAPI_PATHS[key]);
    if (tooltip) {
      return (
        <div className="rf-form-view__tooltip">
          {key} - {tooltip}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      options: { label, key, regionTypes },
      fields,
    } = this.props;
    const regionDefinition = fields[key] || {};
    const regionType = regionDefinition.type;
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltip={this.getTooltip('description')}
        tooltipPlacement="right"
        className="rf-region-definition rf-grey-box"
      >
        <FormRow>
          <Select
            label="Type"
            options={regionTypes}
            value={regionType}
            onChange={this.onChangeRegionType}
            tooltip={this.getTooltip(regionType)}
          />
        </FormRow>
        {this.getRegionTypeFields()}
      </ExpandFormRow>
    );
  }
}

RegionDefinition.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options, postData }) => {
    const { key } = options;
    if (postData) {
      return { [key]: postData[key] };
    }

    return { [key]: options.initValue };
  },

  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),

  getRequestOptions: (fields, options) => {
    const { key } = options;
    const regionDefinition = fields[key] || {};
    let res = {};
    if (regionDefinition.type === 'boundingBox') {
      if (regionDefinition.hasOwnProperty('boundingBox')) {
        const { boundingBox: [topRight = [], bottomLeft = []] = [] } =
          regionDefinition;
        res = {
          type: 'boundingBox',
          north: +topRight[0],
          south: +bottomLeft[0],
          east: +topRight[1],
          west: +bottomLeft[1],
        };
      } else {
        res = JSON.parse(JSON.stringify(regionDefinition));
      }
    } else {
      Object.keys(regionDefinition).forEach((key) => {
        if (regionDefinition[key]) {
          res[key] = regionDefinition[key];
        }
      });
    }
    return {
      method: 'post',
      data: { [key]: res },
    };
  },
  Component: RegionDefinition,
};
