import React from 'react';
import { Textarea, Modal } from '@here/ref-client-ui';
import './styles/configsModal.scss';
import PropTypes from 'prop-types';

class ConfigsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configs: JSON.stringify(props.config, null, 2),
    };
  }

  onSave = () => {
    const { onSave, close } = this.props;
    const { configs } = this.state;
    const configsObj = JSON.parse(configs);

    onSave(configsObj);
    close();
  };

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  onConfigsChange = (e) => {
    this.setState({ configs: e.target.value });
  };

  render() {
    const { isOpen, close } = this.props;
    const { configs } = this.state;

    return (
      <Modal
        isShown={isOpen}
        title="Edit configs"
        className="rf-configs-modal"
        appendToBody
        onMouseDown={this.stopPropagation}
      >
        <Textarea
          label="Configs"
          className="rf-configs-modal__textarea"
          value={configs}
          onChange={this.onConfigsChange}
        />
        <div className="rf-configs-modal__actions">
          <lui-button onClick={close} secondary>
            Cancel
          </lui-button>
          <lui-button onClick={this.onSave} secondary>
            Save
          </lui-button>
        </div>
      </Modal>
    );
  }
}

ConfigsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ConfigsModal;
