import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tooltip } from '@here/ref-client-ui';

import './styles/radioOption.scss';

class RadioOption extends Component {
  onChange = () => {
    const { value, onChange } = this.props;
    onChange(value);
  };

  getLabelEl() {
    let { tooltip, label } = this.props;
    return tooltip ? (
      <Tooltip tooltip={tooltip}>
        <span>{label}</span>
      </Tooltip>
    ) : (
      <span>{label}</span>
    );
  }

  render() {
    let { isChecked, value, disabled, name, ...radioProps } = this.props;

    if (disabled) {
      radioProps.disabled = true;
    }

    if (isChecked) {
      radioProps.checked = true;
    }

    return (
      <lui-radiobutton
        value={value}
        onClick={this.onChange}
        name={name}
        class={classnames('rf-radiooption', {
          'rf-radiooption_disabled': disabled,
        })}
        {...radioProps}
      >
        {this.getLabelEl()}
      </lui-radiobutton>
    );
  }
}

RadioOption.defaultProps = {
  value: '',
  tooltip: '',
  disabled: false,
};

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltip: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RadioOption;
