import { assignTabDataAndDispatch } from '../../actions';
import { ADD_TAB } from '../../constants';
import { RESTORE_FROM_URL } from '../../../constants';

export const SET = 'fields/SET';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case ADD_TAB:
      return action.payload.fields;
    case SET:
      return {
        ...state,
        ...action.payload,
      };
    case RESTORE_FROM_URL:
      return action.payload.fields;
    default:
      return state;
  }
};

export const setFields = (fields, id) => {
  return assignTabDataAndDispatch({ type: SET, payload: fields }, id);
}

export const getFields = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return JSON.parse(JSON.stringify(state.tabs[selectedTab].fields));
};
