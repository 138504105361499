import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@here/ref-client-ui';
import { getOlsOptions, getOlsWaypoints, mapFan } from './compareUtils';

class CompareMapFanButton extends Component {
  open() {
    const { fields } = this.props;
    const waypoints = getOlsWaypoints(fields);
    if (!waypoints) {
      throw new Error("Can't get waypoints");
    }
    const options = getOlsOptions(fields);
    mapFan.open(waypoints, options);
  }

  render() {
    return <Button onClick={this.open.bind(this)} title="Compare to MapFan" />;
  }
}

CompareMapFanButton.propTypes = {
  fields: PropTypes.object,
};

export default CompareMapFanButton;
