import React from 'react';
import PropTypes from 'prop-types';
import './RMDetails.scss';
import { FormRow, Tree, Checkbox } from '@here/ref-client-ui';
import Slider from 'rc-slider';
import { isEqual, get } from 'lodash';

class RMDetails extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(this.props.result.state.filterLinks, nextProps.result.state.filterLinks) ||
      !isEqual(this.props.result.state.matchConfidence, nextProps.result.state.matchConfidence) ||
      !isEqual(this.props.result.state.selectedLine, nextProps.result.state.selectedLine) ||
      !isEqual(this.props.result.raw, nextProps.result.raw)
    ) {
      return true;
    }
    return false;
  }

  toggleInput = (key, evt) => {
    const {
      setResultState
    } = this.props;

    setResultState({ [key]: evt.target.checked });
  };

  onSetConfidence = (value) => {
    const {
      setResultState
    } = this.props;

    setResultState({ matchConfidence: value });
  };

  onWarningClick = (value, route) => {
    const {
      setResultState,
      result: { state: { selectedWarning } }
    } = this.props;
    if (value < 0 || (selectedWarning && selectedWarning.tracePointSeqNum === value)) {
      setResultState({selectedWarning : null});
    } else {
      const tracePoints = route[0].waypoint;
      const { mappedPosition } = tracePoints[value];
      const { latitude: lat, longitude: lng } = mappedPosition;
      setResultState({selectedWarning: { tracePointSeqNum: value, position: [{lat, lng}] }});
    }
  }

  render() {
    const {
      result,
      options: { pathKey }
    } = this.props;
    
    const {
      inputTracePoints = true,
      matchedPointsnroute = true,
      filterLinks = false,
      matchConfidence = 0,
    } = result.state;
    
    let { warnings, route } = get(result, pathKey, { warnings: [] });
    warnings = warnings.map(warning => {
      const { message, tracePointSeqNum } = warning
      return {
        message: `${tracePointSeqNum}::${message}`
      }
    });
    const { selectedLine } = result.state;
    return <lui-default-theme>
      <FormRow>
        <Checkbox
          label="Input trace points"
          onChange={this.toggleInput.bind(this, 'inputTracePoints')}
          isChecked={inputTracePoints}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          label="Matched Points and route"
          onChange={this.toggleInput.bind(this, 'matchedPointsnroute')}
          isChecked={matchedPointsnroute}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          label="Filter links by confidence"
          onChange={this.toggleInput.bind(this, 'filterLinks')}
          isChecked={filterLinks}
        />
      </FormRow>
      {filterLinks && <FormRow>
        <h4>Filter links by confidence: {matchConfidence}</h4>
        <h4>(Green is higher confidence, red is lower confidence)</h4>
        <div className="rf-route-matching__slider">
          <Slider
            value={matchConfidence}
            onChange={this.onSetConfidence}
            onAfterChange={this.onSetConfidence}
            min={0.0}
            step={0.05}
            max={0.90}
            dots
          />
        </div>
      </FormRow>}
      <FormRow>
        <h4>Route Matching Warnings</h4>
              <Tree 
                data={{warnings}}
                valueRenderer={
                  (raw, value, key) => {
                    const [tracePointSeqNum, message] = value.split('::');
                    return <span className="rf-warning-message" onClick={this.onWarningClick.bind(this, tracePointSeqNum, route)}>{message}</span>;
                  }
                }
              />
      </FormRow>
      {selectedLine && <FormRow>
        <h4>Selected Link information</h4>
        <Tree 
          data={selectedLine} 
        />
      </FormRow>}
    </lui-default-theme>
  }
}

RMDetails.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default RMDetails;
