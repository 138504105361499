export const getCenterFromPolyline = (positions) => {
  const geometry = new window.H.geo.LineString();
  positions.forEach(({ lat, lng }) => {
    geometry.pushLatLngAlt(lat, lng);
  });
  return geometry.getBoundingBox().getCenter();
};
export const getBBoxFromPolyline = (positions) => {
  const geometry = new window.H.geo.LineString();
  positions.forEach(({ lat, lng }) => {
    geometry.pushLatLngAlt(lat, lng);
  });
  return geometry.getBoundingBox();
};