import React from 'react';
import { FormRow, Input, Select } from '@here/ref-client-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles/appSettings.scss';
import { getAppSettings } from '../../state/appSettings';
import { setSettings } from '../../state/appSettings/actions';
import DEFAULT_APP_SETTINGS from 'state/appSettings/defaultState';
import { LANGUAGES } from './constants';
import ToggleButton from 'shared/toggleButton';

class AppSettings extends React.Component {
  onChange = ({ target: { value } }) => {
    const { setSettings } = this.props;
    setSettings({
      searchApiKey: value === window.APIKEY ? 'DEFAULT_API_KEY' : value,
    });
  };

  onChangeLang({ target: { value } }) {
    const { setSettings } = this.props;
    setSettings({ searchLang: value });
  }

  onChangeBboxSearch = ({ target: { checked } }) => {
    const { setSettings } = this.props;
    setSettings({ isBboxSearch: checked });
  };

  resetSearchCredentials = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    const { setSettings } = this.props;
    setSettings({
      searchApiKey: DEFAULT_APP_SETTINGS.searchApiKey,
      searchLang: DEFAULT_APP_SETTINGS.searchLang,
      isBboxSearch: DEFAULT_APP_SETTINGS.isBboxSearch,
    });
  };

  render() {
    const {
      appSettings: { searchApiKey, searchLang, isBboxSearch },
      close,
    } = this.props;
    const canResetSearch =
      searchApiKey !== DEFAULT_APP_SETTINGS.searchApiKey ||
      searchLang !== DEFAULT_APP_SETTINGS.searchLang ||
      isBboxSearch;

    return (
      <div className="rf-settings-form__app-settings">
        <h3>Search credentials</h3>
        <FormRow>
          <Input
            label="API Key"
            value={
              searchApiKey === 'DEFAULT_API_KEY' ? window.APIKEY : searchApiKey
            }
            onBlur={this.onChange}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Select
            label="Search language"
            options={LANGUAGES}
            value={searchLang || ''}
            onChange={this.onChangeLang.bind(this)}
            addEmptyOption
            notSelectedLabel="- Not selected -"
          />
        </FormRow>
        <FormRow>
          <ToggleButton
            checked={!!isBboxSearch}
            onChange={this.onChangeBboxSearch}
            label="Search by bounding box:"
          />
        </FormRow>
        {canResetSearch && (
          <FormRow>
            <div className="rf-settings-form__warning">
              <lui-button secondary onClick={this.resetSearchCredentials}>
                Reset Settings
              </lui-button>
            </div>
          </FormRow>
        )}

        <div className="rf-settings-form__action-btn-group">
          <lui-button type="cancel" secondary onClick={close}>
            Close
          </lui-button>
        </div>
      </div>
    );
  }
}

AppSettings.propTypes = {
  appSettings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  appSettings: getAppSettings(state),
});

const mapDispatchToProps = {
  setSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSettings);
