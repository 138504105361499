import { USE_CIT } from '../state/map/tiles/constants';

const tiles = {
  type: [
    {
      label: 'Base',
      value: 'maptile',
      serviceType: 'base',
    },
    {
      label: 'Traffic',
      value: 'traffictile',
      serviceType: 'traffic',
    },
  ],
  useCIT: [
    { label: 'Auto', value: USE_CIT.AUTO },
    { label: 'Yes', value: `${USE_CIT.YES}` },
    { label: 'No', value: `${USE_CIT.NO}` },
  ],
  scheme: [
    {
      label: 'normal.day',
      value: 'normal.day',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.grey',
      value: 'normal.day.grey',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.transit',
      value: 'normal.day.transit',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night.transit',
      value: 'normal.night.transit',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.traffic.day',
      value: 'normal.traffic.day',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.traffic.night',
      value: 'normal.traffic.night',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.custom',
      value: 'normal.day.custom',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night',
      value: 'normal.night',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night.grey',
      value: 'normal.night.grey',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'pedestrian.day',
      value: 'pedestrian.day',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'pedestrian.day.mobile',
      value: 'pedestrian.day.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'pedestrian.night',
      value: 'pedestrian.night',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'pedestrian.night.mobile',
      value: 'pedestrian.night.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'carnav.day.grey',
      value: 'carnav.day.grey',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.mobile',
      value: 'normal.day.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.grey.mobile',
      value: 'normal.day.grey.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.day.transit.mobile',
      value: 'normal.day.transit.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night.transit.mobile',
      value: 'normal.night.transit.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night.mobile',
      value: 'normal.night.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'normal.night.grey.mobile',
      value: 'normal.night.grey.mobile',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'reduced.day',
      value: 'reduced.day',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'reduced.night',
      value: 'reduced.night',
      supportedTypes: ['traffictile', 'maptile'],
    },
    {
      label: 'terrain.day',
      value: 'terrain.day',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'satellite.day',
      value: 'satellite.day',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'hybrid.day',
      value: 'hybrid.day',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'hybrid.day.transit',
      value: 'hybrid.day.transit',
      supportedTypes: [],
    },
    {
      label: 'hybrid.grey.day',
      value: 'hybrid.grey.day',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'hybrid.traffic.day',
      value: 'hybrid.traffic.day',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'terrain.day.mobile',
      value: 'terrain.day.mobile',
      supportedTypes: ['traffictile'],
    },
    {
      label: 'hybrid.day.mobile',
      value: 'hybrid.day.mobile',
      supportedTypes: ['traffictile'],
    },
  ],
  size: [
    { label: '256 × 256', value: '256' },
    { label: '512 × 512', value: '512' },
  ],
  format: [
    { label: 'png', value: 'png' },
    { label: 'png8', value: 'png8' },
    { label: 'jpg', value: 'jpg' },
  ],
  languages: [
    { value: 'ara', label: 'Arabic' },
    { value: 'baq', label: 'Basque' },
    { value: 'cat', label: 'Catalan' },
    { value: 'chi', label: 'Chinese (simplified)' },
    { value: 'cht', label: 'Chinese (traditional)' },
    { value: 'cze', label: 'Czech' },
    { value: 'dan', label: 'Danish' },
    { value: 'dut', label: 'Dutch' },
    { value: 'eng', label: 'English' },
    { value: 'fin', label: 'Finnish' },
    { value: 'fre', label: 'French' },
    { value: 'ger', label: 'German' },
    { value: 'gle', label: 'Gaelic' },
    { value: 'gre', label: 'Greek' },
    { value: 'heb', label: 'Hebrew' },
    { value: 'hin', label: 'Hindi' },
    { value: 'ind', label: 'Indonesian' },
    { value: 'ita', label: 'Italian' },
    { value: 'mul', label: 'Multiple Languages' },
    { value: 'nor', label: 'Norwegian' },
    { value: 'per', label: 'Persian' },
    { value: 'pol', label: 'Polish' },
    { value: 'por', label: 'Portuguese' },
    { value: 'rus', label: 'Russian' },
    { value: 'sin', label: 'Sinhalese' },
    { value: 'spa', label: 'Spanish' },
    { value: 'swe', label: 'Swedish' },
    { value: 'tha', label: 'Thai' },
    { value: 'tur', label: 'Turkish' },
    { value: 'ukr', label: 'Ukrainian' },
    { value: 'urd', label: 'Urdu' },
    { value: 'vie', label: 'Vietnamese' },
    { value: 'wel', label: 'Welsh' },
  ],
  politicalView: [
    { value: '-none-', label: '-none-' },
    { value: 'ARE', label: 'United Arab Emirates' },
    { value: 'ARG', label: 'Argentina' },
    { value: 'BHR', label: 'Bahrain' },
    { value: 'DEF', label: 'Default' },
    { value: 'EGY', label: 'Egypt' },
    { value: 'IND', label: 'India' },
    { value: 'KWT', label: 'Kuwait' },
    { value: 'MAR', label: 'Morocco' },
    { value: 'OMN', label: 'Oman' },
    { value: 'PAK', label: 'Pakistan' },
    { value: 'QAT', label: 'Qatar' },
    { value: 'RUS', label: 'Russia' },
    { value: 'SAU', label: 'Saudi Arabia' },
    { value: 'SRB', label: 'Serbia' },
    { value: 'TUR', label: 'Turkey' },
    { value: 'VNM', label: 'Vietnam' },
  ],
};

export default tiles;
