import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@here/ref-client-ui';

import './transportTypeIcon.scss';

export class TransportTypeIcon extends Component {
  getIconEl() {
    let baseClass = 'rf-transport-type-icon';

    return (
      <div
        className={`${baseClass} ${baseClass}_${this.props.transportType}`}
      ></div>
    );
  }

  render() {
    let { tooltip } = this.props;

    return tooltip ? (
      <Tooltip tooltip={tooltip} placement="top">
        {this.getIconEl()}
      </Tooltip>
    ) : (
      this.getIconEl()
    );
  }
}

TransportTypeIcon.defaultProps = {
  tooltip: '',
  transportType: 'notype',
};

TransportTypeIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  transportType: PropTypes.string.isRequired,
};

export default TransportTypeIcon;
