import { setDefaultMapCenter } from '../../../views/map/defaultMapCenterLocalStorage';

function callback(e, setNotification) {
  setDefaultMapCenter(`${e.latLng.lat},${e.latLng.lng}`);
  setNotification({
    message: 'Default map center was updated',
    impact: 'positive',
    autoDismiss: 2,
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setNotification }) {
  return {
    text: options.text,
    callback: (e) => callback(e, setNotification),
  };
}
