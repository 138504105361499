import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ColorPickerModal from './ColorPickerModal';

export default class TabActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownShown: false,
      dropdownStyles: { top: 0, left: 0 },
      isColorModalShown: false,
    };
    this.actionsIconRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  onAction(action, e) {
    e.stopPropagation();
    this.hideDropdown(e, true);
    action(e);
  }

  onSetTabColor = (e) => {
    const { onSetTabColor } = this.props;
    let index;

    onSetTabColor(index, e);
    this.closeColorModal();
  };

  getColorModal = () => {
    const { isColorModalShown } = this.state;
    const { colorPalette } = this.props;
    if (!isColorModalShown) {
      return null;
    }

    return (
      <ColorPickerModal
        colorPalette={colorPalette}
        onCancel={this.closeColorModal}
        onChange={this.onSetTabColor}
      />
    );
  };

  openColorModal = () => {
    this.setState({ isColorModalShown: true });
  };

  closeColorModal = () => {
    this.setState({ isColorModalShown: false });
  };

  getDropdownEl() {
    const { onEdit, onDuplicate } = this.props;

    if (this.state.isDropdownShown) {
      return (
        <div
          className="rf-nav-tabs__tab__caption__actions__dropdown"
          style={this.state.dropdownStyles}
        >
          <div
            className="rf-nav-tabs__tab__caption__actions__dropdown__item"
            onClick={this.onAction.bind(this, onEdit)}
          >
            Edit Title
          </div>
          <div
            className="rf-nav-tabs__tab__caption__actions__dropdown__item"
            onClick={this.onAction.bind(this, onDuplicate)}
          >
            Duplicate
          </div>
          <div
            className="rf-nav-tabs__tab__caption__actions__dropdown__item"
            onClick={this.onAction.bind(this, this.openColorModal)}
          >
            Set Tab Color
          </div>
        </div>
      );
    }
    return null;
  }

  toggleDropdown = (e) => {
    e.stopPropagation();
    if (this.state.isDropdownShown) {
      this.hideDropdown(e, true);
    } else {
      const actionsIconRect =
        this.actionsIconRef.current.getBoundingClientRect();
      this.setState({
        isDropdownShown: true,
        dropdownStyles: {
          top: actionsIconRect.top + 20,
          left: actionsIconRect.left,
        },
      });
      window.addEventListener('mousedown', this.hideDropdown, false);
    }
  };

  hideDropdown = (e, force) => {
    if (!force && this.dropdownRef.current.contains(e.target)) {
      e.stopPropagation();
      return;
    }
    this.setState({ isDropdownShown: false });
    window.removeEventListener('mousedown', this.hideDropdown, false);
  };

  render() {
    const { styles } = this.props;
    const classes = classnames('rf-nav-tabs__tab__caption__actions', {
      'rf-nav-tabs__tab__caption__actions_active': this.state.isDropdownShown,
    });

    return (
      <div className={classes} ref={this.dropdownRef}>
        <i
          className="fa fa-ellipsis-v"
          onClick={(e) => e.stopPropagation()}
          onMouseDown={this.toggleDropdown}
          style={styles}
          ref={this.actionsIconRef}
        />
        {this.getDropdownEl()}
        {this.getColorModal()}
      </div>
    );
  }
}

TabActions.propTypes = {
  styles: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onSetTabColor: PropTypes.func.isRequired,
  colorPalette: PropTypes.object.isRequired,
};
