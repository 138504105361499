import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Textarea, Input, Modal } from '@here/ref-client-ui';
import './resultModal.scss';

class ResultModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      url: props.url || '',
      origins: props.origins || '',
      destinations: props.destinations || '',
    };
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeOrigins = (e) => {
    this.setState({ origins: e.target.value });
  };

  onChangeDestinations = (e) => {
    this.setState({ destinations: e.target.value });
  };

  onChangeUrl = (e) => {
    const { value } = e.target;
    const state = { url: value };
    this.setState((prevState) => {
      if (!prevState.name) {
        const urlParts = value.split('/');
        state.name = urlParts[urlParts.length - 2];
      }

      return state;
    });
  };

  onSave = () => {
    const { onChange, onClose } = this.props;
    const { name, url, origins, destinations } = this.state;
    onChange({ matrixResultUrl: url, name, origins, destinations });
    onClose();
  };

  render() {
    const { isShown, title, onClose } = this.props;
    const { name, url, origins, destinations } = this.state;
    return (
      <Modal
        appendToBody
        isShown={isShown}
        title={title}
        className="rf-result-storage-modal"
      >
        <FormRow>
          <Input label="Name" value={name} onChange={this.onChangeName} />
        </FormRow>
        <FormRow>
          <Input label="Url" value={url} onChange={this.onChangeUrl} />
        </FormRow>
        <FormRow>
          <Textarea
            label="Origins"
            value={origins}
            onChange={this.onChangeOrigins}
          />
        </FormRow>
        <FormRow>
          <Textarea
            label="Destinations"
            value={destinations}
            onChange={this.onChangeDestinations}
          />
        </FormRow>
        <div className="rf-result-storage-modal__action-btns">
          <lui-button onClick={onClose} secondary>
            Cancel
          </lui-button>
          <lui-button onClick={this.onSave} secondary>
            Save
          </lui-button>
        </div>
      </Modal>
    );
  }
}

ResultModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
  origins: PropTypes.string,
  destinations: PropTypes.string,
};

export default ResultModal;
