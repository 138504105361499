import get from 'lodash/get';
import axios from 'axios';

const avoidSegmentContextMenu = ({ fields, setFields, settings }) => ({
  text: 'Add avoid segment',
  callback: (e) => {
    const url = 'https://router.hereapi.com/v8/routes';
    const { lat, lng } = e.latLng;
    const latLngStr = `${lat},${lng}`;
    const params = {
      apikey: settings.apikey || window.APIKEY,
      origin: latLngStr,
      destination: latLngStr,
      return: 'polyline',
      spans: 'segmentId',
      transportMode: 'car',
    };
    axios.get(url, { params }).then((res) => {
      const segmentId = get(
        res,
        'data.routes[0].sections[0].spans[0].topologySegmentId',
        ''
      ).replace(/\+|-/, '');
      const avoidSegments = fields['avoid[segments]'];
      if (avoidSegments) {
        setFields({ 'avoid[segments]': `${avoidSegments},${segmentId}` });
      } else {
        setFields({ 'avoid[segments]': segmentId });
      }
    });
  },
});

export default avoidSegmentContextMenu;
