export const TimeDomainParser = function() {
    var mainBracket = null;

    function padStart(str, targetLength,padString) {
        targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (str.length > targetLength) {
            return str;
        }
        else {
            targetLength = targetLength-str.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0,targetLength) + str;
        }
    };

    function getMonth(month) {
        if (month === '01' || month === 1) {
            return 'January';
        } else if (month === '02' || month === 2) {
            return 'February';
        } else if (month === '03' || month === 3) {
            return 'March';
        } else if (month === '04' || month === 4) {
            return 'April';
        } else if (month === '05' || month === 5) {
            return 'May';
        } else if (month === '06' || month === 6) {
            return 'June';
        } else if (month === '07' || month === 7) {
            return 'July';
        } else if (month === '08' || month === 8) {
            return 'August';
        } else if (month === '09' || month === 9) {
            return 'September';
        } else if (month === '10' || month === 10) {
            return 'October';
        } else if (month === '11' || month === 11) {
            return 'November';
        } else if (month === '12' || month === 12) {
            return 'December';
        } 
    }

    function getWeekDay(weekday) {
        if (weekday === '1' || weekday === 1) {
            return 'Sunday';
        } else if (weekday === '2' || weekday === 2) {
            return 'Monday';
        } else if (weekday === '3' || weekday === 3) {
            return 'Tuesday';
        } else if (weekday === '4' || weekday === 4) {
            return 'Wednesday';
        } else if (weekday === '5' || weekday === 5) {
            return 'Thursday';
        } else if (weekday === '6' || weekday === 6) {
            return 'Friday';
        } else if (weekday === '7' || weekday === 7) {
            return 'Saturday';
        } else if (weekday === '8' || weekday === 8) {
            return 'Holiday';
        }
    }

    function getOrder(order) {
        if (order === '1' || order === 1) {
            return '1st';
        } else if (order === '2' || order === 2) {
            return '2nd';
        } else if (order === '3' || order === 3) {
            return '3rd';
        } else if (order === '4' || order === 4) {
            return '4th';
        } else if (order === '5' || order === 5) {
            return '5th';
        }
    }

    function getReverseOrder(order) {
        if (order === '1' || order === 1) {
            return 'last';
        } else if (order === '2' || order === 2) {
            return '2nd-to-last';
        } else if (order === '3' || order === 3) {
            return '3rd-to-last';
        } else if (order === '4' || order === 4) {
            return '4th-to-last';
        } else if (order === '5' || order === 5) {
            return '5th-to-last';
        }
    }

    var Bracket = function(isMainStructure) {
        this.startDataCache = null;
        this.durationDataCache = null;

        this.isMainStructure = !!isMainStructure;
        this.startDateTypes = [];
        this.durationDateTypes = [];

        var content = [];
        var parent = null;
        var chars = {
            start: '',
            duration: '',
        };

        function format2Dec(value) {
            // var a = String(value).padStart(2, "0")
            var a = padStart((""+value), 2, "0");
            return a;
        }

        this.setParent = function(parentBracket) {
            parent = parentBracket;
        }
        this.add = function(bracket, join) {
            if(typeof join === 'undefined') join  = null;
            bracket.setParent(this);

            content.push({
                join: join,
                data: bracket,
            });
        }

        this.getParent = function() {
            return parent;
        }

        this.addChar = function(mode, char) {
            chars[mode] += char;
        }

        this.getStartDate = function() {
            var startData = this.getStartData();

            return new Date(
                (null !== startData.year ? startData.year : '1970') + '-' +
                (null !== startData.month ? format2Dec(startData.month) : '01') + '-' +
                (null !== startData.dayofmonth ? format2Dec(startData.dayofmonth) : '01') + ' ' +
                (null !== startData.hour ? format2Dec(startData.hour) : '00') + ':' +
                (null !== startData.minute ? format2Dec(startData.minute) : '00') + ':' +
                (null !== startData.second ? format2Dec(startData.second) : '00') + ''
            );
        }

        this.haveSimpleText = function() {
            var startData = this.getStartData();
            var durationData = this.getDurationData();

            if(
                null !== startData.hour &&
                (null !== durationData.hour || null !== durationData.minute) &&
                !durationData.month && !durationData.dayofmonth
            ) {
                return true;
            }
            if(
                startData.year !== null && startData.month !== null && startData.dayofweek !== null && startData.dayofweek.length === 0 && 
                durationData.dayofmonth !== null &&
                durationData.week === null &&
                durationData.dayofmonth > 1
            ) {
                return true;
            }

            return false;
        }

        this.getSimpleText = function() {
            var startData = this.getStartData();
            var durationData = this.getDurationData();

            // 20:00 Uhr bis 24:00 Uhr
            if(
                null !== startData.hour &&
                (null !== durationData.hour || null !== durationData.minute) &&
                !durationData.month && !durationData.dayofmonth
            ) {
                let startDate = this.getStartDate();
                let interval = (durationData.hour * 3600 + durationData.minute * 60 + durationData.second);
                let endDate = new Date(startDate.getTime() + interval * 1000);

                // if(endDate.getHours() === 0 && endDate.getMinutes() === 0) {
                //     endDate = new Date(endDate.getTime() - 60000);
                // }
                let seconds  = endDate.getSeconds();
                let toTime = format2Dec(endDate.getHours()) + ':' + format2Dec(endDate.getMinutes()) + (seconds?`:${seconds}s`:``);
                if (toTime === '00:00') {
                    toTime = '24:00';
                }

                return this.getStartString() + ' to ' + toTime;
            }

            if(
                startData.year !== null && startData.month !== null && startData.dayofweek !== null && startData.dayofweek.length === 0 &&
                durationData.dayofmonth !== null &&
                durationData.dayofmonth > 1
            ) {
                let startDate = this.getStartDate();
                let interval = (
                    durationData.week * (7 * 86400) +
                    durationData.dayofmonth * 86400 +
                    durationData.hour * 3600 +
                    durationData.minute * 60 +
                    durationData.second
                );
                let endDate = new Date(startDate.getTime() + interval * 1000);
                if(durationData.month !== null) {
                    endDate.setMonth(endDate.getMonth() + durationData.month);
                }
                if(durationData.year !== null) {
                    endDate.setFullYear(endDate.getFullYear() + durationData.year);
                }

                if(endDate.getHours() === 0 && endDate.getMinutes() === 0) {
                    endDate = new Date(endDate.getTime() - 60000);
                }
                // return this.getStartString() + ' to ' + endDate.toLocaleString('de-DE') + '';

                let dateFormat = {
                    day: "2-digit", 
                    month: 'long',
                    year: "numeric",
                    // timeStyle: "medium",
                    // dateStyle: "short",
                    hourCycle: 'h23'
                };
                if (startDate.hour || startData.minute || startData.second || durationData.hour || durationData.minute || durationData.second ) {
                    dateFormat = {
                        day: "2-digit", 
                        month: 'long',
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        // timeStyle: "medium",
                        // dateStyle: "short",
                        hourCycle: 'h23'
                    }
                }
                return this.getStartString() + ' to ' + (endDate.toLocaleString('en-us', dateFormat) + '').replace(/\. /g, '.').replace(/, /g, ' ').replace(/at /g, '');
            }


            return '--ERROR--';
        }

        this.getSingleString = function() {

            if(this.haveSimpleText() === false) {
                var startString = this.getStartString();
                var durationString = this.getDurationString();

                if (durationString.indexOf('ago') > -1) {
                    startString = startString.replace('from', 'until');
                } else if (durationString === '') {
                    startString = startString.replace('from', 'on');
                } else if (durationString === 'ONLY ONE MONTH') {
                    startString = startString.replace('from', 'in');
                    durationString = '';
                } else if (durationString.indexOf('and') === 0 ) {
                    startString = startString.replace('from', 'in') + ' ' + durationString;
                    durationString = '';
                } else if (durationString.indexOf('to') === 0) {
                    startString = startString + ' ' + durationString;
                    durationString = '';
                } else if ( durationString === 'ONLY ONE DAY') {
                    durationString = '';
                } else if ( durationString.indexOf('ANDWEEK') === 0) {
                    startString = startString + ' ' + durationString.replace('ANDWEEK', 'and');
                    durationString = '';
                } else if ( durationString.indexOf('TOWEEK') === 0) {
                    startString = startString + ' ' + durationString.replace('TOWEEK', 'to');
                    durationString = '';
                } else if ( durationString.indexOf('TOMONTHDAY') === 0) {
                    startString = startString + ' ' + durationString.replace('TOMONTHDAY', 'to');
                    durationString = '';
                }

                if (durationString.indexOf('ago') > -1) {
                    return startString + ' from ' + durationString;
                } else {
                    return startString +
                        (
                            durationString !== '' ? ' for ' + durationString : ''
                        );
                }
            } else {
                return this.getSimpleText();
            }

        }

        this.getString = function(splitChar) {
            var texts = [];
            if(content.length > 0) {
                for (var bracketIndex in content) {
                    if(content.hasOwnProperty(bracketIndex)) {
                        var bracket = content[bracketIndex];
                        if (bracket.join === '*') {
                            texts = texts.map((text) => text.replace('\n', ''));
                            texts.push('only applies');
                        } else if (bracket.join === '+') {
                            texts.push('\n');
                        } else if (bracket.join === '-') {
                            texts.push('\n not valid');
                        }

                        let newString = bracket.data.getString('|##|');

                        let splitNewString = newString.replace(/\|##\| \|##\|/g, '|##|').split('|##|');
                        let addNewString = false;

                        // merge duration time when starting times are same
                        for(let stringIndex in texts) {
                            texts[stringIndex] = texts[stringIndex].replace(/\|##\| \|##\|/g, '|##|');
                            let existingStrings = texts[stringIndex].split('|##|');
                            for (let existingStringIndex in existingStrings) {
                                for (let splitNewStringIndex in splitNewString) {
                                    // if starting times are same
                                    const start = splitNewString[splitNewStringIndex];
                                    const duration = splitNewString[parseInt(splitNewStringIndex)+1];
                                    if (existingStrings[existingStringIndex] === start && duration && start !== '') {
                                        // verify start values are all the same
                                        let sameStart = true;
                                        existingStrings.forEach((v, i)=>{
                                            if (i<existingStringIndex && existingStrings[i] !== splitNewString[i]) {
                                                sameStart = false;
                                            }
                                        })

                                        // debugger;
                                        if (sameStart && duration.indexOf('also applies') > -1) {
                                            delete splitNewString[splitNewStringIndex];
                                        } else if (sameStart && duration.indexOf('only applies') > -1) {
                                            const firstDuration = splitNewString[parseInt(existingStringIndex)+1];
                                            const secondDuration = splitNewString[parseInt(existingStringIndex)+2];

                                            if ( 
                                                (firstDuration !== existingStrings[parseInt(existingStringIndex)+1] && secondDuration === existingStrings[parseInt(existingStringIndex)+2]) ||
                                                (firstDuration === existingStrings[parseInt(existingStringIndex)+1] && secondDuration !== existingStrings[parseInt(existingStringIndex)+2])
                                                ) {
                                                if (firstDuration !== existingStrings[parseInt(existingStringIndex)+1]) {
                                                    existingStrings[parseInt(existingStringIndex)+1] += duration.replace(' only applies', 'and');
                                                    splitNewString[parseInt(existingStringIndex)+1] = '';
                                                } else {
                                                    existingStrings[parseInt(existingStringIndex)+2] += secondDuration.replace(' only applies', 'and');
                                                    splitNewString[parseInt(existingStringIndex)+2] = '';
                                                }
                                                texts[stringIndex] = existingStrings.join('|##|');
                                                // TODO need to be optimized
                                                texts.pop();
                                                addNewString = true;
                                            }

                                        } else {

                                        }
                                    }
                                }
                            }
                        }

                        if (!addNewString) {
                            texts.push(newString);
    
                            if (splitChar) texts.push('|##|');
                        }
                    }
                }

            } else {
                var singleString = this.getSingleString();
                texts.push(singleString);

            }
            if(splitChar && texts.length === 2 && texts[texts.length - 1] === splitChar) {
                texts.pop();
            }

            // merge starting time when duration times are same
            if (this.isMainStructure === true) {
                for (let i=0; i<texts.length;i++) {
                    let currentTime = texts[i].split(' |##|');
                    if (currentTime[1]) {
                        for (let tempi=i+1; tempi<texts.length; tempi++) {
                            let tempTime = texts[tempi].split(' |##|');
                            if (currentTime[1] === tempTime[1]) {
                                texts[i] = currentTime[0] + ' and ' + tempTime[0] + ' |##|' + currentTime[1] + ' |##|';
                                currentTime = texts[i].split(' |##|');
                                texts.splice(tempi-1, 2);
                            }
                        }
                    }

                }
            }
            
            for(let i in texts) {
                if (texts[i] === 'only applies') {
                    if (texts[parseInt(i)+1] !== null) {
                        texts[parseInt(i)+1] = texts[parseInt(i)+1].replace(/\n/g, '');
                    }
                }
            }
            var result = texts.join(' ');

            if(this.isMainStructure === true) {
                result = result.replace(new RegExp('[|# ]+$', 'g'), '');
                result = result.replace(new RegExp('[|# ]{5,}', 'g'), '|##|');
                result = result.replace(new RegExp('(\\d{2}:\\d{2}):\\d{2}:\\d{2}', 'g'), '$1');
            }
            
            /* eslint-disable-next-line */
            result = result.replace(/\|\#\#\|/g, splitChar);

            return result;
        }

        var parseTimeDomain = function(timeDomain) {
            var parts = timeDomain.match(/([a-zA-Z]+[0-9]+)/g);

            var date = {
                year: null,
                month: null,
                week: null,
                dayofmonth: null,
                dayofweek: [],
                hour: null,
                minute: null,
                second: null,
                lastmonth: null,
                particulardayofweek: null,
                reverseparticulardayofweek: null,
                odevity: null
            };

            for(var pairIndex in parts) {
                if(parts.hasOwnProperty(pairIndex)) {
                    var pair = parts[pairIndex];
                    var singleParts = pair.match(/([a-zA-Z]+)([0-9]+)/);

                    var type = singleParts[1];
                    var value = singleParts[2];

                    switch (type) {
                        case 'y':
                            date.year = +value;
                            break;
                        case 'M':
                            date.month = +value;
                            break;
                        case 'w':
                            date.week = +value;
                            break;
                        case 'd':
                            date.dayofmonth = +value;
                            break;
                        case 't':
                            date.dayofweek.push(+value);
                            break;
                        case 'f':
                            date.particulardayofweek = +value;
                            break;
                        case 'l':
                            date.reverseparticulardayofweek = +value;
                            break;
                        case 'p':
                            date.odevity = +value;
                            break;
                        case 'h':
                            date.hour = +value;
                            break;
                        case 'm':
                            date.minute = +value;
                            break;
                        case 's':
                            date.second = +value;
                            break;
                        case 'W':
                            date.lastmonth = +value;
                            break;
                        default:
                            break;
                    }
                }
            }

            return date;
        }

        this.getStartData = function() {
            if(this.startDataCache === null) {
                this.startDataCache = parseTimeDomain(chars.start);

                for(var key in this.startDataCache) {
                    if(this.startDataCache[key] !== null) {
                        this.startDateTypes.push(key);
                    }
                }
            }

            return this.startDataCache;
        }

        this.getDurationData = function() {
            if(this.durationDataCache === null) {
                this.durationDataCache = parseTimeDomain(chars.duration);

                for(var key in this.durationDataCache) {
                    if(this.durationDataCache[key] !== null) {
                        this.durationDateTypes.push(key);
                    }
                }
            }

            return this.durationDataCache;
        }

        this.getStartString = function() {
            var date = this.getStartData();

            var text = [];

            if(null !== date.year && null !== date.month && null !== date.dayofmonth) {
                text.push('from ' + getMonth(format2Dec(date.month)) + ' ' + format2Dec(date.dayofmonth) + ' ' +  date.year);
            } else if(null !== date.year && null !== date.month) {
                text.push('from ' + getMonth(date.month) + ' ' + date.year);
            } else if(null !== date.year && null !== date.dayofmonth) {
                text.push('on ' + date.dayofmonth + '. of every month of ' + date.year);
            } else if(null !== date.month && null !== date.dayofmonth) {
                text.push('from ' + getMonth(date.month) + ' ' + format2Dec(date.dayofmonth));
            } else if(null !== date.month) {
                text.push('from ' + getMonth(format2Dec(date.month)));
            }

            if(!date.month && null !== date.dayofmonth) {
                let app = 'th';
                if (date.dayofmonth === 1 || date.dayofmonth === 21 || date.dayofmonth === 31) {
                    app = 'st';
                } else if (date.dayofmonth === 2 || date.dayofmonth === 22) {
                    app = 'nd';
                }
                text.push('every ' + date.dayofmonth + app +' day of month');
            }

            if(date.dayofweek.length > 0) {
                var days = [];
                for(var weekdayIndex in date.dayofweek) {
                    if(date.dayofweek.hasOwnProperty(weekdayIndex)) {
                        var weekday = date.dayofweek[weekdayIndex];

                        days.push(getWeekDay(weekday));
                    }
                }
                if (date.week !== null) {
                    text.push('week '+ date.week + ' ' + days.join(', '));
                } else {
                    text.push('every ' + days.join(', '));
                }
            }


            if(date.particulardayofweek !== null) {
                const order = date.particulardayofweek.toString()[0];
                const weekday = date.particulardayofweek.toString()[1];
                
                text.push(getOrder(order) + ' ' + getWeekDay(weekday));
            }
            
            if(date.reverseparticulardayofweek !== null) {
                const reverseorder = date.reverseparticulardayofweek.toString()[0];
                const weekday = date.reverseparticulardayofweek.toString()[1];
                
                text.push(getReverseOrder(reverseorder) + ' ' + getWeekDay(weekday));
            }

            if(null !== date.hour && null !== date.minute && null !== date.second) {
                // Zeit ist HH:MM:SS
                text.push((text.length ? '' : '') + format2Dec(date.hour) + ':' + format2Dec(date.minute) + ':' + format2Dec(date.second) + '');
            } else if(null !== date.hour && null !== date.minute) {
                // Zeit ist HH:MM
                text.push((text.length ? '' : '') + format2Dec(date.hour) + ':' + format2Dec(date.minute) + '');
            } else if(null !== date.hour) {
                // Zeit ist HH:00
                text.push((text.length ? '' : '') + format2Dec(date.hour) + ':00');
            }

            if (date.odevity !== null) {
                text.push((date.odevity===0 ? 'even' : 'odd') + ' days of the month');
            }

            return text.join(' ');
        }

        this.getDurationString = function() {
            var startDate = this.getStartData();
            var date = this.getDurationData();

            var text = [];
            if (null !== date.year) {
                text.push(date.year + ' year' + (date.year > 1 ? 's':''));
            }
            if (null !== date.year &&
                null !== date.dayofmonth &&
                date.dayofweek.length === 0 &&
                !date.hour &&
                !date.minute &&
                !date.month &&
                !date.second &&
                !date.week) {
                text.push(date.dayofmonth + ' day' + (date.dayofmonth > 1 ? 's':''));
            }
            if (null !== date.month && startDate.particulardayofweek === null && startDate.reverseparticulardayofweek === null ) {
                if (!date.dayofmonth &&
                    date.dayofweek.length === 0 &&
                    !date.year &&
                    !date.week &&
                    !date.hour &&
                    !date.minute &&
                    !date.second &&
                    // only month is set
                    
                    startDate.month &&
                    !startDate.dayofmonth &&
                    startDate.dayofweek.length === 0 &&
                    !startDate.year &&
                    !startDate.week &&
                    !startDate.hour &&
                    !startDate.minute &&
                    !startDate.second
                ) {
                    if (date.month === 1) {
                        text.push('ONLY ONE MONTH');
                    } else if (date.month === 2) {
                        text.push(`and ${getMonth(startDate.month+1)}`);
                    } else {
                        text.push(`to ${getMonth(startDate.month+date.month-1)}`);
                    }
                } else {
                    text.push(date.month + ' month' + (date.month > 1 ? 's':''));
                }
            }
            if (null !== date.month && (startDate.particulardayofweek !== null || startDate.reverseparticulardayofweek !== null)) {
                text.push(date.month + ' month' + (date.month > 1 ? 's':''));
            }

            if (null !== date.week) {
                text.push(date.week + ' week' + (date.week > 1 ? 's':''));
            }

            if(startDate.dayofweek.length > 0 || ((null !== startDate.dayofmonth && startDate.month) &&
                date.dayofmonth === 1 &&
                !date.month &&
                !date.year &&
                !date.week &&
                !date.hour)) {
                // do not add "for 1 day"
                // when only day of month is set
                // when no extra hours are set
                // when x. day of week is set
                // month and day of month is set
            }


            if (date.dayofmonth !== null) {
                if (date.dayofweek.length === 0 &&
                    !date.year &&
                    !date.month &&
                    !date.week &&
                    !date.hour &&
                    !date.minute &&
                    !date.second &&
                    // only month is set
                    
                    !startDate.month &&
                    !startDate.dayofmonth &&
                    startDate.dayofweek.length !== 0 &&
                    !startDate.year &&
                    !startDate.week &&
                    !startDate.hour &&
                    !startDate.minute &&
                    !startDate.second
                ) {
                    if (date.dayofmonth === 1) {
                        text.push('ONLY ONE DAY');
                    } else if (date.dayofmonth === 2) {
                        text.push(`ANDWEEK ${getWeekDay(startDate.dayofweek[0]+1)}`);
                    } else {
                        text.push(`TOWEEK ${getWeekDay(startDate.dayofweek[0]+date.dayofmonth-1)}`);
                    }
                } else if(date.dayofweek.length === 0 &&
                    !date.year &&
                    !date.week &&
                    !date.hour &&
                    !date.minute &&
                    !date.second &&
                    // only month is set
                    
                    startDate.month &&
                    startDate.dayofmonth &&
                    startDate.dayofweek.length === 0 &&
                    !startDate.year &&
                    !startDate.week &&
                    !startDate.hour &&
                    !startDate.minute &&
                    !startDate.second
                ) {
                    if (date.dayofmonth === 1) {
                        text.push(``);
                    } else  {
                        const toDate = new Date(1970, startDate.month-1, startDate.dayofmonth);
                        toDate.setDate(toDate.getDate()+date.dayofmonth-1);
                        
                        text.push(`TOMONTHDAY ${getMonth(toDate.getMonth()+1)} ${format2Dec(toDate.getDate())}`);
                    }
                } else if(date.dayofmonth !== 1 || date.week !== null || date.month !== null || date.hour !== null) {

                    text.push(date.dayofmonth + ' day' + (date.dayofmonth > 1 ? 's':''));
                }

            }

            if (null !== date.hour) {
                if(date.minute === 30) {
                    date.hour += 0.5;
                    date.minute = null;
                }
                text.push(date.hour + ' hour' + (date.hour > 1 ? 's':''));
            }
            if (null !== date.minute) {
                text.push(date.minute + ' minute' + (date.minute > 1 ? 's':''));
            }
            if (null !== date.second) {
                text.push(date.second + ' second' + (date.second > 1 ? 's':''));
            }

            if (null !== date.lastmonth) {
                text.push(date.lastmonth + ' month' + (date.lastmonth > 1 ? 's':'') + ' ago');
            }

            // for odevity
            if (startDate.odevity !== null && date.dayofmonth) {
                text.push(date.dayofmonth + ' day' + (date.dayofmonth > 1 ? 's':''));
            }

            return text.join(' ');
        }
    }

    function parseTimeDomain( input ) {
        mainBracket = new Bracket(true);

        var currentBracket = mainBracket;
        var currentMode = '';
        var joinMode = '';

        for (var i = 0; i < input.length; i++) {
            var char = input[i];

            switch (char) {
                case '[':
                    var tmp = new Bracket(false);
                    currentBracket.add(tmp, joinMode);
                    currentBracket = tmp;
                    joinMode = '';
                    break;
                case ']':

                    currentBracket = currentBracket.getParent();
                    break;
                case '(':
                    currentMode = 'start';
                    break;
                case '{':
                    currentMode = 'duration';
                    break;
                case ')':
                case '}':
                    // do nothing
                    break;
                case '*':
                case '+':
                    joinMode = char;
                    break;
                case '-':
                    if (input[i+1] === 'M') { // case -M in [(M9d22){-M6}]]
                        currentBracket.addChar(currentMode, 'W'); // W stands for -M
                        i++;
                    } else {
                        joinMode = char;
                    }
                    break;
                default:
                    currentBracket.addChar(currentMode, char);
            }
        }

        return mainBracket;
    }

    this.getString = function(input, splitChar) {
        if(typeof splitChar === 'undefined') splitChar = ', ';
        var resultObj = parseTimeDomain(input);

        var result = resultObj.getString(splitChar);
        return result[0].toUpperCase() + result.slice(1);
    };

    this.parse = function(input) {
        var resultObj = parseTimeDomain(input);

        return resultObj;
    };
};


function replaceAt(str, index, eIndex, character) {
    return str.substr(0, index) + character + str.substr(eIndex);
 }

export const translate = function(tdnotice) {
    for (let i=0; i<tdnotice.length; i++) {
        if (tdnotice[i] === '(') {
            tdnotice = replaceAt(tdnotice, i, i+1, "[(");
            i++;
        } else if (tdnotice[i] === '}') {
            tdnotice = replaceAt(tdnotice, i, i+1, "}]");
            i++;
        }
    }

    let lastCombinationNotation = tdnotice.length-1;
    let secondLastCombinationNotation = tdnotice.length-1;
    let combinationNotation = false;
    for (let i=tdnotice.length-1; i>-1; i--) {
        if (tdnotice[i] === '+' || tdnotice[i] === '*' || (tdnotice[i] === '-' && tdnotice[i-1] !== '{')) {
            combinationNotation = true;

            if (tdnotice[i-1] === '+' || tdnotice[i-1] === '*' || tdnotice[i-1] === '-')
                continue;
        }

        if (combinationNotation) {
            let subTDSector = tdnotice.substr(i, lastCombinationNotation-i+1);
            // let subTDSector = tdnotice.substr(i);
            let subUnit = translateSubUnit(subTDSector);

            tdnotice = replaceAt(tdnotice, i, i+subTDSector.length, subUnit);

            secondLastCombinationNotation = secondLastCombinationNotation + 2;
            if (subUnit[0] === '+' || subUnit[0] === '*' || subUnit[0] === '-') {
                subTDSector = tdnotice.substr(i, secondLastCombinationNotation-i+1);
                
                subUnit = translateSubUnit(subTDSector);
                tdnotice = replaceAt(tdnotice, i, i+subTDSector.length, subUnit);

                secondLastCombinationNotation = i-1;
            }

            combinationNotation = false;
            lastCombinationNotation = i-1;

        }
    }

    tdnotice = translateSubUnit(tdnotice);
    return tdnotice;
}

const depthMap = {
    1: {
        'left': ']',
        'right': '['
    },
    2: {
        'left': ']]',
        'right': '[['
    },
    3: {
        'left': ']]]',
        'right': '[[['
    }

}

function translateSubUnit(tdnotice) {
    const combinationNotations = tdnotice.substr(0, tdnotice.indexOf('['));
    let translated = false;
    let temp;
    let index = 0;
    let depth = 0;
    let posDepth = 0
    let finishline = tdnotice.length;
    let i = combinationNotations.length;
    if (i > 0) {
        let subTDnotice = tdnotice.substr(tdnotice.indexOf('['));

        for (i = i-1; i>-1; i--) {
            if (subTDnotice.indexOf('][') === -1) break;
            index = subTDnotice.indexOf('][');
            depth = 1;
            posDepth = 1;
            if (subTDnotice.indexOf('][[') === index) posDepth = 2;
            else if (subTDnotice.indexOf('][[[') === index) posDepth = 3;
            if ( (temp = subTDnotice.indexOf(']][[')) > -1 && temp === index-1) {
                index = temp;
                depth = 2;
                posDepth = 2;
                if (subTDnotice.indexOf(']][[[') === index) posDepth = 3;
            }
            if ( (temp = subTDnotice.indexOf(']]][[[')) > -1 && temp === index-1) {
                index = temp;
                depth = 3;
                posDepth = 3;
            }
            
            subTDnotice = subTDnotice.replace(`${depthMap[depth].left}${depthMap[depth].right}`, `${depthMap[depth].left}${combinationNotations[i]}${depthMap[depth].right}`);
            finishline = subTDnotice.substr(index+depth).indexOf(depthMap[posDepth].left);
            translated = true;
        }
        
        const finishLine = index+depth+finishline+depth;
        tdnotice = translated ? combinationNotations.substr(0, i+1) + '[' + subTDnotice.substr(0, finishLine) + ']' + subTDnotice.substr(finishLine)
                : tdnotice;
    }
    return tdnotice;
}