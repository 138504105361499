const mapAttributes = {
  title: 'Map Attributes',
  tabTitle: 'Map Attributes',
  settings: {
    'Api Key': {
      type: 'apiKey',
      url: 'https://smap.hereapi.com/v8/maps/attributes/path',
      apikey: process.env.REACT_APP_APIKEY
    }
  },
  inputs: [
    {
      type: 'mapAttributes',
      options: {
        key: 'mapAttributes',
        label: 'Map Attributes Input',
        isPost: true,
        inputTypes: [
          { label: 'Segment Ref.', value: 'segments' },
          { label: 'Flex. Polyline', value: 'flexiblePolyline' },
          { label: 'Router URL', value: 'routerUrl' },
          { label: 'Router Origin & Destination', value: 'routerOriginDest' }
        ],
        initValue: { type: 'segments' },
        postTypes: ['segments', 'flexiblePolyline'],
        rcParamsTypes: ['routerUrl', 'routerOriginDest'],
        routingUrl: 'https://router.hereapi.com/v8/routes?origin=ORIGIN_COORD&destination=DESTINATION_COORD&return=polyline%2Csummary%2Cactions%2Cinstructions&spans=segmentRef%2CfunctionalClass%2Cduration&transportMode=car'
      }
    },
    {
      type: 'checkboxes',
      options: {
        key: 'attributes',
        label: 'Attributes',
        initState: 'APPLICABLE_SPEED_LIMIT(*)',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        loadParams: {
          urlApiKey: 'settings.apikey',
          urlPath: 'settings.url',
          urlRegex: '.*/v8/maps/',
          urlSuffix: 'layers/list',
          secUrlSuffix: 'layers/list.html',
          secText: 'Map Attributes Documentation',
          mapAttributes: true,
          notSupported: ['ADMIN_PLACE_n', 'ADMIN_POLY_n', 'CARTO_LINE_DOn', 'CARTO_POLY_DOn', 'CARTO_POLY_RIVER_DOn', 'CENSUS_n',
            'CITY_POI_n', 'DTM_HEIGHT', 'REAL_TIME_TRAFFIC', 'ROAD_OVERVIEW', 'ROUNDABOUT_FCn', 'ROUTING_FCn', 'ADMIN_REGULATIONS',
            'ADMIN_TRUCK_RESTR', 'COUNTRY', 'ENV_ZONE_RESTR', 'ROUTING_ZONES', 'TC_ADMIN', 'TC_SYSTEM', 'TC_TOLLSYS_NAMES', 'TC_VEH_TYPES',
            'TRAFFIC_PATTERN', 'TOLLCOST_FCn'],
          excludes: ['BUILDING', 'CARTO_POLY_TOLL_HAZ', 'JUNCTION_VIEW', 'PSTLCB_GEN', 'PSTLCB_MP', 'PSTLCB_UNGEN', 'SIGNPOST', 'VIRT_CONN']
        }
      }
    },
    {
      type: 'transportMode',
      options: {
        key: 'transportMode',
        initValue: 'car',
        transports: [
          {
            label: 'Car',
            value: 'car'
          },
          {
            label: 'Pedestrian',
            value: 'pedestrian'
          },
          {
            label: 'Truck',
            value: 'truck'
          },
          {
            label: 'Bicycle',
            value: 'bicycle'
          },
          {
            label: 'Scooter',
            value: 'scooter'
          },
          {
            label: 'Bus',
            value: 'bus'
          },
          {
            label: 'Private Bus',
            value: 'privateBus'
          },
          {
            label: 'Taxi',
            value: 'taxi'
          }
        ]
      }
    },
    {
      type: 'routeTime',
      options: {
        label: 'Departure Time',
        departureKey: 'departure',
        cssClasses: 'rf-grey-box',
        isLocalSupported: true,
        supportAny: false
      }
    },
    {
      type: 'select',
      options: {
        key: 'trailerType',
        label: 'Trailer Type',
        selectOptions: [
          { label: 'None', value: '0' },
          { label: 'Caravan', value: '1' },
          { label: 'Trailer', value: '2' },
          { label: 'RVTrailer', value: '3' },
          { label: 'BoatTrailer', value: '4' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        label: 'Vehicle Number Axles',
        placeholder: 'Vehicle Number Axles',
        key: 'vehicleNumberAxles'
      }
    },
    {
      type: 'input',
      options: {
        label: 'Trailer Number Axles',
        placeholder: 'Trailer Number Axles',
        key: 'trailerNumberAxles'
      }
    },
    {
      type: 'select',
      options: {
        key: 'hybrid',
        label: 'Hybrid',
        selectOptions: [
          { label: 'Otherwise', value: '0' },
          { label: 'fuel+electric', value: '1' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        label: 'Trailer Height',
        placeholder: 'Trailer Height',
        key: 'trailerHeight'
      }
    },
    {
      type: 'input',
      options: {
        label: 'Vehicle Weight',
        placeholder: 'Vehicle Weight',
        key: 'vehicleWeight'
      }
    },
    {
      type: 'input',
      options: {
        label: 'Vehicle Weight Class',
        placeholder: 'Vehicle Weight Class',
        key: 'vehicleWeightClass'
      }
    },
    {
      type: 'select',
      options: {
        key: 'disabledEquipped',
        label: 'Disabled Equipped',
        selectOptions: [
          { label: 'Otherwise', value: '0' },
          { label: 'equipped to carry disabled people', value: '1' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        label: 'Passengers Count',
        placeholder: 'Passengers Count',
        key: 'passengersCount'
      }
    },
    {
      type: 'input',
      options: {
        label: 'Tires Count',
        placeholder: 'Tires Count',
        key: 'tiresCount'
      }
    },
    {
      type: 'select',
      options: {
        key: 'commercial',
        label: 'Commercial',
        selectOptions: [
          { label: 'Otherwise', value: '0' },
          { label: 'registered for commercial use', value: '1' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        key: 'heightAbove1stAxle',
        label: 'Height Above 1st Axle'
      }
    },
    {
      type: 'select',
      options: {
        key: 'fuelType',
        label: 'Fuel Type',
        selectOptions: [
          { label: 'Diesel', value: 'Diesel' },
          { label: 'Petrol', value: 'Petrol' },
          { label: 'LPG', value: 'LPG' },
          { label: 'LNG', value: 'LNG' },
          { label: 'CNG', value: 'CNG' },
          { label: 'Ethanol', value: 'Ethanol' },
          { label: 'Propane', value: 'Propane' },
          { label: 'Hydrogen', value: 'Hydrogen' },
          { label: 'Electric', value: 'Electric' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        label: 'Weight Per Axle Group',
        placeholder: 'Weight Per Axle Group',
        key: 'weightPerAxleGroup'
      }
    },
    {
      type: 'input',
      options: {
        key: 'trailerWeight',
        label: 'Trailer Weight'
      }
    },
    {
      type: 'select',
      options: {
        key: 'trailersCount',
        label: 'Trailer Count',
        selectOptions: [
          { label: 'No trailer', value: '0' },
          { label: 'One or more trailers', value: '1' },
          { label: 'Two or more trailers', value: '2' },
          { label: 'Three or more trailers', value: '3' },
          { label: 'one or more semi-trailers', value: '4' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        key: 'height',
        label: 'Height'
      }
    },
    {
      type: 'input',
      options: {
        key: 'length',
        label: 'Length'
      }
    },
    {
      type: 'input',
      options: {
        key: 'width',
        label: 'Width'
      }
    },
    {
      type: 'input',
      options: {
        key: 'limitedWeight',
        label: 'Limited Weight'
      }
    },
    {
      type: 'input',
      options: {
        key: 'weightPerAxle',
        label: 'Weight Per Axle'
      }
    },
    {
      type: 'checkboxes',
      options: {
        key: 'shippedHazardousGoods',
        label: 'Shipped Hazardous Goods',
        isExpanded: true,
        initState: '',
        cssClasses: 'rf-grey-box',
        params: [
          { value: 'explosive', label: 'Explosive' },
          { value: 'gas', label: 'Gas' },
          { value: 'flammable', label: 'Flammable' },
          { value: 'combustible', label: 'Combustible' },
          { value: 'organic', label: 'Organic' },
          { value: 'poison', label: 'Poison' },
          { value: 'radioActive', label: 'Radio Active' },
          { value: 'corrosive', label: 'Corrosive' },
          { value: 'poisonousInhalation', label: 'Poisonous Inhalation' },
          { value: 'harmfulToWater', label: 'Harmful To Water' },
          { value: 'allhazardousGoods', label: 'All Hazardous Goods' },
          { value: 'explosiveFlammable', label: 'Explosive Flammable' },
          { value: 'other', label: 'Other' }
        ]
      }
    },
    {
      type: 'select',
      options: {
        key: 'tunnelCategory',
        label: 'Tunnel Category',
        selectOptions: [
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' },
          { label: 'D', value: 'D' },
          { label: 'E', value: 'E' }
        ]
      }
    },
    {
      type: 'select',
      options: {
        key: 'emissionType',
        label: 'EmissionType Type',
        selectOptions: [
          { label: 'EURO I', value: '1' },
          { label: 'EURO II', value: '2' },
          { label: 'EURO III', value: '3' },
          { label: 'EURO IV', value: '4' },
          { label: 'EURO V', value: '5' },
          { label: 'EURO VI', value: '6' },
          { label: 'EURO EEV', value: '7' },
          { label: 'Electric Vehicles', value: '8' },
          { label: 'EURO II with PRC', value: '9' },
          { label: 'EURO III with PRC', value: '10' }
        ]
      }
    },
    {
      type: 'select',
      options: {
        key: 'co2EmissionClass',
        label: 'CO2 EmissionType Class',
        selectOptions: [
          { label: 'Emission class 1', value: '1' },
          { label: 'Emission class 2', value: '2' },
          { label: 'Emission class 3', value: '3' },
          { label: 'Emission class 4', value: '4' },
          { label: 'Emission class 5', value: '5' }
        ]
      }
    }
  ],
  map: [
    {
      type: 'mapAttributes',
      options: {
        path: 'result.state.geometry',
        segmentsPath: 'result.state.segmentsWithAttrs'
      }
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'setFields',
            options: {
              text: 'Set Origin',
              key: 'mapAttributes',
              setFieldsFormatter: 'addMapAttributesOrigin',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true
                }
              ]
            }
          },
          {
            type: 'setFields',
            options: {
              text: 'Set Destination',
              key: 'mapAttributes',
              setFieldsFormatter: 'addMapAttributesDestination',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true
                }
              ]
            }
          },
          {
            type: 'getMapAttributes',
            options: {
              text: 'Get Map Attributes'
            }
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon'
            }
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address'
            }
          }
        ]
      }
    },
    {
      type: 'marker',
      options: {
        text: 'A',
        coordsPath: 'fields.mapAttributes.routerOriginDest',
        mapAttributes: 0,
        mapAttributesType: 'fields.mapAttributes.type'
      }
    },
    {
      type: 'marker',
      options: {
        text: 'B',
        coordsPath: 'fields.mapAttributes.routerOriginDest',
        mapAttributes: 1,
        mapAttributesType: 'fields.mapAttributes.type'
      }
    },
    {
      type: 'route',
      options: {
        routePath: 'result.state.selectedSegment.segment.geometry',
        colorPath: 'colorPalette.primaryDarker',
        zIndex: 1000,
        centerOnChange: true
      }
    },
    {
      type: 'centerMap',
      options: {
        path: 'result.state.geometry',
        resultsPath: 'result.raw.data.items'
      }
    }
  ],
  resultPanel: [
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Segments',
            type: 'mapAttributes',
            options: {
              key: 'selectedSegment',
              formatter: 'mapAttributesSegments'
            }
          },
          {
            title: 'Response',
            type: 'rawResponse',
            options: {
              urlApiKey: 'settings.apikey',
              urlPath: 'settings.url',
              urlRegex: '.*/v8/maps/',
              urlSuffix: 'layers/list.html',
              urlText: 'Map Attributes Documentation'
            }
          }
        ]
      }
    }
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'required',
        options: {
          path: 'fields.origin'
        }
      },
      {
        type: 'required',
        options: {
          path: 'fields.destination'
        }
      }
    ]
  }
};

export default mapAttributes;
