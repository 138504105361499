import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  parsedParams,
  options,
  hostAndPath,
  settings,
  postData = {},
}) {
  const { key, isPathParam, isPost, inputType, initValue = '' } = options;
  const keyLowerCase = key.toLowerCase();
  if (isPathParam) {
    const regex = new RegExp(`{${key}}`);
    const pathParamIndex = settings.url
      .split('/')
      .findIndex((pathParam) => regex.test(pathParam));
    return { [key]: hostAndPath.split('/')[pathParamIndex] };
  } else if (isPost) {
    const value = get(postData, key, '');
    return { [key]: inputType === 'number' ? value : value.toString() };
  }
  const res = { [key]: parsedParams[keyLowerCase] || initValue };
  delete parsedParams[keyLowerCase];

  return res;
}
