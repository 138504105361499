import React from 'react';
import PropTypes from 'prop-types';
import { FormRow } from '@here/ref-client-ui';
import defaultPreset from './defaultPreset';

class IsolinePresets extends React.Component {
  setDefault = () => {
    const { setFields } = this.props;
    setFields({ ...defaultPreset });
  };

  render() {
    return (
      <FormRow>
        <lui-button onClick={this.setDefault}>Set default values</lui-button>
      </FormRow>
    );
  }
}

IsolinePresets.propTypes = {
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: IsolinePresets,
};
