import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import './hereContextMenu.scss';

const getInitState = () => ({
  open: false,
  position: null,
  contextMenuEvent: null,
});

class HereContextMenu extends React.Component {
  state = getInitState();

  componentDidMount() {
    const { map } = this.props;

    map.addEventListener('contextmenu', this.onContextMenu);
  }

  componentWillUnmount() {
    const { map } = this.props;

    map.removeEventListener('contextmenu', this.onContextMenu);
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  }

  onContextMenu = (e) => {
    const {
      originalEvent: { x, y },
    } = e;
    const { map } = this.props;

    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
      },
      contextMenuEvent: e,
    });
    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  };

  close = () => {
    const { map } = this.props;

    this.setState(getInitState());
    map.removeEventListener('mapviewchangestart', this.close);
    window.removeEventListener('click', this.close);
  };

  render() {
    const { open, position } = this.state;
    const { children, map } = this.props;
    if (!open) {
      return null;
    }

    const style = {
      top: position.top,
      left: position.left,
    };
    const newChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const originalOnClick = child.props.onClick;
        const newOnClick = (e) => {
          const { contextMenuEvent } = this.state;
          const { viewportX, viewportY } = contextMenuEvent;
          const { lat, lng } = map.screenToGeo(viewportX, viewportY);

          originalOnClick({
            latLng: { lat, lng },
            contextMenuEvent,
            clickEvent: e,
          });
        };

        return React.cloneElement(child, { onClick: newOnClick });
      }

      return child;
    });
    return (
      <div className="rf-context-menu" style={style}>
        {newChildren}
      </div>
    );
  }
}

HereContextMenu.propTypes = {
  open: PropTypes.bool,
  map: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default withHereMap(HereContextMenu);
