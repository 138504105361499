import { combineReducers } from 'redux';

import title from './title/reducer';
import isActive from './isActive/reducer';
import tabColorPalette from './tabColorPalette/reducer';
import tabTitle from './tabTitle/reducer';
import history from './history/reducer';
import selectedHistory from './selectedHistory/reducer';
import isLoading from './isLoading/reducer';
import id from './id/reducer';
import preset from './preset/reducer';
import fields from './fields/reducer';
import resultTab from './resultTab/reducer';
import result from './result/reducer';
import paramsMappingReducer from './paramsMapping/reducer';

export default combineReducers({
  id,
  title,
  isActive,
  tabColorPalette,
  tabTitle,
  history,
  selectedHistory,
  isLoading,
  preset,
  fields,
  resultTab,
  result,
  paramsMapping: paramsMappingReducer,
});
