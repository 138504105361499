import React from 'react';
import PropTypes from 'prop-types';
import { latLngToTile, tileToLatLng } from './tileHelpers';
import { isEqual } from 'lodash';
import vectorTileDecode from './vectorTileDecode';

class TileRects extends React.Component {
  componentDidMount() {
    const {
      bounds,
      fields: { x, y, z, selectedRect, rects },
      setFields,
    } = this.props;

    let setFieldsData = {};
    if (bounds.top && !rects) {
      setFieldsData = this.getTilesData();
    }

    if (x && y && z && !selectedRect) {
      setFieldsData.selectedRect = this.getRect(+x, +y, +z);
    }

    if (Object.keys(setFieldsData).length > 0) {
      setFields(setFieldsData);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      bounds,
      result,
      setResultState,
      fields: { x, y, z, selectedRect, rects },
      setFields,
    } = this.props;

    if (result.raw && !isEqual(result.raw, prevProps.result.raw)) {
      setResultState({
        decodedTileData: vectorTileDecode(new Uint8Array(result.raw.data)),
      });
    }

    if (isEqual(prevProps.bounds, bounds) && rects) {
      return;
    }

    let setFieldsData = {};
    if (bounds.top) {
      setFieldsData = this.getTilesData();
    }

    if (x && y && z && !selectedRect) {
      setFieldsData.selectedRect = this.getRect(+x, +y, +z);
    }

    if (Object.keys(setFieldsData).length > 0) {
      setFields(setFieldsData);
    }
  }

  getTilesData = () => {
    const {
      bounds: { top, right, bottom, left, zoom },
    } = this.props;

    const topLeftTile = latLngToTile({ lat: top, lng: left }, zoom);
    const bottomRightTile = latLngToTile({ lat: bottom, lng: right }, zoom);
    const rects = [];
    const tilesData = [];
    for (let ix = topLeftTile.x; ix <= bottomRightTile.x; ix++) {
      for (let iy = topLeftTile.y; iy <= bottomRightTile.y; iy++) {
        rects.push(this.getRect(ix, iy, zoom));
        tilesData.push({ x: ix, y: iy, z: zoom });
      }
    }
    return { rects, tilesData };
  };

  getRect = (x, y, z) => {
    const { lat: top, lng: left } = tileToLatLng({ x, y }, z);
    const { lat: bottom, lng: right } = tileToLatLng({ x: x + 1, y: y + 1 }, z);
    return [
      [top, left],
      [bottom, right],
    ];
  };

  render() {
    return null;
  }
}

TileRects.propTypes = {
  bounds: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default TileRects;
