export const TRUCK_MAPPER = {
  'truck[tunnelCategory]': (val) => ({ tunnelCategory: val.toUpperCase() }),
  'truck[grossWeight]': (val) => ({ limitedWeight: kgToTFormatter(val) }),
  'truck[weightPerAxle]': (val) => ({ weightPerAxle: kgToTFormatter(val) }),
  'truck[shippedHazardousGoods]': ({ values: val }) => ({
    shippedHazardousGoods: val
      .map((hazard) => {
        if (hazard === 'radioactive') {
          return 'radioActive';
        }
        return hazard;
      })
      .join(','),
  }),
  'truck[height]': (val) => ({ height: cmToMFormatter(val) }),
  'truck[width]': (val) => ({ width: cmToMFormatter(val) }),
  'truck[length]': (val) => ({ length: cmToMFormatter(val) }),
  'truck[axleCount]': (val) => ({ axlecount: val }),
  'exclude[countries]': (val) => ({ excludecountries: val }),
  'avoid[features]': (val) => {
    const res = {};
    const { values = [] } = val || {};
    if (values.indexOf('seasonalClosure') !== -1) {
      res.avoidseasonalclosures = 'true';
    }
    if (values.indexOf('difficultTurns') !== -1) {
      res.avoidturns = 'difficult';
    }
    return res;
  },
};

export const ROUTING_MODE_MAPPER = {
  fast: 'fastest',
  short: 'balanced',
};

function cmToMFormatter(val) {
  const res = +val / 100;
  return res ? `${res}` : '';
}

function kgToTFormatter(val) {
  const res = +val / 1000;
  return res ? `${res}` : '';
}
