import { TILE_TYPES } from '../../../state/map/tiles/constants';

export const getTilesUrl = (tilesData) => {
  if (tilesData.type === TILE_TYPES.VECTOR) {
    return getVectorTilesUrl(tilesData.vector);
  } else if (tilesData.type === TILE_TYPES.VECTORHARP) {
    return getVectorTilesUrl(tilesData.vectorHARP);
  } else if(tilesData.type === TILE_TYPES.RASTERV3){
    return getRasterV3TilesUrl(tilesData.rasterv3);
  } else{
    return getRasterTilesUrl(tilesData.raster);
  }
};

function getVectorTilesUrl({ url, path, apiKey }) {
  return `https://${url}/${path.replace(
    /^\/|\/$/g,
    ''
  )}/{z}/{x}/{y}/omv?apikey=${
    apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey
  }`;
}


function getRasterV3TilesUrl({
  apiKey,
  type,
  style,
  ppi,
  size,
  format,
  lg,
  pview,
  projection,
  additionalParameters,
  url,
  fPois,
  fVehicleRestrictions,
  fCongestionZones,
  fEnvZones,
}) {
  const featuresparams = [];
  if (fCongestionZones && fCongestionZones !== '-none-') {
    featuresparams.push(`congestion%20zones:${fCongestionZones}`);
  }
  if (fPois && fPois !== '-none-') {
    featuresparams.push(`pois:${fPois}`);
  }
  if (fVehicleRestrictions && fVehicleRestrictions !== '-none-') {
    featuresparams.push(`vehicle_restrictions:${fVehicleRestrictions}`);
  }
  if (fEnvZones && fEnvZones !== '-none-') {
    featuresparams.push(`environmental%20zones:${fEnvZones}`);
  }

  let ppiQuery = '';
  if ( ppi !== '-none-' && ppi !== '-auto-' ) {
    ppiQuery = `&ppi=${ppi}`;
  }

  const params = 
  `apiKey=${apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey}`+
  `${lg && lg !== '-none-' ? `&lg=${lg}` : ''}`+
  `${style && style !== '-none-' ? `&style=${style}` : ''}`+
  ppiQuery +
  `${featuresparams.length > 0 ? `&features=${featuresparams.join(',')}` : ''}`+
  `${size && size !== '-none-' ? `&size=${size}` : ''}`+
  `${pview && pview !== '-none-' ? `&pview=${pview}` : ''}`+
  `${additionalParameters ? `&${additionalParameters}` : ''}`;
  
  return `https://maps.${url}/v3/${type}/${projection}/{z}/{x}/{y}/${format}?${params}`;
}



function getRasterTilesUrl({
  apiKey,
  type,
  scheme,
  size,
  format,
  lg,
  pview,
  additionalParameters,
  url,
}) {
  const domainType = type === 'traffictile' ? 'traffic' : 'base';
  const domain = `{s}.${domainType}.maps.ls.${url}`;
  const params = 
  `apiKey=${apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey}` +
  `&lg=${lg}` +
  `${pview && pview !== '-none-' ? `&pview=${pview}` : ''}`+
  `${additionalParameters ? `&${additionalParameters}` : ''}`;
  return `https://${domain}/maptile/2.1/${type}/newest/${scheme}/{z}/{x}/{y}/${size}/${format}?${params}`;
}

export function setLeafletPoliticalView(map, pview) {

}