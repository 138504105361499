function callback(options, e, setIsAvoidExcludeShown) {
  const { zoom, latLng, xy, xyToLatLng } = e;
  const { x, y } = xy;
  const { lat, lng } = latLng;

  const padding = 100;
  const topLeftPoint = { x: x - padding, y: y - padding };
  const bottomRightPoint = { x: x + padding, y: y + padding };
  const topLeft = xyToLatLng(topLeftPoint);
  const bottomRight = xyToLatLng(bottomRightPoint);

  const left = xyToLatLng({ x: x - padding, y });
  const right = xyToLatLng({ x: x + padding, y });

  setIsAvoidExcludeShown({
    shown: true,
    center: { lat, lng, zoom },
    box: {topLeft, bottomRight},
    line: { left, right },
    options: options
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setIsAvoidExcludeShown }) {
  return {
    text: options.text,
    callback: (e) => callback(options, e, setIsAvoidExcludeShown),
  };
}
