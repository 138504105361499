import React from "react";

function ReverseIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M7.784 10.954H5.999v5.004H3.026v-5.025H1.3c-.391 0-.553-.287-.355-.636l3.148-5.509c.195-.349.516-.349.715 0l3.143 5.509c.198.349.226.657-.167.657zm.432-5.908h1.785V.042h2.973v5.025h1.725c.393 0 .553.287.357.637l-3.148 5.508c-.195.349-.516.349-.715 0L8.048 5.704c-.199-.35-.225-.658.168-.658z" />
    </svg>
  );
}

export default ReverseIcon;
