import * as types from './constants';
import {
  ADD_TAB,
  REMOVE_TAB,
  REORDER_TABS,
  DUPLICATE_TAB,
} from 'state/tabs/constants';
import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = 0, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TAB:
      return action.index;
    case REORDER_TABS:
    case REMOVE_TAB:
      return get(action, 'data.selectedTab', state);
    case ADD_TAB:
      return 0;
    case DUPLICATE_TAB:
      return state + 1;
    default:
      return state;
  }
};
