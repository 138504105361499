export default function distance(meters, format = 'km', precision = 3) {
  const METERS_IN_MILE = 1609.344;
  const METERS_IN_KM = 1000;
  let result = [],
    convertMeters = (meters, coeff, precision) =>
      (meters / coeff).toFixed(precision);

  if (typeof meters !== 'number') {
    if (meters !== undefined) {
      console.warn('utils#distance: Incorrect input format');
    }

    return '';
  }

  if (format.indexOf('km') !== -1) {
    let kilometers = convertMeters(meters, METERS_IN_KM, precision);
    result.push(kilometers > 1 ? `${kilometers} km` : `${meters} m`);
  }

  if (format.indexOf('mi') !== -1) {
    result.push(`${convertMeters(meters, METERS_IN_MILE, precision)} mi`);
  }

  if (result.length > 1) {
    result[1] = `(${result[1]})`;
  }

  return result.join(' ');
}
