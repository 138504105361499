import React from 'react';
import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import { isConditionsSatisfied } from '../../utils';

function Group(libraryType) {
  const res = function GroupComponent(props) {
    const { options, refClient } = props;
    const { conditions, children } = options;

    if (isConditionsSatisfied(props, conditions)) {
      return children.map((mapConfig) => {
        const plugin = refClient.mapPlugin.get(mapConfig.type);
        const Component = plugin[libraryType] || plugin;

        const childProps = {
          ...props,
          options: { ...mapConfig.options },
          key: `${props.key}${mapConfig.type}`,
        };

        return <Component {...childProps} />;
      });
    }

    return null;
  };

  return res;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Group(TILE_LIBRARY_TYPES.LEAFLET),
  [TILE_LIBRARY_TYPES.JSLA]: Group(TILE_LIBRARY_TYPES.JSLA),
};
