import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import EvRoutes from './EvRoutes';
import { Polyline } from 'react-leaflet';
import HereRoute from '../routes/HereRoute';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: EvRoutes(Polyline),
  [TILE_LIBRARY_TYPES.JSLA]: EvRoutes(HereRoute),
};
