import axios from 'axios';

const defaultLogger = (message = {}, severity) => {
  const source = window.location.hostname;
  const url = window.location.href;
  const metadata = { source };
  if (process.env.NODE_ENV === 'production') {
    axios
      .post(`https://proxy.refclient.ext.here.com/log`, {
        message: { ...message, url },
        severity,
        metadata,
      })
      .catch((err) => {
        console.error(err);
      });
  } else if (severity === 'error') {
    console.error('splunk log', { ...message, url });
  } else {
    // eslint-disable-next-line no-console
    console.log('splunk log', { ...message, url });
  }
};

export const logError = (error = {}) => {
  const errorStr = `${error.message}\n${error.stack}`;
  defaultLogger({ error: errorStr }, 'error');
  console.error(error);
};

export default defaultLogger;
