import React from 'react';
import { FormRow, Input, ExpandFormRow, Select, Tooltip } from '@here/ref-client-ui';
import PropTypes from 'prop-types';
import './olsWaypointAdvanced.scss';
import AttentionSolid from '@here/ref-client-ui/lib/icons/AttentionSolid';
import some from 'lodash/some';
import utils from 'utils';

class OlsWaypointAdvanced extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  chargingParams = {}

  componentDidUpdate() {
    const { data, onChange } = this.props;
    const { sideOfStreetHint } = data;
    if (!sideOfStreetHint) {
      return;
    }
    const noSpaces = sideOfStreetHint.replace(/\s/g, '');
    if (noSpaces !== sideOfStreetHint) {
      onChange('sideOfStreetHint', noSpaces);
    }
  }

  onChangePassThrough = ({ target: { value } }) => {
    const {
      onChange,
      data: { stopDuration, uTurnPermission },
    } = this.props;

    if (value === 'true' && stopDuration) {
      onChange('stopDuration', '');
    }

    if (value === 'true' && uTurnPermission) {
      onChange('uTurnPermission', '');
    }
    onChange('passThrough', value);
  };

  onChangeStopDuration = ({ target: { value } }) => {
    const {
      onChange,
      data: { passThrough },
    } = this.props;

    if (value && passThrough === 'true') {
      onChange('passThrough', '');
    }

    onChange('stopDuration', value);
  };

  onChangeRadiusPenalty = ({ target: { value } }) => {
    const {
      onChange,
      data: { passThrough },
    } = this.props;

    if (value && passThrough === 'true') {
      onChange('passThrough', '');
    }

    onChange('stopDuration', value);
  };

  onChangeNumber = (key, { target: { value } }) => {
    const { onChange } = this.props;
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      onChange(key, +value);
    } else {
      onChange(key, value);
    }
  };

  onChargingChange = (key, e) => {    
    const { onChange } = this.props;
    this.chargingParams[key] = utils.extractData(e);
    if (!this.chargingParams[key]) {
      delete this.chargingParams[key];
    }
    
    const resultArray = Object.keys(this.chargingParams).map(key=>{
      return `${key}=${this.chargingParams[key]}`
    })
    
    onChange('charging', `(${resultArray.join(';')})`)
  }

  getCharging = (charging) => {
    if (charging && charging[0] === '(') {
      charging = charging.substring(1);
    }
    if (charging && charging[charging.length-1] === ')') {
      charging = charging.substring(0, charging.length-1);
    }
    const paramsArray = charging ? charging.split(';') : [];
    this.chargingParams = {};
    paramsArray.forEach( param => {
      const [key, value] = param.split('=');
      this.chargingParams = {...this.chargingParams, [key]: value};
    })
    
    return (
      <ExpandFormRow
        className="rf-advanced-sub-params"
        label="Charging"
      >
        <FormRow>
          <Input
            label="power"
            type="number"
            placeholder="power"
            value={this.chargingParams['power'] || ''}
            onBlur={(e) => this.onChargingChange('power', e)}

            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="current"
            type="number"
            placeholder="current"
            value={this.chargingParams['current'] || ''}
            onBlur={(e) => this.onChargingChange('current', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="voltage"
            type="number"
            placeholder="voltage"
            value={this.chargingParams['voltage'] || ''}
            onBlur={(e) => this.onChargingChange('voltage', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Select
            label="Supply Type"
            addEmptyOption
            options={
              [{
                label:'AC Single', value:'acSingle'
              },{
                label:'AC Three', value:'acThree'
              },{
                label:'DC', value:'dc'
              }]
            }
            value={this.chargingParams['supplyType'] || ''}
            onChange={(e) => this.onChargingChange('supplyType', e)}
          />
        </FormRow>
        <FormRow>
          <Input
            label="minDuration"
            type="number"
            placeholder="minDuration"
            value={this.chargingParams['minDuration'] || ''}
            onBlur={(e) => this.onChargingChange('minDuration', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="maxDuration"
            type="number"
            placeholder="maxDuration"
            value={this.chargingParams['maxDuration'] || ''}
            onBlur={(e) => this.onChargingChange('maxDuration', e)}
            blurOnEnter
          />
        </FormRow>
      </ExpandFormRow>
    )
  }

  render() {
    const { data, onChange, options, fields } = this.props;
    const {
      course,
      sideOfStreetHint,
      displayLocation,
      uTurnPermission,
      matchSideOfStreet,
      match,
      nameHint,
      radius,
      minCourseDistance,
      customizationIndex,
      stopDuration,
      radiusPenalty,
      segmentIdHint,
      onRoadThreshold,
      passThrough,
      extraParams,
      snapRadius,
      charging,
    } = data;

    const isAnySet = some(Object.keys(data), (key) => `${data[key]}`);

    return (
      <div className="rf-advanced-params">
        {isAnySet && (
          <Tooltip placement="right" tooltip="Advanced field has been set.">
            <AttentionSolid />
          </Tooltip>
        )}
        <ExpandFormRow label="Advanced">
          <ExpandFormRow
            className="rf-advanced-sub-params"
            label="Directional Parameters"
          >
            {(!options || options.course) && (
              <FormRow>
                <Input
                  label="Course"
                  type="number"
                  placeholder="Degrees, clock-wise from north"
                  value={course}
                  onBlur={(e) => this.onChangeNumber('course', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.minCourseDistance) && (
              <FormRow>
                <Input
                  label="Min Course Distance"
                  type="number"
                  placeholder="Meters"
                  value={minCourseDistance}
                  onBlur={(e) => this.onChangeNumber('minCourseDistance', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.sideOfStreetHint) && (
              <FormRow>
                <Input
                  label="Side Of Street Hint"
                  placeholder="{lat},{lng}"
                  value={sideOfStreetHint}
                  onBlur={(e) => onChange('sideOfStreetHint', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.matchSideOfStreet) && (
              <FormRow>
                <Select
                  label="Match Side Of Street"
                  value={matchSideOfStreet}
                  options={[
                    { label: 'Always', value: 'always' },
                    { label: 'Only If Divided', value: 'onlyIfDivided' },
                  ]}
                  onChange={(e) => onChange('matchSideOfStreet', e)}
                  addEmptyOption
                />
              </FormRow>
            )}
            {options && options.match && (
              <FormRow>
                <Select
                  label="Match Side Of Street"
                  value={match||''}
                  options={[
                    { label: 'Always', value: 'always' },
                    { label: 'Only If Divided', value: 'onlyIfDivided' },
                  ]}
                  onChange={(e) => onChange('match', e)}
                  addEmptyOption
                />
              </FormRow>
            )}
          </ExpandFormRow>
          <ExpandFormRow
            className="rf-advanced-sub-params"
            label="Location Parameters"
          >
            {(!options || options.nameHint) && (
              <FormRow>
                <Input
                  label="Name Hint"
                  value={nameHint}
                  onBlur={(e) => onChange('nameHint', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.segmentIdHint) && (
              <FormRow>
                <Input
                  label="SegmentId Hint"
                  placeholder="SegmentId Hint"
                  value={segmentIdHint}
                  onBlur={(e) => onChange('segmentIdHint', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.radius) && (
              <FormRow>
                <Input
                  label="Radius"
                  type="number"
                  placeholder="Meters"
                  value={radius}
                  onBlur={(e) => this.onChangeNumber('radius', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.radiusPenalty) && (
              <FormRow>
                <Input
                  label="Radius Penalty"
                  type="number"
                  placeholder="Percentage"
                  value={radiusPenalty}
                  onBlur={(e) => this.onChangeNumber('radiusPenalty', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.onRoadThreshold) && (
              <FormRow>
                <Input
                  label="On Road Threshold"
                  type="number"
                  placeholder="Meters"
                  value={onRoadThreshold}
                  onBlur={(e) => this.onChangeNumber('onRoadThreshold', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
            {(!options || options.snapRadius) && (
              <FormRow>
                <Input
                  label="Snap Radius"
                  type="number"
                  placeholder="Meters"
                  value={snapRadius}
                  onBlur={(e) => this.onChangeNumber('snapRadius', e)}
                  blurOnEnter
                />
              </FormRow>
            )}
          </ExpandFormRow>
          {(!options || options.charging) && (
            this.getCharging(charging)
          )}
          {(!options || options.displayLocation || options.uTurnPermission || options.customizationIndex || options.stopDuration) && (
            <ExpandFormRow
              className="rf-advanced-sub-params"
              label="Other Parameters"
            >
              {(!options || options.displayLocation) && (
                <FormRow>
                  <Input
                    label="Display Location"
                    placeholder="{lat},{lng}"
                    value={displayLocation}
                    onBlur={(e) => onChange('displayLocation', e)}
                    blurOnEnter
                  />
                </FormRow>
              )}
              {(!options || options.uTurnPermission) && (
                <FormRow>
                  <Select
                    label="U-Turn Permission"
                    value={uTurnPermission}
                    options={[
                      { label: 'Allow', value: 'allow' },
                      { label: 'Avoid', value: 'avoid' },
                    ]}
                    onChange={(e) => onChange('uTurnPermission', e)}
                    addEmptyOption
                  />
                </FormRow>
              )}
              {(!options || options.customizationIndex) && (fields && fields.customizations) && (
                <FormRow>
                  <Input
                    label="Customization Index"
                    type="number"
                    placeholder="Customization Index"
                    value={customizationIndex}
                    onBlur={(e) => this.onChangeNumber('customizationIndex', e)}
                    blurOnEnter
                  />
                </FormRow>
              )}
              {(!options || options.stopDuration) && (
                <FormRow>
                  <Input
                    label="Stop Duration"
                    type="number"
                    placeholder="Seconds"
                    value={stopDuration}
                    onBlur={this.onChangeStopDuration}
                    blurOnEnter
                  />
                </FormRow>
              )}
            </ExpandFormRow>
          )}
          {(!options || options.passThrough) && (
            <FormRow isHidden={passThrough === undefined}>
              <Select
                label="Pass Through"
                value={passThrough}
                options={['true', 'false']}
                onChange={this.onChangePassThrough}
                addEmptyOption
              />
            </FormRow>
          )}
          {(!options || options.extraParams) && (
            <FormRow>
              <Input
                label="Extra Params"
                placeholder="Extra Params"
                value={extraParams}
                onBlur={(e) => onChange('extraParams', e)}
                blurOnEnter
              />
            </FormRow>
          )}
          {/* <div className="rf-hotisontal-line" /> */}
        </ExpandFormRow>
      </div>
    );
  }
}

export default OlsWaypointAdvanced;
