export default function olsMatrixRegionDefinitionBbox(fields, bounds) {
  const { searchRegion } = fields;
  const [[lat1, lng1] = [], [lat2, lng2] = []] = bounds;
  return {
    searchRegion: {
      ...searchRegion,
      in: `bbox:${lng2},${lat2},${lng1},${lat1}`,
    },
  };
}
