import React from 'react';
import PropTypes from 'prop-types';
import HerePolyline from 'shared/hereMap/HerePolyline';
import { addOpacityToHexColor } from '../../utils';

class Lines extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {};
  };

  close = () => {
    const { map } = this.props;
    if (!this.isUnmounted) {
      this.setState({ open: false });
    }
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };


  showInfo(segment) {
    const { setResultState, index, listIndex } = this.props;
    setResultState({'selectedSegment': { segment, index, listIndex }})

    const resultPanel = document.querySelector('.rf-result-panel .rf-sidebar__inner');
    const activeTab = document.querySelector('.rf-result-panel .rf-tabs__item_active');
 
    if (resultPanel && activeTab.dataset.index === '0') {
      resultPanel.scrollTo({
        top: 59 * listIndex + 150,
        behavior: 'smooth'
      });
    }
  }


  render() {
    const { positions, coatPositions, fillColor, strokeColor, lineWidth, zIndex, geometryIndex, draggable, resizable, onChange, segment } = this.props;

    const style = { lineWidth };
    if (fillColor) {
      style.fillColor = fillColor;
    } else if (strokeColor) {
      style.fillColor = addOpacityToHexColor(strokeColor, 0.4);
    }
    if (strokeColor) {
      style.strokeColor = strokeColor;
    }

    return <>
      <HerePolyline 
        latLngs={positions} 
        coatLatLngs={coatPositions}
        options={{ style, zIndex }}
        draggable={draggable} 
        resizable={resizable}
        geometryIndex={geometryIndex}
        onChange={onChange}
        onTap={segment ? this.showInfo.bind(this, segment) : null}
        changeCursorOnHover={true}
        onContextMenu={null}
      />
    </>;
  }
}

Lines.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  zIndex: PropTypes.number,
  onChange: PropTypes.func,
};

export default Lines;
