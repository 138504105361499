import React from 'react';
import * as types from './constants';
import { getTabs, getTabIndexById } from './index';
import { getSelectedTab } from '../selectedTab';
import { getTitle } from './tab/title';
import { getNewTabColor } from './tab/tabColorPalette';
import { setSelectedTab } from '../selectedTab/actions';
import { getConfig, getConfigStateData } from '../config';
import { getDefaultPresets } from '../defaultPresets/reducer';
import { getAllSettings } from '../settings';
import { set as setNotification } from '../notification/actions';
import { findIndex } from 'lodash';

function createTabTitleChecker(tabTitle, index) {
  return (tab) => tab.tabTitle === (tabTitle + (index ? ` ${index}` : ''));
}

export function addTab(tabData) {
  return (dispatch, getState) => {
    const state = getState();
    const config = getConfig(state);
    const tabs = getTabs(state);
    let tab = null;
    if (tabData.id) {
      tab = tabs.find((tab) => tab.id === tabData.id);
    }
    if (!tabData.tabColorPalette) {
      tabData.tabColorPalette = tab
        ? tab.tabColorPalette
        : getNewTabColor(state);
    }
    const { title } = tabData;
    const tabTitle = config[title].tabTitle;
    let i = 0;
    while( tabs.findIndex(createTabTitleChecker(tabTitle, i)) > -1 ) {
      i++;
    }
    tabData.tabTitle = tabTitle + (i ? ` ${i}` : '');

    const { fields, preset, errors } = getConfigStateData(
      getConfig(state),
      getAllSettings(state),
      getDefaultPresets(state),
      title
    );
    const stateData = {
      fields,
      preset,
      id: tabData.id,
      result: tabData.result,
    };
    if (tabData.fields) {
      stateData.fields = { ...stateData.fields, ...tabData.fields };
    }

    if (errors.length > 0) {
      const messages = errors.map((error, index) => (
        <li key={index}>&nbsp;&nbsp;- {error.message}</li>
      ));
      const children = (
        <>
          Form View render failure:
          <ul>{messages}</ul>
        </>
      );
      dispatch(setNotification({ children, impact: 'negative' }));
    }

    dispatch({ type: types.ADD_TAB, tabData, payload: stateData });
    if (stateData.id) {
      dispatch(setSelectedTab(getTabIndexById(getState(), stateData.id)));
    }
  };
}

export function removeTab(index) {
  return (dispatch, getState) => {
    let { selectedTab, tabs } = getState();
    const data = {};
    if (selectedTab >= index && selectedTab !== 0 && tabs.length > 1) {
      data.selectedTab = selectedTab - 1;
    }
    dispatch({ type: types.REMOVE_TAB, tabData: { index }, data });
  };
}

export function reorderTabs(indexes) {
  return (dispatch, getState) => {
    const data = {
      indexes,
      selectedTab: indexes.indexOf(getSelectedTab(getState())),
    };
    dispatch({ type: types.REORDER_TABS, data });
  };
}

export function duplicateTab(index) {
  return (dispatch, getState) => {
    const state = getState();
    const tabData = {};
    tabData.tabColorPalette = getNewTabColor(state);
    const title = getTitle(state, index);
    const tabs = getTabs(state);
    const configs = getConfig(state);
    const tabTitle = configs[title].tabTitle;
    let i = 0;
    while( tabs.findIndex(createTabTitleChecker(tabTitle, i)) > -1 ) {
      i++;
    }

    tabData.tabTitle = tabTitle + (i ? ` ${i}` : '');

    dispatch(
      assignTabDataAndDispatch({ type: types.DUPLICATE_TAB, tabData }, index)
    );
    if (index !== getSelectedTab(state)) {
      dispatch(setSelectedTab(index));
    }
  };
}

export function assignTabDataAndDispatch(action, tabIndex) {
  return (dispatch, getState) => {
    let state = getState();
    let tabId = action.tabData && action.tabData.tabId;
    let index;
    if (!tabIndex && !tabId) {
      index = tabIndex === undefined ? getSelectedTab(state) : tabIndex;
    } else if (tabId) {
      index = findIndex(state.tabs, { id: tabId });
    } else {
      index = tabIndex;
    }
    let { title } = state.tabs[index];
    dispatch({
      ...action,
      tabData: { ...(action.tabData || {}), index, title },
    });
  };
}
