import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LayerToggle from '../../../views/map/LayerToggle';
import RoRForm from './RoRForm';

import utils from 'utils';
import { get } from 'lodash';

const defaultState = {
  isActive: false,
  maxCharge: 0,
  initialCharge: 0,
  minChargeAtChargingStation: 0,
  minChargeAtFirstChargingStation: 0,
  isMaxChargeActive: false,
  isInitialChargeActive: false,
  isMinChargeAtStopActive: false,
  isMinChargeAtFirstStopActive: false,
  isChargingAllowed: true,
};

export const rorFields = {
  isMaxChargeActive: 'maxCharge',
  isInitialChargeActive: 'initialCharge',
  isMinChargeAtStopActive: 'minChargeAtChargingStation',
  isMinChargeAtFirstStopActive: 'minChargeAtFirstChargingStation',
};

class RoRContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = { formExpanded: false };
    this.props.setResultState(defaultState);
  }

  componentDidUpdate() {
    const { result, fields } = this.props;
    const fieldsToUpdate = {};
    Object.keys(rorFields).forEach((isActiveField) => {
      const fieldName = rorFields[isActiveField];
      const isActive = result.state[isActiveField];
      if (isActive) {
        return;
      }
      const apiValue = +get(fields, `ev[${fieldName}]`, 0);
      const rorValue = +get(result.state, fieldName, 0);
      if (apiValue !== rorValue) {
        fieldsToUpdate[fieldName] = apiValue;
      }
    });
    if (Object.keys(fieldsToUpdate).length) {
      this.setRangeOnRouteData(fieldsToUpdate, this.props);
    }
  }

  setRangeOnRouteData(data, nextProps) {
    let { fields, setResultState, tabIndex } = nextProps;

    Object.keys(data).forEach((key) => {
      if (Object.keys(rorFields).indexOf(key) > -1 && data[key] === false) {
        data[rorFields[key]] = +get(fields, `ev[${rorFields[key]}]`, 0);
      }
    });

    setResultState(data, tabIndex);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  onToggle = () => {
    const { formExpanded } = this.state;
    if (formExpanded) {
      this.setState({ formExpanded: false });
      window.removeEventListener('click', this.onToggle);
    } else {
      this.setState({ formExpanded: true });
      window.addEventListener('click', this.onToggle);
    }
  };

  onRoRToggle = () => {
    let isActive = !get(this.props, 'result.state.isActive');
    let { setResultState, setNotification, setFields, fields, request } = this.props;
    
    let spanValues = get(fields, 'spans.values', []);

    if (isActive) {
      let spans = [];
      if (!spanValues.includes('segmentRef')) {
        spans.push('segmentRef')
      } 
      if(!spanValues.includes('consumption')) {
        spans.push('consumption')
      }

      if(spans.length) {
        setFields({ spans: { values: [...spanValues, ...spans] } });

        request();
        setNotification({
          message: `Add ${spans.join(', ')} span and request.`,
          impact: 'significant',
          autoDismiss: 5,
        });
      }
    }
    setResultState({ isActive });
  };

  onRoRChange = (prop, event) => {
    this.props.setResultState({ [prop]: +event.target.value });
  };

  onCheckboxChange = (prop, event) => {
    this.props.setResultState({ [prop]: utils.extractData(event) });
  };

  updateFilterValue(key, value) {
    const { setFields } = this.props;
    setFields({ [key]: value });
  }

  renderRoRForm(isActive) {
    let { totalCapacity, batteryLevel } = this.props.result.state;
    return (
      <RoRForm
        rangeOnRoute={this.props.result.state}
        totalCapacity={totalCapacity}
        batteryLevel={batteryLevel}
        isActive={isActive}
        onChange={this.onRoRChange}
        onCheckboxChange={this.onCheckboxChange}
        onToggle={this.onRoRToggle}
        onBlur={this.onToggle}
      />
    );
  }

  render() {
    let isActive = !!get(this.props, 'result.state.isActive');
    let tooltipText = this.state.formExpanded ? undefined : 'Range on Route';

    return (
      <div onClick={this.stopPropagation}>
        <div className="rf-map__ror-toggle">
          <LayerToggle
            active={isActive}
            disabled={false}
            onChange={this.onToggle}
            tooltipText={tooltipText}
          />
        </div>
        {this.state.formExpanded && this.renderRoRForm(isActive)}
      </div>
    );
  }
}

RoRContainer.propTypes = {
  fields: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  request: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default RoRContainer;
