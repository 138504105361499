export const circle = (index, color, strokeColor = '#000000') =>
  // eslint-disable-next-line max-len
  `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><circle stroke="${strokeColor}" fill="${color}" cx="10" cy="10" r="8"/><text x="50%" y="50%" fill="#ffffff" font-size="11" text-anchor="middle" dy="4">${index}</text></svg>`;

export const cluster = (count, color) =>
  // eslint-disable-next-line max-len
  `<svg xmlns="http://www.w3.org/2000/svg" height="120" width="120"><circle cx="60" cy="60" r="36" fill="${color}"/><circle cx="60" cy="60" r="42" fill-opacity=".8" fill="${color}"/><circle cx="60" cy="60" r="48" fill-opacity=".5" fill="${color}"/><circle cx="60" cy="60" r="54" fill-opacity=".2" fill="${color}"/><circle cx="60" cy="60" r="60" fill-opacity=".2" fill="${color}"/><text x="50%" y="50%" fill="#fff" font-size="40" text-anchor="middle" dy="10">${count}</text></svg>`;


export const bed = (d) => '<svg width="114" height="32" xmlns="http://www.w3.org/2000/svg">' +
'<rect stroke="#000" fill="#1b468d" x="0" y="0" width="114" height="24" />'+
'<path transform="translate(2,0)"  width="20" height="20" fill-rule="evenodd" clip-rule="evenodd" d="M3 4C3 3.44772 2.55228 3 2 3C1.44772 3 1 3.44772 1 4V14V17V20C1 20.5523 1.44772 21 2 21C2.55228 21 3 20.5523 3 20V18H21V20C21 20.5523 21.4477 21 22 21C22.5523 21 23 20.5523 23 20V17V14V11C23 8.23858 20.7614 6 18 6H12C11.4477 6 11 6.44772 11 7V9.5C11 7.567 9.433 6 7.5 6C5.567 6 4 7.567 4 9.5C4 11.433 5.567 13 7.5 13H3V4ZM7.5 13C9.433 13 11 11.433 11 9.5V13H7.5ZM21 15V16H3V15H12H21ZM21 11V13H13V8H18C19.6569 8 21 9.34315 21 11ZM6 9.5C6 8.67157 6.67157 8 7.5 8C8.32843 8 9 8.67157 9 9.5C9 10.3284 8.32843 11 7.5 11C6.67157 11 6 10.3284 6 9.5Z" fill="#FFFFFF"/>'+
'<text x="29" y="8" font-size="6pt" font-family="Arial" font-weight="" text-anchor="left" fill="white">Long Rest</text>'+
'<text x="29" y="19" font-size="8pt" font-family="Arial" font-weight="" text-anchor="left" fill="white">'+d+'</text>'+
'<path d="M6.5,8.5l6,7l6-7H6.5z" transform="translate(0,15)" />'+
'</svg>';

export const coffee = (d) => '<svg width="114" height="32" xmlns="http://www.w3.org/2000/svg">' +
'<rect stroke="#000" fill="#1b468d" x="0" y="0" width="114" height="24" />'+
'<path transform="translate(2,0)" width="4" height="4" fill-rule="evenodd" clip-rule="evenodd" d="M9.7,22a8,8,0,0,0,8.037-6H19a3.005,3.005,0,0,0,2.942-3.6,3.081,3.081,0,0,0-3.06-2.4H3a1,1,0,0,0-1,1v2.7A8.2,8.2,0,0,0,9.7,22ZM18,12h1a1,1,0,0,1,0,2H18ZM6.707,3.707l-.75.75A2.42,2.42,0,0,1,6.561,6a2.049,2.049,0,0,1-.6,1.457l-1.25,1.25A1,1,0,0,1,3.293,7.293l1.25-1.25a2.42,2.42,0,0,1-.6-1.543,2.049,2.049,0,0,1,.6-1.457l.75-.75A1,1,0,0,1,6.707,3.707Zm5,0-.75.75A2.42,2.42,0,0,1,11.561,6a2.049,2.049,0,0,1-.6,1.457l-1.25,1.25A1,1,0,0,1,8.293,7.293l1.25-1.25a2.42,2.42,0,0,1-.6-1.543,2.049,2.049,0,0,1,.6-1.457l.75-.75a1,1,0,1,1,1.414,1.414Zm5,0-.75.75A2.42,2.42,0,0,1,16.561,6a2.049,2.049,0,0,1-.6,1.457l-1.25,1.25a1,1,0,0,1-1.414-1.414l1.25-1.25a2.42,2.42,0,0,1-.6-1.543,2.049,2.049,0,0,1,.6-1.457l.75-.75a1,1,0,1,1,1.414,1.414Z" fill="#FFFFFF"/>' +
'<text x="29" y="8" font-size="6pt" font-family="Arial" font-weight="" text-anchor="left" fill="white">Short Rest</text>'+
'<text x="29" y="19" font-size="8pt" font-family="Arial" font-weight="" text-anchor="left" fill="white">'+d+'</text>'+
'<path d="M6.5,8.5l6,7l6-7H6.5z" transform="translate(0,15)" />'+
'</svg>';

export const customEv = '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="28">' +
'<path stroke="black" stroke-width="1px" fill="#3a7bdb" fill-opacity="1" stroke-opacity="1" d="M11.50000010727088,0.22302245725223135 c-6.223700517201001,0 -11.268858545860132,5.045158028659132 -11.268858545860132,11.268858545860132 c0,6.320104810742259 5.478977349594789,10.488787137288792 11.268858545860132,16.285095286456887 c5.789881196265344,-5.797111518280938 11.268858545860132,-9.964990475714629 11.268858545860132,-16.285095286456887 C22.768858653131016,5.268180485911363 17.72370062447188,0.22302245725223135 11.50000010727088,0.22302245725223135 " id="svg_1" class=""/>'+
'<text x="11.5" y="17.5" font-size="15px" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">&#x1f50b;</text>' +
'<text x="16.5" y="10.5" font-size="8px" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">&#x2B50;</text>' +
'</svg>';
