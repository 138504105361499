import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';

class ExtraParams extends React.Component {
  onChange = (e) => {
    const { setFields } = this.props;
    setFields({ extraParams: e.target.value });
  };

  render() {
    const {
      fields: { extraParams },
    } = this.props;
    return (
      <FormRow>
        <Input
          value={extraParams}
          label="Extra Params"
          onBlur={this.onChange}
          type="text"
          blurOnEnter
        />
      </FormRow>
    );
  }
}

ExtraParams.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, urlParamsMap }) => {
    const paramStrs = Object.keys(parsedParams).map(
      (paramKey) => {
        const key = urlParamsMap[paramKey] ? urlParamsMap[paramKey] : paramKey;
        return `${key}=${parsedParams[paramKey]}`;
      }
    );

    return { extraParams: paramStrs.join('&') };
  },
  defaultState: {
    extraParams: '',
  },
  getRequestOptions: (fields, options) => ({
    params: getFieldParams(fields, options),
  }),
  Component: ExtraParams,
};

function getFieldParams(fields) {
  const { extraParams } = fields;
  if (!extraParams) {
    return {};
  }
  return extraParams.split('&').reduce((acc, param) => {
    const [key, ...rest] = param.split('=');
    const value = rest.length ? rest.join('=') : rest[0];
    let decodedValue;
    if (value) {
      try {
        decodedValue = decodeURIComponent(value);
      } catch (e) {
        console.error('Can`t decode input value', e);
      }
    } else {
      decodedValue = value;
    }

    return {
      ...acc,
      [key]: decodedValue,
    };
  }, {});
}
