import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormRow, Checkbox, CopyButton, Input, Tree, Tooltip } from '@here/ref-client-ui';
import { get, cloneDeep } from 'lodash';
import './rawResponse.scss';
import { TimeDomainParser, translate } from 'utils/timeDomainParser';
import copyToClipboard from 'copy-to-clipboard';

const timeDomainParser = new TimeDomainParser();

class RawResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePolylines: false,
      showCopied: false
    };
  }

  onClick = (v) => {
    if (v[0] === '"') {
      v = v.substr(1);
    }
    if (v[v.length-1] === '"') {
      v = v.slice(0, -1);
    }
    copyToClipboard(v);
    this.setState({showCopied: true});
  }

  onMouseOut = () => {
    this.setState({showCopied: false});
  }
  hidePolylines = (data) => {
    data = cloneDeep(data);
    if (get(data, 'routes.0')) {
      for (let i = 0; i < data.routes.length; i++) {
        const route = data.routes[i];
        if (get(route, 'sections.0')) {
          for (let j = 0; j < route.sections.length; j++) {
            const section = route.sections[j];
            if (section.polyline) {
              delete section.polyline;
            }
          }
        }
      }
    }
    return data;
  };

  togglePolylines = () => {
    this.setState({
      hidePolylines: !this.state.hidePolylines,
    });
  };

  render() {
    const {
      result: { raw },
      options: { responsePath, expandLevel = 4, fields = [] ,
        urlApiKey,
        urlPath,
        urlRegex,
        urlSuffix,
        urlText
      } = {},
    } = this.props;
    let { data } = raw || {};
    const serverTime = get(raw, 'additionalData.serverTime', 0) / 1000;
    const clientTime = get(raw, 'additionalData.clientTime', 0);
    if (responsePath) {
      data = get(this.props, responsePath);
    }
    const shouldExpandNode = (keyPath, data, level) => level < expandLevel;
    let dataString;
    try {
      dataString = JSON.stringify(data);
    } catch (e) {
      console.error('Can`t stringify JSON', e);
    }

    if (this.state.hidePolylines) {
      data = this.hidePolylines(data);
    }
    let textUrl = '';
    if ( urlText ) {
      const propsUrl = get(this.props, urlPath, '');
      const propsUrlApiKey = get(this.props, urlApiKey, '');
      const regexp = new RegExp(urlRegex);
      const matchedUrl = propsUrl.match(regexp);
      if (matchedUrl) {
        textUrl = `${matchedUrl[0]}${urlSuffix}?apikey=${propsUrlApiKey}`;
      }
    }

    const fieldsEls = fields.map(({ label, valuePath }) => (
      <FormRow key={valuePath}>
        <Input label={label} isReadonly value={get(this.props, valuePath)} />
      </FormRow>
    ));

    return (        
    <lui-default-theme>
      <div className="rf-raw-response">
        {!!serverTime && (
          <FormRow>
            <Input
              label="Server Response Time"
              isReadonly
              value={`${Math.round(serverTime)} ms`}
            />
          </FormRow>
        )}
        <FormRow>
          <Input
            label="Client Response Time"
            isReadonly
            value={`${clientTime} ms`}
          />
        </FormRow>
        {fieldsEls}
        <h4>Raw Response</h4>
        <FormRow>
          <Checkbox
            label="Hide polylines"
            onChange={this.togglePolylines}
            isChecked={this.state.hidePolylines}
          />
        </FormRow>
        {urlText && (<FormRow>
          <a className='rf-input__caption' href={textUrl} target="_blank" rel="noopener noreferrer">{urlText}</a>
        </FormRow>)}
        <FormRow>
          <CopyButton value={dataString}>
            <lui-button secondary>Copy to clipboard</lui-button>
          </CopyButton>
        </FormRow>
        {data && (
          <FormRow>
            <Tree 
              data={data} 
              shouldExpandNode={shouldExpandNode} 
              valueRenderer={
                (raw, value, key) => {
                  if (key === 'routeHandle' || key === 'polyline') {
                    const short = raw.slice(0, 20) + ' ... ... ' + raw.substr(raw.length - 15);
                    const tdTooltip = <div className="rf-form-view__tooltip">{raw+`\n\n${this.state.showCopied ? 'Copied!' : 'Click to copy to Clipboard'}`}</div>
                    const tdRaw = <div onClick={() => this.onClick(raw)} onMouseOut={this.onMouseOut}>{short}</div>
                    return <Tooltip placement="right" className="toolTip-rawResponse" tooltip={tdTooltip}>{tdRaw}</Tooltip>
                  } else if (key === 'restrictedTimes' || key === 'applicableTimes') {
                    const parsedTDUnit = timeDomainParser.getString(translate(raw)).split(', \n ');
                    let conditions = 0;
            
                    parsedTDUnit.forEach(unit=>{
                        if (unit.indexOf('not valid') === -1) conditions++;
                    })
                    for (var i = 0; i < parsedTDUnit.length; i++) {
                      parsedTDUnit[i] = (conditions > 1 || parsedTDUnit[i].indexOf('not valid')===0 ? '    ' : '') + parsedTDUnit[i].charAt(0).toUpperCase() + parsedTDUnit[i].slice(1);
                    }
                    let parsedTDTooltip = parsedTDUnit.join('\n');

                    if (conditions > 1) {
                      parsedTDTooltip = 'ANY OF\n' + parsedTDTooltip;
                    }
                    
                    const tdTooltip = <div className="rf-form-view__tooltip">{parsedTDTooltip+`\n\n${this.state.showCopied ? 'Copied!' : 'Click to copy to Clipboard'}`}</div>
                    const tdRaw = <div onClick={() => this.onClick(parsedTDTooltip)} onMouseOut={this.onMouseOut}>{raw}</div>
                    return <Tooltip placement="right" className="toolTip-timedomain" tooltip={tdTooltip}>{tdRaw}</Tooltip>
                  }
                  else 
                    return raw;
                }
              }
            />
            
          </FormRow>
        )}
      </div>
    </lui-default-theme>
    );
  }
}

RawResponse.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object,
};

export default RawResponse;
