export default function olsMatrixRegionDefinitionCircle(
  fields,
  center,
  radius
) {
  const { regionDefinition } = fields;
  return {
    regionDefinition: {
      ...regionDefinition,
      center,
      radius,
    },
  };
}
