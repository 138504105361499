import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomComponent from 'shared/component';

import './tabs.scss';
import { debounce } from 'lodash';
import Toggler from '../toggler';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';

class CustomTabs extends CustomComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    let data = nextProps.data;
    if (nextProps.currentIndex !== null) {
      return {
        currentIndex: data[nextProps.currentIndex]
          ? nextProps.currentIndex
          : data.length - 1,
      };
    }
    if (!data[prevState.currentIndex]) {
      return {
        currentIndex: data.length - 1,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    this.onSetTab = this.onSetTab.bind(this);
    this.wrapperRef = React.createRef();
    this.onScrollDebounced = debounce(this.onScroll, 200);

    const { currentResultTabId = 0 } = props;
    this.state = {
      currentIndex: currentResultTabId,
      isLeftScrollShown: false,
      isRightScrollShown: false,
    }
  }

  state = {};
  tabRefs = [];
  scrollIndex = 0;

  componentDidMount() {
    const el = this.wrapperRef.current;
    this.setState({
      isRightScrollShown: el.scrollWidth > el.clientWidth,
    });
  }

  componentDidUpdate() {
    const {data} = this.props;
    if( data.length === 2 && data[0].title === 'Tab settings' ) {
      adjustLUIStyle(document.querySelectorAll('.rf-tabs lui-button'), 'lui-button');
    }
  }

  getTabsEl() {
    return this.props.data.map((item, idx) => {
      let baseClass = 'rf-tabs__item',
        cssClasses = classnames(baseClass, {
          [`${baseClass}_active`]: idx === this.state.currentIndex,
        });
      return (
        <div
          key={`rf-tabs__item_${idx}`}
          className={cssClasses}
          data-index={idx}
          ref={(ref) => {
            this.tabRefs[idx] = ref;
          }}
        >
          {item.title}
        </div>
      );
    }, this);
  }

  onSetTab(e) {
    let currentIndex = parseInt(e.target.getAttribute('data-index'));
    const { onSetTab, onChange, data } = this.props;
    if (!isNaN(currentIndex)) {
      this.setState({ currentIndex });
      onChange(currentIndex);
      if (onSetTab) {
        onSetTab(currentIndex, data[currentIndex]);
      }
    }

  }

  onScroll = () => {
    const el = this.wrapperRef.current;
    const { scrollWidth, clientWidth, scrollLeft } = el;
    let totalElsWidth = 0;
    if (scrollLeft === 0) {
      this.scrollIndex = 0;
    } else {
      this.tabRefs.find((tabRef, index) => {
        totalElsWidth += tabRef.clientWidth;
        if (totalElsWidth >= scrollLeft) {
          this.scrollIndex = index + 1;
          return true;
        }
        return false;
      });
    }
    this.setState({
      isLeftScrollShown: scrollLeft !== 0,
      isRightScrollShown: scrollWidth - clientWidth !== scrollLeft,
    });
  };

  scrollLeft = () => {
    this.scrollIndex--;
    if (this.scrollIndex < 0) {
      this.scrollIndex = 0;
    }

    this.scroll();
  };

  scrollRight = () => {
    this.scrollIndex++;
    const { length } = this.tabRefs;
    if (this.scrollIndex > length - 1) {
      this.scrollIndex = length - 1;
    }
    this.scroll();
  };

  scroll = () => {
    const el = this.wrapperRef.current;

    let left = 0;
    if (this.scrollIndex > 0) {
      for (let i = 0; i < this.scrollIndex; i++) {
        left += this.tabRefs[i].clientWidth;
      }
    }
    el.scrollTo({ behavior: 'smooth', left });
  };

  render() {
    const { data } = this.props;
    const { isLeftScrollShown, isRightScrollShown } = this.state;

    return (
      <div className={this.getCssClasses(['rf-tabs'])} onClick={this.onSetTab}>
        <div className="rf-tabs__wrapper">
          <div
            className="rf-tabs__wrapper__list"
            ref={this.wrapperRef}
            onScroll={this.onScrollDebounced}
          >
            {this.getTabsEl()}
          </div>
          {isLeftScrollShown && (
            <Toggler
              cssClasses="rf-tabs__scroll-left"
              rightArrow={false}
              toggle={this.scrollLeft}
            />
          )}
          {isRightScrollShown && (
            <Toggler
              cssClasses="rf-tabs__scroll-right"
              rightArrow
              toggle={this.scrollRight}
            />
          )}
        </div>
        <div className="rf-tabs__content">
          {data[this.state.currentIndex].content}
        </div>
      </div>
    );
  }
}

CustomTabs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  currentIndex: PropTypes.number,
};

CustomTabs.defaultProps = {
  onChange: () => {},
  currentIndex: null,
};

export default CustomTabs;
