import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormRow, ExpandFormRow, Tree, Tooltip } from '@here/ref-client-ui';
import './olsSummary.scss';
import { get, isString } from 'lodash';
import CompareTo from '../compareTo';
import getAlgorithm from './getAlgorithm';
import { decode } from 'ref-client-core/utils/flexPolyline';
import { getSectionConsumption, getSectionCo2emission, getSectionTollCost } from './olsSummaryHelpers';
import { TimeDomainParser, translate } from 'utils/timeDomainParser';
import copyToClipboard from 'copy-to-clipboard';


const getSummary = ({ summary }) => summary;
const getTravelSummary = ({ travelSummary }) => travelSummary;
const detectSummaryGetter = (section) =>
  section && [getSummary, getTravelSummary].find((method) => method(section));
const consumptionUnits = {
  'electric': 'kWh',
  'diesel': 'L (liters)',
  'petrol': 'L (liters)',
  'lpg': 'L (liters)',
  'cng': 'kg (kilograms)',
  'lng': 'L (liters)',
  'ethanol': 'L (liters)',
  'propane': 'L (liters)',
  'hydrogen': 'L (liters)',
}
const timeDomainParser = new TimeDomainParser();

class OlsSummary extends React.Component {
  
  addNoticesSpan = (e) => {
    const { setFields, fields, request } = this.props;
    const spanValues = get(fields, 'spans.values', []);

    setFields({ spans: { values: [...spanValues, 'notices'] } });
    request();
  };

  state = {
    showCopied: false
  };

  onClick = (v) => {
    copyToClipboard(v);
    this.setState({showCopied: true});
  }

  onMouseOut = () => {
    this.setState({showCopied: false});
  }

  getNotice(sectionIndex, notice, noticeIndex) {
    const { 
      setResultState
    } = this.props;
    const selectedNotice = get(this.props, 'result.state.selectedNotice');
    const inputs = Object.keys(notice).map((key) => {
      return (

      <FormRow key={key}>
        {isString(notice[key]) ? (
          <Input
            label={key}
            isReadonly
            value={isString(notice[key]) ? notice[key] : ''}
          />
        ) : (
          <>
            <label className="rf-input rf-input_readonly">
              <span className="rf-input__caption">details</span>
            </label>
              <Tree 
                data={notice[key]} 
                shouldExpandNode={() => true} 
                valueRenderer={
                  (raw, value, key) => {
                    if (key === 'restrictedTimes') {
                      const parsedTDUnit = timeDomainParser.getString(translate(raw)).split(', \n ');
                      let conditions = 0;
              
                      parsedTDUnit.forEach(unit=>{
                          if (unit.indexOf('not valid') === -1) conditions++;
                      })
                      for (var i = 0; i < parsedTDUnit.length; i++) {
                        parsedTDUnit[i] = (conditions > 1 || parsedTDUnit[i].indexOf('not valid')===0 ? '    ' : '') + parsedTDUnit[i].charAt(0).toUpperCase() + parsedTDUnit[i].slice(1);
                      }
                      let parsedTDTooltip = parsedTDUnit.join('\n');

                      if (conditions > 1) {
                        parsedTDTooltip = 'ANY OF\n' + parsedTDTooltip;
                      }
                      
                      const tdTooltip = <div className="rf-form-view__tooltip">{parsedTDTooltip+`\n\n${this.state.showCopied ? 'Copied!' : 'Click to copy to Clipboard'}`}</div>
                      const tdRaw = <div onClick={() => this.onClick(parsedTDTooltip)} onMouseOut={this.onMouseOut}>{raw}</div>
                      return <Tooltip placement="right" className="toolTip-timedomain" tooltip={tdTooltip}>{tdRaw}</Tooltip>
                    }
                    else 
                      return raw;
                  }
                }
              />
          </>
        )}

      </FormRow>
    )});

    const selectNotice = () => {
      if (sectionIndex !== null) {
        if (
          selectedNotice &&
          selectedNotice.sectionIndex === sectionIndex &&
          selectedNotice.noticeIndex === noticeIndex
        ) {
          setResultState({ selectedNotice: null });
        } else {
          const { result } = this.props;
          const currentRoute = result.state.currentRoute || 0;
          const section = get(
            result,
            ['raw', 'data', 'routes', currentRoute, 'sections', sectionIndex],
            {}
          );
          let shape;
          const resShape = [];

          if (section.polyline) {
            shape = decode(section.polyline);
          }

          get(section, 'spans', []).forEach((span, index) => {
            if (get(span, 'notices', []).indexOf(noticeIndex) !== -1) {
              const nextSpan = section.spans[index + 1] || {
                offset: shape.length,
              };
              shape
                .slice(span.offset, nextSpan.offset + 1)
                .forEach(([lat, lng]) => {
                  resShape.push({
                    lat: +lat,
                    lng: +lng,
                  });
                });
            }
          });

          setResultState({
            selectedNotice: {
              sectionIndex,
              noticeIndex: noticeIndex,
              shape: resShape,
            },
          });
        }
      }
    };

    return (
      <FormRow className="rf-section-notice__item" key={noticeIndex}>
        <div onClick={selectNotice}>{inputs}</div>
      </FormRow>
    );
  }

  render() {
    const {
      result,
      refClient: { formatterPlugin },
      fields,
      options: { compareTo, includeAlgorithm } = {},
    } = this.props;
    const currentRoute = result.state.currentRoute || 0;
    const routes = get(result, `raw.data.routes`, []);
    const globalNotices = get(result, `raw.data.notices`, []);
    const algorithm = get(result, 'raw.additionalData.algorithm', '');
    const serverTime = `${
      get(result, 'raw.additionalData.serverTime', 0) / 1000
    } ms`;
    const clientTime = `${get(result, 'raw.additionalData.clientTime', 0)} ms`;
    const route = routes[currentRoute] || {};
    const sections = route.sections || [];
    const spanValues = get(fields, 'spans.values', []);
    const hasNoticeSpan = spanValues.indexOf('notices') !== -1;

    let totalDuration = 0;
    let totalLength = 0;
    let totalMlDuration = 0;

    const getSummary = detectSummaryGetter(sections[0]);
    const globalNoticesEl = (
      <div className="rf-ols-summary">
        <FormRow className="rf-section-notice rf-global-notice">
          <h5>Notices:</h5>
          <div>{globalNotices.map(this.getNotice.bind(this, null))}</div>
        </FormRow>
      </div>
    );
    if (!getSummary) {
      let sectionNotices = 0;
      const sectionsEls = sections.map((section, index) => {
        const label = `Section ${index + 1}`;
        const notices = (section.notices || []).map(
          this.getNotice.bind(this, index)
        );
        if (notices.length) sectionNotices++;
        return (<>
          {!!notices.length && (<ExpandFormRow isExpanded label={label} key={`${label}${index}`}>
            {notices.length > 0 && (
              <FormRow className="rf-section-notice">
                <h5>Section notices:</h5>
                <div>{notices}</div>
                {!hasNoticeSpan && (
                  <div className="rf-section-notice__btn">
                    <lui-button secondary onClick={this.addNoticesSpan}>
                      Add notices span and request
                    </lui-button>
                  </div>
                )}
              </FormRow>
            )}
          </ExpandFormRow>)}
          </>);
      })

      if (globalNotices.length > 0 || sectionNotices) {
        return <>
          {globalNotices.length > 0 && (globalNoticesEl)}
          <div className="rf-ols-summary">
            {sectionsEls}
          </div>
        </>;
      }
      return (
        <div className="rf-empty">
          No summary information was requested from server.
          <br />
          In order to fetch this information, please select
          <br />
          <b>Return options -&gt; Summary</b> or
          <b>Return options -&gt; Travel Summary</b>
        </div>
      );
    }

    const totalConsumption = sections.reduce(
      (total, section) => total + getSectionConsumption(section),
      0
    );

    const consumptionType = sections[0].consumptionType;
    const consumptionUnit = consumptionUnits[consumptionType];

    const totalCo2emission = sections.reduce(
      (total, section) => total + getSectionCo2emission(section),
      0
    )

    let totalTollCurrency = false;
    let sectionTolls = false;
    let totalTollCost = sections.reduce(
      (total, section) => {
        if (section.tolls ) sectionTolls = true;
        const toll = getSectionTollCost(section);
        if (toll.tollCost !== undefined) {
          if (totalTollCurrency === false)
            totalTollCurrency = toll.currency;
          else if (toll.tollCost !== 0)
            totalTollCurrency = toll.currency;
        }
        return total + toll.tollCost || 0;
      },
      0
    )
    
    // if tolls is returned in section
    if (sectionTolls) {
      const sectionFares = sections.map(
        (section) => {
          const { tolls } = section;
          if ( tolls ) {
            const fares = tolls.map(
              toll => toll.fares
            )
            return fares;
          } else return {};
        }
      )
      const faresId = {};
      totalTollCost = sectionFares.flat(2).reduce(
        (total, fare) => {
          const price = fare.convertedPrice || fare.price || {};
          if (faresId[fare.id]) {
            return total || 0;
          } else {
            faresId[fare.id] = true;
            return total + price.value || 0;
          }
        },
        0
      )
    }

    const totalChargingDuration = sections.reduce(
      (total, section) =>
        total +
        (!section.postActions
          ? 0
          : section.postActions
              .filter(
                (postAction) =>
                  ['charging', 'chargingSetup'].indexOf(postAction.action) > -1
              )
              .reduce((sum, postAction) => sum + postAction.duration, 0)),
      0
    );

    const totalTrafficDuration = sections.reduce((total, section) => {
      const baseDuration = get(section, 'summary.baseDuration');
      if (!baseDuration) {
        return total;
      }
      return total + (get(section, 'summary.duration', 0) - baseDuration);
    }, 0);

    const formattedTotalTrafficDuration =
      totalTrafficDuration < 0
        ? `- ${formatterPlugin.get('time')(Math.abs(totalTrafficDuration))}`
        : formatterPlugin.get('time')(totalTrafficDuration);
    
    const sectionsEls = sections.map((section, index) => {
      const { duration, length, mlDuration } = getSummary(section);
      totalDuration += duration;
      totalLength += length;
      if (mlDuration) {
        totalMlDuration += mlDuration;
      }
      const label = `Section ${index + 1}`;
      const consumption = getSectionConsumption(section);
      const co2Emission = getSectionCo2emission(section);
      const toll = getSectionTollCost(section);

      const chargingDuration = !section.postActions
        ? null
        : section.postActions
            .filter(
              (postAction) =>
                ['charging', 'chargingSetup'].indexOf(postAction.action) > -1
            )
            .reduce((sum, postAction) => sum + postAction.duration, 0);

      const baseDuration = get(section, 'summary.baseDuration', 0);
      const trafficDuration = baseDuration === 0 ? 0 :
        (get(section, 'summary.duration', 0) -
        get(section, 'summary.baseDuration', 0));
      const formattedTrafficDuration =
        trafficDuration < 0
          ? `- ${formatterPlugin.get('time')(Math.abs(trafficDuration))}`
          : formatterPlugin.get('time')(trafficDuration);

      const notices = (section.notices || []).map(
        this.getNotice.bind(this, index)
      );

      return (
        <ExpandFormRow isExpanded label={label} key={label}>
          {notices.length > 0 && (
            <FormRow className="rf-section-notice">
              <h5>Section notices:</h5>
              <div>{notices}</div>
              {!hasNoticeSpan && (
                <div className="rf-section-notice__btn">
                  <lui-button secondary onClick={this.addNoticesSpan}>
                    Add notices span and request
                  </lui-button>
                </div>
              )}
            </FormRow>
          )}
          {includeAlgorithm && (
            <FormRow>
              <Input
                type="text"
                label="Algorithm"
                isReadonly
                value={getAlgorithm(index, currentRoute, algorithm)}
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              type="text"
              label="Duration"
              isReadonly
              value={formatterPlugin.get('time')(duration)}
            />
          </FormRow>
          {!!trafficDuration && (
            <FormRow>
              <Input
                type="text"
                label="Incl. traffic duration"
                isReadonly
                value={formattedTrafficDuration}
              />
            </FormRow>
          )}
          {mlDuration != null && (
            <FormRow>
              <Input
                type="text"
                label="ML duration"
                isReadonly
                value={formatterPlugin.get('time')(mlDuration)}
              />
            </FormRow>
          )}
          {!!chargingDuration && (
            <FormRow>
              <Input
                type="text"
                label="Incl. charging duration"
                isReadonly
                value={formatterPlugin.get('time')(chargingDuration)}
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              type="text"
              label="Length"
              isReadonly
              value={formatterPlugin.get('distance')(length)}
            />
          </FormRow>
          {!!consumption && (
            <FormRow>
              <Input
                type="text"
                label="Consumption"
                isReadonly
                value={`${+consumption.toPrecision(5)} ${consumptionUnit}`}
              />
            </FormRow>
          )}
          {!!co2Emission && (
            <FormRow>
              <Input
                type="text"
                label="Co2 Emission"
                isReadonly
                value={`${+co2Emission.toPrecision(5)} kg`}
              />
            </FormRow>
          )}
          {(toll.tollCost !== undefined) && (
            <FormRow>
              <Input
                type="text"
                label="TOLL COST"
                isReadonly
                value={`${toll.tollCost} ${toll.currency} `}
              />
            </FormRow>
          )}
        </ExpandFormRow>
      );
    });

    return (
      <div className="rf-ols-summary">
        {globalNotices.length > 0 && globalNoticesEl}
        <ExpandFormRow
          label="Total"
          isExpanded
          className="rf-ols-summary__total"
        >
          <FormRow>
            <Input
              type="text"
              label="Total Duration"
              isReadonly
              value={formatterPlugin.get('time')(totalDuration)}
            />
          </FormRow>
          {!!totalTrafficDuration && (
            <FormRow>
              <Input
                type="text"
                label="Incl. total traffic duration"
                isReadonly
                value={formattedTotalTrafficDuration}
              />
            </FormRow>
          )}
          {!!totalChargingDuration && (
            <FormRow>
              <Input
                type="text"
                label="Incl. total charging duration"
                isReadonly
                value={formatterPlugin.get('time')(totalChargingDuration)}
              />
            </FormRow>
          )}
          {totalMlDuration > 0 && (
            <FormRow>
              <Input
                type="text"
                label="Total ML duration"
                isReadonly
                value={formatterPlugin.get('time')(totalMlDuration)}
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              type="text"
              label="Total Length"
              isReadonly
              value={formatterPlugin.get('distance')(totalLength)}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Server response time"
              isReadonly
              value={serverTime}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Client response time"
              isReadonly
              value={clientTime}
            />
          </FormRow>
          {!!totalConsumption && (
            <FormRow>
              <Input
                type="text"
                label="Total consumption"
                isReadonly
                value={`${+totalConsumption.toPrecision(5)} ${consumptionUnit}`}
              />
            </FormRow>
          )}
          {!!totalCo2emission && (
            <FormRow>
            <Input
              type="text"
              label="Total Co2 Emission"
              isReadonly
              value={`${+totalCo2emission.toPrecision(5)} kg`}
            />
          </FormRow>
          )}
          {totalTollCurrency && (
            <FormRow>
            <Input
              type="text"
              label="Total Toll Cost"
              isReadonly
              value={`${+totalTollCost.toPrecision(4)} ${totalTollCurrency}`}
            />
          </FormRow>
          )}
        </ExpandFormRow>
        {sectionsEls}
        {compareTo && <CompareTo fields={fields} />}
      </div>
    );
  }
}

OlsSummary.propTypes = {
  fields: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
  request: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default OlsSummary;
