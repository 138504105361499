import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import './tabSortable.scss';
import {
  FormRow,
  Input,
  DragIcon,
  CloseButton,
} from '@here/ref-client-ui';
import { validateLon, validateLat } from 'utils/validation/coordsValidation';
import isFinite from 'lodash/isFinite';
import omit from 'lodash/omit';
import OlsWaypointAdvanced from '../../../core/formViewsPlugins/olsWaypoint/olsWaypointAdvanced';

const SortableItem = SortableElement((props) => props.children);
const Sortable = SortableContainer((props) => props.children);

const transformToOlsWaypointAdvanced = (point = {}, options = {}) => {
  const res = omit(point, ['lat', 'lng']);
  if (point.sideOfStreetHint) {
    const { lat, lng, matchSideOfStreet, match } = point.sideOfStreetHint;
    if (lat || lng) {
      res.sideOfStreetHint = `${lat || ''},${lng || ''}`;
    } else {
      delete res.sideOfStreetHint;
    }
    if (match)
      res.match = match;
    else
      res.matchSideOfStreet = matchSideOfStreet;
  }
  const keys = Object.keys(res);
  keys.forEach(key => {
    if (!options.hasOwnProperty(key)) {
      res.extraParams = res.hasOwnProperty('extraParams') ? `${res.extraParams};${key}=${res[key]}` : `${key}=${res[key]}`;
    }
  })
  return res;
};

const isPointValid = (point) =>
  isFinite(+point.lat) &&
  isFinite(+point.lng) &&
  validateLat(+point.lat) &&
  validateLon(+point.lng);

class TabSortable extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    const {
      tabs
    } = this.props
    this.setState({
      items: tabs
    })
  }

  getTabs() {
    let { data, pointsCount, options, onBlur, onRemovePoint, onChangeAdvanced } = this.props;

    return (
      <div className="tabbar">
      {data.map((point, num) => {
        const separator = point.lat || point.lng ? ',' : '';
        const value = `${point.lat || ''}${separator}${point.lng || ''}`;
        const isValid = isPointValid(point);
        return (
          <SortableItem key={`item-${num}`} index={num} value={point}>
            <div>
              <FormRow className="rf-point-item">
                <div className="rf-point-item__drag">
                  <DragIcon />
                </div>
                <Input
                  className="rf-clearfix rf-point-item__input"
                  label={`Point ${num + 1}`}
                  placeholder="Coordinates"
                  isValid={isValid}
                  value={value}
                  onBlur={(e) => onBlur(num, e)}
                  blurOnEnter
                />
                {pointsCount > 0 && (
                  <CloseButton onClick={(e) => onRemovePoint(num, e)} />
                )}
              </FormRow>
              {options && options !== null && (
                <OlsWaypointAdvanced
                  options={options}
                  data={transformToOlsWaypointAdvanced(point, options)}
                  onChange={(key, value) =>
                    onChangeAdvanced(num, key, value)
                  }
                />
              )}
            </div>
          </SortableItem>
        );
      })}
      </div>
    )
  }

  render() {
    const {
      onSortEnd,
      tabsRef,
    } = this.props

    return (
    <div ref={tabsRef} className="ui-sortable rf-nav-tabs__sortable">
      <Sortable
        helperClass="rf-point-drag-container"
        axis="y"
        lockAxis="y"
        transitionDuration={0}
        distance={10}
        onSortEnd={onSortEnd}
      >
        {this.getTabs()}
      </Sortable>
    </div>
    );
  }
}

export default TabSortable;
