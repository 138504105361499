import { ADD_TAB, DUPLICATE_TAB } from '../../constants';
import { createTabId } from '.';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case ADD_TAB:
      return action.payload.id || createTabId();
    case DUPLICATE_TAB:
      return createTabId();
    default:
      return state || createTabId();
  }
};
