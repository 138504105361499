import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsFormContainer from './SettingsFormContainer';

import classnames from 'classnames';

import './styles/settings.scss';
import { getSelectedTabs } from 'state/tabs';
import { getTitle } from 'state/tabs/tab/title';
import { getSettings, setSettingsValue } from 'state/settings';
import { getTabPreset, setPreset } from 'state/tabs/tab/preset/reducer';
import {
  addSettings,
  deleteSettings,
  changeSettingsName,
} from 'state/settings/custom/reducer';
import { setDefaultPreset } from 'state/defaultPresets/reducer';
import { set as setNotification } from 'state/notification/actions';
import * as serviceWorker from '../../serviceWorkerRegistration';
import adjustLUIStyle from '../../utils/adjustLUIStyle';

export class SettingsContainer extends Component {
  state = {
    isSettingsShown: false,
    isNewVersion: false,
    waitingWorker: null,
  };
  settingsPopupRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.onHidePopup, false);
    const onUpdate = (registration) => {
      this.setState({
        isNewVersion: true,
        waitingWorker: registration && registration.waiting,
      });
    };
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate });
    }

    adjustLUIStyle(document.querySelectorAll('.rf-settings lui-button') , 'lui-button');
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHidePopup, false);
  }

  onHidePopup = (e) => {
    let popup = this.settingsPopupRef.current.getDomNode();
    if (!popup.contains(e.target) && this.state.isSettingsShown) {
      this.close();
    }
  };

  onClick = (e) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({
      isSettingsShown: !this.state.isSettingsShown,
    });
  };

  closeOnEsc = (e) => {
    let keyCode = e.which || e.keyCode || 0;
    if (keyCode === 27) {
      this.close();
    }
  };

  close = () => {
    this.setState({
      isSettingsShown: false,
    });
  };

  render() {
    const {
      setSettingsValue,
      title,
      currentTabData,
      preset,
      settings,
      setPreset,
      addSettings,
      deleteSettings,
      changeSettingsName,
      setDefaultPreset,
      setNotification,
    } = this.props;
    const { waitingWorker, isNewVersion } = this.state;

    const btnClassName = classnames('rf-settings__btn', {
      'rf-settings__btn_active': this.state.isSettingsShown,
    });

    return (
      <div className="rf-settings" onKeyDown={this.closeOnEsc}>
        <div className={btnClassName} onClick={this.onClick} />
        {isNewVersion && <div className="rf-settings-notification" />}
        <SettingsFormContainer
          ref={this.settingsPopupRef}
          title={title}
          isSettingsShown={this.state.isSettingsShown}
          setSettingsValue={setSettingsValue}
          close={this.close}
          color={currentTabData.tabColorPalette.primary}
          settings={settings}
          preset={preset}
          setPreset={setPreset}
          addSettings={addSettings}
          deleteSettings={deleteSettings}
          changeSettingsName={changeSettingsName}
          setDefaultPreset={setDefaultPreset}
          setNotification={setNotification}
          isNewVersion={isNewVersion}
          waitingWorker={waitingWorker}
        />
      </div>
    );
  }
}

SettingsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  preset: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  currentTabData: PropTypes.object.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
  addSettings: PropTypes.func.isRequired,
  deleteSettings: PropTypes.func.isRequired,
  changeSettingsName: PropTypes.func.isRequired,
  setPreset: PropTypes.func.isRequired,
  setDefaultPreset: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    title: getTitle(state),
    currentTabData: getSelectedTabs(state),
    settings: getSettings(state),
    preset: getTabPreset(state),
  };
}

const mapDispatchToProps = {
  setSettingsValue,
  setPreset,
  addSettings,
  deleteSettings,
  changeSettingsName,
  setDefaultPreset,
  setNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
