import { TILE_TYPES } from '../tiles/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  type: TILE_TYPES.VECTOR,
  isActive: false,
  raster: {
    // host: 'https://tiles.traffic.api.here.com/traffic/6.0/tiles',
    url: 'traffic.maps.hereapi.com',
    path: '/v3/flow/mc/',
    imagesize: '256',
    imageformat: 'png',
    profile: '',
    apiKey: window.APIKEY,
    extraparams: '',
    refreshInterval: 60,
  },
  vector: {
    url: 'traffic.vector.hereapi.com',
    path: '/v2/traffictiles/traffic/mc/',
    apiKey: window.APIKEY,
    refreshInterval: 60,
  },
  vectorHARP: {
    url: 'traffic.vector.hereapi.com',
    path: '/v2/',
    apiKey: window.APIKEY,
    refreshInterval: 60,
  },
};
