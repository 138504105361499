import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';

function EvRoute(RouteComponent) {
  return class Route extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      result: PropTypes.object,
      colorPalette: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
    };
    state = {};

    shouldComponentUpdate(nextProps) {
      const {
        options: { selectedPath },
        options,
      } = this.props;
      const selectedRoute = get(this.props, selectedPath, 0);
      const { routesArrayPath } = options;
      const nextSelectedRoute = get(nextProps, selectedPath, 0);
      const routes = get(this.props, routesArrayPath);
      const nextRoutes = get(nextProps, routesArrayPath);
      return selectedRoute !== nextSelectedRoute || isEqual(routes, nextRoutes);
    }

    renderRoutes = (
      routes,
      routePath,
      formatter,
      colorPalette,
      sectionsPath
    ) => {
      const {
        options: { selectedPath, lowEnergyColor, hasNoEnergyColor },
      } = this.props;
      const selectedRoute = get(this.props, selectedPath, 0);
      const routeWrapper = routes[selectedRoute];
      const evGeometry = formatter(
        this.props.result.state,
        routeWrapper,
        sectionsPath,
        routePath
      );

      const result = [
        ...evGeometry.lowEnergyPaths.map((positions, key) => (
          <RouteComponent
            key={`evLowEnergy-${key}`}
            positions={positions}
            color={lowEnergyColor}
            weight={8}
            zIndex={31}
          />
        )),
      ];
      if (evGeometry.noEnergyPath.length) {
        result.push(
          <RouteComponent
            key="evNoEnergy"
            positions={evGeometry.noEnergyPath}
            color={hasNoEnergyColor}
            weight={8}
            zIndex={31}
          />
        );
      }
      return result;
    };

    render() {
      const {
        options,
        colorPalette,
        refClient: { formatterPlugin },
      } = this.props;
      const {
        routesArrayPath,
        routePath,
        format,
        sectionsPath,
        contextMenuFormatter,
        contextMenuLabel,
      } = options;
      const formatter = formatterPlugin.get(format);
      const routes = get(this.props, routesArrayPath);
      if (!routes || !this.props.result.state.isActive) {
        return null;
      }
      return (
        <>
          {this.renderRoutes(
            routes,
            routePath,
            formatter,
            colorPalette,
            sectionsPath
          )}
          {contextMenuFormatter && this.state.open && (
            <div className="rf-context-menu" style={{ ...this.state.position }}>
              <HereMapContextMenuItem
                label={contextMenuLabel}
                onClick={formatterPlugin
                  .get(contextMenuFormatter)
                  .bind(this, this.props)}
              />
            </div>
          )}
        </>
      );
    }
  };
}

export default EvRoute;
