import { useEffect } from 'react';
import { useHereMap } from '../../shared/hereMap/HereMapContext';

// Component for assigning map to window
function MapE2E() {
  const { map } = useHereMap();
  useEffect(() => {
    window.rfMap = map;
  }, [map]);
  return null;
}

export default MapE2E;
