export const ROUTE_WAYPOINT_CATEGORIES = ['geo', 'link', 'street'];
export const ROUTE_WAYPOINT_TYPES = ['stopOver', 'passThrough'];

export const DEFAULT_WAYPOINT = {
  displayPosition: '',
  coords: '',
  link: { value: '' },
  street: { street: '', name: '' },
  stopOverDuration: '',
  heading: '',
  type: 'stopOver',
  category: 'geo',
};
