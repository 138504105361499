export const empty = {
  fuelEnabled: false,

  // Consumption Parameters
  'fuel[type]': '',
  'fuel[freeFlowSpeedTable]': '',
  'fuel[trafficSpeedTable]': '',
  'fuel[additionalConsumption]': '',
  'fuel[ascent]': '',
};

export const modelDefault = {
  label: 'Default',
  value: 'default',
  preset: {
    fuelEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'fuel[type]': 'petrol',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetDiesel = {
  label: 'Diesel Car',
  value: 'diesel',
  preset: {
    fuelEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'fuel[type]': 'diesel',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetPetrol = {
  label: 'Petrol Car',
  value: 'petrol',
  preset: {
    fuelEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'fuel[type]': 'petrol',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]': '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetLpg = {
  label: 'LPG Car',
  value: 'lpg',
  preset: {
    fuelEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'fuel[type]': 'lpg',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetCng = {
  label: 'CNG Car',
  value: 'cng',
  preset: {
    ...empty,
    fuelEnabled: true,

    'fuel[type]': 'cng',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetLng = {
  label: 'LNG Car',
  value: 'lng',
  preset: {
    ...empty,
    fuelEnabled: true,

    'fuel[type]': 'lng',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetEthanol = {
  label: 'Ethanol Car',
  value: 'ethanol',
  preset: {
    ...empty,
    fuelEnabled: true,

    'fuel[type]': 'ethanol',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetPropane = {
  label: 'Propane Car',
  value: 'propane',
  preset: {
    ...empty,
    fuelEnabled: true,

    'fuel[type]': 'propane',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

export const presetHydrogen = {
  label: 'Hydrogen Car',
  value: 'hydrogen',
  preset: {
    ...empty,
    fuelEnabled: true,

    'fuel[type]': 'hydrogen',
    'fuel[freeFlowSpeedTable]':
      '10,0.095,20,0.072,30,0.058,40,0.049,50,0.045,60,0.043,70,0.042,80,0.043,90,0.045,100,0.047,110,0.05,120,0.052,130,0.055',
    // eslint-disable-next-line max-len
    'fuel[trafficSpeedTable]':
      '',
    'fuel[additionalConsumption]': '0.1',
    'fuel[ascent]': '0.1',
  },
};

const consumptionPresets = [
  modelDefault,
  presetDiesel,
  presetPetrol,
  presetLpg,
  presetCng,
  presetLng,
  presetEthanol,
  presetPropane,
  presetHydrogen
];

export default consumptionPresets;
