import {
  Checkbox,
  ExpandFormRow,
  FormRow,
  Input,
  Textarea,
} from '@here/ref-client-ui';
import set from 'lodash/set';
import get from 'lodash/get';
import './avoidSegments.scss';
import Searchable from 'ref-client-core/views/shared/searchUtils/Searchable';

const key = 'avoid[segments]';

function AvoidSegments({ fields, setFields, options = {} }) {
  const { avoidSegmentsIsPost } = fields;
  const { onlyPost } = options;
  const avoidSegments = fields['avoid[segments]'];

  const onChangeIsPost = ({ target: { checked } }) => {
    setFields({ avoidSegmentsIsPost: checked });
  };

  const onChangeSegments = ({ target: { value } }) => {
    setFields({ 'avoid[segments]': value });
  };

  const field = avoidSegmentsIsPost ? (
    <Textarea
      label="Avoid segments"
      value={avoidSegments}
      onBlur={onChangeSegments}
    />
  ) : (
    <Input
      label="Avoid segments"
      placeholder="Avoid segments"
      value={avoidSegments}
      onBlur={onChangeSegments}
      blurOnEnter
    />
  );

  return (
    <Searchable searchKey="avoid[segments]">
      <ExpandFormRow
        label="Avoid segments"
        isExpanded
        className="avoid-segments"
      >
        {!onlyPost && (
          <Checkbox
            label="Is POST"
            isChecked={avoidSegmentsIsPost || false}
            onChange={onChangeIsPost}
          />
        )}
        <FormRow>{field}</FormRow>
      </ExpandFormRow>
    </Searchable>
  );
}

const avoidSegmentsPlugin = {
  parseUrl: ({ parsedParams, postData = {}, options = {} }) => {
    const res = {};
    if (parsedParams[key] != null) {
      res[key] = parsedParams[key];
      res.avoidSegmentsIsPost = false;
      delete parsedParams[key];
    } else if (get(postData, 'avoid.segments', []).length > 0) {
      res[key] = get(postData, 'avoid.segments', []).join(',');
      res.avoidSegmentsIsPost = true;
    } else {
      res.avoidSegmentsIsPost = !!options.onlyPost;
    }

    return res;
  },
  defaultState: (options = {}) => ({
    'avoid[segments]': '',
    avoidSegmentsIsPost: !!options.onlyPost,
  }),
  getRequestOptions: (fields) => {
    const req = {};
    if (fields['avoid[segments]']) {
      if (fields.avoidSegmentsIsPost) {
        req.method = 'post';
        req.data = {
          avoid: {
            segments: fields['avoid[segments]'].split(','),
          },
        };
      } else {
        set(req, ['params', 'avoid[segments]'], fields['avoid[segments]']);
      }
    }
    return req;
  },
  Component: AvoidSegments,
};

export default avoidSegmentsPlugin;
