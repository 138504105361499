import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isConditionsSatisfied } from '../../utils';
import HereContextMenu from 'shared/hereMap/HereContextMenu';
import HereContextMenuItem from 'shared/hereMap/HereContextMenuItem';
import { withHereMap } from 'shared/hereMap/HereMapContext';
import mapCenterConfigs from './mapCenterConfigs';
import showInMap from './showInMap';
import { setIsOpenInShown, setIsAvoidExcludeShown } from 'state/ui/reducer';
import { fetchData } from 'state/tabs/tab/response/actions';

class ContextMenu extends React.Component {
  onClick = (callback) => (e) => {
    const { bounds: { zoom } } = this.props;
    const { latLng, contextMenuEvent } = e;
    const { map } = this.props;
    const xyToLatLng = ({ x, y }) => map.screenToGeo(x, y);

    if (contextMenuEvent.target instanceof window.H.map.Marker) {
      const position = contextMenuEvent.target.getGeometry();
      const latLng = { lat: position.lat, lng: position.lng };
      const screenPosition = map.geoToScreen(latLng);
      return callback({
        latLng,
        xy: { x: screenPosition.x, y: screenPosition.y },
        xyToLatLng,
        zoom
      });
    }

    const { viewportX: x, viewportY: y } = contextMenuEvent;
    return callback({
      latLng,
      xy: { x, y },
      xyToLatLng,
      zoom
    });
  };

  render() {
    const {
      selectedTab,
      tabIndex,
      fields,
      refClient: { formatterPlugin, mapPlugin },
      addTab,
      setFields,
      setNotification,
      settings,
      setIsOpenInShown,
      setIsAvoidExcludeShown,
      request
    } = this.props;
    if (selectedTab !== tabIndex) {
      return null;
    }

    const { items } = this.props.options;
    const itemEls = [...items, mapCenterConfigs, showInMap].map((item) => {
      const { type, options = {} } = item;
      if (!isConditionsSatisfied(this.props, options.conditions)) {
        return null;
      }

      const contextMenuItem = mapPlugin.get(type);
      const { text, callback } = contextMenuItem({
        options,
        fields,
        formatterPlugin,
        addTab,
        setFields,
        setNotification,
        settings,
        setIsOpenInShown,
        setIsAvoidExcludeShown,
        request
      });

      return (
        <HereContextMenuItem
          key={text}
          label={text}
          onClick={this.onClick(callback)}
        />
      );
    });
    return <HereContextMenu>{itemEls}</HereContextMenu>;
  }
}

const mapDispatchToProps = {
  setIsOpenInShown,
  setIsAvoidExcludeShown,
  request: fetchData
};

function mapStateToProps() {
  return {};
}

ContextMenu.propTypes = {
  options: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default withHereMap(connect(mapStateToProps, mapDispatchToProps)(ContextMenu));
