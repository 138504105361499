import React, { useEffect, useRef } from 'react';
import {
  getDecoder,
  getHorizontalTileIndex,
  getVerticalTileIndex,
  partitionFromXY,
  getOlpSettings,
  getPartitionId,
} from 'ref-client-core/core/formViewsPlugins/inspectSegment/helpers';
import { VersionedLayerClient } from '@here/olp-sdk-dataservice-read';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import {
  CS_HRN,
  DECODER_SCHEMA_HRN,
  getChargingStations,
  getPartitions,
  useIcon,
} from './helpers';
import StationMarker from './StationMarker';
import { Pane } from 'react-leaflet';
import { CHARGING_STATION_TABS } from './constants';
import { Buffer } from 'buffer';

function partitionsInBBox([[top, right], [bottom, left]], level) {
  const step = 360 / 2 ** level;
  const leftIndex = getHorizontalTileIndex(+left, step);
  const rightIndex = getHorizontalTileIndex(+right, step);
  const topIndex = getVerticalTileIndex(+top, step);
  const bottomIndex = getVerticalTileIndex(+bottom, step);
  const partitions = [];
  for (let x = leftIndex; x <= rightIndex; x++) {
    for (let y = bottomIndex; y <= topIndex; y++) {
      partitions.push(partitionFromXY(x, y, level));
    }
  }
  return partitions;
}

function partitionsFromPoints(points, level) {
  const res = new Set();
  let latLngs = [];
  try {
    latLngs = JSON.parse(points);
  } catch (e) {
    // Ignore invalid JSON
  }

  latLngs.forEach((point) => {
    res.add(getPartitionId(point, level));
  });

  return [...res.values()];
}

function ChargingStations(type) {
  return function ({
    bounds,
    fields,
    setFields,
    setResultState,
    result,
    colorPalette,
    settings,
    setNotification,
  }) {
    const { bbox, version, inputType, points } = fields;
    const {
      state: { partitions, data = [], selectedStation },
    } = result;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prevProps = useRef({ version }).current;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const icons = useIcon(colorPalette);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (
        (!bbox && inputType === CHARGING_STATION_TABS.BBOX) ||
        (points.length === 2 &&
          inputType === CHARGING_STATION_TABS.ALONG_THE_ROUTE)
      ) {
        return;
      }
      const visiblePartitions =
        inputType === CHARGING_STATION_TABS.ALONG_THE_ROUTE
          ? partitionsFromPoints(points, 12)
          : partitionsInBBox(bbox, 12);
      getOlpSettings(settings).then((settings) => {
        const clientData = {
          catalogHrn: CS_HRN,
          layerId: 'charging-stations',
          settings,
        };
        if (version) {
          clientData.version = +version;
        }

        const versionedLayerClient = new VersionedLayerClient(clientData);
        if (!partitions || prevProps.version !== version) {
          prevProps.version = version;
          setFields({ isLoading: true });
          getPartitions(versionedLayerClient, version)
            .then(async ({ partitions }) => {
              const partitionObj = partitions.reduce(
                (acc, { partition, version }) => {
                  acc[partition] = version;
                  return acc;
                },
                {}
              );
              const data = await loadData(partitionObj);
              setFields({ isLoading: false });
              setResultState({
                isResultPanelShown: true,
                data,
                partitions: partitionObj,
                selectedStation: null,
              });
            })
            .catch((err) => {
              setFields({ isLoading: false });
              setNotification({
                message: err.message,
                impact: 'negative',
              });
            });
        } else {
          setFields({ isLoading: true });
          loadData(partitions).then((data) => {
            setFields({ isLoading: false });
            setResultState({
              isResultPanelShown: true,
              data,
              selectedStation: null,
            });
          });
        }
        async function loadData(partitionObj) {
          const decoder = await getDecoder(DECODER_SCHEMA_HRN, settings);
          const arrayBuffers = await Promise.all(
            visiblePartitions
              .map((partition) => {
                if (!partitionObj[partition]) {
                  return null;
                }
                return getChargingStations(
                  versionedLayerClient,
                  partition,
                  partitionObj[partition]
                );
              })
              .filter(Boolean)
          );
          const rawData = arrayBuffers.map((arrayBuffer) => {
            const message = decoder.decode(Buffer.from(arrayBuffer));
            return decoder.toObject(message, {
              defaults: true,
              enums: String,
            });
          });

          return rawData.map((partitionData) => {
            const stations = partitionData.stations.reduce((acc, station) => {
              if (!acc[station.identifier]) {
                acc[station.identifier] = [];
              }
              acc[station.identifier].push(station);

              return acc;
            }, {});
            return {
              ...partitionData,
              stations,
            };
          });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bbox, version, inputType]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!bbox && bounds.top) {
        const { top, right, bottom, left } = bounds;
        const topRight = [top, right].map((coord) => coord.toFixed(6));
        const bottomLeft = [bottom, left].map((coord) => coord.toFixed(6));
        setFields({ bbox: [topRight, bottomLeft] });
      }
    });

    const markers = [];

    data.forEach(({ stations }, dataIndex) => {
      Object.keys(stations).forEach((identifier) => {
        const { position } = stations[identifier][0];
        const isSelected =
          selectedStation &&
          dataIndex === selectedStation.dataIndex &&
          identifier === selectedStation.id;
        const icon = icons[type][isSelected ? 'selectedIcon' : 'icon'];
        markers.push(
          <StationMarker
            key={identifier}
            id={identifier}
            data={data}
            icon={icon}
            position={position}
            dataIndex={dataIndex}
            setResultState={setResultState}
            type={type}
          />
        );
      });
    });
    return type === TILE_LIBRARY_TYPES.LEAFLET ? (
      <Pane style={{ zIndex: 500 }}>{markers}</Pane>
    ) : (
      markers
    );
  };
}

const chargingStationsMapPlugin = {
  [TILE_LIBRARY_TYPES.LEAFLET]: ChargingStations(TILE_LIBRARY_TYPES.LEAFLET),
  [TILE_LIBRARY_TYPES.JSLA]: ChargingStations(TILE_LIBRARY_TYPES.JSLA),
};

export default chargingStationsMapPlugin;
