import axios from 'axios';

export default function fetch(reqOptions = {}) {
  const start = new Date().getTime();
  const { formData, data } = reqOptions;

  // convert to form data if form is sent
  if (formData === true && data) {
      const bodyFormData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        bodyFormData.append(key, value);
      }
      reqOptions.data = bodyFormData;
  } else if (formData && data) {
    reqOptions.data = data[formData];
  }
  return axios(reqOptions)
    .then((res) => {
      const { data, headers } = res;
      const additionalData = {
        clientTime: new Date().getTime() - start,
      };
      if (headers && headers['x-irt']) {
        additionalData.serverTime = +headers['x-irt'];
      }
      if (headers && headers['x-ra']) {
        additionalData.algorithm = headers['x-ra'];
      }

      return { isError: false, data, statusCode: res.status, additionalData };
    })
    .catch((e) => {
      const { response } = e;
      if (response) {
        const { data } = response;
        return { isError: true, data, statusCode: response.status };
      } else if (axios.isCancel(e)) {
        return { isError: true, data: 'Request aborted', statusCode: 0 };
      }
      return { isError: true, data: 'Server unavailable.', statusCode: 0 };
    });
}

export function xmlErrorParse(data) {
  let regexp = /<Details>(.*)<\/Details>/;
  let found = data.match(regexp);
  return found[1] || data;
}
