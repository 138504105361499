function callback(e, setIsOpenInShown) {
  const { zoom, latLng } = e;
  const { lat, lng } = latLng;
  setIsOpenInShown({
    shown: true,
    openIn: { lat, lng, zoom }
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ setIsOpenInShown }) {
  return {
    text: 'View position in ...',
    callback: (e) => callback(e, setIsOpenInShown),
  };
}
