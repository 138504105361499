import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';
import { addEventListeners, removeEventListeners } from './mapEvents';

const DRAG_STATES = {
  RESIZE_TOP: 1,
  RESIZE_LEFT: 2,
  RESIZE_BOTTOM: 3,
  RESIZE_RIGHT: 4,
  RESIZE_TOP_LEFT: 5,
  RESIZE_TOP_RIGHT: 6,
  RESIZE_BOTTOM_LEFT: 7,
  RESIZE_BOTTOM_RIGHT: 8,
  DRAG: 9,
};

class HereRect extends React.Component {
  rect = null;

  componentDidMount() {
    this.addRect();
  }

  componentDidUpdate(prevProps) {
    const { latLngBounds, options, draggable, resizable } = this.props;

    if (
      !isEqual(latLngBounds, prevProps.latLngBounds) ||
      !isEqual(options, prevProps.options) ||
      draggable !== prevProps.draggable ||
      resizable !== prevProps.resizable
    ) {
      this.removeRect();
      this.addRect();
    }
  }

  componentWillUnmount() {
    this.removeRect();
  }

  onPointerMove = (targetData) => {
    const { map, draggable, resizable, resizePadding } = this.props;
    const {
      currentPointer: { viewportX, viewportY },
    } = targetData;
    const geoToScreen = (point) => map.geoToScreen(point);
    const point = new window.H.math.Point(viewportX, viewportY);
    let bounds = this.rect.getBoundingBox();
    let cursorStyle = 'move';
    let topRight = geoToScreen(
      new window.H.geo.Point(bounds.getTop(), bounds.getRight())
    );
    let bottomLeft = geoToScreen(
      new window.H.geo.Point(bounds.getBottom(), bounds.getLeft())
    );
    let padding = resizePadding || 10;

    if (resizable) {
      if (geoToScreen(bounds.getTopLeft()).distance(point) < padding) {
        this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
        cursorStyle = 'nwse-resize';
      } else if (topRight.distance(point) < padding) {
        this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
        cursorStyle = 'nesw-resize';
      } else if (
        geoToScreen(bounds.getBottomRight()).distance(point) < padding
      ) {
        this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
        cursorStyle = 'nwse-resize';
      } else if (bottomLeft.distance(point) < padding) {
        this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
        cursorStyle = 'nesw-resize';
      } else if (Math.abs(topRight.y - point.y) < padding) {
        this.dragState = DRAG_STATES.RESIZE_TOP;
        cursorStyle = 'ns-resize';
      } else if (Math.abs(bottomLeft.y - point.y) < padding) {
        this.dragState = DRAG_STATES.RESIZE_BOTTOM;
        cursorStyle = 'ns-resize';
      } else if (Math.abs(bottomLeft.x - point.x) < padding) {
        this.dragState = DRAG_STATES.RESIZE_LEFT;
        cursorStyle = 'ew-resize';
      } else if (Math.abs(topRight.x - point.x) < padding) {
        this.dragState = DRAG_STATES.RESIZE_RIGHT;
        cursorStyle = 'ew-resize';
      } else if (draggable) {
        this.dragState = DRAG_STATES.DRAG;
      } else {
        cursorStyle = 'auto';
        if (!draggable) {
          this.dragState = null;
        }
      }
    } else if (draggable) {
      this.dragState = DRAG_STATES.DRAG;
    }
    map.getElement().style.cursor = cursorStyle;
  };

  onDragStart = (e) => {
    if (!this.dragState) {
      return;
    }

    const { map } = this.props;
    const {
      currentPointer: { viewportX, viewportY },
    } = e;
    const { lat, lng } = map.screenToGeo(viewportX, viewportY);
    this.mouseStart = { lat, lng };
    this.boundsStart = this.rect.getBoundingBox();
    e.stopPropagation();
  };

  onDrag = (e) => {
    if (!this.dragState) {
      return;
    }
    const { map } = this.props;
    const { currentPointer } = e;
    const { lat, lng } = map.screenToGeo(
      currentPointer.viewportX,
      currentPointer.viewportY
    );
    let top = this.boundsStart.getTop();
    let right = this.boundsStart.getRight();
    let bottom = this.boundsStart.getBottom();
    let left = this.boundsStart.getLeft();

    switch (this.dragState) {
      case DRAG_STATES.RESIZE_TOP: {
        top = lat;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM: {
        bottom = lat;
        break;
      }
      case DRAG_STATES.RESIZE_LEFT: {
        left = lng;
        break;
      }
      case DRAG_STATES.RESIZE_RIGHT: {
        right = lng;
        break;
      }
      case DRAG_STATES.RESIZE_TOP_LEFT: {
        top = lat;
        left = lng;
        break;
      }
      case DRAG_STATES.RESIZE_TOP_RIGHT: {
        top = lat;
        right = lng;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_RIGHT: {
        bottom = lat;
        right = lng;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_LEFT: {
        bottom = lat;
        left = lng;
        break;
      }
      default: {
        const deltaLat = this.mouseStart.lat - lat;
        const deltaLng = this.mouseStart.lng - lng;
        top -= deltaLat;
        right -= deltaLng;
        bottom -= deltaLat;
        left -= deltaLng;
        break;
      }
    }

    this.rect.setBoundingBox(
      latLngBoundsToGeoRect([
        { lat: top, lng: right },
        { lat: bottom, lng: left },
      ])
    );
    e.stopPropagation();
  };

  addRect = () => {
    const { latLngBounds, options, map, group, draggable, resizable } =
      this.props;
    const bounds = latLngBoundsToGeoRect(latLngBounds);
    this.rect = new window.H.map.Rect(bounds, options);
    addEventListeners(this.props, this.rect);
    if (group) {
      group.addObject(this.rect);
    } else {
      map.addObject(this.rect);
    }

    if (draggable || resizable) {
      this.rect.draggable = true;
      this.initInteractionEvents();
    }
  };

  removeRect = () => {
    const { group } = this.props;
    removeEventListeners(this.props, this.rect);
    if (this.rect && group && group.contains(this.rect)) {
      group.removeObject(this.rect);
    }
  };

  initInteractionEvents = () => {
    this.rect.addEventListener('dragstart', this.onDragStart);
    this.rect.addEventListener('drag', this.onDrag);
    this.rect.addEventListener('dragend', this.onDragEnd);

    this.rect.addEventListener('pointermove', this.onPointerMove);
    this.rect.addEventListener('pointerleave', this.onPointerLeave);
  };

  onDragEnd = () => {
    if (!this.dragState) {
      return;
    }
    const { onChange } = this.props;

    if (onChange) {
      onChange(this.rect.getBoundingBox());
    }
  };

  onPointerLeave = () => {
    const { map } = this.props;
    map.getElement().style.cursor = 'auto';
  };

  render() {
    return null;
  }
}

HereRect.propTypes = {
  latLngBounds: PropTypes.arrayOf(PropTypes.object).isRequired,
  map: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  resizePadding: PropTypes.number,
  onChange: PropTypes.func,
};

export default withHereMap(HereRect);

function latLngBoundsToGeoRect(latLngBounds) {
  const [{ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }] = latLngBounds;
  const top = Math.max(lat1, lat2);
  const bottom = Math.min(lat1, lat2);
  const right = Math.max(lng1, lng2);
  const left = Math.min(lng1, lng2);
  return new window.H.geo.Rect(top, left, bottom, right);
}
