import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormRow, ExpandFormRow } from '@here/ref-client-ui';
import './olsPtSummary.scss';
import moment from 'moment';
import { get } from 'lodash';
import { sectionTransportSummary } from "../../../utils/publicTransit";

class OlsPtSummary extends React.Component {
  render() {
    const {
      result,
      refClient: { formatterPlugin },
    } = this.props;
    const currentRoute = result.state.currentRoute || 0;
    const routes = get(result, `raw.data.routes`, []);
    const route = routes[currentRoute] || {};
    const sections = route.sections || [];

    let totalDuration = 0;
    let totalLength = 0;

    const travelSummary = get(sections[0], 'travelSummary');

    if (!travelSummary) {
      return (
        <div className="rf-empty">
          No summary information was requested from server.
          <br />
          In order to fetch this information, please select
          <br />
          <b>Return options -&gt; Travel Summary</b>
        </div>
      );
    }

    const sectionsEls = sections.map((section, index) => {
      const { duration, length } = get(section, 'travelSummary');
      totalDuration += duration;
      totalLength += length;
      const label = `${index + 1} - ${sectionTransportSummary(section)}`;
      const departureTime = get(section, 'departure.time');
      const arrivalTime = get(section, 'arrival.time');

      const notices = (section.notices || []).map((notice, index) => {
        const inputs = Object.keys(notice).map((key) => (
          <FormRow key={key}>
            <Input label={key} isReadonly value={notice[key]} />
          </FormRow>
        ));

        return (
          <FormRow className="rf-section-notice__item" key={index}>
            {inputs}
          </FormRow>
        );
      });

      return (
        <ExpandFormRow label={label} key={label}>
          {notices.length > 0 && (
            <FormRow className="rf-section-notice">
              <h5>Notices:</h5>
              <div>{notices}</div>
            </FormRow>
          )}
          <FormRow>
            <Input
              type="text"
              label="Duration"
              isReadonly
              value={formatterPlugin.get('time')(duration)}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Departure"
              isReadonly
              value={moment(departureTime).format('HH:mm:ss')}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Arrival"
              isReadonly
              value={moment(arrivalTime).format('HH:mm:ss')}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Length"
              isReadonly
              value={formatterPlugin.get('distance')(length)}
            />
          </FormRow>
        </ExpandFormRow>
      );
    });

    return (
      <div className="rf-ols-summary">
        <ExpandFormRow
          label="Total"
          isExpanded
          className="rf-ols-summary__total"
        >
          <FormRow>
            <Input
              type="text"
              label="Total Duration"
              isReadonly
              value={formatterPlugin.get('time')(totalDuration)}
            />
          </FormRow>
          <FormRow>
            <Input
              type="text"
              label="Total Length"
              isReadonly
              value={formatterPlugin.get('distance')(totalLength)}
            />
          </FormRow>
        </ExpandFormRow>
        {sectionsEls}
      </div>
    );
  }
}

OlsPtSummary.propTypes = {
  fields: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default OlsPtSummary;
