import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@here/ref-client-ui';

import './styles/uiZoom.scss';
import { UI_SIZE } from '../../state/appSettings/constants';

const SIZE_TO_PIXELS = {
  [UI_SIZE.SMALL]: 7.5,
  [UI_SIZE.MEDIUM]: 10,
  [UI_SIZE.LARGE]: 15,
};

const OPTIONS = [
  { label: 'Small', value: UI_SIZE.SMALL.toString() },
  { label: 'Medium', value: UI_SIZE.MEDIUM.toString() },
  { label: 'Large', value: UI_SIZE.LARGE.toString() },
];

class UIZoom extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      UIZoom.applySize(nextProps.value);
    }

    return {
      value: nextProps.value,
    };
  }

  static sizeToPixels(uiSize) {
    return SIZE_TO_PIXELS[uiSize] || UI_SIZE.MEDIUM;
  }

  static applySize(uiSize) {
    document.documentElement.style.fontSize = `${UIZoom.sizeToPixels(
      uiSize
    )}px`;
  }

  state = {
    value: null,
  };

  onChange = (evt) => {
    this.props.setSettings({ uiSize: +evt.target.value });
  };

  render() {
    return (
      <div className="rf-app-zoom">
        <span className="rf-app-zoom__caption">UI Size:</span>
        <Select
          options={OPTIONS}
          value={this.props.value.toString()}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

UIZoom.propTypes = {
  value: PropTypes.number.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default UIZoom;
