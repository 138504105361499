import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import './styles/shareContainer.scss';
import copyToClipboard from 'copy-to-clipboard';
import { getTabs } from 'state/tabs';
import * as NotificationActions from 'state/notification/actions';
import { bindActionCreators } from 'redux';
import { getDefaultConfigs } from '../../state/config/default/reducer';
import { getAllSettings } from '../../state/settings';
import { merge } from 'lodash';
import formViewsPlugin from '../../core/formViewsPlugin';
import settingsPlugin from '../../core/settingsPlugin';
import { Tooltip, TabsControl } from '@here/ref-client-ui';
import refClient from '../../core/refClient';

const shareMethods = {
  currentTab: 0,
  activeTabs: 1,
  allTabs: 2,
};

const shareMethodTitles = {
  [shareMethods.currentTab]: 'Current tab',
  [shareMethods.activeTabs]: 'Active tabs',
  [shareMethods.allTabs]: 'All tabs',
};

const omit = (obj, arr) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));

class ShareContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showMenu: false,
      shareMethod: shareMethods.allTabs,
    };
    this.onHidePopup = this.onHidePopup.bind(this);
    this.shareContainerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onHidePopup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onHidePopup, false);
  }

  onHidePopup(e) {
    if (
      this.state.showMenu &&
      !this.shareContainerRef.current.contains(e.target)
    ) {
      this.setState({
        showMenu: false,
      });
    }
  }

  onShare = () => {
    copyToClipboard(this.getShareUrl());
    this.props.notify({
      message: 'Url was copied to clipboard',
      impact: 'info',
      autoDismiss: 2,
    });
    this.setState({
      showMenu: false,
    });
  };

  onShareMethodChange = (shareMethod) => {
    this.setState({ shareMethod });
  };

  getShareUrl() {
    const { selectedTab, defaultConfigs, allSettings } = this.props;
    const selectedTabObj = this.props.tabs[selectedTab];
    const tabUrls = this.props.tabs
      .filter((tab, index) => {
        if (this.state.shareMethod === shareMethods.allTabs) {
          return true;
        } else if (
          this.state.shareMethod === shareMethods.activeTabs &&
          tab.isActive
        ) {
          return true;
        } else if (
          this.state.shareMethod === shareMethods.currentTab &&
          index === this.props.selectedTab
        ) {
          return true;
        }
        return false;
      })
      .map((tab) => {
        const { title, tabTitle, tabColorPalette, isActive, preset, fields } =
          tab;
        const config = defaultConfigs[title];
        const tabSettings = allSettings[title];
        if (!config || config.notShareable) {
          return null;
        }
        const data = {
          tabTitle,
          tabColorPalette,
          isActive,
          title,
          preset,
        };
        if (selectedTabObj === tab) {
          data.isSelected = true;
        }

        if (!config.settings[preset]) {
          data.settings = tabSettings[preset];
        }

        const fieldsRequestOptions = config.inputs.reduce((acc, input) => {
          try {
            const requestOptions = formViewsPlugin
              .get(input.type)
              .getRequestOptions(fields, input.options, refClient);
            return merge(acc, requestOptions);
          } catch (e) {
            return acc;
          }
        }, {});

        if (
          fieldsRequestOptions.method === 'post' &&
          Object.keys(fieldsRequestOptions.data || {}).length > 0
        ) {
          data.postData = fieldsRequestOptions.data;
        }
        
        if (
          fieldsRequestOptions.rcParams &&
          Object.keys(fieldsRequestOptions.rcParams || {}).length > 0
        ) {
          data.rcParams = fieldsRequestOptions.rcParams;
        }
        

        const { getDisplayUrl } = settingsPlugin.get(tabSettings[preset].type);
        data.url = encodeURIComponent(
          // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8
          // build process
          // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
          getDisplayUrl(tabSettings[preset], fieldsRequestOptions)
            .replaceAll(window.APIKEY, 'DEFAULT_API_KEY')
            .replaceAll(window.APIKEY2, 'DEFAULT_API_KEY')
        );
        if (data.url === "") {
          // share param in data params if url is not available
          data.fieldParams = omit(fields, ['isLoading', 'inspectSegmentData', 'layers', 'segmentId']);
        }
        return data;
      })
      .filter(Boolean);

    return `${window.location.origin}/#${encodeURIComponent(
      JSON.stringify(tabUrls)
    )}`;
  }

  getShareMenu() {
    const tooltip = (
      <div>
        To simplify url you can use&nbsp;
        <a
          href={window.SHORTENER_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          a shortener
        </a>
        &nbsp;service
      </div>
    );
    let tabsData = [
      shareMethods.currentTab,
      shareMethods.activeTabs,
      shareMethods.allTabs,
    ].map((shareMethod) => ({
      label: shareMethodTitles[shareMethod],
      name: shareMethod,
    }));
    return (
      <div className="rf-share-container__menu">
        <h3>Share Tabs</h3>
        <TabsControl
          currentTabName={this.state.shareMethod}
          onChange={this.onShareMethodChange}
          tabsData={tabsData}
        />
        <h4>Share Code</h4>
        <Tooltip tooltip={tooltip}>
          <lui-textfield>
            <textarea readOnly value={this.getShareUrl()} />
          </lui-textfield>
          <lui-button secondary onClick={this.onShare}>
            <i className="rf-share-container__menu__share-button" />
            Copy to clipboard
          </lui-button>
        </Tooltip>
      </div>
    );
  }

  toggleShareMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  render() {
    let iconClass = classnames('rf-share-container__icon', {
      'rf-share-container__icon_active': this.state.showMenu,
    });
    return (
      <div ref={this.shareContainerRef}>
        <div className="rf-share-container" onClick={this.toggleShareMenu}>
          <i className={iconClass} />
        </div>
        {this.state.showMenu && this.getShareMenu()}
      </div>
    );
  }
}

ShareContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  defaultConfigs: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  notify: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    tabs: getTabs(state),
    selectedTab: state.selectedTab,
    defaultConfigs: getDefaultConfigs(state),
    allSettings: getAllSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(NotificationActions.set, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareContainer);
