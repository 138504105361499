import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { latLngBounds } from 'leaflet';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';

function Rectangle(RectComponent) {
  return class Rectangle extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      setFields: PropTypes.func.isRequired,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
      paramsMapping: PropTypes.bool,
    };

    onChange = (bounds) => {
      const {
        options: { onChangeFormatter, key },
        setFields,
        fields,
        refClient: { formatterPlugin },
      } = this.props;

      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, bounds));
      } else if (key) {
        setFields({ [key]: bounds });
      }
    };

    render() {
      const {
        options: { path, showUnselected, color, draggable, resizable },
        selectedTab,
        tabIndex,
        paramsMapping,
      } = this.props;
      if (tabIndex !== selectedTab && !showUnselected) {
        return null;
      }

      let bounds = get(this.props, path, []);
      if (typeof bounds === 'string' && bounds.indexOf('bbox:')>-1) {
        const [ln1, lt1, ln2, lt2] = bounds.split(':')[1].split(',');
        bounds = [[lt1, ln1], [lt2, ln2]];
      }
      let [[lat1, lng1] = [], [lat2, lng2] = []] = bounds;

      if (!bounds.length) {
        const keys = path.split('.');
        const {north, south, east, west} = this.props[keys[0]][keys[1]] || {};
        if(north && south && east && west) {
          bounds = [[north, east],[south, west]];
          [[lat1, lng1] = [], [lat2, lng2] = []] = bounds;
        }
      }

      if (
        !validateLat(lat1) ||
        !validateLon(lng1) ||
        !validateLat(lat2) ||
        !validateLon(lng2) ||
        !latLngBounds(bounds).isValid()
      ) {
        return null;
      }

      return (
        <RectComponent
          bounds={bounds}
          onChange={this.onChange}
          color={color}
          draggable={draggable && paramsMapping}
          resizable={resizable && paramsMapping}
        />
      );
    }
  };
}

export default Rectangle;
