import React from 'react';
import { Button } from '@here/ref-client-ui';
import ViaField from './ViaField';
import isArray from 'lodash/isArray';
import './viaFields.scss';
import PluginWithTextArea from '../../sharedUI/pluginWithTextarea';

function ViaFields({ fields, setFields }) {
  const { via = [] } = fields;

  const onChange = (via) => {
    setFields({ via });
  };

  const onAddItem = () => {
    setFields({ via: [...via, { index: '', stopDuration: '' }] });
  };

  const onChangeItem = (item, index) => {
    setFields({ via: [...via.slice(0, index), item, ...via.slice(index + 1)] });
  };

  const onRemoveItem = (index) => {
    setFields({ via: [...via.slice(0, index), ...via.slice(index + 1)] });
  };

  const items = via.map((via, index) => (
    <ViaField
      key={index}
      index={index}
      via={via}
      onChange={onChangeItem}
      onRemove={onRemoveItem}
    />
  ));

  return (
    <PluginWithTextArea
      label="Via"
      onChange={onChange}
      value={via.map(({ index, stopDuration }) => {
        const res = { index: +index };
        if (stopDuration) {
          res.stopDuration = +stopDuration;
        }
        return res;
      })}
      className="rf-via-fields"
    >
      <>
        {items}
        <Button title="Add Via" onClick={onAddItem} />
      </>
    </PluginWithTextArea>
  );
}

const viaFieldsPlugin = {
  parseUrl: ({ postData = {} }) => {
    if (postData.via && isArray(postData.via)) {
      return { via: postData.via };
    }
    return { via: [] };
  },
  defaultState: () => ({ via: [] }),
  getRequestOptions: (fields) => {
    const { via } = fields;
    if (via && via.length > 0) {
      return {
        data: {
          via: via.map(({ index, stopDuration }) => {
            const res = { index: +index };
            if (stopDuration) {
              res.stopDuration = +stopDuration;
            }
            return res;
          }),
        },
      };
    }
    return {};
  },
  Component: ViaFields,
};

export default viaFieldsPlugin;
