import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select, Input, FormRow, Tooltip } from '@here/ref-client-ui';
import defaultState from 'state/map/tiles/defaultState';

import config from 'config/tiles';
import { TILE_TYPES } from '../../state/map/tiles/constants';
import { isEqual } from 'lodash';

const rasterDefaultState = defaultState[TILE_TYPES.RASTER];

export default class RasterTilesForm extends Component {
  onChange = (key, e) => {
    this.props.onChange({ [key]: e.target.value });
  };

  resetSettings = () => {
    this.props.onChange(rasterDefaultState);
  };

  onChangeApiKey = ({ target: { value } }) => {
    const { onChange } = this.props;
    const apiKey = value === window.APIKEY ? 'DEFAULT_API_KEY' : value;
    onChange({ apiKey });
  };

  render() {
    let {
      isInternal,
      type,
      scheme,
      size,
      format,
      lg,
      pview,
      additionalParameters,
      url,
      apiKey,
      appId,
      appCode,
    } = this.props;
    let availableSchemes = config.scheme.filter(
      (item) => item.supportedTypes.indexOf(type) !== -1
    );
    const canResetSettings = !isEqual(rasterDefaultState, {
      type,
      scheme,
      size,
      format,
      lg,
      pview,
      additionalParameters,
      url,
      apiKey,
      appCode,
      appId,
    });
    const appIdAppCodeMessage =
      'You have to remove Api Key if you want to use App Id, App Code params.';

    return (
      <div>
        <h2>Raster Map Tiles</h2>
        <FormRow>
          <Input
            cssClasses="rf-clearfix"
            label="Base Url"
            placeholder="Base Url"
            isValid
            value={url}
            onBlur={this.onChange.bind(this, 'url')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Select
            label="Type"
            options={config.type}
            value={type}
            onChange={this.onChange.bind(this, 'type')}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Scheme"
            options={availableSchemes}
            value={scheme}
            onChange={this.onChange.bind(this, 'scheme')}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Size"
            options={config.size}
            value={size}
            onChange={this.onChange.bind(this, 'size')}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Format"
            options={config.format}
            value={format}
            onChange={this.onChange.bind(this, 'format')}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Language"
            options={config.languages}
            value={lg}
            onChange={this.onChange.bind(this, 'lg')}
          />
        </FormRow>
        {isInternal && (<FormRow>
          <Select
            label="Polical View"
            options={config.politicalView}
            value={pview}
            onChange={this.onChange.bind(this, 'pview')}
          />
        </FormRow>
        )}
        <FormRow>
          <Input
            label="Api Key"
            value={apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey}
            onBlur={this.onChangeApiKey}
            blurOnEnter
          />
        </FormRow>
        <Tooltip tooltip={appIdAppCodeMessage}>
          <FormRow>
            <Input
              label="App Id"
              value={appId}
              onBlur={this.onChange.bind(this, 'appId')}
              blurOnEnter
            />
          </FormRow>
        </Tooltip>
        <Tooltip tooltip={appIdAppCodeMessage}>
          <FormRow>
            <Input
              label="App Code"
              value={appCode}
              onBlur={this.onChange.bind(this, 'appCode')}
              blurOnEnter
            />
          </FormRow>
        </Tooltip>
        <FormRow>
          <Input
            cssClasses="rf-clearfix"
            label="Additional Parameters"
            placeholder="param1=value&param2=value..."
            isValid
            value={additionalParameters}
            onBlur={this.onChange.bind(this, 'additionalParameters')}
            blurOnEnter
          />
        </FormRow>
        {canResetSettings && (
          <FormRow>
            <lui-button secondary onClick={this.resetSettings}>
              Reset Defaults
            </lui-button>
          </FormRow>
        )}
      </div>
    );
  }
}

RasterTilesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  scheme: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  appCode: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  lg: PropTypes.string.isRequired,
  pview: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  additionalParameters: PropTypes.string.isRequired,
};
