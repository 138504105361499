import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Button, Select } from '@here/ref-client-ui';
import ResultModal from './ResultModal';

const LOCAL_STORAGE_KEY = '[ref-client] - Matrix Results';

class ResultFieldsStore extends React.Component {
  state = {
    isAddModalOpen: false,
    isEditModalOpen: false,
    storedResults: getStoredResults(),
  };

  componentDidMount() {
    const { fields } = this.props;
    const { matrixResultUrl, origins } = fields;
    if (matrixResultUrl && origins) {
      this.setState({
        storedResults: updateStoredResults(fields, this.state.storedResults),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { fields } = this.props;
    const { matrixResultUrl, origins } = fields;
    if (prevProps.fields.matrixResultUrl !== matrixResultUrl && origins) {
      this.setState({
        storedResults: updateStoredResults(fields, this.state.storedResults),
      });
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    const { setFields } = this.props;
    const storedResult = this.state.storedResults.find(
      (res) => res.matrixResultUrl === value
    );
    setFields({
      matrixResultUrl: value,
      origins: storedResult.origins,
      destinations: storedResult.destinations,
    });
  };

  openModal = () => {
    this.setState({ isAddModalOpen: true });
  };

  openEditModal = () => {
    this.setState({ isEditModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isAddModalOpen: false, isEditModalOpen: false });
  };

  onModalChange = (data) => {
    this.setState((prevState) => ({
      storedResults: updateStoredResults(data, prevState.storedResults),
    }));
  };

  render() {
    const { isAddModalOpen, isEditModalOpen } = this.state;
    const { fields } = this.props;
    const { storedResults } = this.state;
    const options = storedResults.map((storedResult) => ({
      label: storedResult.name,
      value: storedResult.matrixResultUrl,
    }));
    const isShown = isAddModalOpen || isEditModalOpen;
    const modalProps = {};

    if (isEditModalOpen) {
      const storedResult = storedResults.find(
        (res) => res.matrixResultUrl === fields.matrixResultUrl
      );
      modalProps.name = storedResult.name;
      modalProps.url = storedResult.matrixResultUrl;
      modalProps.origins = storedResult.origins;
      modalProps.destinations = storedResult.destinations;
    }

    return (
      <>
        <FormRow>
          <Select
            value={fields.matrixResultUrl}
            label="Stored Results"
            onChange={this.onChange}
            options={options}
            addEmptyOption
          />
        </FormRow>
        <FormRow>
          {fields.matrixResultUrl && (
            <Button title="Edit Result" onClick={this.openEditModal} />
          )}
          <Button title="Add Result" onClick={this.openModal} />
          {isShown && (
            <ResultModal
              title="Add result"
              isShown={isShown}
              onChange={this.onModalChange}
              onClose={this.closeModal}
              {...modalProps}
            />
          )}
        </FormRow>
      </>
    );
  }
}

ResultFieldsStore.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: ResultFieldsStore,
};

function getStoredResults() {
  const str = localStorage.getItem(LOCAL_STORAGE_KEY) || '[]';
  try {
    return JSON.parse(str);
  } catch (e) {
    return [];
  }
}

function updateStoredResults(data, storedResults) {
  const { matrixResultUrl, origins, destinations, name } = data;
  const storeIndex = storedResults.findIndex(
    (storedResult) =>
      storedResult.matrixResultUrl === matrixResultUrl &&
      storedResult.origins === origins &&
      storedResult.destinations === destinations
  );

  let newStoredResults;
  if (storeIndex === -1) {
    const urlParts = matrixResultUrl.split('/');
    newStoredResults = [
      {
        name: name || urlParts[urlParts.length - 1],
        matrixResultUrl,
        origins,
        destinations,
      },
      ...storedResults,
    ].slice(0, 10);
  } else {
    newStoredResults = [
      {
        name: name || storedResults[storeIndex].name,
        matrixResultUrl,
        origins,
        destinations,
      },
      ...storedResults.slice(0, storeIndex),
      ...storedResults.slice(storeIndex + 1),
    ];
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newStoredResults));

  return newStoredResults;
}
