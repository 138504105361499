import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import OlsIsolineMap from './OlsIsolineMap';
import { Polyline } from 'react-leaflet';
import HerePolygon from './HerePolygon';
import HereRoute from '../routes/HereRoute';
import LeafletPolygon from './LeafletPolygon';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: OlsIsolineMap(LeafletPolygon, Polyline),
  [TILE_LIBRARY_TYPES.JSLA]: OlsIsolineMap(HerePolygon, HereRoute),
};
