import { latLng } from 'leaflet';
import get from 'lodash/get';
import omit from 'lodash/omit';

function navigationAttrParser(topologyData, layerData) {
  const { parsed } = topologyData;
  const segmentAnchors = layerData.segment_anchor;
  const res = {};
  const latLngDist = [];
  let totalDist = 0;
  parsed.geometry && parsed.geometry.forEach(({ latitude, longitude }, index) => {
    if (index === 0) {
      latLngDist.push({ latitude, longitude, dist: 0 });
      return;
    }
    if (index === parsed.geometry.length - 1) {
      latLngDist.push({ latitude, longitude, dist: parsed.length });
      return;
    }
    const { latitude: prevLat, longitude: prevLng } =
      parsed.geometry[index - 1];
    totalDist += latLng([latitude, longitude]).distanceTo([prevLat, prevLng]);
    latLngDist.push({
      latitude,
      longitude,
      dist: totalDist,
    });
  });
  segmentAnchors.forEach((anchor, index) => {
    if (
      anchor.oriented_segment_ref.find(
        (ref) => ref.segment_ref.identifier === parsed.identifier
      )
    ) {
      const startRange = get(anchor, 'first_segment_start_offset.value');
      const endRange = get(anchor, 'last_segment_end_offset.value');
      const attribute_orientation = get(anchor, 'attribute_orientation');
      let startLength = 0;
      if (startRange) {
        startLength = parsed.length * startRange;
      }
      let endLength = parsed.length;
      if (endRange) {
        endLength = parsed.length * endRange;
      }
      const geometry = [];
      latLngDist.find(({ latitude, longitude, dist }, index) => {
        if (dist > startLength) {
          const prev = latLngDist[index - 1];
          geometry.push({ lat: prev.latitude, lng: prev.longitude });
        }
        if (endLength === dist) {
          geometry.push({ lat: latitude, lng: longitude });
        }
        return dist > endLength;
      });
      res[index] = { geometry, range: [startRange, endRange], segment_anchor: { attribute_orientation } };
    }
  });

  const finalRes = {};
  Object.keys(omit(layerData, ['partition_name', 'segment_anchor'])).forEach(
    (key) => {
      layerData[key].forEach((attrData) => {
        const segmentAnchorIndex = attrData.segment_anchor_index;
        segmentAnchorIndex.forEach((index) => {
          if (res[index]) {
            const { segment_anchor_index, lane_anchor_index, ...data } =
              attrData;
            if (!finalRes[index]) {
              finalRes[index] = {
                ...res[index],
              };
            }
            if (!finalRes[index][key]) {
              finalRes[index][key] = [];
            }
            finalRes[index][key].push(data);
          }
        });
      });
    }
  );
  return finalRes;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'topology-geometry': (segmentId, layerData) => {
    const segment = layerData.segment.find(
      (segmentObj) => segmentObj.identifier === segmentId
    );
    if (!segment) {
      return null;
    }
    const startNodeId = segment.start_node_ref.identifier;
    const endNodeId = segment.end_node_ref.identifier;
    let startNode;
    let endNode;
    layerData.node.find((nodeObj) => {
      if (nodeObj.identifier === startNodeId) {
        startNode = {
          identifier: nodeObj.identifier,
          geometry: nodeObj.geometry,
        };
      }
      if (nodeObj.identifier === endNodeId) {
        endNode = {
          identifier: nodeObj.identifier,
          geometry: nodeObj.geometry,
        };
      }
      if (segment.startNode && segment.endNode) {
        return true;
      }

      return false;
    });
    return {
      identifier: segment.identifier,
      geometry: segment.geometry.point,
      length: segment.length,
      startNode,
      endNode,
    };
  },
  'truck-attributes': (topologyData, layerData) => {
    const { parsed } = topologyData;
    const segmentAnchors = layerData.segment_anchor;
    const res = {};
    const latLngDist = [];
    let totalDist = 0;
    parsed.geometry && parsed.geometry.forEach(({ latitude, longitude }, index) => {
      if (index === 0) {
        latLngDist.push({ latitude, longitude, dist: 0 });
        return;
      }
      if (index === parsed.geometry.length - 1) {
        latLngDist.push({ latitude, longitude, dist: parsed.length });
        return;
      }
      const { latitude: prevLat, longitude: prevLng } =
        parsed.geometry[index - 1];
      totalDist += latLng([latitude, longitude]).distanceTo([prevLat, prevLng]);
      latLngDist.push({
        latitude,
        longitude,
        dist: totalDist,
      });
    });
    segmentAnchors.forEach((anchor, index) => {
      if (
        anchor.oriented_segment_ref.find(
          (ref) => ref.segment_ref.identifier === parsed.identifier
        )
      ) {
        const startRange = get(anchor, 'first_segment_start_offset.value');
        const endRange = get(anchor, 'last_segment_end_offset.value');
        const attribute_orientation = get(anchor, 'attribute_orientation');
        let startLength = 0;
        if (startRange) {
          startLength = parsed.length * startRange;
        }
        let endLength = parsed.length;
        if (endRange) {
          endLength = parsed.length * endRange;
        }
        const geometry = [];
        latLngDist.find(({ latitude, longitude, dist }, index) => {
          if (dist > startLength) {
            const prev = latLngDist[index - 1];
            geometry.push({ lat: prev.latitude, lng: prev.longitude });
          }
          if (endLength === dist) {
            geometry.push({ lat: latitude, lng: longitude });
          }
          return dist > endLength;
        });
        res[index] = { geometry, range: [startRange, endRange], segment_anchor: { attribute_orientation } };
      }
    });

    const finalRes = {};
    layerData.transport_access_restriction.forEach((restrictions) => {
      const segmentAnchorIndex = restrictions.segment_anchor_index;
      segmentAnchorIndex.forEach((index) => {
        if (res[index]) {
          const { segment_anchor_index, lane_anchor_index, ...data } =
            restrictions;
          if (!finalRes[index]) {
            finalRes[index] = {
              ...res[index],
              restrictions: [],
            };
          }
          finalRes[index].restrictions.push(data);
        }
      });
    });
    return finalRes;
  },
  'navigation-attributes': navigationAttrParser,
  'advanced-navigation-attributes': navigationAttrParser,
  'road-attributes': navigationAttrParser
};
