import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import axios from 'axios';
import { merge } from 'lodash';
import isHttpWithHttps, {
  isHttpWithHttpsNotification,
} from './isHttpWithHttps';

class DirectSettings extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isCustom: PropTypes.bool,
    setNotification: PropTypes.func,
  };

  onChange(key, { target: { value } }) {
    if (isHttpWithHttps(value)) {
      const { setNotification } = this.props;
      isHttpWithHttpsNotification(setNotification);
    }
    this.props.onChange({ [key]: value });
  }

  render() {
    const { url = '', isCustom } = this.props;

    return (
      <div className="rf-app-id-code-form">
        <FormRow>
          <Input
            type="text"
            label="Url"
            value={url}
            onBlur={this.onChange.bind(this, 'url')}
            isReadonly={!isCustom}
            blurOnEnter
            isCompact
          />
        </FormRow>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => {
    return {};
  },
  getDisplayUrl: (settings, fieldRequestOptions) => {
    const url = getUrl(settings, fieldRequestOptions);

    return decodeURIComponent(
      axios.getUri(merge({ url }, fieldRequestOptions))
    );
  },
  getRequestOptions: (settings, fieldParams) => ({
    url: getUrl(settings, fieldParams),
    responseType: settings.responseType || 'json',
    params: {},
  }),
  Component: DirectSettings,
};

function getUrl(settings, fieldRequestOptions) {
  const { pathParams = {} } = fieldRequestOptions;
  return Object.keys(pathParams).reduce(
    (acc, paramKey) =>
      acc.replace(new RegExp(`{${paramKey}}`, 'g'), pathParams[paramKey]),
    settings.url || ''
  );
}
