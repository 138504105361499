import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as appSettingsActions from 'state/appSettings/actions';

import SettingsContainer from '../settingsPanel/SettingsContainer';
import SearchBar from './SearchBar';
import AutoRefreshToggler from './AutoRefreshToggler';
import AutoZoomToggler from './AutoZoomToggler';
import UIZoom from './UIZoom';
import RouteTimezone from './RouteTimezone';

import './styles/nav.scss';

class NavContainer extends Component {
  render() {
    return (
      <nav className="rf-navbar">
        <div className="rf-navbar__logo" />
        <SearchBar />
        <AutoRefreshToggler
          setSettings={this.props.setSettings}
          isAutoRefreshOn={this.props.isAutoRefreshOn}
        />
        <AutoZoomToggler
          setSettings={this.props.setSettings}
          isAutoZoomOn={this.props.isAutoZoomOn}
        />
        <UIZoom
          value={this.props.uiSize}
          setSettings={this.props.setSettings}
        />
        <RouteTimezone
          value={this.props.routeTimezone}
          setSettings={this.props.setSettings}
        />
        <SettingsContainer />
        <a
          className="rf-help-link"
          href="https://developer.here.com/"
          target="blank"
        >
          Help
        </a>
      </nav>
    );
  }
}

NavContainer.propTypes = {
  isAutoRefreshOn: PropTypes.bool.isRequired,
  isAutoZoomOn: PropTypes.bool.isRequired,
  uiSize: PropTypes.number.isRequired,
  routeTimezone: PropTypes.string.isRequired,
  setSettings: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAutoRefreshOn: state.appSettings.isAutoRefreshOn,
    isAutoZoomOn: state.appSettings.isAutoZoomOn,
    uiSize: state.appSettings.uiSize,
    routeTimezone: state.appSettings.routeTimezone,
  };
}

const mapDispatchToProps = {
  setSettings: appSettingsActions.setSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavContainer);
