import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import {
  FormRow,
  Textarea,
  TabsControl,
  ExpandFormRow,
} from '@here/ref-client-ui';

import './advancedCorridorPoints.scss';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import get from 'lodash/get';
import Searchable from '../../../views/shared/searchUtils/Searchable';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';
import TabSortable from './TabSortable';

const DEFAULT_SEPARATOR = ';';
const tooltipKeys = [
  { key: 'description', path: 'components.parameters.via.description' },
];

class CorridorPoints extends React.Component {
  static PointTabs = {
    TEXT_AREA: 'textArea',
    POINTS: 'points',
  };

  state = {
    // eslint-disable-next-line no-use-before-define
    currentPointsTab: CorridorPoints.PointTabs.POINTS,
    openapiInfo: null,
  };

  componentDidMount() {
    const propsUrl = get(this.props, 'settings.url');
    const url = getOpenapiUrl(propsUrl, '.*/v8/', 'openapi');
    getOpenapiInfo(url, tooltipKeys).then((openapiInfo) => {
      if (!this.isUnmounted) {
        this.setState({ openapiInfo });
      }
    });

    adjustLUIStyle(document.querySelectorAll('.rf-add-point'), 'lui-button');
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  parseCommaSeparatedPoints = (value) => {
    const valueArr = value ? value.split(',') : [];
    const res = [];
    for (let i = 0; i < valueArr.length; i += 2) {
      if (!valueArr[i + 1]) {
        res.push(valueArr[i] || '');
      } else {
        res.push(`${valueArr[i]},${valueArr[i + 1]}`);
      }
    }

    return res;
  };

  onChangePoint = (num, e) => {
    let newValue = utils.extractData(e);
    const {
      fields,
      options: { key, separator = DEFAULT_SEPARATOR },
      setFields,
    } = this.props;

    const pointsArr = this.parsePointsArr(fields[key], separator);
    pointsArr[num] = newValue;
    setFields({ [key]: pointsArr.join(separator) });
  };

  onRemovePoint = (num) => {
    const {
      fields,
      options: { key, separator = DEFAULT_SEPARATOR },
      setFields,
    } = this.props;

    const pointsArr = this.parsePointsArr(fields[key], separator);
    pointsArr.splice(num, 1);
    setFields({ [key]: pointsArr.join(separator) });
  };

  onAddPoint = () => {
    const {
      fields,
      options: { key, separator = DEFAULT_SEPARATOR },
      setFields,
    } = this.props;

    setFields({ [key]: `${fields[key]}${separator}` });
  };

  getAdvancedWaypointContent = () => null; // to be redefined

  setPoints = (e) => {
    const points = e.target.value;
    const {
      options: { key },
      setFields,
    } = this.props;
    setFields({ [key]: points });
  };

  getTextAreaEl = () => {
    let {
      fields,
      options: { key },
    } = this.props;

    return (
      <Textarea
        label="Points"
        value={this.stringifyPoints(fields[key])}
        onBlur={this.setPoints.bind(this)}
      />
    );
  };

  handleSort = ({newIndex, oldIndex}) => {
    const {
      options: { key },
      setFields,
      fields,
    } = this.props;
    const via = fields[key];

    const item = via[oldIndex];
    via.splice(oldIndex, 1);
    via.splice(newIndex, 0, item);
    setFields({ [key]: via });
  };

  getPointsEl = () => {
    const {
      options: { separator = DEFAULT_SEPARATOR, key },
      fields,
    } = this.props;
    const coordsArr = this.parseCoords(fields[key], separator);

    return (
      <div>
        <TabSortable 
          onSortEnd={this.handleSort}
          className="rf-nav-tabs__sortable"
          data={coordsArr}
          onBlur={this.onChangePoint}
          closeButton={!!fields[key]}
          onRemovePoint={this.onRemovePoint}
          getAdvancedWaypointContent={this.getAdvancedWaypointContent}
        / >
        <FormRow>
          <div className="rf-clearfix">
            <lui-button class="rf-add-point" onClick={this.onAddPoint}>
              Add point
            </lui-button>
          </div>
        </FormRow>
      </div>
    );
  };

  parsePointsArr = (value, separator) =>
    separator === ','
      ? this.parseCommaSeparatedPoints(value)
      : value.split(separator);

  parseCoords = (value, separator) => this.parsePointsArr(value, separator);

  stringifyPoints = (value) => value;

  render() {
    const { currentPointsTab } = this.state;

    const pointsViewEl =
      currentPointsTab === CorridorPoints.PointTabs.TEXT_AREA
        ? this.getTextAreaEl()
        : this.getPointsEl();
    const tabsData = [
      { label: 'Points', name: CorridorPoints.PointTabs.POINTS },
      { label: 'Text', name: CorridorPoints.PointTabs.TEXT_AREA },
    ];

    return (
      <Searchable searchKey="via">
        <ExpandFormRow
          isExpanded
          label="VIA"
          className="rf-corridor-points rf-grey-box"
          tooltip={this.getTooltip()}
          tooltipPlacement="right"
        >
          <TabsControl
            tabsData={tabsData}
            currentTabName={currentPointsTab}
            onChange={(e) => this.setState({ currentPointsTab: e })}
          />
          {pointsViewEl}
        </ExpandFormRow>
      </Searchable>
    );
  }
}

CorridorPoints.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

export default CorridorPoints;
