import React from 'react';
import PropTypes from 'prop-types';
import './hereMap.scss';
import { HereMapContext } from './HereMapContext';
import { addEventListeners, removeEventListeners } from './mapEvents';
import HereGroup from './HereGroup';

class HereMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      map: null,
    };
    this.map = null;
  }

  componentDidMount() {
    const {
      options: propsOptions = {},
      center,
      zoom,
      customDefaultLayer,
      bounds,
    } = this.props;
    const options = {
      ...propsOptions,
    };
    if (bounds) {
      options.bounds = bounds;
    } else {
      options.center = center;
      options.zoom = zoom;
    }
    const map = new window.H.Map(
      this.mapRef.current,
      customDefaultLayer,
      options
    );
    map.behavior = new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(map)
    );
    if (
      options.engineType === window.H.map.render.RenderEngine.EngineType.P2D
    ) {
      map.behavior.disable(window.H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
    }
    addEventListeners(this.props, map);

    const ui = new window.H.ui.UI(map);
    const scale = new window.H.ui.ScaleBar({alignment: window.H.ui.LayoutAlignment.BOTTOM_CENTER});
    ui.addControl('scaleBar', scale);
    
    this.setState({ map });
    this.map = map;
  }

  componentWillUnmount() {
    removeEventListeners(this.props, this.state.map);
    this.mapRef.current.innerHTML = '';
  }

  getEvents = () =>
    Object.keys(this.props)
      .filter((key) => key.startsWith('on'))
      .reduce(
        (acc, key) => ({
          ...acc,
          [key.replace(/^on/, '').toLowerCase()]: this.props[key],
        }),
        {}
      );

  render() {
    const { children } = this.props;
    const { map } = this.state;
    return (
      <HereMapContext.Provider value={{ map }}>
        <div className="rf-here-map" ref={this.mapRef}></div>
        {map && <HereGroup>{children}</HereGroup>}
      </HereMapContext.Provider>
    );
  }
}

HereMap.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.object,
  customDefaultLayer: PropTypes.object,
  options: PropTypes.object,
  bounds: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default HereMap;
