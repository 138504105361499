import * as tabsTypes from '../../constants';
import { assignTabDataAndDispatch } from 'state/tabs/actions';
import { RESTORE_FROM_URL } from '../../../constants';

const SET_TAB_TITLE = 'SET_TAB_TITLE';

const tabTitleReducer = function (state = '', action) {
  switch (action.type) {
    case SET_TAB_TITLE:
      return action.payload;
    case tabsTypes.ADD_TAB:
    case tabsTypes.DUPLICATE_TAB:
      return action.tabData.tabTitle;
    case RESTORE_FROM_URL:
      return action.payload.tabTitle || state;
    default:
      return state;
  }
};

export default tabTitleReducer;

export function setTabTitle(index, tabTitle) {
  return assignTabDataAndDispatch(
    { type: SET_TAB_TITLE, payload: tabTitle },
    index
  );
}
