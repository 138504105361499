import React from 'react';
import { FormRow, Input, Tree } from '@here/ref-client-ui';
import isString from 'lodash/isString';
import './notice.scss';
import PropTypes from 'prop-types';

function Notice({ notices, position }) {
  return (
    <div className="rf-ols-summary rf-map-notices" style={{ ...position }}>
      <FormRow className="rf-section-notice rf-global-notice">
        <h5>Notices:</h5>
        <div>{notices.map(getNotice)}</div>
      </FormRow>
    </div>
  );
}

Notice.propTypes = {
  notices: PropTypes.array,
  position: PropTypes.object,
};

export default Notice;

function getNotice(notice, index) {
  const inputs = Object.keys(notice).map((key) => (
    <FormRow key={key}>
      {isString(notice[key]) ? (
        <Input
          label={key}
          isReadonly
          value={isString(notice[key]) ? notice[key] : ''}
        />
      ) : (
        <>
          <label className="rf-input rf-input_readonly">
            <span className="rf-input__caption">details</span>
          </label>
          <Tree data={notice[key]} shouldExpandNode={() => true} />
        </>
      )}
    </FormRow>
  ));

  return (
    <FormRow className="rf-section-notice__item" key={index}>
      {inputs}
    </FormRow>
  );
}
