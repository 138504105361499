import { HRN } from '@here/olp-sdk-core';
import { DataRequest, PartitionsRequest } from '@here/olp-sdk-dataservice-read';
import { Icon } from 'leaflet';
import { useRef } from 'react';
import { MarkerIcon } from '@here/ref-client-ui';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';

const PARTITIONS_CACHE = {};

const CHARGING_STATIONS_CACHE = {};

export const CS_HRN = HRN.fromString(
  'hrn:here:data::olp-here:charging-stations-for-routing-source-evcp'
);

export const DECODER_SCHEMA_HRN = HRN.fromString(
  'hrn:here:schema::olp-here:com.here.routing.schema:charging_stations_for_routing_partition_v1:1.2.0'
);

export const getPartitions = (versionedLayerClient, version) =>
  new Promise((resolve, reject) => {
    if (version && PARTITIONS_CACHE[version]) {
      resolve(PARTITIONS_CACHE[version]);
      return;
    }

    const partitionsRequest = new PartitionsRequest();
    versionedLayerClient
      .getPartitions(partitionsRequest)
      .then((res) => {
        resolve(res);
        if (version) {
          PARTITIONS_CACHE[version] = res;
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getChargingStations = (versionedLayerClient, partition, version) =>
  new Promise((resolve, reject) => {
    const key = `${partition}-${version}`;
    if (CHARGING_STATIONS_CACHE[key]) {
      resolve(CHARGING_STATIONS_CACHE[key]);
      return;
    }
    const dataRequest = new DataRequest().withPartitionId(partition);
    versionedLayerClient
      .getData(dataRequest)
      .then((res) => res.arrayBuffer())
      .then((res) => {
        resolve(res);
        CHARGING_STATIONS_CACHE[key] = res;
      })
      .catch((err) => {
        reject(err);
      });
  });

export const useIcon = (colorPalette) => {
  const svg = MarkerIcon({
    color: colorPalette.primary,
    strokeColor: '#000000',
  });
  const selectedSvg = MarkerIcon({
    color: colorPalette.primaryDarker,
    strokeColor: '#000000',
  });
  const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
  const selectedIconUrl = `data:image/svg+xml;base64,${btoa(selectedSvg)}`;

  const icons = useRef({
    [TILE_LIBRARY_TYPES.LEAFLET]: {
      icon: new Icon({
        iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [12, 28],
        iconSize: [23, 28],
      }),
      selectedIcon: new Icon({
        iconUrl: selectedIconUrl,
        iconRetinaUrl: selectedIconUrl,
        iconAnchor: [12, 28],
        iconSize: [23, 28],
      }),
    },
    [TILE_LIBRARY_TYPES.JSLA]: {
      icon: new window.H.map.Icon(iconUrl, {
        anchor: { x: 11.5, y: 28 },
        size: { w: 23, h: 28 },
      }),
      selectedIcon: new window.H.map.Icon(selectedIconUrl, {
        anchor: { x: 11.5, y: 28 },
        size: { w: 23, h: 28 },
      }),
    },
  }).current;
  return icons;
};
