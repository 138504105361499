import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ExtendableList from 'shared/extendableList';
import { createSelector } from 'reselect';
import { Columns } from '@here/ref-client-ui';
import './matrixList.scss';
import RfAutocomplete from '../../../views/shared/autocomplete';

const getRes = createSelector(
  (state) => state.raw,
  (state) => state.originsStr,
  (state) => state.destinationsStr,
  (state) => state.formatterPlugin,
  (raw, originsStr, destinationsStr, formatterPlugin) => {
    const travelTimes = get(raw, 'data.matrix.travelTimes', []);
    const distances = get(raw, 'data.matrix.distances', []);
    const errors = get(raw, 'data.matrix.errorCodes', []);
    const origins = safeJsonParse(originsStr) || [];
    let destinations = safeJsonParse(destinationsStr);
    const isDestinationEmpty = !destinations || destinations.length === 0;

    if (isDestinationEmpty) {
      destinations = origins;
    }

    const destLength = destinations.length;
    const res = [];
    const originTitles = [];
    const destinationTitles = [];
    origins.forEach((origin, originIndex) => {
      const from = isDestinationEmpty
        ? `O/D ${originIndex + 1}`
        : `O ${originIndex + 1}`;
      originTitles.push(from);
      destinations.forEach((dest, destIndex) => {
        const index = destLength * originIndex + destIndex;
        const to = isDestinationEmpty
          ? `O/D ${destIndex + 1}`
          : `D ${destIndex + 1}`;
        if (originIndex === 0) {
          destinationTitles.push(to);
        }
        const key = `${from} -> ${to}`;
        const details = {};
        if (travelTimes[index] !== undefined) {
          details.travelTime =
            formatterPlugin.get('time')(travelTimes[index]) || 0;
        }
        if (distances[index] !== undefined) {
          details.distance = formatterPlugin.get('distance')(distances[index]);
        }
        if (errors[index] !== undefined) {
          const error = getError(errors[index]);
          if (error) {
            details.error = error;
          }
        }
        res[index] = {
          key,
          title: `${from} -> ${to}`,
          details,
        };
      });
    });
    return { res, originTitles, destinationTitles };
  }
);

class MatrixList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: [],
      selectedOrigin: null,
      selectedDestination: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      setRawResult,
      result: { raw },
      fields,
      selectedTabId,
    } = this.props;
    const { matrixResultUrl } = fields;
    if (
      selectedTabId === prevProps.selectedTabId &&
      matrixResultUrl !== prevProps.fields.matrixResultUrl &&
      raw
    ) {
      setRawResult(null);
    }
  }

  onHover = (title) => {
    const { setResultState } = this.props;
    setResultState({ hoveredRoute: title });
  };

  onLeave = () => {
    const { setResultState } = this.props;
    setResultState({ hoveredRoute: null });
  };

  onSelect = (selectedRoute) => {
    const { setResultState } = this.props;
    setResultState({ selectedRoute });
  };

  filter = (isOrigin) => (item) => {
    const {
      result: { raw },
      fields,
      refClient: { formatterPlugin },
    } = this.props;
    const { origins: originsStr, destinations: destinationsStr } = fields;
    const { res } = getRes({
      raw,
      originsStr,
      destinationsStr,
      formatterPlugin,
    });
    let { selectedDestination, selectedOrigin } = this.state;
    let regex;
    if (isOrigin) {
      selectedOrigin = item;
    } else {
      selectedDestination = item;
    }
    regex = new RegExp(
      `${selectedOrigin || ''} -> ${selectedDestination || ''}`
    );
    this.setState({
      filteredList: res.filter(({ title }) => regex.test(title)),
      selectedOrigin,
      selectedDestination,
    });
  };

  render() {
    const {
      result: { state = {}, raw },
      fields,
      refClient: { formatterPlugin },
    } = this.props;
    const { filteredList } = this.state;
    const { selectedRoute, hoveredRoute } = state;
    const travelTimes = get(raw, 'data.matrix.travelTimes', []);
    const distances = get(raw, 'data.matrix.distances', []);
    if (travelTimes.length === 0 && distances.length === 0) {
      return null;
    }
    const { origins: originsStr, destinations: destinationsStr } = fields;
    const { res, originTitles, destinationTitles } = getRes({
      raw,
      originsStr,
      destinationsStr,
      formatterPlugin,
    });

    return (
      <div className="rf-matrix-list">
        <Columns>
          <RfAutocomplete
            placeholder="Origin filter"
            label="Origin filter"
            data={originTitles}
            onSelect={this.filter(true)}
            showListRegex={/\d/}
          />
          <RfAutocomplete
            placeholder="Destination filter"
            label="Destination filter"
            data={destinationTitles}
            onSelect={this.filter(false)}
            showListRegex={/\d/}
          />
        </Columns>
        <div className="rf-matrix-list__results">
          <ExtendableList
            data={filteredList.length > 0 ? filteredList : res.slice(0, 1000)}
            onSelect={this.onSelect}
            selectedItem={selectedRoute}
            hoveredItem={hoveredRoute}
            onHover={this.onHover}
            onLeave={this.onLeave}
          />
        </div>
      </div>
    );
  }
}

MatrixList.propTypes = {
  result: PropTypes.object,
  setResultState: PropTypes.func.isRequired,
  setRawResult: PropTypes.func.isRequired,
  fields: PropTypes.object,
  refClient: PropTypes.object.isRequired,
  selectedTabId: PropTypes.number.isRequired,
};

export default MatrixList;

function safeJsonParse(jsonStr) {
  let res = null;

  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return res;
  }
}

function getError(errorCode) {
  switch (errorCode) {
    case 0:
      return null;
    case 1:
      return 'Graph disconnected';
    case 2:
      return 'Origin or destination matching failed';
    case 3:
      return 'Route violates requested options';
    case 4:
      return 'Waypoints that are matched to or are inside the `margin`, or are completely outside the region limits';
    default:
      return `Error code unknown: ${errorCode}`;
  }
}
