import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './maneuvers.scss';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { ExpandFormRow, Tooltip } from '@here/ref-client-ui';

class Maneuvers extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setResultState,
      result: { raw },
      options: { key, formatter },
    } = this.props;
    
    const { result: { raw: prevRaw } } = prevProps;

    if (!!raw && !prevRaw) {
      setResultState({ [key]: {} });
      setResultState({ [`${key}-copy-${formatter}`]: {} });
    }
  }
  
  // componentWillUnmount() {
  //   const {
  //     setResultState,
  //     options: { key },
  //   } = this.props;
  //   setResultState({ [key]: {} });
  // }

  getManeuverElement({ maneuver, index, sectionIndex }, selected) {
 
    const isSelected =
      selected.index === index && selected.sectionIndex === sectionIndex;
    const listItemClasses = classnames('rf-maneuvers', {
      'rf-maneuvers_selected': isSelected,
    });
    return (
      <li
        className={listItemClasses}
        onClick={this.onClick.bind(this, maneuver, index, sectionIndex)}
        key={`${maneuver.instruction}${index}`}
      >
        <div className="rf-maneuvers__inner">
          <div className="rf-maneuvers__content">{maneuver.instruction}</div>
        </div>
        {isSelected && this.getDetails(maneuver)}
      </li>
    );
  }

  getDetails(maneuver) {
    if (!maneuver.details) {
      return null;
    }
    const detailsEl = Object.keys(maneuver.details).filter(v => v !== 'noticesRaw').map((key, i) => {
      if (key === 'notices') {
        let { notices, noticesRaw } = maneuver.details;
        return (
          <div key={key + i} onClick={this.onNoticesClick.bind(this, maneuver, i)}>
            {key}: [ {noticesRaw.map((notice, idx) => (
              <Tooltip key={key + i + idx} placement="right" className="tooltip-notices" tooltip={JSON.stringify(notice, null, 2)}>
                {notices[idx] + (notices.length === idx+1 ? "" : ",")}
              </Tooltip>
            )
            )} ]
          </div>)
      }
      else 
        return (
          <div key={key + i} onClick={this.onNoticesClick.bind(this, maneuver, i)}>
            {key}: {maneuver.details[key]}
          </div>
        )
    });
    return <div className="rf-maneuvers__details">{detailsEl}</div>;
  }

  onNoticesClick = (maneuver, index) => {
    console.log(maneuver, index, '/click')
  }

  onClick = (maneuver, index, sectionIndex) => {
    const selection = window.getSelection();
    if (selection.toString().length !== 0) {
      return;
    }
    const {
      setResultState,
      options: { key, formatter },
      result,
    } = this.props;
    const selected = get(result.state, key, {});
    if (selected.index === index && selected.sectionIndex === sectionIndex) {
      setResultState({ [key]: {} });
      setResultState({ [`${key}-copy-${formatter}`]: {} });
    } else {
      setResultState({ [key]: { maneuver, index, sectionIndex } });
      setResultState({ [`${key}-copy-${formatter}`]: { maneuver, index, sectionIndex } });
    }
  };

  render() {
    const {
      options: { formatter, key },
      result,
      refClient: { formatterPlugin },
    } = this.props;

    const maneuversFormatter = formatterPlugin.get(formatter);
    const currentRoute = get(result, 'state.currentRoute') || 0;
    const route = get(result, ['raw', 'data', 'routes', currentRoute]);
    const maneuvers = maneuversFormatter(route);
    
    if (maneuvers.length === 0 || get(maneuvers, 'sections.length') === 0) {
      return (
        <div className="rf-maneuvers-empty">
          No results <br />
        </div>
      );
    }
    const selected = get(result.state, key, {});

    if (!isArray(maneuvers)) {
      return maneuvers.sections.map(({spans: sectionManeuvers, id}, sectionIndex) => (
        <ExpandFormRow key={sectionIndex} label={`Section ${id + 1}`} isExpanded>
          {sectionManeuvers.map((maneuver, index) =>
            this.getManeuverElement({ maneuver, index, sectionIndex }, selected)
          )}
        </ExpandFormRow>
      ));
    }
    const maneuversEl = maneuvers.map((maneuver, index) =>
      this.getManeuverElement({ maneuver, index }, selected)
    );

    return <ul>{maneuversEl}</ul>;
  }
}

Maneuvers.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default Maneuvers;
