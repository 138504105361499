import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './extendableList.scss';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import get from 'lodash/get';

class ExtendableList extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    const htmlFontSize =
      +document.documentElement.style.fontSize.split('px')[0];
    this.itemHeight = 6 * htmlFontSize;
    this.itemHeightE2 = 8 * htmlFontSize;
    this.itemHeightE3 = 10 * htmlFontSize;
  }

  componentDidUpdate(prevProps) {
    const { selectedItem } = this.props;
    if (selectedItem && prevProps.selectedItem !== selectedItem) {
      // this.listRef.current.scrollToItem(200);
    }
  }

  onSelect = (key) => {
    const { selectedItem, onSelect } = this.props;
    let newSelectedItem = null;
    if (key !== selectedItem) {
      newSelectedItem = key;
    }
    onSelect(newSelectedItem);
  };

  getDetails = (details) => {
    if (!details) {
      return null;
    }
    const detailsEl = Object.keys(details).map((key) => (
      <div key={key}>
        <b>{key}</b>: {details[key]}
      </div>
    ));
    return <div className="rf-extendable-list__details">{detailsEl}</div>;
  };

  getListItem = ({ index, style }) => {
    const { data, selectedItem, onHover, onLeave, hoveredItem } = this.props;
    const item = data[index];
    const isSelected = item.key === selectedItem;
    const isHovered = item.key === hoveredItem;
    const listItemClasses = classnames('rf-extendable-list', {
      'rf-extendable-list_selected': isSelected,
      'rf-extendable-list_hovered': isHovered,
    });
    return (
      <li
        style={style}
        className={listItemClasses}
        onClick={this.onSelect.bind(this, item.key)}
        onMouseEnter={onHover.bind(this, item.key)}
        onMouseLeave={onLeave}
        key={item.key}
      >
        <div className="rf-maneuvers__inner">
          <div className="rf-maneuvers__content">{item.title}</div>
          {this.getDetails(item.details)}
        </div>
      </li>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={this.listRef}
            height={height}
            width={width}
            itemCount={data.length}
            itemSize={(i) => {
              const msgLength = get(data[i], 'details.error', '').length;
              if (msgLength>70) return this.itemHeightE3;
              else if (msgLength>30) return this.itemHeightE2;
              else return this.itemHeight;
            }}
          >
            {this.getListItem}
          </List>
        )}
      </AutoSizer>
    );
  }
}

ExtendableList.propTypes = {
  data: PropTypes.array.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hoveredItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
};

export default ExtendableList;
