import React, { Component } from 'react';
import { connect } from 'react-redux';

import AvoidExcludeForm from './AvoidExcludeForm';
import { getIsAvoidExcludeShown } from '../../state/ui';

class AddAvoidExcludeContainer extends Component {
  state = {
    currentArea: 'bbox',
    currentMode: 'avoid',
  };

  onChange = (key, value) => {
    this.setState({[key]: value});
  }

  addArea = (area, radius) => {
    const { currentMode, currentArea } = this.state;
    const { fields, setFields, isAvoidExcludeShown } = this.props;
    const { options: { skipCorridor } } = isAvoidExcludeShown;

    const {
      [currentMode]: { areas, features },
    } = fields;
    
    let newArea = {
      geometry: area,
      type: currentArea==='corridor'&&skipCorridor ? 'bbox' : currentArea,
      isException: false,
      i: areas.length
    }
    if (currentArea === 'corridor' && !skipCorridor) {
      newArea.radius = radius;
    }
    setFields({
      [currentMode]: {
        areas: [...areas, newArea],
        features,
      },
    });
  }

  onToggleForm = () => {
    const { setIsAvoidExcludeShown } = this.props;
    setIsAvoidExcludeShown(false);
  };

  renderForm() {
    const { isAvoidExcludeShown } = this.props;
    const { currentMode, currentArea } = this.state;
    const { box, line, center: {zoom}, options: { skipCorridor } } = isAvoidExcludeShown;
    
    return (
      <AvoidExcludeForm
        skipCorridor={skipCorridor}
        onBlur={this.onToggleForm}
        currentMode={currentMode}
        currentArea={currentArea==='corridor'&&skipCorridor ? 'bbox' : currentArea}
        box={box}
        line={line}
        zoom={zoom}
        onChangeState={this.onChange}
        addArea={this.addArea}
      />
    );
  }

  render() {
    const { isAvoidExcludeShown } = this.props;
    return (
      <div>
        {isAvoidExcludeShown && this.renderForm()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAvoidExcludeShown: getIsAvoidExcludeShown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAvoidExcludeContainer);
