import L from 'leaflet';

export function createCanvas(color, text, selected) {
  var canvas = document.createElement('canvas'),
      width = 28,
      diff = selected ? 8 : 0,
      height = 16 + diff,
      fontSize = 10 + diff;
      
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
      
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(14, 16 + diff);
  ctx.lineTo(14, 9 + diff);
  ctx.stroke();
  ctx.closePath();

  ctx.font = 'bold ' + fontSize + 'px Arial';
  ctx.fillStyle = color;
  ctx.textAlign = 'center'; 
  ctx.fillText(text,14,8 + diff);

  return canvas
};

export function createIcon(canvas, selected) {
  const diff = selected ? 8 : 0;
  const icon = new window.H.map.Icon(canvas, ({
    'anchor': {
        'x': 14,
        'y': 16 + diff
    }
  }));

  return icon;
}

export function createLeafletIcon(color, text, selected) {
  const diff = selected ? 8 : 0;
  const svg = `<svg width="28" height="${16+diff}" xmlns="http://www.w3.org/2000/svg"><line x1="14" y1="${16 + diff}" x2="14" y2="${9 + diff}" stroke="${color}" stroke-width="1" /><text x="14" y="${8 + diff}" font-family="Arial" font-weight="bold" font-size="10px" fill="${color}" text-anchor="middle">${text}</text></svg>`;
  const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
  const icon = new L.Icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    iconAnchor: [14, 16 + diff],
    iconSize: [28, 16 + diff],
  });

  return icon;
}