import 'leaflet/dist/leaflet.css';
import RefClient from 'ref-client-core';

import CompareTo from './resultPlugins/compareTo';
import OlsSummary from './resultPlugins/olsSummary/OlsSummary';
import './mapPlugins';
import './chargingStationsPlugins';
import './matrixResultPlugins';
import './formatterPlugins';
import './formViewPlugins';
import './isolinePlugins';
import './vehiclePlugins';
import './routeImportPlugins';
import './offlineToolsPlugins';

RefClient.resultPanelPlugin.register('compareTo', CompareTo);
RefClient.resultPanelPlugin.register('olsSummary', OlsSummary);
