import React from 'react';
import PropTypes from 'prop-types';

import CustomChart from 'shared/chart';
import { CheckboxList, ExpandFormRow, MarkerIcon, FormRow, Select } from '@here/ref-client-ui';
import './profiling.scss';
import { get, isEqual, chunk, times, flatten, debounce, uniq } from 'lodash';
import { decode } from '../../../utils/flexPolyline';
import classnames from 'classnames';
import UIZoom from '../../../views/navPanel/UIZoom';

const shortConnectorNames = {
  iec62196Type1Combo: 't1',
  iec62196Type2Combo: 't2',
  chademo: 'C',
  tesla: 'T',
};

class Profiling extends React.Component {
  state = {
    containerSize: {
      width: 400,
      height: 320,
    },
    speedScaleFromZero: true 
  };

  lastHoveredValue = null;


  speedUnits = [
    'm/s',   // meters per second
    'km/h',  // kilometers per hour
    'mph'    // miles per hour
  ]

  distanceUnits = [
    'KM',  // kilometers per hour
    'Mile'    // miles per hour
  ]

  constructor(...args) {
    super(...args);
    this.onResize = debounce(this.onResize, 300);
    this.profilesContainerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.result.state.isResultPanelMaximized !==
      this.props.result.state.isResultPanelMaximized
    ) {
      this.onResize();
    }
  }

  componentWillUnmount() {
    this.props.setResultState({
      hoveredPoint: null,
      selectedPoint: null,
    });
    window.removeEventListener('resize', this.onResize);
  }

  getShape = (data) => {
    let shape = [];
    data.sections.forEach((sectionData) => {
      const encodedPolyline = sectionData.polyline;
      if (!encodedPolyline) {
        return;
      }
      const decodedPolyline = encodedPolyline ? decode(encodedPolyline) : [];
      shape = [...shape, ...flatten(decodedPolyline)];
    });
    return shape;
  };

  addEnergyDataToAxis = (axis, maxCharge) => {
    axis.max = +maxCharge;
    axis.min = 0;
  };

  addFCDataToAxis = (axis) => {
    axis.min = 1;
    axis.max = 5;
    axis.inverted = true;
    axis.tick = {
      values: [1, 2, 3, 4, 5],
      count: 5,
    };
  };

  prepareElevationData(data, speedUnit) {
    let result = { x: [], y: [] },
      distance = 0;
    let p1;
    if (data.shape[0] && data.shape[1]) {
      p1 = new window.H.geo.Point(data.shape[0], data.shape[1]);
    }

    for (let i = 2; i < data.shape.length; i += 3) {
      let p2 = new window.H.geo.Point(data.shape[i - 2], data.shape[i - 1]);

      distance += p1.distance(p2) / 1000;

      result.x.push(distance);
      result.y.push(Math.round(data.shape[i]*(speedUnit==='mph'?3.28084:1)));
      p1 = p2;
    }

    return result;
  }

  onZoomChart = ([zoomStart, zoomEnd]) => {
    this.setState({ zoomStart, zoomEnd });
  };

  onInit = () => {
    setTimeout(() => {
      if (!this.chart) {
        return;
      }
      const [initialZoomStart, initialZoomEnd] =
        this.chart.internal.x.orgDomain();
      this.setState({ initialZoomStart, initialZoomEnd });
    }, 1);
  };

  onClick = (e, a) => {
    const speedScaleFromZero = this.state.speedScaleFromZero;
    if (e.target.nodeName !== 'rect') {
      this.setState({
        speedScaleFromZero: !speedScaleFromZero
      })
    } else {
      this.props.setResultState({
        selectedPoint: this.props.result.state.hoveredPoint,
      });
    }
  }

  onMouseOut = () => {
    this.lastHoveredValue = null;
    this.props.setResultState({
      hoveredPoint: null,
    });
  };

  onMouseOver = ({ x }) => {
    if (x === this.lastHoveredValue) {
      return;
    }
    const currentRoute = this.props.result.state.currentRoute || 0;
    const routes = get(this.props.result, `raw.data.routes`, []);
    const route = routes[currentRoute] || {};
    let totalDistance = 0;
    route.sections.some((section) => {
      if (section.spans) {
        const encodedPolyline = section.polyline;
        if (!encodedPolyline) {
          return false;
        }
        const shape = encodedPolyline ? decode(encodedPolyline) : [];
        const shapeLength = shape.length;
        return section.spans.some((span, index) => {
          totalDistance += span.length;
          if (this.convertDistanceUnit(totalDistance) >= x) {
            const nextSpan = section.spans[x === 0 ? 0 : (index+1)] || {
              offset: shapeLength - 1,
            };
            const { offset } = nextSpan;
            const point = shape[offset];
            this.props.setResultState({
              hoveredPoint: { lat: +point[0], lng: +point[1] },
            });
            this.lastHoveredValue = x;
            return true;
          }
          return false;
        });
      }
      return false;
    });
  };

  formatProfileData(distanceData, valuesData, type) {
    const formattedDistanceData = [];
    const formattedValuesData = [];
    if (distanceData.length && valuesData.length) {
      distanceData.forEach((distance, index) => {
        const value = valuesData[index];
        const formattedLength = formattedDistanceData.length;
        if (
          type === 'step' &&
          formattedValuesData[formattedLength - 1] === value &&
          index < distanceData.length - 1
        ) {
          return;
        } else if (value === null) {
          formattedDistanceData.push(distanceData[index-1]);
          formattedValuesData.push(valuesData[index-1]);
        } else if (
          formattedValuesData[formattedLength - 1] === value &&
          formattedValuesData[formattedLength - 2] === value
        ) {
          formattedDistanceData.length--;
          formattedValuesData.length--;
        }
        formattedDistanceData.push(distance);
        formattedValuesData.push(value);
      });
    }
    return { formattedDistanceData, formattedValuesData };
  }

  getProfilesOptions(speedUnit) {
    const { selectedProfiles = [] } = this.props.result.state;
    const evEnabled = get(this.props, 'fields.evEnabled');
    return [
      { value: 'ev', label: 'EV', disabled: !evEnabled },
      { value: 'baseSpeed', label: 'Base Speed' },
      { value: 'trafficSpeed', label: 'Traffic Speed' },
      { value: 'speedLimit', label: 'Speed Limit' },
      { value: 'e_per_km', label: `Energy / ${speedUnit==='mph'?'mile':'km'}`, disabled: !evEnabled },
      { value: 'frc', label: 'FRC' },
      { value: 'elevation', label: 'Elevation' },
    ].map((options) => ({
      ...options,
      isChecked: selectedProfiles.indexOf(options.value) > -1,
      onChange: this.onProfileChanged,
    }));
  }

  onProfileChanged = (e, value) => {
    const { setFields, setNotification, request, fields } = this.props;
    const spansParams = fields.spans.values;
    const returnParams = fields.return.values;
    const { selectedProfiles = [] } = this.props.result.state;

    let values = [...selectedProfiles];
    if (e.target.checked && values.indexOf(value) === -1) {
      values.push(value);
    } else if (!e.target.checked) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
    }

    if (values.length > 2) {
      setNotification({
        message: 'Only two profiles could be chosen',
        impact: 'negative',
        autoDismiss: 5,
      });
      e.currentTarget.checked = false;
      // workaround for LUI:
      e.currentTarget.shadowRoot.querySelector('input').checked = false;
      return;
    }
    if (
      (values.indexOf('baseSpeed') > -1 &&
      selectedProfiles.indexOf('baseSpeed') === -1 &&
      (spansParams.indexOf('dynamicSpeedInfo') === -1 ||
        spansParams.indexOf('length') === -1)) || 
      (values.indexOf('trafficSpeed') > -1 &&
      selectedProfiles.indexOf('trafficSpeed') === -1 &&
      (spansParams.indexOf('dynamicSpeedInfo') === -1 ||
        spansParams.indexOf('length') === -1)
      )
    ) {
      setFields({
        spans: {
          values: uniq([...fields.spans.values, 'dynamicSpeedInfo', 'length']),
        },
      });
      request();
      setNotification({
        children: (
          <div>
            The following span params were automatically enabled by this action:
            <ul>
              <li>Length</li>
              <li>Dynamic Speed Info</li>
            </ul>
          </div>
        ),
        impact: 'significant',
      });
    }
    if (
      values.indexOf('ev') > -1 &&
      selectedProfiles.indexOf('ev') === -1 &&
      returnParams.indexOf('summary') === -1
    ) {
      setFields({
        return: { values: uniq([...fields.return.values, 'summary']) },
      });
      request();
      setNotification({
        children: (
          <div>
            <b>Summary</b> return param was automatically enabled by this action
          </div>
        ),
        impact: 'significant',
      });
    }
    if (
      values.indexOf('e_per_km') > -1 &&
      selectedProfiles.indexOf('e_per_km') === -1 &&
      (spansParams.indexOf('consumption') === -1 ||
        spansParams.indexOf('length') === -1)
    ) {
      setFields({
        spans: {
          values: uniq([...fields.spans.values, 'consumption', 'length']),
        },
      });
      request();
      setNotification({
        children: (
          <div>
            The following span params were automatically enabled by this action:
            <ul>
              <li>Length</li>
              <li>Consumption</li>
            </ul>
          </div>
        ),
        impact: 'significant',
      });
    }
    if (
      values.indexOf('elevation') > -1 &&
      selectedProfiles.indexOf('elevation') === -1 &&
      returnParams.indexOf('elevation') === -1
    ) {
      setFields({ return: { values: [...fields.return.values, 'elevation'] } });
      request();
      setNotification({
        children: (
          <div>
            <b>Elevation</b> return param was automatically enabled by this
            action
          </div>
        ),
        impact: 'significant',
      });
    }
    if (
      values.indexOf('speedLimit') > -1 &&
      selectedProfiles.indexOf('speedLimit') === -1 &&
      (spansParams.indexOf('speedLimit') === -1 ||
        spansParams.indexOf('length') === -1)
    ) {
      setFields({
        spans: {
          values: uniq([...fields.spans.values, 'speedLimit', 'length']),
        },
      });
      request();
      setNotification({
        children: (
          <div>
            The following span params were automatically enabled by this action:
            <ul>
              <li>length</li>
              <li>speedLimit</li>
            </ul>
          </div>
        ),
        impact: 'significant',
      });
    }
    if (
      values.indexOf('frc') > -1 &&
      selectedProfiles.indexOf('frc') === -1 &&
      (spansParams.indexOf('functionalClass') === -1 ||
        spansParams.indexOf('length') === -1)
    ) {
      setFields({
        spans: {
          values: uniq([...fields.spans.values, 'functionalClass', 'length']),
        },
      });
      request();
      setNotification({
        children: (
          <div>
            The following span params were automatically enabled by this action:
            <ul>
              <li>length</li>
              <li>functionalClass</li>
            </ul>
          </div>
        ),
        impact: 'significant',
      });
    }
    this.props.setResultState({
      selectedProfiles: values,
    });
  };

  onResize = () => {
    if (this.props.result.state.isResultPanelMaximized) {
      this.setState({
        containerSize: {
          width: this.profilesContainerRef.current.offsetWidth,
          height:
            this.profilesContainerRef.current.offsetHeight -
            this.profilesContainerRef.current.querySelector(
              '.rf-expand-form-row'
            ).offsetHeight -
            parseFloat(
              window.getComputedStyle(this.profilesContainerRef.current)
                .paddingBottom
            ) -
            50,
        },
      });
      return;
    }
    this.setState({
      containerSize: {
        width: 400,
        height: 320,
      },
    });
  };

  maximize = () => {
    this.props.setResultState({
      isResultPanelMaximized: true,
    });
  };

  minimize = () => {
    this.props.setResultState({
      isResultPanelMaximized: false,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    let returnValue = true;
    const evEnabled = get(nextProps, 'fields.evEnabled');
    if ( nextState.speedScaleFromZero !== this.state.speedScaleFromZero ||
      nextProps.result.state.speedUnit !== this.props.result.state.speedUnit
      ) {
      return returnValue;
    }

    if (!evEnabled) {
      let selectedProfiles = [
        ...(nextProps.result.state.selectedProfiles || []),
      ];
      const evIndex = selectedProfiles.indexOf('ev');
      if (evIndex > -1) {
        selectedProfiles.splice(evIndex, 1);
      }
      const evPerKmIndex = selectedProfiles.indexOf('e_per_km');
      if (evPerKmIndex > -1) {
        selectedProfiles.splice(evPerKmIndex, 1);
      }
      if (
        selectedProfiles.length !==
        (nextProps.result.state.selectedProfiles || []).length
      ) {
        this.props.setResultState({ selectedProfiles });
        return false;
      }
    }
    // if raw result, selected profiles and maximized not changed skip rerender
    if (
      nextProps.result.state.currentRoute ===
        this.props.result.state.currentRoute &&
      nextProps.result.state.isResultPanelMaximized ===
        this.props.result.state.isResultPanelMaximized &&
      isEqual(this.props.result.raw, nextProps.result.raw) &&
      isEqual(
        this.props.result.state.selectedProfiles,
        nextProps.result.state.selectedProfiles
      ) &&
      nextProps.result.state.speedUnit ===
        this.props.result.state.speedUnit &&
      this.state.initialZoomStart === nextState.initialZoomStart &&
      this.state.initialZoomEnd === nextState.initialZoomEnd &&
      isEqual(this.state.containerSize, nextState.containerSize)
    ) {
      return false;
    }
    return returnValue;
  }

  setSpeedUnit = ({ target: { value } }) => {
    this.props.setResultState({
      speedUnit: value,
    });
  }

  convertSpeedUnit = speed => {
    const precision = 1e5;
    let convertedSpeed = speed;
    if( this.props.result.state.speedUnit === this.speedUnits[2] ) {
      convertedSpeed = Math.round(speed * 2.23694 * precision) / precision;
    } else if ( this.props.result.state.speedUnit === this.speedUnits[1] ) {
      convertedSpeed = Math.round(speed * 3.6 * precision) / precision;
    }
    return convertedSpeed;
  }

  convertDistanceUnit = distance => {
    let convertedDistance = distance;
    if( this.props.result.state.speedUnit === this.speedUnits[2] ) {
      convertedDistance = distance * 0.621371;
    }
    return convertedDistance;
  }

  render() {
    const currentRoute = this.props.result.state.currentRoute || 0;
    const routes = get(this.props.result, `raw.data.routes`, []);
    if (routes.length === 0) {
      return null;
    }
    const route = routes[currentRoute] || {};
    const selectedProfilesState =
      this.props.result.state.selectedProfiles || [];
    const speedUnit =
      this.props.result.state.speedUnit || this.speedUnits[0];

    const maxChargeAfterChargingStation =
      this.props.fields['ev[maxChargeAfterChargingStation]'];
    const minChargeAtChargingStation =
      this.props.fields['ev[minChargeAtChargingStation]'];
    const minChargeAtFirstChargingStation =
    this.props.fields['ev[minChargeAtFirstChargingStation]'];
    const maxCharge = this.props.fields['ev[maxCharge]'];
    const isEVProfileEnabled = selectedProfilesState.indexOf('ev') > -1;
    const isSpeedProfileEnabled = selectedProfilesState.indexOf('baseSpeed') > -1;
    const isTrafficSpeedProfileEnabled = selectedProfilesState.indexOf('trafficSpeed') > -1;
    const isSpeedLimitProfileEnabled =
      selectedProfilesState.indexOf('speedLimit') > -1;
    const isFRCProfileEnabled = selectedProfilesState.indexOf('frc') > -1;
    const isEnergyPerKmProfileEnabled =
      selectedProfilesState.indexOf('e_per_km') > -1;
    const isElevationProfileEnabled =
      selectedProfilesState.indexOf('elevation') > -1;
    const waypointsData = [{ distance: 0 }];

    let currentLength = 0;
    const lengthData = [];
    const consumptionData = [];

    const spanSpeedDistanceData = [];
    const spanTrafficSpeedDistanceData = [];
    const spanSpeedLimitDistanceData = [];
    const spanFRCDistanceData = [];
    const speedData = [];
    const trafficSpeedData = [];
    const speedLimitData = [];
    const functionalClassData = [];
    let currentPower = 0;
    const labelsIndexes = [];

    const energyPerMeterData = [];
    let currentSpanDistance = 0;

    route.sections.forEach((section) => {
      lengthData.push(this.convertDistanceUnit(currentLength));
      consumptionData.push(+get(section, 'departure.charge', 0).toPrecision(5));
      labelsIndexes.push(consumptionData.length - 1);

      currentPower = +section.departure.charge;

      if (section.spans) {
        section.spans.forEach((span, index) => {
          if (!span.length) {
            return;
          }
          
          currentSpanDistance += this.convertDistanceUnit(span.length);
          if (span.consumption !== undefined) {
            // Calculate Consumption (from spans)
            currentPower -= span.consumption;
            const power = +currentPower.toPrecision(5);
            // avoid duplicates:
            if (
              section.spans.length <= 50 &&
              !lengthData.find(
                (val, index) =>
                  val === currentSpanDistance &&
                  consumptionData[index] === power
              )
            ) {
              consumptionData.push(power);
              lengthData.push(currentSpanDistance);
            }

            // Calculate Energy per KM
            const consumptionPerMeter = span.consumption / span.length;
            for (let i = 0; i < span.length; i++) {
              energyPerMeterData.push(consumptionPerMeter);
            }
          }

          // Calculate Speed Profile
          if (span.dynamicSpeedInfo !== undefined) {
            if (index === 0) {
              spanSpeedDistanceData.push(0);
            }
            spanSpeedDistanceData.push(currentSpanDistance);
            speedData.push(this.convertSpeedUnit(span.dynamicSpeedInfo.baseSpeed));
            if (index === section.spans.length - 1) {
              speedData.push(this.convertSpeedUnit(span.dynamicSpeedInfo.baseSpeed));
            }

            if (index === 0) {
              spanTrafficSpeedDistanceData.push(0);
            }
            spanTrafficSpeedDistanceData.push(currentSpanDistance);
            trafficSpeedData.push(this.convertSpeedUnit(span.dynamicSpeedInfo.trafficSpeed));
            if (index === section.spans.length - 1) {
              trafficSpeedData.push(this.convertSpeedUnit(span.dynamicSpeedInfo.trafficSpeed));
            }
          }
          // Calculate Speed Limit Profile
          if (span.speedLimit !== undefined) {
            if (spanSpeedLimitDistanceData.length === 0) {
              spanSpeedLimitDistanceData.push(currentSpanDistance-span.length);
            } else if (speedLimitData[spanSpeedLimitDistanceData.length-2] === null) {
              spanSpeedLimitDistanceData[spanSpeedLimitDistanceData.length-1] = currentSpanDistance-span.length;
            }
            spanSpeedLimitDistanceData.push(currentSpanDistance);
            speedLimitData[spanSpeedLimitDistanceData.length-2] = this.convertSpeedUnit(span.speedLimit);
            speedLimitData[spanSpeedLimitDistanceData.length-1] = this.convertSpeedUnit(span.speedLimit);
          } 
          else if (speedLimitData.length > 0 && speedLimitData[spanSpeedLimitDistanceData.length-1] !== null) {
            spanSpeedLimitDistanceData.push(currentSpanDistance);
            spanSpeedLimitDistanceData.push(currentSpanDistance);
            speedLimitData[spanSpeedLimitDistanceData.length-2] = null;
            speedLimitData[spanSpeedLimitDistanceData.length-1] = null;
          }
          // Calculate Functional Class Profile
          if (span.functionalClass !== undefined) {
            if (index === 0) {
              spanFRCDistanceData.push(0);
            }
            spanFRCDistanceData.push(currentSpanDistance);
            functionalClassData.push(span.functionalClass);
            if (index === section.spans.length - 1) {
              functionalClassData.push(span.functionalClass);
            }
          }
        });
      }

      currentLength += this.convertDistanceUnit(get(section, 'summary.length', 0));
      // if (lengthData)
      lengthData.push(currentLength);
      consumptionData.push(+get(section, 'arrival.charge', 0).toPrecision(5));
      labelsIndexes.push(consumptionData.length - 2);

      const chargingStation = get(section, 'postActions', []).find(
        (postAction) => postAction.action === 'charging'
      );

      const consumablePower = get(chargingStation, 'consumablePower', null);

      waypointsData.push({
        distance: currentLength,
        power: consumablePower,
        connectorType: get(section, 'arrival.place.attributes.connectorType'),
      });
    });

    const energyPerKmData = chunk(energyPerMeterData, (speedUnit==='mph'?1609:1000)).map((meters) =>
      meters.reduce((tatal, val) => tatal + val, 0)
    );

    // Calculate Elevation Profile
    const shape = this.getShape(route);
    const elevationValues = this.prepareElevationData({ shape }, speedUnit);

    const profiles = {
      ev: { name: 'energy', label: 'Energy (kWh)' },
      baseSpeed: { name: 'base speed', label: `Base Speed (${speedUnit})` },
      trafficSpeed: { name: 'traffic speed', label: `Traffic Speed (${speedUnit})` },
      e_per_km: { name: `energy per ${speedUnit==='mph'?'mile':'km'}`, label: `Energy per ${speedUnit==='mph'?'mile':'km'}` },
      elevation: { name: 'elevation', label: `Elevation (${speedUnit==='mph'?'foot':'m'})` },
      speedLimit: { name: 'speed limit', label: `Speed Limit (${speedUnit})` },
      frc: { name: 'functional class', label: 'Functional Class' },
    };

    const selectedProfiles = selectedProfilesState.map(
      (selectedProfile) => profiles[selectedProfile]
    );
    const axes = {};
    if (selectedProfiles.length > 0) {
      axes[selectedProfiles[0].name] = 'y';
      if (selectedProfiles.length > 1) {
        axes[selectedProfiles[1].name] = 'y2';
      }
    }

    const colors = {};
    if (selectedProfiles.length) {
      colors[selectedProfiles[0].name] = '#0000cd';
    }
    if (selectedProfiles.length > 1) {
      colors[selectedProfiles[1].name] = '#cc6600';
    }

    const columnsData = [];
    if (isEVProfileEnabled) {
      columnsData.push({
        distance: lengthData,
        values: consumptionData,
        xName: 'distance',
        yName: 'energy',
      });
    }
    if (isSpeedProfileEnabled) {
      columnsData.push({
        distance: spanSpeedDistanceData,
        values: speedData,
        xName: 'speedDistance',
        yName: 'base speed',
        type: 'step',
      });
    }

    if (isTrafficSpeedProfileEnabled) {
      columnsData.push({
        distance: spanTrafficSpeedDistanceData,
        values: trafficSpeedData,
        xName: 'trafficSpeedDistance',
        yName: 'traffic speed',
        type: 'step',
      });
    }
    if (isSpeedLimitProfileEnabled) {
      columnsData.push({
        distance: spanSpeedLimitDistanceData,
        values: speedLimitData,
        xName: 'speedLimitDistance',
        yName: 'speed limit',
        type: 'step',
      });
    }
    if (isFRCProfileEnabled) {
      columnsData.push({
        distance: spanFRCDistanceData,
        values: functionalClassData,
        xName: 'FRCDistance',
        yName: 'functional class',
        type: 'step',
      });
    }
    if (isEnergyPerKmProfileEnabled) {
      columnsData.push({
        distance: times(energyPerKmData.length, (val) => val * 1000),
        values: energyPerKmData,
        xName: 'ePerKmDistance',
        yName: `energy per ${speedUnit==='mph'?'mile':'km'}`,
      });
    }
    if (isElevationProfileEnabled) {
      columnsData.push({
        distance: elevationValues.x.map((val) => val * (speedUnit==='mph'?621.371:1000)),
        values: elevationValues.y,
        xName: 'elevationDistance',
        yName: `elevation`,
      });
    }

    const columns = [];
    const xs = {};
    const types = {};
    columnsData.forEach((data) => {
      const { formattedDistanceData, formattedValuesData } =
        this.formatProfileData(data.distance, data.values, data.type);
      columns.push([data.xName].concat(formattedDistanceData));
      columns.push([data.yName].concat(formattedValuesData));
      xs[data.yName] = data.xName;
      if (data.type) {
        types[data.yName] = data.type;
      }
    });

    const chartData = {
      xs,
      selection: {
        enabled: false,
        multiple: false,
      },
      columns,
      types,
      axes,
      colors,
      labels: {
        format: {
          energy: (val, id, key) => {
            if (labelsIndexes.indexOf(key) === -1) {
              return '';
            }
            return val ? +val.toFixed(2) : val;
          },
        },
      },
    };

    const axis = {
      x: {
        label: {
          text: `Distance (${speedUnit==='mph'?'Mile':'km'})`,
          position: 'inner-center',
        },
        tick: {
          count: 10,
          format: (x) => (x / 1000).toFixed(),
        },
      },
    };

    const speedScaleFromZero = this.state.speedScaleFromZero;
    // filter out value without speed limit (null)
    const filteredSpeedLimitData = speedLimitData.filter(value => value != null);
    if (selectedProfiles.length > 0) {
      axis.y = {
        label: {
          text: selectedProfiles[0].label,
          position: 'inner-bottom',
        },
      };
      if (selectedProfiles[0].name === 'energy') {
        this.addEnergyDataToAxis(axis.y, maxCharge);
      } else if (selectedProfiles[0].name === 'functional class') {
        this.addFCDataToAxis(axis.y);
      } else if (
        ['base speed', 'speed limit', 'traffic speed'].indexOf(selectedProfiles[0].name) > -1
      ) {
        const max = Math.max(...speedData, ...trafficSpeedData, ...filteredSpeedLimitData);
        const min = speedScaleFromZero ? 0 : Math.min(...speedData, ...trafficSpeedData, ...filteredSpeedLimitData);
        axis.y.max = max;
        axis.y.min = min;
      }
    }

    if (selectedProfiles.length > 1) {
      axis.y2 = {
        show: true,
        label: {
          text: selectedProfiles[1].label,
          position: 'inner-bottom',
        },
      };
      if (selectedProfiles[1].name === 'energy') {
        this.addEnergyDataToAxis(axis.y2, maxCharge);
      } else if (selectedProfiles[1].name === 'functional class') {
        this.addFCDataToAxis(axis.y2);
      } else if (
        ['base speed', 'speed limit', 'traffic speed'].indexOf(selectedProfiles[1].name) > -1
      ) {
        const max = Math.max(...speedData, ...trafficSpeedData, ...filteredSpeedLimitData);
        const min = speedScaleFromZero ? 0 : Math.min(...speedData, ...trafficSpeedData, ...filteredSpeedLimitData);
        axis.y2.max = max;
        axis.y2.min = min;
      }

      if (
        ['base speed', 'speed limit', 'traffic speed'].indexOf(selectedProfiles[0].name) > -1 &&
        ['base speed', 'speed limit', 'traffic speed'].indexOf(selectedProfiles[1].name) > -1
      ) {
        axis.y.label.text = axis.y.label.text.replace(`(${speedUnit})`, '/ '+axis.y2.label.text);
        axis.y2.show = false;
      }
    }

    const containerSize = { ...this.state.containerSize };
    if (!this.props.result.state.isResultPanelMaximized) {
      containerSize.width *= UIZoom.sizeToPixels(this.props.uiSize) / 10;
    }

    const options = {
      oninit: this.onInit,
      size: containerSize,
      axis,
      grid: {},
      zoom: {
        enabled: true,
        rescale: false,
        onzoom: this.onZoomChart,
      },
      legend: { show: true },
      point: {
        show: false,
      },
      line: {
        step: {
          type: 'step-after',
        },
      },

      tooltip: {
        format: {
          title: (x) => {
            this.onMouseOver({ x }); // workaround for bugged onMouseOver handler
            return `Distance: ${+(x / 1000).toFixed(3)} ${speedUnit==='mph'?'mile':'km'}`;
          },
        },
      },
    };

    if (isEVProfileEnabled) {
      const evAxis = selectedProfiles[0].name === 'energy' ? 'y' : 'y2';
      options.grid.y = {
        lines: [
          {
            value: maxCharge,
            text: 'maxCharge',
            class: 'color-grid-maxcharge',
            axis: evAxis,
          },
          {
            value: minChargeAtChargingStation,
            text: 'minChargeAtChargingStation',
            class: 'color-grid-minchargeatstop',
            axis: evAxis,
          },
          {
            value: minChargeAtFirstChargingStation,
            text: 'minChargeAtFirstChargingStation',
            class: 'color-grid-minchargeatstop',
            axis: evAxis,
          },
          {
            value: maxChargeAfterChargingStation,
            text: 'maxChargeAfterChargingStation',
            class: 'color-grid-chargingstopdeparturecharge',
            axis: evAxis,
          },
        ],
      };
    }

    let waypointNumber = 0;

    // Waypoint markers position calculation
    const widthInRem =
      containerSize.width / UIZoom.sizeToPixels(this.props.uiSize) - 3.6;
    const offset = this.state.initialZoomStart;
    let { zoomStart = -offset, zoomEnd = this.state.initialZoomEnd } =
      this.state;
    zoomStart += offset;
    zoomEnd -= offset;
    const length = zoomEnd - zoomStart;
    const lengthPerRem = length / widthInRem;
    const { isResultPanelMaximized } = this.props.result.state;

    const classes = classnames('rf-profiles', {
      'rf-profiles__maximized': this.props.result.state.isResultPanelMaximized,
    });

    return (
      <lui-default-theme>
        <div ref={this.profilesContainerRef} className={classes}>
          {!isResultPanelMaximized && (
            <i className="rf-maximize" onClick={this.maximize} />
          )}
          {isResultPanelMaximized && (
            <i className="rf-minimize" onClick={this.minimize} />
          )}
          <ExpandFormRow label="Shown Profiles:" isExpanded>
            <CheckboxList params={this.getProfilesOptions(speedUnit)} />
          </ExpandFormRow>

          <FormRow>
            <Select
              className="rf-routing_speed_units"
              options={this.speedUnits}
              value={speedUnit}
              onChange={this.setSpeedUnit}
            />
          </FormRow>
          <CustomChart
            data={chartData}
            options={options}
            setChartObj={(chart) => {
              this.chart = chart;
            }}
            onClick={this.onClick}
            onMouseOut={this.onMouseOut}
          />
          <div className="rf-waipoints-line">
            {waypointsData.map((waypointData, index) => {
              let title = '&#x1f50b;';
              if (index === 0) {
                title = 'A';
              } else if (index === waypointsData.length - 1) {
                title = 'B';
              } else if (waypointData.power === null) {
                title = ++waypointNumber;
              }

              const currentWaypointDistance = waypointData.distance;
              if (currentWaypointDistance < zoomStart) {
                return null;
              }

              const relPosition = currentWaypointDistance - zoomStart;

              return (
                <div
                  style={{
                    position: 'absolute',
                    left: `${relPosition / lengthPerRem}rem`,
                  }}
                  key={index}
                >
                  <div
                    className="Container"
                    /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{ __html: MarkerIcon({ title }) }}
                    /* eslint-enable react/no-danger */
                  />
                  <div>{waypointData.power || ''}</div>
                  <div>
                    {shortConnectorNames[waypointData.connectorType] || ''}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </lui-default-theme>
    );
  }
}

Profiling.propTypes = {
  result: PropTypes.object,
  fields: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
  uiSize: PropTypes.number.isRequired,
  routeTimezone: PropTypes.string.isRequired,
  tabColorPalette: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
};

export default Profiling;
