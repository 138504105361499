import mapPlugin from './mapPlugin';
import settingsPlugin from './settingsPlugin';
import formViewsPlugin from './formViewsPlugin';
import resultPanelPlugin from './resultPanelPlugin';
import formatterPlugin from './formattersPlugin';
import autoRefreshPlugin from './autoRefreshPlugin';

class RefClient {
  mapPlugin = mapPlugin;
  resultPanelPlugin = resultPanelPlugin;
  formViewsPlugin = formViewsPlugin;
  formatterPlugin = formatterPlugin;
  settingsPlugin = settingsPlugin;
  autoRefreshPlugin = autoRefreshPlugin;

  configs = {};

  apiKeys = {};

  loadConfig(config) {
    this.configs[config.title] = config;
  }

  loadKeys(keys) {
    this.apiKeys[keys.apiKeys.title] = keys.apiKeys;
  }
}

const refClient = new RefClient();
Object.freeze(refClient);

export default refClient;
