import { GEOCODER as CREDENTIALS } from './servicesCredentials';

const HOST = {
  geocode: 'geocoder.api.here.com',
  search: 'geocoder.api.here.com',
  reversegeocode: 'reverse.geocoder.api.here.com',
};

const SOURCE = {
  geocode: 'geocode.json',
  search: 'search.json',
  reversegeocode: 'reversegeocode.json',
};

export default function getGeocoderSettings(key = 'geocode') {
  return {
    url: {
      jsonp: true,
      useSSL: true,
      host: HOST[key],
      path: '/6.2/',
      source: SOURCE[key],
    },
    app_id: CREDENTIALS.app_id,
    app_code: CREDENTIALS.app_code,
  };
}
