import defaultState from './defaultState';
import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';
import { TILE_LIBRARY_TYPES } from './constants';

const SET_DATA = `map/tiles/SET_DATA`;
const SET_TYPE = `map/tiles/SET_TYPE`;
const SET_LIBRARY_TYPE = `map/tiles/SET_LIBRARY_TYPE`;

function setTiles(state = defaultState, action) {
  switch (action.type) {
    case SET_DATA: {
      const { type, data } = action.payload;
      return {
        ...state,
        [type]: { ...state[type], ...data },
      };
    }
    case SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      };
    case SET_LIBRARY_TYPE:
      return {
        ...state,
        libraryType: action.payload,
      };
    default:
      return state;
  }
}

// force to use library type JSLA
const forceState = {
  libraryType: TILE_LIBRARY_TYPES.JSLA
}

export default localStorageReducerDecorator(
  setTiles,
  'mapTiles.v3',
  defaultState,
  forceState
);

export const getTilesData = (state) => state.map.tiles;

export const setTilesData = (type, data = {}) => ({
  type: SET_DATA,
  payload: { type, data },
});
export const setTilesType = (type) => ({ type: SET_TYPE, payload: { type } });
export const setTilesLibraryType = (type) => ({
  type: SET_LIBRARY_TYPE,
  payload: type,
});
