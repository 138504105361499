import * as types from './constants';
import defaultState from './defaultState';
import localStorageReducerDecorator from 'utils/localStorageReducerDecorator';

function setAppSettings(state, action) {
  switch (action.type) {
    case types.SET:
      return { ...state, ...action.data };

    default:
      return { ...state };
  }
}

export default localStorageReducerDecorator(
  setAppSettings,
  'appSettings.v4',
  defaultState
);
