export default function customizationSearchCircle(
  fields,
  center,
  radius
) {
  const { searchRegion } = fields;
  return {
    searchRegion: {
      ...searchRegion,
      in: `circle:${center.lat},${center.lng};r=${radius}`,
    },
  };
}
