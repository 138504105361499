export const setOrigin = (fields, e) => {
  const { lat: latFull, lng: lngFull } = e.latLng;
  const lat = latFull.toFixed(6);
  const lng = lngFull.toFixed(6);

  return {
    originDestinationKey: 'origin',
    originDestinationValue: `${lat},${lng}`,
  };
};

export const setDestination = (fields, e) => {
  const { lat: latFull, lng: lngFull } = e.latLng;
  const lat = latFull.toFixed(6);
  const lng = lngFull.toFixed(6);

  return {
    originDestinationKey: 'destination',
    originDestinationValue: `${lat},${lng}`,
  };
};
