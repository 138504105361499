import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props, setFields) => {
  const routes = get(props, 'result.raw.data.routes');
  if (!routes) {
    return [];
  }
  const currentRouteIndex = get(props, 'result.state.currentRoute', 0);
  const fields = get(props, 'fields', {});
  const excludes = get(fields, 'ev[excludeChargingStations]', '');
  const chargingStations = get(routes[currentRouteIndex], 'sections', [])
    .filter((section) => section.arrival.place.type === 'chargingStation')

  const currentRouteMarkers = [];
  chargingStations.forEach((section) => {
    const place = get(section, 'arrival.place');
    const customEvIcon = section.arrival.place.hasOwnProperty('waypoint') ? 'customEv' : null;

    if (section.arrival.place.displayLocation) {
      const { lat, lng } = section.arrival.place.displayLocation;
      currentRouteMarkers.push({
        value: `${lat},${lng}`,
        draggable: false,
        isCurrentRoute: true,
        text: '&#9679;',
        iconType: 'displayLocation',
        iconHeight: 26,
        iconWidth: 22,
        onChange: setFields,
        excludes,
        id: place.id,
        customEvIcon,
      });
    }
    const { lat, lng } = section.arrival.place.location;
    currentRouteMarkers.push({
      value: `${lat},${lng}`,
      text: '&#x1f50b;',
      draggable: false,
      isCurrentRoute: true,
      onChange: setFields,
      excludes,
      id: place.id,
      customEvIcon,
    });
  });

  const otherMarkers = [];
  routes
    .filter((route, index) => index !== currentRouteIndex)
    .forEach((route) => {
      get(route, 'sections', [])
        .filter((section) => section.arrival.place.type === 'chargingStation')
        .forEach((section) => {
          const { lat, lng } = section.arrival.place.location;
          const value = `${lat},${lng}`;
          const place = get(section, 'arrival.place');
          const customEvIcon = section.arrival.place.hasOwnProperty('waypoint') ? 'customEv' : null;

          if (currentRouteMarkers.find((marker) => marker.value === value)) {
            return;
          }
          if (section.arrival.place.displayLocation) {
            const { lat, lng } = section.arrival.place.displayLocation;
            otherMarkers.push({
              value: `${lat},${lng}`,
              draggable: false,
              isCurrentRoute: true,
              text: '&#9679;',
              iconType: 'displayLocation',
              iconHeight: 26,
              iconWidth: 22,
              onChange: setFields,
              excludes,
              id: place.id,
              customEvIcon,
            });
          }

          otherMarkers.push({
            value,
            text: '&#x1f50b;',
            draggable: false,
            isCurrentRoute: false,
            onChange: setFields,
            excludes,
            id: place.id,
            customEvIcon,
          });

        });
    });

  return [...currentRouteMarkers, ...otherMarkers];
};
