import React from 'react';

const isHttpWithHttps = (value) => {
  if (window.location.protocol === 'https:') {
    return /^http:\/\//.test(value);
  }
  return false;
};

export default isHttpWithHttps;

export const isHttpWithHttpsNotification = (setNotification) => {
  const host = window.location.host;
  const url = `http://${host}/`;
  setNotification({
    children: (
      <div>
        You can&#39;t use HTTP endpoints in HTTPS refclient. Go to&nbsp;
        <a href={url} target="__blank">
          {url}
        </a>
      </div>
    ),
    impact: 'significant',
  });
};
