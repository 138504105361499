import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Columns, Input } from '@here/ref-client-ui';
import { set, cloneDeep } from 'lodash';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';

class BoundingBox extends React.Component {
  onChange = (key, e) => {
    const { bounds, onChange } = this.props;
    const newBounds = set(cloneDeep(bounds), key, e.target.value);
    onChange(newBounds);
  };

  render() {
    const { bounds: [topRight = [], bottomLeft = []] = [] } = this.props;
    return (
      <Columns>
        <FormRow>
          <Input
            label="North"
            value={topRight[0] || ''}
            isValid={validateLat(topRight[0])}
            onBlur={(e) => this.onChange('0.0', e)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="West"
            value={bottomLeft[1] || ''}
            isValid={validateLon(bottomLeft[1])}
            onBlur={(e) => this.onChange('1.1', e)}
          />
        </FormRow>
        <FormRow>
          <Input
            label="South"
            value={bottomLeft[0] || ''}
            isValid={validateLat(bottomLeft[0])}
            onBlur={(e) => this.onChange('1.0', e)}
          />
        </FormRow>
        <FormRow>
          <Input
            label="East"
            value={topRight[1] || ''}
            isValid={validateLon(topRight[1])}
            onBlur={(e) => this.onChange('0.1', e)}
          />
        </FormRow>
      </Columns>
    );
  }
}

BoundingBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  bounds: PropTypes.array,
};

export default BoundingBox;
