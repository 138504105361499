import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SearchContext } from './searchContext';
import './searchable.scss';
import isArray from 'lodash/isArray';

function Searchable({ searchKey, children }) {
  const { search } = useContext(SearchContext);
  const ref = useRef();
  const lowerKeys = isArray(searchKey)
    ? searchKey.map((val) => val.toLowerCase())
    : searchKey.toLowerCase();
  useEffect(() => {
    if (
      isArray(lowerKeys)
        ? lowerKeys.indexOf(search) !== -1
        : search === lowerKeys
    ) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const isSelectedClass = classnames('rf-searchable', {
    'rf-searchable_selected': isArray(lowerKeys)
      ? lowerKeys.indexOf(search) !== -1
      : search === lowerKeys,
  });

  return (
    <div ref={ref} className={isSelectedClass} data-key={searchKey}>
      {children}
    </div>
  );
}

Searchable.propTypes = {
  searchKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Searchable;
