import { createSelector } from 'reselect';
import queryParamsSelector from './queryParamsSelector';
import { stringifyUrl } from 'utils/url';
import { getTabSettings } from '../tabs/tab/preset/reducer';

export default createSelector(
  queryParamsSelector,
  (state) => getTabSettings(state),
  stringifyUrl
);
