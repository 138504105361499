import React from 'react';
import PropTypes from 'prop-types';
import { Polygon as LeafletPolygon, Marker } from 'react-leaflet';
import { divIcon } from 'leaflet';
import './polygon.scss';
import { get } from 'lodash';

class Polygons extends React.Component {
  render() {
    const {
      options: { showUnselected, showMarkers, polygonsFormatter, selectedPath },
      selectedTab,
      tabIndex,
      refClient: { formatterPlugin },
      colorPalette,
    } = this.props;
    let selected = get(this.props, selectedPath);
    if (tabIndex !== selectedTab && !showUnselected) {
      return null;
    }

    const formatter = formatterPlugin.get(polygonsFormatter);

    const polygons = formatter(this.props);
    if (!selected) {
      selected = 0;
    }
    return polygons.map((polygon, index) => (
      <React.Fragment key={index}>
        <LeafletPolygon
          positions={polygon}
          color={
            selected === index ? colorPalette.primary : colorPalette.secondary
          }
        />

        {showMarkers &&
          polygon.map((position, index) => {
            const icon = divIcon({
              html: `<div class="rf-polygon__marker">${index + 1}</div>`,
            });
            return <Marker key={index} position={position} icon={icon} />;
          })}
      </React.Fragment>
    ));
  }
}

Polygons.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  selectedTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  colorPalette: PropTypes.object,
};

export default Polygons;
