import selectPlugin from '../select/selectFormView';

class ProfileSelect extends selectPlugin.Component {
  onChange = (e) => {
    const {
      options: { key },
      setFields,
      fields: { regionDefinition = {} },
      setNotification,
    } = this.props;
    const { value } = e.target;
    const newFields = { [key]: value };
    if (value !== '' && regionDefinition.type !== 'world') {
      newFields.regionDefinition = { type: 'world' };
      setNotification({
        message: 'Region definition has been set to `world`',
        autoDismiss: 5,
      });
    }

    setFields(newFields);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...selectPlugin,
  Component: ProfileSelect,
};
