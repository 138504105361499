import React, { useRef, useState, useEffect } from 'react';
import HerePolyline from 'shared/hereMap/HerePolyline';
import { useHereMap } from '../../../views/shared/hereMap/HereMapContext';
import PropTypes from 'prop-types';
import HereMapContextMenuItem from 'shared/hereMap/HereContextMenuItem';
import get from 'lodash/get';
import { decode, encode } from 'utils/flexPolyline';

function getPolyline(route) {
  const { sections = [] } = route;

  if (sections.length === 0) {
    return '';
  } else if (sections.length === 1) {
    return sections[0].polyline;
  } else {
    let points = [];
    sections.forEach(section => {
      const p = decode(section.polyline);
      points = points.concat(p);
    })
    return encode(points);
  }
}

function HereNoticesPolyline({
  withInteractions,
  positions,
  color,
  isSelected,
  selectNotice,
  setHovered,
  index,
  props
}) {
  const { 
    selectedTab, 
    tabIndex, 
    options = {}, 
    refClient: { formatterPlugin },
    map
  } = props;
  const {
    contextMenuFormatter, 
    contextMenuLabel, 
    getMapAttributes
  } = options;

  const isUnmounted = useRef(false);
  const [open, setOpen] = useState(null);
  const [position, setPosition] = useState(null);

  const style = {
    strokeColor: color,
    lineWidth: 10,
  };

  if (!isSelected) {
    style.lineDash = [1, 3];
  }
  const interactionProps = {};
  if (withInteractions) {
    interactionProps.onTap = selectNotice;
    interactionProps.onPointerLeave = () => setHovered(null);
    interactionProps.onPointerEnter = () => setHovered(index);
  }

  const close = () => {
    if (!isUnmounted.current) {
      setOpen(false);
    }
    window.removeEventListener('click', close);
    map.removeEventListener('mapviewchangestart', close);
  };

  const onContextMenu = (e) => {
    if (selectedTab !== tabIndex) {
      return;
    }
    e.stopPropagation();
    const {
      originalEvent: { x, y },
    } = e;
    setOpen(true);
    setPosition({
      top: y,
      left: x,
    });
    window.addEventListener('click', close);
    map.addEventListener('mapviewchangestart', close);
  };

  const getMapAttributesFunc = () => {
    const { addTab, fields, options: { selectedPath } } = props;
    const selectedRoute = get(props, selectedPath, 0);
    const routes = get(props, "result.raw.data.routes", []);
    const flexiblePolyline = getPolyline(routes[selectedRoute] || {});

    const { key, value } = fields.routeTime;
    const routeTime = (key==="departureTime" && value!== "any") ? {key, value} : {key:'', value:''};
    const trailersCount = fields['vehicle[trailerCount]'] > 3 ? '3' : fields['vehicle[trailerCount]'];
    const shippedHazardousGoods = JSON.parse(JSON.stringify(fields['vehicle[shippedHazardousGoods]']));
    const index = shippedHazardousGoods.values.indexOf('radioactive');
    if (index !== -1) {
      shippedHazardousGoods.values[index] = 'radioActive';
    }
    const mafields = {
      mapAttributes: {
        flexiblePolyline,
        type: "flexiblePolyline"
      },
      transportMode: fields.transportMode,
      routeTime,
      vehicleWeight: fields['vehicle[grossWeight]'],
      length: fields['vehicle[length]'],
      trailersCount,
      shippedHazardousGoods
    }

    addTab({ title: 'Map Attributes', fields: mafields });
  }

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
      window.addEventListener('click', close);
      map.addEventListener('mapviewchangestart', close);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HerePolyline
        latLngs={positions}
        options={{ zIndex: 500, style }}
        changeCursorOnHover
        {...interactionProps}
        onContextMenu={onContextMenu}
      />
      { contextMenuFormatter && open && (
        <div className="rf-context-menu" style={{ ...position }}>
          { contextMenuFormatter && (
            <HereMapContextMenuItem
              label={contextMenuLabel}
              onClick={formatterPlugin
                .get(contextMenuFormatter)
                .bind(this, props)}
            />
          )}
          { getMapAttributes && (
            <HereMapContextMenuItem
            label={`Get Map Attributes`}
            onClick={() => getMapAttributesFunc()}
          />
          )}
        </div>
      )}
    </>
  );
}

HereNoticesPolyline.propTypes = {
  withInteractions: PropTypes.bool.isRequired,
  positions: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectNotice: PropTypes.func.isRequired,
  setHovered: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default HereNoticesPolyline;

export const useHereOnMove = (setSelectedShape) => {
  const { map } = useHereMap();
  useEffect(() => {
    const hide = () => setSelectedShape(null);
    map.addEventListener('mapviewchangestart', hide);

    return () => {
      map.removeEventListener('mapviewchangestart', hide);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
