import { TILE_TYPES, TILE_URLS, TILE_LIBRARY_TYPES, TILE_PATHS } from './constants';

const type = window.navigator.webdriver ? TILE_TYPES.RASTERV3 : TILE_TYPES.VECTOR;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  libraryType: TILE_LIBRARY_TYPES.JSLA,
  type,
  raster: {
    type: 'maptile',
    scheme: 'normal.day',
    size: '256',
    format: 'png',
    additionalParameters: '',
    lg: 'eng',
    pview: 'DEF',
    url: 'hereapi.com',
    apiKey: 'DEFAULT_API_KEY',
    appId: '',
    appCode: '',
  },
  rasterv3: {
    type: 'base',
    style: 'explore.day',
    ppi: '-auto-',
    size: '-none-',
    format: 'png',
    additionalParameters: '',
    lg: '-none-',
    pview: '-none-',
    projection: 'mc',
    url: 'hereapi.com',
    fPois: '-none-',
    fCongestionZones: '-none-',
    fEnvZones: '-none-',
    fVehicleRestrictions: '-none-',
    apiKey: 'DEFAULT_API_KEY',
    appId: '',
    appCode: '',
  },
  vector: {
    url: TILE_URLS[0],
    pview: '-none-',
    path: TILE_PATHS[0],
    apiKey: 'DEFAULT_API_KEY',
    styleUrl: 'https://js.api.here.com/v3/3.1/styles/omv/miami/normal.day.yaml',
  },
  vectorHARP: {
    url: TILE_URLS[0],
    pview: '-none-',
    path: TILE_PATHS[0],
    apiKey: 'DEFAULT_API_KEY',
    styleUrl: 'https://js.api.here.com/v3/3.1/styles/harp/oslo/normal.day.json',
  },
};
