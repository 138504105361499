import React from 'react';
import FuelConsumptionComponent from './OlsFuelConsumptionComponent';
import PropTypes from 'prop-types';
import utils from 'utils';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import set from 'lodash/set';
import { empty } from './olsFuelPreset';

class ConsumptionViewFuel extends React.Component {
  onChange = (key, e) => {
    const { setFields } = this.props;
    setFields({ [key]: utils.extractData(e) });
  };

  onMultiUpdate = (data) => {
    const { setFields } = this.props;
    setFields(data);
  };

  render() {
    const { fields, options = {}, settings } = this.props;
    const {
      chargeAwareRoutingEnabled = true,
      showPreset
    } = options;
    return (
      <FuelConsumptionComponent
        formData={{ fields }}
        showPreset={showPreset}
        chargeAwareRoutingEnabled={chargeAwareRoutingEnabled}
        settings={settings}
        onChange={this.onChange}
        onMultiUpdate={this.onMultiUpdate}
      />
    );
  }
}

ConsumptionViewFuel.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  settings: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

const fuelParamMask = /^fuel\[.+]$/;

const fuelParamsMap = {
  'fuel[freeflowspeedtable]': 'fuel[freeFlowSpeedTable]',
  'fuel[trafficspeedtable]': 'fuel[trafficSpeedTable]',
  'fuel[additionalconsumption]': 'fuel[additionalConsumption]',
  'fuel[type]': 'fuel[type]',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, postData = {} }) => {
    const result = {};

    Object.keys(parsedParams).forEach((param) => {
      if (fuelParamMask.test(param)) {
        const resKey = fuelParamsMap[param] || param;
        
        result[resKey] = parsedParams[param];
        delete parsedParams[param];
      }
    });

    if (postData.fuel) {
      Object.keys(postData.fuel).forEach((key) => {
        const resKey = `fuel[${key}]`;
        result[resKey] = postData.fuel[key];
        if (!result.evPost) {
          result.evPost = {};
        }
        result.evPost[resKey] = true;
      });
    }

    result.fuelEnabled = !!Object.keys(result).length;
    return { ...empty, ...result };
  },
  defaultState: () => ({
    ...empty,
  }),
  getRequestOptions: (fields) => {
    if (fields.fuelEnabled) {
      let params = omit(fields, 'fuelEnabled', 'evPost');
      let data = {};
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if (fuelParamMask.test(key) && params[key].length !== 0) {
          if (fields.evPost[key]) {
            set(data, key, params[key]);
            delete params[key];
          } else if (isArray(params[key])) {
            params[key] = params[key].join(',');
          }
        } else {
          delete params[key];
        }
      });
      const res = { params };
      if (Object.keys(data).length > 0) {
        res.data = data;
        res.method = 'post';
      }
      return res;
    } else {
      return {};
    }
  },
  Component: ConsumptionViewFuel,
};
