import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@here/ref-client-ui';
import './autocomplete.scss';
import OutsideClickHandler from '../outsideClickHandler';

function RfAutocomplete({ data, placeholder, onSelect, label, showListRegex }) {
  const [inputValue, setInputValue] = useState('');
  const [showList, setShowList] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const lowerCasedData = data.map((val) => val.toLowerCase());

  const onChange = (e) => {
    let value = inputValue;
    if (e) {
      value = e.target.value;
    }
    setInputValue(value);
    setHoveredIndex(null);
    const lowerCasedValue = value.toLowerCase();
    setFilteredList(
      data.filter(
        (item, index) => lowerCasedData[index].indexOf(lowerCasedValue) !== -1
      )
    );
    if (showListRegex.test(value)) {
      setShowList(true);
    } else {
      setShowList(false);
    }
    if (value === '') {
      onSelect(null);
    }
  };

  const select = (item) => {
    setInputValue(item);
    setShowList(false);
    setHoveredIndex(null);
    onSelect(item);
  };

  const onKeyDown = (e) => {
    if (!showList) {
      return;
    }
    let preventDefault = false;
    // eslint-disable-next-line default-case
    switch (e.key) {
      case 'Down':
      case 'ArrowDown': {
        if (hoveredIndex === null) {
          setHoveredIndex(0);
        } else if (hoveredIndex + 1 < filteredList.length - 1) {
          setHoveredIndex(hoveredIndex + 1);
        }
        preventDefault = true;
        break;
      }
      case 'Up':
      case 'ArrowUp': {
        if (hoveredIndex === 0) {
          setHoveredIndex(null);
        } else if (hoveredIndex > 0) {
          setHoveredIndex(hoveredIndex - 1);
        }
        preventDefault = true;
        break;
      }
      case 'Enter':
        if (hoveredIndex !== null) {
          e.target.blur();
          select(filteredList[hoveredIndex]);
        }
        preventDefault = true;
        break;
      case 'Esc':
      case 'Escape':
        e.target.blur();
        setShowList(false);
        setHoveredIndex(null);
        preventDefault = true;
        break;
    }
    if (preventDefault) {
      e.preventDefault();
    }
  };

  return (
    <OutsideClickHandler
      outsideClick={() => setShowList(false)}
      cssClasses="rf-filter-input"
    >
      <Input
        label={label}
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onChange}
        onKeyDown={onKeyDown}
      />
      {showList && (
        <ul>
          {filteredList.map((item, index) => (
            <li
              key={item}
              onClick={() => select(item)}
              className={
                index === hoveredIndex ? 'rf-filter-input__item_hovered' : null
              }
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </OutsideClickHandler>
  );
}

RfAutocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  data: PropTypes.array,
  showListRegex: PropTypes.object,
};

export default RfAutocomplete;
