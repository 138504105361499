import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import isHttpWithHttps, {
  isHttpWithHttpsNotification,
} from './isHttpWithHttps';

class Credentials extends React.Component {
  onChange(key, { target: { value } }) {
    if (isHttpWithHttps(value)) {
      const { setNotification } = this.props;
      isHttpWithHttpsNotification(setNotification);
    }
    this.props.onChange({ [key]: value });
  }

  render() {
    const { onChange, isCustom, type, setNotification, ...credentials } =
      this.props;

    const credentialEls = Object.keys(credentials).map((credKey) => (
      <FormRow key={credKey}>
        <Input
          label={credKey}
          value={credentials[credKey]}
          onBlur={this.onChange.bind(this, credKey)}
          isReadonly={!isCustom}
          blurOnEnter
          isCompact
        />
      </FormRow>
    ));
    return <div>{credentialEls}</div>;
  }
}

Credentials.propTypes = {
  onChange: PropTypes.func.isRequired,
  isCustom: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({}),
  getDisplayUrl: () => '',
  getRequestOptions: () => ({}),
  Component: Credentials,
};
