function callback(e, setFields, fields) {
  const { lat, lng } = e.latLng;
  const coords = `${lat.toFixed(6)},${lng.toFixed(6)}`;
  const { waypoints } = fields;
  const oldWaypoint = waypoints[waypoints.length - 1];
  const value = { ...oldWaypoint, coords };
  const newWaypoints = [...waypoints.slice(0, -1), value];
  setFields({ waypoints: newWaypoints });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ setFields, fields }) {
  return {
    text: 'Route to',
    callback: (e) => callback(e, setFields, fields),
  };
}
