import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormRow } from '@here/ref-client-ui';
import CustomMultiSelect from 'shared/multiSelect';

class MultiSelect extends React.Component {
  onChange = (e) => {
    const {
      setFields,
      options: { key },
    } = this.props;

    setFields({ [key]: e ? e.map(({ value }) => value).join(',') : '' });
  };

  render() {
    const { options, fields } = this.props;
    const { key, params, paramsKey, label } = options;
    let selectParams = null;
    if (params) {
      selectParams = params;
    } else {
      selectParams = get(this.props, paramsKey, []);
    }
    const values = fields[key] ? fields[key].split(',') : [];
    return (
      <FormRow>
        <CustomMultiSelect
          label={label}
          values={values}
          options={selectParams}
          onChange={this.onChange}
        />
      </FormRow>
    );
  }
}

MultiSelect.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData = {} }) => {
    const { key, isPost } = options;
    const keyLowerCase = key.toLowerCase();
    if (isPost) {
      return { [key]: (postData[key] || []).join(',') };
    }
    const res = { [key]: parsedParams[keyLowerCase] || '' };
    delete parsedParams[keyLowerCase];
    return res;
  },
  defaultState: (options) => ({ [options.key]: options.initState || '' }),
  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;

    if (!fields[key]) return {};

    if (isPost) {
      return {
        method: 'post',
        data: {
          [key]: fields[key].split(','),
        },
      };
    }

    return { params: { [key]: fields[key] } };
  },
  Component: MultiSelect,
};
