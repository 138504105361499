import * as hpolyline from '@here/hpolyline';

const POLYLINE_CACHE = {};

export function decode(encodedPolyline) {
  if (POLYLINE_CACHE[encodedPolyline]) {
    return POLYLINE_CACHE[encodedPolyline];
  }
  const decodedPolyline = hpolyline.decode(encodedPolyline).polyline;
  POLYLINE_CACHE[encodedPolyline] = decodedPolyline;
  return decodedPolyline;
}

export function encode(polyline) {
  const encodedPolyline = hpolyline.encode({polyline});
  return encodedPolyline;
}
