import { RESTORE_FROM_URL } from './constants';
import { assignTabDataAndDispatch } from './tabs/actions';

export const restoreFromUrl = (title, preset, fields, tabTitle, tabIndex) =>
  assignTabDataAndDispatch(
    {
      type: RESTORE_FROM_URL,
      payload: { title, preset, fields, tabTitle },
    },
    tabIndex
  );
