// eslint-disable-next-line import/no-anonymous-default-export
export default {
  evEnabled: true,

  // eslint-disable-next-line max-len
  'ev[freeFlowSpeedTable]':
    '10,0.130,20,0.132,30,0.136,40,0.142,50,0.151,60,0.161,70,0.176,80,0.194,90,0.214,100,0.237,110,0.264,120,0.290,130,0.321,140,0.354,150,0.389,160,0.426,170,0.466,180,0.509,190,0.554,200,0.601',
  // eslint-disable-next-line max-len
  'ev[trafficSpeedTable]':
    '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
  'ev[auxiliaryConsumption]': '1.8',
  'ev[ascent]': '9',
  'ev[descent]': '4.3',
};
