import { get, every } from 'lodash';

export function isConditionSatisfied(props, condition) {
  if (!condition) {
    return true;
  }
  const { type, key, value } = condition;
  switch (type) {
    case 'equals':
      return get(props, key) === value;
    case 'contains':
      return value.includes(get(props, key));
    default:
      return true;
  }
}

export function isConditionsSatisfied(props, conditions) {
  // console.log(conditions.length, 8888, props)
  return every(conditions, (condition) =>
    isConditionSatisfied(props, condition)
  );
}

export function addOpacityToHexColor(hex, opacity = 1) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )},${opacity})`
    : null;
}

export function interpolateLine(latA, lonA, latB, lonB, latInterval) {
  const points = [];
  
  // Check if latA equals latB (horizontal line)
  if (latA === latB) {
      // Interpolate by longitude only since latitudes are the same
      const step = lonA < lonB ? 0.5 : -0.5;
      let currentLon = lonA;
      
      while ((step > 0 && currentLon <= lonB) || (step < 0 && currentLon >= lonB)) {
          points.push({ lat: latA, lng: currentLon });
          currentLon += step;
      }
  } else {
      // Normal interpolation by latitude
      const step = latA < latB ? latInterval : -latInterval;
      let currentLat = latA;

      // Interpolate while latitude is within the range
      while ((step > 0 && currentLat <= latB) || (step < 0 && currentLat >= latB)) {
          const fraction = (currentLat - latA) / (latB - latA);
          const currentLon = lonA + fraction * (lonB - lonA);
          points.push({ lat: currentLat, lng: currentLon });
          currentLat += step;
      }
  }

  return points;
}

export function calculateBearingFromPixels(xA, yA, xB, yB) {
  // Calculate the differences in x and y
  const deltaX = xB - xA;
  const deltaY = yB - yA;

  // Calculate the angle in radians
  const angleRadians = Math.atan2(deltaX, deltaY);

  // Convert the angle to degrees
  let angleDegrees = angleRadians * (180 / Math.PI);

  // Normalize the angle to be within 0 to 360 degrees
  let bearing = (angleDegrees*-1 - 90 + 360) % 360;

  return bearing;
}