import copyToClipboard from 'copy-to-clipboard';
import { fetchAddress } from '../../../utils/api/geocoder';

function callback(e, setNotification) {
  fetchAddress(`${e.latLng.lat},${e.latLng.lng}`)
    .then((address) => {
      copyToClipboard(address);
      setNotification({
        message: 'Address copied to clipboard',
        impact: 'positive',
        autoDismiss: 2,
      });
    })
    .catch((err) => {
      setNotification({
        message: err.message,
        impact: 'negative',
        autoDismiss: 0,
      });
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setNotification }) {
  return {
    text: options.text,
    callback: (e) => callback(e, setNotification),
  };
}
