import autoRefreshPlugin from '../autoRefreshPlugin';
import required from './required';
import waypointsPerformed from './waypointsPerformed';
import somePerformed from './somePerformed';
import never from './never';
import allFieldsPerformed from './allFieldsPerformed';

autoRefreshPlugin.register('required', required);
autoRefreshPlugin.register('waypointsPerformed', waypointsPerformed);
autoRefreshPlugin.register('somePerformed', somePerformed);
autoRefreshPlugin.register('never', never);
autoRefreshPlugin.register('allFieldsPerformed', allFieldsPerformed);
