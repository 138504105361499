// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, { latLng: { lat, lng } }) => {
  const {
    regionDefinition: { type, outer },
  } = fields;
  return {
    regionDefinition: {
      type,
      outer: [...outer, { lat: +lat.toFixed(6), lng: +lng.toFixed(6) }],
    },
  };
};
