import mapPlugin from '../mapPlugin';
import Polygons from './polygon/Polygons';
import olsIsolineMap from './olsIsoline';
import inspectSegment from './inspectSegment';
import routes from './routes';
import polylines from './polylines';
import ptRoutes from './ptRoutes';
import route from './route';
import tileRects from './tileRects';
import setDefaultMapCenter from './contextMenuItem/setDefaultMapCenter';
import showInMap from './contextMenuItem/showInMap';
import noticesPlugin from './notices';
import group from './group';
import mapAttributes from './mapAttributesLines';

mapPlugin.register('polygons', Polygons);
mapPlugin.register('olsIsolineMap', olsIsolineMap);
mapPlugin.register('inspectSegment', inspectSegment);
mapPlugin.register('routes', routes);
mapPlugin.register('polylines', polylines);
mapPlugin.register('ptRoutes', ptRoutes);
mapPlugin.register('route', route);
mapPlugin.register('tileRects', tileRects);
mapPlugin.register('setDefaultMapCenter', setDefaultMapCenter);
mapPlugin.register('showInMap', showInMap);
mapPlugin.register('notices', noticesPlugin);
mapPlugin.register('group', group);
mapPlugin.register('mapAttributes', mapAttributes);
