import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import CorridorList from './CorridorList';
import ELeafletCorridor from './ELeafletCorridor';
import EHereCorridor from './EHereCorridor';
import LeafletPolygonMarker from '../polygonList/LeafletPolygonMarker';
import HerePolygonMarker from '../polygonList/HerePolygonMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: CorridorList(ELeafletCorridor, LeafletPolygonMarker, TILE_LIBRARY_TYPES.LEAFLET),
  [TILE_LIBRARY_TYPES.JSLA]: CorridorList(EHereCorridor, HerePolygonMarker, TILE_LIBRARY_TYPES.JSLA),
};
