import React from 'react';
import { render } from 'react-dom';
import '@here/ref-client-ui/dist/main.css';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import 'rc-slider/assets/index.css';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import * as data from './state';
import { Route } from 'react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import initGlobalErrors from './utils/globalErrors';
import settingsInitState from './state/settings/default/initState';
import { addTab } from './state/tabs/actions';
import { getDefaultModule } from './state/appSettings';
import { Provider } from 'react-redux';
import App from './views/App';
import refClient from './core/refClient';
import '@here/lui-core/dist/lui-core.min';


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  renderRefClient: (configs, el) => {
    const history = createBrowserHistory();
    const reducer = combineReducers({
      ...data.appReducers,
      router: connectRouter(history),
    });

    // Sync dispatched route actions to the history
    const reduxRouterMiddleware = routerMiddleware(history);

    const middlewares = [reduxRouterMiddleware, thunkMiddleware];

    let createStoreWithMd;
    if (process.env.NODE_ENV !== 'production') {
      createStoreWithMd = composeWithDevTools(
        applyMiddleware(...middlewares, createLogger({ collapsed: true }))
      )(createStore);
    } else {
      initGlobalErrors();
      createStoreWithMd = composeWithDevTools(applyMiddleware(...middlewares))(
        createStore
      );
    }

    const store = createStoreWithMd(reducer, {
      settings: { default: settingsInitState(configs) },
    });

    store.dispatch(addTab({ title: getDefaultModule(store.getState()) }));

    render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route path="/" component={App} />
        </ConnectedRouter>
      </Provider>,
      el
    );
  },
  ...refClient,
  loadConfig: refClient.loadConfig,
  loadKeys: refClient.loadKeys,
};
