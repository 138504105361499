const NS = 'appSettings';

export const SET = `${NS}/SET`;

export const UI_SIZE = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
};

export const ROUTE_TIMEZONE = {
  CLIENT_LOCAL: "CLIENT",
  DEPARTURE: "DEPARTURE",
  ARRIVAL: "ARRIVAL",
  LOCAL: "LOCAL",
};
