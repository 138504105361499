import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import {
  Button,
  CloseButton,
  ExpandFormRow,
  FormRow,
  Input,
} from '@here/ref-client-ui';
import set from 'lodash/set';
import get from 'lodash/get';
import './excludeStates.scss';

function ExcludeStates(props) {
  const { fields, options, setFields } = props;
  const {
    key,
    label,
    placeholder,
    openapiInfo: openapiInfoOptions,
    tooltip,
  } = options;
  const [openapiInfo, setOpenapiInfo] = useState(null);
  const isUnmounted = useRef(false);

  useEffect(() => {
    if (openapiInfoOptions) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfoOptions;
      const propsUrl = get(props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys).then((openapiInfo) => {
        if (!isUnmounted.current) {
          setOpenapiInfo(openapiInfo);
        }
      });
    }

    return () => {
      isUnmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTooltip = () => {
    let tooltipEl = null;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  const items = fields[key] || [];

  const onAddCountry = () => {
    setFields({ [key]: [...items, { country: '', codes: [''] }] });
  };

  const onAddState = (index) => {
    const item = items[index];
    const { country, codes = [] } = item;
    codes.push('');
    setFields({ 
      [key]: [...items.slice(0, index), { country, codes }, ...items.slice(index + 1)],
    });
  };

  const onChangeCountry = ({ target: { value } }, index) => {
    const item = items[index];
    const { codes = [] } = item;
    
    setFields({
      [key]: [...items.slice(0, index), { country: value, codes }, ...items.slice(index + 1)],
    });
  };

  const onChangeState = ({ target: { value } }, index, i) => {
    const item = items[index];
    const { country, codes = [] } = item;
    codes[i] = value;
    setFields({
      [key]: [...items.slice(0, index), { country, codes }, ...items.slice(index + 1)],
    });
  };

  const onRemove = (index) => {
    setFields({ [key]: [...items.slice(0, index), ...items.slice(index + 1)] });
  };

  const onRemoveState = (index, i) => {
    const item = items[index];
    const { country, codes = [] } = item;

    setFields({ 
      [key]: [
        ...items.slice(0, index), 
        { country, codes: [...codes.slice(0, i), ...codes.slice(i + 1)] }, 
        ...items.slice(index + 1)
      ] 
    });
  };

  const els = items.map((item, index) => {
    const { country, codes = [] } = item;
    const statesList = codes.map((state, i) => (
      <FormRow key={`${index}${i}states`} className="rf-exclude-states__item">
        <Input
          key={`${country}states${index}${i}`}
          value={state}
          placeholder={'State Code'}
          onBlur={(e) => onChangeState(e, index, i)}
        />
        <CloseButton onClick={() => onRemoveState(index, i)} />
      </FormRow>
    ))
    
    return (
      <FormRow key={index}>
        <FormRow key={index+'country'} className="rf-exclude-states__item">
          <Input
            value={country}
            placeholder={placeholder}
            onBlur={(e) => onChangeCountry(e, index)}
          />
          <Button title="Add State" onClick={onAddState.bind(this, index)} />
          <CloseButton onClick={() => onRemove(index)} />
        </FormRow>
        {statesList}
      </FormRow>
    )
  });

  return (
    <ExpandFormRow
      label={label}
      isExpanded
      className="rf-exclude-states"
      tooltip={getTooltip()}
      tooltipPlacement="right"
    >
      {els}
      <FormRow className="rf-exclude-states__btn">
        <Button title="Add Country" onClick={onAddCountry} />
      </FormRow>
    </ExpandFormRow>
  );
}

ExcludeStates.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData }) => {
    const { key, isPost } = options;
    const keyLowerCase = key.toLowerCase();
    if (postData && isPost) {
      return { [key]: get(postData, key, []) };
    }
    const paramsArray = parsedParams[keyLowerCase]
    ? parsedParams[keyLowerCase].split('|')
    : [];
    const states = paramsArray.map(state => {
      const [country, codes = ''] = state.split(':');
      return {country, codes: codes.split(',')};
    })

    delete parsedParams[keyLowerCase];
    return { [key]: states };
  },
  defaultState: (options) => ({
    [options.key]: [],
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;

    if (!fields[key] || fields[key].length === 0) return {};

    if (isPost) {
      const data = {};
      set(data, key, fields[key]);
      return {
        method: 'post',
        data,
      };
    } else {
      const states = fields[key].map(({ country, codes }) => `${country}:${codes.join(',')}`);
      return {
        params: { [key]: states.join('|') },
      };
    }
  },
  Component: ExcludeStates,
};
