
import React from 'react';
import PropTypes from 'prop-types';
import { withLeaflet, Polyline } from 'react-leaflet';
// import 'leaflet-path-drag';

class LeafletPolyline extends React.Component {
  ref = React.createRef();

  componentDidUpdate(prevProps) {
    const { draggable } = this.props;
    const { leafletElement: polygon } = this.ref.current;
    if (polygon.dragging) {
      if (draggable) {
        polygon.dragging.enable();
      }
      else
        polygon.dragging.disable();
    }
  }

  render() {
    const { positions, geometryIndex, color, weight, opacity } = this.props;
    const polylineClass = 'rf-corridor__polyline-draggable'+geometryIndex;
    const props = {};
    if (color) {
      props.color = color;
    }
    if (weight) {
      props.weight = weight;
    }
    if (opacity) {
      props.opacity = opacity;
    }
    return (
      <Polyline
        className={polylineClass}
        positions={positions} 
        draggable={true} // workaround that not enable draggable when this is set to false 
        ref={this.ref}
        {...{ ...props }}
      />
    );
  }
}

LeafletPolyline.propTypes = {
  leaflet: PropTypes.object.isRequired,
  positions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  geometryIndex: PropTypes.number,
};

export default withLeaflet(LeafletPolyline);