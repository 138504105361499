import React from 'react';
import PropTypes from 'prop-types';
import LeafletPolyline from 'shared/leafletPolyline';

function LeafletPolylines({
    positions, coatPositions, geometryIndex, strokeColor, fillColor, lineWidth, opacity
}) {
    return <LeafletPolyline       
      positions={positions}
      coatPositions={coatPositions}
      geometryIndex={geometryIndex}
      draggable={false}
      resizable={false}
      onChange={()=>{}}
      color={strokeColor}
      fillColor={fillColor}
      weight={lineWidth}
      opacity={opacity}
    />
}

LeafletPolylines.propTypes = {
  zIndex: PropTypes.number,
};

export default LeafletPolylines;
