import React, { Component } from 'react';

export default class RightArrow extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.3"
        height="15.82"
        viewBox="0 0 9.3 15.82"
      >
        <path
          id="hide_response"
          d="M10956.9,1880l-7.9,7.9-1.4-1.42,6.5-6.48-6.5-6.49,1.4-1.41Z"
          transform="translate(-10947.6 -1872.09)"
        />
      </svg>
    );
  }
}
