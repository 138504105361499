import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CustomDateTime from 'shared/datetime';
import { FormRow, Select } from '@here/ref-client-ui';

import utilsDate from 'utils/date';

import './styles/dateTimeField.scss';

const timezoneMinutesOptions = ['00', '15', '30'];

class DateTimeField extends Component {
  onTimezoneHourChange = ({ target: { value } }) => {
    const { date, timezone, onChange } = this.props;
    const [, minutes] = timezone.split(':');
    if (/[+-]\d\d/.test(value)) {
      onChange(`${date}${value}:${minutes || '00'}`);
    } else {
      onChange(`${date}${value}`);
    }
  };

  onTimezoneMinutesChange = ({ target: { value } }) => {
    const { date, timezone, onChange } = this.props;
    const [hours] = timezone.split(':');
    onChange(`${date}${hours}:${value}`);
  };

  onDateChange = (date) => {
    this.props.onChange(date);
  };

  render() {
    const { timezone, date, isLocalSupported } = this.props;
    const generatedTimeZones = utilsDate.generateTimezoneList();
    const localOption = { key: '', label: 'local', value: '' };
    const [hours, minutes] = timezone.split(':');
    let timeZoneOptions = null;
    if (isLocalSupported) {
      timeZoneOptions = [localOption, ...generatedTimeZones];
    } else {
      timeZoneOptions = [...generatedTimeZones];
    }
    const value = `${date}${timezone}`;

    return (
      <div className="rf-datetime-field">
        <FormRow>
          <CustomDateTime
            value={value}
            onChange={this.onDateChange.bind(this)}
          />
        </FormRow>
        <FormRow>
          <div className="rf-datetime-field__timezone">
            <Select
              label="Time Zone Hours"
              value={hours}
              options={timeZoneOptions}
              onChange={this.onTimezoneHourChange.bind(this)}
            />
            {/[+-]\d\d/.test(hours) && (
              <Select
                label="Time Zone Minutes"
                value={minutes}
                options={timezoneMinutesOptions}
                onChange={this.onTimezoneMinutesChange.bind(this)}
              />
            )}
          </div>
        </FormRow>
      </div>
    );
  }
}

DateTimeField.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLocalSupported: PropTypes.bool,
};

export default DateTimeField;
