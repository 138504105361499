import { ExpandFormRow, Select, Input, FormRow } from '@here/ref-client-ui';
import PropTypes from 'prop-types';

const selectOptions = [
  { label: 'Distance', value: 'distance' },
  { label: 'Time', value: 'time' },
  { label: 'Consumption', value: 'consumption' },
];

function IsolineRange({ fields, setFields }) {
  const { fuelEnabled } = fields;
  const fuelType = fields['fuel[type]'];
  const changeType = ({ target: { value } }) => {
    setFields({ 'range[type]': value });
  }

  const changeValues = ({ target: { value } }) => {
    setFields({ 'range[values]': value });
  }

  let valuesLabel = 'Range values ';
  switch(fields['range[type]']) {
    case 'distance':
      valuesLabel += '(meters)';
      break;
    case 'time':
      valuesLabel += '(seconds)';
      break;
    case 'consumption':
      if (fuelEnabled) {
        if (fuelType === 'cng') {
          valuesLabel += '(mg)';
        } else {
          valuesLabel += '(ml)';
        }
      } else { // evEnabled and by default
        valuesLabel += '(Wh)';
      }
      break;
    default:
  }

  return (
    <ExpandFormRow label="Range" isExpanded className="rf-grey-box">
      <FormRow>
        <Select
          options={selectOptions}
          label="Range type"
          onChange={changeType}
          value={fields['range[type]']}
        />
      </FormRow>
      <FormRow>
        <Input
          label={valuesLabel}
          onBlur={changeValues}
          blurOnEnter
          value={fields['range[values]']}
          tooltip="A comma-separated list of ranges."
        />
      </FormRow>
    </ExpandFormRow>
  );
}

IsolineRange.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

const isolineRangePlugin = {
  parseUrl: ({ parsedParams }) => {
    const res = {};
    res['range[type]'] = parsedParams['range[type]'];
    res['range[values]'] = parsedParams['range[values]'];
    delete parsedParams['range[type]'];
    delete parsedParams['range[values]'];

    return res;
  },
  defaultState: () => ({
    'range[type]': 'distance',
    'range[values]': '',
  }),
  getRequestOptions: (fields) => {
    const params = {};
    if (fields['range[type]']) {
      params['range[type]'] = fields['range[type]'];
    }

    if (fields['range[values]']) {
      params['range[values]'] = fields['range[values]'];
    }
    return { params };
  },
  Component: IsolineRange,
};

export default isolineRangePlugin;
