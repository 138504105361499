import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import stringParseUrl from '../stringParseUrl';
import set from 'lodash/set';
import isFinite from 'lodash/isFinite';
import get from 'lodash/get';
import './inputFormView.scss';
import { getOpenapiUrl, getOpenapiInfo } from '../openapiHelpers';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class InputFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
  }

  componentDidUpdate() {
    const {
      options: { key, removeSpaces },
      fields,
      setFields,
    } = this.props;

    const field = fields[key];
    if (removeSpaces && field) {
      const noSpaces = field.replace(/\s/g, '');
      if (noSpaces !== field) {
        setFields({ [key]: noSpaces });
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChange = ({ target: { value } }) => {
    const {
      setFields,
      options: { key, inputType },
    } = this.props;
    if (inputType === 'number' && isFinite(+value) && value !== '') {
      setFields({ [key]: +value });
    } else {
      setFields({ [key]: value });
    }
  };

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  isValid = () => {
    const {
      options: { key, validation = [] },
      fields,
    } = this.props;
    const value = fields[key];

    if (!value) {
      return true;
    }

    for (let i = 0; i < validation.length; i++) {
      const validationConfig = validation[i];
      switch (validationConfig.type) {
        case 'min': {
          if (+value < validationConfig.value) {
            return false;
          }
          break;
        }
        case 'max': {
          if (+value > validationConfig.value) {
            return false;
          }
          break;
        }
        case 'regExp': {
          let re = new RegExp(validationConfig.value);
          if (!re.test(value)) {
            return false;
          }
          break;
        }
        default:
          console.error(`Unsupported validation type: "validationConfig.type"`);
          return false;
      }
    }
    return true;
  };

  render() {
    const {
      options: { key, label, inputType, placeholder, disabled },
      fields,
    } = this.props;
    
    return (
      <Searchable searchKey={key}>
        <FormRow className='rf-form-view'>
          <Input
            className={`${disabled ? 'input-disabled' : ''}`}
            value={fields[key]}
            label={label}
            onBlur={this.onChange}
            placeholder={placeholder || label}
            type={inputType || 'text'}
            isValid={this.isValid()}
            tooltip={this.getTooltip()}
            tooltipPlacement="right"
            blurOnEnter
            disabled={disabled}
          />
        </FormRow>
      </Searchable>
    );
  }
}

InputFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: stringParseUrl,
  defaultState: (options) => ({
    [options.key]: options.value || '',
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost, isPathParam } = options;
    if (isPathParam) {
      return {
        pathParams: { [key]: fields[key] },
      };
    }
    if (isPost) {
      const data = {};
      if (fields[key] !== '' && fields[key] != null) {
        set(data, key, fields[key]);
      }
      return {
        method: 'post',
        data,
      };
    }
    const params = (fields[key] || fields[key]===0) ? { [key]: fields[key] } : {};
    return { params };
  },
  Component: InputFormView,
};
