import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './mapAttributes.scss';
import get from 'lodash/get';
import { Checkbox, FormRow, Tree } from '@here/ref-client-ui';

class MapAttributes extends React.Component {
  getSegmentElement({ segment, segmentIdx, listIdx }, selected) {
    const isSelected =
      selected.index === segmentIdx;
    const listItemClasses = classnames('rf-segments', {
      'rf-segments_selected': isSelected,
    });

    const { attributes } = segment;
    const wAttr = attributes ? '-attributes' : '';
    const className = `rf-segments__content${wAttr}`
    return (
      <li
        className={listItemClasses}
        onClick={this.onClick.bind(this, segment, segmentIdx, listIdx)}
        key={`${segment.instruction}${segmentIdx}`}
      >
        <div className="rf-segments__inner">
          <div className={className}>{segment.segmentRef}</div>
        </div>
        {isSelected && this.getDetails(segment)}
      </li>
    );
  }

  getDetails(segment) {
    if (!segment.attributes) {
      return null;
    }

    return <div className="rf-segments__details">
      <div className="rf-segments__content">
        <Tree 
          className="rf-segments-tree"
          data={segment.attributes}
          shouldExpandNode={() => true} 
        />
      </div>
    </div>;
  }

  onClick = (segment, index, listIndex, e) => {
    const selection = window.getSelection();
    if (selection.toString().length !== 0) {
      return;
    }

    const {
      setResultState,
      options: { key },
      result,
    } = this.props;
    const { target } = e;
    const selected = get(result.state, key, {});
    const tree = document.querySelector('.rf-segments-tree');

    if (selected.index === index && tree && !tree.contains(target)) {
      setResultState({ [key]: {} });
    } else if (selected.index !== index) {
      setResultState({ [key]: { segment, index, listIndex } });
    }
  };

  toggleAllSegments = () => {
    const {
      setResultState,
      options: { formatter },
      result,
      refClient: { formatterPlugin },
    } = this.props;

    const segmentsFormatter = formatterPlugin.get(formatter);
    const seg = get(result, 'state.segmentsWithAttrs');

    if (seg) {
      setResultState({'segmentsWithAttrs': null});
    } else {
      const stateSegments = get(result, 'state.segmentsGeometry') || [];
      const dataSegments = get(result, 'raw.data.segments') || [];
      const segmentsWithAttrs = segmentsFormatter(stateSegments, dataSegments);
      setResultState({segmentsWithAttrs});
    }
  }

  render() {
    const {
      options: { formatter, key },
      result,
      refClient: { formatterPlugin },
    } = this.props;

    const segmentsFormatter = formatterPlugin.get(formatter);

    const segmentsWithAttrs = get(result, 'state.segmentsWithAttrs');
    const stateSegments = get(result, 'state.segmentsGeometry') || [];
    const dataSegments = get(result, 'raw.data.segments') || [];
    const segmentsDetails = segmentsFormatter(stateSegments, dataSegments);
    
    if (segmentsDetails.length === 0) {
      return (
        <div className="rf-segments-empty">
          No results <br />
        </div>
      );
    }
    
    const selected = get(result.state, key, {});

    const segmentsEl = [];
    if (!!segmentsWithAttrs) {
      segmentsDetails.forEach((segment, segmentIdx) =>{
        if (segment.attributes) {
          segmentsEl.push(this.getSegmentElement({ segment, segmentIdx, listIdx: segmentsEl.length }, selected))
        }
      });      
      segmentsDetails.forEach((segment, segmentIdx) =>{
        if (!segment.attributes) {
          segmentsEl.push(this.getSegmentElement({ segment, segmentIdx }, selected))
        }
      });
    } else {
      segmentsDetails.forEach((segment, segmentIdx) =>{
        segmentsEl.push(this.getSegmentElement({ segment, segmentIdx }, selected))
      });
    }

    return <lui-default-theme>
      <h4>Segments Attributes</h4>
      <FormRow>
        <Checkbox
          label="Highlight and sort Segments with Attributes"
          onChange={this.toggleAllSegments}
          isChecked={!!segmentsWithAttrs}
        />
      </FormRow>
      <FormRow>
      <ul>{segmentsEl}</ul>
      </FormRow>
    </lui-default-theme>
  }
}

MapAttributes.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default MapAttributes;
