import React from 'react';
import PropTypes from 'prop-types';
import { withLeaflet, Rectangle as LeafletRectangle } from 'react-leaflet';
import { latLngBounds } from 'leaflet';

const DRAG_STATES = {
  DRAG: 0,
  RESIZE_TOP: 1,
  RESIZE_LEFT: 2,
  RESIZE_BOTTOM: 3,
  RESIZE_RIGHT: 4,
  RESIZE_TOP_LEFT: 5,
  RESIZE_TOP_RIGHT: 6,
  RESIZE_BOTTOM_LEFT: 7,
  RESIZE_BOTTOM_RIGHT: 8,
};

const RESIZE_PADDING = 15;


class EditableRectangle extends React.Component {
  ref = React.createRef();
  dragging = false;

  onMouseMove = (e) => {
    if (this.dragging) {
      return;
    }
    const {
      leaflet: { map },
    } = this.props;
    const { target: rect, layerPoint } = e;
    const { x, y } = layerPoint;
    let cursor = 'move';
    const bounds = rect.getBounds();

    const toPoint = (latLng) => map.latLngToLayerPoint(latLng);
    const topLeft = toPoint(bounds.getNorthWest());
    const bottomRight = toPoint(bounds.getSouthEast());
    if (topLeft.distanceTo(layerPoint) <= RESIZE_PADDING) {
      cursor = 'nwse-resize';
      this.dragState = DRAG_STATES.RESIZE_TOP_LEFT;
    } else if (
      toPoint(bounds.getNorthEast()).distanceTo(layerPoint) <= RESIZE_PADDING
    ) {
      this.dragState = DRAG_STATES.RESIZE_TOP_RIGHT;
      cursor = 'nesw-resize';
    } else if (bottomRight.distanceTo(layerPoint) <= RESIZE_PADDING) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM_RIGHT;
      cursor = 'nwse-resize';
    } else if (
      toPoint(bounds.getSouthWest()).distanceTo(layerPoint) <= RESIZE_PADDING
    ) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM_LEFT;
      cursor = 'nesw-resize';
    } else if (Math.abs(topLeft.y - y) <= RESIZE_PADDING) {
      this.dragState = DRAG_STATES.RESIZE_TOP;
      cursor = 'ns-resize';
    } else if (Math.abs(bottomRight.y - y) <= RESIZE_PADDING) {
      this.dragState = DRAG_STATES.RESIZE_BOTTOM;
      cursor = 'ns-resize';
    } else if (Math.abs(topLeft.x - x) <= RESIZE_PADDING) {
      this.dragState = DRAG_STATES.RESIZE_LEFT;
      cursor = 'ew-resize';
    } else if (Math.abs(bottomRight.x - x) <= RESIZE_PADDING) {
      this.dragState = DRAG_STATES.RESIZE_RIGHT;
      cursor = 'ew-resize';
    } else {
      this.dragState = DRAG_STATES.DRAG;
    }

    e.originalEvent.srcElement.style.cursor = cursor;
  };

  onMouseDown = (e) => {
    if (e.originalEvent.which !== 1) {
      return;
    }
    const {
      leaflet: { map },
    } = this.props;
    map.dragging.disable();
    map.on('mousemove', this.onDrag);
  };

  onDrag = (e) => {
    const { leafletElement: rect } = this.ref.current;
    if (!this.dragging) {
      this.dragging = true;
      this.mouseStart = e.latlng;
      this.boundsStart = rect.getBounds();
    }
    const { lat, lng } = e.latlng;
    let top = this.boundsStart.getNorth();
    let right = this.boundsStart.getEast();
    let bottom = this.boundsStart.getSouth();
    let left = this.boundsStart.getWest();
    switch (this.dragState) {
      case DRAG_STATES.RESIZE_TOP_LEFT: {
        top = lat;
        left = lng;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_RIGHT: {
        bottom = lat;
        right = lng;
        break;
      }
      case DRAG_STATES.RESIZE_TOP_RIGHT: {
        top = lat;
        right = lng;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM_LEFT: {
        bottom = lat;
        left = lng;
        break;
      }
      case DRAG_STATES.RESIZE_BOTTOM: {
        bottom = lat;
        break;
      }
      case DRAG_STATES.RESIZE_TOP: {
        top = lat;
        break;
      }
      case DRAG_STATES.RESIZE_LEFT: {
        left = lng;
        break;
      }
      case DRAG_STATES.RESIZE_RIGHT: {
        right = lng;
        break;
      }
      default: {
        const deltaLat = this.mouseStart.lat - lat;
        const deltaLng = this.mouseStart.lng - lng;
        top -= deltaLat;
        right -= deltaLng;
        bottom -= deltaLat;
        left -= deltaLng;
        break;
      }
    }

    rect.setBounds([
      [top, right],
      [bottom, left],
    ]);
  };

  onMouseUp = () => {
    const {
      leaflet: { map },
      onChange,
    } = this.props;
    const { leafletElement: rect } = this.ref.current;
    map.dragging.enable();
    map.off('mousemove', this.onDrag);
    if (!this.dragging) {
      return;
    }
    this.dragging = false;
    this.mouseStart = null;
    this.boundsStart = null;

    const bounds = rect.getBounds();
    const top = +bounds.getNorth().toFixed(6);
    const right = +bounds.getEast().toFixed(6);
    const bottom = +bounds.getSouth().toFixed(6);
    const left = +bounds.getWest().toFixed(6);

    onChange([
      [top, right],
      [bottom, left],
    ]);
  };

  addException = (type, e) => {
    const { onContextMenu, map } = this.props;

    try {
      e.latlng = e.relatedTarget.getLatLng();
    } catch (e) {
      // skip exception
    }
    const originalEvent = e;
    const { lat, lng } = e.latlng;
    const { x, y } = e.layerPoint;

    onContextMenu(type, e, {
      latLng: { lat, lng },
      xy: { x, y },
      xyToLatLng: map.layerPointToLatLng.bind(map),
      originalEvent,
      zoom: map.getZoom(),
    });
  }

  remove = (type, e) => {
    const { onContextMenu } = this.props;
    onContextMenu(type, e);
  }

  componentWillUnmount() {
    const {
      leaflet: { map },
    } = this.props;

    map.dragging.enable();
    map.off('mousemove', this.onDrag);
  }

  render() {
    const { bounds, contextMenu, noException, isException, onContextMenu, skipCorridor, map, color, fillOpacity, draggable, resizable, ...rectProps } = this.props;     
    const contextWidth = 165;
    let contextmenuItems = !noException ? [
      {
        text: `Add Exception Rectangle`,
        index: 0,
        callback: this.addException.bind(this, 'bbox'),
      },
      {
        text: `Add Exception Polygon`,
        index: 1,
        callback: this.addException.bind(this, 'polygon'),
      }
    ] : [];
    if (!noException && !skipCorridor) contextmenuItems.push({
      text: `Add Exception Corridor`,
      index: 2,
      callback: this.addException.bind(this, 'corridor'),
    })
    contextmenuItems.push({
      text: `Remove${isException ? ' Exception' : ''}`,
      index: contextmenuItems.length,
      callback: this.remove.bind(this, 'remove'),
    })
    if (!latLngBounds(bounds).isValid()) {
      return null;
    }

    const props = {};
    if (color) {
      props.color = color;
    }
    if (fillOpacity) {
      props.fillOpacity = fillOpacity;
    }
    if (draggable || resizable) {
      props.onmousedown = this.onMouseDown;
      props.onmouseup = this.onMouseUp;
      props.onmousemove = this.onMouseMove;
      props.onmouseout = this.onMouseOut;
    }
    return (
      <LeafletRectangle
        contextmenu={contextMenu}
        contextWidth={contextWidth}
        contextmenuItems={contextmenuItems}
        contextmenuInheritItems={false}
        bounds={bounds}
        ref={this.ref}
        {...{ ...props, ...rectProps }}
      />
    );
  }
}

EditableRectangle.propTypes = {
  leaflet: PropTypes.object.isRequired,
  bounds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
};

export default withLeaflet(EditableRectangle);
