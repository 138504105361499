import { getTabs } from '../../index';
import { getAllSettings } from '../../../settings';
import { assignTabDataAndDispatch } from '../../actions';
import { ADD_TAB } from '../../constants';
import { RESTORE_FROM_URL } from '../../../constants';
import {
  CHANGE_SETTINGS_NAME,
  ADD_SETTINGS,
  DELETE_SETTINGS,
} from '../../../settings/custom/constants';
import { get } from 'lodash';
import { getTitle } from '../title';

const SET = 'tab/preset/SET';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = null, action) => {
  switch (action.type) {
    case SET:
      return action.payload;
    case ADD_TAB:
    case RESTORE_FROM_URL:
    case ADD_SETTINGS:
      return action.payload.preset;
    case CHANGE_SETTINGS_NAME:
    case DELETE_SETTINGS:
      return action.payload.newPreset;
    default:
      return state;
  }
};

export const setPreset = (preset) =>
  assignTabDataAndDispatch({ type: SET, payload: preset });

export const getTabPreset = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return getTabs(state)[selectedTab].preset;
};

export const getTabSettings = (state, tabIndex) => {
  const preset = getTabPreset(state, tabIndex);
  const title = getTitle(state, tabIndex);
  const settings = getAllSettings(state);

  return get(settings, [title, preset]);
};
