import PropTypes from 'prop-types';
import React from 'react';
import utils from 'utils';
import { ExpandFormRow, FormRow, Input, Select } from '@here/ref-client-ui';
import BoundingBox from './BoundingBox';
import './searchRegion.scss';
import { isCoordValidAndDefined } from '../../../utils/validation/coordsValidation';

const initState = {
  at: {
    at: ''
  },
  circle: {
    in: 'circle:'
  },
  bbox: {
    in: 'bbox'
  },
  countryCode: {
    in: 'countryCode'
  }
};

class SearchRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapi: null,
    };
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeRegionType = (e) => {
    const {
      options: { key },
      setFields
    } = this.props;
    const type = utils.extractData(e);
    const searchRegion = { ...(initState[type] || {}) };
    const newFields = { [key]: searchRegion };
    setFields(newFields);
  };

  onChangeCoord = (paramKey, e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const searchRegion = fields[key] || {};
    
    const radius = fields[key].in.split(';r=')[1];
    const inValue = `circle:${e.target.value};r=${radius}`;
    setFields({
      searchRegion: {
        ...searchRegion,
        in: inValue,
      },
    });
  };

  onChangeBbox = (bounds) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const [[north, east],[south, west]] = bounds
    setFields({
      searchRegion: {
        ...fields[key],
        in: `bbox:${west},${south},${east},${north}`,
      },
    });
  };

  onChangeCountry = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      searchRegion: { ...fields[key], in: 'countryCode:'+e.target.value },
    });
  };

  onChangeAt = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      searchRegion: { ...fields[key], at: e.target.value },
    });
  };

  onChangeRadius = ({ target: { value } }) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const newradius = isFinite(+value) > 0 ? +value : value;
    const firstPart = fields[key].in.split(';r=')[0];
    const inValue = `${firstPart};r=${newradius}`;
    setFields({
      searchRegion: { ...fields[key], in: inValue },
    });
  };

  onChangeMaxRadius = ({ target: { value } }) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const maxRadius = isFinite(+value) > 0 ? +value : value;
    setFields({
      searchRegion: { ...fields[key], maxRadius },
    });
  };

  getRegionTypeFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { in: keyIn } = fields[key] || {};
    if (!!keyIn) {
      if (keyIn.indexOf('circle') > -1) {
        return this.getCircleFields();
      } else if (keyIn.indexOf('bbox') > -1) {
        return this.getBoundingBoxFields();
      } else if (keyIn.indexOf('countryCode') > -1) {
        return this.getCountryFields();
      } 
    } else {
      return this.getAtFields();
    }
  };

  getCircleFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { in: inValue = '' } = fields[key] || {};
    const [firstPart, radius] = inValue.split(';r=');
    const center = firstPart.split(':')[1];
    return (
      <div>
        <FormRow>
          <Input
            label="Center"
            value={center}
            onBlur={this.onChangeCoord.bind(this, 'center')}
            isValid={isCoordValidAndDefined(center)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="Radius"
            value={radius}
            onBlur={this.onChangeRadius}
            isValid={isFinite(radius) && radius > 0}
            blurOnEnter
          />
        </FormRow>
      </div>
    );
  };

  getAtFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const searchRegion = fields[key] || {};
    return (
      <>
        <FormRow>
          <Input
            label="Search At"
            value={searchRegion.at}
            onChange={this.onChangeAt}
          />
        </FormRow>
      </>
    );
  };

  getBoundingBoxFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const {in: inValue = ''} = fields[key];
    const bounds = inValue.split(':')[1];
    let res = [];
    if (bounds) {
      const [west, south, east, north] = bounds.split(',');
      res = [[north, east],[south, west]];
    }
    return (
      <BoundingBox
        bounds={res}
        onChange={this.onChangeBbox}
      />
    );
  };

  getCountryFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const {in: inValue = ''} = fields[key];
    const countryCodes = inValue.split(':')[1];
    return (
      <>
        <FormRow>
          <Input
            label="Country Code"
            value={countryCodes}
            onChange={this.onChangeCountry}
          />
        </FormRow>
      </>
    );
  };

  render() {
    const {
      options: { label, key, regionTypes },
      fields,
    } = this.props;
    const searchRegion = fields[key] || {};
    const regionType = searchRegion.hasOwnProperty('in') ? searchRegion.in.split(':')[0] : 'at';
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltipPlacement="right"
        className="rf-region-definition rf-grey-box"
      >
        <FormRow>
          <Select
            label="Type"
            options={regionTypes}
            value={regionType}
            onChange={this.onChangeRegionType}
          />
        </FormRow>
        {this.getRegionTypeFields()}
      </ExpandFormRow>
    );
  }
}

SearchRegion.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData }) => {
    const { key } = options;
    const entries = Object.entries(parsedParams);
    const filteredEntries = entries.filter(([key, v]) => (key ==='in' || key === 'at'));
    const params = Object.fromEntries(filteredEntries);

    if (params) {
      return { [key]: params };
    }
    else if (postData) {
      return { [key]: postData[key] };
    }
    return { [key]: options.initValue };
  },

  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),

  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;
    if (!isPost) {
      const searchRegion = fields[key] || {};
      let params = {};
      if (searchRegion.type === 'bbox') {
        if (searchRegion.hasOwnProperty('bbox')) {
          const { bbox: [topRight = [], bottomLeft = []] = [] } =
            searchRegion;
            params = {
            in: 'bbox',
            north: +topRight[0],
            south: +bottomLeft[0],
            east: +topRight[1],
            west: +bottomLeft[1],
          };
        } else {
          params = JSON.parse(JSON.stringify(searchRegion));
        }
      } else {
        Object.keys(searchRegion).forEach((key) => {
          if (searchRegion[key]) {
            params[key] = searchRegion[key];
          }
        });
      }

      return {params};
    }
  },
  Component: SearchRegion,
};
