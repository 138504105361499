import React from 'react';
import { get } from 'lodash';
import {
  validateLat,
  validateLon,
} from 'utils/validation/coordsValidation';

const displayLocationKeys = [
  {
    main: 'origin',
    displayLocation: 'origin_displayLocation'
  }, {
    main: 'destination',
    displayLocation: 'destination_displayLocation'
  }, {
    // originDestination_displayLocation is for isoline routing
    main: 'originDestinationValue',
    displayLocation: 'originDestination_displayLocation'
  }, {
    main: 'origin',
    displayLocation: 'origin_sideOfStreetHint'
  }, {
    main: 'destination',
    displayLocation: 'destination_sideOfStreetHint'
  }, {
    main: 'originDestinationValue',
    displayLocation: 'originDestination_sideOfStreetHint'
  }
];

function DiffLine(LineComponent) {
  return class Line extends React.Component {

    renderLines = (
      displayLocationLines,
      colorPalette
    ) => {
      const lineStyle = {
        strokeColor: colorPalette.secondary,
        lineDash: [5, 5]
      }
      
      const lines = [];
      displayLocationLines.forEach((line, i) => {
        const [lat1, lng1] = line[0].split(',');
        const [lat2, lng2] = line[1].split(',');

        const isCoordsValid =
          validateLat(lat1) &&
          validateLon(lng1) &&
          validateLat(lat2) &&
          validateLon(lng2);

        if (isCoordsValid){
          lines.push(
            <LineComponent
              key={'al-'+i}
              latLngs={[{lat:lat1, lng:lng1}, {lat:lat2, lng:lng2}]}
              options={{ style: lineStyle }}
              positions={[{lat:lat1, lng:lng1}, {lat:lat2, lng:lng2}]}
              dashArray={lineStyle.lineDash}
              color={lineStyle.strokeColor}
            />
          );
        }
      })
      
      return lines;
    };

    render() {
      const {
        fields,
        colorPalette
      } = this.props;

      const displayLocationLines = [];
      displayLocationKeys.forEach(key => {
        const {main, displayLocation} = key;

        const mainPos = get(fields, main);
        const dlPos = get(fields, displayLocation);
        if (mainPos && dlPos) {
          displayLocationLines.push([mainPos, dlPos]);
        }
      });
      const vias = get(fields, 'via');
      if (vias) {
        vias.forEach((via, i) => {
          if (via.advanced.displayLocation && via.coords) {
            displayLocationLines.push([via.advanced.displayLocation, via.coords])
          }
          if (via.advanced.sideOfStreetHint && via.coords) {
            displayLocationLines.push([via.advanced.sideOfStreetHint, via.coords])
          }
        });
      }

      return (
        <>
          {this.renderLines(
            displayLocationLines,
            colorPalette
          )}
        </>
      );
    }
  };
}

export default DiffLine;
