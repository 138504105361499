import { get } from 'lodash';
import { SET_SETTINGS_VALUE } from '..';
import localStorageReducerDecorator from '../../../utils/localStorageReducerDecorator';
import {
  CHANGE_SETTINGS_NAME,
  ADD_SETTINGS,
  DELETE_SETTINGS,
} from './constants';
import { assignTabDataAndDispatch } from '../../tabs/actions';
import { DELETE_CONFIG } from '../../config/custom/constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case DELETE_CONFIG: {
      const title = action.payload;
      const newState = { ...state };
      delete newState[title];

      return newState;
    }
    case ADD_SETTINGS: {
      const { title, preset, data } = action.payload;
      return {
        ...state,
        [title]: {
          ...state[title],
          [preset]: {
            ...get(state, [title, preset]),
            ...data,
          },
        },
      };
    }
    case CHANGE_SETTINGS_NAME: {
      const { title, oldPreset, newPreset } = action.payload;
      const newModuleSettings = { ...state[title] };
      const oldPresetSettings = { ...newModuleSettings[oldPreset] };
      delete newModuleSettings[oldPreset];
      return {
        ...state,
        [title]: {
          ...newModuleSettings,
          [newPreset]: oldPresetSettings,
        },
      };
    }
    case DELETE_SETTINGS: {
      const { title, preset } = action.payload;
      const formSettings = { ...state[title] };
      delete formSettings[preset];
      return {
        ...state,
        [title]: formSettings,
      };
    }
    case SET_SETTINGS_VALUE: {
      const { title, preset, data } = action.payload;
      const settings = get(state, [title, preset]);
      if (settings) {
        return {
          ...state,
          [title]: {
            ...state[title],
            [preset]: {
              ...get(state, [title, preset]),
              ...data,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default localStorageReducerDecorator(reducer, 'v1.customSettings');

export const addSettings = (title, preset, data, tabIndex) =>
  assignTabDataAndDispatch(
    {
      type: ADD_SETTINGS,
      payload: {
        title,
        preset,
        data: { ...data, isCustom: true },
      },
    },
    tabIndex
  );

export const deleteSettings = (title, preset, newPreset) =>
  assignTabDataAndDispatch({
    type: DELETE_SETTINGS,
    payload: { title, preset, newPreset },
  });
export const changeSettingsName = (title, oldPreset, newPreset) =>
  assignTabDataAndDispatch({
    type: CHANGE_SETTINGS_NAME,
    payload: { title, oldPreset, newPreset },
  });
