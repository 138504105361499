import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function useUpdateEffect(effect, deps) {
  const isMount = useRef(true);
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

function Matrix({ fields, setFields, setNotification, setPreset, settings }) {
  const { transportMode, profile, async } = fields;

  useUpdateEffect(() => {
    if (transportMode && profile) {
      setFields({ profile: '' });
      setNotification({
        message: 'Matrix profile has been unset.',
        autoDismiss: 5,
      });
    }
  }, [transportMode]);

  useUpdateEffect(() => {
    if (transportMode && profile) {
      setFields({ transportMode: '' });
      setNotification({
        message: 'Matrix transportMode has been unset.',
        autoDismiss: 5,
      });
    }
  }, [profile]);

  useUpdateEffect(() => {
    if (async === 'true' && settings.type !== 'apiKey') {
      setPreset('ApiKey');
      setNotification({
        children: (
          <>
            <div>ApiKey authentication has been selected.</div>
            <div>
              Synchronous requests are supported only with apikey auth in
              RefClient
            </div>
          </>
        ),
        autoDismiss: 5,
      });
    }
  }, [async]);

  useUpdateEffect(() => {
    if (async === 'true' && settings.type !== 'apiKey') {
      setFields({ async: 'false' });
      setNotification({
        message:
          'Changed async to false. Asynchronous requests are not supported by here auth in ref client.',
        autoDismiss: 0,
      });
    }
  }, [settings.type]);

  return null;
}

Matrix.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

const matrixPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: Matrix,
};

export default matrixPlugin;
