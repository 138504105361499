import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';
import { addEventListeners, removeEventListeners } from './mapEvents';

class HerePolygon extends React.Component {
  polygon = null;
  startPolygon = null;
  tempGeometry = null;
  tempExterior = null;

  componentDidMount() {
    this.addPolygon();
  }

  componentDidUpdate(prevProps) {
    const { latLngs, coatLatLngs, options, draggable, resizable } = this.props;

    if (
      !isEqual(latLngs, prevProps.latLngs) ||
      !isEqual(coatLatLngs, prevProps.coatLatLngs) ||
      !isEqual(options, prevProps.options) ||
      !isEqual(draggable, prevProps.draggable) ||
      !isEqual(resizable, prevProps.resizable)
    ) {
      this.removePolygon();
      this.addPolygon();
    }
  }

  componentWillUnmount() {
    this.removePolygon();
  }

  addPolygon = () => {
    const { latLngs, coatLatLngs, options, map, group, draggable, resizable } = this.props;
    
    const geometry = new window.H.geo.LineString();
    (coatLatLngs || latLngs).forEach(({ lat, lng }) => {
      geometry.pushLatLngAlt(lat, lng);
    });
    
    this.polygon = new window.H.map.Polygon(geometry, options);
    addEventListeners(this.props, this.polygon);
    if (group) {
      group.addObject(this.polygon);
    } else {
      map.addObject(this.polygon);
    }
    
    if (draggable && resizable) {
      this.polygon.draggable = true;
      this.initInteractionEvents();
    }
  };

  removePolygon = () => {
    const { map, group } = this.props;
    
    removeEventListeners(this.props, this.polygon);
    if (this.polygon && group && group.contains(this.polygon)) {
      if (group) {
        group.removeObject(this.polygon);
      } else {
        map.removeObject(this.polygon);
      }
      this.polygon = null;
    }
  };

  onPointerEnter = () => {
    const { map } = this.props;
    let cursorStyle = 'move';

    map.getElement().style.cursor = cursorStyle;
  };

  onDragStart = (evt) => {
    const { map, group } = this.props;
    var pointer = evt.currentPointer,
        object = evt.target;

    group.getObjects().forEach((o, i)=>{
      const data = o.getData() || {};
      if ( data.hideOnDrag === true )
        o.setVisibility(false);
    });
    
    object.setData({
      startCoord: map.screenToGeo(pointer.viewportX, pointer.viewportY)
    });
    
    evt.stopPropagation();
  };

  onDrag = (evt) => {
    const { map } = this.props;
    var pointer = evt.currentPointer,
        object = evt.target,
        startCoord = object.getData()['startCoord'],
        newCoord = map.screenToGeo(pointer.viewportX, pointer.viewportY),
        outOfMapView = false;

    if (!newCoord.equals(startCoord)) {
      var currentLineString = object.getGeometry().getExterior(),
          newLineString = new window.H.geo.LineString();

      // create new LineString with updated coordinates
      currentLineString.eachLatLngAlt(function (lat, lng, alt) {
        var diffLat = (lat - startCoord.lat),
            diffLng = (lng - startCoord.lng),
            newLat =newCoord.lat + diffLat,
            newLng = newCoord.lng + diffLng;

        // prevent dragging to latitude over 90 or -90 degrees to prevent loosing altitude values
        if (newLat >= 90 || newLat <= -90) {
          outOfMapView = true;
          return;
        }

        newLineString.pushLatLngAlt(newLat, newLng, 0);
      });

      if (!outOfMapView) {
        object.setGeometry(new window.H.geo.Polygon(newLineString));
        object.setData({
          startCoord: newCoord
        });
      }
    }
    evt.stopPropagation();
  };

  onDragEnd = (evt) => {
    const { onChange, group, coatLatLngs, latLngs } = this.props;
    var object = evt.target;

    group.getObjects().forEach((o, i)=>{
      const data = o.getData() || {};
      if ( data.hideOnDrag === true )
        o.setVisibility(true);
    });

    let { coordinates } = object.getGeometry().toGeoJSON();

    if (coatLatLngs) {
      const index = Math.floor(coatLatLngs.length/2);
      const initialCoord = coatLatLngs[index];
      const coord = coordinates[0][index];
      const latDiff = initialCoord.lat - coord[1];
      const lngDiff = initialCoord.lng - coord[0];

      coordinates[0] = latLngs.map(({ lat, lng }) =>[lng - lngDiff, lat - latDiff]);
      coordinates[0].push('space');
    }
    if (onChange) {
      coordinates[0].pop();
      onChange(coordinates[0]);
    }
  };

  onPointerLeave = () => {
    const { map } = this.props;
    map.getElement().style.cursor = 'auto';
  };

  initInteractionEvents = () => {
    this.polygon.addEventListener('dragstart', this.onDragStart);
    this.polygon.addEventListener('drag', this.onDrag);
    this.polygon.addEventListener('dragend', this.onDragEnd);

    this.polygon.addEventListener('pointerenter', this.onPointerEnter);
    this.polygon.addEventListener('pointerleave', this.onPointerLeave);
  };

  render() {
    return <div></div>;
  }
}

HerePolygon.propTypes = {
  latLngs: PropTypes.arrayOf(PropTypes.object).isRequired,
  map: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  geometryIndex: PropTypes.number,
};

export default withHereMap(HerePolygon);
