import React, { useEffect } from 'react';
import { Pane, Polyline, useLeaflet } from 'react-leaflet';
import { getCenterFromPolyline, getBBoxFromPolyline } from './helpers';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';

function LeafletRoute({ zIndex, centerOnChange, ...props }) {
  const leaflet = useLeaflet();

  useEffect(() => {
    if (
      centerOnChange &&
      isArray(props.positions) &&
      props.positions.length > 0
    ) {      
      const bound = getBBoxFromPolyline(props.positions);
      const bounds = [[bound.getBottom(), bound.getLeft()],[bound.getTop(), bound.getRight()]];
      if (leaflet.map.getBounds().contains(bounds)) {
        leaflet.map.setView(getCenterFromPolyline(props.positions));
      } else {
        leaflet.map.fitBounds(bounds);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.positions]);

  if (zIndex) {
    return (
      <Pane style={{ zIndex }}>
        <Polyline {...props} />
      </Pane>
    );
  }

  return <Polyline {...props} />;
}

LeafletRoute.propTypes = {
  centerOnChange: PropTypes.bool,
  zIndex: PropTypes.number,
  positions: PropTypes.array,
};

export default LeafletRoute;
