const LS_KEY = '[ref-client] Map Center';

export function setDefaultMapCenter(coords) {
  localStorage.setItem(LS_KEY, coords);
}

export function getDefaultMapCenter() {
  const coords = localStorage.getItem(LS_KEY);
  if (coords) {
    const [lat, lng] = coords.split(',').map(Number);
    return { lat, lng };
  }
  return null;
}
