import React from 'react';
import PropTypes from 'prop-types';
import { latLng, latLngBounds } from 'leaflet';
import get from 'lodash/get';

class SearchRegion extends React.Component {
  componentDidUpdate(prevProps) {
    const searchRegionType = get(this.props, 'fields.searchRegion');
    const prevSearchRegionType = get(prevProps, 'fields.searchRegion');

    const { in: inValue, at: atValue } = searchRegionType;
    const { in: prevInValue, at: prevAtValue } = prevSearchRegionType;
    const {
      setFields,
      bounds: { top, right, bottom, left },
    } = this.props;

    let searchRegion;
    if ((searchRegionType.hasOwnProperty('at') && !prevSearchRegionType.hasOwnProperty('at')) ||
    (!inValue && !atValue && !prevInValue && !prevAtValue)) {
      if(top && right) {
        const center = latLngBounds([
          [top, right],
          [bottom, left],
        ]).getCenter();
        searchRegion = {
          "at": `${center.lat.toFixed(6)},${center.lng.toFixed(6)}`
        }
  
        setFields({ searchRegion });
      }
    } else if ((!!inValue && !prevInValue) || (!!inValue && !!prevInValue && inValue.split(':')[0] !== prevInValue.split(':')[0])) {
      switch (inValue.split(':')[0]) {
        case 'circle': {
          const center = latLngBounds([
            [top, right],
            [bottom, left],
          ]).getCenter();
          const rightLatLon = latLng(center.lat, right);
          const topLatLon = latLng(top, center.lng);
          const distToTop = center.distanceTo(topLatLon);
          const distToRight = center.distanceTo(rightLatLon);
          const radius = Math.floor(Math.min(distToRight, distToTop));
          searchRegion = {
            "in": `circle:${center.lat.toFixed(6)},${center.lng.toFixed(6)};r=${radius}`
          };
          break;
        }
        case 'bbox': {
          searchRegion = {
            "in": `bbox:${left.toFixed(6)},${bottom.toFixed(6)},${right.toFixed(6)},${top.toFixed(6)}`
          };
          break;
        }
        case 'countryCode': {
          searchRegion = {
            "in": `countryCode:USA`
          };
          break;
        }
        default:
          break;
      }
      setFields({ searchRegion });
    }
  }

  render() {
    return null;
  }
}

SearchRegion.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object.isRequired,
  bounds: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default SearchRegion;
