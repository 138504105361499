import RefClient from 'ref-client-core';
import routing from './configs/external/routing';
import ptRouting from './configs/external/ptRouting';
import intermodal from './configs/external/intermodal';
import matrixV8 from './configs/external/matrix';
import matrixRes from './configs/external/matrixResult';
import isoline from './configs/external/isoline';
import routeHandle from './configs/external/routeHandle';
import routeImport from './configs/external/route-import.json';
import offlineTools from './configs/offlineTools.json';
import customizeSearch from './configs/external/customizeSearch';
import mapAttributes from './configs/external/map-attributes';
import routeMatching from './configs/external/route-matching';
import './common';

RefClient.loadConfig(routing);
RefClient.loadConfig(ptRouting);
RefClient.loadConfig(intermodal);
RefClient.loadConfig(matrixV8);
RefClient.loadConfig(matrixRes);
RefClient.loadConfig(isoline);
RefClient.loadConfig(routeHandle);
RefClient.loadConfig(routeImport);
RefClient.loadConfig(offlineTools);
RefClient.loadConfig(customizeSearch);
RefClient.loadConfig(mapAttributes);
RefClient.loadConfig(routeMatching);

RefClient.renderRefClient(RefClient.configs, document.getElementById('root'));
