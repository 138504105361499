function callback(e, options, setFields, fields, formatterPlugin) {
  const { lat: latFull, lng: lngFull } = e.latLng;
  const lat = +latFull.toFixed(6);
  const lng = +lngFull.toFixed(6);
  const { key, append, separator, setFieldsFormatter } = options;
  if (setFieldsFormatter) {
    const formatter = formatterPlugin.get(setFieldsFormatter);
    setFields(formatter(fields, e));
  } else {
    let value = `${lat},${lng}`;
    if (append && fields[key]) {
      value = `${fields[key]}${separator}${value}`;
    }
    setFields({ [options.key]: value });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setFields, fields, formatterPlugin }) {
  return {
    text: options.text,
    callback: (e) => callback(e, options, setFields, fields, formatterPlugin),
  };
}
