import { get } from 'lodash';
import { decode } from '../../utils/flexPolyline';

// eslint-disable-next-line import/no-anonymous-default-export
export default (resultState, routeWrapper, sectionsPath, routePath) => {
  const sections = get(routeWrapper, sectionsPath, []);
  const sectionsData = [];
  if (sectionsPath) {
    get(routeWrapper, sectionsPath, []).forEach((section) => {
      sectionsData.push(get(section, routePath));
    });
  } else {
    sectionsData.push(get(routeWrapper, routePath));
  }
  const shape = sectionsData.reduce(
    (acc, sectionData) => [
      ...acc,
      ...decode(sectionData).map((latLng) => ({
        lat: +latLng[0],
        lng: +latLng[1],
      })),
    ],
    []
  );

  const lowEnergyPaths = [];
  const noEnergyPath = [];
  let lowEnergy;

  let tatalSectionOffset = 0;
  let charge = +resultState.initialCharge;
  const minChargeAtChargingStation = +resultState.minChargeAtChargingStation;
  const minChargeAtFirstChargingStation = +resultState.minChargeAtFirstChargingStation;
  const maxCharge = +resultState.maxCharge;
  const { isMinChargeAtFirstStopActive } = resultState;
  sections.forEach((section, sectionIndex) => {
    lowEnergy = false;
    const sectionOffset =
      section.actions[section.actions.length - 1].offset + 1;
    let currentOffset = tatalSectionOffset;

    const spans =
      section.spans && section.spans[0].consumption !== undefined
        ? section.spans
        : [
            {
              consumption: section.departure.charge - section.arrival.charge,
              offset: 0,
            },
          ];
    spans.forEach((span, spanIndex) => {
      const { consumption, offset } = span;
      const nextSpan = spans[spanIndex + 1];
      const spanPointsCount =
        (nextSpan ? nextSpan.offset : sectionOffset) - offset;
      const consumptionPerPoint = consumption / spanPointsCount;

      for (let i = currentOffset; i < currentOffset + spanPointsCount; i++) {
        charge -= consumptionPerPoint;
        if (charge <= 0) {
          if (!noEnergyPath.length && shape[i-1]) {
            noEnergyPath.push(shape[i-1]);
          }
          noEnergyPath.push(shape[i]);
        } else if (sectionIndex === 0 && isMinChargeAtFirstStopActive ) {
          if (charge <= minChargeAtFirstChargingStation) {
            if (!lowEnergy) {
              lowEnergyPaths.push([]);
            }
            lowEnergy = true;
            lowEnergyPaths[lowEnergyPaths.length - 1].push(shape[i]);
          }
        } else if (charge <= minChargeAtChargingStation) {
          if (!lowEnergy) {
            lowEnergyPaths.push([]);
          }
          lowEnergy = true;
          lowEnergyPaths[lowEnergyPaths.length - 1].push(shape[i]);
        }
      }

      currentOffset += spanPointsCount;
    });

    tatalSectionOffset += sectionOffset;

    if (section.postActions && charge > 0 && resultState.isChargingAllowed) {
      const { targetCharge } = section.postActions.find(
        (postAction) => postAction.action === 'charging'
      );
      if (targetCharge) {
        charge = Math.min(targetCharge, maxCharge);
      }
    }
  });

  return { lowEnergyPaths, noEnergyPath };
};
