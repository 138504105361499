import React from 'react';
import PropTypes from 'prop-types';
import {
  FormRow,
  Input,
  Modal,
  ModalActions,
  Select,
  TabsControl,
} from '@here/ref-client-ui';
import settingsPlugin from '../../core/settingsPlugin';
import { RESTORE_URL_TYPE } from './constants';
import moment from 'moment';
import adjustLUIStyle from '../../utils/adjustLUIStyle';

class CustomSettingsModal extends React.Component {
  constructor(props) {
    super(props);
    const { settings = {}, url, probableTitle } = props;
    const selectedPreset = probableTitle
      ? `${settings.type || probableTitle} ${moment(Date.now()).format(
          'MM.DD.YY HH:mm:ss'
        )}`
      : '';
    this.state = {
      selectedTab: probableTitle
        ? RESTORE_URL_TYPE.NEW
        : RESTORE_URL_TYPE.SELECT,
      selectedModule: probableTitle || '',
      selectedPreset,
      settings: {
        ...settings,
        url,
      },
    };
  }

  componentDidMount () {
    adjustLUIStyle(document.querySelectorAll('.rf-modal lui-overlay'), 'lui-overlay');
  }

  componentDidUpdate () {
    adjustLUIStyle(document.querySelectorAll('.rf-modal lui-overlay'), 'lui-overlay');
  }
  onChangeTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  onChangeSettingsType = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        type: value,
      },
    }));
  };

  onSave = () => {
    if (this.hasError()) {
      return;
    }
    const { onSave } = this.props;
    const { selectedTab, selectedModule, selectedPreset, settings } =
      this.state;
    if (selectedTab === RESTORE_URL_TYPE.SELECT) {
      onSave({
        type: selectedTab,
        data: { selectedModule, selectedPreset },
      });
    } else if (selectedTab === RESTORE_URL_TYPE.NEW) {
      onSave({
        type: selectedTab,
        data: {
          selectedModule,
          selectedPreset,
          settings,
        },
      });
    }
  };

  hasError = () => {
    const {
      selectedModule,
      settings: { type },
      selectedTab,
    } = this.state;
    let hasError = false;
    if (!selectedModule) {
      hasError = true;
    }
    if (!this.isNameValid()) {
      hasError = true;
    }
    if (selectedTab === RESTORE_URL_TYPE.NEW && !type) {
      hasError = true;
    }

    return hasError;
  };

  onChangeSettings = (data) => {
    this.setState((prevState) => ({
      settings: { ...prevState.settings, ...data },
    }));
  };

  onChangeSettingsName = (e) => {
    const { value } = e.target;
    this.setState({ selectedPreset: value });
  };

  onChangeModule = (e) => {
    const { value } = e.target;
    this.setState({ selectedModule: value });
  };

  isNameValid = () => {
    const { allSettings } = this.props;
    const { selectedModule, selectedPreset, selectedTab } = this.state;

    if (selectedModule && selectedTab === RESTORE_URL_TYPE.NEW) {
      const moduleSettings = allSettings[selectedModule];
      return (
        selectedPreset &&
        Object.keys(moduleSettings).indexOf(selectedPreset) === -1
      );
    }

    return !!selectedPreset;
  };

  getTabEl = () => {
    const { configs, allSettings } = this.props;
    const {
      selectedTab,
      selectedModule,
      selectedPreset,
      settings = {},
    } = this.state;
    const Component = settings.type
      ? settingsPlugin.get(settings.type).Component
      : null;
    const moduleSelector = (
      <FormRow>
        <Select
          label="API Module"
          options={Object.keys(configs)}
          value={selectedModule}
          onChange={this.onChangeModule}
          isValid={!!selectedModule}
          addEmptyOption
        />
      </FormRow>
    );

    if (selectedTab === RESTORE_URL_TYPE.NEW) {
      return (
        <>
          <FormRow>
            <Input
              label="Environment name"
              value={selectedPreset}
              onChange={this.onChangeSettingsName}
              isValid={this.isNameValid()}
            />
          </FormRow>
          {moduleSelector}
          <FormRow>
            <Select
              label="Settings type"
              options={[...settingsPlugin.components.keys()]}
              value={settings.type || ''}
              onChange={this.onChangeSettingsType}
              isValid={!!settings.type}
              addEmptyOption
            />
          </FormRow>
          {Component && (
            <FormRow>
              <Component
                isCustom
                onChange={this.onChangeSettings}
                {...settings}
              />
            </FormRow>
          )}
        </>
      );
    } else if (selectedTab === RESTORE_URL_TYPE.SELECT) {
      const presetEl = selectedModule ? (
        <FormRow>
          <Select
            label="Environment"
            options={Object.keys(allSettings[selectedModule] || {})}
            value={selectedPreset}
            onChange={this.onChangeSettingsName}
            isValid={!!selectedPreset}
            addEmptyOption
          />
        </FormRow>
      ) : null;
      return (
        <>
          {moduleSelector}
          {presetEl}
        </>
      );
    }

    return null;
  };

  render() {
    const { selectedTab } = this.state;
    const { onCancel } = this.props;
    const tabsData = [
      { label: 'Select environment', name: RESTORE_URL_TYPE.SELECT },
      { label: 'New environment', name: RESTORE_URL_TYPE.NEW },
    ];

    const saveProps = {
      onClick: this.onSave,
      secondary: true,
    };
    if (this.hasError()) {
      saveProps.disabled = true;
    }

    return (
      <Modal isShown appendToBody title="Select settings">
        <TabsControl
          tabsData={tabsData}
          currentTabName={selectedTab}
          onChange={this.onChangeTab}
        />
        {this.getTabEl()}

        <ModalActions>
          <lui-button onClick={onCancel} secondary>
            Cancel
          </lui-button>
          <lui-button {...saveProps}>Save</lui-button>
        </ModalActions>
      </Modal>
    );
  }
}

CustomSettingsModal.propTypes = {
  url: PropTypes.string.isRequired,
  probableTitle: PropTypes.string,
  settings: PropTypes.object,
  configs: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CustomSettingsModal;
