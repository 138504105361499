import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, merge } from 'lodash';
import { Columns } from '@here/ref-client-ui';

class ColumnsFormView extends React.Component {
  render() {
    const { options, fields, setFields, refClient } = this.props;
    const { formViewsPlugin } = refClient;
    const { inputs } = options;

    const inputEls = inputs.map((input, index) => {
      const { type, options } = input;
      const { Component } = formViewsPlugin.get(type);

      return (
        <Component
          key={`${index}${type}`}
          options={options}
          fields={fields}
          setFields={setFields}
          refClient={refClient}
        />
      );
    });

    return <Columns>{inputEls}</Columns>;
  }
}

ColumnsFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  refClient: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, refClient }) => {
    const { formViewsPlugin } = refClient;
    const { inputs } = options;
    const params = inputs.reduce((acc, input) => {
      const { type, options } = input;
      return {
        ...acc,
        ...formViewsPlugin
          .get(type)
          .parseUrl({ parsedParams, options, refClient }),
      };
    }, {});

    return params;
  },
  defaultState: (options, refClient) => {
    const { formViewsPlugin } = refClient;
    const { inputs, includeParamsCheckbox } = options;
    const init = {};
    if (includeParamsCheckbox) {
      init[includeParamsCheckbox.key] = includeParamsCheckbox.value;
    }
    return inputs.reduce((acc, input) => {
      const { type, options } = input;
      const { defaultState } = formViewsPlugin.get(type);

      return {
        ...acc,
        ...(isFunction(defaultState)
          ? defaultState(options, refClient)
          : defaultState),
      };
    }, init);
  },
  getRequestOptions: (fields, options, refClient) => {
    const { formViewsPlugin } = refClient;
    const { inputs, includeParamsCheckbox } = options;
    if (includeParamsCheckbox && !fields[includeParamsCheckbox.key]) {
      return {};
    }
    return inputs.reduce((acc, input) => {
      const { type, options } = input;
      return merge(
        acc,
        formViewsPlugin.get(type).getRequestOptions(fields, options)
      );
    }, {});
  },
  Component: ColumnsFormView,
};
