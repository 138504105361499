import React, { useState } from 'react';
import { Marker } from 'react-leaflet';
import HereMarker from 'ref-client-core/views/shared/hereMap/HereMarker';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import HoveredStationTooltip from './HoveredStationTooltip';

const getConnectorType = (type) => type.replace('CONNECTOR_TYPE_', '');
const getPower = (power) => `${power / 1000} kW`;

function StationMarker({
  setResultState,
  data,
  dataIndex,
  id,
  type,
  icon,
  position,
}) {
  const [hoveredStations, setHoveredStations] = useState(null);

  const selectStation = (e) => {
    const partitionData = data[dataIndex];
    const stations = partitionData.stations[id];
    const selectedStation = [];
    const hoveredStations = [];
    stations.forEach((station) => {
      const selectData = {
        ...station,
        charging_station_brand_name:
          partitionData.brands[station.charging_station_brand_name_ref],
        charging_station_port: station.charging_station_port_ref.map(
          (ref) => partitionData.ports[ref]
        ),
      };
      delete selectData.charging_station_brand_name_ref;
      delete selectData.charging_station_port_ref;
      selectedStation.push(selectData);

      const hoverData = {
        brandName:
          partitionData.brands[station.charging_station_brand_name_ref]
            .brand_name,
        ports: station.charging_station_port_ref.map(
          (ref) =>
            `${getPower(partitionData.ports[ref].power)} - ${getConnectorType(
              partitionData.ports[ref].connector_type
            )}`
        ),
      };
      hoveredStations.push(hoverData);
    });
    setResultState({
      selectedStation: {
        dataIndex,
        id,
        data: selectedStation,
      },
    });

    const {
      originalEvent: { x: left, y: top },
    } = e;
    setHoveredStations({ stations: hoveredStations, position: { left, top } });
  };

  const tooltipLeave = () => {
    setHoveredStations(null);
  };

  const marker =
    type === TILE_LIBRARY_TYPES.LEAFLET ? (
      <Marker
        position={{ lat: position.latitude, lng: position.longitude }}
        icon={icon}
        onClick={selectStation}
      />
    ) : (
      <HereMarker
        latLng={{ lat: position.latitude, lng: position.longitude }}
        options={{ icon }}
        onTap={selectStation}
      />
    );

  return (
    <>
      {marker}
      {hoveredStations && (
        <HoveredStationTooltip
          position={hoveredStations.position}
          stations={hoveredStations.stations}
          onMouseLeave={tooltipLeave}
        />
      )}
    </>
  );
}

export default StationMarker;
