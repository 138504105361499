export default function customizationSearchAt(
  fields,
  center,
  radius
) {
  const {lat, lng} = center.latLng;
  const { searchRegion } = fields;
  return {
    searchRegion: {
      ...searchRegion,
      at: `${lat.toFixed(6)},${lng.toFixed(6)}`,
    },
  };
}
