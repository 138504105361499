import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../utils/splunkLog';

class MapErrorHandler extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    logError(error);
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return null;
    }
    return children;
  }
}

MapErrorHandler.propTypes = {
  children: PropTypes.node,
};

export default MapErrorHandler;
