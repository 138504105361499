import get from 'lodash/get';
import { CHARGING_STATION_TABS } from '../chargingStationsPlugins/constants';

function openChargingStations(props) {
  const {
    addTab,
    options: { selectedPath, format, routesArrayPath, routePath, sectionsPath },
    refClient: { formatterPlugin },
  } = props;
  const formatter = formatterPlugin.get(format);
  const routes = get(props, routesArrayPath);
  const selectedRoute = get(props, selectedPath, 0);
  const route = routes[selectedRoute];

  const sections = [];
  if (sectionsPath) {
    get(route, sectionsPath, []).forEach((section) => {
      if (get(section, routePath)) {
        sections.push(get(section, routePath));
      }
    });
  } else if (get(route, routePath)) {
    sections.push(get(route, routePath));
  }
  if (sections.length === 0) return;
  const positions = sections.reduce(
    (acc, section) => [...acc, ...(formatter ? formatter(section) : section)],
    []
  );
  addTab({
    title: 'Charging stations',
    fields: {
      inputType: CHARGING_STATION_TABS.ALONG_THE_ROUTE,
      points: JSON.stringify(positions),
    },
  });
}

export default openChargingStations;
