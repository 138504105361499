import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

function MatrixRoutes(RouteComponent) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      result: PropTypes.object,
      colorPalette: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      selectedTab: PropTypes.number.isRequired,
      setResultState: PropTypes.func.isRequired,
      bounds: PropTypes.object.isRequired,
    };

    onHover = (title) => {
      const { setResultState } = this.props;
      setResultState({ hoveredRoute: title });
    };

    onLeave = () => {
      const { setResultState } = this.props;
      setResultState({ hoveredRoute: null });
    };

    onSelect = (selectedRoute) => {
      const {
        setResultState,
        result: { state = {} },
      } = this.props;
      let newSelectedRoute = null;
      if (selectedRoute !== state.selectedRoute) {
        newSelectedRoute = selectedRoute;
      }
      setResultState({ selectedRoute: newSelectedRoute });
    };

    render() {
      const {
        result: { state = {}, raw = {} },
        fields,
        colorPalette,
        selectedTab,
        tabIndex,
        bounds: { zoom }
      } = this.props;
      const travelTimes = get(raw, 'data.matrix.travelTimes', []);
      const distances = get(raw, 'data.matrix.distances', []);
      const { hoveredRoute, selectedRoute } = state;
      const origins = JSON.parse(fields.origins || '[]');
      let destinations = JSON.parse(fields.destinations || '[]');
      const isDestinationEmpty = destinations.length === 0;

      if (!destinations.length) {
        destinations = origins;
      }
      const routes = [];
      const destLength = destinations.length;
      if (travelTimes.length > 100) {
        if (selectedRoute) {
          const [origin, destination] = selectedRoute.split(' -> ');
          const originIndex = +origin.split(' ')[1] - 1;
          const destIndex = +destination.split(' ')[1] - 1;
          const index = destLength * originIndex + destIndex;
          if (travelTimes[index] || distances[index]) {
            routes.push({
              key: selectedRoute,
              positions: [
                {
                  lat: origins[originIndex].lat,
                  lng: origins[originIndex].lng,
                },
                {
                  lat: destinations[destIndex].lat,
                  lng: destinations[destIndex].lng,
                },
              ],
              weight: 5,
            });
          }
        }
      } else {
        origins.forEach((start, startIndex) => {
          destinations.forEach((dest, destIndex) => {
            const index = destLength * startIndex + destIndex;
            if (travelTimes[index] || distances[index]) {
              const from = isDestinationEmpty
                ? `O/D ${startIndex + 1}`
                : `O ${startIndex + 1}`;
              const to = isDestinationEmpty
                ? `O/D ${destIndex + 1}`
                : `D ${destIndex + 1}`;
              const key = `${from} -> ${to}`;
              routes.push({
                key,
                positions: [
                  { lat: start.lat, lng: start.lng },
                  { lat: dest.lat, lng: dest.lng },
                ],
                weight: key === hoveredRoute || key === selectedRoute ? 5 : 3,
              });
            }
          });
        });
      }

      if (!routes.length) {
        return null;
      }
      return routes.map((route) => {
        const interactableProps = {};
        if (selectedTab === tabIndex && travelTimes.length <= 100) {
          interactableProps.onmouseout = this.onLeave;
          interactableProps.onmouseover = this.onHover.bind(this, route.key);
          interactableProps.onclick = this.onSelect.bind(this, route.key);
        }

        return (
          <RouteComponent
            key={route.key}
            positions={route.positions}
            color={colorPalette.primary}
            weight={route.weight}
            dashArray="10,20"
            offset="3"
            zoom={zoom}
            {...interactableProps}
          />
        );
      });
    }
  };
}

export default MatrixRoutes;
