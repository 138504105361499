import PropTypes from 'prop-types';
import React from 'react';
import utils from 'utils';
import { ExpandFormRow, FormRow, Input, Select } from '@here/ref-client-ui';
import './mapAttributes.scss';
import axios from 'axios';

// const initState = {
//   segments: {
//     type: 'segments',
//     // segments: ''
//   },
//   flexiblePolyline: {
//     type: 'flexiblePolyline',
//     // flexiblePolyline: ''
//   },
//   routerUrl: {
//     type: 'routerUrl',
//     // routerUrl: ''
//   },
//   routerOriginDest: {
//     type: 'routerOriginDest',
//     // routerOriginDest: ''
//   }
// };

// const postTypes = ['segments', 'flexiblePolyline'];
// const rcParamsTypes = ['routerUrl', 'routerOriginDest'];

class MapAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapi: null,
    };
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeInputType = (e) => {
    const {
      options: { key },
      setFields,
      fields
    } = this.props;
    const mapAttrInput = fields[key] || {};
    const type = utils.extractData(e);
    const mapAttributes = { ...mapAttrInput, ...(type? {type} : {}) };
    const newFields = { [key]: mapAttributes };
    
    setFields(newFields);
  };

  onChangeAt = (inputType, e) => {
    const {
      setFields,
      fields: { mapAttributes }
    } = this.props;

    setFields({
        mapAttributes: { ...mapAttributes, type: inputType, [inputType]: e.target.value},
    });
  };

  onChangeOriDes = (pos, e) => {
    const inputType = 'routerOriginDest';
    const {
      setFields,
      fields: { mapAttributes }
    } = this.props;
    let coords = mapAttributes[inputType] || '';
    coords = coords.split(';');
    coords[pos] = e.target.value;

    setFields({
        mapAttributes: { ...mapAttributes, type: inputType, [inputType]: (coords[0]||'') + ';' + (coords[1]||'')},
    });
  };

  getInputTypeFields = () => {
    const {
      options: { key, initValue },
      fields,
    } = this.props;
    const mapAttrInput = fields[key] || {};
    const inputType = mapAttrInput.type || initValue.type;
    
    if (inputType === 'routerOriginDest') {
        return this.getOriginDestFields(inputType);
    } else {
        return this.getFields(inputType);
    }
  };

  getOriginDestFields = (inputType) => {
    const {
        options: { key },
        fields,
      } = this.props;
      const mapAttributes = fields[key] || {};
      const value = mapAttributes[inputType] || '';
      const coords = value.split(';');
      return (
        <>
          <FormRow>
            <Input
              label='Origin'
              placeholder='Origin'
              value={coords[0]}
              onChange={this.onChangeOriDes.bind(this, 0)}
              blurOnEnter
            />
          </FormRow>
          <FormRow>
            <Input
              label='Destination'
              placeholder='Destination'
              value={coords[1]}
              onChange={this.onChangeOriDes.bind(this, 1)}
              blurOnEnter
            />
          </FormRow>
        </>
      );
  };

  getFields = (inputType) => {
    const {
      options: { key, inputTypes },
      fields,
    } = this.props;
    const mapAttributes = fields[key] || {};
    const value = mapAttributes[inputType];
    const input = inputTypes.filter(input => input.value === inputType);

    return (
      <>
        <FormRow>
          <Input
            label={input[0].label}
            placeholder={input[0].label}
            value={value}
            onChange={this.onChangeAt.bind(this, inputType)}
            blurOnEnter
          />
        </FormRow>
      </>
    );
  };

  render() {
    const {
      options: { label, key, inputTypes, initValue },
      fields,
    } = this.props;
    
    const mapAttrInput = fields[key] || {};
    const inputType = mapAttrInput.type || initValue.type;
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltipPlacement="right"
        className="rf-region-definition rf-grey-box"
      >
        <FormRow>
          <Select
            label="Type"
            options={inputTypes}
            value={inputType}
            onChange={this.onChangeInputType}
          />
        </FormRow>
        {this.getInputTypeFields()}
      </ExpandFormRow>
    );
  }
}

MapAttributes.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options, postData, parsedParams, rcParams }) => {
    const { key, initValue, rcParamsTypes, postTypes } = options;
    let type = Object.keys(rcParams)[0] || Object.keys(postData)[0] || initValue.type;

    if ( postTypes.indexOf(type) > -1 ) {
        return { [key]: { type, [type]: postData[type] } };
    } else if ( rcParamsTypes.indexOf(type) > -1 ) {
      return { [key]: { type, [type]: rcParams[type] } };
    } else {
      return { [key]: { [type]: parsedParams[type.toLowerCase()], type } };
    }
  },

  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),

  getRequestOptions: (fields, options) => {
    const { key, isPost, initValue, postTypes, routingUrl } = options;
    const inputType = fields[key] || {};
    const { type = initValue.type } = inputType;
    const { type: mapAttributesType } = fields[key];

    if (isPost && (postTypes.indexOf(type) > -1)) {
      return {
        method: 'post',
        formData: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: {
          [mapAttributesType]: decodeURIComponent(fields[key][mapAttributesType] || "")
        },
      };
    } else {
      const params = fields[key];

      return {
        rcParams: {
          [params.type]: decodeURIComponent(params[params.type] || "")
        },
        method: 'post',
        formData: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        preRequest: (rcParams, settingsRequestOptions, additionalParams={})=>{
          const { params: { apikey } } = settingsRequestOptions;
          const  { routerUrl, routerOriginDest } = rcParams;
          let url = routerUrl;
          if (routerUrl) {
            const regex = /apikey=(.*?)&/;
            const regexRes = routerUrl.match(regex);
            if (regexRes && regexRes[1]) {
              url = routerUrl.replace(regexRes[1], apikey);
            } else {
              url = `${routerUrl}&apikey=${apikey}`
            }
            const regexSpans = /spans=(.*?)&/;
            const regexSpansRes = routerUrl.match(regexSpans);
            if (regexSpansRes && regexSpansRes[1]) {
              const spans = regexSpansRes[1].split(',').concat(['segmentRef','functionalClass','duration']);
              url = url.replace(regexSpansRes[1], [...new Set(spans)].join(','));
            } else {
              url = `${url}&spans=segmentRef,functionalClass,duration`;
            }
          } else if (routerOriginDest) {
            const coords = routerOriginDest.split(';');
            url = routingUrl.replace(/ORIGIN_COORD/, coords[0]).replace(/DESTINATION_COORD/g, coords[1]) +`&apikey=${apikey}`;
          }
 
          return axios.get(url, additionalParams)
            .then((res) => {
              const { data, status } = res;
              if (status === 200) {
                const { routes } = data;
                const path = [];
                
                const geometry = [];
                const segmentsGeometry = [];
                [routes[0]].forEach((itr) => {
                  itr.sections.forEach((section) => {
                    const linestring = window.H.geo.LineString.fromFlexiblePolyline(section.polyline);
                    const latLngAlts = linestring.getLatLngAltArray();
                    for (let i = 0; i<latLngAlts.length; i += 3) {
                      geometry.push({lat: latLngAlts[i], lng:latLngAlts[i+1]});
                    }
                    let remaining = section.summary.duration;
                    section.spans.forEach((span, i) => {
                      const { segmentRef, offset, functionalClass=0, duration } = span;
                      remaining = remaining - duration;
                      const e = [segmentRef,""+ latLngAlts[3*offset], ""+latLngAlts[3*offset+1], ""+remaining, ""+functionalClass].join(',');          
                      path.push(e);
                      
                      const spanGeometry = (i+1 === section.spans.length) ? 
                        latLngAlts.slice(3*offset) :
                        latLngAlts.slice(3*offset, 3*section.spans[i+1].offset);
                      segmentsGeometry.push({
                        segmentRef,
                        segment: Array.from(Array(spanGeometry.length/3), (v, index) => ({
                          lat: spanGeometry[3*index],
                          lng: spanGeometry[3*index+1],
                        }))
                      })
                    })
                  });
                });
                const segments = path.join(';');
                return { 'data': { segments }, 'additionalParams': {geometry, segmentsGeometry, segments} };
              } else {
                return { res };
              }
            })
        }
        // params: {[params.type]: params[params.type]},
      };
    }
    
  },
  Component: MapAttributes,
};
