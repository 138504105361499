import React from 'react';
import PropTypes from 'prop-types'
import CompareGoogleButton from './CompareGoogleButton';
import CompareMapFanButton from './CompareMapFanButton';
import { FormRow, Button } from '@here/ref-client-ui';
import CompareTomTomButton from './CompareTomTomButton';
import openHlsTab from './openHlsTab';

function CompareTo({ fields }) {
  return (
    <>
      <FormRow>
        <Button onClick={() => openHlsTab(fields)} title="Compare To HLS" />
      </FormRow>
      <FormRow>
        <CompareGoogleButton fields={fields} />
      </FormRow>
      <FormRow>
        <CompareTomTomButton fields={fields} />
      </FormRow>
      <FormRow>
        <CompareMapFanButton fields={fields} />
      </FormRow>
    </>
  );
}

CompareTo.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default CompareTo;
