import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'shared/toggleButton';
import './styles/autoRefreshToggler.scss';

class AutoRefreshToggler extends Component {
  onToggle = (e) => {
    this.props.setSettings({ isAutoRefreshOn: e.target.checked });
  };

  render() {
    return (
      <div className="rf-auto-refresh">
        <span className="rf-auto-refresh__label">Auto Refresh:</span>
        <ToggleButton
          defaultChecked={this.props.isAutoRefreshOn}
          onChange={this.onToggle}
        />
      </div>
    );
  }
}

AutoRefreshToggler.propTypes = {
  isAutoRefreshOn: PropTypes.bool.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default AutoRefreshToggler;
