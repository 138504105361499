import * as types from './constants';

export default function map(state = {}, action) {
  switch (action.type) {
    case types.SET_MAP_BOUNDS:
      return { ...action.data };
    default:
      return state;
  }
}

export const getMapBounds = (state) => state.map.bounds;
