import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { latLng } from 'leaflet';
import './corridor.scss';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';
import { decode } from '../../../utils/flexPolyline';
import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import HereGroup from 'shared/hereMap/HereGroup';
import { buffer, lineString } from '@turf/turf';



function CorridorList(CorridorComponent, MarkerComponent, renderEngine) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      setFields: PropTypes.func.isRequired,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
    };

    onChangePoint = (geometryIndex, index, pos) => {
      const {
        options: { onChangeFormatter, key, path, mode = 'avoid' },
        setFields,
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      const boundsList = get(this.props, path);
      let newBound = boundsList[geometryIndex];
      const lng = pos.lng>180?180:(pos.lng<-180?-180:pos.lng);
      newBound.geometry[index] = [pos.lat.toFixed(6), lng.toFixed(6)];
      const newBoundsList = [
        ...boundsList.slice(0, geometryIndex),
        newBound,
        ...boundsList.slice(geometryIndex + 1),
      ];

      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, newBoundsList, mode));
      } else if (key) {
        setFields({ [key]: newBoundsList });
      }
    };

    onChangeMidPoint = (geometryIndex, index, pos) => {
      const {
        options: { onChangeFormatter, key, path, mode = 'avoid' },
        setFields,
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      const boundsList = get(this.props, path);
      let newBound = boundsList[geometryIndex];
      const lng = pos.lng>180?180:(pos.lng<-180?-180:pos.lng);
      newBound.geometry.splice(index, 0, [pos.lat.toFixed(6), lng.toFixed(6)]);
      const newBoundsList = [
        ...boundsList.slice(0, geometryIndex),
        newBound,
        ...boundsList.slice(geometryIndex + 1),
      ];

      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, newBoundsList, mode));
      } else if (key) {
        setFields({ [key]: newBoundsList });
      }
    };

    onChangeShape = (geometryIndex, shape) =>  {
      const {
        options: { onChangeFormatter, key, path, mode = 'avoid' },
        setFields,
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      
      let boundsList = get(this.props, path);
      const newBound = shape.map(([a, b]) => [(b>90?90:(b<-90?-90:b)).toFixed(6), (a>180?180:(a<-180?-180:a)).toFixed(6)]);
      boundsList[geometryIndex].geometry = newBound;

      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, boundsList, mode));
      } else if (key) {
        setFields({ [key]: boundsList });
      }
    }

    onContextMenu = (index, type, event, e) => {
      const { 
        options: { mode = 'avoid', onChangeExcludeFormatter },
        setFields, 
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      const { [mode]: { areas } } = fields;

      if (onChangeExcludeFormatter) {
        if (type === 'remove') {
          const formatter = formatterPlugin.get(onChangeExcludeFormatter+'Remove');
          setFields(formatter(fields, e, mode, index));
        } else if (type === 'bbox' || type === 'polygon' || type === 'corridor'){
          const areaType = type.charAt(0).toUpperCase() + type.slice(1);
          const formatter = formatterPlugin.get(onChangeExcludeFormatter + areaType);
          setFields(formatter(fields, e, mode, true, areas[index].i));
        }
      }
    }

    render() {
      const {
        options: { path, showUnselected, color, exceptionColor, fillColor, fillOpacity, exceptionFillColor },
        selectedTab,
        tabIndex,
        map
      } = this.props;
      
      if (tabIndex !== selectedTab && !showUnselected) {
        return null;
      }

      const corridors = get(this.props, path, []);
      if(!corridors) return null;
      
      return corridors.map((corridor, geometryIndex) => {
        let { geometry, radius, type, isException } = corridor;
        const markers = [];
        const positions = [];
        let positionsWithMiddle = [];
        let startLat, startLng;
        const draggable = (type !== "encodedCorridor");
        const resizable = (type !== "encodedCorridor");
        let isValid = true;
  
        if (type !== "corridor" && type !== "encodedCorridor") {
          return null;
        }
        if (type === "encodedCorridor") {
          geometry = decode(geometry[0]);
        }

        geometry.forEach(([ lat, lng ], index) => {
          if(lat === null || lng === null || lat === "" || lng === "" || lat === "NaN" || lng === "NaN" || lat === undefined || lng === undefined){
            return null;
          }
          if (validateLat(lat) && validateLon(lng)) {
            const latLngValue = latLng(lat, lng);
            positions.push(latLngValue);
            positionsWithMiddle.push(latLngValue);
            markers.push(
              <MarkerComponent 
                key={index} 
                hideOnDrag={true}
                position={latLngValue} 
                index={index+1} 
                geometryIndex={geometryIndex}
                draggable={draggable} 
                resizable={resizable}
                cursorStyle={'pointer'}
                onChange={this.onChangePoint.bind(this, geometryIndex, index)}
              />
            );
          } else {
            isValid = false;
          }
        });

        if (draggable || resizable) {
          let newMiddles = 0;
          positions.forEach(({lat, lng }, index) => {
            if (index > 0) {
              const midlatLngValue = latLng(lat/2 + startLat/2, lng/2 + startLng/2);
              markers.push(<MarkerComponent 
                key={`middlepoint${index}`}
                hideOnDrag={true}
                position={midlatLngValue}
                index={index}
                geometryIndex={geometryIndex}
                draggable={draggable}
                resizable={resizable}
                middlePoint={true}
                cursorStyle={'pointer'}
                onChange={this.onChangeMidPoint.bind(this, geometryIndex, index)}
              />)
              positionsWithMiddle = [
                ...positionsWithMiddle.slice(0, newMiddles+index),
                midlatLngValue,
                ...positionsWithMiddle.slice(newMiddles+index)
              ];
              newMiddles++;
            }

            startLat = lat;
            startLng = lng;

          })
        }
        if (markers.length === 0) {
          return null;
        }
        const zIndex = 100;
        radius = (radius * 1) || 1; 

        if (positions.length === 1) {
          positions.push(positions[0])
        }

        const interpolatedPositions = [];
        const diff = 0.5;

        const positionsWithMiddleLength = positionsWithMiddle.length;
        let lastPosition = positionsWithMiddle[0];
        for (let i = 0; i<positionsWithMiddleLength; i++) {
          const pos = positionsWithMiddle[i];

          for (let j=1; Math.abs(pos.lat-lastPosition.lat)>=j*diff; j++) {
            const p = Math.abs(pos.lat-lastPosition.lat)/(pos.lat-lastPosition.lat);
            const lat = lastPosition.lat + diff * p * j;
            const dlat = pos.lat - lastPosition.lat;
            const dlng = pos.lng - lastPosition.lng;
            
            let lng = dlng * diff * p * j / dlat + lastPosition.lng;

            interpolatedPositions.push({lat, lng});
          }
          interpolatedPositions.push(pos);
          lastPosition = pos;
        }
        
        const linePositions = lineString(interpolatedPositions.map(({ lat, lng }) => [lng, lat]));
        const feature =  buffer(linePositions, radius/1000, { units: 'kilometers' }); 
        const { coordinates } = feature.geometry;
        const corridorPositions = coordinates[0].map(([lng, lat]) => {return {lat, lng}});
        
        if (renderEngine === TILE_LIBRARY_TYPES.JSLA) {
          return (
              <div key={geometryIndex}>
                <HereGroup options={{ zIndex }} key={geometryIndex}>
                  {isValid && <CorridorComponent 
                  contextMenu={true}
                  onContextMenu={this.onContextMenu.bind(this, geometryIndex)}
                  map={map}
                  strokeColor={isException ? exceptionColor : color}
                  fillColor={isException ? exceptionFillColor : fillColor}
                  radius={radius}
                  coatPositions={corridorPositions}
                  positions={positions}
                  positionsWithMiddle={interpolatedPositions}
                  geometryIndex={geometryIndex}
                  draggable={draggable}
                  resizable={resizable}
                  onChange={this.onChangeShape.bind(this, geometryIndex)}
                  isException={isException}
                  />}
                  {markers}
                </HereGroup>
              </div>
          );
        } else {
          return (
              <div key={geometryIndex}>
                {isValid && <CorridorComponent 
                contextMenu={true}
                onContextMenu={this.onContextMenu.bind(this, geometryIndex)}
                map={map}
                color={isException ? exceptionColor : color}
                fillColor={isException ? exceptionFillColor : fillColor}
                fillOpacity={fillOpacity}
                radius={radius}
                coatPositions={corridorPositions}
                positions={positions}
                positionsWithMiddle={interpolatedPositions}
                geometryIndex={geometryIndex}
                draggable={draggable}
                resizable={resizable}
                onChange={this.onChangeShape.bind(this, geometryIndex)}
                isException={isException}
                />}
                {markers}
              </div>
          );
        }
      });
      
    }
  };
}

export default CorridorList;
