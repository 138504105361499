import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormRow, Input } from '@here/ref-client-ui';
import { isObject } from 'lodash';

class ResultPanelErrors extends Component {
  render() {
    const { response } = this.props;
    const { data } = response;

    const content = isObject(data) ? (
      Object.keys(data).map((key) => (
        <FormRow key={key}>
          <Input
            type="text"
            label={key}
            value={JSON.stringify(data[key]).replace(/\\n/g, "\n")}
            isReadonly
          />
        </FormRow>
      ))
    ) : (
      <FormRow>
        <Input type="text" label="Message" value={data} isReadonly />
      </FormRow>
    );

    return (
      <div className="rf-sidebar__error">
        <h4 className="rf-sidebar__header rf-sidebar__header_error">Error</h4>
        <div>
          <FormRow>
            <Input
              type="text"
              label="Status Code"
              value={response.statusCode}
              isReadonly
            />
          </FormRow>
          {content}
        </div>
      </div>
    );
  }
}

ResultPanelErrors.propTypes = {
  response: PropTypes.object.isRequired,
};

export default ResultPanelErrors;
