import React from 'react';
import PropTypes from 'prop-types';
import { latLng, latLngBounds } from 'leaflet';
import get from 'lodash/get';

class RegionDefinition extends React.Component {
  componentDidUpdate(prevProps) {
    const type = get(this.props, 'fields.regionDefinition.type');
    const prevType = get(prevProps, 'fields.regionDefinition.type');
    const {
      setFields,
      bounds: { top, right, bottom, left },
    } = this.props;

    let regionDefinition;
    if (type !== prevType) {
      switch (type) {
        case 'circle': {
          const center = latLngBounds([
            [top, right],
            [bottom, left],
          ]).getCenter();
          const rightLatLon = latLng(center.lat, right);
          const topLatLon = latLng(top, center.lng);
          const distToTop = center.distanceTo(topLatLon);
          const distToRight = center.distanceTo(rightLatLon);
          const radius = Math.floor(Math.min(distToRight, distToTop));
          regionDefinition = {
            type,
            center: {
              lat: +center.lat.toFixed(6),
              lng: +center.lng.toFixed(6),
            },
            radius,
          };
          break;
        }
        case 'boundingBox': {
          const topRight = [top, right].map((coord) => coord.toFixed(6));
          const bottomLeft = [bottom, left].map((coord) => coord.toFixed(6));
          regionDefinition = { type, boundingBox: [topRight, bottomLeft] };
          break;
        }
        case 'polygon': {
          regionDefinition = {type, outer: 
            [{lat: top.toFixed(6), lng: ((left+right)/2).toFixed(6)},
            {lat: bottom.toFixed(6), lng: left.toFixed(6)},
            {lat: bottom.toFixed(6), lng: right.toFixed(6)}]};
          break;
        }
        default:
          return;
      }
      setFields({ regionDefinition });
    }
  }

  render() {
    return null;
  }
}

RegionDefinition.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object.isRequired,
  bounds: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default RegionDefinition;
