import search from './search/reducer';
import notification from './notification/reducer';
import map from './map/reducer';
import appSettings from './appSettings/reducer';
import tabs from './tabs/reducer';
import selectedTab from './selectedTab/reducer';
import ui from './ui/reducer';
import settings from './settings/reducer';
import config from './config/reducer';
import defaultPresets from './defaultPresets/reducer';

export const appReducers = {
  search,
  notification,
  map,
  appSettings,
  tabs,
  selectedTab,
  ui,
  settings,
  config,
  defaultPresets,
};
