/* eslint-disable max-len */
import React from 'react';

// Icons from http://lui.in.here.com/
export function DrivingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>LUI-icon-pd-driving-solid-24</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-driving-solid-24">
          <g
            id="LUI-icon-pd-driving-solid-24-2"
            data-name="LUI-icon-pd-driving-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M22,8H19.45l-.86-1.18A2,2,0,0,0,16.93,6H7.07a2,2,0,0,0-1.66.82L4.55,8H2v2H3.1l-.77,1.06a1.64,1.64,0,0,0-.33,1V17a2,2,0,0,0,2,2H7a1,1,0,0,0,1-1V17h8v1a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2V12.06a1.64,1.64,0,0,0-.33-1L20.9,10H22ZM8,15H4V13H8ZM5,11,6.7,8.45A1,1,0,0,1,7.54,8h8.92a1,1,0,0,1,.84.45L19,11H5Zm15,4H16V13h4Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function CarHovIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <path
        id="hov"
        d="M183,1404.74v6.74a0.515,0.515,0,0,1-.514.52h-2.054a0.515,0.515,0,0,1-.513-0.52L180,1410H167l0.081,1.48a0.515,0.515,0,0,1-.513.52h-2.055a0.515,0.515,0,0,1-.513-0.52V1404s0.545-7,.726-9.08a2.465,2.465,0,0,1,2.211-1.92h13.125a2.465,2.465,0,0,1,2.212,1.92C182.455,1397,183,1404,183,1404v0.74ZM169.471,1409h8.058a0.5,0.5,0,0,0,0-1h-8.058A0.5,0.5,0,0,0,169.471,1409Zm-4.46-3.5a1.5,1.5,0,1,0,1.5-1.5A1.5,1.5,0,0,0,165.011,1405.5Zm15.416-10.22a1.883,1.883,0,0,0-1.746-1.28H168.319a1.883,1.883,0,0,0-1.746,1.28C166.43,1396.67,166,1402,166,1402h15S180.57,1396.67,180.427,1395.28Zm0.059,8.72a1.5,1.5,0,1,0,1.5,1.5A1.507,1.507,0,0,0,180.486,1404Zm-9.986-5a2.5,2.5,0,0,1,2.45,2h-4.9A2.5,2.5,0,0,1,170.5,1399Zm6,0a2.5,2.5,0,0,1,2.45,2h-4.9A2.5,2.5,0,0,1,176.5,1399Zm0-1a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,176.5,1398Zm-6,0a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,170.5,1398Z"
        transform="translate(-156.5 -1385.5)"
      />
    </svg>
  );
}

export function PublicTransportIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <path
        id="public_transport"
        d="M236,1408H225a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h11a2,2,0,0,1,2,2v14A2,2,0,0,1,236,1408Zm-11-3h1a1,1,0,0,0,0-2h-1A1,1,0,0,0,225,1405Zm9.4-12.99h-7.81a0.5,0.5,0,1,0,0,.98h7.81A0.5,0.5,0,1,0,234.4,1392.01ZM237,1394H224v8h13v-8Zm-1,9h-1a1,1,0,0,0,0,2h1A1,1,0,0,0,236,1403Zm-14-12h-5a1,1,0,0,0-1,1v6h6v6h-4v1a1,1,0,0,1-1,1,0.991,0.991,0,0,1-.967-0.84A0.293,0.293,0,0,1,216,1405v-1a3.9,3.9,0,0,1-1-2v-6h0a1,1,0,0,1-1-1v-1.95a0.677,0.677,0,0,1,.045-0.27A0.989,0.989,0,0,1,215,1392v-1.5a2.5,2.5,0,0,1,2.5-2.5H226a2.034,2.034,0,0,1,2,1h-3A3.458,3.458,0,0,0,222,1391Zm-4,11a1,1,0,1,0-1-1A1,1,0,0,0,218,1402Zm8,7h2v1h5v-1h2v1h2v2H224v-2h2v-1Z"
        transform="translate(-209 -1383)"
      />
    </svg>
  );
}

export function PublicTransportTimeTableIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <path
        id="public_transport_timetable"
        d="M290,1408H279a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h4.233a5.492,5.492,0,0,0-.233,1.5c0,0.17.035,0.34,0.051,0.51h-2.457a0.5,0.5,0,1,0,0,.98h2.637a5.5,5.5,0,0,0,.4,1.01H278v8h13v-5.63a5.062,5.062,0,0,0,1-.66V1406A2,2,0,0,1,290,1408Zm-10-5h-1a1,1,0,0,0,0,2h1A1,1,0,0,0,280,1403Zm10,0h-1a1,1,0,0,0,0,2h1A1,1,0,0,0,290,1403Zm-1.5-8a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,288.5,1395Zm2-4H289v-1.5a0.5,0.5,0,0,0-1,0v2a0.5,0.5,0,0,0,.5.5h2A0.5,0.5,0,0,0,290.5,1391Zm-14.5,0h-5a1,1,0,0,0-1,1v6h6v6h-4v1a1,1,0,0,1-1,1,0.991,0.991,0,0,1-.967-0.84A0.293,0.293,0,0,1,270,1405v-1a3.9,3.9,0,0,1-1-2v-6h0a1,1,0,0,1-1-1s0.027-3.01,0-2.02a0.42,0.42,0,0,1,.041-0.2A0.989,0.989,0,0,1,269,1392v-1h0v-0.5a2.5,2.5,0,0,1,2.5-2.5H280a2.034,2.034,0,0,1,2,1h-3A3.458,3.458,0,0,0,276,1391Zm-4,11a1,1,0,1,0-1-1A1,1,0,0,0,272,1402Zm8,7h2v1h5v-1h2v1h2v2H278v-2h2v-1Z"
        transform="translate(-263 -1383)"
      />
    </svg>
  );
}

export function BicycleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>LUI-icon-pd-bicycle-solid-24</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-bicycle-solid-24">
          <g
            id="LUI-icon-pd-bicycle-solid-24-2"
            data-name="LUI-icon-pd-bicycle-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M18.4,13,16.86,3H13V5h2.14l.47,3.05-5.42.77L8.58,6H5V8H7.42l.77,1.35L6.6,13.05A3.94,3.94,0,0,0,6,13a4,4,0,1,0,4,4,4,4,0,0,0-1.56-3.16l1-2.31,1.05,1.83a2,2,0,0,0,1.47,1l.27,0a2,2,0,0,0,1.41-.59l2.48-2.47.31,2a4,4,0,1,0,2-.31ZM8,17a2,2,0,1,1-2.24-2A1,1,0,0,1,6,15a2,2,0,0,1,2,2Zm4.22-4.63-1-1.68,3.07-.44ZM18,19a2,2,0,0,1-2-2,2,2,0,0,1,.75-1.55A1.92,1.92,0,0,1,18,15a2,2,0,0,1,.73.14A2,2,0,0,1,18,19ZM21,8H20a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1h1Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function MotoscooterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>LUI-icon-pd-motorscooter-solid-24</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-motorscooter-solid-24">
          <g
            id="LUI-icon-pd-motorscooter-solid-24-2"
            data-name="LUI-icon-pd-motorscooter-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M16.82,4H13V6h2.18l.72,2.6-4.62,3.69L9.54,9H2v2h.64A6.57,6.57,0,0,0,2,14v2H4a4,4,0,0,0,8,0V14.28L18.1,9.4ZM8,18a2,2,0,0,1-2-2h4A2,2,0,0,1,8,18Zm10-6a4,4,0,1,0,4,4A4,4,0,0,0,18,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,18,18Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function TruckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>LUI-icon-pd-truck-solid-24</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-truck-solid-24">
          <g
            id="LUI-icon-pd-truck-solid-24-2"
            data-name="LUI-icon-pd-truck-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M20,11V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4v7H2v3H4v6a2,2,0,0,0,2,2H9a1,1,0,0,0,1-1V20h4v1a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2V14h2V11ZM10,18H6V16h4Zm8,0H14V16h4ZM6,14,7,8H17l1,6Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function WalkingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>LUI-icon-pd-walking-solid-24</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-walking-solid-24">
          <g
            id="LUI-icon-pd-walking-solid-24-2"
            data-name="LUI-icon-pd-walking-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M11.5,6A1.5,1.5,0,0,0,13,4.5v-1a1.5,1.5,0,0,0-3,0v1A1.5,1.5,0,0,0,11.5,6Zm2.91,5.41a2,2,0,0,0,1.42.59H19V10H16L13,7H9v6l.67,1L9,16,5,20l2,2,4-4,.62-1.25L16,22l2-2-5-6V10ZM7,7,5.59,8.41A2,2,0,0,0,5,9.83V13H7Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function TaxiIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-taxi-solid-24">
          <g
            id="LUI-icon-pd-taxi-solid-24-2"
            data-name="LUI-icon-pd-taxi-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M22,10H19.45l-.86-1.18A2,2,0,0,0,16.93,8H7.07a2,2,0,0,0-1.66.82L4.55,10H2v2H3.1l-.77,1.06a1.64,1.64,0,0,0-.33,1V19a2,2,0,0,0,2,2H7a1,1,0,0,0,1-1V19h8v1a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2V14.06a1.64,1.64,0,0,0-.33-1L20.9,12H22ZM8,17H4V15H8ZM5,13l1.7-2.55A1,1,0,0,1,7.54,10h8.92a1,1,0,0,1,.84.45L19,13H5Zm15,4H16V15h4ZM15,6H9V4h6Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function BusIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-bus_local-solid-24">
          <g
            id="LUI-icon-pd-bus_local-solid-24-2"
            data-name="LUI-icon-pd-bus_local-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M20,6V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V6H2v4H4V20a2,2,0,0,0,2,2H9a1,1,0,0,0,1-1V20h4v1a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2V10h2V6ZM10,18H6V16h4Zm8,0H14V16h4Zm0-4H6V8H18Zm0-8H6V4H18Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PrivateBusIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <g id="Layer_2" data-name="Layer 2">
        <g id="LUI-icon-pd-bus_local-solid-24">
          <g
            id="LUI-icon-pd-bus_local-solid-24-2"
            data-name="LUI-icon-pd-bus_local-solid-24"
          >
            <rect width="24" height="24" fill="none" />
            <path d="M20,6V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V6H2v4H4V20a2,2,0,0,0,2,2H9a1,1,0,0,0,1-1V20h4v1a1,1,0,0,0,1,1h3a2,2,0,0,0,2-2V10h2V6ZM10,18H6V16h4Zm8,0H14V16h4Zm0-4H6V8H18Zm0-8H6V4H18Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}