import React from 'react';
import { get, isEqual } from 'lodash';


/**
    Helper to create the line style based on the links confidence value
*/
function makeConfidenceAwareStyle(c, filterLinks, colorPalette) {
    if(!filterLinks) {
        // default blue RME links
        return {lineWidth: 8, strokeColor: colorPalette.primary, lineJoin: "round"};
    }
    if (c === undefined) {
      // support rme versions without confidence on result.
      return {lineWidth: 8, strokeColor: colorPalette.primary, lineJoin: "round"};
    }
    var color;
    var MAX_CONF = 1.0;
    if (c > MAX_CONF) {
      color = 'green';
    } else if (c <= 0.01) {
      color = 'red';
    } else {
      var greenPart = c;
      var redPart = MAX_CONF - c;

      var red = Math.floor(255 * redPart / MAX_CONF);
      var green = Math.floor(255 * greenPart / MAX_CONF);

      color = 'rgba(' + red + ', ' + green + ', 0, 0.7)';

    }
    return {lineWidth: 8, strokeColor: color, lineJoin: 'round', zIndex: 3};
}
  
let uTurn = false;

function getCoordsWithOffset(coords1, distance, currentLink, numberOfLinks) {
    var temp = [];
    var prevCoord = [coords1[0], coords1[1]];
    var midPoint;
    var midPointIndex;
    for (var c = 0; c < coords1.length; c += 2){
        var linkLength = getKartesianDistanceInMeter(prevCoord[0], prevCoord[1], coords1[c], coords1[c+1]);  //calculate distance to the next point           // if this is a link with offset, do calculations for the offset
        if ((distance - linkLength) < 0) {        //if offset is not reached add new point
            // var midPoint = getMidPoint(prevCoord[0], prevCoord[1], coords1[c], coords1[c+1], linkLength - distance);  //if offset is reached calculate new point based on the distance from the first point, and angle of the link.
            midPoint = getMidPoint(prevCoord[0], prevCoord[1], coords1[c], coords1[c+1], distance);  //if offset is reached calculate new point based on the distance from the first point, and angle of the link.
            midPointIndex = c;
            break;
        } else {
            distance = distance - linkLength;
        }
        prevCoord[0] = coords1[c];
        prevCoord[1] = coords1[c + 1];
    }
    if(!midPoint) {
        midPoint = getMidPoint(coords1[coords1.length - 4], coords1[coords1.length - 3], coords1[coords1.length - 2], coords1[coords1.length - 1], distance);  //if offset is reached calculate new point based on the distance from the first point, and angle of the link.
        midPointIndex = coords1.length - 2;
    }
    if (currentLink === 0 || uTurn){
        if (uTurn) uTurn = false;	
        temp.push(String(midPoint[0]));
        temp.push(String(midPoint[1]));
        for (let c = midPointIndex; c < coords1.length; c += 1){
            temp.push(coords1[c]);
        }
    } else {                                         
        if (currentLink !== numberOfLinks-1) uTurn = true;         
        for (let c = 0; c < midPointIndex; c += 1){
            temp.push(coords1[c]);
        }
        temp.push(midPoint[0]);
        temp.push(midPoint[1]);
    }

    return temp;
}
   

function getKartesianDistanceInMeter(lat1, lon1, lat2, lon2) {
    var earthRadius = 6371000;
    // convert input parameters from decimal degrees into radians
    var phi1 = (lat1) * Math.PI / 180;	  
    var phi2 = (lat2) * Math.PI / 180;
    var dphi = phi2 - phi1;
    var dl = (lon2 - lon1) * (Math.PI / 180);

    var a = Math.sin(dphi/2) * Math.sin(dphi/2) +
            Math.cos(phi1) * Math.cos(phi2) *
            Math.sin(dl/2) * Math.sin(dl/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return earthRadius * c;
}

function getMidPoint(lat1, lon1, lat2, lon2, distance) {
    /* var lon = ratio*lon1 + (1.0 - ratio)*lon2;
        var lat = ratio*lat1 + (1.0 - ratio)*lat2;*/
        
        var heading = getHeading(lat2,lon2,lat1,lon1);
        var shiftedLatLon = shiftLatLon(lat1, lon1, ((parseFloat(heading) + 180) % 360), distance);  // only 180 degrees to go into the opposite direction
        
    return shiftedLatLon;
}

function getHeading(lat1,lng1,lat2,lng2) {
    var phi1 = lat1 * (Math.PI / 180),
        phi2 = lat2 * (Math.PI / 180),
        dl = (lng2 - lng1) * (Math.PI / 180),
        y = Math.sin(dl) * Math.cos(phi2),
        x = Math.cos(phi1) * Math.sin(phi2) - Math.sin(phi1) * Math.cos(phi2) * Math.cos(dl),
        t = Math.atan2(y, x);

    return Math.round(((t * 180 / Math.PI) + 360) % 360);
};
  
/**
 This method shifts the given lat and long along given bearing to the given distance
*/
function shiftLatLon(latDegrees, lonDegrees, bearing, distance) {
    var earthRadius = 6371000;
    // convert input parameters from decimal degrees into radians
    var latRad = (latDegrees) * Math.PI / 180;
    var lonRad = (lonDegrees) * Math.PI / 180;

    var bearingRad = bearing * Math.PI / 180;
    var distRad = distance / earthRadius;

    var latNewRad = Math.asin(Math.sin(latRad) * Math.cos(distRad) + Math.cos(latRad) * Math.sin(distRad)
    * Math.cos(bearingRad));
    var lonNewRad = lonRad
    + Math.atan2(Math.sin(bearingRad) * Math.sin(distRad) * Math.cos(latRad), Math.cos(distRad) - Math.sin(latRad)
    * Math.sin(latNewRad));

    // convert input parameters from radians into decimal degrees
    var latNewDegrees = latNewRad * 180 / Math.PI;
    var lonNewDegrees = lonNewRad * 180 / Math.PI;
    var latLonRet = [];
    latLonRet.push(latNewDegrees);
    latLonRet.push(lonNewDegrees);
    return latLonRet;
}

function Route(MarkerComponent, LineComponent) {
  return class Route extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.isUnmounted = false;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
      const {
        setResultState,
        result: { raw },
      } = this.props;
      
      const { result: { raw: prevRaw } } = prevProps;
  
      if (!isEqual(raw, prevRaw)) {
        setResultState({ selectedLine: null });
        setResultState({ selectedWarning: null });
        this.setState({selectedLine: null});
      }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    onTapRoute = (line, i) => {
        const { setResultState } = this.props;
        if (this.state.selectedLine !== i) {
            setResultState({ selectedLine: line });
            this.setState({selectedLine: i});
        } else {
            setResultState({ selectedLine: null });
            this.setState({selectedLine: null});
        }
    }

    getRouteConfidence = (routesConfidence) => {
        return routesConfidence.map((routeConfidence, i) => {
            if ( !routeConfidence ) return null;
            const { coords, strokeColor, lineWidth, zIndex, link } = routeConfidence;
            const style = { strokeColor, lineWidth };
            return (<LineComponent
                changeCursorOnHover
                key={`routeconfidence${i}`}
                latLngs={coords}
                options={{ zIndex, style }}
                onTap={this.onTapRoute.bind(this, link, i)}
            />);
        })
    };

    getRouteMatchRoute = (respJsonObj) => {
        const {
            result: { state },
            colorPalette
        } = this.props;
        const {
            filterLinks = false,
            matchConfidence = 0,
        } = state;
        const {
            selectedLine
        } = this.state;

        const links = [];
        var routeLinks = respJsonObj.route[0].leg[0].link;

        var lLinkId = -99999999999999999; 
        for (var l = 0; l < routeLinks.length; l++) {
            if(filterLinks) {
                if (routeLinks[l].confidence < matchConfidence) {
                    links.push(null);
                    continue;
                }
            }

            var coords1 =  routeLinks[l].shape; //in calculateroute resource ths shape is already returned as array
            if (routeLinks[l].offset && routeLinks[l].offset < 1) {
                let distance;
                if (routeLinks[l].linkId < 0){
                    distance = (1 - routeLinks[l].offset) * routeLinks[l].length; //if  offset is set calculate new length of the link, caclulateroute.json resource returns back the link length in the length json field while matchroute.json returns it in linkLength
                } else {
                    distance = routeLinks[l].offset * routeLinks[l].length; //if  offset is set calculate new length of the link
                }
                coords1 = getCoordsWithOffset(coords1, distance, l, routeLinks.length);
            }
            
            if(Math.abs(routeLinks[l].linkId) !== lLinkId) {
                var coords2 = [];
                for (var c = 0; c < coords1.length; c += 2){
                    coords2.push({lat: coords1[c], lng: coords1[c+1]}); //if it is not offset link, just add new point
                }
                
                lLinkId = Math.abs(routeLinks[l].linkId); 

                var lineStyle = makeConfidenceAwareStyle(routeLinks[l].confidence, filterLinks, colorPalette);

                if (selectedLine === links.length) lineStyle.strokeColor = colorPalette.secondary;
                links.push({ coords: coords2, ...lineStyle, link: routeLinks[l] });
            }
        }

        return links;
    };

    getTracePoints = (respJsonObj) => {
        const {
            result: { state },
            colorPalette
        } = this.props;
        const {
            inputTracePoints = true,
            matchedPointsnroute = true,
            filterLinks = false,
            selectedWarning
        } = state;
        const { tracePointSeqNum } = selectedWarning || {};

        // draw the original and the matched trace points
        const tracePoints = respJsonObj.route[0].waypoint;

        return tracePoints.map((tracePoint, id) => {
            const { originalPosition, mappedPosition } = tracePoint;
            return [
                inputTracePoints && <MarkerComponent key={`originalmarker${id}`} position={originalPosition} color={"#000000"} text={id} />,
                matchedPointsnroute && (tracePointSeqNum !== String(id)) && <MarkerComponent key={`mappedmarker${id}`} position={mappedPosition} color={"#00FF00"} text={id} />,
                (tracePointSeqNum === String(id)) && <MarkerComponent key={`selectedmarker${id}`} position={mappedPosition} color={colorPalette.secondaryDarker} text={id} selected={true} />,
                filterLinks && <LineComponent key={`line${id}`} id={id} latLngs={[{lat: originalPosition.latitude, lng: originalPosition.longitude}, {lat: mappedPosition.latitude, lng: mappedPosition.longitude}]} options={{ style: {strokeColor: '#000', lineWidth: 1} }} />
            ];
        })
    }

    render() {
        const {
            options: { routesPath }
        } = this.props;
        const routes = get(this.props, routesPath);

        if (!routes) {
            return null;
        }

        const links = this.getRouteMatchRoute(routes);
        const tracePoints = this.getTracePoints(routes);
        return (
        <>
            {this.getRouteConfidence(links)}
            {tracePoints.flat()}
        </>
        );
    }
  };
}

export default Route;
