const toFixed6 = (val) => +val.toFixed(6);

// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e, mode = 'avoid', isException, exceptionOf) => {
  const {
    [mode]: { areas, features },
  } = fields;
  const {
    xy: { x, y },
    xyToLatLng,
    zoom,
  } = e;
  exceptionOf = exceptionOf === undefined ? areas.length : exceptionOf;

  const padding = 100;
  const radius = Math.max(Math.pow(2, 14-zoom) * 100, 100);
  const left = xyToLatLng({ x: x - padding, y });
  const right = xyToLatLng({ x: x + padding, y });

  return {
    [mode]: {
      areas: [
        ...areas,
        {
          geometry:[
            [toFixed6(left.lat), toFixed6(left.lng)],
            [toFixed6(right.lat), toFixed6(right.lng)],
          ],
          type: "corridor",
          radius: radius,
          isException: !!isException,
          i: exceptionOf
        }
      ],
      features,
    },
  };
};
