import React from 'react';
import PropTypes from 'prop-types';
import HereRoute from './HereRoute';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { decode, encode } from 'ref-client-core/utils/flexPolyline';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';
import { withHereMap } from '../../../views/shared/hereMap/HereMapContext';

function getPolyline(route) {
  const { sections = [] } = route;

  if (sections.length === 0) {
    return '';
  } else if (sections.length === 1) {
    return sections[0].polyline;
  } else {
    let points = [];
    sections.forEach(section => {
      const p = decode(section.polyline);
      points = points.concat(p);
    })
    return encode(points);
  }
}
class HereRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isUnmounted = false;
    this.showIndicator = false;
  }

  mapviewchangeend = (e) => {
    const { map } = this.props;
    if( map.getZoom() <= 14 ) {
      this.setState({
        showIndicator: false,
      })
    } else if(this.showIndicator) {
      this.setState({
        showIndicator: true,
      })
    }
  }

  componentDidMount() {
    const { map } = this.props;
    map.addEventListener('mapviewchangeend', this.mapviewchangeend)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { options } = this.props;
    const { routesArrayPath, selectedPath, highlightedPath } = options;
    const routes = get(this.props, routesArrayPath);
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);

    const nextRoutes = get(nextProps, routesArrayPath);
    const nextSelectedRoute = get(nextProps, selectedPath, 0);
    const nextHighlightedRoute = get(nextProps, highlightedPath, -1);

    return (
      !isEqual(routes, nextRoutes) ||
      selectedRoute !== nextSelectedRoute ||
      highlightedRoute !== nextHighlightedRoute ||
      !isEqual(this.state, nextState)
    );
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    const { map } = this.props;
    map.removeEventListener('mapviewchangeend', this.mapviewchangeend)
  }

  selectRoute = (currentRoute) => {
    const { setResultState } = this.props;
    setResultState({ currentRoute });
  };

  onContextMenu = (e) => {
    const { map, selectedTab, tabIndex } = this.props;
    if (selectedTab !== tabIndex) {
      return;
    }
    e.stopPropagation();
    const {
      originalEvent: { x, y },
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
      },
    });
    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  };

  close = () => {
    const { map } = this.props;
    if (!this.isUnmounted) {
      this.setState({ open: false });
    }
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };

  toggleIndicator = (show) => {
    const { map } = this.props;
    if ( map.getZoom() > 14 ) {
      this.showIndicator = show;
      this.setState({ showIndicator: this.showIndicator });
    }
  }

  getMapAttributes = () => {
    const { addTab, fields, options: { selectedPath }, } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const routes = get(this.props, "result.raw.data.routes", []);
    const flexiblePolyline = getPolyline(routes[selectedRoute] || {});

    const { key, value } = fields.routeTime;
    const routeTime = (key==="departureTime" && value!== "any") ? {key, value} : {key:'', value:''};
    const trailersCount = fields['vehicle[trailerCount]'] > 3 ? '3' : fields['vehicle[trailerCount]'];
    const shippedHazardousGoods = JSON.parse(JSON.stringify(fields['vehicle[shippedHazardousGoods]']));
    const index = shippedHazardousGoods.values.indexOf('radioactive');
    if (index !== -1) {
      shippedHazardousGoods.values[index] = 'radioActive';
    }
    const mafields = {
      mapAttributes: {
        flexiblePolyline,
        type: "flexiblePolyline"
      },
      transportMode: fields.transportMode,
      routeTime,
      vehicleWeight: fields['vehicle[grossWeight]'],
      length: fields['vehicle[length]'],
      trailersCount,
      shippedHazardousGoods
    }

    addTab({ title: 'Map Attributes', fields: mafields });
  }

  renderRoutes = (routes, routePath, formatter, colorPalette, sectionsPath, type) => {
    const {
      options: { selectedPath, highlightedPath },
    } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);
    const polylines = [];
    routes.forEach((routeWrapper, routeIndex) => {
      const sections = [];
      if (sectionsPath) {
        get(routeWrapper, sectionsPath, []).forEach((section) => {
          if (get(section, routePath)) {
            sections.push(get(section, routePath));
          }
        });
      } else if (get(routeWrapper, routePath)) {
        sections.push(get(routeWrapper, routePath));
      }
      if (sections.length === 0) return;
      sections.forEach((section, index) => {
        const positions = formatter ? formatter(section) : section;
        const key = `${routeIndex}${index}`;
        const keyshadow = `${routeIndex}${index}s`;
        const keyshadowbg = `${routeIndex}${index}sbg`;

        if (routeIndex === highlightedRoute) {
          polylines.push(
            <HereRoute
              changeCursorOnHover
              onClick={() => this.selectRoute(routeIndex)}
              key={key}
              positions={positions}
              color={colorPalette.secondaryDarker}
              weight={8}
              zIndex={30}
              onContextMenu={this.onContextMenu}
            />
          );
        } else if (routeIndex === selectedRoute) {
          polylines.push(
            <HereRoute
              key={key}
              positions={positions}
              color={colorPalette.primary}
              weight={8}
              zIndex={20}
              onContextMenu={this.onContextMenu}
            />
          );

          if( this.state.showIndicator ) {
            if( type === 'vector' ) {
              polylines.push(
                <HereRoute
                  key={keyshadow}
                  positions={positions}
                  color={colorPalette.primaryDarker}
                  weight={8}
                  zIndex={19}
                  offset={0.0002}
                  type={'vectorArrow'}
                />
                , 
                <HereRoute
                  key={keyshadowbg}
                  positions={positions}
                  color={colorPalette.primaryDarker}
                  weight={8}
                  zIndex={18}
                  offset={0.0002}
                  type={'vectorArrowBg'}
                />
              );
            } else {
              polylines.push(
                <HereRoute
                  key={keyshadow}
                  positions={positions}
                  color={colorPalette.primaryDarker}
                  weight={8}
                  zIndex={18}
                  offset={0.0002}
                  type={'rasterArrow'}
                />
              );
            }
          }
        } else {
          polylines.push(
            <HereRoute
              changeCursorOnHover
              onClick={() => this.selectRoute(routeIndex)}
              key={key}
              positions={positions}
              color={colorPalette.secondary}
              weight={6}
              zIndex={10}
              onContextMenu={this.onContextMenu}
            />
          );
        }
      });
    });
    return [...polylines];
  };

  render() {
    const {
      options,
      colorPalette,
      refClient: { formatterPlugin },
      type,
      map
    } = this.props;
    const {
      routesArrayPath,
      routePath,
      format,
      sectionsPath,
      contextMenuFormatter,
      contextMenuLabel,
      getMapAttributes
    } = options;
    const formatter = formatterPlugin.get(format);
    const routes = get(this.props, routesArrayPath);
    if (!routes) {
      return null;
    }
    return (
      <>
        {this.renderRoutes(
          routes,
          routePath,
          formatter,
          colorPalette,
          sectionsPath,
          type
        )}
        {(contextMenuFormatter || map.getZoom() > 14) && this.state.open && (
          <div className="rf-context-menu" style={{ ...this.state.position }}>
            { contextMenuFormatter && (
              <HereMapContextMenuItem
                label={contextMenuLabel}
                onClick={formatterPlugin
                  .get(contextMenuFormatter)
                  .bind(this, this.props)}
              />
            )}
            { getMapAttributes && (
              <HereMapContextMenuItem
              label={`Get Map Attributes`}
              onClick={() => this.getMapAttributes()}
            />
            )}
            { map.getZoom() > 14 && (
              <HereMapContextMenuItem
                label={`${this.showIndicator?'Hide':'Show'} Maneuver Indicator`}
                onClick={() => this.toggleIndicator(!this.showIndicator)}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

HereRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default withHereMap(HereRoutes);
