import routeTimeFormView from 'ref-client-core/core/formViewsPlugins/routeTime/routeTimeFormView';

const isolineRouteTimeFormView = {
  ...routeTimeFormView,
  getRequestOptions: (fields, options) => {
    const {
      routeTime: { value },
      originDestinationKey,
    } = fields;
    if (value === 'any') {
      const key =
        originDestinationKey === 'destination'
          ? 'arrivalTime'
          : 'departureTime';
      return { params: { [key]: 'any' } };
    } else {
      return routeTimeFormView.getRequestOptions(fields, options);
    }
  },
};

export default isolineRouteTimeFormView;
