import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLeaflet } from 'react-leaflet';
import EditableCircle from '../../../views/shared/EditableCircle';

export default function Circle(props) {
  const {centerOnChange, center} = props;
  const leaflet = useLeaflet();

  useEffect(() => {
    if (
      centerOnChange && center
    ) {      
      const zoom = leaflet.map.getZoom();
      leaflet.map.flyTo(center, zoom > 14 ? zoom : 14);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  return (
      <EditableCircle {...{ ...props }} />
  );
}

Circle.propTypes = {
  color: PropTypes.string,
};
