import { useEffect } from 'react';
import { useLeaflet } from 'react-leaflet';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import { getCenterFromPolyline } from 'ref-client-core/core/mapPlugins/route/helpers';
import { useHereMap } from 'ref-client-core/views/shared/hereMap/HereMapContext';
import get from 'lodash/get';

function CenterMapLeaflet(props) {
  const leaflet = useLeaflet();
  const shape = get(props, props.options.path);

  useEffect(() => {
    if (shape && shape.length > 0) {
      leaflet.map.setView(getCenterFromPolyline(shape));
    } else if ( shape && shape.hasOwnProperty('index')) {
      const items = get(props, props.options.resultsPath);
      leaflet.map.setView(items[shape.index].position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shape]);

  return null;
}

function CenterMapJsla(props) {
  const { map } = useHereMap();
  const shape = get(props, props.options.path);

  useEffect(() => {
    if (shape && shape.length > 0) {
      map.setCenter(getCenterFromPolyline(shape), true);
    } else if ( shape && shape.hasOwnProperty('index')) {
      const items = get(props, props.options.resultsPath);
      map.setCenter(items[shape.index].position, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shape]);

  return null;
}

const centerMapPlugin = {
  [TILE_LIBRARY_TYPES.LEAFLET]: CenterMapLeaflet,
  [TILE_LIBRARY_TYPES.JSLA]: CenterMapJsla,
};

export default centerMapPlugin;
