import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@here/ref-client-ui';
import './loader.scss';

class LoaderFormView extends React.Component {
  render() {
    const {
      fields: { isLoading },
    } = this.props;

    if (!isLoading) {
      return null;
    }

    return (
      <div className="rf-loader-form-view">
        <Loader />
      </div>
    );
  }
}

LoaderFormView.propTypes = {
  fields: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({}),
  defaultState: () => ({
    isLoading: false,
  }),
  getRequestOptions: () => ({}),
  Component: LoaderFormView,
};
