import * as tabTypes from '../../constants';
import { RESTORE_FROM_URL } from '../../../constants';

const titleReducer = (state = '', action) => {
  switch (action.type) {
    case tabTypes.ADD_TAB:
      return action.tabData.title;
    case RESTORE_FROM_URL:
      return action.payload.title;
    default:
      return state;
  }
};

export default titleReducer;
