import tabReducer from './tab/reducer';
import * as types from './constants';
import * as selectedHistoryTypes from './tab/selectedHistory/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = [], action) {
  let { index } = action.tabData || {};
  switch (action.type) {
    case types.ADD_TAB: {
      if (action.payload.id) {
        const tabStateIndex = state.findIndex(
          (tab) => action.payload.id === tab.id
        );
        if (tabStateIndex !== -1) {
          return [
            ...state.slice(0, tabStateIndex),
            tabReducer(undefined, action),
            ...state.slice(tabStateIndex + 1),
          ];
        }
      }
      return [tabReducer(undefined, action), ...state];
    }
    case types.REMOVE_TAB: {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case types.REORDER_TABS: {
      return action.data.indexes.map((index) => state[index]);
    }
    case types.DUPLICATE_TAB: {
      return [
        ...state.slice(0, index + 1),
        tabReducer(JSON.parse(JSON.stringify(state[index])), action),
        ...state.slice(index + 1),
      ];
    }
    case selectedHistoryTypes.SET_SELECTED_HISTORY: {
      if (action.index !== -1) {
        const newTabState = {
          ...state[index].history[action.index].tabData,
        };
        newTabState.history = [...state[index].history];

        Object.assign(action.tabData, {
          form: newTabState.form,
          module: newTabState.module,
        });
        return [
          ...state.slice(0, index),
          tabReducer(newTabState, action),
          ...state.slice(index + 1),
        ];
      }
      return defaultHandler();
    }
    default: {
      return defaultHandler();
    }
  }

  function defaultHandler() {
    if (index !== undefined) {
      return [
        ...state.slice(0, index),
        tabReducer(state[index], action),
        ...state.slice(index + 1),
      ];
    }
    return state;
  }
}
