import autoRefreshPlugin from '../core/autoRefreshPlugin';
import { without } from 'lodash';
import utils from 'utils';

export default function isFormPerformedForRequest(
  tabData,
  config,
  oldFields = {}
) {
  const silentParams = config.silentParams || [];
  let conditions = config.conditions || [{ type: 'allFieldsPerformed' }];
  let diffKeys = utils.objectDiffKeys(tabData.fields, oldFields);
  if (!without(diffKeys, ...silentParams).length) {
    return false;
  }
  return conditions.every((currentConfig) => {
    const plugin = autoRefreshPlugin.get(currentConfig.type);
    return plugin(tabData, currentConfig.options);
  });
}
