import React from 'react';
import {
  validateLat,
  validateLon,
} from 'ref-client-core/utils/validation/coordsValidation';
import { Checkbox, CloseButton, FormRow, Input } from '@here/ref-client-ui';
import isFinite from 'lodash/isFinite';

export const isPointValid = (point) =>
  isFinite(point.lat) &&
  isFinite(point.lng) &&
  validateLat(point.lat) &&
  validateLon(point.lng);

function TraceItem({
  onRemove,
  index,
  onChangeLatLng,
  onChangeVia,
  trace,
  isVia,
}) {
  const changeLatLng = ({ target: { value } }) => {
    const [lat, lng] = value
      .split(',')
      .map((coord) => (isNaN(coord) ? coord : parseFloat(coord)));
    onChangeLatLng({ lat, lng }, index);
  };
  const changeVia = ({ target: { checked } }) => {
    onChangeVia(checked, index);
  };

  const remove = () => {
    onRemove(index);
  };

  const separator = trace.lat || trace.lng ? ',' : '';
  const traceStr = `${trace.lat || ''}${separator}${trace.lng || ''}`;

  return (
    <FormRow className="rf-trace-item">
      <Input
        label={`Point ${index + 1}`}
        placeholder="lat,lng"
        value={traceStr}
        onBlur={changeLatLng}
        isValid={isPointValid(trace)}
      />
      <Checkbox label="Is Via" isChecked={!!isVia} onChange={changeVia} />
      <CloseButton onClick={remove} />
    </FormRow>
  );
}

export default TraceItem;
