class ResultPanelPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported result panel type: '${name}'.
          Please register with result panel plugin 
          import resultPanelPlugin from 'core/resultPanelPlugin'
          
          resultPanelPlugin.register('${name}', {
            Component: 'Component that represents settings block'
          })`
    );
  }
}

const resultPanelPlugin = new ResultPanelPlugin();
Object.freeze(resultPanelPlugin);

export default resultPanelPlugin;
