import './styles/map.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LayerToggle from './LayerToggle';
import RulerToggle from './RulerToggle';
import { getConfig, isInternal } from '../../state/config';
import {
  getIsLeftPanelShown,
  getIsResultPanelShown,
  getTriggerAutoZoom,
  getIsOpenInShown,
} from 'state/ui';
import { setIsOpenInShown, setIsAvoidExcludeShown } from 'state/ui/reducer';
import { getResult } from '../../state/tabs/tab/result/raw/reducer';
import { getSearchData } from '../../state/search/reducer';
import { getSelectedTab } from '../../state/selectedTab';
import { getTabs } from '../../state/tabs';
import { setMapBounds } from '../../state/map/bounds/actions';
import { hasResult } from '../resultPanel/ResultPanelContainer';
import { getIsAutoZoomOn } from 'state/appSettings';
import { setResultState } from 'state/tabs/tab/result/state/reducer';
import { getMapBounds } from '../../state/map/bounds/reducer';
import { getFields, setFields } from '../../state/tabs/tab/fields/reducer';
import { setSelectedTab } from '../../state/selectedTab/actions';
import TilesContainer from './TilesContainer';
import LeafletMap from './leaflet/LeafletMap';
import { getTilesData, setTilesData, setTilesType } from '../../state/map/tiles/reducer';
import { TILE_LIBRARY_TYPES, TILE_TYPES } from '../../state/map/tiles/constants';
import JslaMap from './jsla/JslaMap';
import { addTab } from '../../state/tabs/actions';
import { TRUCK_VECTOR_STYLES_URL } from './constants';
import TrafficOverlays from './TrafficOverlaysContainer';
import ViewInContainer from './ViewInContainer';
import AddAvoidExcludeContainer from './AddAvoidExcludeContainer';
import { set as setNotification } from 'state/notification/actions';
import { getAllSettings } from '../../state/settings';
import { fetchData } from '../../state/tabs/tab/response/actions';
import getUserLocation from 'utils/userLocation';

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTruckTileChecked: false,
    };

    this.leafletRef = React.createRef();
    this.jslaRef = React.createRef();
  }

  async componentDidMount() {
    const { config, tilesData, setTilesData } = this.props;
    const { type } = tilesData;
    if ( !isInternal(config) ) {
      let countryCode = await getUserLocation();
      setTilesData( type, { 'pview': countryCode[type] } );
      tilesData['vectorHARP'].pview = countryCode.vectorHARP;
      tilesData['vector'].pview = countryCode.vector;
      tilesData['raster'].pview = countryCode.raster;
      tilesData['rasterv3'].pview = countryCode.rasterv3;
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { tilesData } = nextProps;
    const { type } = tilesData;

    if (type === TILE_TYPES.RASTERV3) {
      return {
        isTruckTileChecked: tilesData[type].fVehicleRestrictions === 'active_and_inactive'
      };
    }
    return null;
  }

  onToggleTruckTile = () => {
    const { setTilesData, tilesData: { type } } = this.props;
    if ( type === TILE_TYPES.RASTERV3 ) {
      setTilesData( type, { 'fVehicleRestrictions': !this.state.isTruckTileChecked ? 'active_and_inactive' : '' } );
    }

    this.setState((state) => ({
      isTruckTileChecked: !state.isTruckTileChecked,
    }));
  };

  resizeMap = () => {
    const { setMapBounds, tilesData } = this.props;
    let bounds = {};
    if (tilesData.libraryType === TILE_LIBRARY_TYPES.LEAFLET) {
      const { leafletElement } = this.leafletRef.current.mapRef.current;
      leafletElement.invalidateSize();
      const mapBounds = leafletElement.getBounds();
      bounds.top = mapBounds.getNorth();
      bounds.bottom = mapBounds.getSouth();
      bounds.left = mapBounds.getWest();
      bounds.right = mapBounds.getEast();
      bounds.zoom = Math.floor(leafletElement.getZoom());
    } else {
      const { map } = this.jslaRef.current.mapRef.current;
      map.getViewPort().resize();
      const rect = map.getViewModel().getLookAtData().bounds.getBoundingBox();
      bounds.top = rect.getTop();
      bounds.right = rect.getRight();
      bounds.bottom = rect.getBottom();
      bounds.left = rect.getLeft();
      bounds.zoom = Math.floor(map.getZoom());
    }

    setMapBounds(bounds);
  };

  render() {
    const {
      isLeftPanelShown,
      isResultPanelShown,
      isOpenInShown,
      setIsOpenInShown,
      setIsAvoidExcludeShown,
      result,
      searchData,
      config,
      fields,
      tabs,
      selectedTab,
      bounds,
      setNotification,
      setMapBounds,
      isAutoZoomOn,
      tilesData,
      setFields,
      setSelectedTab,
      setResultState,
      addTab,
      trafficOverlays,
      triggerAutoZoom,
      allSettings,
      request,
      setTilesType,
      apiUrl,
    } = this.props;
    
    const { isTruckTileChecked } = this.state;
    const mapClasses = classnames('rf-map', {
      'rf-map_collapsed-left': isLeftPanelShown,
      'rf-map_collapsed-right': isResultPanelShown && hasResult(result),
      'rf-map_no-api-url-bar': apiUrl === '',
    });
    const styleUrl = isTruckTileChecked
      ? TRUCK_VECTOR_STYLES_URL
      : tilesData.vector.styleUrl;
    const showTruckTile = isTruckTileChecked;

    const mapProps = {
      showTruckTile,
      searchData,
      config,
      setNotification,
      tabs,
      selectedTab,
      setResultState,
      bounds,
      setFields,
      setSelectedTab,
      setMapBounds,
      isAutoZoomOn,
      result,
      tilesData,
      addTab,
      styleUrl,
      trafficOverlays,
      triggerAutoZoom,
      allSettings,
      request,
      setTilesType,
    };

    return (
      <div className={mapClasses} onTransitionEnd={this.resizeMap}>
        {tilesData.libraryType === TILE_LIBRARY_TYPES.JSLA ? (
          <JslaMap ref={this.jslaRef} {...mapProps} />
        ) : (
          <LeafletMap ref={this.leafletRef} {...mapProps} />
        )}
        <div className="rf-map__truck-icon">
          <LayerToggle
            active={isTruckTileChecked}
            onChange={this.onToggleTruckTile}
            tooltipText="Vehicle Restrictions"
          />
        </div>
        <div className="rf-map__dm-toggle">
          <RulerToggle
            tooltipText="Distance Measurement"
            {...mapProps}
          />
        </div>
        <TilesContainer />
        <TrafficOverlays setNotification={setNotification} setTilesType={setTilesType}/>
        {isOpenInShown && <ViewInContainer setIsOpenInShown={setIsOpenInShown} isOpenInShown={isOpenInShown}/>}
        <AddAvoidExcludeContainer setIsAvoidExcludeShown={setIsAvoidExcludeShown} fields={fields} setFields={setFields}/>
      </div>
    );
  }
}

MapContainer.propTypes = {
  config: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  isLeftPanelShown: PropTypes.bool.isRequired,
  isResultPanelShown: PropTypes.bool.isRequired,
  isAutoZoomOn: PropTypes.bool.isRequired,
  setMapBounds: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  result: PropTypes.object,
  searchData: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired,
  bounds: PropTypes.object.isRequired,
  tilesData: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  trafficOverlays: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  triggerAutoZoom: PropTypes.number,
};

const mapStateToProps = (state) => ({
  config: getConfig(state),
  fields: getFields(state),
  isLeftPanelShown: getIsLeftPanelShown(state),
  isResultPanelShown: getIsResultPanelShown(state),
  isOpenInShown: getIsOpenInShown(state),
  result: getResult(state),
  searchData: getSearchData(state),
  selectedTab: getSelectedTab(state),
  tabs: getTabs(state),
  isAutoZoomOn: getIsAutoZoomOn(state),
  bounds: getMapBounds(state),
  tilesData: getTilesData(state),
  trafficOverlays: state.map.trafficOverlays,
  triggerAutoZoom: getTriggerAutoZoom(state),
  allSettings: getAllSettings(state),
});

const mapDispatchToProps = {
  setIsOpenInShown,
  setIsAvoidExcludeShown,
  setMapBounds,
  setResultState,
  setFields,
  setSelectedTab,
  addTab,
  setTilesData,
  setTilesType,
  setNotification,
  request: fetchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
