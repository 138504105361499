const matrix = {
  title: 'Matrix V8',
  tabTitle: 'Matrix',
  settings: {
    'Production - Api Key': {
      type: 'apiKey',
      url: 'https://matrix.router.hereapi.com/v8/matrix',
      apikey: process.env.REACT_APP_APIKEY,
    },
    'Production - Api Key 2': {
      type: 'apiKey',
      url: 'https://matrix.router.hereapi.com/v8/matrix',
      apikey: process.env.REACT_APP_APIKEY2,
    },
  },
  inputs: [
    {
      type: 'matrix',
    },
    {
      type: 'multiplePoints',
      options: {
        key: 'origins',
        label: 'Origins',
        isPost: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.schemas.MatrixRequest.properties.origins.description',
            },
          ],
        },
        advancedOptions: {
          course: true,
          sideOfStreetHint: true,
          match: true,
          nameHint: true,
          radius: true,
          snapRadius: true,
          minCourseDistance: true,
          radiusPenalty: true,
          segmentIdHint: true,
          onRoadThreshold: true,
          extraParams: true,
        },
      },
    },
    {
      type: 'multiplePoints',
      options: {
        key: 'destinations',
        label: 'Destinations',
        isPost: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.schemas.MatrixRequest.properties.destinations.description',
            },
          ],
        },
        advancedOptions: {
          course: true,
          sideOfStreetHint: true,
          match: true,
          nameHint: true,
          radius: true,
          snapRadius: true,
          minCourseDistance: true,
          radiusPenalty: true,
          segmentIdHint: true,
          onRoadThreshold: true,
          extraParams: true,
        },
      },
    },
    {
      type: 'group',
      options: {
        isExpanded: true,
        inputs: [
          {
            type: 'transportMode',
            options: {
              key: 'transportMode',
              transports: [
                { label: 'Car', value: 'car' },
                { label: 'Pedestrian', value: 'pedestrian' },
                { label: 'Truck', value: 'truck' },
                { label: 'Bicycle', value: 'bicycle' },
                { label: 'Taxi', value: 'taxi' },
                { label: 'Scooter', value: 'scooter' },
                { label: 'Bus', value: 'bus' },
                { label: 'PrivateBus', value: 'privateBus' }
              ],
              isPost: true,
            },
          },
          {
            type: 'select',
            options: {
              key: 'routingMode',
              label: 'Routing Mode',
              selectOptions: [
                {
                  label: 'Fast',
                  value: 'fast',
                },
                {
                  label: 'Short',
                  value: 'short',
                },
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.RoutingMode.description',
                  },
                ],
              },
              isPost: true,
            },
          },
          {
            type: 'group',
            options: {
              label: 'Vehicle attributes',
              cssClasses: 'rf-grey-box',
              isExpanded: true,
              conditions: [
                { type: 'contains', key: 'fields.transportMode', value: 'car,truck,taxi,bus,privateBus' }
              ],
              inputs: [
                {
                  type: 'checkboxes',
                  options: {
                    key: 'vehicle[shippedHazardousGoods]',
                    label: 'Shipped Hazardous Goods',
                    isExpanded: false,
                    isPost: true,
                    loadParams: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      resultPath: 'components.schemas.VehicleBase.properties.shippedHazardousGoods.items.enum'
                    },
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.shippedHazardousGoods.description'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Gross Weight (in kg.)',
                    placeholder: 'Gross Weight (minimum 0)',
                    key: 'vehicle[grossWeight]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.grossWeight.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.grossWeight.minimum'
                        }
                      ]
                    },
                    validation: [{ type: 'min', value: 0 }]
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Current Weight (in kg.)',
                    placeholder: 'Current Weight (minimum 0)',
                    key: 'vehicle[currentWeight]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.currentWeight.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.currentWeight.minimum'
                        }
                      ]
                    },
                    validation: [{ type: 'min', value: 0 }]
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Weight Per Axle (in kg.)',
                    placeholder: 'Weight Per Axle (minimum 0)',
                    key: 'vehicle[weightPerAxle]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.weightPerAxle.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.weightPerAxle.minimum'
                        }
                      ]
                    },
                    validation: [{ type: 'min', value: 0 }]
                  }
                },
                {
                  type: 'inputObject',
                  options: {
                    label: 'Weight Per Axle Group',
                    key: 'vehicle[weightPerAxleGroup]',
                    isPost: true,
                    inputType: 'number',
                    isExpanded: false,
                    groupConditions: [
                      { type: 'contains', key: 'fields.transportMode', value: 'truck,bus,privateBus' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.WeightPerAxleGroup.description'
                        }
                      ]
                    },
                    inputs: [
                      {
                        key: 'single',
                        label: 'Single',
                        placeholder: 'Single'
                      }, {
                        key: 'tandem',
                        label: 'Tandem',
                        placeholder: 'Tandem'
                      }, {
                        key: 'triple',
                        label: 'Triple',
                        placeholder: 'Triple'
                      }
                    ]
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Height (in cm.)',
                    placeholder: 'Height (min 0, max 5000)',
                    key: 'vehicle[height]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.height.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.height.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.height.maximum'
                        }
                      ]
                    },
                    validation: [
                      { type: 'min', value: 0 },
                      { type: 'max', value: 5000 }
                    ]
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Width (in cm.)',
                    placeholder: 'Width (min 0, max 5000)',
                    key: 'vehicle[width]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.width.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.width.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.width.maximum'
                        }
                      ]
                    },
                    validation: [
                      { type: 'min', value: 0 },
                      { type: 'max', value: 5000 }
                    ]
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Length (in cm.)',
                    placeholder: 'Length (min 0, max 30000)',
                    key: 'vehicle[length]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.length.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.length.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.length.maximum'
                        }
                      ]
                    },
                    validation: [
                      { type: 'min', value: 0 },
                      { type: 'max', value: 30000 }
                    ]
                  }
                },
                {
                  type: 'select',
                  options: {
                    key: 'vehicle[tunnelCategory]',
                    label: 'Tunnel Category',
                    isPost: true,
                    selectOptions: [
                      { value: 'B', label: 'B' },
                      { value: 'C', label: 'C' },
                      { value: 'D', label: 'D' },
                      { value: 'E', label: 'E' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.tunnelCategory.description'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Axle Count',
                    placeholder: 'Axle Count (min 2)',
                    key: 'vehicle[axleCount]',
                    isPost: true,
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.axleCount.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.axleCount.minimum'
                        }
                      ]
                    },
                    validation: [{ type: 'min', value: 2 }]
                  }
                },
                {
                  type: 'select',
                  options: {
                    key: 'vehicle[category]',
                    isPost: true,
                    label: 'Category',
                    groupConditions: [
                      { type: 'equals', key: 'fields.transportMode', value: 'truck' }
                    ],
                    loadParams: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      resultPath: 'components.schemas.VehicleBase.properties.category.enum'
                    },
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.category.description'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'select',
                  options: {
                    key: 'vehicle[type]',
                    label: 'Type',
                    isPost: true,
                    groupConditions: [
                      { type: 'contains', key: 'fields.transportMode', value: 'truck,bus,privateBus' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleType.description'
                        }
                      ]
                    },
                    loadParams: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      resultPath: 'components.schemas.VehicleType.enum'
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    label: 'Trailer Count',
                    key: 'vehicle[trailerCount]',
                    isPost: true,
                    inputType: 'number',
                    validation: [{ type: 'min', value: 0 }],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.trailerCount.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.trailerCount.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.trailerCount.maximum'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'licensePlate',
                  options: {
                    key: 'vehicle[licensePlate]',
                    label: 'License Plate',
                    placeholder: 'Last Character',
                    isPost: true,
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.LicensePlate.description'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[kpraLength]',
                    label: 'Kingpin to rear axle length',
                    isPost: true,
                    inputType: 'number',
                    groupConditions: [
                      { type: 'contains', key: 'fields.transportMode', value: 'truck,car,taxi' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.kpraLength.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.kpraLength.minimum'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[payloadCapacity]',
                    label: 'Payload Capacity',
                    isPost: true,
                    inputType: 'number',
                    groupConditions: [
                      { type: 'contains', key: 'fields.transportMode', value: 'truck,car,taxi' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.payloadCapacity.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.payloadCapacity.minimum'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[speedCap]',
                    label: 'Speed cap (m/s)',
                    placeholder: 'Speed cap (min 1, max 70)',
                    inputType: 'number',
                    isPost: true,
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.speedCap.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.speedCap.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.speedCap.maximum'
                        }
                      ]
                    },
                    validation: [
                      {
                        type: 'min',
                        value: 1
                      },
                      {
                        type: 'max',
                        value: 70
                      }
                    ]
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[occupancy]',
                    label: 'Vehicle Occupancy',
                    inputType: 'number',
                    isPost: true,
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.occupancy.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.occupancy.minimum'
                        }
                      ]
                    }
                  }
                }
              ],
            },
          },
          {
            type: 'group',
            options: {
              label: 'Scooter attributes',
              cssClasses: 'rf-grey-box',
              isExpanded: true,
              conditions: [
                {
                  type: 'equals',
                  key: 'fields.transportMode',
                  value: 'scooter',
                },
              ],
              inputs: [
                {
                  type: 'select',
                  options: {
                    key: 'scooter[allowHighway]',
                    label: 'Allow Highway',
                    isPost: true,
                    selectOptions: [
                      { label: 'True', value: 'true' },
                      { label: 'False', value: 'false' }
                    ],
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Scooter.properties.allowHighway.description'
                        }
                      ]
                    },
                    type: 'boolean'
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[speedCap]',
                    label: 'Speed cap (m/s)',
                    placeholder: 'Speed cap (min 1, max 70)',
                    inputType: 'number',
                    isPost: true,
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.VehicleBase.properties.speedCap.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.VehicleBase.properties.speedCap.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.VehicleBase.properties.speedCap.maximum'
                        }
                      ]
                    },
                    validation: [
                      {
                        type: 'min',
                        value: 1
                      },
                      {
                        type: 'max',
                        value: 70
                      }
                    ]
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[occupancy]',
                    label: 'Vehicle Occupancy',
                    inputType: 'number',
                    isPost: true,
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.occupancy.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.occupancy.minimum'
                        }
                      ]
                    }
                  }
                }
              ],
            },
          },
        ],
      },
    },
    {
      type: 'group',
      options: {
        label: 'Allow',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'select',
            options: {
              key: 'allow[hov]',
              label: 'Allow HOV lanes',
              isPost: true,
              type: 'boolean',
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Allow.properties.hov.description'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'allow[hot]',
              label: 'allow HOT lanes',
              isPost: true,
              type: 'boolean',
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Allow.properties.hot.description'
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      type: 'select',
      options: {
        key: 'async',
        label: 'Async',
        initValue: 'false',
        selectOptions: [
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
        ],
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'paths./matrix.post.parameters[0].schema.description',
            },
          ],
        },
      },
    },
    {
      type: 'regionDefinion',
      options: {
        key: 'regionDefinition',
        label: 'Region Definition',
        regionTypes: [
          { label: 'World', value: 'world' },
          { label: 'Auto Circle', value: 'autoCircle' },
          { label: 'Circle', value: 'circle' },
          { label: 'Bounding Box', value: 'boundingBox' },
          { label: 'Polygon', value: 'polygon' },
        ],
        initValue: { type: 'world' },
      },
    },
    {
      type: 'profileSelect',
      options: {
        key: 'profile',
        label: 'Profile',
        selectOptions: [
          { label: 'Car fast', value: 'carFast' },
          { label: 'Car short', value: 'carShort' },
          { label: 'Truck fast', value: 'truckFast' },
          { label: 'Pedestrian', value: 'pedestrian' },
          { label: 'Bicycle', value: 'bicycle' },
        ],
        initValue: 'carFast',
        isPost: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.schemas.MatrixRequest.properties.profile.description',
            },
          ],
        },
      },
    },
    {
      type: 'routeTime',
      options: {
        label: 'Departure Time',
        cssClasses: 'rf-grey-box',
        isPost: true,
        isLocalSupported: false,
        supportAny: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.schemas.DepartureTimeWithAny.description',
            },
          ],
        },
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'matrixAttributes',
        label: 'Matrix Attributes',
        isPost: true,
        isExpanded: true,
        params: [
          { label: 'Travel Times', value: 'travelTimes' },
          { label: 'Distances', value: 'distances' },
        ],
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.schemas.MatrixRequest.properties.matrixAttributes.description',
            },
          ],
        },
      },
    },
    {
      type: 'group',
      options: {
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        label: 'Avoid',
        inputs: [
          {
            type: 'checkboxes',
            options: {
              key: 'avoid[features]',
              label: 'Avoid features',
              isExpanded: true,
              isPost: true,
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.AvoidFeatures.items.enum',
              },
            },
          },
          {
            type: 'avoidExclude',
            options: {
              isPost: true,
              skipCorridor: true
            },
          },
          {
            type: 'avoidSegments',
            options: {
              onlyPost: true,
            },
          },
          {
            type: 'multiItems',
            options: {
              isPost: true,
              label: 'Avoid zone identifiers',
              placeholder: 'Avoid zone identifiers',
              key: 'avoid[zoneIdentifiers]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Avoid.properties.zoneIdentifiers.description',
                  },
                ],
              },
            },
          },
          {
            type: 'multiItems',
            options: {
              isPost: true,
              label: 'Avoid truck road types',
              placeholder: 'Avoid truck road types',
              key: 'avoid[truckRoadTypes]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Avoid.properties.truckRoadTypes.description',
                  },
                ],
              },
            },
          },
          {
            type: 'checkboxes',
            options: {
              key: 'avoid[zoneCategories][categories]',
              label: 'Avoid zone categories',
              isExpanded: true,
              isPost: true,
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath:
                  'components.schemas.AvoidZoneCategories.properties.categories.items.x-extensible-enum',
              },
            },
          },
          {
            type: 'multiItems',
            options: {
              isPost: true,
              label: 'Avoid zone categories except zone ids',
              placeholder: 'Zone IDs',
              key: 'avoid[zoneCategories][exceptZoneIds]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.AvoidZoneCategories.properties.exceptZoneIds.description',
                  },
                ],
              },
            },
          }
        ],
      },
    },
    {
      type: 'group',
      options: {
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        label: 'Exclude',
        inputs: [
          {
            type: 'avoidExclude',
            options: {
              isPost: true,
              skipCorridor: true,
              mode: 'exclude'
            }
          },
          {
            type: 'multiItems',
            options: {
              isPost: true,
              label: 'Exclude countries',
              placeholder: 'Country code',
              key: 'exclude[countries]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Exclude.properties.countries.description'
                  }
                ]
              }
            }
          },
          {
            type: 'excludeStates',
            options: {
              isPost: true,
              label: 'Exclude states',
              placeholder: 'Country code',
              key: 'exclude[states]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Exclude.properties.states.description'
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      type: 'group',
      options: {
        label: 'Traffic',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'input',
            options: {
              isPost: true,
              key: 'traffic[overrideFlowDuration]',
              label: 'Traffic Override Flow Duration',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Traffic.properties.overrideFlowDuration.description'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              isPost: true,
              key: 'traffic[mode]',
              label: 'Traffic Mode',
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.Traffic.properties.mode.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Traffic.properties.mode.description'
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      type: 'segmentSpeedCap',
      options: {
        label: 'Segment Speed Cap',
        isExpanded: true,
        key: 'maxSpeedOnSegment',
        onlyPost: true
      }
    },
    {
      type: 'extraParams',
    },
  ],
  map: [
    {
      type: 'regionDefinition',
    },
    {
      type: 'matrixRoutes',
    },
    {
      type: 'circle',
      options: {
        radiusPath: 'fields.regionDefinition.radius',
        centerPath: 'fields.regionDefinition.center',
        onChangeFormatter: 'olsMatrixRegionDefinitionCircle',
        draggable: true,
        resizable: true,
      },
    },
    {
      type: 'rectangle',
      options: {
        path: 'fields.regionDefinition.boundingBox',
        onChangeFormatter: 'olsMatrixRegionDefinitionBbox',
        draggable: true,
        resizable: true,
      },
    },
    {
      type: 'rectangleList',
      options: {
        path: 'fields.avoid.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        color: '#ff0000',
        fillColor: 'rgba(255, 0, 0, 0.4)',
        exceptionColor: '#ffff00',
        noException: true,
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'polygonList',
      options: {
        path: 'fields.avoid.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffff00',
        noException: true,
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'rectangleList',
      options: {
        path: 'fields.exclude.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        color: '#ffffff',
        fillColor: 'rgba(255, 0, 0, 0.5)',
        exceptionColor: '#ffffff',
        exceptionFillColor: 'rgba(255, 255, 0, 0.5)',
        fillOpacity: 1,
        mode: 'exclude',
        noException: true,
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'polygonList',
      options: {
        path: 'fields.exclude.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffffff',
        exceptionFillColor: 'rgba(255, 255, 0, 0.5)',
        color: '#ffffff',
        fillColor: 'rgba(0, 0, 155, 0.5)',
        fillOpacity: 1,
        mode: 'exclude',
        noException: true,
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'polygon',
      options: {
        path: 'fields.regionDefinition.outer',
        draggable: true,
        resizable: true,
        adjustMidPoint: true
      },
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'olsMatrixAddPoint',
            options: {
              text: 'Add origin',
              key: 'origins',
            },
          },
          {
            type: 'olsMatrixAddPoint',
            options: {
              text: 'Add destination',
              key: 'destinations',
            },
          },
          {
            type: 'avoidExcludeAreas',
            options: {
              text: 'Add Avoid/Exclude Areas',
              skipCorridor: true
            }
          },
          {
            type: 'avoidSegmentContextMenu',
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon',
            },
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address',
            },
          },
        ],
      },
    },
    {
      type: 'matrixMarkers',
      options: {
        origins_path: 'fields.origins',
        destinations_path: 'fields.destinations',
        removable: true,
      },
    },
  ],
  resultPanel: [
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Summary',
            type: 'matrixList',
          },
          {
            title: 'Response',
            type: 'rawResponse',
          },
        ],
      },
    },
    {
      type: 'tabOpener',
      options: {
        tabTitle: 'Matrix Result V8',
        mapper: [
          {
            path: 'result.raw.data.statusUrl',
            fieldsPath: 'matrixResultStatusUrl',
            openTabOnChange: true,
          },
          {
            path: 'fields.origins',
            fieldsPath: 'origins',
          },
          {
            path: 'fields.destinations',
            fieldsPath: 'destinations',
          },
        ],
      },
    },
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'required',
        options: {
          path: 'fields.origins',
        },
      },
    ],
  },
};

export default matrix;
