const ALGORITHM_MAP = {
  A: 'MAASTAR',
  F: 'Flash',
  M: 'MLD',
  C: 'Contraction Heirarchies',
  L: 'Labeled ECAR',
  G: 'Greedy ECAR',
};

const getAlgorithm = (section, alternative, algorithmRes) => {
  const sections = algorithmRes.split(';');
  const alternatives = (sections[section] || sections[0]).split('|');
  const res = alternatives[alternative] || alternatives[0];
  if (res.length > 1) {
    return res
      .split('')
      .map((val) => ALGORITHM_MAP[val] || val)
      .join(' fallbacks to ');
  }
  return ALGORITHM_MAP[res] || res;
};

export default getAlgorithm;
