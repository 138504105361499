import React from 'react';
import PropTypes from 'prop-types';
import './hereMapContextMenuItem.scss';

class HereMapContextMenuItem extends React.Component {
  render() {
    const { label, onClick } = this.props;

    return (
      <div className="rf-context-menu-item" onClick={onClick}>
        {label}
      </div>
    );
  }
}

HereMapContextMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HereMapContextMenuItem;
