import { UI_SIZE, ROUTE_TIMEZONE } from './constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isAutoRefreshOn: false,
  isAutoZoomOn: true,
  uiSize: UI_SIZE.MEDIUM,
  routeTimezone: ROUTE_TIMEZONE.CLIENT_LOCAL,
  defaultModule: null,
  searchApiKey: 'DEFAULT_API_KEY',
  isApiUrlMultiline: true,
  searchLang: 'en-us',
  isBboxSearch: false,
};
