import React from 'react';
import PropTypes from 'prop-types';
import c3 from 'c3';

export default class Chart extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    setChartObj: PropTypes.func,
  };

  static defaultProps = {
    setChartObj: () => {},
  };

  componentDidMount() {
    this.generateChart();
  }

  componentDidUpdate() {
    this.generateChart();
  }

  componentWillUnmount() {
    this.chart = this.chart.destroy();
  }

  generateChart() {
    this.chart = c3.generate({
      bindto: this.node,
      ...this.props.config,
    });
    this.props.setChartObj(this.chart);
  }

  render() {
    const { config, setChartObj, ...otherProps } = this.props;
    return (
      <div
        ref={(node) => {
          this.node = node;
        }}
        {...otherProps}
      />
    );
  }
}
