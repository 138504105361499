import React from 'react';
import PropTypes from 'prop-types';
import Chart from './react-c3';

import CustomComponent from 'shared/component';

import 'c3/c3.css';
import './styles/chart.scss';
import { isEqual } from 'lodash';

class CustomChart extends CustomComponent {
  point = null;
  chart = null;

  shouldComponentUpdate(nextProps) {
    if (
      (this.props.options.axis.hasOwnProperty('y') && 
      nextProps.options.axis.hasOwnProperty('y') && 
      this.props.options.axis.y.min !== nextProps.options.axis.y.min) || 
      (this.props.options.axis.hasOwnProperty('y2') && 
      nextProps.options.axis.hasOwnProperty('y2') && 
      this.props.options.axis.y2.min !== nextProps.options.axis.y2.min)
    ) {
      return true;
    } else if (!isEqual(this.props.data.columns, nextProps.data.columns)) {
      return true;
    } else if (
      !isEqual(this.props.options.size, nextProps.options.size) &&
      this.chart
    ) {
      this.chart.resize(nextProps.options.size);
    }
    return false;
  }

  getDefaultOptions() {
    return {
      zoom: {
        enabled: true,
        rescale: true,
      },
      grid: {
        x: { show: true },
        y: { show: true },
      },
      legend: { show: false },
      tooltip: { show: false },
    };
  }

  onMouseOver = (obj) => {
    this.point = obj;
    if (obj.target && obj.target.nodeName !== 'rect') {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
    const { onMouseOver } = this.props;
    if (onMouseOver) {
      onMouseOver(obj);
    }
  };

  onMouseOut = (e) => {
    this.point = null;
    document.body.style.cursor = 'default';
    const { onMouseOut } = this.props;
    if (onMouseOut) {
      onMouseOut();
    }
  };

  // We need this on click handling because the data.onclick from c3.js does not work.
  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e, this);
    }
  };

  setChartObj = (chart) => {
    this.chart = chart;
    this.props.setChartObj(chart);
  };

  render() {
    let { data, options } = this.props,
      opts = Object.assign(this.getDefaultOptions(), options),
      config = { ...opts, data };
    data.onmouseover = this.onMouseOver;
    data.onmouseout = this.onMouseOut;

    return (
      <Chart
        className="rf-chart"
        config={config}
        onClick={this.onClick}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        setChartObj={this.setChartObj}
      />
    );
  }
}

CustomChart.defaultProps = {
  options: {},
  setChartObj: () => {},
};

CustomChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  setChartObj: PropTypes.func,
};

export default CustomChart;
