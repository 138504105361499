const requestOptionsPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: (fields, options) => {
    return { ...options };
  },
  Component: () => null,
};

export default requestOptionsPlugin;
