import React from 'react';
import PropTypes from 'prop-types';
import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import InspectSegment from './InspectSegment';
import { Polyline, Circle } from 'react-leaflet';
import HereRoute from '../routes/HereRoute';
import HereCircle from '../../../views/shared/hereMap/HereCircle';
import { addOpacityToHexColor } from '../../utils';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: InspectSegment(Polyline, Circle),
  [TILE_LIBRARY_TYPES.JSLA]: InspectSegment(HereRoute, HereInspectCircle),
};

function HereInspectCircle({ center, color, radius }) {
  const style = {
    strokeColor: color,
    fillColor: addOpacityToHexColor(color, 0.5),
  };
  return <HereCircle center={center} radius={radius} options={{ style }} />;
}

HereInspectCircle.propTypes = {
  center: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired,
};
