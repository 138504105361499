import settingsPlugin from '../../../core/settingsPlugin';
import formViewsPlugin from '../../../core/formViewsPlugin';
import { isArray, every, isEqual } from 'lodash';
import { logError } from '../../splunkLog';
import refClient from '../../../core/refClient';

export const getUrlParamsMap = (paramsStr) =>
  paramsStr.split('&').reduce((acc, value) => {
    const [key] = value.split(/=(.*)/);
    const keyLowerCase = key.toLowerCase();
    return {
      ...acc,
      [keyLowerCase]: key,
    };
  }, {});

export const parseParams = (paramsStr) =>
  paramsStr.split('&').reduce((acc, value) => {
    const [key, val] = value.split(/=(.*)/);
    const keyLowerCase = key.toLowerCase();
    if (acc[keyLowerCase] === undefined) {
      return {
        ...acc,
        [keyLowerCase]: val,
      };
    }

    return {
      ...acc,
      [keyLowerCase]: [
        ...(isArray(acc[keyLowerCase])
          ? acc[keyLowerCase]
          : [acc[keyLowerCase]]),
        val,
      ],
    };
  }, {});

export function getTitleAndPreset(url, allSettings, tabPreset) {
  const [hostAndPath, paramsStr = ''] = decodeURIComponent(url).split('?');
  const parsedParams = paramsStr ? parseParams(paramsStr) : {};
  const urlParamsMap = paramsStr ? getUrlParamsMap(paramsStr) : {};
  const probableTypeWithParams = [];
  const probableTypeNoParams = [];

  settingsPlugin.components.forEach((plugin, type) => {
    const { parseUrl } = plugin;
    const paramsClone = { ...parsedParams };
    const params = parseUrl(paramsClone);
    if (!isEqual(parsedParams, paramsClone)) {
      probableTypeWithParams.push({ type, ...params });
    } else {
      probableTypeNoParams.push({ type });
    }
  });

  let preset = '';
  const probableTitle = new Set();
  const title = Object.keys(allSettings).find((title) => {
    const res = [];
    const presets = Object.keys(allSettings[title]).filter((settingKey) => {
      const settings = allSettings[title][settingKey];
      const [displayUrl, settingsParams] = settingsPlugin
        .get(settings.type)
        .getDisplayUrl(settings, {})
        .split('?');

      if (
        displayUrl === hostAndPath ||
        isEqualUrlsWithPathParam(settings.url, hostAndPath)
      ) {
        res.push(settingsParams ? parseParams(settingsParams) : null);
        return true;
      }

      return false;
    });

    if (presets.length > 0) {
      probableTitle.add(title);
    }
    
    for (let i = 0; i < res.length; i++) {
      if (
        res[i] &&
        every(
          Object.keys(res[i]),
          (param) => parsedParams[param] === res[i][param]
        )
      ) {
        preset = presets[i];
        break;
      }
    }

    if (!preset && probableTypeWithParams.length === 0) {
      for (let i = 0; i < res.length; i++) {
        if (!res[i] && presets[i] === tabPreset) {
          preset = presets[i];
          break;
        }
      }
      if (!preset) {
        for (let i = 0; i < res.length; i++) {
          if (!res[i]) {
            preset = presets[i];
            break;
          }
        }
      }
    }

    return !!preset;
  });

  if (!title) {
    const res = { parsedParams, url: hostAndPath, urlParamsMap };
    if (probableTitle.size === 1) {
      res.probableTitle = [...probableTitle.values()][0];
    }
    if (probableTypeWithParams.length === 1) {
      return { settings: probableTypeWithParams[0], ...res };
    } else if (probableTypeNoParams.length === 1) {
      return { settings: probableTypeNoParams[0], ...res };
    } else {
      return res;
    }
  }

  return { title, preset, parsedParams, url: hostAndPath, urlParamsMap };
}

export function getFields(
  settings,
  inputs,
  parsedParams,
  urlParamsMap,
  hostAndPath,
  postData,
  rcParams
) {
  settingsPlugin.get(settings.type).parseUrl(parsedParams);
  let hasExtraParams = false;
  const paramsSet = new Set(Object.keys(parsedParams));
  let removedList = [];
  const fields = inputs.reduce((acc, val) => {
    if (val.type === 'extraParams') {
      hasExtraParams = true;
      return acc;
    }
    let parser;
    try {
      parser = formViewsPlugin.get(val.type).parseUrl;
    } catch (e) {
      return acc;
    }
    let fields = {};
    if (parser) {
      try {
        const parsedParamsClone = { ...parsedParams };
        fields = parser({
          parsedParams: parsedParamsClone,
          options: val.options,
          hostAndPath,
          settings,
          postData: postData || {},
          rcParams: rcParams || {},
          refClient,
        });
        removedList = [
          ...removedList,
          ...setDifference(paramsSet, new Set(Object.keys(parsedParamsClone))),
        ];
      } catch (e) {
        logError({...e, val});
        return acc;
      }
    } else {
      fields = { [val.type]: parsedParams[val.type] || '' };
      removedList.push(val.type);
    }

    return {
      ...acc,
      ...fields,
    };
  }, {});

  if (hasExtraParams) {
    const extraParams = [
      ...setDifference(paramsSet, new Set(removedList)),
    ].reduce((acc, val) => {
      acc[val] = parsedParams[val];
      return acc;
    }, {});
    fields.extraParams = formViewsPlugin
      .get('extraParams')
      .parseUrl({ parsedParams: extraParams || {}, urlParamsMap }).extraParams;
  }

  return fields;
}

function isEqualUrlsWithPathParam(settingsUrl, hostAndPath) {
  if (!settingsUrl) {
    return false;
  }
  const hostAndPathSplitted = hostAndPath.split('/');
  const settingsUrlSplitted = settingsUrl.split('/');

  return (
    hostAndPathSplitted.length === settingsUrlSplitted.length &&
    every(settingsUrlSplitted, (value, index) => {
      if (/\{[a-zA-Z0-9]*\}/.test(value)) {
        return true;
      } else {
        return value === hostAndPathSplitted[index];
      }
    })
  );
}

function setDifference(set1, set2) {
  return new Set([...set1].filter((x) => !set2.has(x)));
}
