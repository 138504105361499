import React from 'react';
import BoundingBox from 'ref-client-core/core/formViewsPlugins/regionDefinition/BoundingBox';
import PropTypes from 'prop-types';
import './chargingStations.scss';
import { Checkbox, TabsControl } from '@here/ref-client-ui';
import { CHARGING_STATION_TABS } from './constants';

const tabsData = Object.keys(CHARGING_STATION_TABS).map((key) => ({
  label: CHARGING_STATION_TABS[key],
  name: CHARGING_STATION_TABS[key],
}));

function ChargingStationsInputs({ fields, setFields }) {
  const { bbox, showBbox, points, inputType } = fields;

  const onChange = (bounds) => {
    setFields({ bbox: bounds });
  };

  const toggleBbox = ({ target }) => {
    if (showBbox !== target) {
      setFields({ showBbox: target.checked });
    }
  };

  const getTabEl = () => {
    if (inputType === CHARGING_STATION_TABS.BBOX) {
      return (
        <>
          <BoundingBox bounds={bbox} onChange={onChange} />
          <Checkbox
            label="Show / Hide Bounding Box"
            isChecked={showBbox}
            onChange={toggleBbox}
          />
        </>
      );
    } else if (points.length === 2) {
      return (
        <div className="rf-charging-stations__points">
          Build a route in Routing V8 -&gt; Right click on it and select
          "Charging stations along the route"
        </div>
      );
    }
    return null;
  };

  return (
    <div className="rf-charging-stations">
      <div className="rf-charging-stations__link">
        <strong>Data source</strong>{' '}
        <a
          href="https://platform.here.com/data/hrn:here:data::olp-here:charging-stations-for-routing-source-evcp/charging-stations/overview"
          target="__blank"
        >
          Charging Stations For Routing - Source EVCP -&gt; Charging Stations
        </a>
      </div>
      <TabsControl
        currentTabName={inputType}
        onChange={(e) => setFields({ inputType: e })}
        tabsData={tabsData}
        className="rf-charging-stations__tabs"
      />
      {getTabEl()}
    </div>
  );
}

ChargingStationsInputs.propTypes = {
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

const chargingStationsInputPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({
    bbox: undefined,
    showBbox: true,
    inputType: CHARGING_STATION_TABS.BBOX,
    points: '[]',
  }),
  getRequestOptions: () => ({}),
  Component: ChargingStationsInputs,
};

export default chargingStationsInputPlugin;
