import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as TabsActions from 'state/tabs/actions';

import './styles/newTab.scss';
import ConfigsModal from '../../tabsPanel/ConfigsModal';
import Module from './Module';
import {
  setConfig,
  duplicateConfig,
  addConfig,
  getCustomConfigs,
  deleteConfig,
} from '../../../state/config/custom/reducer';
import { getDefaultConfigs } from '../../../state/config/default/reducer';
import { setIsNewTabShown } from 'state/ui/reducer';
import { setSettings } from 'state/appSettings/actions';
import { set as setNotification } from 'state/notification/actions';

class NewTab extends Component {
  state = {
    isEditConfigsOpen: false,
    modalSaveAction: null,
    config: null,
  };

  closeEditConfigs = () => {
    this.setState({ isEditConfigsOpen: false });
  };

  onAddNewModule = () => {
    const { addConfig } = this.props;

    this.setState({
      isEditConfigsOpen: true,
      modalSaveAction: addConfig,
      config: {},
    });
  };

  onDuplicateConfig = (title) => {
    const { duplicateConfig, customConfigs, defaultConfigs } = this.props;

    duplicateConfig(customConfigs[title] || defaultConfigs[title]);
  };

  onEditModule = (title) => {
    const { setConfig, customConfigs } = this.props;
    const config = customConfigs[title];

    this.setState({
      isEditConfigsOpen: true,
      modalSaveAction: setConfig.bind(this, config.title),
      config,
    });
  };

  setDefaultModule = (title) => {
    const { setSettings, setNotification } = this.props;
    setSettings({ defaultModule: title });
    setNotification({
      message: `"${title}" has been set as default module`,
      impact: 'positive',
      autoDismiss: 2,
    });
  };

  getModuleEl = (config, isCustom) => {
    const { deleteConfig } = this.props;
    const { title } = config;
    const props = {
      addTab: this.addTab.bind(this, { title }),
      setDefaultModule: this.setDefaultModule.bind(this, title),
      label: title,
      duplicateConfig: this.onDuplicateConfig,
    };

    if (isCustom) {
      props.editConfig = this.onEditModule;
      props.deleteConfig = deleteConfig;
    }

    return <Module key={title} {...props} />;
  };

  addTab(data) {
    const { setIsNewTabShown, addTab } = this.props;
    addTab(data);
    setIsNewTabShown(false);
  }

  render() {
    const { customConfigs, defaultConfigs } = this.props;
    const { isEditConfigsOpen, modalSaveAction, config } = this.state;

    const defaultModules = Object.keys(defaultConfigs).map((title) =>
      this.getModuleEl(defaultConfigs[title])
    );
    const customConfigsKeys = Object.keys(customConfigs);
    const customModules = customConfigsKeys.map((title) =>
      this.getModuleEl(customConfigs[title], true)
    );

    return (
      <div className="rf-new-tab">
        <p className="rf-new-tab__description">Default Modules</p>
        <div>{defaultModules}</div>
        {customConfigsKeys.length > 0 && (
          <p className="rf-new-tab__description">Custom Modules</p>
        )}
        <div>{customModules}</div>
        <div>
          <lui-button secondary onClick={this.onAddNewModule}>
            Add New Module
          </lui-button>
        </div>
        {isEditConfigsOpen && (
          <ConfigsModal
            isOpen={isEditConfigsOpen}
            close={this.closeEditConfigs}
            onSave={modalSaveAction}
            config={config}
          />
        )}
      </div>
    );
  }
}

NewTab.propTypes = {
  addTab: PropTypes.func.isRequired,
  setIsNewTabShown: PropTypes.func.isRequired,
  duplicateConfig: PropTypes.func.isRequired,
  setConfig: PropTypes.func.isRequired,
  addConfig: PropTypes.func.isRequired,
  deleteConfig: PropTypes.func.isRequired,
  setSettings: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  customConfigs: PropTypes.object.isRequired,
  defaultConfigs: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    customConfigs: getCustomConfigs(state),
    defaultConfigs: getDefaultConfigs(state),
  };
}

const mapDispatchToProps = {
  addTab: TabsActions.addTab,
  setIsNewTabShown,
  duplicateConfig,
  setConfig,
  addConfig,
  deleteConfig,
  setSettings,
  setNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTab);
