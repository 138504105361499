import { getAdvancedWaypointParams } from '../formViewsPlugins/advancedCorridorPoints/AdvancedViaFieldsComponent';

export const addVia = (fields, e) => {
  const { lat: latFull, lng: lngFull } = e.latLng;
  const lat = +latFull.toFixed(6);
  const lng = +lngFull.toFixed(6);

  const value = [...fields.via];
  value.push({
    coords: `${lat},${lng}`,
    advanced: getAdvancedWaypointParams(),
  });

  return {
    via: value,
  };
};
