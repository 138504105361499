import { getConfig } from 'state/config';

export function getAppSettings(state) {
  return state.appSettings;
}

export function getIsAutoRefreshOn(state) {
  return getAppSettings(state).isAutoRefreshOn;
}

export const getIsAutoZoomOn = (state) => getAppSettings(state).isAutoZoomOn;

export const getDefaultModule = (state) => {
  const { defaultModule } = getAppSettings(state);
  const configs = getConfig(state);
  const moduleTitles = Object.keys(configs);
  if (!defaultModule || moduleTitles.indexOf(defaultModule) === -1) {
    return moduleTitles[0];
  }

  return defaultModule;
};
