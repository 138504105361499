import settingsPlugin from '../../core/settingsPlugin';
import { isObject, set } from 'lodash';

export function stringifyUrl(fieldRequestOptions = {}, settings = null) {
  if (!settings) {
    return { apiUrl: '' };
  }
  return {
    apiUrl: settingsPlugin
      .get(settings.type)
      .getDisplayUrl(settings, fieldRequestOptions),
    postData: fieldRequestOptions.data,
    rcParams: fieldRequestOptions.rcParams,
  };
}

export function objectToQueryString(obj) {
  if (!isObject(obj)) {
    return obj;
  }
  let result = [];
  Object.keys(obj).forEach((key) => {
    result.push(`${key}=${obj[key]}`);
  });
  return result.join('&');
}

export function urlToObject(apiUrl) {
  let url = apiUrl.split('?')[1] || '';
  let urlParts = url.split('&');
  let result = {};
  urlParts.forEach((part) => {
    let [key, val = ''] = part.split('=');
    result[key] = val;
  });
  return result;
}

const urlParamsMapping = {
  corridor: 'corridor.points',
  corridorwidth: 'corridor.width',
  maxresults: 'maxResults',
  offset: 'offset',
  connectortype: 'connectorType',
  powerfeedtype: 'powerFeedType',
  sortkey: 'sortKey',
  sortorder: 'sortOrder',
  shape: 'shape',
};

export function transformToPost(apiUrl) {
  const urlObj = urlToObject(apiUrl);
  const { params, postData } = Object.keys(urlObj).reduce(
    (red, key) => {
      if (urlParamsMapping[key]) {
        set(red.postData, urlParamsMapping[key], urlObj[key]);
      } else {
        const urlParam = `${key}=${urlObj[key]}`;
        red.params = red.params ? `${red.params}&${urlParam}` : urlParam;
      }
      return red;
    },
    { params: null, postData: {} }
  );
  return { url: `${apiUrl.split('?')[0]}?${params}`, postData };
}

export function genUrl4Dnd(data) {
  let { coords, apiUrl, regExp } = data;

  let matched = apiUrl.match(regExp);
  let [oldCategory, oldType, ...extraParams] = matched
    ? (matched[1] || '').split('!')
    : [];
  let oldCoords = extraParams.slice(-1)[0] || null;
  if (oldCoords && oldCategory && oldType && coords) {
    let pointCoords = matched[0].replace(
      matched[1],
      `geo!${oldType}!${coords.lat},${coords.lng}`
    );
    return apiUrl.replace(matched[0], pointCoords);
  }
  return null;
}

export function getHostName(url) {
  let match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    return match[2];
  }
  return null;
}

export function parseQuickUrl(urlHash, setNotification) {
  const url = urlHash.replace('#url=', '');
  const regex = /&postData=(.*)$/;
  const regexRes = url.match(regex);
  if (regexRes && regexRes[1]) {
    let postData = null;
    try {
      postData = JSON.parse(regexRes[1]);
    } catch (e) {
      setNotification({
        title: 'Restore failure',
        message: `Failed to restore post data from url.`,
        impact: 'negative',
        autoDismiss: 0,
      });
    }
    return {
      apiUrl: url.replace(regex, ''),
      postData,
    };
  }
  const regexRc = /&rcParams=(.*)$/;
  const regexResRc = url.match(regexRc);
  if (regexResRc && regexResRc[1]) {
    let rcParams = null;
    try {
      rcParams = JSON.parse(regexResRc[1]);
    } catch (e) {
      setNotification({
        title: 'Restore failure',
        message: `Failed to restore post data from url.`,
        impact: 'negative',
        autoDismiss: 0,
      });
    }
    return {
      apiUrl: url.replace(regexRc, ''),
      rcParams,
    };
  }
  return { apiUrl: url };
}
