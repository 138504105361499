import PropTypes from 'prop-types';
import React, { Component } from 'react';
import utils from 'utils';
import { isEqual } from 'lodash';

import CustomChart from 'shared/chart';
import {
  FormRow,
  Textarea,
  Tooltip,
  Input,
  TabsControl,
  CloseButton,
} from '@here/ref-client-ui';

import './speedGroup.scss';

class SpeedGroup extends Component {
  constructor(props) {
    super(props);
    this.speedGroupRef = React.createRef();
    this.state = {
      currentTabKey: 'fields',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.speedArray, nextProps.speedArray) ||
      !isEqual(this.state, nextState)
    );
  }

  onChange(key, e) {
    let _speedArray = this.props.speedArray.slice();
    _speedArray[key] = e.target.value;
    this.props.onChange(_speedArray.join(','));
  }

  onAdd = () => {
    this.props.onChange(this.props.speedArray.concat(0, 0).join(','));
  };

  onRemove(index) {
    let _speedArray = this.props.speedArray.slice();
    _speedArray.splice(index, 2);
    this.props.onChange(_speedArray.join(','));
  }

  onTabChange = (currentTabKey) => {
    this.setState({ currentTabKey });
  };

  getSpeedRow(speed, value, key) {
    return (
      <div key={key} className="rf-speed-group__row">
        <Input
          type="number"
          min="0"
          value={speed}
          onBlur={this.onChange.bind(this, key)}
          blurOnEnter
        />

        <Input
          type="number"
          min="0"
          step="any"
          value={value}
          onBlur={this.onChange.bind(this, key + 1)}
          blurOnEnter
        />

        <CloseButton onClick={this.onRemove.bind(this, key)} />
      </div>
    );
  }

  getGraph() {
    let { speedArray } = this.props;
    let values = this.prepareChartData(speedArray.length > 1 ? speedArray : []);
    const ref = this.speedGroupRef.current;
    const width = ref ? ref.offsetWidth : 322;

    let chartData = {
      x: 'x',
      columns: [values.speed, values.range],
      colors: {
        y: '#48dad0',
      },
      types: {
        point: 'area',
      },
    };

    let options = {
      size: {
        height: 240,
        width,
      },
      zoom: {
        enabled: false,
        rescale: false,
      },
      padding: {
        right: 10,
      },
      point: {
        r: 5,
        focus: {
          expand: {
            r: 6,
          },
        },
      },
      axis: {
        x: {
          tick: {
            multiline: false,
          },
        },
        y: {
          tick: {
            format: (x) => x.toFixed(2),
          },
        },
      },
    };

    return <CustomChart data={chartData} options={options} />;
  }

  prepareChartData(speedArray) {
    let data = {
      speed: ['x'],
      range: ['y'],
    };
    if (this.props.chartType === 'piece-wise') {
      speedArray = [...speedArray];
      for (let i = speedArray.length - 2; i > 0; i -= 2) {
        speedArray.splice(i, 0, speedArray[i], speedArray[i - 1]);
      }
    }
    for (let i = 0, length = speedArray.length; i < length; i += 2) {
      data.speed.push(speedArray[i]);
      data.range.push(speedArray[i + 1]);
    }
    return data;
  }

  renderFields() {
    let { speedArray } = this.props;
    let speedRows = [];

    for (let i = 0, length = speedArray.length; i < length; i += 2) {
      speedRows.push(this.getSpeedRow(speedArray[i], speedArray[i + 1], i));
    }

    return (
      <div className="rf-speed-group__rows">
        <div className="rf-speed-group__title">
          <Tooltip placement="right" tooltip={this.props.vTooltip}>
            <div className="rf-speed-group__title__speed">
              {this.props.vLabel}
            </div>
          </Tooltip>
          <Tooltip placement="right" tooltip={this.props.hTooltip}>
            <div className="rf-speed-group__title__consumption">
              {this.props.hLabel}
            </div>
          </Tooltip>
        </div>
        {speedRows}
        <lui-button secondary onClick={this.onAdd}>
          Add Row
        </lui-button>
      </div>
    );
  }

  renderTextarea() {
    let { speedArray } = this.props;
    return (
      <div>
        <Textarea
          label="Value"
          value={speedArray.join(',')}
          onBlur={(e) => this.props.onChange(utils.extractData(e))}
        />
      </div>
    );
  }

  render() {
    let tabs = [
      { label: 'Fields', name: 'fields' },
      { label: 'Textarea', name: 'textarea' },
    ];

    return (
      <div className="rf-speed-group">
        <div className="rf-speed-group__graph" ref={this.speedGroupRef}>
          {this.getGraph()}
        </div>

        <FormRow>
          <TabsControl
            currentTabName={this.state.currentTabKey}
            onChange={this.onTabChange}
            tabsData={tabs}
          />
          {this.state.currentTabKey === 'fields'
            ? this.renderFields()
            : this.renderTextarea()}
        </FormRow>
      </div>
    );
  }
}

SpeedGroup.defaultProps = {
  vLabel: 'Vehicle speed, kph',
  hLabel: 'Consumption',
  vTooltip: 'Speed value [km/h]',
  hTooltip: 'Energy consumption per metre at a given speed [E/m]',
  chartType: 'linear',
};

SpeedGroup.propTypes = {
  speedArray: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  vLabel: PropTypes.string,
  hLabel: PropTypes.string,
  vTooltip: PropTypes.string,
  hTooltip: PropTypes.string,
  chartType: PropTypes.string, // linear | piece-wise
};

export default SpeedGroup;
