import { TILE_LIBRARY_TYPES } from 'state/map/tiles/constants';
import HereNoticesPolyline, { useHereOnMove } from './HereNoticesPolyline';
import Notices from './Notices';
import NoticesLeaflet, { useLeafletOnMove } from './LeafletNoticesPolyline';

const noticesPlugin = {
  [TILE_LIBRARY_TYPES.LEAFLET]: Notices(NoticesLeaflet, useLeafletOnMove),
  [TILE_LIBRARY_TYPES.JSLA]: Notices(HereNoticesPolyline, useHereOnMove),
};

export default noticesPlugin;
