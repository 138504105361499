import { decode } from '../../utils/flexPolyline';

export default function flexPolyline(encodedPolyline) {
  if (!encodedPolyline) {
    return null;
  }

  return decode(encodedPolyline).map((latLng) => ({
    lat: +latLng[0],
    lng: +latLng[1],
  }));
}
