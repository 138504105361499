import customConfigsReducer from '../../config/custom/reducer';

// eslint-disable-next-line import/no-anonymous-default-export
export default (defaultConfigs) => {
  const configs = { ...defaultConfigs, ...customConfigsReducer(undefined, {}) };
  return Object.keys(configs).reduce(
    (acc, title) => ({
      ...acc,
      [title]: configs[title].settings,
    }),
    {}
  );
};
