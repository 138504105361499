import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { Tooltip } from '@here/ref-client-ui';

import './styles/layerToggle.scss';

class LayerToggle extends Component {
  click = () => {
    let { disabled, active, onChange } = this.props;
    if (!disabled) {
      onChange(!active);
    }
  };

  render() {
    let { active, disabled, label, tooltipText } = this.props;
    let baseClass = 'rf-layer-toggle';
    let classes = classnames(baseClass, {
      'rf-layer-toggle_active': !disabled && active,
      'rf-layer-toggle_disabled': disabled,
    });

    let toggleEl = (
      <div className={classes} onClick={this.click}>
        <div className="rf-layer-toggle__label">{label}</div>
      </div>
    );

    if (tooltipText) {
      return <Tooltip tooltip={tooltipText}>{toggleEl}</Tooltip>;
    }
    return toggleEl;
  }
}

LayerToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

LayerToggle.defaultProps = {
  disabled: false,
  active: false,
  label: '',
};

export default LayerToggle;
