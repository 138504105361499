const traceFormatter = ({ fields: { trace: traceStr, via } }, setFields) => {
  try {
    const trace = JSON.parse(traceStr);
    const viaSet = new Set();
    via.forEach(({ index }) => {
      viaSet.add(+index);
    });
    return trace.map(({ lat, lng }, index) => {
      const onChange = ({ coord: { value: coords } }) => {
        if (coords === null) {
          setFields({
            trace: JSON.stringify([
              ...trace.slice(0, index),
              ...trace.slice(index + 1),
            ]),
          });
        } else {
          const [lat, lng] = coords.split(',');
          const changedLatLng = { lat: +lat, lng: +lng };
          setFields({
            trace: JSON.stringify([
              ...trace.slice(0, index),
              changedLatLng,
              ...trace.slice(index + 1),
            ]),
          });
        }
      };
      const isVia = viaSet.has(index);
      return {
        value: `${lat},${lng}`,
        text: index + 1,
        draggable: true,
        strokeColor: isVia ? '#e10000' : 'black',
        onChange,
      };
    });
  } catch (e) {
    console.error(e);
    // Ignore parse exceptions;
    return [];
  }
};

export default traceFormatter;
