import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import HerePolyline from 'ref-client-core//views/shared/hereMap/HerePolyline';

function LocationOriginalLocationDiffRoutes({
  result: { state, raw = {} },
  colorPalette,
}) {
  const polylines = [];
  const route = get(raw, `data.routes[${state.currentRoute || 0}]`, {});
  (route.sections || []).forEach((section) => {
    const startLocation = get(section, 'departure.place.location');
    const startOriginalLocation = get(
      section,
      'departure.place.originalLocation'
    );
    const endLocation = get(section, 'arrival.place.location');
    const endOriginalLocation = get(section, 'arrival.place.originalLocation');
    const style = {
      strokeColor: colorPalette.secondary,
      lineWidth: 4,
      lineDash: [2, 2],
    };

    const arrivalPlace = get(section, 'arrival.place');
    const { type, displayLocation, location } = arrivalPlace;
    if (type === 'chargingStation' && displayLocation) {
      polylines.push(
        <HerePolyline
          key={`${section.id}chargingStation`}
          latLngs={[displayLocation, location]}
          options={{ style }}
        />
      );
    }

    if (
      !isEqual(startLocation, startOriginalLocation) &&
      startLocation &&
      startOriginalLocation
    ) {
      polylines.push(
        <HerePolyline
          key={`${section.id}start`}
          latLngs={[startLocation, startOriginalLocation]}
          options={{ style }}
        />
      );
    }
    if (
      !isEqual(endLocation, endOriginalLocation) &&
      endLocation &&
      endOriginalLocation
    ) {
      polylines.push(
        <HerePolyline
          key={`${section.id}end`}
          latLngs={[endLocation, endOriginalLocation]}
          options={{ style }}
        />
      );
    }
  });

  return polylines;
}

const locationOriginalLocationDiffRoutes = {
  [TILE_LIBRARY_TYPES.JSLA]: LocationOriginalLocationDiffRoutes,
  [TILE_LIBRARY_TYPES.LEAFLET]: null,
};

export default locationOriginalLocationDiffRoutes;
