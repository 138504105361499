import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from '@here/ref-client-ui';
import './resultErrorHandler.scss';
import { logError } from '../../utils/splunkLog';

class ResultPanelErrorHandler extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    logError(error);
    this.setState({ error });
  }

  render() {
    const { error: stateError } = this.state;
    const { children, resultConfig, error = stateError } = this.props;
    if (error) {
      return (
        <div className="rf-result-error">
          <h3>
            Failed to render:&nbsp;
            <b className="rf-result-error__message">
              &quot;{error.message}&quot;
            </b>
          </h3>
          <h4>Result config:</h4>
          <Tree data={resultConfig} />
        </div>
      );
    }

    return children;
  }
}

ResultPanelErrorHandler.propTypes = {
  children: PropTypes.node,
  resultConfig: PropTypes.object.isRequired,
  error: PropTypes.object,
};

export default ResultPanelErrorHandler;
