import { assignTabDataAndDispatch } from '../../actions';

const SET_RESULT_TAB = 'SET_RESULT_TAB';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = 0, action) => {
  switch (action.type) {
    case SET_RESULT_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const setResultTab = (result, tabIndex) =>
  assignTabDataAndDispatch({ type: SET_RESULT_TAB, payload: result }, tabIndex);

export const getResultTab = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return state.tabs[selectedTab].result;
};
