import React from 'react';
import PropTypes from 'prop-types';
import resultPanelPlugin from '../../core/resultPanelPlugin';
import ResultPanelErrors from './ResultPanelErrors';
import ResultPanelErrorHandler from './ResultPanelErrorHandler';
import { logError } from '../../utils/splunkLog';
import refClient from '../../core/refClient';

class ResultPanel extends React.Component {
  render() {
    const {
      title,
      result,
      config,
      setResultState,
      addTab,
      setSelectedTab,
      tabs,
      request,
      fields,
      selectedTabId,
      setRawResult,
      setFields,
      setNotification,
      tabColorPalette,
      uiSize,
      routeTimezone,
      allSettings,
    } = this.props;
    const { raw } = result;

    if (raw && raw.isError) {
      return <ResultPanelErrors response={raw} />;
    }
    let selectedPreset = '';
    tabs.forEach((tab) => {
      const { id, preset } = tab;
      if ( id === selectedTabId ) {
        selectedPreset = preset;
      }
    });
    const tabConfigs = config[title];
    const resultComponents = tabConfigs.resultPanel.map(
      (resultConfig, index) => {
        const key = `${resultConfig.type}${selectedTabId}${index}`;
        try {
          const Component = resultPanelPlugin.get(resultConfig.type);
          return (
            <ResultPanelErrorHandler key={key} resultConfig={resultConfig}>
              <Component
                result={result}
                fields={fields}
                options={resultConfig.options}
                plugin={resultPanelPlugin}
                setResultState={setResultState}
                addTab={addTab}
                setSelectedTab={setSelectedTab}
                tabs={tabs}
                request={request}
                selectedTabId={selectedTabId}
                setRawResult={setRawResult}
                refClient={refClient}
                setNotification={setNotification}
                setFields={setFields}
                tabColorPalette={tabColorPalette}
                uiSize={uiSize}
                routeTimezone={routeTimezone}
                settings={allSettings[title][selectedPreset]}
              />
            </ResultPanelErrorHandler>
          );
        } catch (e) {
          logError({...e, resultConfig});
          return (
            <ResultPanelErrorHandler
              key={key}
              resultConfig={resultConfig}
              error={e}
            />
          );
        }
      }
    );

    return resultComponents;
  }
}

ResultPanel.propTypes = {
  title: PropTypes.string.isRequired,
  result: PropTypes.object,
  config: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  tabColorPalette: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  setRawResult: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  selectedTabId: PropTypes.number.isRequired,
  uiSize: PropTypes.number.isRequired,
  routeTimezone: PropTypes.string.isRequired,
};

export default ResultPanel;
