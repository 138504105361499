import PropTypes from 'prop-types';
import React from 'react';

import DateTimeField from './DateTimeField';
import CustomComponent from 'shared/component';
import { ExpandFormRow, TabsControl } from '@here/ref-client-ui';

import utilsDate from 'utils/date';

import './styles/routeDateField.scss';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import get from 'lodash/get';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class RouteDateField extends CustomComponent {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onKeyChange = (key) => {
    const { setFields } = this.props;
    let value = '';
    if (key === 'departureTime' || key === 'arrivalTime') {
      value = utilsDate.getLocalDate().toISOString().split('.')[0];
    } else if (key === 'any') {
      key = 'departureTime';
      value = 'any';
    }
    setFields({
      routeTime: { key, value },
    });
  };

  onDateChange = (date = '') => {
    const { fields, setFields } = this.props;
    setFields({
      routeTime: {
        key: fields.routeTime.key,
        value: date,
      },
    });
  };

  getDateTimeEl = (routeDate = {}) => {
    const { date = '', timezone = '' } = utilsDate.splitDate(routeDate.value);
    const {
      options: { isLocalSupported },
    } = this.props;
    const { key, value } = routeDate;
    return !key || key === 'now' || value === 'any' ? null : (
      <DateTimeField
        date={date}
        timezone={timezone}
        onChange={this.onDateChange}
        isLocalSupported={isLocalSupported}
      />
    );
  };

  getTabs = (options) => {
    let tabsData = [{ label: 'Now', name: 'now' }];
    if (options.supportAny) tabsData.push({ label: 'Any', name: 'any' });
    if (options.supportArrival) {
      tabsData.push({ label: 'Departure', name: 'departureTime' });
      tabsData.push({ label: 'Arrival', name: 'arrivalTime' });
    } else {
      tabsData.push({ label: 'Custom', name: 'departureTime' });
    }
    return tabsData;
  };

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  render() {
    const {
      options,
      fields: { routeTime },
    } = this.props;
    const { key, value } = routeTime;
    const { departureKey, arrivalKey } = options;

    let currentTab = key;
    if (key === departureKey) {
      currentTab = 'departureTime';
    } else if (key === arrivalKey) {
      currentTab = 'arrivalTime';
    }
    if (value === 'any') {
      currentTab = 'any';
    }
    if (!key) {
      currentTab = 'now';
    }

    let tabsData = this.getTabs(options);

    return (
      <Searchable searchKey={['departureTime', 'arrivalTime']}>
        <ExpandFormRow
          label={options.label}
          className={this.getCssClasses([
            'rf-routedate-field',
            options.cssClasses,
          ])}
          tooltip={this.getTooltip()}
          tooltipPlacement="right"
          isExpanded
        >
          <TabsControl
            currentTabName={currentTab}
            onChange={this.onKeyChange}
            tabsData={tabsData}
          />
          <div className="rf-routedate-field__value">
            {this.getDateTimeEl(routeTime)}
          </div>
        </ExpandFormRow>
      </Searchable>
    );
  }
}

RouteDateField.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default RouteDateField;
