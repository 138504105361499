import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HerePolyline from 'shared/hereMap/HerePolyline';
import { useHereMap } from '../../../views/shared/hereMap/HereMapContext';
import { getCenterFromPolyline, getBBoxFromPolyline } from '../route/helpers';
import isArray from 'lodash/isArray';

function HereRoute({
  positions,
  color,
  weight,
  onClick,
  zIndex,  
  offset,
  type,
  centerOnChange,
  onContextMenu,
}) {
  const { map } = useHereMap();

  useEffect(() => {
    if (centerOnChange && isArray(positions) && positions.length > 0) {
      
      const bounds = getBBoxFromPolyline(positions);
      const viewModal = map.getViewModel();
      if (viewModal.getLookAtData().bounds.getBoundingBox().containsRect(bounds)) {
        map.setCenter(getCenterFromPolyline(positions), true);
      } else {
        viewModal.setLookAtData({
          bounds: bounds
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const style = { strokeColor: color, lineWidth: weight };
  return (
    <HerePolyline
      latLngs={positions} 
      options={{ zIndex, style, offset }}
      changeCursorOnHover
      onTap={onClick}
      onContextMenu={onContextMenu}
      type={type}
    />
  );
}

HereRoute.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  changeCursorOnHover: PropTypes.bool,
  centerOnChange: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default HereRoute;
