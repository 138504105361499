import * as types from './constants';
import { SET_RESULT } from '../result/raw/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_LOADING:
      return action.isLoading;
    case SET_RESULT:
      return false;
    default:
      return state;
  }
};
