import React from 'react';
import PropTypes from 'prop-types';
import HerePolyline from 'shared/hereMap/HerePolyline';
import HerePolygon from 'shared/hereMap/HerePolygon';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';
import { addOpacityToHexColor } from '../../utils';

class EHereCorridor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }
  
  onContextMenu = (e) => {
    const { map } = this.props;
    
    e.stopPropagation();
    const {
      originalEvent: { x, y },
      viewportX,
      viewportY
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
        x: viewportX,
        y: viewportY
      },
    });

    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  }

  close = () => {
    const { map } = this.props;
    if (!this.isUnmounted) {
      this.setState({ open: false });
    }
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };

  addException = (type, e, v) => {
    const { onContextMenu, map } = this.props;
    const { position: { x, y } } = this.state;
    const xyToLatLng = ({ x, y }) => map.screenToGeo(x, y);

    onContextMenu(type, e, {
      latLng: xyToLatLng({x, y}),
      xy: { x, y },
      xyToLatLng,
      zoom: map.getZoom()
    });
  }

  remove = (type, e) => {
    const { onContextMenu } = this.props;
    onContextMenu(type, e);
  }

  render() {
    const { contextMenu, positions, positionsWithMiddle, coatPositions, fillColor, strokeColor, zIndex, geometryIndex, draggable, resizable, onChange, isException } = this.props;
    let style = {
      strokeColor: 'rgba(255, 255, 255, 0)'
    };
    let lineStyle = {
      strokeColor: 'rgba(0, 0, 145, 0.3)',
      lineWidth: 2
    };
    if (fillColor) {
      style.fillColor = fillColor;
    } else if (strokeColor) {
      style.fillColor = addOpacityToHexColor(strokeColor, 0.5);
    }
    if (strokeColor) {
      lineStyle.strokeColor = strokeColor;
    }

    return <div>
        <HerePolygon 
          latLngs={positions} 
          coatLatLngs={coatPositions}
          options={{ style, zIndex }}
          draggable={draggable} 
          resizable={resizable}
          geometryIndex={geometryIndex}
          onChange={onChange}
          onContextMenu={contextMenu ? this.onContextMenu : null}
        />   
        <HerePolyline
          latLngs={positionsWithMiddle}
          draggable={false} 
          resizable={false}
          options={{ style: lineStyle, data: { hideOnDrag: true } }}
        />   
        {contextMenu && this.state.open && (<div className="rf-context-menu" style={{ ...this.state.position }}>
        <HereMapContextMenuItem
          label={`Add Exception Rectangle`}
          onClick={this.addException.bind(this, 'bbox')}
        />
        <HereMapContextMenuItem
          label={`Add Exception Polygon`}
          onClick={this.addException.bind(this, 'polygon')}
        />
        <HereMapContextMenuItem
          label={`Add Exception Corridor`}
          onClick={this.addException.bind(this, 'corridor')}
        />
        <HereMapContextMenuItem
          label={`Remove${isException ? ' Exception' : ''}`}
          onClick={this.remove.bind(this, 'remove')}
        />
      </div>
      )}
      </div>;
  }
}

EHereCorridor.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  zIndex: PropTypes.number,
  onChange: PropTypes.func,
};

export default EHereCorridor;
