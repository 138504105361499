import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from '../../shared/hereMap/HereMapContext';
import { getVectorTileLayer, getRasterTrafficTileLayer} from './TilesHelper';
import { getTrafficLayerStyles } from '../constants';
import { isEqual } from 'lodash';

class HereTrafficLayer extends React.Component {
  componentDidMount() {
    const { data, tilesData, map } = this.props;
    const { vector, vectorHARP, raster } = data;
    const { isInternal, type } = tilesData;
    this.platform = new window.H.service.Platform({
      apikey: 'API_KEY',
    });

    if ( type === 'vector' ) {
      this.layer = getVectorTileLayer(
        this.platform,
        vector,
        getTrafficLayerStyles()
      );
    } else if (type === 'vectorHARP') {
      const { url, apiKey, path } = vectorHARP;
      let style = map.getBaseLayer().getProvider().getStyle();
      const service = this.platform.getTrafficVectorTileService({
        layer: 'traffic',
        subDomain: '',
        baseUrl: new window.H.service.Url(
          'https',
          url,
          path.replace(/^\/|\/$/g, ''),
          {
            apikey: apiKey
          }
        )
      });
  
      this.layer = service.createLayer(style, {engineType: window.H.Map.EngineType.HARP});

    }else if ( isInternal ) {
      this.layer = getRasterTrafficTileLayer(
        this.platform,
        raster
      )
    } else {
      return;
    }
    map.addLayer(this.layer);
    this.interval = setInterval(() => {
      const provider = this.layer.getProvider();
      provider.getCache().removeAll();
      provider.reload(true);
    }, vector.refreshInterval * 1000);
  }

  componentDidUpdate(prevProps) {
    const { vector } = this.props.data;
    if (!isEqual(prevProps.data.vector, vector)) {
      const { map } = this.props;
      clearInterval(this.interval);
      map.removeLayer(this.layer);

      this.layer = getVectorTileLayer(
        this.platform,
        vector,
        getTrafficLayerStyles()
      );
      map.addLayer(this.layer);

      this.interval = setInterval(() => {
        const provider = this.layer.getProvider();
        provider.getCache().removeAll();
        provider.reload(true);
      }, vector.refreshInterval * 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    const { map } = this.props;
    map.removeLayer(this.layer);
  }

  render() {
    return null;
  }
}

HereTrafficLayer.propTypes = {
  map: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withHereMap(HereTrafficLayer);
