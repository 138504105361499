export default function approachPathLocation(
    fields,
    center,
    radius
  ) {
    const {lat, lng} = center.latLng;
    const { location } = fields;
    return {
      location: {
        ...location,
        type: 'waypoint',
        waypoint: `${lat.toFixed(6)},${lng.toFixed(6)}`,
      },
    };
  }
  