// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e, mode = 'avoid', index) => {
  const {
    [mode]: { areas, features },
  } = fields;
  
  const { i, isException } = areas[index];
  let toBeRemovedCount = 1;
  let restAreas = [];
  areas.slice(index + 1).forEach((area, idx) => {
    if (!area.isException) {
      area.i = area.i - toBeRemovedCount;
      restAreas[idx] = area;
    } else if (area.i < index) {
      restAreas[idx] = area;
    } else if (area.i > index){
      const newI = restAreas[area.i-index-1].i;
      area.i = newI;
      restAreas[idx] = area;
    } else if (!isException && area.i === i) {
      toBeRemovedCount++;
      restAreas[idx] = null;
    } else {
      console.log("Something WRONG with this condition!")
    }
  });
  restAreas = restAreas.filter(area => area !== null);
    
  return {
    [mode]: {
      areas: [...areas.slice(0, index), ...restAreas],
      features,
    },
  };
};
