import { isEqual, get, without } from 'lodash';

const utils = {
  extractData(data) {
    if (!data || !(data.nativeEvent instanceof Event)) {
      return data;
    }
    let target = data.currentTarget || data.target;
    return target.type === 'checkbox' ||
      (target.tagName && target.tagName.toLowerCase() === 'lui-checkbox')
      ? target.checked
      : target.value;
  },

  isPropChanged(props1, props2, path) {
    let v1 = get(props1, path),
      v2 = get(props2, path);
    return !isEqual(v1, v2);
  },

  objectDiffKeys(obj1, obj2) {
    let keys = Object.keys(obj1);
    return keys
      .filter((key) => obj1[key] !== obj2[key])
      .concat(without(Object.keys(obj2), ...keys));
  },
};

export default utils;
