import React from 'react';
import PropTypes from 'prop-types';
import './styles/apiUrlTextarea.scss';
import ContentEditable from 'react-contenteditable';
import ApiUrlPostData from './ApiUrlPostData';
import stripHtml from './stripHtml';
import ToggleButton from '../shared/toggleButton';

const transformToHtml = (value, isMultiline) => {
  const [host = '', params = ''] = value.split('?');
  const htmlParams = params.split('&').map((param) => {
    const [key, value] = param.split(/=(.*)/);
    let classes = 'rf-api-url-textarea__content__param';
    if (isMultiline) {
      classes += ' rf-api-url-textarea__content__param_multiline';
    }
    return `<strong class="${classes}">${key}</strong>=${value}`;
  });
  let br = '';
  let join = '&';
  if (isMultiline) {
    br = '<br />';
    join = '&<br />';
  }
  return `<strong class="rf-api-url-textarea__content__host">${host}</strong>?${br}${htmlParams.join(
    join
  )}`;
};

class ApiUrlTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: transformToHtml(props.value, props.isApiUrlMultiline),
      prevPropsValue: props.value,
      prevPropsIsMultiline: props.isApiUrlMultiline,
    };

    this.textareaRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const nextState = {};
    const { prevPropsValue, prevPropsIsMultiline } = state;
    if (prevPropsValue === null) {
      nextState.prevPropsValue = props.value;
    }
    if (prevPropsValue !== null && props.value !== prevPropsValue) {
      nextState.value = transformToHtml(props.value, props.isApiUrlMultiline);
      nextState.prevPropsValue = props.value;
    }

    if (prevPropsIsMultiline !== props.isApiUrlMultiline) {
      nextState.value = transformToHtml(
        stripHtml(state.value),
        props.isApiUrlMultiline
      );
      nextState.prevPropsIsMultiline = props.isApiUrlMultiline;
    }
    return nextState;
  }

  onKeyPress = (e) => {
    const { onChange, hideTextarea } = this.props;
    const { textContent, innerText } = e.target;

    if (e.key === 'Enter') {
      e.preventDefault();
      onChange({ target: { value: textContent || innerText } });
      hideTextarea();
    }
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  onMouseLeave = () => {
    const { hideTextarea } = this.props;
    const {
      el: { current: textareaEl },
    } = this.textareaRef.current;
    if (textareaEl !== document.activeElement && !this.isEditing) {
      hideTextarea();
    }
  };

  onBlur = (e) => {
    const { onChange, hideTextarea } = this.props;
    const { textContent, innerText } = e.target;

    const inputValue = textContent || innerText;
    
    onChange({ target: { value: inputValue } });
    hideTextarea();
  };

  onChangeView = ({ target: { checked } }) => {
    const { setAppSettings } = this.props;
    setAppSettings({ isApiUrlMultiline: checked });
  };

  onChangeParamsMapping = ({ target: { checked } }) => {
    const { setTabParamsMapping } = this.props;
    setTabParamsMapping(checked);
  };

  onChangePostData = (postData) => {
    const { hideTextarea, onChangePostData } = this.props;

    onChangePostData(postData);
    hideTextarea();
  };

  onChangeIsEditing = (isEditing) => {
    this.isEditing = isEditing;
  };

  render() {
    const { value } = this.state;
    const {
      style,
      isApiUrlMultiline,
      postData,
      setNotification,
      paramsMapping,
    } = this.props;

    return (
      <div
        onMouseLeave={this.onMouseLeave}
        className="rf-api-url-textarea"
        style={style}
      >
        <div className="rf-api-url-textarea__scrollable">
          <ContentEditable
            className="rf-api-url-textarea__content"
            html={value}
            spellCheck="false"
            onBlur={this.onBlur}
            onKeyPress={this.onKeyPress}
            onChange={this.onChange}
            ref={this.textareaRef}
          />
          <div
            style={{
              bottom: style.bottom,
              right: `calc(100% - ${style.left + style.width}px`,
            }}
            className="rf-api-url-textarea__toggle"
          >
            <ToggleButton
              label="Params mapping"
              checked={paramsMapping}
              onChange={this.onChangeParamsMapping}
            />
            <ToggleButton
              label="Multi line view"
              defaultChecked={isApiUrlMultiline}
              onChange={this.onChangeView}
            />
          </div>
          <ApiUrlPostData
            isApiUrlMultiline={isApiUrlMultiline}
            postData={postData}
            onChange={this.onChangePostData}
            onChangeIsEditing={this.onChangeIsEditing}
            setNotification={setNotification}
          />
        </div>
      </div>
    );
  }
}

ApiUrlTextarea.propTypes = {
  value: PropTypes.string.isRequired,
  hideTextarea: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setAppSettings: PropTypes.func.isRequired,
  isApiUrlMultiline: PropTypes.bool.isRequired,
  style: PropTypes.object,
  postData: PropTypes.object,
  onChangePostData: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  setTabParamsMapping: PropTypes.func.isRequired,
  paramsMapping: PropTypes.bool.isRequired,
};

export default ApiUrlTextarea;
