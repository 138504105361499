class AutoRefreshPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported auto refresh plugin type: '${name}'.
          Please register with auto refresh plugin
          import AutoRefreshPlugin from 'core/AutoRefreshPlugin'

          autoRefreshPlugin.register('${name}', <auto refresh condition function>)`
    );
  }
}

const autoRefreshPlugin = new AutoRefreshPlugin();
Object.freeze(autoRefreshPlugin);

export default autoRefreshPlugin;
