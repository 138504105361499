import React from 'react';
import PropTypes from 'prop-types';

import CustomComponent from 'shared/component';

import './styles/toggler.scss';
import RightArrow from './rigthArrow';
import LeftArrow from './leftArrow';

export default class Toggler extends CustomComponent {
  render() {
    const arrow = this.props.rightArrow ? <RightArrow /> : <LeftArrow />;
    return (
      <div
        className={this.getCssClasses(['rf-toggler'])}
        onClick={this.props.toggle}
      >
        {arrow}
      </div>
    );
  }
}

Toggler.propTypes = {
  rightArrow: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
