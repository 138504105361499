import React from 'react';
import { CloseButton, Columns, FormRow, Input } from '@here/ref-client-ui';

function ViaField({ index, via, onChange, onRemove }) {
  const onChangeTraceIndex = ({ target: { value } }) => {
    onChange({ ...via, index: value }, index);
  };
  const onChangeStopDuration = ({ target: { value } }) => {
    onChange({ ...via, stopDuration: value }, index);
  };

  const remove = () => {
    onRemove(index);
  };
  return (
    <FormRow className="rf-via-field-item">
      <Columns>
        <Input
          label="Trace Index"
          placeholder="Trace Index"
          value={via.index}
          onBlur={onChangeTraceIndex}
        />
        <Input
          label="Stop Duration"
          placeholder="Stop Duration"
          value={via.stopDuration}
          onBlur={onChangeStopDuration}
        />
      </Columns>
      <CloseButton onClick={remove} />
    </FormRow>
  );
}

export default ViaField;
