
import React from 'react';
import PropTypes from 'prop-types';
import { withLeaflet, Marker } from 'react-leaflet';
import 'leaflet-path-drag';

class EditableLeafletMarker extends React.Component {
  ref = React.createRef();

  onMouseMove = (move, e) => {
    const { cursorStyle } = this.props;
    let cursor = move ? (cursorStyle || 'move') : 'pointer';
    e.originalEvent.srcElement.style.cursor = cursor;
  };

  onDragEnd = () => {
    const {
      onChange,
    } = this.props;
    const { leafletElement: marker } = this.ref.current;

    onChange(marker.getLatLng());
  };

  render() {
    const { position, icon, draggable, resizable, onClick } = this.props;
    const props = {
      onmousemove: this.onMouseMove.bind(this, draggable || resizable)
    };
    if (onClick) {
      props.onClick = onClick;
    }
    
    if (draggable || resizable) {
      props.ondragend = this.onDragEnd;
    }
    return (
      <Marker
        position={position} 
        draggable={draggable}
        icon={icon} 
        ref={this.ref}
        {...{ ...props }}
      />
    );
  }
}

EditableLeafletMarker.propTypes = {
  leaflet: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
};

export default withLeaflet(EditableLeafletMarker);