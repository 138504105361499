import React from 'react';
import PropTypes from 'prop-types';
import { get, some, set, isEqual } from 'lodash';
import { createTabId } from '../../../state/tabs/tab/id';

class TabOpener extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      options: { mapper, fieldsMapper = [], tabTitle },
      addTab,
      setResultState,
      result,
      fields,
      selectedTabId,
    } = this.props;
    if (selectedTabId !== prevProps.selectedTabId) {
      return;
    }
    const isPropUpdated = ({ path, openTabOnChange }) =>
      openTabOnChange &&
      get(this.props, path) &&
      !isEqual(get(prevProps, path), get(this.props, path));
    let shouldOpenTab = some(mapper, isPropUpdated);

    if (shouldOpenTab) {
      const newTabFields = mapper.reduce((acc, { path, fieldsPath }) => {
        set(acc, fieldsPath, get(this.props, path));
        return acc;
      }, {});
      const id = get(result, 'state.relatedTabId') || createTabId();
      setResultState({ relatedTabId: id });
      const resultState = fieldsMapper.reduce(
        (acc, fieldsKey) => ({
          ...acc,
          [fieldsKey]: fields[fieldsKey],
        }),
        {}
      );
      addTab({
        title: tabTitle,
        fields: newTabFields,
        id,
        result: resultState,
      });
    }
  }

  render() {
    return null;
  }
}

TabOpener.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  selectedTabId: PropTypes.number.isRequired,
};

export default TabOpener;
