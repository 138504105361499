import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input, Select } from '@here/ref-client-ui';

class SelectWithCustom extends React.Component {
  state = {
    isCustom: false,
  };

  onChangeSelect = ({ target: { value } }) => {
    const { onChange } = this.props;
    if (value === 'custom') {
      this.setState({ isCustom: true });
    } else {
      this.setState({ isCustom: false });
      onChange(value);
    }
  };

  onChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { options, label, value } = this.props;
    const { isCustom } = this.state;
    const optionsWithCustom = [...options, 'custom'];
    const isCustomValue = options.indexOf(value) === -1 || isCustom;

    return (
      <>
        <FormRow>
          <Select
            label={label}
            options={optionsWithCustom}
            value={isCustomValue ? 'custom' : value}
            onChange={this.onChangeSelect}
          />
        </FormRow>
        {isCustomValue && (
          <FormRow>
            <Input
              className="rf-clearfix"
              label={label}
              placeholder={label}
              isValid
              value={value}
              onBlur={this.onChange}
              blurOnEnter
            />
          </FormRow>
        )}
      </>
    );
  }
}

SelectWithCustom.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectWithCustom;
