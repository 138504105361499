import React, { Component } from "react";
import { withLeaflet } from "react-leaflet";
import {polylineDecorator, Symbol, polyline} from "leaflet";
import "leaflet-polylinedecorator";

class PolylineDecorator extends Component {
  constructor(props) {
    super(props);
    this.map = {};
    this.arrow = null;
    this.polyline = null;
  }
  
  componentDidMount() {
    const { leaflet, positions, color, weight } = this.props
    const { map } = leaflet; //get native Leaflet map
    this.map = map;
    
    const arrow = [
      {
        offset: 25,
        repeat: 50,
        symbol: Symbol.arrowHead({
          pixelSize: 10, 
          // polygon: false, 
          pathOptions: {
            // color: 'white',
            fillColor: '#C4D1E5',
            color: '#C4D1E5',
            fillOpacity: 1,
            weight: 0,
            stroke: true,
          }
        })
      }
    ];

    this.polyline = polyline(positions, { color, weight }).addTo(map);
    this.arrow = polylineDecorator(this.polyline, { patterns: arrow }).addTo(map);
  }

  componentWillUnmount () {
    this.map.removeLayer(this.arrow);
    this.map.removeLayer(this.polyline);
  }

  render() {
    return <div></div>
  }
}

export default withLeaflet(PolylineDecorator);

