import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';

class HereDomMarker extends React.Component {
  marker = null;

  componentDidMount() {
    this.addMarker();
  }

  componentDidUpdate(prevProps) {
    const { latLng, options } = this.props;

    if (
      !isEqual(latLng, prevProps.latLng) ||
      !isEqual(options, prevProps.options)
    ) {
      this.removeMarker();
      this.addMarker();
    }
  }

  componentWillUnmount() {
    this.removeMarker();
  }

  addMarker = () => {
    const { latLng, options, map, group, draggable, resizable, onClick } = this.props;
    this.marker = new window.H.map.Marker(latLng, {
      ...options,
      volatility: !!draggable,
    });

    if (group) {
      group.addObject(this.marker);
    } else {
      map.addObject(this.marker);
    }

    if (draggable && resizable) {
      this.marker.draggable = true;
      this.initInteractionEvents()
    }

    if (onClick) {
      this.marker.addEventListener('tap', this.onClick);
    }

  };

  removeMarker = () => {
    const { group } = this.props;
    if (this.marker && group && group.contains(this.marker)) {
      group.removeObject(this.marker);
    }
  };

  
  onPointerMove = (targetData) => {
    const { map, cursorStyle } = this.props;

    map.getElement().style.cursor = cursorStyle || 'move';
  };

  onDragStart = (e) => {
    const { map } = this.props;
    const {
      currentPointer: { viewportX, viewportY },
    } = e;
    const { lat, lng } = map.screenToGeo(viewportX, viewportY);
    this.mouseStart = { lat, lng };
    e.stopPropagation();
  };

  onDrag = (e) => {
    const { map } = this.props;
    const { currentPointer } = e;
    const { lat, lng } = map.screenToGeo(
      currentPointer.viewportX,
      currentPointer.viewportY
    );
    this.marker.setGeometry({lat, lng});
    e.stopPropagation();
  };

  onDragEnd = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(this.marker.getGeometry());
    }
  };

  onClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(this.marker.getGeometry());
    }
    e.stopPropagation();
  }

  onPointerLeave = () => {
    const { map } = this.props;
    map.getElement().style.cursor = 'auto';
  };

  initInteractionEvents = () => {
    this.marker.addEventListener('dragstart', this.onDragStart);
    this.marker.addEventListener('drag', this.onDrag);
    this.marker.addEventListener('dragend', this.onDragEnd);

    this.marker.addEventListener('pointermove', this.onPointerMove);
    this.marker.addEventListener('pointerleave', this.onPointerLeave);
  };

  render() {
    return null;
  }
}

HereDomMarker.propTypes = {
  map: PropTypes.object.isRequired,
  latLng: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withHereMap(HereDomMarker);
