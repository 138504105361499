import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Sidebar from 'shared/sidebar';

import './styles/resultPanel.scss';
import { getTitle } from 'state/tabs/tab/title';
import { Loader } from '@here/ref-client-ui';
import { getIsLoading } from 'state/tabs/tab/isLoading';
import ResultPanel from './ResultPanel';
import {
  getResult,
  setRawResult,
} from '../../state/tabs/tab/result/raw/reducer';
import { getConfig } from '../../state/config';
import { getAllSettings } from '../../state/settings';
import { setResultState } from 'state/tabs/tab/result/state/reducer';
import { setIsResultPanelShown } from 'state/ui/reducer';
import { getIsResultPanelShown } from 'state/ui';
import { addTab } from '../../state/tabs/actions';
import { fetchData } from '../../state/tabs/tab/response/actions';
import { getFields, setFields } from 'state/tabs/tab/fields/reducer';
import { setSelectedTab } from '../../state/selectedTab/actions';
import { set as setNotification } from 'state/notification/actions';
import { getID } from 'state/tabs/tab/id';
import { getSelectedTabs, getTabs } from 'state/tabs';

export function hasResult(result) {
  return !!result.raw || result.state.isResultPanelShown;
}

export class ResultPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  getLoader() {
    if (this.props.isLoading) {
      return (
        <div className="rf-result-panel__loader">
          <Loader />
        </div>
      );
    }
    return null;
  }

  toggleVisibility(e) {
    e.preventDefault();
    this.props.setVisibility(!this.props.isResultPanelShown);
  }

  render() {
    const {
      title,
      result,
      config,
      setResultState,
      addTab,
      setSelectedTab,
      tabs,
      request,
      uiSize,
      routeTimezone,
      isLoading,
      fields,
      selectedTabId,
      setRawResult,
      setFields,
      setNotification,
      currentTabData,
      allSettings,
    } = this.props;

    if (!hasResult(result) && !isLoading) {
      return null;
    }

    const classes = classnames('rf-result-panel', {
      'rf-result-panel_fadeout': this.props.isResultPanelShown,
    });
    const btnClasses = classnames(
      'rf-result-panel__toggler',
      'rf-result-panel__toggler_visible'
    );

    return (
      <Sidebar cssClasses={classes}>
        {this.getLoader()}
        <div className={btnClasses} onClick={this.toggleVisibility} />
        <ResultPanel
          result={result}
          title={title}
          config={config}
          setResultState={setResultState}
          addTab={addTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
          request={request}
          setFields={setFields}
          setNotification={setNotification}
          fields={fields}
          selectedTabId={selectedTabId}
          setRawResult={setRawResult}
          tabColorPalette={currentTabData.tabColorPalette}
          uiSize={uiSize}
          routeTimezone={routeTimezone}
          allSettings={allSettings}
        />
      </Sidebar>
    );
  }
}

ResultPanelContainer.propTypes = {
  setVisibility: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  setRawResult: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  request: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  isResultPanelShown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  result: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  currentTabData: PropTypes.object.isRequired,
  selectedTabId: PropTypes.number.isRequired,
  uiSize: PropTypes.number.isRequired,
  routeTimezone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    isResultPanelShown: getIsResultPanelShown(state),
    title: getTitle(state),
    isLoading: getIsLoading(state),
    result: getResult(state),
    config: getConfig(state),
    fields: getFields(state),
    selectedTabId: getID(state),
    tabs: getTabs(state),
    currentTabData: getSelectedTabs(state),
    uiSize: state.appSettings.uiSize,
    routeTimezone: state.appSettings.routeTimezone,
    allSettings: getAllSettings(state),
  };
}

const mapDispatchToProps = {
  setVisibility: setIsResultPanelShown,
  setResultState,
  setRawResult,
  addTab,
  setSelectedTab,
  request: fetchData,
  setFields,
  setNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultPanelContainer);
