import tabColors from '../../../../config/tabColors';

export function getReservedColors(state) {
  return state.tabs.map((tab) => tab.tabColorPalette);
}

export function getNewTabColor(state) {
  let stringifiedReservedColors = getReservedColors(state).map(
    (color) => color.primary
  );
  let availableColors = tabColors.filter(
    (color) => stringifiedReservedColors.indexOf(color.primary) === -1
  );

  return availableColors.length ? availableColors[0] : tabColors[0];
}
