import splunkLog from './splunkLog';

const globalErrors = () => {
  window.onerror = function (msg, url, line, column, error) {
    let errorStr;
    if (error && error.stack) {
      errorStr = `${msg}\n${error.stack}`;
    } else {
      errorStr = `${msg}\n\tLine: ${line}\n\tColumn: ${column}`;
    }
    splunkLog({ error: errorStr }, 'error');
  };
};

export default globalErrors;
