import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import fetch from 'utils/fetch';
import get from 'lodash/get';

function createLineString( segmentRef ){
  var linkGeom = segmentRef.attributes.ROAD_GEOM_FCN;
  if (linkGeom) {
    var strip = [];
    for( var g=0; g < linkGeom.length; g++) {}
      var lat = linkGeom[0].LAT.split(",");
      var lon = linkGeom[0].LON.split(",");
      
      var temLat = 0, temLon = 0;
      for (var i = 0; i < lat.length; i++) {
        var latChange = parseInt(lat[i]) || 0; // to handle cases where there is no change in lat value
        var lonChange = parseInt(lon[i]) || 0; // to handle cases where there is no change in lon value
          temLat = temLat + latChange;
          temLon = temLon + lonChange;
          strip.push({
              lat: temLat / 100000,
              lng: temLon / 100000
          });
      }
    }

    return strip;
  }


function parseSegmentResults( segments ){
  let points = [];
  let segmentsGeometry = [];
  for (let i = 0; i< segments.length; i++) {
    const segment = createLineString(segments[i]);
    segmentsGeometry.push({ segment, segmentRef: segments[i].segmentRef});
    if (i === 0) {
      points = segment;
    } else {
      if (isEqual(points[points.length-1], segment[segment.length-1])) {
        points = points.concat(segment.reverse().slice(1));
      } else if (isEqual(points[points.length-1], segment[0])) {
        points = points.concat(segment.slice(1));
      } else if (isEqual(points[0], segment[segment.length-1])) {
        points = points.reverse().concat(segment.reverse().slice(1));
      } else if (isEqual(points[0], segment[0])) {
        points = points.reverse().concat(segment.slice(1));
      } 
    }
  }

  return {points, segmentsGeometry}
}

function MapAttributesLines(LinesComponent) {
  return class extends React.Component {
    componentDidUpdate(prevProps, prevState) {
      const { setResultState } = this.props;
      // Check if response has changed, and conditionally make XHR request for geometry
      if(!isEqual(this.props.result.raw, prevProps.result.raw) && this.props.result.raw && !this.props.result.raw.isError) {
        const { settings, fields, result } = this.props;
        const { raw: { data = {}, preRequestResult }} = result;
        const { segments = [] } = data;
        const { mapAttributes, routeTime, shippedHazardousGoods } = fields;

        const geoms = segments.filter(segment => {
          const { attributes = {} } = segment;
          return attributes.hasOwnProperty('ROAD_GEOM_FCN')
        })

        if ( geoms.length > 0 ) {
          const { points, segmentsGeometry } = parseSegmentResults(segments);

          setResultState({
            'geometry': points,
            'segmentsWithAttrs': null,
            segmentsGeometry
          });
        } else {
          const fieldsOptions = Object.keys(fields)
            .filter(key => !['routeTime','attributes','mapAttributes','shippedHazardousGoods'].includes(key) && fields[key])
            .reduce((obj, key) => {
              obj[key] = fields[key];
              return obj;
            }, {});
          if (routeTime.key && routeTime.key!=='now') {
            fieldsOptions.departure = routeTime.value;
          }
          if (shippedHazardousGoods.values.length) {
            fieldsOptions.shippedHazardousGoods = shippedHazardousGoods.values.join(',');
          }
          const { url, apikey } = settings;
          let { type } = mapAttributes;
          let segments = mapAttributes[type];
          if ( type === 'routerOriginDest' || type === 'routerUrl' ) {
            type = 'segments';
            segments = preRequestResult.segments;
          }
          const reqOptions = {
            data: {
              [type]: segments,
              attributes: "ROAD_GEOM_FCn(*)"
            },
            formData: true,
            headers : {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: "post",
            params: {...fieldsOptions, apikey},
            responseType: "json",
            url
          }
          fetch(reqOptions).then(res => {
            const { isError, data } = res;
            if (!isError) {
              const { segments } = data;
              const { points, segmentsGeometry } = parseSegmentResults(segments);
    
              setResultState({
                'geometry': points,
                'segmentsWithAttrs': null,
                segmentsGeometry
              });
            }
          })
        }
        
      }
    }

    showSegments(segments) {
      const { colorPalette, setResultState } = this.props;
      const lineElements = [];

      segments.forEach((segment,id) => {
        if (segment.attributes) {
          lineElements.push(<LinesComponent
            key={`segmentsWithAttrs${id}`}
            positions={segment.geometry}
            fillColor={colorPalette.primary}
            zIndex={400}
            lineWidth={10}
            opacity={0.4}
            onChange={()=>{}}
            segment={segment}
            index={id}
            listIndex={lineElements.length}
            setResultState={setResultState}
          />)
        }
      })
      return lineElements;
    }

    render() {
      const { colorPalette, options: { path, segmentsPath } = {} } = this.props;
      
      const line = get(this.props, path);
      const segments = get(this.props, segmentsPath);
      
      if (line) {
        const strokeColor = colorPalette.primary;

        return <>
          <LinesComponent
          positions={line}
          strokeColor={strokeColor}
          zIndex={400}
          lineWidth={3}
          onChange={()=>{}}
        />
        {segments && this.showSegments(segments)}
        </>
      }
      else {
        return null
      }
    }
  };
}

MapAttributesLines.propTypes = {
  options: PropTypes.object,
  colorPalette: PropTypes.object,
};

export default MapAttributesLines;
