import * as types from './constants';

export function setData(type, data = {}) {
  return { type: types.SET_DATA, payload: { type, data } };
}

export function setType(type) {
  return { type: types.SET_TYPE, payload: { type } };
}

export function toggle(isActive) {
  return { type: types.SET_IS_ACTIVE, payload: { isActive } };
}
