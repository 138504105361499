import { decode } from '../../utils/flexPolyline';

export default function actionsToManeuvers(route) {
  const maneuvers = [];
  if (!route) {
    return maneuvers;
  }
  route.sections.forEach((section) => {
    let shape;
    if (section.polyline) {
      shape = decode(section.polyline);
    }
    if (!section.turnByTurnActions) {
      return;
    }
    section.turnByTurnActions.forEach((action, index) => {
      const nextAction = section.turnByTurnActions[index + 1] || {};
      const nextOffset = nextAction.offset || action.offset + 1;
      let maneuverShape = [];
      if (shape) {
        maneuverShape = shape
          .slice(action.offset, nextOffset + 1)
          .map(([lat, lng]) => ({
            lat: +lat,
            lng: +lng,
          }));
      }
      const details = Object.keys(action).reduce(
        (acc, actionKey) => ({
          ...acc,
          [actionKey]: JSON.stringify(action[actionKey]),
        }),
        {}
      );
      maneuvers.push({
        instruction: action.instruction || action.action,
        shape: maneuverShape.length > 1 ? maneuverShape : null,
        circle: action.duration === 0 ? maneuverShape[0] : null,
        details,
      });
    });
  });

  return maneuvers;
}
