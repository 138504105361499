const mapAttributes = {
  "title": "Route Matching",
  "tabTitle": "Route Matching",
  "settings": {
    "ApiKey": {
      "type": "apiKey",
      "url": "https://routematching.hereapi.com/v8/match/routelinks",
      "apikey": process.env.REACT_APP_APIKEY
    }
  },
  "inputs": [
    {
      "type": "group",
      "options": {
        "label": "Input Trace",
        "isExpanded": true,
        "cssClasses": "rf-grey-box",
        "inputs": [{
          "type": "routeTrace",
          "label": "Input Trace",
          "options": {
            "key": "InputTrace"
          }
        }]
      }
    },
    {
      "type": "checkboxes",
      "options": {
        "key": "attributes",
        "label": "Attributes",
        "isExpanded": true,
        "cssClasses": "rf-grey-box",
        "loadParams": {
          "urlApiKey": "settings.apikey",
          "url": "https://smap.hereapi.com/v8/maps/layers/list",
          "secUrlSuffix": "layers/list.html",
          "secText": "Map Attributes Documentation",
          "mapAttributes": true,
          "notSupported": ["ADMIN_PLACE_n", "ADMIN_POLY_n", "CARTO_LINE_DOn", "CARTO_POLY_DOn", "CARTO_POLY_RIVER_DOn", "CENSUS_n",
            "CITY_POI_n", "DTM_HEIGHT", "REAL_TIME_TRAFFIC", "ROAD_OVERVIEW", "ROUNDABOUT_FCn", "ROUTING_FCn", "ADMIN_REGULATIONS",
            "ADMIN_TRUCK_RESTR", "COUNTRY", "ENV_ZONE_RESTR", "ROUTING_ZONES", "TC_ADMIN", "TC_SYSTEM", "TC_TOLLSYS_NAMES", "TC_VEH_TYPES",
            "TRAFFIC_PATTERN", "TOLLCOST_FCn"],
          "excludes": ["BUILDING", "CARTO_POLY_TOLL_HAZ", "JUNCTION_VIEW", "PSTLCB_GEN", "PSTLCB_MP", "PSTLCB_UNGEN", "SIGNPOST", "VIRT_CONN"]
        }
      }
    },
    {
      "type": "select",
      "options": {
        "label": "Routing Mode",
        "key": "mode",
        "initValue": "car",
        "selectOptions": [
          { "label": "Car", "value": "car" },
          { "label": "Pedestrian", "value": "pedestrian" },
          { "label": "Truck", "value": "truck" },
          { "label": "Bicycle", "value": "bicycle" },
          { "label": "motorcycle", "value": "motorcycle" },
          { "label": "Scooter", "value": "scooter" },
          { "label": "Bus", "value": "bus" },
          { "label": "carHOV", "value": "carHOV" },
          { "label": "emergency", "value": "emergency" },
          { "label": "roadtrain", "value": "roadtrain" },
          { "label": "Taxi", "value": "taxi" }
        ]
      }
    },
    {
      "type": "input",
      "options": {
        "inputType": "number",
        "key": "routeMatch",
        "label": "routeMatch",
        "value": 1,
        "disabled": true,
        "validation": [
          { "type": "min", "value": 1 },
          { "type": "max", "value": 1 }
        ]
      }
    },
    {
      "type": "input",
      "options": {
        "inputType": "number",
        "key": "width",
        "label": "width"
      }
    },
    {
      "type": "input",
      "options": {
        "inputType": "number",
        "key": "height",
        "label": "height"
      }
    },
    {
      "type": "input",
      "options": {
        "inputType": "number",
        "key": "length",
        "label": "length"
      }
    },
    {
      "type": "input",
      "options": {
        "inputType": "number",
        "key": "vehicleWeight",
        "label": "Vehicle Weight"
      }
    },
    {
      "type": "select",
      "options": {
        "label": "trailers count",
        "key": "trailersCount",
        "selectOptions": [
          { "label": "no trailer", "value": "0" },
          { "label": "one or more trailers", "value": "1" },
          { "label": "two or more trailers", "value": "2" },
          { "label": "three or more trailers", "value": "3" },
          { "label": "one or more semi-trailers", "value": "4" }
        ]
      }
    }
  ],
  "map": [
    {
      "type": "routeMatchingRoute",
      "options": {
        "routesPath": "result.raw.data.response"
      }
    },
    {
      "type": "circle",
      "options": {
        "radiusPath": "fields.regionDefinition.radius",
        "centerPath": "fields.regionDefinition.center",
        "onChangeFormatter": "olsMatrixRegionDefinitionCircle",
        "draggable": true,
        "resizable": true
      }
    },
    {
      "type": "contextMenu",
      "options": {
        "items": [
          {
            "type": "routeMatchingAddPoint",
            "options": {
              "text": "Add point",
              "key": "InputTrace",
              "conditions": [
                {
                  "type": "equals",
                  "key": "paramsMapping",
                  "value": true
                }
              ]
            }
          }
        ]
      }
    },
    {
      "type": "centerMap",
      "options": {
        "path": "result.state.selectedWarning.position"
      }
    }
  ],
  "resultPanel": [
    {
      "type": "tab",
      "options": {
        "tabs": [
          {
            "title": "Details",
            "type": "rmDetails",
            "options": {
              "pathKey": "raw.data.response"
            }
          },
          {
            "title": "Response",
            "type": "rawResponse"
          }
        ]
      }
    },
    {
      "type": "tabOpener",
      "options": {
        "tabTitle": "Matrix Result V8",
        "mapper": [
          {
            "path": "result.raw.data.statusUrl",
            "fieldsPath": "matrixResultStatusUrl",
            "openTabOnChange": true
          },
          {
            "path": "fields.origins",
            "fieldsPath": "origins"
          },
          {
            "path": "fields.destinations",
            "fieldsPath": "destinations"
          }
        ]
      }
    }
  ],
  "autoRefresh": {
    "conditions": [
      {
        "type": "required",
        "options": {
          "path": "fields.origins"
        }
      }
    ]
  }
}

export default mapAttributes;