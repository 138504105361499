import { ROUTING_MODE_MAPPER, TRUCK_MAPPER } from './hlsFieldsMapper';

const openHlsTab = (fields) => {
  const { transportMode, routingMode } = fields;

  let waypointIndex = 0;
  const getWaypointParam = (coords, isWaypoint) =>
    `waypoint${waypointIndex++}=geo!${
      isWaypoint ? 'passThrough' : 'stopOver'
    }!${coords}`;

  const params = [];
  params.push(getWaypointParam(fields.origin, false));

  const viaCoords = fields.via.map((point) => point.coords.split(','));
  viaCoords.forEach((coord) =>
    params.push(getWaypointParam(`${coord[0]},${coord[1]}`, true))
  );

  params.push(getWaypointParam(fields.destination, false));

  let hlsTruckFields = {};

  Object.keys(fields).forEach((fieldKey) => {
    if (TRUCK_MAPPER[fieldKey]) {
      hlsTruckFields = {
        ...hlsTruckFields,
        ...TRUCK_MAPPER[fieldKey](fields[fieldKey]),
      };
    }
  });

  Object.keys(hlsTruckFields).forEach((key) => {
    const value = hlsTruckFields[key];
    if (value) {
      params.push(`${key}=${value}`);
    }
  });

  params.push(
    `mode=${ROUTING_MODE_MAPPER[routingMode] || 'fastest'};${transportMode}`
  );
  if (fields.alternatives) {
    params.push(`alternatives=${fields.alternatives}`);
  }

  const url =
    'http://refclient.in.here.com/#url=https://route.api.here.com/routing/7.2/calculateroute.json?routeattributes=sh,gr&maneuverattributes=po,ti,pt,ac,di,fj,ix&metricsystem=metric&jsonattributes=41&language=en-us';
  const win = window.open(`${url}&${params.join('&')}`, '_blank');
  win.focus();
};

export default openHlsTab;
