import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import DiffLine from './DiffLine';
import { Polyline } from 'react-leaflet';
import HerePolyline from 'shared/hereMap/HerePolyline';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: DiffLine(Polyline),
  [TILE_LIBRARY_TYPES.JSLA]: DiffLine(HerePolyline),
};
