import { get, isEqual } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ result: { state, raw = {} } }) {
  const route = get(raw, `data.routes[${state.currentRoute || 0}]`, {});
  const points = (route.sections || []).reduce(
    (acc, { departure = {}, arrival = {} }) => {
      const start = get(departure, 'place.originalLocation');
      const end = get(arrival, 'place.originalLocation');

      if (isEqual(acc[acc.length - 1], start)) {
        return [...acc, end];
      } else {
        return [...acc, start, end];
      }
    },
    []
  );
  let res = [];
  points.forEach((point, index) => {
    if (!point) {
      return;
    }
    let text = index === 0 ? 'A' : index;
    if (index === points.length - 1) {
      text = 'B';
    }

    res.push({
      text,
      value: `${point.lat},${point.lng}`,
      draggable: false,
    });
  });

  return res;
}
