import PropTypes from 'prop-types';
import React from 'react';
import utils from 'utils';
import { ExpandFormRow, FormRow, Input, Select } from '@here/ref-client-ui';
import OlsWaypointAdvanced from '../olsWaypoint/olsWaypointAdvanced';
import {
  splitAdvancedWaypointParams,
  stringifyWaypointParams, 
  removeAdvancedParamsPrefix, 
  getAdvancedParams,
  defaultAdvancedState
} from '../olsWaypoint/olsWaypoint';
import './location.scss';
import get from 'lodash/get';
import { getOpenapiUrl } from '../openapiHelpers';
import yamlLoad from '../checkboxes/yamlLoad';

const OPTIONS = {
  course: true,
  sideOfStreetHint: true,
  displayLocation: true,
  matchSideOfStreet: true,
  nameHint: true,
  radius: true,
  snapRadius: true,
  minCourseDistance: true,
  radiusPenalty: true,
  segmentIdHint: true,
  onRoadThreshold: true,
  passThrough: true,
  stopDuration: true,
  extraParams: true,
}

function getLocationType(location) {
  const [type] = location.split(':');
  if (type === 'tmc' || type === 'olr') {
    return type;
  } else
    return 'waypoint';
}

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapi: null,
    };
  }
  componentDidMount() {
    const propsUrl = get(this.props, 'settings.url');
    const url = getOpenapiUrl(propsUrl, /.*\/v8\//, 'openapi');
    yamlLoad(url).then((openapi) => {
      if (!this.isUnmounted) {
        this.setState({ openapi });
      }
    });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeLocationType = (e) => {
    const {
      options: { key },
      setFields,
      fields: { location }
    } = this.props;
    const type = utils.extractData(e);
    if (type === 'waypoint') {
      setFields({ [key]: {...location, type} });
    } else {
      setFields({ [key]: {...{[type]: `${type}:`}, ...location, type} });
    }
  };

  onChangeOlr = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    setFields({
      [key]: { ...fields[key], olr: `olr:${e.target.value}` },
    });
  };


  onChangeTmc = (idx, e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const location = fields[key];

    const { tmc = 'tmc:' } = fields[key] || {};
    let TmcString = tmc.split(':')[1].split(',');
    if (TmcString.length > 3) {
      let [p1, p2, ...p3] = tmc.split(':')[1].split(',');
      p3 = p3.join(',');
      TmcString = [p1, p2, p3];
    }
    TmcString[idx] = e.target.value;
    const tmcCopy = [];
    TmcString.forEach((e, i)=>{
      if (e)
        tmcCopy[i] = e;
    })
    setFields({
      [key]: { ...location, tmc: `tmc:${tmcCopy.join(',')}` },
    });
  };

  getLocationTypeFields = (locationType) => {
    switch (locationType) {
      case 'waypoint':
        return this.getWaypointFields();
      case 'tmc':
        return this.getTmcFields();
      case 'olr':
        return this.getOlrFields();
      default:
        return null;
    }
  };

  onAdvancedChanged = (field, e) => {
    const {
      setFields,
    } = this.props;
    
    setFields({ [`location_${field}`]: utils.extractData(e) });
  };

  onWaypointChange = (e) => {
    const {
      options: { key },
      setFields,
      fields: { location }
    } = this.props;
    setFields({ [key]: {...location, ...{'waypoint': utils.extractData(e)}} });
  };

  getWaypointFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { waypoint } = fields[key]

    return (
      <>
        <FormRow>
          <Input
            label={'Coordinate'}
            placeholder={'{lat},{lng}'}
            value={waypoint}
            onBlur={this.onWaypointChange}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <OlsWaypointAdvanced 
            fields={fields}
            data={removeAdvancedParamsPrefix(getAdvancedParams(this.props.fields, 'location'), 'location')}
            options={OPTIONS}
            onChange={this.onAdvancedChanged}
          />
        </FormRow>
      </>
    );
  };

  getTmcFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { tmc = 'tmc:' } = fields[key] || {};
    const tmcParams = (tmc.split(':')[1]||'').split(',');
    let [ TmcLocationCode, OffsetMeters, LengthMeters ] = tmcParams;
    if (tmcParams.length > 3) {
      [ TmcLocationCode, OffsetMeters, ...LengthMeters ] = tmcParams;
      LengthMeters = LengthMeters.join(',');
    }
    return (
      <>
        <FormRow>
          <Input
            label="Tmc Location Code"
            value={TmcLocationCode || ''}
            onChange={this.onChangeTmc.bind(this, 0)}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="Offset Meters"
            type="number"
            value={OffsetMeters || ''}
            onChange={this.onChangeTmc.bind(this, 1)}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Length Meters"
            type="number"
            value={LengthMeters || ''}
            onChange={this.onChangeTmc.bind(this, 2)}
          />
        </FormRow>
      </>
    );
  };

  getOlrFields = () => {
    const {
      options: { key },
      fields,
    } = this.props;
    const { olr = 'olr:' } = fields[key] || {};
    return (
      <FormRow>
        <Input
          label="OLR Location"
          value={olr.split(':')[1]||''}
          onChange={this.onChangeOlr}
          blurOnEnter
        />
      </FormRow>
    );
  };

  getTooltip = (key) => {
    // const { openapi } = this.state;
    // const tooltip = get(openapi, OPENAPI_PATHS[key]);
    // if (tooltip) {
    //   return (
    //     <div className="rf-form-view__tooltip">
    //       {key} - {tooltip}
    //     </div>
    //   );
    // }
    return null;
  };

  render() {
    const {
      options: { label, key, locationTypes, initValue },
      fields,
    } = this.props;
    const locationType = fields[key].type || initValue.type;
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltip={this.getTooltip('description')}
        tooltipPlacement="right"
        className="rf-approach-path-location rf-grey-box"
      >
        <FormRow>
          <Select
            label="Location Type"
            options={locationTypes}
            value={locationType}
            onChange={this.onChangeLocationType}
            tooltip={this.getTooltip(locationType)}
          />
        </FormRow>
        {this.getLocationTypeFields(locationType)}
      </ExpandFormRow>
    );
  }
}

Location.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options, parsedParams }) => {
    const { key } = options;
    const location = parsedParams[key]||'';
    const type = getLocationType(location);
    if (type === 'waypoint') {
      const parsedValue = splitAdvancedWaypointParams(
        location,
        defaultAdvancedState('location'),
        'location'
      );
      const res = {
        location: {
          type,
          [type]: parsedValue.coords
        },
        ...parsedValue.advanced
        };
      delete parsedParams.location;
      return res;
    } else if (type === 'tmc') {
      return {
        location: {
          type: 'tmc', 
          tmc: location
        }
      }
    } else if (type === 'olr') {
      return {
        location: {
          type: 'olr', 
          olr: location
        }
      }
    } 
  },

  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),

  getRequestOptions: (fields, options) => {
    const { key } = options;
    const location = fields[key];
    const { type, waypoint, tmc, olr } = location;

    if (type === 'waypoint') {
      const params = stringifyWaypointParams(
        {
          coords: waypoint || '',
          advanced: getAdvancedParams(fields, 'location'),
        },
        'location'
      );

      return {
        params: { [key]: params },
      };
    } else if (type === 'tmc') {
      return {
        params: { [key]: tmc },
      }
    } else if (type === 'olr') {
      return {
        params: { [key]: olr },
      }
    }

    return {
      params: { [key]: location[location.type] },
    };
  },
  Component: Location,
};
