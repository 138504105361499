import { get } from 'lodash';

function toHoursAndMinutes(s) {
  const m = Math.floor(s / 60);

  const seconds = s % 60;
  const hours = Math.floor(m / 60);
  const minutes = m % 60;

  return { h: hours, m: minutes, s: seconds };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const routes = get(props, 'result.raw.data.routes');
  if (!routes) {
    return [];
  }
  const currentRouteIndex = get(props, 'result.state.currentRoute', 0);

  const currentRouteMarkers = get(routes[currentRouteIndex], 'sections', [])
    .filter((section) => 
      section.arrival.place.type === 'place' &&
      !section.arrival.place.hasOwnProperty('waypoint') &&
      section.postActions && 
      !!section.postActions.filter((postAction) => postAction.action === 'wait').length
    )
    .map((section) => {
      const { lat, lng } = section.arrival.place.location;
      const postActions = section.postActions.filter((postAction) => postAction.action === 'wait');
      const duration = toHoursAndMinutes(postActions[0].duration);

      // const icon = postActions[0].duration >= 6 * 60 * 60 ? 
      //   '&#x1F6CF;' : 
      //   '&#x2615;';      // coffee
        // '&#x1F375;';     // tea
        // '&#x1F17F;';     // parking 
        // '&#x1F964;';     // cup with straw
        // '&#x1F6CF;';   // knife and fork

      return {
        value: `${lat},${lng}`,
        text: `${duration.h}h ${duration.m}min ${duration.s}s`,
        dsIcon: postActions[0].duration >= 6 * 60 * 60 ? 'bed' : 'coffee',
        draggable: false,
        isCurrentRoute: true,
        iconType: 'dsMarker',
        iconHeight: 32,
        iconWidth: 114,
        iconX:11.5,
        iconY:32,
        rectIcon: true,
      };
    });

  const otherMarkers = [];
  routes
    .filter((route, index) => index !== currentRouteIndex)
    .forEach((route) => {
      get(route, 'sections', [])
      .filter((section) => 
          section.arrival.place.type === 'place' &&
          !section.arrival.place.hasOwnProperty('waypoint') &&
          section.postActions && 
          !!section.postActions.filter((postAction) => postAction.action === 'wait').length
        )
        .forEach((section) => {
          const { lat, lng } = section.arrival.place.location;
          const postActions = section.postActions.filter((postAction) => postAction.action === 'wait');
          const duration = toHoursAndMinutes(postActions[0].duration);
          const value = `${lat},${lng}`;

          if (currentRouteMarkers.find((marker) => marker.value === value)) {
            return;
          }

          otherMarkers.push({
            value,
            text: `${duration.h}h ${duration.m}min ${duration.s}s`,
            dsIcon: postActions[0].duration >= 6 * 60 * 60 ? 'bed' : 'coffee',
            draggable: false,
            isCurrentRoute: false,
            iconType: 'dsMarker',
            iconHeight: 32,
            iconWidth: 114,
            iconX:11.5,
            iconY:32,
            rectIcon: true,
          });
        });
    });

  return [...currentRouteMarkers, ...otherMarkers];
};
