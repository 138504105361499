import React from 'react';
import PropTypes from 'prop-types';
import { Select, FormRow, Input } from '@here/ref-client-ui';
import {
  TILE_TYPES,
  TILE_URLS,
  TILE_URLS_INTERNAL,
  TILE_STYLE_URLS,
  TILE_STYLE_HARP_URLS,
  TILE_PATHS,
} from 'state/map/tiles/constants';
import defaultState from 'state/map/tiles/defaultState';
import SelectWithCustom from './SelectWithCustom';
import { isEqual } from 'lodash';
import config from 'config/vector';
import refClient from '../../core/refClient'


const vectorDefaultState = defaultState[TILE_TYPES.VECTOR];
const vectorHarpDefaultState = defaultState[TILE_TYPES.VECTORHARP];

class VectorTilesForm extends React.Component {
  onChange = (key, e) => {
    const { onChange } = this.props;
    onChange({ [key]: e.target.value });
  };

  resetSettings = () => {
    const { type } = this.props;
    const defaultState = type ? vectorHarpDefaultState : vectorDefaultState;

    this.props.onChange(defaultState);
  };

  onChangeUrl = (value) => {
    const { onChange } = this.props;
    this.onChangeApiKey({target: {value: value === TILE_URLS_INTERNAL[4] ? refClient.apiKeys.keys.ChinaVector : 'DEFAULT_API_KEY' } })
    this.onChange('path', {target: {value: value === TILE_URLS_INTERNAL[4] ? TILE_PATHS[1] : TILE_PATHS[0] } })
    onChange({ url: value });
  };

  onChangeStyleUrl = (value) => {
    const { onChange } = this.props;
    onChange({ styleUrl: value });
  };

  onChangeApiKey = ({ target: { value } }) => {
    const { onChange } = this.props;
    const apiKey = value === window.APIKEY ? 'DEFAULT_API_KEY' : value;
    onChange({ apiKey });
  };

  render() {
    const { url, apiKey, path, styleUrl, pview, isInternal, type } = this.props;
    const canResetSettings = !isEqual(vectorDefaultState, {
      url,
      pview,
      apiKey,
      path,
      styleUrl,
    });

    const styleUrls = type ? TILE_STYLE_HARP_URLS : TILE_STYLE_URLS;

    return (
      <div>
        <h2>Vector Map Tiles</h2>
        <SelectWithCustom
          options={isInternal?TILE_URLS_INTERNAL:TILE_URLS}
          value={url}
          label="Url"
          onChange={this.onChangeUrl}
        />
        {isInternal && ( <FormRow>
          <Select
            className="rf-clearfix"
            label="Political view"
            options={config.politicalView}
            value={pview}
            onChange={this.onChange.bind(this, 'pview')}
          />
        </FormRow>
        )}
        <FormRow>
          <Input
            className="rf-clearfix"
            label="Path"
            placeholder="Path"
            isValid
            value={path}
            onBlur={this.onChange.bind(this, 'path')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            className="rf-clearfix"
            label="Api Key"
            placeholder="Api Key"
            isValid
            value={apiKey === 'DEFAULT_API_KEY' ? window.APIKEY : apiKey}
            onBlur={this.onChangeApiKey}
            blurOnEnter
          />
        </FormRow>
        <SelectWithCustom
          options={styleUrls}
          value={styleUrl}
          label="Style Url"
          onChange={this.onChangeStyleUrl}
        />
        {canResetSettings && (
          <FormRow>
            <lui-button secondary onClick={this.resetSettings}>
              Reset Defaults
            </lui-button>
          </FormRow>
        )}
      </div>
    );
  }
}

VectorTilesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  pview: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  styleUrl: PropTypes.string.isRequired,
};

export default VectorTilesForm;
