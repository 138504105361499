import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import Circle from './Circle';
import HereCircle from './HereCircle';
import LeafletCircle from './LeafletCircle';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Circle(LeafletCircle),
  [TILE_LIBRARY_TYPES.JSLA]: Circle(HereCircle),
};
