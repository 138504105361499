import React, { useCallback, useState } from 'react';
import { Button, FormRow, Textarea } from '@here/ref-client-ui';
import './flexPolylineFormView.scss';
import { decode } from 'ref-client-core/utils/flexPolyline';

function getCoord({ lat, lng }) {
  return `${lat},${lng}`;
}

function FlexPolylineFormView({
  fields: { flexPolyline },
  setFields,
  setResultState,
}) {
  const [isValid, setIsValid] = useState(true);

  const changePolyline = useCallback(
    ({ target: { value } }) => {
      setFields({ flexPolyline: value });
      setIsValid(true);
    },
    [setFields]
  );
  const getPolylines = useCallback(() => {
    try {
      const decodedPolyline = decode(flexPolyline).map(
        ([lat, lng, elevation]) => ({
          lat,
          lng,
          elevation,
        })
      );
      const start = getCoord(decodedPolyline[0]);
      const end = getCoord(decodedPolyline[decodedPolyline.length - 1]);

      setResultState({
        polyline: decodedPolyline,
        start,
        end,
        isResultPanelShown: true,
      });
    } catch (e) {
      console.error(e);
      setIsValid(false);
    }
  }, [flexPolyline, setResultState]);

  return (
    <FormRow className="rf-flex-polyline-form-view">
      <Textarea
        label="Flex Polyline"
        value={flexPolyline}
        onBlur={changePolyline}
        isValid={isValid}
      />
      <Button title="Get Points" onClick={getPolylines} />
    </FormRow>
  );
}

const flexPolylineFormViewPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: FlexPolylineFormView,
};

export default flexPolylineFormViewPlugin;
