import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import MapAttributesLines from './MapAttributesLines';
import HereLines from './HereLines';
import LeafletLines from './LeafletLines';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: MapAttributesLines(LeafletLines),
  [TILE_LIBRARY_TYPES.JSLA]: MapAttributesLines(HereLines),
};
