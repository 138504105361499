import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@here/ref-client-ui';
import stringParseUrl from '../stringParseUrl';
import stringGetRequestOptions from '../stringGetRequestOptions';
import Searchable from '../../../views/shared/searchUtils/Searchable';

const LANGUAGES = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic - Saudi Arabia', value: 'ar-sa' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Assamese', value: 'as' },
  { label: 'Azerbaijani - Latin', value: 'az-latn' },
  { label: 'Bangla - Bangladesh', value: 'bn-bd' },
  { label: 'Bangla - India', value: 'bn-in' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bosnian - Latin', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Central Kurdish - Arabic', value: 'ku-arab' },
  { label: 'Chinese - Simplified China', value: 'zh-cn' },
  { label: 'Chinese - Traditional Taiwan', value: 'zh-tw' },
  { label: 'Chinese - Traditional Hong Kong', value: 'zh-hk' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech – Czech Republic', value: 'cs-cz' },
  { label: 'Danish – Denmark', value: 'da-dk' },
  { label: 'Dari - Arabic', value: 'prs-arab' },
  { label: 'Dutch – Netherlands', value: 'nl-nl' },
  { label: 'Estonian', value: 'et' },
  { label: 'English – United Kingdom', value: 'en-gb' },
  { label: 'English – United States', value: 'en-us' },
  { label: 'Filipino - Latin', value: 'fil-latn' },
  { label: 'Finnish – Finland', value: 'fi-fi' },
  { label: 'French – France', value: 'fr-fr' },
  { label: 'Galician', value: 'gl' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German – Germany', value: 'de-de' },
  { label: 'Greek – Greece', value: 'el-gr' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Hausa - Latin', value: 'ha-latn' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian – Hungary', value: 'hu-hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Igbo - Latin', value: 'ig-latn' },
  { label: 'Indonesian - Indonesia', value: 'id-id' },
  { label: 'Irish', value: 'ga' },
  { label: 'IsiXhosa', value: 'xh' },
  { label: 'IsiZulu', value: 'zu-za' },
  { label: 'Italian – Italy', value: 'it-it' },
  { label: 'Japanese – Japan', value: 'ja-jp' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: "K'iche' - Latin", value: 'quc-latn' },
  { label: 'Kinyarwanda - Rwanda', value: 'rw-rw' },
  { label: 'KiSwahili', value: 'sw' },
  { label: 'Konkani', value: 'kok' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kyrgyz - Cyrillic', value: 'ky-cyrl' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luxembourgish', value: 'lb' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malay - Malaysia', value: 'ms-my' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori - Latin', value: 'mi-latn' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Mongolian - Cyrillic', value: 'mn-cyrl' },
  { label: 'Nepali - Nepal', value: 'ne-np' },
  { label: 'Norwegian Bokmal', value: 'nb' },
  { label: 'Norwegian Nynorsk', value: 'nn' },
  { label: 'Odia', value: 'or' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish – Poland', value: 'pl-pl' },
  { label: 'Portuguese – Brazil', value: 'pt-br' },
  { label: 'Portuguese – Portugal', value: 'pt-pt' },
  { label: 'Punjabi Gurmukhi', value: 'pa' },
  { label: 'Punjabi - Arabic', value: 'pa-arab' },
  { label: 'Quechua', value: 'quz-latn-pe' },
  { label: 'Romanian – Romania', value: 'ro-ro' },
  { label: 'Russian – Russia', value: 'ru-ru' },
  { label: 'Scottish Gaelic - Latin', value: 'gd-latn' },
  { label: 'Serbian - Cyrillic - Bosnia', value: 'sr-cyrl-ba' },
  { label: 'Serbian - Cyrillic - Serbia', value: 'sr-cyrl-rs' },
  { label: 'Serbian - Latin - Serbia', value: 'sr-latn-rs' },
  { label: 'Sesotho Sa Leboa', value: 'nso' },
  { label: 'Setswana', value: 'tn' },
  { label: 'Sindhi - Arabic', value: 'sd_arab' },
  { label: 'Sinhala', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian – Slovenia', value: 'sl-si' },
  { label: 'Spanish – Spain', value: 'es-es' },
  { label: 'Swedish – Sweden', value: 'sv-se' },
  { label: 'Tajik - Cyrillic', value: 'tg-cyrl' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar - Cyrillic', value: 'tt-cyrl' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Tigrinya Ethiopia', value: 'ti' },
  { label: 'Turkish – Turkey', value: 'tr-tr' },
  { label: 'Turkmen - Latin', value: 'tk-latn' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uyghur - Arabic', value: 'ug-arab' },
  { label: 'Uzbek - Cyrillic', value: 'uz-cyrl' },
  { label: 'Valencian - Spain', value: 'ca-es' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Wolof - Latin', value: 'wo-latn' },
  { label: 'Yoruba - Latin', value: 'yo-latn' },
];

class LanguagePlugin extends React.Component {
  onChange = (e) => {
    const {
      options: { key },
      setFields,
    } = this.props;
    setFields({ [key]: e.target.value });
  };

  render() {
    const {
      options: { key, label },
      fields,
    } = this.props;
    return (
      <Searchable searchKey={key}>
        <Select
          label={label}
          options={LANGUAGES}
          value={fields[key] || ''}
          onChange={this.onChange}
          addEmptyOption
          notSelectedLabel="- Not selected -"
        />
      </Searchable>
    );
  }
}

LanguagePlugin.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: (data) => {
    const { options } = data;
    const { isPost, key } = options;
    const res = stringParseUrl(data);
    if (!res[key] && isPost) {
      return { [key]: options.initValue };
    }
    return res;
  },
  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),
  getRequestOptions: stringGetRequestOptions,
  Component: LanguagePlugin,
};
