import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import { ExpandFormRow, FormRow, CloseButton, Input, Select, Columns, Button, Checkbox, Textarea } from '@here/ref-client-ui';
import './segmentSpeedCap.scss';
import utils from 'utils';
import Searchable from '../../../views/shared/searchUtils/Searchable';

const directions = [
    { label: '*', value: '*' },
    { label: '+', value: '+'},
    { label: '-', value: '-'}
];

class SegmentSpeedCap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeIsPost = (e) => {
    const value = utils.extractData(e);
    const { setFields, options } = this.props;
    const { key } = options;

    setFields({ [`${key}IsPost`]: value });
  }

  onChangeSegmentSpeedCap = (e) => {
    const value = utils.extractData(e);
    const { setFields, options } = this.props;
    const { key } = options;

    try{
        const sp = JSON.parse(value);
        setFields({ [key]: sp });
        this.setState({ isValid: true });
    } catch(e) {
        this.setState({ isValid: false });
    }
  }

  onChange = (i, item, e) => {
    let value = utils.extractData(e);
    const { setFields, fields, options } = this.props;
    const { key } = options;
    const newSpeedCaps = [...fields[key]];
    const newSpeedCap = newSpeedCaps[i];
    if (item === 'speed') {
        newSpeedCap.speed = +value;
    } else {
        const { segment } = newSpeedCap;
        const idDirection = segment.split('#');
        idDirection[item] = value;
        if (idDirection[1]==='') {
            newSpeedCap.segment = idDirection[0];
        } else {
            newSpeedCap.segment = idDirection.join('#');
        }
    }

    setFields({ [key]: [...newSpeedCaps.slice(0, i), newSpeedCap, ...newSpeedCaps.slice(i+1)] });
  }

  onRemoveEntry = (i) => {
    const { setFields, fields, options } = this.props;
    const { key } = options;
    const newSpeedCaps = [...fields[key]];
    newSpeedCaps.splice(i, 1);

    setFields({ [key]: newSpeedCaps });
  }

  onAddSegmentSpeedCapEntry = () => {
    const { setFields, fields, options } = this.props;
    const { key } = options;
    const newSpeedCaps = [...(fields[key]||[])];
    newSpeedCaps.push({
        segment: '',
        speed: 0
    });

    setFields({ [key]: newSpeedCaps });
  }

  getSegmentSpeedCapEle = (speedCaps, maxSpeedOnSegmentShowPost) => {
    const { isValid } = this.state;
    if (maxSpeedOnSegmentShowPost) {
        return <Textarea
            value={JSON.stringify(speedCaps)}
            onBlur={this.onChangeSegmentSpeedCap}
            isValid={isValid}
        />
    }
    const speedCapsArray = Array.isArray(speedCaps) ? speedCaps : [];
    const segmentSpeedCapEntries = speedCapsArray.map((entry, i) => {
        const { segment='', speed } = entry;
        const [ segmentId, direction ] = segment.split('#');
        return <Columns key={i}>
            <Input
                className={'rf-speedcap-segmentid'}
                label='SegmentId'
                placeholder='SegmentId'
                value={segmentId}
                onBlur={this.onChange.bind(this, i, 0)}
                blurOnEnter
            />
            <Select
                className={'rf-speedcap-direction'}
                label='Direction'
                options={directions}
                value={direction||''}
                onChange={this.onChange.bind(this, i, 1)}
                addEmptyOption
            />
            <Input
                className={'rf-speedcap-speed'}
                label='Speed'
                type='number'
                value={speed}
                onBlur={this.onChange.bind(this, i, 'speed')}
                blurOnEnter
            />
            <CloseButton className="rf-speedcap-segment-close-button" onClick={this.onRemoveEntry.bind(this, i)} />
        </Columns>
    });

    return (
        <FormRow key={'param.key'}>
            {segmentSpeedCapEntries}
            <FormRow>
            <Button
                title="Add Entry"
                onClick={this.onAddSegmentSpeedCapEntry.bind(this, 'param.key')}
            />
            </FormRow>
        </FormRow>
    )
  }

  render() {
    const {
      options: { label, key, isExpanded, onlyPost },
      fields,
    } = this.props;
    const segmentSpeedCap = fields[key] || [];
    const maxSpeedOnSegmentShowPost = !!fields[`${key}IsPost`];
    return (
    <Searchable searchKey={key}>
        <ExpandFormRow
            label={label}
            isExpanded={isExpanded}
            tooltipPlacement="right"
            className="rf-segment-speed-cap rf-grey-box"
        >
            <Checkbox
                label={onlyPost ? "Text" : "Is POST"}
                isChecked={maxSpeedOnSegmentShowPost}
                onChange={this.onChangeIsPost}
            />
            {this.getSegmentSpeedCapEle(segmentSpeedCap, maxSpeedOnSegmentShowPost)}
        </ExpandFormRow>
    </Searchable>
    );
  }
}

SegmentSpeedCap.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData = {} }) => {
    const res = {};
    const { key}  = options;
    const keyLowerCase = key.toLowerCase();
    if (parsedParams[keyLowerCase] != null) {
        const speedCaps =  parsedParams[keyLowerCase].split(',');
        res[key] = speedCaps.map(speedCap => {
            const [ segment, speed='' ] = speedCap.split(';');
            return {
                segment,
                speed: +speed.replace(/speed=/, '')
            }
        })
        res[`${key}IsPost`] = false;
        delete parsedParams[keyLowerCase];
    } else if (get(postData, key, []).length > 0) {
      res[key] = get(postData, key, []);
      res[`${key}IsPost`] = true;
    }

    return res;
  },

  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),

  getRequestOptions: (fields, options) => {
    const { key, onlyPost }  = options;
    const params = {};
    if (fields[key]) {
      if (fields[`${key}IsPost`] || onlyPost) {
        params.method = 'post';
        params.data = {
          [key]: fields[key]
        };
        return params;
      } else {
        const speedCaps = fields[key];
        const speedCapsParam = speedCaps.map(speedCap => {
            return `${speedCap.segment};speed=${speedCap.speed}`;
        })
        set(params, key, speedCapsParam.join(','));
      }
    }
    return {params};
  },

  Component: SegmentSpeedCap,
};
