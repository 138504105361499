import { get } from "lodash";

const VEHICLE_MODE_NAMES = {
  'car': 'Car',
  'bicycle': 'Bicycle',
  'kickScooter': 'Kick Scooter',
}

export function sectionTransportSummary(section) {
  const type = get(section, 'type');
  if (type === 'pedestrian') {
    return 'Walking';
  } else if (type === 'taxi') {
    const category = get(section, 'transport.category');
    const agencyName = get(section, 'agency.name');
    return `${category}/${agencyName}`;
  } else if (type === 'vehicle' || type === 'rented') {
    const mode = get(section, 'transport.mode');
    let modeName = VEHICLE_MODE_NAMES[mode] || 'Unknown';
    if (type === 'rented') {
      modeName = `Rented ${modeName}`;
    }
    return modeName;
  } else {
    const category = get(section, 'transport.category');
    const name = get(section, 'transport.name');
    const headsign = get(section, 'transport.headsign');
    return `${category}/${name}/${headsign}`;
  }
}