import React, { useEffect } from 'react';
import { Polyline, Pane, useLeaflet } from 'react-leaflet';
import PropTypes from 'prop-types';

function NoticesLeaflet({
  withInteractions,
  positions,
  color,
  isSelected,
  selectNotice,
  setHovered,
  index,
}) {
  const interactionProps = {};
  if (withInteractions) {
    interactionProps.onClick = selectNotice;
    interactionProps.onMouseOver = () => setHovered(index);
    interactionProps.onMouseOut = () => setHovered(null);
  }

  return (
    <Pane style={{ zIndex: 500 }}>
      <Polyline
        positions={positions}
        color={color}
        dashArray={isSelected ? null : [5, 20]}
        weight={10}
        {...interactionProps}
      />
    </Pane>
  );
}

NoticesLeaflet.propTypes = {
  withInteractions: PropTypes.bool.isRequired,
  positions: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectNotice: PropTypes.func.isRequired,
  setHovered: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default NoticesLeaflet;

export const useLeafletOnMove = (setSelectedShape) => {
  const { map } = useLeaflet();

  useEffect(() => {
    const hide = () => setSelectedShape(null);
    map.on('moveend', hide);
    return () => {
      map.off('moveend', hide);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
