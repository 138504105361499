
import React from 'react';
import PropTypes from 'prop-types';
import { withLeaflet, Polygon } from 'react-leaflet';
// import 'leaflet-path-drag';

class EditableLeafletPolygon extends React.Component {
  ref = React.createRef();

  componentDidUpdate(prevProps) {
    const { draggable } = this.props;
    const { leafletElement: polygon } = this.ref.current;
    if (polygon.dragging) {
      if (draggable) {
        polygon.dragging.enable();
      }
      else
        polygon.dragging.disable();
    }
  }

  onMouseMove = (move, e) => {
    let cursor = move ? 'move' : 'pointer';
    e.originalEvent.srcElement.style.cursor = cursor;
  };

  onDragEnd = () => {
    const { onChange, geometryIndex, coatPositions, positions } = this.props;
    const { leafletElement: polygon } = this.ref.current;

    const {geometry} = polygon.toGeoJSON();
    const newPositions = geometry.coordinates;

    if (coatPositions) {
      const index = Math.floor(coatPositions.length/2);
      const initialCoord = coatPositions[index];
      const coord = geometry.coordinates[0][index];
      const latDiff = initialCoord.lat - coord[1];
      const lngDiff = initialCoord.lng - coord[0];
  
      newPositions[0] = positions.map(({ lat, lng }) =>[lng - lngDiff, lat - latDiff]);
      newPositions[0].push('space');
    }

    newPositions[0].pop();
    onChange(newPositions[0]);

    document.querySelectorAll('.rf-polygon__marker-draggable'+geometryIndex).forEach(m=>{
      m.parentNode.style.display = "block";
    });

    document.querySelectorAll('.rf-corridor__polyline-draggable'+geometryIndex).forEach(m=>{
      m.style.display = "block";
  });
  };

  onDragStart = () => {
    const { geometryIndex } = this.props;

    document.querySelectorAll('.rf-polygon__marker-draggable'+geometryIndex).forEach(m=>{
      m.parentNode.style.display = "none";
    });

    document.querySelectorAll('.rf-corridor__polyline-draggable'+geometryIndex).forEach(m=>{
      m.style.display = "none";
    });
  };

  addException = (type, e) => {
    const { onContextMenu, map } = this.props;

    try {
      e.latlng = e.relatedTarget.getLatLng();
    } catch (e) {
      // skip exception
    }
    const originalEvent = e;
    const { lat, lng } = e.latlng;
    const { x, y } = e.layerPoint;

    onContextMenu(type, e, {
      latLng: { lat, lng },
      xy: { x, y },
      xyToLatLng: map.layerPointToLatLng.bind(map),
      originalEvent,
      zoom: map.getZoom(),
    });
  }

  remove = (type, e) => {
    const { onContextMenu } = this.props;
    onContextMenu(type, e);
  }

  render() {
    const { positions, contextMenu, noException, skipCorridor, color, fillColor, fillOpacity, coatPositions, draggable, resizable, showCoatStroke = false, isException } = this.props;
    const contextWidth = 165;
    let contextmenuItems = !noException ? [
      {
        text: `Add Exception Rectangle`,
        index: 0,
        callback: this.addException.bind(this, 'bbox'),
      },
      {
        text: `Add Exception Polygon`,
        index: 1,
        callback: this.addException.bind(this, 'polygon'),
      }
    ] : [];
    if (!noException && !skipCorridor) {
      contextmenuItems.push(
        {
          text: `Add Exception Corridor`,
          index: 2,
          callback: this.addException.bind(this, 'corridor'),
        })
    }
    contextmenuItems.push({
      text: `Remove${isException ? ' Exception' : ''}`,
      index: contextmenuItems.length,
      callback: this.remove.bind(this, 'remove'),
    })
    const props = {
      onmousemove: this.onMouseMove.bind(this, draggable || resizable)
    };
    if (color) {
      props.color = color;
    }

    if (fillColor) {
      props.fillColor = fillColor;
    }

    if (fillOpacity) {
      props.fillOpacity = fillOpacity;
    }
    
    if (draggable || resizable) {
      props.ondragend = this.onDragEnd;
      props.ondragstart = this.onDragStart;
    }
    
    return (
      coatPositions ? 
      <Polygon
        positions={coatPositions} 
        contextmenu={contextMenu}
        contextWidth={contextWidth}
        contextmenuItems={contextmenuItems}
        contextmenuInheritItems={false}
        stroke={showCoatStroke}
        draggable={true} // workaround that not enable draggable when this is set to false 
        ref={this.ref}
        {...{ ...props }}
      /> :
      <Polygon
        positions={positions} 
        contextmenu={contextMenu}
        contextWidth={contextWidth}
        contextmenuItems={contextmenuItems}
        contextmenuInheritItems={false}
        draggable={true} // workaround that not enable draggable when this is set to false 
        ref={this.ref}
        {...{ ...props }}
      />
    );
  }
}

EditableLeafletPolygon.propTypes = {
  leaflet: PropTypes.object.isRequired,
  positions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  geometryIndex: PropTypes.number,
};

export default withLeaflet(EditableLeafletPolygon);