import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow, Checkbox, Input } from '@here/ref-client-ui';
import CustomToggleButton from 'shared/toggleButton';
import './rorContainer.scss';

export default class RoRForm extends Component {
  render() {
    let { rangeOnRoute, onChange, onCheckboxChange, isActive, onToggle } =
      this.props;
    return (
      <div className="rf-map__tooltip rf-map__ror-module rf-map__ror-module_electric">
        <h2>Range on Route</h2>
        <FormRow>
          <CustomToggleButton checked={isActive} onChange={onToggle} />
        </FormRow>

        <FormRow>
          <Checkbox
            label="Max Charge"
            isChecked={rangeOnRoute.isMaxChargeActive}
            onChange={onCheckboxChange.bind(this, 'isMaxChargeActive')}
          />
          <Input
            className="rf-clearfix"
            placeholder="Max Charge"
            disabled={!rangeOnRoute.isMaxChargeActive}
            isValid
            value={rangeOnRoute.maxCharge}
            onChange={onChange.bind(this, 'maxCharge')}
            type="number"
            debounce
          />
        </FormRow>

        <FormRow>
          <Checkbox
            label="Initial Charge"
            isChecked={rangeOnRoute.isInitialChargeActive}
            onChange={onCheckboxChange.bind(this, 'isInitialChargeActive')}
          />
          <Input
            className="rf-clearfix"
            placeholder="Initial Charge"
            disabled={!rangeOnRoute.isInitialChargeActive}
            isValid
            value={rangeOnRoute.initialCharge}
            onChange={onChange.bind(this, 'initialCharge')}
            type="number"
            debounce
          />
          <span>
            {(
              (rangeOnRoute.initialCharge / rangeOnRoute.maxCharge) *
              100
            ).toFixed(1)}
            %
          </span>
        </FormRow>

        <FormRow>
          <Checkbox
            label="Min Charge At Stop"
            isChecked={rangeOnRoute.isMinChargeAtStopActive}
            onChange={onCheckboxChange.bind(this, 'isMinChargeAtStopActive')}
          />
          <Input
            className="rf-clearfix"
            placeholder="Min Charge At Stop"
            disabled={!rangeOnRoute.isMinChargeAtStopActive}
            isValid
            value={rangeOnRoute.minChargeAtChargingStation}
            onChange={onChange.bind(this, 'minChargeAtChargingStation')}
            type="number"
            debounce
          />
          <span>
            {(
              (rangeOnRoute.minChargeAtChargingStation /
                rangeOnRoute.maxCharge) *
              100
            ).toFixed(1)}
            %
          </span>
        </FormRow>

        <FormRow>
          <Checkbox
            label="Min Charge At First Stop"
            isChecked={rangeOnRoute.isMinChargeAtFirstStopActive}
            onChange={onCheckboxChange.bind(this, 'isMinChargeAtFirstStopActive')}
          />
          <Input
            className="rf-clearfix"
            placeholder="Min Charge At First Stop"
            disabled={!rangeOnRoute.isMinChargeAtFirstStopActive}
            isValid
            value={rangeOnRoute.minChargeAtFirstChargingStation}
            onChange={onChange.bind(this, 'minChargeAtFirstChargingStation')}
            type="number"
            debounce
          />
          <span>
            {(
              (rangeOnRoute.minChargeAtFirstChargingStation /
                rangeOnRoute.maxCharge) *
              100
            ).toFixed(1)}
            %
          </span>
        </FormRow>

        <FormRow>
          <Checkbox
            label="Allow charging"
            isChecked={rangeOnRoute.isChargingAllowed}
            onChange={onCheckboxChange.bind(this, 'isChargingAllowed')}
          />
        </FormRow>
      </div>
    );
  }
}

RoRForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  rangeOnRoute: PropTypes.object.isRequired,
};
