import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { getFields, parseParams, getUrlParamsMap } from 'utils/url/parser/parseHref';
import { getConfig } from '../../state/config';
import { getTabs } from '../../state/tabs';
import { connect } from 'react-redux';
import { addSettings } from '../../state/settings/custom/reducer';
import { restoreFromUrl } from '../../state/actions';
import { fetchData } from '../../state/tabs/tab/response/actions';
import { removeTab, addTab } from '../../state/tabs/actions';
import { push } from 'connected-react-router';
import { setTabColor } from '../../state/tabs/tab/tabColorPalette/actions';
import { setIsTabActive } from '../../state/tabs/tab/isActive/actions';
import { setSelectedTab } from '../../state/selectedTab/actions';
import { getAllSettings } from '../../state/settings';
import { set as setNotification } from 'state/notification/actions';

class RestoreFromDeepUrl extends React.Component {
  componentDidMount() {
    this.restore();
  }

  componentDidUpdate(prevProps) {
    const { urlHash } = this.props;
    if (urlHash && urlHash !== prevProps.urlHash) {
      this.restore();
    }
  }

  restore() {
    const {
      urlHash,
      tabs,
      addTab,
      removeTab,
      restoreFromUrl,
      setSelectedTab,
      request,
      setTabColor,
      setIsTabActive,
      push,
      configs,
      allSettings,
      addSettings,
      setNotification,
    } = this.props;

    try {
      const decodedHash = decodeURIComponent(urlHash.slice(1));
      if (!decodedHash || urlHash.startsWith('#url')) {
        return;
      }
      const tabsData = JSON.parse(decodedHash);

      const urls = tabsData.map((tab) => decodeURIComponent(tab.url));
      let lengthDiff = urls.length - tabs.length;
      if (lengthDiff >= 0) {
        range(0, lengthDiff).forEach(() => addTab({ title: tabs[0].title }));
      } else {
        range(0, Math.abs(lengthDiff)).forEach(() => removeTab(0));
      }
      let selectedTab = 0;
      tabsData.forEach((tabData, index) => {
        const {
          tabTitle,
          title,
          preset,
          isActive,
          tabColorPalette,
          isSelected,
          settings,
          postData,
          rcParams,
          fieldParams
        } = tabData;
        // We are masking default apikey for apikey rotation functionality which happens in ref-client-v8 build process
        // window.APIKEY and window.APIKEY2 are initialized in ref-client-v8 project index.html file
        const url = decodeURIComponent(tabData.url).replaceAll(
          'DEFAULT_API_KEY',
          window.APIKEY
        );
        if (settings) {
          addSettings(title, preset, settings, index);
        }
        if (isSelected) {
          selectedTab = index;
        }
        const inputs = configs[title].inputs;
        const [hostAndPath, paramsStr = ''] = url.split('?');
        const parsedParams = paramsStr ? parseParams(paramsStr) : {};
        const urlParamsMap = paramsStr ? getUrlParamsMap(paramsStr) : {};
        const fields = getFields(
          settings || allSettings[title][preset],
          inputs,
          parsedParams,
          urlParamsMap,
          hostAndPath,
          postData,
          rcParams
        );
        const params = url === '' ? {...fields, ...fieldParams} : fields;
        restoreFromUrl(title, preset, params, tabTitle, index);
        if (url !== '') request(index);
        setTabColor(index, tabColorPalette);
        setIsTabActive(index, isActive);
      });
      setSelectedTab(selectedTab);
      push('');
    } catch (e) {
      setNotification({
        title: 'Restore failure',
        message: `Failed to restore state from url: ${e.message}`,
        impact: 'negative',
        autoDismiss: 0,
      });
      push('');
    }
  }

  render() {
    return null;
  }
}

RestoreFromDeepUrl.propTypes = {
  request: PropTypes.func.isRequired,
  setTabColor: PropTypes.func.isRequired,
  setIsTabActive: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  removeTab: PropTypes.func.isRequired,
  restoreFromUrl: PropTypes.func.isRequired,
  addSettings: PropTypes.func.isRequired,
  urlHash: PropTypes.string.isRequired,
  configs: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  setNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // urlHash: state.routing.locationBeforeTransitions.hash,
  urlHash: state.router.location.hash,
  tabs: getTabs(state),
  configs: getConfig(state),
  allSettings: getAllSettings(state),
});

const mapDispatchToProps = {
  request: fetchData,
  restoreFromUrl,
  removeTab,
  addTab,
  push,
  setTabColor,
  setIsTabActive,
  setSelectedTab,
  addSettings,
  setNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreFromDeepUrl);
