import React from 'react';
import PropTypes from 'prop-types';
import HerePolyline from 'shared/hereMap/HerePolyline';

class HereMatrixRoute extends React.Component {
  render() {
    const { positions, color, weight, onmouseover, onclick, onmouseout, zoom } =
      this.props;
    const style = {
      strokeColor: color,
      lineWidth: weight,
      lineDash: [2, 10],
      lineJoin: 'round',
    };
    const offset = 1.8 / Math.pow(2, zoom-1);
    return (
      <HerePolyline
        latLngs={positions}
        options={{ style, offset }}
        onPointerEnter={onmouseover}
        onPointerLeave={onmouseout}
        onTap={onclick}
      />
    );
  }
}

HereMatrixRoute.propTypes = {
  onmouseout: PropTypes.func,
  onmouseover: PropTypes.func,
  onclick: PropTypes.func,
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
};

export default HereMatrixRoute;
