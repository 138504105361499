import React, { useContext } from 'react';

export const HereMapContext = React.createContext({ map: null });

export const withHereMap = (Component) => {
  const WithHereComponent = (props, ref) => (
    <HereMapContext.Consumer>
      {(context) => (
        <Component
          {...props}
          map={context.map}
          group={context.group}
          ref={ref}
        />
      )}
    </HereMapContext.Consumer>
  );

  WithHereComponent.displayName =
    Component.displayName || Component.name || 'Component';

  return React.forwardRef(WithHereComponent);
};

export const useHereMap = () => useContext(HereMapContext);
