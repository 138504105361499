import React, { Component } from 'react';
import PropTypes from 'prop-types';

import utils from 'utils';
import { CloseButton, AutosizeInput, Loader } from '@here/ref-client-ui';
import { ShowSolid, HideSolid } from '@here/ref-client-ui/lib/icons';
import TabActions from './TabActions';

export class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  onSetTitle = (e) => {
    const title = utils.extractData(e);
    if (title !== '') {
      this.props.setTitle(title);
    }
    this.setState({ isEdit: false });
  };

  onEdit = (e) => {
    e.stopPropagation();
    this.setState({ isEdit: true });
  };

  getIsActive(isActive) {
    const { setActive, colorPalette } = this.props;
    if (isActive) {
      return (
        <div
          className="rf-nav-tabs__tab__is-active"
          onClick={setActive.bind(this, false)}
        >
          <ShowSolid style={{ fill: colorPalette.primary }} />
        </div>
      );
    }
    return (
      <div
        className="rf-nav-tabs__tab__is-active"
        onClick={setActive.bind(this, true)}
      >
        <HideSolid />
      </div>
    );
  }

  getTitle() {
    const { title, isSelected, colorPalette, duplicate, setTabColor } =
      this.props;
    let styles = isSelected ? { color: colorPalette.primary } : {};
    let titleEl;
    if (this.state.isEdit) {
      titleEl = (
        <AutosizeInput
          value={title}
          onBlur={this.onSetTitle}
          className="rf-nav-tabs__tab__title-input"
        />
      );
    } else {
      titleEl = (
        <span className="rf-nav-tabs__tab__caption__label" style={styles}>
          {title}
        </span>
      );
    }
    return (
      <span className="rf-nav-tabs__tab__caption">
        {titleEl}&nbsp;
        <TabActions
          onEdit={this.onEdit}
          onDuplicate={duplicate}
          onSetTabColor={setTabColor}
          styles={styles}
          colorPalette={colorPalette}
        />
      </span>
    );
  }

  getCloseButton() {
    const { isSelected, colorPalette } = this.props;
    let styles = isSelected ? { color: colorPalette.primary } : {};
    return <CloseButton onClick={this.props.close} style={styles} />;
  }

  render() {
    const { isSelected, isActive, showCloseButton, isLoading } = this.props;
    return (
      <div>
        {isLoading ? <Loader /> : this.getIsActive(isActive, isSelected)}
        {this.getTitle()}
        {showCloseButton && this.getCloseButton()}
      </div>
    );
  }
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  colorPalette: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
  setTabColor: PropTypes.func.isRequired,
};

export default Tab;
