import * as tabTypes from '../../constants';
import tabColors from 'config/tabColors';

const tabColorPaletteReducer = (state = tabColors[0], action) => {
  switch (action.type) {
    case tabTypes.ADD_TAB:
    case tabTypes.SET_COLOR:
    case tabTypes.DUPLICATE_TAB:
      return action.tabData.tabColorPalette;
    default:
      return state;
  }
};

export default tabColorPaletteReducer;

export const getColorPalette = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return state.tabs[selectedTab].tabColorPalette;
};
