import { TILE_LIBRARY_TYPES } from 'state/map/tiles/constants';
import Rectangle from './Rectangle';
import HereRect from '../rectangleList/HereRect';
import EditableRectangle from 'shared/editableRectangle';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Rectangle(EditableRectangle),
  [TILE_LIBRARY_TYPES.JSLA]: Rectangle(HereRect),
};
