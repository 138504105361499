import React from 'react';
import { Circle as LeafletCircle, withLeaflet } from 'react-leaflet';
import PropTypes from 'prop-types';

class EditableCircle extends React.Component {
  ref = React.createRef();

  onMouseDown = (e) => {
    if (e.originalEvent.which !== 1) {
      return;
    }
    const {
      leaflet: { map },
    } = this.props;
    map.dragging.disable();
    map.on('mousemove', this.updateCenter);
  };

  onMouseUp = (e) => {
    const {
      leaflet: { map },
      onChange,
    } = this.props;
    const { target: circle } = e;
    const center = circle.getLatLng();
    map.dragging.enable();
    map.off('mousemove', this.updateCenter);
    if (!this.mouseStart) {
      return;
    }
    this.dragging = false;
    this.mouseStart = null;
    onChange({
      center,
      radius: circle.getRadius(),
    });
  };

  onMouseMove = (e) => {
    if (this.dragging) {
      return;
    }
    const {
      leaflet: { map },
      draggable,
      resizable,
    } = this.props;
    const { target: circle } = e;

    const northLat = circle.getBounds().getNorth();
    const center = circle.getLatLng();
    const centerPoint = map.latLngToLayerPoint(center);
    const topNorthPoint = map.latLngToLayerPoint([northLat, center.lng]);
    const radiusPx = centerPoint.distanceTo(topNorthPoint);
    const distToMousePx = centerPoint.distanceTo(e.layerPoint);
    const { lat, lng } = e.latlng;

    this.isResize = resizable && radiusPx - distToMousePx <= 15;
    if (this.isResize) {
      let resizeCursor = 'nesw-resize';
      if (
        (lat > center.lat && lng < center.lng) ||
        (lat < center.lat && lng > center.lng)
      ) {
        resizeCursor = 'nwse-resize';
      }
      e.originalEvent.srcElement.style.cursor = resizeCursor;
    } else if (draggable) {
      e.originalEvent.srcElement.style.cursor = 'move';
    } else {
      e.originalEvent.srcElement.style.cursor = 'auto';
    }
  };

  updateCenter = (e) => {
    const {
      center: { lat, lng },
      draggable,
    } = this.props;
    const { leafletElement: circle } = this.ref.current;

    if (!this.dragging) {
      this.dragging = true;
      this.mouseStart = e.latlng;
    }

    if (this.isResize) {
      circle.setRadius(e.latlng.distanceTo([lat, lng]));
    } else if (draggable) {
      const deltaLat = this.mouseStart.lat - e.latlng.lat;
      const deltaLon = this.mouseStart.lng - e.latlng.lng;
      circle.setLatLng([lat - deltaLat, lng - deltaLon]);
    }
  };

  componentWillUnmount() {
    const {
      leaflet: { map },
    } = this.props;
    map.dragging.enable();
    map.off('mousemove', this.updateCenter);
  }

  render() {
    const { radius, center, color, draggable, resizable } = this.props;

    const props = {};
    if (color) {
      props.color = color;
    }

    if (draggable || resizable) {
      props.onmousedown = this.onMouseDown;
      props.onmouseup = this.onMouseUp;
      props.onmousemove = this.onMouseMove;
      props.onmouseout = this.onMouseOut;
    }

    props.fillOpacity = 0.5;
    props.fillColor = color;

    return (
      <LeafletCircle
        ref={this.ref}
        radius={radius}
        center={center}
        {...props}
      />
    );
  }
}

EditableCircle.propTypes = {
  leaflet: PropTypes.object.isRequired,
  center: PropTypes.object.isRequired,
  radius: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  color: PropTypes.string,
};

export default withLeaflet(EditableCircle);
