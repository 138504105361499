import axios from 'axios';

const CACHE = {};

const inProgress = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (url) {
  if (inProgress[url]) {
    return inProgress[url];
  }
  const promise = new Promise((resolve, reject) => {
    if (CACHE[url]) {
      resolve(CACHE[url]);
      delete inProgress[url];
      return;
    }

    axios
      .get(url, { responseType: 'text' })
      .then((res) => {
        const { data } = res;
        const publicLayers = data.filter(layer=>layer.layerType==='public');
        let t = '';
        const filteredLayers = publicLayers.filter(layer=>{
            const { name } = layer;
            const first = name.substring(0, name.length-1);
            let keep = true;
            
            if(t === first) {
                keep = false;
            }

            t = first;
            return keep;
        })

        const layers = filteredLayers.map(layer=>{
            const { name } = layer;
            const first = name.substring(0, name.length-1);
            const last = name[name.length - 1];

            let value;
            if ( last === '0' || last === '1' ) {
                value =  first + 'n(*)';
            } else {
                value = name + '(*)';
            }

            return { value, label: value }
        })
        CACHE[url] = layers;
        resolve(layers);
        delete inProgress[url];
      })
      .catch((err) => {
        reject(err);
      });
  });
  inProgress[url] = promise;

  return promise;
}
