import RouteDateField from './RouteDateField';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData = {} }) => {
    const { isPost, departureKey, arrivalKey } = options;
    let key = '';

    let value = '';
    if (isPost) {
      if (postData.departureTime)
        key = 'departureTime';
      else if (postData.arrivalTime)
        key = 'arrivalTime';
      else if (postData[departureKey])
        key = departureKey;
      else if (postData[arrivalKey])
        key = arrivalKey;

      value = postData[key];
        
    } else if (parsedParams.departuretime) {
      key = 'departureTime';
      value = parsedParams.departuretime;
      delete parsedParams.departuretime;
    } else if (parsedParams[departureKey]) {
      key = departureKey;
      value = parsedParams[key];
      delete parsedParams[key];
    } else if (parsedParams.arrivaltime) {
      key = 'arrivalTime';
      value = parsedParams.arrivaltime;
      delete parsedParams.arrivaltime;
    } else if (parsedParams[arrivalKey]) {
      key = arrivalKey;
      value = parsedParams[key];
      delete parsedParams[key];
    }

    return { routeTime: { key, value } };
  },
  defaultState: {
    routeTime: {
      key: 'now',
      value: '',
    },
  },
  getRequestOptions: (fields, options) => {
    const {
      routeTime: { key, value },
    } = fields;
    const { isPost, departureKey, arrivalKey } = options;
    let resValue = {};
    if (key && key !== 'now') {
      let resKey = key;
      if (departureKey && key === 'departureTime')
        resKey = departureKey;
      else if (arrivalKey && key === 'arrivalTime')
        resKey = arrivalKey;
      resValue = { [resKey]: value };
    }
    if (isPost) {
      return {
        method: 'post',
        data: resValue,
      };
    } else {
      return { params: resValue };
    }
  },
  Component: RouteDateField,
};
