import jsonp from 'jsonp';
import getGeocoderSettings from 'config/geocoder';
import { objectToQueryString } from 'utils/url';
import fetch from 'utils/fetch';
import { get } from 'lodash';

export function reverseGeocode(coords = '', options) {
  const settings = getGeocoderSettings('reversegeocode');
  const {
    url: { useSSL, host, path, source },
    app_id,
    app_code,
  } = settings;
  const params = {
    app_id,
    app_code,
    mode: 'retrieveAddresses',
    maxresults: 1,
    prox: coords,
    ...options,
  };
  const protocol = useSSL ? 'https' : 'http';
  const queryString = objectToQueryString(params);
  const reverseUrl = `${protocol}://${host}${path}${source}?${queryString}`;

  return fetch(reverseUrl);
}

export function fetchAddress(coords) {
  const settings = getGeocoderSettings('reversegeocode');
  const {
    url: { useSSL, host, path, source },
    app_id,
    app_code,
  } = settings;
  const params = {
    app_id,
    app_code,
    mode: 'retrieveAddresses',
    maxresults: 1,
    prox: coords,
  };
  const protocol = useSSL ? 'https' : 'http';
  const queryString = objectToQueryString(params);
  const requestUrl = `${protocol}://${host}${path}${source}?${queryString}`;

  return new Promise((resolve, reject) => {
    jsonp(requestUrl, { param: 'jsoncallback' }, (err, data) => {
      if (err) {
        reject(new Error('Problem fetching the address'));
      } else {
        const addressPath = 'Response.View[0].Result[0].Location.Address.Label';
        const payload = get(data, addressPath);
        if (payload) {
          resolve(payload);
        } else {
          reject(new Error('No address found for the given coordinates'));
        }
      }
    });
  });
}
