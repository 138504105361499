import yamlLoad from './checkboxes/yamlLoad';
import get from 'lodash/get';

export const getOpenapiInfo = (url, keys, customizations, settings) =>
  new Promise((resolve) => {
    yamlLoad(url, customizations, settings).then((data) => {
      let res = '';
      keys.forEach(({ key, path }) => {
        res += `${key} - ${get(data, path)}\n`;
      });
      resolve(res);
    });
  });

export const getOpenapiUrl = (propsUrl, urlRegex, urlSuffix) => {
  const regexp = new RegExp(urlRegex);
  const matchedUrl = (propsUrl || '').match(regexp);
  if (matchedUrl) {
    return `${matchedUrl[0]}${urlSuffix}`;
  }
  return '';
};
