import React from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from '../../shared/outsideClickHandler';

class Module extends React.Component {
  state = {
    isDropdownOpen: false,
    dropdownStyles: { top: 0, left: 0 },
  };

  constructor(props) {
    super(props);
    this.actionsIconRef = React.createRef();
  }

  toggleDropdown = (e) => {
    e.stopPropagation();
    if (this.state.isDropdownOpen) {
      this.hideDropdown();
    } else {
      const actionsIconRect =
        this.actionsIconRef.current.getBoundingClientRect();
      this.setState({
        isDropdownOpen: true,
        dropdownStyles: {
          top: actionsIconRect.top + 20,
          left: actionsIconRect.left,
        },
      });
    }
  };

  hideDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  onAction = (action) => (e) => {
    e.stopPropagation();
    action();
    this.hideDropdown();
  };

  onDuplicate = () => {
    const { duplicateConfig, label } = this.props;

    duplicateConfig(label);
  };

  onEdit = () => {
    const { editConfig, label } = this.props;

    editConfig(label);
  };

  onDelete = () => {
    const { deleteConfig, label } = this.props;
    deleteConfig(label);
  };

  getDropdownEl = () => {
    if (!this.state.isDropdownOpen) {
      return null;
    }

    const { editConfig, deleteConfig, setDefaultModule } = this.props;

    return (
      <div
        className="rf-new-tab__group__item__actions-dropdown"
        style={this.state.dropdownStyles}
      >
        <div
          className="rf-new-tab__group__item__actions-dropdown__item"
          onClick={this.onAction(setDefaultModule)}
        >
          Set default
        </div>
        <div
          className="rf-new-tab__group__item__actions-dropdown__item"
          onClick={this.onAction(this.onDuplicate)}
        >
          Duplicate
        </div>
        {editConfig && (
          <div
            className="rf-new-tab__group__item__actions-dropdown__item"
            onClick={this.onAction(this.onEdit)}
          >
            Edit Configs
          </div>
        )}
        {deleteConfig && (
          <div
            className="rf-new-tab__group__item__actions-dropdown__item"
            onClick={this.onAction(this.onDelete)}
          >
            Delete Configs
          </div>
        )}
      </div>
    );
  };

  render() {
    const { label, addTab } = this.props;
    return (
      <OutsideClickHandler outsideClick={this.hideDropdown}>
        <div className="rf-new-tab__group__item" onClick={addTab}>
          <div className="rf-new-tab__group__item__label">{label}</div>
          <div
            className="rf-new-tab__group__item__actions-btn"
            onClick={this.toggleDropdown}
          >
            <i className="fa fa-ellipsis-v" ref={this.actionsIconRef} />
          </div>
          {this.getDropdownEl()}
        </div>
      </OutsideClickHandler>
    );
  }
}

Module.propTypes = {
  label: PropTypes.string.isRequired,
  addTab: PropTypes.func.isRequired,
  isDefault: PropTypes.bool,
  duplicateConfig: PropTypes.func.isRequired,
  setDefaultModule: PropTypes.func.isRequired,
  editConfig: PropTypes.func,
  deleteConfig: PropTypes.func,
};

export default Module;
