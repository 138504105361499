// eslint-disable-next-line import/no-anonymous-default-export
export default function (fields, options) {
  const { key, isPost, isPathParam } = options;
  if (isPathParam) {
    return {
      pathParams: { [key]: fields[key] },
    };
  }
  if (isPost) {
    if (fields[key]) {
      return {
        method: 'post',
        data: {
          [key]: fields[key],
        },
      };
    }

    return {};
  }
  const params = fields[key] ? { [key]: fields[key] } : {};
  return { params };
}
