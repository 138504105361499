import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import DistanceMeasurement from './DistanceMeasurement';
import HereLine from './HereLine';
import LeafletLine from './LeafletLine';
import LeafletPolygonMarker from '../polygonList/LeafletPolygonMarker';
import HerePolygonMarker from '../polygonList/HerePolygonMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: DistanceMeasurement(LeafletPolygonMarker, LeafletLine, TILE_LIBRARY_TYPES.LEAFLET),
  [TILE_LIBRARY_TYPES.JSLA]: DistanceMeasurement(HerePolygonMarker, HereLine, TILE_LIBRARY_TYPES.JSLA),
};
