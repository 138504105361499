import React, { Component } from 'react';
import classnames from 'classnames';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import './tabSortable.scss';


const SortableItem = SortableElement((props) => props.children);
const Sortable = SortableContainer((props) => props.children);

class TabSortable extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    const {
      tabs
    } = this.props
    this.setState({
      items: tabs
    })
  }

  getTabs() {
    let { tabs, selectedTab, setTabTitle, duplicateTab, setTabColor, onTabClick, onTabRemove, onTabToggle, Element } = this.props;

    return (
      <div>
        {tabs.map((tab, index) => {
          const isSelected = selectedTab === index;
          let classes = classnames('rf-nav-tabs__tab', {
            'rf-nav-tabs__tab_selected': isSelected,
          });
          let styles =
            tab.isActive || isSelected
              ? {
                  borderColor: tab.tabColorPalette.primary,
                }
              : {};
          
          return (
            <SortableItem key={`item-${index}`} className={classes} index={index}>
              <div onClick={onTabClick.bind(this, index)} className={`rf-nav-tabs__tab ${isSelected?'rf-nav-tabs__tab_selected ':''}ui-sortable-item false`}>
                <div className="rf-nav-tabs__tab__inner" style={styles}>
                  <Element
                    title={tab.tabTitle}
                    isSelected={isSelected}
                    isActive={tab.isActive}
                    close={onTabRemove.bind(this, index)}
                    setTitle={setTabTitle.bind(this, index)}
                    setActive={onTabToggle.bind(this, index)}
                    showCloseButton={tabs.length > 1}
                    duplicate={duplicateTab.bind(this, index)}
                    isLoading={tab.isLoading}
                    setTabColor={setTabColor}
                    colorPalette={tab.tabColorPalette}
                  />
                </div>
              </div>
            </SortableItem>
          );
        })}
      </div>
    )
    
  }

  render() {
    const {
      onSortEnd,
      tabsRef,
    } = this.props

    return (
    <div ref={tabsRef} className="ui-sortable rf-nav-tabs__sortable">
      <Sortable
        helperClass="tab"
        axis="x"
        lockAxis="x"
        transitionDuration={0}
        distance={10}
        onSortEnd={onSortEnd}
      >
        {this.getTabs()}
      </Sortable>
    </div>
    );
  }
}

export default TabSortable;
