import numericValidation from './numericValidation';
import { isEmpty } from 'lodash';

export function isCoordsValid(coords = '', delimeter = ';', allowEmpty = true) {
  return (coords || '')
    .split(delimeter)
    .every(allowEmpty ? isCoordValid : isCoordValidAndDefined);
}

export function isCoordValidAndDefined(latlon) {
  return latlon !== '' && isCoordValid(latlon);
}

export function isCoordValid(latLon) {
  if (latLon === undefined) {
    return false;
  }

  if (latLon.length === 0) {
    return true;
  }

  let isValid = false;
  let [lat, lng, ...other] = latLon.split(',');
  let [altitude] = other;

  if (other.length > 1 || !validateAltitude(altitude)) {
    return false;
  }

  if (lat && lat.length > 0 && lng && lng.length > 0) {
    isValid = validateLat(lat) && validateLon(lng);
  }

  return isValid;
}

export function validateLat(lat) {
  return lat <= 90 && lat >= -90;
}

export function validateLon(lon) {
  return lon <= 180 && lon >= -180;
}

export function validateAltitude(altitude) {
  if (altitude === undefined) {
    return true;
  }
  return !isEmpty(altitude) && numericValidation(altitude);
}
