import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormRow, Select, Tree } from '@here/ref-client-ui';
import { get } from 'lodash';

function getSupplyType(supplyType) {
  if (supplyType === 'CURRENT_TYPE_DC') {
    return 'dc';
  } else if (supplyType === 'CURRENT_TYPE_AC_SINGLE') {
    return 'acSingle';
  } else if (supplyType === 'CURRENT_TYPE_AC_THREE') {
    return 'acThree';
  } else {
    return '';
  }
}

function setInputStyle(tabsDetail) {
  const input = document.querySelector('.route-waypoint select');
  if (input) {
    if (tabsDetail[input.value]) {
      input.style.setProperty('border-top', `0.4rem solid ${tabsDetail[input.value].tab.tabColorPalette.primary}`);
    } else {
      input.style.setProperty('border-top', '');
    }
  }
}

// function ChargingStationsResult(param) {
class ChargingStationsResult extends React.Component {
  tabsDetail = {};
  tabOptions = [{label: 'New Tab', value: 'new'}];

  componentDidUpdate() {
    setInputStyle(this.tabsDetail);
  }

  componentDidMount() {
    setInputStyle(this.tabsDetail);
  }

  onChangeTab = ({ target: { value } }) => {
    const { result = {}, setResultState } = this.props;
    const { state = {} } = result;
    const selectedTab = get(state, 'selectedTab', {});
    selectedTab.routeTab = value;
    setResultState({ selectedTab });
  }

  onChangePort = ({ target: { value } }) => {
    const { result = {}, setResultState } = this.props;
    const { state = {} } = result;
    const selectedPort = get(state, 'selectedPort', {});
    selectedPort.portId = value;
    setResultState({ selectedPort });
  }

  addVia = (ports, portId, position, routeTab) => {
    const { addTab, setFields, setSelectedTab } = this.props;
    const { current, voltage, current_type, power } = ports[parseInt(portId)];
    const { longitude, latitude } = position;
    const { tab } = this.tabsDetail[routeTab] || {};
    const { fields } = tab || {};
    const { via } = fields || {};
    const supplyType = getSupplyType(current_type);
    const newVia = {
      coords:  `${latitude},${longitude}`, 
      advanced: {
        charging: `(power=${power};current=${current};voltage=${voltage};supplyType=${supplyType};minDuration=900;maxDuration=3600)`
      }
    };
    const waypointFields = {
      via: [ ...(via || []), newVia]
    };

    // selected 'new tab' or selected tab is removed
    if (routeTab === 'new' || !this.tabsDetail.hasOwnProperty(routeTab)) {
      addTab({ title: 'Routing V8', fields: waypointFields });
    } else {
      const { index } = this.tabsDetail[routeTab];
      setFields(waypointFields, parseInt(index));
      setSelectedTab(parseInt(index));
    }
  }

  addExclude = (identifier, routeTab) => {
    const key = 'ev[excludeChargingStations]';
    const { addTab, setFields, setSelectedTab } = this.props;
    const { tab } = this.tabsDetail[routeTab] || {};
    const { fields } = tab || {};

    // selected 'new tab' or selected tab is removed
    if (routeTab === 'new' || !this.tabsDetail.hasOwnProperty(routeTab)) {
      const newExcludes = {
        [key]: identifier,
        evEnabled: true
      };
      addTab({ title: 'Routing V8', fields: newExcludes });
    } else {
      const excludes = (fields || {})[key].split(',').filter(id => id);
      if ( excludes.indexOf(identifier) === -1 ) {
        excludes.push(identifier);
      }
      const excludesFields = {
        [key]: excludes.join(',')
      };
      const { index } = this.tabsDetail[routeTab];
      setFields(excludesFields, parseInt(index));
      setSelectedTab(parseInt(index));
    }
  }

  render(){
    const { result = {}, tabs } = this.props;
    const { state = {} } = result;
    const { selectedStation, selectedTab = {}, selectedPort = {} } = state;

    if (!selectedStation) {
      return <h4>Please select charging station</h4>;
    }

    const { data = [] } = selectedStation;

    let { routeTab = 'new' } = selectedTab;
    let routingTabExists = false;
    let { portId = '0' } = selectedPort;
    const charging_station_ports = data.map(d => d.charging_station_port || []);
    const { position, identifier } = data[0];
    const ports = charging_station_ports.flat();


    const routingTabs = tabs.map((tab, index) => {
      if (tab.title === 'Routing V8') {
        this.tabsDetail[tab.id] = {tab, index};
        if ( ''+tab.id === routeTab ) {
          routingTabExists = true;
        }
        return {label: tab.tabTitle, value: tab.id+''} 
      } else return null
    }).filter(item => item);
    routeTab = routingTabExists ? routeTab : 'new';

    const chargingPorts = ports.map(
      (port, index) => {
        return {label: `Charging port ${index+1} (Voltage: ${port.voltage})`, value: index+''}
      }
    );
    portId = portId >= chargingPorts.length ? '0' : portId;
    return (
      <>
        <h4>Selected charging station:</h4>
        <Tree data={data} shouldExpandNode={() => true} />
        <div>
          <h4>Add Charging station to Routing:</h4>
          {ports.length > 1 && <FormRow className="charging-station-port">
            <Select
              options={chargingPorts}
              value={portId}
              onChange={this.onChangePort}
            />
          </FormRow> }
          <FormRow className="route-waypoint">
            <Select
              options={this.tabOptions.concat(routingTabs)}
              value={routeTab}
              onChange={this.onChangeTab}
            />
          </FormRow>
          <Button className = "routeto" onClick={() => this.addVia(ports, portId, position, routeTab)} title="Add to Via point" />
          <Button className = "routeto" onClick={() => this.addExclude(identifier, routeTab)} title="Exclude in Routing" />
        </div>
      </>
    );
  }
}

ChargingStationsResult.propTypes = {
  result: PropTypes.object,
};

export default ChargingStationsResult;
