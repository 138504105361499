function callback(e, options, setFields, fields, request) {
  const { lat, lng } = e.latLng;
  let tracePoints = fields[options.key] || '';
  const csvHeader = 'LATITUDE,LONGITUDE';
  const csv = tracePoints.indexOf(csvHeader);
  tracePoints = `${csv === 0 ? tracePoints : csvHeader}\n${parseFloat(lat.toFixed(6))},${parseFloat(lng.toFixed(6))}`;
  setFields({ [options.key]: tracePoints });
  request();
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setFields, fields, request }) {
  return {
    text: options.text,
    callback: (e) => callback(e, options, setFields, fields, request),
  };
}
