const HERE_GOOGLE_MODE_MAP = {
  car: 'driving',
  publicTransport: 'transit',
  bicycle: 'bicycling',
  pedestrian: 'walking',
};

const HERE_TOMTOM_MODE_MAP = {
  car: 'CAR',
  truck: 'TRUCK',
};

const HERE_MAP_FAN_MODE_MAP = {
  car: 'car',
  pedestrian: 'walk',
  truck: 'truck',
};

const DEFAULT_MAP_FAN_PARAMS = {
  car: () => 'now,0,0,1,0,1,30,80,50',
  walk: () => 'now,4,101',
  truck: (width, height, weight, hazardousGoods) => {
    const w = width > 250 ? 500 : width || 250;
    const h = height > 410 ? 1000 : height || 380;
    const weightParam = weight > 36000 ? 65000 : weight || 16000;
    return `now,0,0,1,0,3,30,80,50,6,${w},${h},${weightParam},${+!!hazardousGoods}`;
  },
};

export const myDrive = {
  getUrl(waypoints, options) {
    const [startLat, startLon] = waypoints[0].split(',');
    const [destLat, destLon] = waypoints[waypoints.length - 1].split(',');
    const viewPortLat = (+startLat + +destLat) / 2;
    const viewPortLon = (+startLon + +destLon) / 2;
    const viewPort = `${viewPortLat},${viewPortLon},11.00,0,-0`; //lat,lon,zoom,3D angle,compass angle

    const baseUrl = 'https://mydrive.tomtom.com/en_gb';
    const mode = HERE_TOMTOM_MODE_MAP[options.mode] || 'CAR';
    const points = waypoints.map((waypoint) => `hw~${waypoint}`);
    let vehicleParamsUri = '';
    if (mode === 'TRUCK') {
      const vehicleParams = { ...options.truckOptions };
      vehicleParams.hazmat = [];
      vehicleParamsUri = `"vehicleParams":${JSON.stringify(vehicleParams)},`;
    }
    const encodedUri = encodeURI(`#mode=routes+viewport=${viewPort}+\
routes={"departure":true,"traffic":true,"routeType":"FASTEST","travelMode":"${mode}","points":\
["${points.join('","')}"],${vehicleParamsUri}"avoidCriteria":[]}+ver=3`);
    return `${baseUrl}/${encodedUri}`;
  },

  open(waypoints, options) {
    const win = window.open(this.getUrl(waypoints, options), '_blank');
    win.focus();
  },
};

export const google = {
  getUrl(waypoints, mode = 'car') {
    const origin = waypoints[0];
    const destination = waypoints.pop();
    const middlePoints = waypoints.slice(1);
    const travelMode = HERE_GOOGLE_MODE_MAP[mode]
      ? HERE_GOOGLE_MODE_MAP[mode]
      : HERE_GOOGLE_MODE_MAP.car;

    const params = [
      'api=1',
      `origin=${origin}`,
      `destination=${destination}`,
      `travelmode=${travelMode}`,
    ];

    if (middlePoints.length > 0) {
      params.push(`waypoints=${middlePoints.join('|')}`);
    }

    return `https://www.google.com/maps/dir/?${params.join('&')}`;
  },

  open(waypoints, mode) {
    const url = this.getUrl(waypoints, mode);
    const win = window.open(url, '_blank');
    win.focus();
  },
};

export const mapFan = {
  open(
    waypoints,
    { mode, truckOptions: { width, height, weight, hazardousGoods } }
  ) {
    const points = waypoints.join('/');
    const mapFanMode = HERE_MAP_FAN_MODE_MAP[mode] || 'car';
    const modeOptions = DEFAULT_MAP_FAN_PARAMS[mapFanMode](
      width,
      height,
      weight,
      hazardousGoods.length
    );
    const url = `https://mapfan.com/map/points/${points}/types/${mapFanMode}/settings/${modeOptions}/routes`;
    const win = window.open(url, '_blank');
    win.focus();
  },
};

export function getOlsWaypoints(fields) {
  const origin = fields.origin;
  const destination = fields.destination;
  let via = [];
  if (fields.via.length) {
    via = fields.via.map((value) => value.coords);
  }

  return [origin, ...via, destination];
}

export function getOlsOptions(fields) {
  const truckOptions = {
    hazardousGoods: fields['truck[shippedHazardousGoods]'],
  };
  if (fields['truck[height]']) {
    truckOptions.height = +fields['truck[height]'];
  }
  if (fields['truck[width]']) {
    truckOptions.width = +fields['truck[width]'];
  }
  if (fields['truck[length]']) {
    truckOptions.length = +fields['truck[length]'];
  }
  if (fields['truck[grossWeight]']) {
    truckOptions.weight = +fields['truck[grossWeight]'];
  }
  if (fields['truck[weightPerAxle]']) {
    truckOptions.axleWeight = +fields['truck[weightPerAxle]'];
  }

  return { truckOptions, mode: fields.transportMode };
}
