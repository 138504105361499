import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LayerToggle from '../LayerToggle';
import { withHereMap } from '../../shared/hereMap/HereMapContext';

class ZoomControls extends Component {
  onZoomIn = () => {
    const { map } = this.props;
    map.setZoom(map.getZoom() + 1, true);
  };

  onZoomOut = () => {
    const { map } = this.props;
    map.setZoom(map.getZoom() - 1, true);
  };

  render() {
    return (
      <div>
        <div className="rf-map__zoom-controls">
          <LayerToggle onChange={this.onZoomIn} tooltipText="Zoom in" />
          <LayerToggle onChange={this.onZoomOut} tooltipText="Zoom out" />
        </div>
      </div>
    );
  }
}

ZoomControls.propTypes = {
  map: PropTypes.object.isRequired,
};

export default withHereMap(ZoomControls);
