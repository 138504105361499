import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Main from './main/Main';
import { connect } from 'react-redux';
import apiUrlSelector from 'state/selectors/apiUrlSelector';
import { logError } from '../utils/splunkLog';

class App extends Component {
  render() {
    const { apiUrl, postData, rcParams } = this.props;
    return <Main apiUrl={apiUrl} postData={postData} rcParams={rcParams} />;
  }
}

App.propTypes = {
  apiUrl: PropTypes.string,
  postData: PropTypes.object,
  rcParams: PropTypes.object,
};

const mapStateToProps = (state) => {
  try {
    return {
      ...apiUrlSelector(state),
    };
  } catch (e) {
    logError({...e, type: 'state'});
    return {
      apiUrl: null,
      postData: null,
      rcParams: null,
    };
  }
};

export default connect(mapStateToProps)(App);
