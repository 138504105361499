import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setResultTab } from 'state/tabs/tab/resultTab/reducer';
// import { setResultState } from 'state/tabs/tab/result/state/reducer';
import CustomTabs from 'shared/tabs';
import './resultTabs.scss';

class ResultTabs extends React.Component {

  onSetTab = (index, resultTab) => {
    const { 
      setResultTab, 
      selectedTabId, 
      tabs, 
      setResultState ,
      result: { state }
    } = this.props;
    const {
      content: { props }
    } = resultTab;
    
    tabs.forEach((tab, tabIdx) => {
      if( tab.id === selectedTabId ) {
        setResultTab(index, tabIdx);
       
        if (tab.title === 'Routing V8') {
          const { options={} } = props; 
          const { key, formatter } = options;
          
          if (key === 'selectedManeuver'){
            setResultState({ [key]: state[`${key}-copy-${formatter}`] });
  
          } else {
            setResultState({ 'selectedManeuver': {} });
          }
        }
      }
    })

  };

  render() {
    const { options, plugin, result, setResultState, ...otherProps } = this.props;
      
    const data = options.tabs.map((tab, index) => {
      const Component = plugin.get(tab.type);
      const content = (
        <Component
          key={index}
          options={tab.options}
          result={result}
          setResultState={setResultState}
          {...otherProps}
        />
      );
      return { title: tab.title, content };
    });
    let currentResultTabId =  0;
    if (this.props.hasOwnProperty('selectedTabId')) {
      const { tabs, selectedTabId }  = this.props;
      tabs.forEach((tab, tabIdx) => {
        if( tab.id === selectedTabId ) {
          currentResultTabId = tabs[tabIdx].resultTab;
        }
      })
    }
    return <CustomTabs cssClasses="rf-result-tabs" 
        currentResultTabId={currentResultTabId}
        data={data} 
        onSetTab={this.onSetTab} 
      />;
  }
}

ResultTabs.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object,
  plugin: PropTypes.object,
  setResultState: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = {
  setResultTab: setResultTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultTabs);