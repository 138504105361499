import React from 'react';
import {
  FormRow,
  Checkbox,
  Input,
  ExpandFormRow,
  Select,
  Columns,
  Textarea,
} from '@here/ref-client-ui';
import SpeedGroup from './SpeedGroupComponent';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';

import './olsFuelConsumption.scss';
import olsFuelPreset, { presetPetrol } from './olsFuelPreset';
import yamlLoad from '../checkboxes/yamlLoad';
import { getOpenapiUrl } from '../openapiHelpers';
import isArray from 'lodash/isArray';
import Searchable from '../../../views/shared/searchUtils/Searchable';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';

const getDescriptionKey = (key) => ({
  key: 'description',
  path: `components.schemas.Fuel.properties.${key}.description`,
});

const consumptionSpeedTableKey = [
  {
    key: 'description',
    path: 'components.schemas.ConsumptionSpeedTable.description',
  },
  {
    key: 'example',
    path: 'components.schemas.ConsumptionSpeedTable.example',
  },
];

const getFuelTooltipKeys = (key) => [
  getDescriptionKey(key),
  {
    key: 'minimum',
    path: `components.schemas.Fuel.properties.${key}.minimum`,
  },
];

const consumptionParameters = (type) => {
  const consumptionRate = (type === 'cng' ? '(gm/m)' : '(ml/m)');
  const addconsumptionRate = (type === 'cng' ? '(gm/s)' : '(ml/s)');

  return [
    {
      label: 'Ascent ' + consumptionRate,
      key: 'fuel[ascent]',
      tooltipKeys: getFuelTooltipKeys('ascent'),
    },
    {
      label: 'Additional ' + addconsumptionRate,
      key: 'fuel[additionalConsumption]',
      tooltipKeys: getFuelTooltipKeys('additionalConsumption'),
    },
  ]
};

const consumptionType = [
  // { value: 'electric', label: 'Electric (kWh)' },
  { value: 'diesel', label: 'Diesel (Liters)' },
  { value: 'petrol', label: 'Petrol (Liters)' },
  { value: 'lpg', label: 'LPG (Liters)' },
  { value: 'cng', label: 'CNG (kg)' },
  { value: 'lng', label: 'LNG (Liters)' },
  { value: 'ethanol', label: 'Ethanol (Liters)' },
  { value: 'propane', label: 'Propane (Liters)' },
  { value: 'hydrogen', label: 'Hydrogen (Liters)' }
]

const mapParamKey = (param) => param.key;

class OlsFuelConsumption extends React.Component {
  state = {
    preset: 'custom',
    consumptionType: 'diesel',
    openapiData: null,
  };

  componentDidMount() {
    const propsUrl = get(this.props, 'settings.url');
    const url = getOpenapiUrl(propsUrl, '.*/v8/', 'openapi');
    yamlLoad(url).then((openapiData) => {
      if (!this.isUnmounted) {
        this.setState({ openapiData });
      }
    });

    adjustLUIStyle(document.querySelectorAll('.rf-ols-consumption lui-button'), 'lui-button');
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getTooltip(tooltipKeys) {
    const { openapiData } = this.state;
    let tooltip = '';
    if (!isArray(tooltipKeys)) {
      return null;
    }
    tooltipKeys.forEach(({ key, path }) => {
      tooltip += `${key} - ${get(openapiData, path)}\n`;
    });
    return <div className="rf-form-view__tooltip">{tooltip}</div>;
  }

  getFields(fields) {
    return fields.map((param) => {
      const field = get(this.props, `formData.fields['${param.key}']`, {});
      const evPost = get(
        this.props,
        `formData.fields.evPost['${param.key}']`,
        false
      );
      const tooltip = this.getTooltip(param.tooltipKeys);
      const postCheckbox = param.postable ? (
        <Checkbox
          label="Is POST"
          isChecked={evPost}
          onChange={this.onChangeIsPost.bind(this, param.key)}
        />
      ) : null;
      let inputEl = null;
      if (evPost) {
        inputEl = (
          <Textarea
            label={param.label}
            value={field}
            onBlur={this.onChange.bind(this, `${param.key}`)}
          />
        );
      } else {
        inputEl = (
          <Input
            label={param.label}
            placeholder={param.label}
            value={field}
            onChange={this.onChange.bind(this, `${param.key}`)}
            tooltip={tooltip}
            tooltipPlacement="right"
            debounce
          />
        );
      }
      return (
        <FormRow key={param.key}>
          {postCheckbox}
          {inputEl}
        </FormRow>
      );
    });
  }

  multiUpdate = (data) => {
    const filteredData = { ...data };
    this.props.onMultiUpdate(filteredData);
  };

  onPresetChange = (preset) => {
    const { onChange } = this.props;
    const value = preset.target ? preset.target.value : preset;

    const newPreset = find(olsFuelPreset, { value });
    if (!newPreset) {
      return;
    }
    onChange('evEnabled', false);

    this.setState({
      'preset': value,
      'consumptionType': newPreset.preset['fuel[type]'],
    });

    this.multiUpdate(newPreset.preset);
  };

  onConsumptionTypeChange = (consumptionType) => {
    const { onChange } = this.props;
    const value = consumptionType.target ? consumptionType.target.value : consumptionType;

    onChange('fuel[type]', value);
    this.setState({
      'consumptionType': value,
      'preset': 'custom',
    });
  }

  onToggle = (e) => {
    const { onChange } = this.props;

    onChange('fuelEnabled', e.target.checked);
    if (e.target.checked) {
      onChange('evEnabled', false);
    }
  };

  setDefaultConsumption = () => {
    this.multiUpdate(presetPetrol.preset);
    this.setState({
      preset: 'petrol',
    });
  };

  onChange = (key, value) => {
    this.props.onChange(key, value);
    this.setState({
      preset: 'custom',
    });
  };

  onChangeIsPost = (key, { target: { checked } }) => {
    const { onChange } = this.props;
    let evPost = get(this.props, `formData.fields.evPost`, {});
    evPost = { ...evPost, [key]: checked };
    onChange('evPost', evPost);
  };

  render() {
    const {
      formData = {},
      showPreset,
      chargeAwareRoutingEnabled,
    } = this.props;
    const { fields = {} } = formData;
    const { fuelEnabled } = fields;
    return (
      <ExpandFormRow
        label="Fuel Parameters"
        className="rf-ols-consumption"
        isExpanded
      >
        <FormRow>
          <lui-button onClick={this.setDefaultConsumption}>
            Set default values
          </lui-button>
        </FormRow>
        <FormRow>
          <Checkbox
            label="Include Fuel Parameters"
            isChecked={fuelEnabled}
            onChange={this.onToggle}
          />
        </FormRow>

        {showPreset && !!olsFuelPreset.length && (
          <FormRow>
            <Select
              label="Configuration Preset"
              options={olsFuelPreset}
              value={this.state.preset}
              onChange={this.onPresetChange}
              notSupportedLabel="Custom"
            />
          </FormRow>
        )}

        <FormRow>
          <Select
            label="Consumption Type"
            options={consumptionType}
            value={fields['fuel[type]'] || this.state.consumptionType}
            onChange={this.onConsumptionTypeChange}
          />
        </FormRow>

        {chargeAwareRoutingEnabled && (
          <Searchable
            searchKey={[
              'fuel[freeFlowSpeedTable]',
              'fuel[trafficSpeedTable]',
            ].concat(consumptionParameters(this.state.consumptionType).map(mapParamKey))}
          >
            <ExpandFormRow
              label="Consumption Aware Routing"
              className="rf-battery-fields"
            >
              <ExpandFormRow
                label="Free Flow Speed Table"
                tooltip={this.getTooltip(consumptionSpeedTableKey)}
                tooltipPlacement="right"
              >
                <SpeedGroup
                  speedArray={fields['fuel[freeFlowSpeedTable]'].split(',')}
                  onChange={this.onChange.bind(this, `fuel[freeFlowSpeedTable]`)}
                />
              </ExpandFormRow>
              <ExpandFormRow
                label="Traffic Speed Table"
                tooltip={this.getTooltip([
                  getDescriptionKey('trafficSpeedTable'),
                ])}
                tooltipPlacement="right"
              >
                <SpeedGroup
                  speedArray={fields['fuel[trafficSpeedTable]'].split(',')}
                  onChange={this.onChange.bind(this, `fuel[trafficSpeedTable]`)}
                />
              </ExpandFormRow>

              <Columns>{this.getFields(consumptionParameters(this.state.consumptionType))}</Columns>
            </ExpandFormRow>
          </Searchable>
        )}

      </ExpandFormRow>
    );
  }
}

OlsFuelConsumption.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onMultiUpdate: PropTypes.func.isRequired,
  chargeAwareRoutingEnabled: PropTypes.bool.isRequired,
  showPreset: PropTypes.bool.isRequired,
};

export default OlsFuelConsumption;
