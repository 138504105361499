class FormattersPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported formatter type: '${name}'.
          Please register with formatter plugin
          import formatterPlugin from 'core/formattersPlugin';

          formatterPlugin.register('${name}', <Formatter function>)`
    );
  }
}

const formattersPlugin = new FormattersPlugin();
Object.freeze(formattersPlugin);

export default formattersPlugin;
