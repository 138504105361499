import React, { useMemo } from 'react';
import { Button } from '@here/ref-client-ui';
import './trace.scss';
import PluginWithTextArea from '../../sharedUI/pluginWithTextarea';
import multiplePoints from 'ref-client-core/core/formViewsPlugins/multiplePoints/multiplePoints';
import TraceItem, { isPointValid } from './TraceItem';
import isArray from 'lodash/isArray';
import every from 'lodash/every';

function Trace({ fields, setFields }) {
  const { trace: traceStr, via } = fields;
  const trace = useMemo(() => JSON.parse(traceStr || '[]'), [traceStr]);
  const isTraceValid = useMemo(() => {
    if (!isArray(trace)) {
      return false;
    }
    return every(trace, isPointValid);
  }, [trace]);
  const viaIndexesSet = useMemo(() => {
    if (!via) {
      return;
    }
    const res = new Set();
    via.forEach((item) => {
      res.add(item.index === '' ? '' : +item.index);
    });
    return res;
  }, [via]);

  const onChange = (trace) => {
    setFields({ trace: JSON.stringify(trace) });
  };

  const onAddItem = () => {
    setFields({ trace: JSON.stringify([...trace, { lat: '', lng: '' }]) });
  };

  const onChangeLatLng = (coord, index) => {
    setFields({
      trace: JSON.stringify([
        ...trace.slice(0, index),
        coord,
        ...trace.slice(index + 1),
      ]),
    });
  };

  const onChangeVia = (isChecked, index) => {
    if (isChecked && !viaIndexesSet.has(index)) {
      setFields({ via: [...via, { index: `${index}` }] });
    } else if (!isChecked && viaIndexesSet.has(index)) {
      const removeIndex = via.findIndex((item) => +item.index === index);
      setFields({
        via: [...via.slice(0, removeIndex), ...via.slice(removeIndex + 1)],
      });
    }
  };

  const onRemoveItem = (index) => {
    setFields({
      trace: JSON.stringify([
        ...trace.slice(0, index),
        ...trace.slice(index + 1),
      ]),
    });
  };

  const items = trace.map((item, index) => (
    <TraceItem
      key={index}
      index={index}
      trace={item}
      isVia={viaIndexesSet && viaIndexesSet.has(index)}
      onChangeVia={onChangeVia}
      onChangeLatLng={onChangeLatLng}
      onRemove={onRemoveItem}
    />
  ));

  return (
    <PluginWithTextArea
      label="Trace"
      onChange={onChange}
      value={trace}
      className="rf-trace"
      isValid={isTraceValid}
    >
      <>
        {items}
        <Button title="Add Point" onClick={onAddItem} />
      </>
    </PluginWithTextArea>
  );
}

const tracePlugin = {
  ...multiplePoints,
  Component: Trace,
};

export default tracePlugin;
