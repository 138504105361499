import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalActions } from '@here/ref-client-ui';
import ColorPickerDropDown from './ColorPickerDropDown';
import adjustLUIStyle from '../../utils/adjustLUIStyle';

const isHexColorValid = (color) => /#[a-fA-F0-9]{6}/.test(color);

const isColorPaletteValid = (colorPalette) =>
  ['primary', 'primaryDarker', 'secondary', 'secondaryDarker'].every((key) =>
    isHexColorValid(colorPalette[key])
  );

function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

function ColorPickerModal({ colorPalette, onChange, onCancel }) {
  const [colorPaletteState, setColorPaletteState] = useState({
    ...colorPalette,
  });

  useEffect(() => {
    const closeModal = (e) => {
      if (e.key === 'Escape') {
        onCancel();
      }
    };
    window.addEventListener('keyup', closeModal);

    adjustLUIStyle(document.querySelectorAll('.rf-color-picker-modal lui-overlay'), 'lui-overlay');
    return () => {
      window.removeEventListener('keyup', closeModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePalette = (fieldName) => (hex) => {
    if (fieldName === 'primary') {
      setColorPaletteState({
        primary: hex,
        primaryDarker: shadeColor(hex, -20),
        secondary: shadeColor(hex, 40),
        secondaryDarker: shadeColor(hex, 20),
      });
    } else {
      setColorPaletteState({ ...colorPaletteState, [fieldName]: hex });
    }
  };

  const saveProps = {
    onClick: () => onChange(colorPaletteState),
    secondary: true,
  };
  if (!isColorPaletteValid(colorPaletteState)) {
    saveProps.disabled = true;
  }

  return (
    <Modal
      appendToBody
      isShown
      title="Select tab colors"
      className="rf-color-picker-modal"
    >
      <ColorPickerDropDown
        label="Primary"
        color={colorPaletteState.primary}
        onChange={onChangePalette('primary')}
      />
      <ColorPickerDropDown
        label="Primary darker"
        color={colorPaletteState.primaryDarker}
        onChange={onChangePalette('primaryDarker')}
      />
      <ColorPickerDropDown
        label="Secondary"
        color={colorPaletteState.secondary}
        onChange={onChangePalette('secondary')}
      />
      <ColorPickerDropDown
        label="Secondary darker"
        color={colorPaletteState.secondaryDarker}
        onChange={onChangePalette('secondaryDarker')}
      />
      <ModalActions>
        <lui-button onClick={onCancel} secondary>
          Cancel
        </lui-button>
        <lui-button {...saveProps}>Save</lui-button>
      </ModalActions>
    </Modal>
  );
}

ColorPickerModal.propTypes = {
  colorPalette: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorPickerModal;
