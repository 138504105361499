import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';
import { addEventListeners, removeEventListeners } from './mapEvents';

class HereMarker extends React.Component {
  marker = null;

  componentDidMount() {
    this.addMarker();
  }

  componentDidUpdate(prevProps) {
    const { latLng, options } = this.props;

    if (!isEqual(latLng, prevProps.latLng)) {
      this.marker.setGeometry(latLng);
    }

    if (!isEqual(options.icon, prevProps.options.icon)) {
      this.marker.setIcon(options.icon);
    }
  }

  componentWillUnmount() {
    this.removeMarker();
  }

  dragStart = (e) => {
    e.stopPropagation();
    const {
      currentPointer: { viewportX, viewportY },
    } = e;
    const { map } = this.props;
    this.mouseStart = map.screenToGeo(viewportX, viewportY);
    this.markerStart = this.marker.getGeometry();
  };

  drag = (e) => {
    e.stopPropagation();
    const { map } = this.props;
    const {
      currentPointer: { viewportX, viewportY },
    } = e;
    const currentCoords = map.screenToGeo(viewportX, viewportY);
    const deltaLat = this.mouseStart.lat - currentCoords.lat;
    const deltaLng = this.mouseStart.lng - currentCoords.lng;
    this.marker.setGeometry({
      lat: this.markerStart.lat - deltaLat,
      lng: this.markerStart.lng - deltaLng,
    });
  };

  dragEnd = (e) => {
    e.stopPropagation();

    const { onChange } = this.props;
    const { lat, lng } = this.marker.getGeometry();
    onChange({ lat, lng });
  };

  onPointerMove = (targetData) => {
    const { map } = this.props;
    let cursorStyle = 'move';

    map.getElement().style.cursor = cursorStyle;
  };

  onPointerLeave = () => {
    const { map } = this.props;
    map.getElement().style.cursor = 'auto';
  };

  addMarker = () => {
    const { latLng, options, map, group, draggable, cursorStyleOnHover } = this.props;
    this.marker = new window.H.map.Marker(latLng, {
      ...options,
      volatility: !!draggable,
    });

    if (group) {
      group.addObject(this.marker);
    } else {
      map.addObject(this.marker);
    }

    addEventListeners(this.props, this.marker);
    if (draggable) {
      this.marker.draggable = true;
      this.marker.addEventListener('dragstart', this.dragStart);
      this.marker.addEventListener('drag', this.drag);
      this.marker.addEventListener('dragend', this.dragEnd);

      if (cursorStyleOnHover) {
        this.marker.addEventListener('pointermove', this.onPointerMove);
        this.marker.addEventListener('pointerleave', this.onPointerLeave);
      }
    }
  };

  removeMarker = () => {
    const { group, draggable } = this.props;
    removeEventListeners(this.props, this.marker);
    if (draggable) {
      this.marker.removeEventListener('dragstart', this.dragStart);
      this.marker.removeEventListener('drag', this.drag);
      this.marker.removeEventListener('dragend', this.dragEnd);
    }
    if (this.marker && group && group.contains(this.marker)) {
      group.removeObject(this.marker);
    }
  };

  render() {
    return null;
  }
}

HereMarker.propTypes = {
  map: PropTypes.object.isRequired,
  latLng: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  draggable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withHereMap(HereMarker);
