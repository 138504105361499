import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import CustomComponent from 'shared/component';

import './styles/multiSelect.scss';

export class CustomMultiSelect extends CustomComponent {
  render() {
    let { label, values, options } = this.props;

    return (
      <label className={this.getCssClasses(['rf-multi-select'])}>
        <div className="rf-multi-select__caption">{label}</div>
        <Select
          value={values.map((value) => ({ value, label: value }), {})}
          options={options}
          placeholder={label}
          onChange={this.props.onChange.bind(this)}
          isMulti
        />
      </label>
    );
  }
}

CustomMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomMultiSelect;
