import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@here/ref-client-ui';
import { getOlsOptions, getOlsWaypoints, myDrive } from './compareUtils';

class CompareTomTomButton extends Component {
  open() {
    const { fields } = this.props;
    const waypoints = getOlsWaypoints(fields);
    if (!waypoints) {
      throw new Error("Can't get waypoints");
    }
    myDrive.open(waypoints, getOlsOptions(fields));
  }

  render() {
    return <Button onClick={this.open.bind(this)} title="Compare to TomTom" />;
  }
}

CompareTomTomButton.propTypes = {
  fields: PropTypes.object,
};

export default CompareTomTomButton;
