const NS = 'state';

const localStorageReducerDecorator = function (
  reducer,
  key,
  defaultState = {},
  forceState = {}
) {
  let fullKey = `${NS}.${key}`;
  let storedState = JSON.parse(localStorage.getItem(fullKey) || '{}');
  let initialState = { ...defaultState, ...storedState, ...forceState };
  return (state = initialState, action) => {
    let newState = reducer(state, action);
    localStorage.setItem(fullKey, JSON.stringify(newState));
    return newState;
  };
};

export default localStorageReducerDecorator;
