import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import './tabSortable.scss';
import {
  FormRow,
  Input,
  DragIcon,
  CloseButton,
} from '@here/ref-client-ui';
import { isCoordValidAndDefined } from 'utils/validation/coordsValidation';

const SortableItem = SortableElement((props) => props.children);
const Sortable = SortableContainer((props) => props.children);

class TabSortable extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    const {
      tabs
    } = this.props
    this.setState({
      items: tabs
    })
  }

  getTabs() {
    let { data, onBlur, closeButton, onRemovePoint, getAdvancedWaypointContent } = this.props;

    return (
      <div className="tabbar">
      {data.map((point, num) => {
          return (
            <SortableItem key={`item-${num}`} index={num} value={point}>
              <div>
                <FormRow className="rf-point-item">
                  <div className="rf-point-item__drag">
                    <DragIcon />
                  </div>
                  <Input
                    className="rf-clearfix rf-point-item__input"
                    label={`Point ${num + 1}`}
                    placeholder="Coordinates"
                    value={point}              
                    isValid={isCoordValidAndDefined(point)}
                    onBlur={(e) => onBlur(num, e)}
                    blurOnEnter
                  />
                  {closeButton && (
                    <CloseButton onClick={onRemovePoint.bind(this, num)} />
                  )}
                </FormRow>
                {getAdvancedWaypointContent(point, num)}
              </div>
            </SortableItem>
          );
        })}
      </div>
    )
  }

  render() {
    const {
      onSortEnd,
      tabsRef,
    } = this.props

    return (
    <div ref={tabsRef} className="ui-sortable rf-nav-tabs__sortable">
      <Sortable
        helperClass="rf-point-drag-container"
        axis="y"
        lockAxis="y"
        transitionDuration={0}
        distance={10}
        onSortEnd={onSortEnd}
      >
        {this.getTabs()}
      </Sortable>
    </div>
    );
  }
}

export default TabSortable;
