import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import PolygonList from './PolygonList';
import ELeafletPolygon from './ELeafletPolygon';
import LeafletPolygonMarker from './LeafletPolygonMarker';
import HerePolygon from '../olsIsoline/HerePolygon';
import HerePolygonMarker from './HerePolygonMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: PolygonList(ELeafletPolygon, LeafletPolygonMarker, TILE_LIBRARY_TYPES.LEAFLET),
  [TILE_LIBRARY_TYPES.JSLA]: PolygonList(HerePolygon, HerePolygonMarker, TILE_LIBRARY_TYPES.JSLA),
};
