import { assignTabDataAndDispatch } from 'state/tabs/actions';
import { ADD_TAB } from 'state/tabs/constants';

const SET = 'result/state/SET';

const initState = {
  isResultPanelShown: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_TAB:
      return {
        ...state,
        relatedTabResult: action.payload.result,
      };
    default:
      return state;
  }
};

export const setResultState = (data, index) =>
  assignTabDataAndDispatch({ type: SET, payload: data }, index);
