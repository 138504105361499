import localStorageReducerDecorator from '../../utils/localStorageReducerDecorator';

const SET = 'defaultPreset/SET';

function reducer(state = {}, action) {
  switch (action.type) {
    case SET: {
      const { moduleTitle, preset } = action.payload;
      return {
        ...state,
        [moduleTitle]: preset,
      };
    }
    default:
      return state;
  }
}

export default localStorageReducerDecorator(reducer, 'defaultPresets', {});

export const setDefaultPreset = (moduleTitle, preset) => ({
  type: SET,
  payload: { moduleTitle, preset },
});

export const getDefaultPresets = (state) => state.defaultPresets;
