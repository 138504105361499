import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HereCircle from 'shared/hereMap/HereCircle';
import HereGroup from '../../../views/shared/hereMap/HereGroup';
import { useHereMap } from '../../../views/shared/hereMap/HereMapContext';
import { addOpacityToHexColor } from '../../utils';

export default function Circle(props) {
  const {centerOnChange, center} = props;
  const { map } = useHereMap();

  useEffect(() => {
    if (centerOnChange && center) {
      const zoom = map.getZoom();
      // map.setZoom(zoom > 16 ? zoom : 16);
      // map.setCenter(center, true);

      const viewModal = map.getViewModel();
      viewModal.setLookAtData({
        position: center,
        zoom: zoom > 14 ? zoom : 14
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  const color = props.color;
  const options = {};
  if (color) {
    options.style = {};
    options.style.strokeColor = color;
    options.style.fillColor = addOpacityToHexColor(color, 0.5);
  }
  return (
    <HereGroup options={{ volatility: true }}>
      <HereCircle {...{ ...props, options }} />
    </HereGroup>
  );
}

Circle.propTypes = {
  color: PropTypes.string,
};
