import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import PublicTransitMarkers from './publicTransitMarkers';
import Marker from '../marker/LeafletMarker';
import HereMarker from '../marker/HereMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: PublicTransitMarkers(Marker),
  [TILE_LIBRARY_TYPES.JSLA]: PublicTransitMarkers(HereMarker),
};
