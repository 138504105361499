import settingsPlugin from '../settingsPlugin';

import appIdAppCodeSettings from './appIdAppCodeSettings';
import hereTokenSettings from './hereTokenSettings';
import apiKeySettings from './apiKeySettings';
import credentials from './credentials';
import directSettings from './DirectSettings';

settingsPlugin.register('appIdAppCode', appIdAppCodeSettings);
settingsPlugin.register('apiKey', apiKeySettings);
settingsPlugin.register('hereToken', hereTokenSettings);
settingsPlugin.register('credentials', credentials);
settingsPlugin.register('direct', directSettings);
