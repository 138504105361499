import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import MatrixMarkers from './MatrixMarkers';
import Marker from '../marker/LeafletMarker';
import HereMarker from '../marker/HereMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: MatrixMarkers(Marker),
  [TILE_LIBRARY_TYPES.JSLA]: MatrixMarkers(HereMarker),
};
