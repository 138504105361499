import React from 'react';
import PropTypes from 'prop-types';
import { Pane, Polyline } from 'react-leaflet';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { decode, encode } from 'ref-client-core/utils/flexPolyline';
import polylineOffset from '../../../views/shared/hereMap/polylineOffset';
import PolylineDecorator from './PolylineDecorator'

function getPolyline(route) {
  const { sections = [] } = route;

  if (sections.length === 0) {
    return '';
  } else if (sections.length === 1) {
    return sections[0].polyline;
  } else {
    let points = [];
    sections.forEach(section => {
      const p = decode(section.polyline);
      points = points.concat(p);
    })
    return encode(points);
  }
}

class LeafletRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showIndicator = false;
  }

  componentDidUpdate = () => {
    const { map } = this.props;
    map.on('zoomend', e => {
      if( map.getZoom() <= 14 ) {
        this.setState({
          showIndicator: false,
        })
      } else if(this.showIndicator) {
        this.setState({
          showIndicator: true,
        })
      }
    })

    if ( map.contextmenu.isVisible() ) {
      map.contextmenu.removeItem(this.showIndicator ? 1 : 0);
      if ( map.getZoom() <= 14 ) {
        map.contextmenu.removeItem(0);
      }
    }
  }

  selectRoute = (currentRoute) => {
    const { setResultState } = this.props;
    setResultState({ currentRoute });
  };

  toggleIndicator = () => {
    const { map } = this.props;
    if ( map.getZoom() > 14 ) {
      this.showIndicator = !this.showIndicator;
      this.setState({ showIndicator: this.showIndicator });
    }
  }

  getMapAttributes = () => {
    const { addTab, fields, options: { selectedPath }, } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const routes = get(this.props, "result.raw.data.routes", []);
    const flexiblePolyline = getPolyline(routes[selectedRoute] || {});

    const { key, value } = fields.routeTime;
    const routeTime = (key==="departureTime" && value!== "any") ? {key, value} : {key:'', value:''};
    const trailersCount = fields['vehicle[trailerCount]'] > 3 ? '3' : fields['vehicle[trailerCount]'];
    const shippedHazardousGoods = JSON.parse(JSON.stringify(fields['vehicle[shippedHazardousGoods]']));
    const index = shippedHazardousGoods.values.indexOf('radioactive');
    if (index !== -1) {
      shippedHazardousGoods.values[index] = 'radioActive';
    }
    const mafields = {
      mapAttributes: {
        flexiblePolyline,
        type: "flexiblePolyline"
      },
      transportMode: fields.transportMode,
      routeTime,
      vehicleWeight: fields['vehicle[grossWeight]'],
      length: fields['vehicle[length]'],
      trailersCount,
      shippedHazardousGoods
    }

    addTab({ title: 'Map Attributes', fields: mafields });
  }

  onContextMenu = (e) =>  {
    const { selectedTab, tabIndex } = this.props;
    if (selectedTab !== tabIndex) {
      return;
    }    
    const {
      originalEvent: { x, y },
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
      },
    });
  }


  shouldComponentUpdate(nextProps, nextState) {
    const { options } = this.props;
    const { routesArrayPath, selectedPath, highlightedPath } = options;
    const routes = get(this.props, routesArrayPath);
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);

    const nextRoutes = get(nextProps, routesArrayPath);
    const nextSelectedRoute = get(nextProps, selectedPath, 0);
    const nextHighlightedRoute = get(nextProps, highlightedPath, -1);

    return (
      !isEqual(routes, nextRoutes) ||
      selectedRoute !== nextSelectedRoute ||
      highlightedRoute !== nextHighlightedRoute ||
      !isEqual(this.state, nextState)
    );
  }

  renderRoutes = (routes, routePath, formatter, colorPalette, sectionsPath) => {
    const {
      options: { selectedPath, highlightedPath, getMapAttributes },
    } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);
    const polylines = [];
    let selectedPane = [];
    let highlightedPane;
    routes.forEach((routeWrapper, index) => {
      const sections = [];
      if (sectionsPath) {
        get(routeWrapper, sectionsPath, []).forEach((section) => {
          sections.push(get(section, routePath));
        });
      } else {
        sections.push(get(routeWrapper, routePath));
      }
      const positions = sections.reduce(
        (acc, section) => [
          ...acc,
          ...(formatter ? formatter(section) : section),
        ],
        []
      );
      if (index === selectedRoute) {
        const newPositions = polylineOffset(positions, 0.0002);
        const contextmenu = true;
        const contextWidth = 165;
        let contextmenuItems = [
          {
            text: `Hide Maneuver Indicator`,
            index: 0,
            callback: this.toggleIndicator,
          },
          {
            text: `Show Maneuver Indicator`,
            index: 1,
            callback: this.toggleIndicator,
          },
        ];
        if (getMapAttributes) {
          contextmenuItems.push({
            text: `Get Map Attributes`,
            index: 2,
            callback: this.getMapAttributes,
          })
        }
        selectedPane = [
          <Pane key="selectedRoute" style={{ zIndex: 420 }}>
            <Polyline
              contextmenu={contextmenu}
              contextmenuWidth={contextWidth}
              contextmenuItems={contextmenuItems}
              contextmenuInheritItems={false}
              key={index}
              positions={positions}
              color={colorPalette.primary}
              weight={8}
              oncontextmenu={this.onContextMenu}
            />
            {this.state.showIndicator && (
              <PolylineDecorator 
                key={selectedRoute+'shadow'}
                color={colorPalette.primaryDarker}
                weight={8}
                positions={newPositions} 
              />
            )}
          </Pane>
        ];
      } else if (index === highlightedRoute) {
        highlightedPane = (
          <Pane key="highlightedRoute" style={{ zIndex: 430 }}>
            <Polyline
              key={index}
              positions={positions}
              color={colorPalette.secondaryDarker}
              weight={8}
              onClick={() => this.selectRoute(index)}
            />
          </Pane>
        );
      } else {
        polylines.push(
          <Polyline
            key={index}
            positions={positions}
            color={colorPalette.secondary}
            weight={6}
            onClick={() => this.selectRoute(index)}
          />
        );
      }
    });

    const normalPane = (
      <Pane key="normalRoutes" style={{ zIndex: 410 }}>
        {[...polylines]}
      </Pane>
    );

    return [...selectedPane, highlightedPane, normalPane];
  };

  render() {
    const {
      options,
      colorPalette,
      refClient: { formatterPlugin }
    } = this.props;
    const { routesArrayPath, routePath, format, sectionsPath } = options;
    const formatter = formatterPlugin.get(format);
    const routes = get(this.props, routesArrayPath);
    if (!routes) {
      return null;
    }
    return (
      <>
        {this.renderRoutes(
          routes,
          routePath,
          formatter,
          colorPalette,
          sectionsPath
        )}
      </>
    );
  }
}

LeafletRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default LeafletRoutes;
