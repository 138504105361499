// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, boundsList, mode) => {
  const {
    [mode]: { features },
  } = fields;
  return {
    [mode]: {
      areas: boundsList,
      features,
    },
  };
};
