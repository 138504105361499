import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { decode } from '../../../utils/flexPolyline';
import { addOpacityToHexColor } from '../../utils';

function OlsIsolineMap(PolygonComponent, PolylineComponent) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      result: PropTypes.object,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
      colorPalette: PropTypes.object,
    };

    render() {
      const {
        colorPalette,
        result: { raw = {}, state },
      } = this.props;
      const selected = state.selectedIsoline || 0;
      const { selectedIsolineOpacity } = state;

      const isolines = get(raw, 'data.isolines', []);
      const uniquePolygonsSet = new Set();
      return isolines.map(({ polygons = [], connections = [] }, index) => {
        const isSelected = index === selected;
        const fillColor = isSelected
          ? addOpacityToHexColor(
              colorPalette.primary,
              selectedIsolineOpacity || 0.5
            )
          : addOpacityToHexColor(colorPalette.primary, 0.2);
        const strokeColor = isSelected ? colorPalette.primary : fillColor;

        const polygonEls = [];
        const selectedPolygons = [];
        polygons.forEach(({ outer }, index) => {
          if (uniquePolygonsSet.has(outer) && !isSelected) {
            return;
          }
          uniquePolygonsSet.add(outer);
          const points = decode(outer).map(([lat, lng]) => ({ lat, lng }));
          const el = (
            <PolygonComponent
              key={index}
              positions={points}
              fillColor={fillColor}
              strokeColor={strokeColor}
              zIndex={isSelected ? 400 : null}
            />
          );
          if (isSelected) {
            selectedPolygons.push(el);
          } else {
            polygonEls.push(el);
          }
        });

        const connectionEls = connections.map(({ polyline }, index) => {
          const decodedPolyline = decode(polyline).map(([lat, lng]) => ({
            lat,
            lng,
          }));
          return (
            <PolylineComponent
              key={index}
              positions={decodedPolyline}
              color={strokeColor}
              weight={5}
            />
          );
        });

        return (
          <React.Fragment key={index}>
            {polygonEls}
            {selectedPolygons}
            {connectionEls}
          </React.Fragment>
        );
      });
    }
  };
}

export default OlsIsolineMap;
