import Tangram from 'tangram';
import { MapLayer, withLeaflet } from 'react-leaflet';
import { getTilesUrl } from './helpers';
import { isInternal } from 'state/config';

function loadSubscription(props, layer) {
  const load = {
    load: (e) => {
      if (e.config) {
        const { tilesData } = props;
        e.config.sources.omv.type = 'MVT';
        e.config.sources.omv.url = getTilesUrl(tilesData);
        e.config.sources.omv.max_zoom = 17;

        // Fix default filter for POIs
        e.config.layers.pois.default.filter = "function() {\n  return feature.icons && feature.icons.length;\n}\n";
        // Fixing labels order
        e.config.styles['text-blend-order'].blend_order = 4;
        e.config.styles.icon_library.blend_order = 4;
        e.config.styles.shields.blend_order = 4;

        const { pview } = tilesData[tilesData.type];
        e.config.global.political_view = pview;
        e.config.sources.omv.extra_data.political_view = pview;
        // Fixing rendering political view
        e.config.sources.omv.transform = e.config.sources.omv.transform.political_view;
      }

      layer.scene.unsubscribe(load);
    },
  };
  return load;
}

class TangramLayer extends MapLayer {
  createLeafletElement(props) {
    this.layer = Tangram.leafletLayer({
      scene: props.styleUrl,
    });
    this.layer.scene.subscribe(loadSubscription(props, this.layer));
    return this.layer;
  }

  updateLeafletElement(prevProps, props) {
    const url = getTilesUrl(props.tilesData);
    if (url !== getTilesUrl(prevProps.tilesData)) {
      const { scene } = this.layer;
      if (scene.config) {
        scene.config.sources.omv.url = url;
        scene.updateConfig();
      }
    }
    if (prevProps.styleUrl !== props.styleUrl) {
      this.layer.scene.load(props.styleUrl);
      this.layer.scene.subscribe(loadSubscription(props, this.layer));
    }
    if ( 
      isInternal(props.config) &&
      this.layer.scene.config
    ) {
      const { scene } = this.layer;
      const { tilesData } = props;
      const { pview } = tilesData[tilesData.type];
      scene.config.global.political_view = pview;
      scene.config.sources.omv.extra_data.political_view = pview;
      
      scene.updateConfig();
    }
  }

  componentWillUnmount() {
    const { scene } = this.layer;
    if (scene && scene.config) {
      scene.config.sources = {};
      scene.container.remove();
      scene.updateConfig();
    }
  }
}

export default withLeaflet(TangramLayer);
