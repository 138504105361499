import AdvancedViaFieldsComponent, {
  splitAdvancedWaypointParams,
  stringifyViaWaypoints,
} from './AdvancedViaFieldsComponent';
import { isArray } from 'lodash';
import * as Qs from 'qs';

const advancedViaFields = {
  parseUrl: ({ parsedParams, options }) => {
    const { key } = options;
    const keyLowerCase = key.toLowerCase();
    const pointsArr = isArray(parsedParams[keyLowerCase])
      ? parsedParams[keyLowerCase]
      : [parsedParams[keyLowerCase]];
    const res = {
      [key]: pointsArr
        .filter(Boolean)
        .map((pointStr) => splitAdvancedWaypointParams(pointStr)),
    };
    delete parsedParams[keyLowerCase];

    return res;
  },
  defaultState: (options) => ({
    [options.key]: [],
  }),
  getRequestOptions: (fields, options) => {
    const { key, isMultiParams } = options;
    if (isMultiParams && fields[key]) {
      return {
        params: { [key]: stringifyViaWaypoints(fields[key]) },
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
      };
    }
    const params = fields[key] ? { [key]: fields[key] } : {};
    return { params };
  },
  Component: AdvancedViaFieldsComponent,
};

export default advancedViaFields;
