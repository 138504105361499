import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import MatrixRoutes from './MatrixRoutes';
import { Polyline } from 'react-leaflet';
import HereMatrixRoute from './HereMatrixRoute';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: MatrixRoutes(Polyline),
  [TILE_LIBRARY_TYPES.JSLA]: MatrixRoutes(HereMatrixRoute),
};
