import React from 'react';
import PropTypes from 'prop-types';
import { Polyline } from 'react-leaflet';

function LeafletLine({ 
  latLngs, options, onTap, id
}) {
  return <Polyline
    key={id}
    positions={latLngs}
    color={options.style.strokeColor}
    weight={options.style.lineWidth}
    fillColor={options.style.strokeColor}
    onClick={onTap}
  />
}

LeafletLine.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object)
};

export default LeafletLine;