import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@here/ref-client-ui';
import './newVersion.scss';

function NewVersion({ worker }) {
  const update = () => {
    if (worker) {
      worker.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  };

  return (
    <div className="rf-new-version">
      <div className="rf-new-version__text">
        New version is available please
      </div>
      <div>
        <Button onClick={update} title="Update" />
      </div>
    </div>
  );
}

NewVersion.propTypes = {
  worker: PropTypes.object,
};

export default NewVersion;
