import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { FormRow, Input, TabsControl } from '@here/ref-client-ui';
import OlsWaypointAdvanced from '../olsWaypoint/olsWaypointAdvanced';
import {
  splitAdvancedWaypointParams,
  stringifyWaypointParams, 
  removeAdvancedParamsPrefix, 
  getAdvancedParams,
  defaultAdvancedState
} from '../olsWaypoint/olsWaypoint';

const MODES = [
  { label: 'Origin', name: 'origin' },
  { label: 'Destination', name: 'destination' },
];

const OPTIONS = {
  course: true,
  sideOfStreetHint: true,
  displayLocation: true,
  matchSideOfStreet: true,
  nameHint: true,
  radius: true,
  snapRadius: true,
  minCourseDistance: true,
  radiusPenalty: true,
  segmentIdHint: true,
  onRoadThreshold: true,
  extraParams: true,
}

const ISOLINE_KEY = 'originDestination';

class IsolineOriginDestination extends React.Component {
  componentDidUpdate() {
    const {
      fields: { originDestinationValue: value },
      setFields,
    } = this.props;

    if (!value) {
      return;
    }

    const noSpaces = value.replace(/\s/g, '');
    if (noSpaces !== value) {
      setFields({ originDestinationValue: noSpaces });
    }
  }

  onKeyChange = (key) => {
    const { setFields } = this.props;
    setFields({
      originDestinationKey: key,
    });
  };

  onChange = ({ target: { value } }) => {
    const { setFields } = this.props;
    setFields({
      originDestinationValue: value,
    });
  };

  onAdvancedChanged = (field, e) => {
    const {
      setFields,
    } = this.props;
    setFields({ [`originDestination_${field}`]: utils.extractData(e) });
  };

  render() {
    const { fields } = this.props;
    const { originDestinationKey: key, originDestinationValue: value } = fields;
    return (
      <div className='rf-grey-box'>
      <FormRow>
        <TabsControl
          currentTabName={key}
          onChange={this.onKeyChange}
          tabsData={MODES}
        />
        <Input
          value={value}
          label={key === 'origin' ? 'Origin' : 'Destination'}
          onBlur={this.onChange}
          blurOnEnter
        />
      </FormRow>
      <OlsWaypointAdvanced
        fields={fields}
        data={removeAdvancedParamsPrefix(getAdvancedParams(this.props.fields, ISOLINE_KEY), ISOLINE_KEY)}
        options={OPTIONS}
        onChange={this.onAdvancedChanged}
      />
      </div>
    );
  }
}

IsolineOriginDestination.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams }) => {
    let keyValue = {
      receivedkey: 'origin',
      receivedValue: '',
    };
    if (parsedParams.origin) {
      keyValue.receivedValue = parsedParams.origin;
    } else if (parsedParams.destination) {
      keyValue.receivedkey = 'destination';
      keyValue.receivedValue = parsedParams.destination;
    }
    const parsedValue = splitAdvancedWaypointParams(
      keyValue.receivedValue,
      defaultAdvancedState(ISOLINE_KEY),
      ISOLINE_KEY
    );
    const res = {
      'originDestinationKey': keyValue.receivedkey,
      'originDestinationValue': parsedValue.coords,
       ...parsedValue.advanced
      };
    delete parsedParams.origin;
    delete parsedParams.destination;
    return res;
  },
  defaultState: {
    originDestinationKey: 'origin',
    originDestinationValue: '',
    ...defaultAdvancedState(ISOLINE_KEY),
  },
  getRequestOptions: (fields) => {
    const { originDestinationKey, originDestinationValue } = fields;
    const params = stringifyWaypointParams(
      {
        coords: originDestinationValue,
        advanced: getAdvancedParams(fields, ISOLINE_KEY),
      },
      ISOLINE_KEY
    );
    if (params) {
      return { params: { [originDestinationKey]: params } };
    }
    return {};
  },
  Component: IsolineOriginDestination,
};
