import React from 'react';
import PropTypes from 'prop-types';
import CustomComponent from '../component';

class OutsideClickHandler extends CustomComponent {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.outsideClick();
    }
  };

  render() {
    return (
      <div className={this.getCssClasses()} ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

OutsideClickHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  outsideClick: PropTypes.func.isRequired,
};

export default OutsideClickHandler;
