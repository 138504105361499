import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import HistoryList from './HistoryList';
import { Loader } from '@here/ref-client-ui';

import './styles/history.scss';
import { setSelectedHistory } from 'state/tabs/tab/selectedHistory/actions';
import { getSelectedHistory } from 'state/tabs/tab/selectedHistory/index';
import { getHistory } from 'state/tabs/tab/history';
import { getIsHistoryShown } from 'state/ui';

export class HistoryContainer extends Component {
  onClick = (id) => {
    this.props.setSelectedHistory(id);
  };

  render() {
    const { selectedHistory, isLoading, isHistoryShown, history } = this.props;
    const classes = classnames({
      'rf-history__overlay': true,
      'rf-history__overlay_visible': isLoading,
    });

    return (
      <div className="rf-history">
        <div className={classes}>
          <Loader />
        </div>
        <HistoryList
          onClick={this.onClick}
          history={history}
          selectedHistory={selectedHistory}
          isHistoryShown={isHistoryShown}
        />
      </div>
    );
  }
}

HistoryContainer.propTypes = {
  selectedHistory: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isHistoryShown: PropTypes.bool.isRequired,
  setSelectedHistory: PropTypes.func.isRequired,
  history: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedHistory: getSelectedHistory(state),
    isLoading: state.isLoading,
    isHistoryShown: getIsHistoryShown(state),
    history: getHistory(state),
  };
}

const mapDispatchToProps = {
  setSelectedHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer);
