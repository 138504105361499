import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { Tooltip } from '@here/ref-client-ui';

import './styles/layerToggle.scss';

class RulerToggle extends Component {
  click = () => {
    const { setFields, tabs, selectedTab, setSelectedTab, addTab } = this.props;
    const tab = tabs[selectedTab];
    const { fields } = tab;
    const active = !!fields.ruler;

    if ( tab.title === 'Offline Tools' ) {
      const ruler = active ? null : [];
      setFields({ruler});
    } else {
      const idx = tabs.findIndex(tab => {
        if ( tab.title === 'Offline Tools' && !!tab.fields.ruler ) return true;
        else return false;
      });
      if (idx>-1) {
        setSelectedTab(idx);
        return
      }
      const id = tabs.findIndex(tab => {
        if ( tab.title === 'Offline Tools' ) return true;
        else return false;
      });
      if (id>-1) {
        const ruler = [];
        setFields({ruler}, id);
        setSelectedTab(id);
        return
      }
      const ruler = [];
      addTab({title: 'Offline Tools', fields: {ruler}});
    }
  };

  render() {
    let { disabled, label, tooltipText, tabs, selectedTab } = this.props;
    const tab = tabs[selectedTab];
    const { fields } = tab;
    let baseClass = 'rf-layer-toggle';
    let classes = classnames(baseClass, {
      'rf-layer-toggle_active': !!fields.ruler,
      'rf-layer-toggle_disabled': disabled,
    });
    let toggleEl = (
      <div className={classes} onClick={this.click}>
        <div className="rf-layer-toggle__label">{label}</div>
      </div>
    );

    if (tooltipText) {
      return <Tooltip tooltip={tooltipText}>{toggleEl}</Tooltip>;
    }
    return toggleEl;
  }
}

RulerToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

RulerToggle.defaultProps = {
  disabled: false,
  active: false,
  label: '',
};

export default RulerToggle;
