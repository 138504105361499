import { assignTabDataAndDispatch } from 'state/tabs/actions';

const SET = 'tabs/paramsMapping/SET';

const paramsMappingReducer = (state = true, action) => {
  switch (action.type) {
    case SET:
      return action.payload;
    default:
      return state;
  }
};

export default paramsMappingReducer;

export const setTabParamsMapping = (payload, index) =>
  assignTabDataAndDispatch({ type: SET, payload }, index);

export const getTabParamsMapping = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return state.tabs[selectedTab].paramsMapping;
};
