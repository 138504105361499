import React from 'react';

import ToggleButton from 'react-toggle';
import CustomComponent from '../component';

import './styles/toggleButton.scss';
import { omit } from 'lodash';

export default class CustomToggleButton extends CustomComponent {
  constructor(props) {
    super(props);
    this.toggleButtonRef = React.createRef();
  }

  componentDidMount() {
    this.toggleButtonRef.current.addEventListener('transitionend', (e) =>
      e.stopPropagation()
    );
  }

  render() {
    let props = omit(
      {
        ...this.props,
        icons: {
          checked: (
            <svg width="1.4rem" height="1.1rem" viewBox="0 0 14 11">
              <title>switch-check</title>
              <path
                d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
                fill="#fff"
                fillRule="evenodd"
              ></path>
            </svg>
          ),
          unchecked: (
            <svg width="1rem" height="1rem" viewBox="0 0 10 10">
              <title>switch-x</title>
              <path
                d={`M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 \
7.072 4.95 9.9 2.12`}
                fill="#fff"
                fillRule="evenodd"
              ></path>
            </svg>
          ),
        },
      },
      'cssClasses'
    );
    return (
      <div
        className={this.getCssClasses(['rf-toggle-button'])}
        ref={this.toggleButtonRef}
      >
        <span className="rf-toggle-button__label">{this.props.label}</span>
        <ToggleButton {...props} />
      </div>
    );
  }
}
