import { decode } from '../../utils/flexPolyline';

export default function spansToManeuvers(route) {
  const res = { sections: [] };
  if (!route) {
    return [];
  }
  route.sections.forEach((section, id) => {
    const spans = [];
    let shape;
    let shapeLength;
    if (section.polyline) {
      shape = decode(section.polyline);
      shapeLength = shape.length;
    }
    if (!section.spans) {
      return;
    }

    section.spans.forEach((span, index) => {
      const nextSpan = section.spans[index + 1] || { offset: shapeLength };
      let spanShape = [];
      if (shape) {
        spanShape = shape
          .slice(span.offset, nextSpan.offset + 1)
          .map(([lat, lng]) => ({
            lat: +lat,
            lng: +lng,
          }));
      }
      const { names = [], routeNumbers = [] } = span;
      let instruction;
      if (names.length || routeNumbers.length) {
        instruction = [...routeNumbers, ...names]
          .map((name) => name.value)
          .join(', ');
      } else {
        instruction = Object.keys(span)
          .filter((key) => key !== 'offset')
          .join(', ');
      }
      const details = Object.keys(span).reduce((acc, spanKey) => {
        if (spanKey === 'notices') {
          const noticesRaw = span[spanKey].map(
            (noticeIndex) => section.notices[noticeIndex]
          );
          return {
            ...acc,
            noticesRaw: noticesRaw,
            [spanKey]: span[spanKey],
            // ['noticesRaw']: JSON.stringify(noticesRaw, null, 2),
            // [spanKey]: JSON.stringify(span[spanKey], null, 2),
          };
        }
        return {
          ...acc,
          [spanKey]: JSON.stringify(span[spanKey], null, 2),
        };
      }, {});
      spans.push({
        instruction: `${index}. ${instruction}`,
        shape: spanShape.length ? spanShape : null,
        circle: span.duration === 0 ? spanShape[0] : null,
        details,
      });
    });
    res.sections.push({spans, id});
  });

  return res;
}
