import { USE_CIT } from '../state/map/tiles/constants';

const tilesV3 = {
  type: [
    {
      label: 'Base',
      value: 'base',
      serviceType: 'base',
    },
  ],
  useCIT: [
    { label: 'Auto', value: USE_CIT.AUTO },
    { label: 'Yes', value: `${USE_CIT.YES}` },
    { label: 'No', value: `${USE_CIT.NO}` },
  ],
  style: [
    {
      label: 'explore.day',
      value: 'explore.day',
      supportedTypes: ['base'],
    },
    {
      label: 'explore.night',
      value: 'explore.night',
      supportedTypes: ['base'],
    },
    {
      label: 'explore.satellite.day',
      value: 'explore.satellite.day',
      supportedTypes: ['base'],
    },
    {
      label: 'lite.day',
      value: 'lite.day',
      supportedTypes: ['base'],
    },
    {
      label: 'satellite.day',
      value: 'satellite.day',
      supportedTypes: ['base'],
    },
    {
      label: 'logistics.day',
      value: 'logistics.day',
      supportedTypes: ['base'],
    },
  ],
  ppi: [
    { label: '-auto-', value: '-auto-' },
    { label: '-none-', value: '-none-' },
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '400', value: '400' },
  ],
  size: [
    { label: '-none-', value: '-none-' },
    { label: '256 × 256', value: '256' },
    { label: '512 × 512', value: '512' },
  ],
  format: [
    { label: 'png', value: 'png' },
    { label: 'png8', value: 'png8' },
    { label: 'jpeg', value: 'jpeg' },
  ],
  languages: [
    { value: '-none-', label: '-none-' },
    { value: 'ar', label: 'Arabic' },
    { value: 'as', label: 'Assamese' },
    { value: 'az', label: 'Azerbaijani' },
    { value: 'be', label: 'Belarusian' },
    { value: 'bg', label: 'Bulgarian' },
    { value: 'bn', label: 'Bengali' },
    { value: 'bs', label: 'Bosnian' },
    { value: 'ca', label: 'Catalan' },
    { value: 'cs', label: 'Czech' },
    { value: 'cy', label: 'Welsh' },
    { value: 'da', label: 'Danish' },
    { value: 'de', label: 'German' },
    { value: 'el', label: 'Greek' },
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'et', label: 'Estonian' },
    { value: 'eu', label: 'Basque' },
    { value: 'fi', label: 'Finnish' },
    { value: 'fo', label: 'Faroese' },
    { value: 'fr', label: 'French' },
    { value: 'ga', label: 'Irish' },
    { value: 'gl', label: 'Galiciangl' },
    { value: 'gn', label: 'Guarani' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'he', label: 'Hebrew' },
    { value: 'hi', label: 'Hindi' },
    { value: 'hr', label: 'Croatian' },
    { value: 'hu', label: 'Hungarian' },
    { value: 'hy', label: 'Armenian' },
    { value: 'id', label: 'Indonesian' },
    { value: 'is', label: 'Icelandic' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ka', label: 'Georgian' },
    { value: 'kk', label: 'Kazakh' },
    { value: 'km', label: 'Cambodian' },
    { value: 'kn', label: 'Kannada' },
    { value: 'ko', label: 'Korean' },
    { value: 'ky', label: 'Kirghiz' },
    { value: 'lt', label: 'Lithuanian' },
    { value: 'lv', label: 'Latvian' },
    { value: 'mk', label: 'Macedonian' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'mr', label: 'Marathi' },
    { value: 'ms', label: 'Malay' },
    { value: 'mt', label: 'Maltese' },
    { value: 'my', label: 'Burmese' },
    { value: 'nl', label: 'Dutch' },
    { value: 'no', label: 'Norwegian' },
    { value: 'or', label: 'Oriya' },
    { value: 'pa', label: 'Panjabi' },
    { value: 'pl', label: 'Polish' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'ro', label: 'Romanian' },
    { value: 'ru', label: 'Russian' },
    { value: 'sk', label: 'Slovak' },
    { value: 'sl', label: 'Slovenian' },
    { value: 'sq', label: 'Albanian' },
    { value: 'sr', label: 'Serbian' },
    { value: 'sv', label: 'Swedish' },
    { value: 'ta', label: 'Tamil' },
    { value: 'te', label: 'Telugu' },
    { value: 'th', label: 'Thai' },
    { value: 'tr', label: 'Turkish' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'uz', label: 'Uzbek' },
    { value: 'vi', label: 'Vietnamese' },
    { value: 'zh', label: 'Chinese' },
    { value: 'zh-Hant', label: 'zh-Hant' },
  ],
  politicalView: [
    { value: '-none-', label: '-none-' },
    { value: 'ARG', label: 'Argentina' },
    { value: 'CYP', label: 'Cyprus' },
    { value: 'EGY', label: 'Egypt' },
    { value: 'IND', label: 'India' },
    { value: 'KEN', label: 'Kenya' },
    { value: 'MAR', label: 'Morocco' },
    { value: 'PAK', label: 'Pakistan' },
    { value: 'PSE', label: 'Palestinian Territory' },
    { value: 'RUS', label: 'Russia' },
    { value: 'SDN', label: 'Sudan' },
    { value: 'SRB', label: 'Serbia' },
    { value: 'SUR', label: 'Suriname' },
    { value: 'SYR', label: 'Syria' },
    { value: 'TZA', label: 'Tanzania' },
    { value: 'URY', label: 'Uruguay' },
    { value: 'VNM', label: 'Vietnam' },
  ],
  projection: [
    { value: 'mc', label: 'mc'},
  ],
  fPois: [
    { value: '-none-', label: '-none-' },
    { value: 'disabled', label: 'disabled' },
    { value: 'enabled', label: 'enabled' },
  ],
  fCongestionZones: [
    { value: '-none-', label: '-none-' },
    { value: 'disabled', label: 'disabled' },
    { value: 'enabled', label: 'enabled' },
  ],
  fEnvZones: [
    { value: '-none-', label: '-none-' },
    { value: 'disabled', label: 'disabled' },
    { value: 'enabled', label: 'enabled' },
  ],
  fVehicleRestrictions: [
    { value: '-none-', label: '-none-' },
    { value: 'active_and_inactive', label: 'active_and_inactive' }
  ]
};

export default tilesV3;
