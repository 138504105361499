import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { Input } from '@here/ref-client-ui';
import './colorPickerDropDown.scss';
import OutsideClickHandler from '../shared/outsideClickHandler';

const checkColor = (color) => /#[a-fA-F0-9]{6}/.test(color);

function ColorPickerDropDown({ label, color, onChange }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const onChangeInput = ({ target: { value } }) => {
    onChange(value);
  };

  const onChangePicker = ({ hex }) => {
    onChange(hex);
  };

  return (
    <div className="rf-color-picker">
      <Input
        label={label}
        value={color}
        isValid={checkColor(color)}
        onBlur={onChangeInput}
      />
      <div
        className="rf-color-picker__color"
        onClick={() => setShowDropdown(true)}
        style={{ backgroundColor: color }}
      >
        {showDropdown && (
          <OutsideClickHandler outsideClick={() => setShowDropdown(false)}>
            <SketchPicker color={color} onChange={onChangePicker} />
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
}

ColorPickerDropDown.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorPickerDropDown;
