import React from 'react';
import PropTypes from 'prop-types';
import { Polygon, Pane } from 'react-leaflet';

function LeafletPolygon({ positions, fillColor, strokeColor, zIndex }) {
  const props = { positions };
  if (fillColor) {
    props.fillColor = fillColor;
    props.fillOpacity = 1;
  }
  if (strokeColor) {
    props.color = strokeColor;
  }
  const polygonEl = <Polygon {...props} />;
  if (zIndex != null) {
    return <Pane style={{ zIndex }}>{polygonEl}</Pane>;
  } else {
    return polygonEl;
  }
}

LeafletPolygon.propTypes = {
  zIndex: PropTypes.number,
};

export default LeafletPolygon;
