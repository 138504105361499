import React from 'react';
import PropTypes from 'prop-types';

import Datetime from 'react-datetime';
import moment from 'moment';

import CustomComponent from 'shared/component';
import { Input } from '@here/ref-client-ui';
import utilsDate from 'utils/date';
import classnames from 'classnames';
import { get } from 'lodash';

import 'react-datetime/css/react-datetime.css';

import './styles/datetime.scss';

export class CustomDateTime extends CustomComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateTimeOpen: false,
    };
    this.dateTimeInputRef = React.createRef();
    this.dateTimeRef = React.createRef();
  }

  onInputChange = (e) => {
    this.props.onChange(e.target.value);
  };

  onDateTimeChange = (momentDate) => {
    const { value, onChange } = this.props;
    const { timezone } = utilsDate.splitDate(value);
    onChange(`${momentDate.format('YYYY-MM-DDTHH:mm:ss')}${timezone}`);
  };

  toggleDateTime = (e) => {
    e.nativeEvent.stopImmediatePropagation();

    if (this.props.isReadonly) {
      return;
    }
    if (this.state.dateTimeOpen) {
      this.closeDateTime();
      return;
    }
    this.setState({ dateTimeOpen: true });
    document.addEventListener('click', this.closeDateTime, false);
  };

  closeDateTime = () => {
    this.setState({ dateTimeOpen: false });
    document.removeEventListener('click', this.closeDateTime, false);
  };

  renderDatetime = (mode, renderDefault) => {
    const { dateTimeOpen } = this.state;
    if (!dateTimeOpen) {
      return null;
    }
    const inputNode = get(this, 'dateTimeInputRef.current.inputRef.current');
    if (inputNode) {
      const inputBounds = inputNode.getBoundingClientRect();
      const styles = {
        bottom: window.innerHeight - inputBounds.top,
        left: inputBounds.left,
      };
      return (
        <div className="rf-datetime__picker" style={styles}>
          {renderDefault()}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { label, value, isReadonly } = this.props;
    const { date } = utilsDate.splitDate(value);
    const { dateTimeOpen } = this.state;
    const labelEl = label ? (
      <span className="rf-datetime__caption">{label}</span>
    ) : (
      ''
    );
    const calendarBtnClasses = classnames('rf-datetime__calendar-btn', {
      'rf-datetime__calendar-btn_readonly': isReadonly,
    });
    const momentValue = moment.utc(date);

    return (
      <div className={this.getCssClasses(['rf-datetime'])}>
        {labelEl}
        <div className={calendarBtnClasses}>
          <i className="rf-datetime-icon" onClick={this.toggleDateTime} />
        </div>
        <Input
          blurOnEnter
          debounce
          onBlur={this.onInputChange}
          value={value}
          ref={this.dateTimeInputRef}
          disabled={isReadonly}
          onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
        />
        <div onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
          <Datetime
            ref={this.dateTimeRef}
            className="rf-datetime__field"
            value={value && momentValue.isValid() ? momentValue : value}
            closeOnSelect
            onChange={this.onDateTimeChange}
            renderView={this.renderDatetime.bind(this)}
            renderInput={() => null}
            open={dateTimeOpen}
            utc
          />
        </div>
      </div>
    );
  }
}

CustomDateTime.defaultProps = {
  value: '',
  isReadonly: false,
};

CustomDateTime.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomDateTime;
