import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';
import { addEventListeners, removeEventListeners } from './mapEvents';

class HereCircle extends React.Component {
  circle = null;

  componentDidMount() {
    this.addCircle();
  }

  componentDidUpdate(prevProps) {
    const { center, radius, options } = this.props;

    if (
      !isEqual(center, prevProps.center) ||
      !isEqual(options, prevProps.options) ||
      radius !== prevProps.radius
    ) {
      this.removeCircle();
      this.addCircle();
    }
  }

  componentWillUnmount() {
    this.removeCircle();
  }

  addCircle = () => {
    const { center, radius, options, map, group, draggable, resizable } =
      this.props;
    this.circle = new window.H.map.Circle(center, radius, options);
    addEventListeners(this.props, this.circle);
    if (group) {
      group.addObject(this.circle);
    } else {
      map.addObject(this.circle);
    }

    if (draggable || resizable) {
      this.circle.draggable = true;
      this.initInteractionEvents();
    }
  };

  removeCircle = () => {
    const { group } = this.props;

    removeEventListeners(this.props, this.circle);
    if (this.circle && group && group.contains(this.circle)) {
      group.removeObject(this.circle);
    }
  };

  initInteractionEvents = () => {
    this.circle.addEventListener('dragstart', this.onDragStart);
    this.circle.addEventListener('drag', this.onDrag);
    this.circle.addEventListener('dragend', this.onDragEnd);

    this.circle.addEventListener('pointermove', this.onPointerMove);
    this.circle.addEventListener('pointerleave', this.onPointerLeave);
  };

  onDragStart = (e) => {
    if (!this.isDrag && !this.isResize) {
      return;
    }
    let {
      currentPointer: { viewportX, viewportY },
    } = e;
    const { map } = this.props;
    this.mouseStart = map.screenToGeo(viewportX, viewportY);
    this.centerStart = this.circle.getCenter();
    e.stopPropagation();
  };

  onDrag = (e) => {
    if (!this.isDrag && !this.isResize) {
      return;
    }
    const { map } = this.props;
    const {
      currentPointer: { viewportX, viewportY },
    } = e;
    const currentCoords = map.screenToGeo(viewportX, viewportY);

    if (this.isDrag) {
      const deltaLat = this.mouseStart.lat - currentCoords.lat;
      const deltaLng = this.mouseStart.lng - currentCoords.lng;
      const centerPoint = {
        lat: this.centerStart.lat - deltaLat,
        lng: this.centerStart.lng - deltaLng,
      };
      this.circle.setCenter(centerPoint);
    } else if (this.isResize) {
      this.circle.setRadius(this.centerStart.distance(currentCoords));
    }
    e.stopPropagation();
  };

  onDragEnd = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({
        center: this.circle.getCenter(),
        radius: this.circle.getRadius(),
      });
    }
  };

  onPointerMove = (e) => {
    const { map, resizePadding, draggable, resizable } = this.props;
    const {
      currentPointer: { viewportY, viewportX },
    } = e;
    const currentCoords = map.screenToGeo(viewportX, viewportY);
    const point = new window.H.math.Point(viewportX, viewportY);
    const center = this.circle.getCenter();
    const centerPoint = map.geoToScreen(center);
    const top = this.circle.getBoundingBox().getTop();
    const topPoint = map.geoToScreen({ lat: top, lng: center.lng });
    const radius = centerPoint.distance(topPoint);
    const distance = centerPoint.distance(point);
    const padding = resizePadding || 10;
    this.isResize = radius - distance <= padding && resizable;
    let resizeCursor;
    if (this.isResize) {
      this.isDrag = false;
      resizeCursor = 'nesw-resize';
      if (
        (currentCoords.lat > center.lat && currentCoords.lng < center.lng) ||
        (currentCoords.lat < center.lat && currentCoords.lng > center.lng)
      ) {
        resizeCursor = 'nwse-resize';
      }
      map.getElement().style.cursor = resizeCursor;
    } else if (draggable) {
      this.isDrag = true;
      map.getElement().style.cursor = 'move';
    } else {
      map.getElement().style.cursor = 'auto';
    }
  };

  onPointerLeave = () => {
    const { map } = this.props;
    map.getElement().style.cursor = 'auto';
  };

  render() {
    return null;
  }
}

HereCircle.propTypes = {
  center: PropTypes.object.isRequired,
  radius: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  onChange: PropTypes.func,
  resizePadding: PropTypes.number,
};

export default withHereMap(HereCircle);
