import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import {
  Button,
  CloseButton,
  ExpandFormRow,
  FormRow,
  Input,
} from '@here/ref-client-ui';
import set from 'lodash/set';
import get from 'lodash/get';
import './multiItems.scss';

function MultipleItems(props) {
  const { fields, options, setFields } = props;
  const {
    key,
    label,
    placeholder,
    openapiInfo: openapiInfoOptions,
    tooltip,
  } = options;
  const [openapiInfo, setOpenapiInfo] = useState(null);
  const isUnmounted = useRef(false);

  useEffect(() => {
    if (openapiInfoOptions) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfoOptions;
      const propsUrl = get(props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys).then((openapiInfo) => {
        if (!isUnmounted.current) {
          setOpenapiInfo(openapiInfo);
        }
      });
    }

    return () => {
      isUnmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTooltip = () => {
    let tooltipEl = null;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  const items = fields[key];

  const onAdd = () => {
    setFields({ [key]: [...items, ''] });
  };

  const onChange = ({ target: { value } }, index) => {
    setFields({
      [key]: [...items.slice(0, index), value, ...items.slice(index + 1)],
    });
  };

  const onRemove = (index) => {
    setFields({ [key]: [...items.slice(0, index), ...items.slice(index + 1)] });
  };

  const els = items.map((item, index) => (
    <FormRow key={index} className="rf-multi-items__item">
      <Input
        value={item}
        placeholder={placeholder}
        onBlur={(e) => onChange(e, index)}
      />
      <CloseButton onClick={() => onRemove(index)} />
    </FormRow>
  ));
  return (
    <ExpandFormRow
      label={label}
      isExpanded
      className="rf-multi-items"
      tooltip={getTooltip()}
      tooltipPlacement="right"
    >
      {els}
      <FormRow className="rf-multi-items__btn">
        <Button title="Add item" onClick={onAdd} />
      </FormRow>
    </ExpandFormRow>
  );
}

MultipleItems.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData }) => {
    const { key, isPost } = options;
    const keyLowerCase = key.toLowerCase();
    if (postData && isPost) {
      return { [key]: get(postData, key, []) };
    }
    const res = { [key]: parsedParams[keyLowerCase] || [] };
    delete parsedParams[keyLowerCase];
    return res;
  },
  defaultState: (options) => ({
    [options.key]: [],
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;

    if (!fields[key] || fields[key].length === 0) return {};

    if (isPost) {
      const data = {};
      set(data, key, fields[key]);
      return {
        method: 'post',
        data,
      };
    } else {
      return {
        params: { [key]: fields[key].join(',') },
      };
    }
  },
  Component: MultipleItems,
};
