import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import HereRoute from '../routes/HereRoute';

class HerePtRoutes extends React.Component {
  selectRoute = (currentRoute) => {
    const { setResultState } = this.props;
    setResultState({ currentRoute });
  };

  getPositions = (sections, routePath, formatter) => {
    const sectionsCoords = [];
    sections.forEach((section) => {
      sectionsCoords.push(get(section, routePath));
    });
    return sectionsCoords.reduce((acc, sectionCoords) => {
      const coords = formatter ? formatter(sectionCoords) : sectionCoords;
      if (coords) {
        return [...acc, ...coords];
      } else {
        return acc;
      }
    }, []);
  };

  renderRoutes = (
    routes,
    routePath,
    formatter,
    colorPalette,
    sectionsPath,
    sectionTypePath,
    transportColorPath
  ) => {
    const {
      options: { selectedPath, highlightedPath },
    } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);
    const polylines = [];
    routes.forEach((routeWrapper, routeIndex) => {
      const sections = get(routeWrapper, sectionsPath, []);
      if (routeIndex === highlightedRoute) {
        const positions = this.getPositions(sections, routePath, formatter);
        if (positions && positions.length > 2) {
          polylines.push(
            <HereRoute
              changeCursorOnHover
              onClick={() => this.selectRoute(routeIndex)}
              key={routeIndex}
              positions={positions}
              color={colorPalette.secondaryDarker}
              weight={8}
              zIndex={30}
            />
          );
        }
      } else if (routeIndex === selectedRoute) {
        sections.forEach((section, sectionIndex) => {
          const coords = get(section, routePath);
          const positions = formatter ? formatter(coords) : coords;

          let transportColor = get(section, transportColorPath);
          if (
            !transportColor &&
            get(section, sectionTypePath) === 'pedestrian'
          ) {
            transportColor = 'gray';
          }
          if (positions && positions.length > 2) {
            polylines.push(
              <HereRoute
                key={`${routeIndex}_${sectionIndex}`}
                positions={positions}
                color={transportColor || colorPalette.primary}
                weight={8}
                zIndex={20}
              />
            );
          }
        });
      } else {
        const positions = this.getPositions(sections, routePath, formatter);
        if (positions && positions.length > 2) {
          polylines.push(
            <HereRoute
              changeCursorOnHover
              onClick={() => this.selectRoute(routeIndex)}
              key={routeIndex}
              positions={positions}
              color={colorPalette.secondary}
              weight={6}
              zIndex={10}
            />
          );
        }
      }
    });
    return [...polylines];
  };

  render() {
    const {
      options,
      colorPalette,
      refClient: { formatterPlugin },
    } = this.props;
    const {
      routesArrayPath,
      routePath,
      format,
      sectionsPath,
      sectionTypePath,
      transportColorPath,
    } = options;
    const formatter = formatterPlugin.get(format);
    const routes = get(this.props, routesArrayPath);
    if (!routes) {
      return null;
    }
    return this.renderRoutes(
      routes,
      routePath,
      formatter,
      colorPalette,
      sectionsPath,
      sectionTypePath,
      transportColorPath
    );
  }
}

HerePtRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default HerePtRoutes;
