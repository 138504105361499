import L from 'leaflet';
import { divIcon } from 'leaflet';

const fontSize = 15;
export function createHereText(color, text, bearing = 0, middle) {
  const textWidth = Math.max(context.measureText(text).width, 20);
  const width = textWidth * 2 + 10;
  const height = width;

  let translateX = textWidth/-2;
  let translateY = 23;
  if (bearing > 90 && bearing < 270) {
    bearing = bearing - 180;
    translateX = textWidth/2;
    translateY = -15;
  }
  middle = middle ? 1 : 0;

  const svg = `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
      <text 
        x="${width/2}" 
        y="${height/2}" 
        font-family="Arial" 
        font-weight="bold" 
        font-size="${fontSize}px" 
        stroke="white"
        stroke-width="2"
        fill="${color}" 
        text-anchor="middle" 
        paint-order="stroke"
        transform="rotate(${bearing},${width/2},${height/2}) translate(${translateX * middle} ${translateY}) ">
        ${text}
      </text>
    </svg>`;

  const MapIcon = new window.H.map.Icon(svg, ({
    'anchor': {
      'x': width/2,
      'y': height/2
    }
  }));

  return MapIcon;
}

export function createHerePoint() {
  var canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const width = 14;
  const height = 14;
  const radius = 5;

  canvas.width = width;
  canvas.height = height;

  ctx.beginPath();
  ctx.arc(width/2, 7, radius, 0, 2 * Math.PI, false);
  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.lineWidth = 3;
  ctx.strokeStyle = '#000';
  ctx.stroke();
  ctx.closePath();

  const MapIcon = new window.H.map.Icon(canvas, ({
    'anchor': {
      'x': width/2,
      'y': height/2
    }
  }));

  return MapIcon;
}

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
context.font = 'bold '+fontSize+'px Arial';

export function createLeafletIcon(color, text, type, bearing=0, middle) {
  if (type === 'text') {
    // Estimate the width of the text
    const textWidth = context.measureText(text).width + 10;
    const width = textWidth * 2 + 10;
    const height = width;
  
    let translateX = textWidth/-2;
    let translateY = 23;
    if (bearing > 90 && bearing < 270) {
      bearing = bearing - 180;
      translateX = textWidth/2;
      translateY = -15;
    }
    middle = middle ? 1 : 0;

    const svg = `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
      <text 
        x="${width/2}" 
        y="${height/2}" 
        font-family="Arial" 
        font-weight="bold" 
        font-size="${fontSize}px" 
        stroke="white"
        stroke-width="2"
        fill="${color}" 
        text-anchor="middle" 
        paint-order="stroke"
        transform="rotate(${bearing},${width/2},${height/2}) translate(${translateX * middle} ${translateY}) ">
        ${text}
      </text>
    </svg>`;

    const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    const icon = new L.Icon({
      iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: [width/2, height/2],
      iconSize: [width, height],
    });
  
    return icon;
  } else {
    return divIcon({
      html: `<div class="rf-distance-measurement__marker">${text}</div>`,
    });
  }
}