import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'shared/toggleButton';
import './paramsMapping.scss';

function ParamsMapping({ paramsMapping, setTabParamsMapping }) {
  const onToggle = ({ target: { checked } }) => {
    setTabParamsMapping(checked);
  };
  return (
    <div className="rf-params-mapping">
      <ToggleButton
        checked={paramsMapping}
        onChange={onToggle}
        label="Params mapping is disabled. To enable it please toggle."
      />
    </div>
  );
}

ParamsMapping.propTypes = {
  setTabParamsMapping: PropTypes.func.isRequired,
  paramsMapping: PropTypes.bool.isRequired,
};

export default ParamsMapping;
