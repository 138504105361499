import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Columns, ExpandFormRow } from '@here/ref-client-ui';
import FeatureRow from './FeatureRow';

class FeaturesGroup extends Component {
  getFeatureRows() {
    let { features = {}, supportedFeatures, featureSet } = this.props;

    return supportedFeatures.map((feature, id) => {
      let data = features[feature.value] || {};
      return (
        <FeatureRow
          key={id}
          label={feature.label}
          value={feature.value}
          selectValue={data.value}
          isChecked={data.isChecked}
          featureSet={featureSet}
          onChange={this.props.onChange}
        />
      );
    });
  }

  render() {
    return (
      <ExpandFormRow label={this.props.label} className="rf-features-group">
        <Columns>{this.getFeatureRows()}</Columns>
      </ExpandFormRow>
    );
  }
}

FeaturesGroup.defaultProps = {
  features: {},
};

FeaturesGroup.propTypes = {
  label: PropTypes.string.isRequired,
  features: PropTypes.object.isRequired,
  supportedFeatures: PropTypes.array.isRequired,
  featureSet: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FeaturesGroup;
