import ReverseIcon from './ReverseIcon';
import './reverseOriginDestination.scss';
import { Tooltip } from '@here/ref-client-ui';

function ReverseOriginDestination({ setFields, fields }) {
  const reverse = () => {
    const originKeys = Object.keys(fields).filter((key) =>
      key.startsWith('origin')
    );
    const newFields = {};

    originKeys.forEach((key) => {
      const destinationKey = key.replace('origin', 'destination');
      newFields[key] = fields[destinationKey];
      newFields[destinationKey] = fields[key];
    });

    setFields(newFields);
  };

  return (
    <div className="rf-reverse" onClick={reverse}>
      <Tooltip tooltip="Reverse origin, destination" placement="bottom">
        <ReverseIcon />
      </Tooltip>
    </div>
  );
}

const reverseOriginDestinationPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: ReverseOriginDestination,
};

export default reverseOriginDestinationPlugin;
