import { BodyEnd, FormRow } from '@here/ref-client-ui';
import OutsideClickHandler from '@here/ref-client-ui/lib/outsideClickHandler';

function HoveredStationTooltip({
  position: { top, left },
  stations,
  onMouseEnter,
  onMouseLeave,
}) {
  const stationEls = stations.map(({ ports, brandName }, stationIndex) => (
    <div className="rf-hovered-station-tooltip__station" key={stationIndex}>
      {ports.map((port, index) => (
        <FormRow key={`${port}${index}${stationIndex}`}>
          <label className="rf-input rf-input_readonly">
            <strong className="rf-input__caption">
              Port {index + 1}: {port}
            </strong>
          </label>
        </FormRow>
      ))}
      <FormRow>
        <label className="rf-input rf-input_readonly">
          <strong className="rf-input__caption">Brand: {brandName}</strong>
        </label>
      </FormRow>
    </div>
  ));
  return (
    <BodyEnd>
      <OutsideClickHandler outsideClick={onMouseLeave}>
        <div
          className="rf-hovered-station-tooltip"
          style={{ left: left - 15, top: top - 15 }}
          onMouseEnter={onMouseEnter}
        >
          {stationEls}
        </div>
      </OutsideClickHandler>
    </BodyEnd>
  );
}

export default HoveredStationTooltip;
