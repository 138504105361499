import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'shared/radioGroup';
import Slider from 'rc-slider';
import './olsIsolineSummary.scss';
import range from 'lodash/range';
import get from 'lodash/get';
import { FormRow } from '@here/ref-client-ui';

class OlsIsolineSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: get(props, 'result.state.selectedIsolineOpacity', 0.5),
    };
  }

  setSelectedIsoline = (value) => {
    const { setResultState } = this.props;
    setResultState({ selectedIsoline: +value });
  };

  setOpacity = (value) => {
    if (value >= 0.5) {
      this.setState({ opacity: value });
    }
  };

  setSelectedIsolineOpacity = (value) => {
    const { setResultState } = this.props;
    setResultState({ selectedIsolineOpacity: value });
  };

  render() {
    const {
      result: { raw = {}, state },
    } = this.props;
    const { opacity } = this.state;
    const options = range(get(raw, 'data.isolines', []).length).map(
      (index) => ({
        label: `Isoline ${index}`,
        value: `${index}`,
      })
    );
    const value = `${state.selectedIsoline || 0}`;

    return (
      <div className="rf-ols-isoline-summary">
        <FormRow>
          <h2>Selected isoline opacity: {opacity}</h2>
          <div className="rf-ols-isoline-summary__slider">
            <Slider
              value={opacity}
              onChange={this.setOpacity}
              onAfterChange={this.setSelectedIsolineOpacity}
              min={0}
              step={0.1}
              max={1}
              dots
            />
          </div>
        </FormRow>
        <RadioGroup
          label="Isolines"
          options={options}
          value={value}
          columns={1}
          onChange={this.setSelectedIsoline}
        />
      </div>
    );
  }
}

OlsIsolineSummary.propTypes = {
  result: PropTypes.object,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default OlsIsolineSummary;
