import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import RectangleList from './RectangleList';
import EditableRectangle from 'shared/editableRectangle';
import HereRect from './HereRect';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: RectangleList(EditableRectangle),
  [TILE_LIBRARY_TYPES.JSLA]: RectangleList(HereRect),
};
