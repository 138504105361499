import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Pane, Polyline } from 'react-leaflet';

class LeafletPtRoutes extends React.Component {
  selectRoute = (currentRoute) => {
    const { setResultState } = this.props;
    setResultState({ currentRoute });
  };

  getPositions = (sections, routePath, formatter) => {
    const sectionsCoords = [];
    sections.forEach((section) => {
      sectionsCoords.push(get(section, routePath));
    });
    return sectionsCoords.reduce(
      (acc, sectionCoords) => [
        ...acc,
        ...(formatter ? formatter(sectionCoords) : sectionCoords),
      ],
      []
    );
  };

  renderRoutes = (
    routes,
    routePath,
    formatter,
    colorPalette,
    sectionsPath,
    sectionTypePath,
    transportColorPath
  ) => {
    const {
      options: { selectedPath, highlightedPath },
    } = this.props;
    const selectedRoute = get(this.props, selectedPath, 0);
    const highlightedRoute = get(this.props, highlightedPath, -1);
    const selectedPolylines = [];
    const polylines = [];
    let highlightedPane;
    routes.forEach((routeWrapper, routeIndex) => {
      const sections = get(routeWrapper, sectionsPath, []);
      if (routeIndex === highlightedRoute) {
        const positions = this.getPositions(sections, routePath, formatter);
        highlightedPane = (
          <Pane key="highlightedRoute" style={{ zIndex: 430 }}>
            <Polyline
              onClick={() => this.selectRoute(routeIndex)}
              key={routeIndex}
              positions={positions}
              color={colorPalette.secondaryDarker}
              weight={8}
            />
          </Pane>
        );
      } else if (routeIndex === selectedRoute) {
        sections.forEach((section, sectionIndex) => {
          const coords = get(section, routePath);
          const positions = formatter ? formatter(coords) : coords;
          let transportColor = get(section, transportColorPath);
          if (
            !transportColor &&
            get(section, sectionTypePath) === 'pedestrian'
          ) {
            transportColor = 'gray';
          }
          selectedPolylines.push(
            <Polyline
              key={`${routeIndex}_${sectionIndex}`}
              positions={positions}
              color={transportColor || colorPalette.primary}
              weight={8}
            />
          );
        });
      } else {
        const positions = this.getPositions(sections, routePath, formatter);
        polylines.push(
          <Polyline
            onClick={() => this.selectRoute(routeIndex)}
            key={routeIndex}
            positions={positions}
            color={colorPalette.secondary}
            weight={6}
          />
        );
      }
    });

    const selectedPane = (
      <Pane key="selectedRoute" style={{ zIndex: 420 }}>
        {[...selectedPolylines]}
      </Pane>
    );

    const normalPane = (
      <Pane key="normalRoutes" style={{ zIndex: 410 }}>
        {[...polylines]}
      </Pane>
    );

    return [selectedPane, highlightedPane, normalPane];
  };

  render() {
    const {
      options,
      colorPalette,
      refClient: { formatterPlugin },
    } = this.props;
    const {
      routesArrayPath,
      routePath,
      format,
      sectionsPath,
      sectionTypePath,
      transportColorPath,
    } = options;
    const formatter = formatterPlugin.get(format);
    const routes = get(this.props, routesArrayPath);
    if (!routes) {
      return null;
    }
    return this.renderRoutes(
      routes,
      routePath,
      formatter,
      colorPalette,
      sectionsPath,
      sectionTypePath,
      transportColorPath
    );
  }
}

LeafletPtRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default LeafletPtRoutes;
