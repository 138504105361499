export function latLngToTile({ lat, lng }, zoom) {
  // eslint-disable-next-line no-bitwise
  const scale = 1 << zoom;
  return {
    x: lngToTile(lng, scale),
    y: latToTile(lat, scale),
  };
}

export function tileToLatLng({ x, y }, zoom) {
  // eslint-disable-next-line no-bitwise
  const scale = 1 << zoom;
  return {
    lat: tileToLat(y, scale),
    lng: tileToLng(x, scale),
  };
}

function lngToTile(lng, scale) {
  return Math.floor(((lng + 180) / 360) * scale);
}

function latToTile(lat, scale) {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      scale
  );
}

function tileToLng(x, scale) {
  return (x / scale) * 360 - 180;
}
function tileToLat(y, scale) {
  const n = Math.PI - (2 * Math.PI * y) / scale;
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
}
