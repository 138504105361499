const toFixed6 = (val) => +val.toFixed(6);

// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e) => {
  const {
    mapAttributes: { routerOriginDest = "" },
  } = fields;
  const coords = routerOriginDest.split(';');
  const {
    latLng: { lat, lng }
  } = e;

  return {
    mapAttributes: {
        type: "routerOriginDest",
        routerOriginDest: `${toFixed6(lat)},${toFixed6(lng)};${coords[1]||""}`
    }
  };
};
