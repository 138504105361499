import {
  ADD_CUSTOM_CONFIG,
  DELETE_CONFIG,
  DUPLICATE_CONFIG,
  SET_CONFIG,
} from './constants';
import localStorageReducerDecorator from '../../../utils/localStorageReducerDecorator';
import { getTabs } from '../../tabs';
import { set as setNotification } from 'state/notification/actions';

const customConfigsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CUSTOM_CONFIG:
      return {
        ...state,
        [action.payload.title]: action.payload,
      };
    case DUPLICATE_CONFIG:
      return {
        ...state,
        [action.payload.title]: action.payload,
      };
    case SET_CONFIG: {
      const nextState = { ...state };
      delete nextState[action.payload.title];

      return {
        ...nextState,
        [action.payload.config.title]: action.payload.config,
      };
    }
    case DELETE_CONFIG: {
      const nextState = { ...state };
      delete nextState[action.payload];

      return nextState;
    }
    default:
      return state;
  }
};

export default localStorageReducerDecorator(
  customConfigsReducer,
  'v1.customConfigs',
  {}
);

export const duplicateConfig = (config) => {
  const newTitle = `${config.title} copy`;
  const newConfig = {
    ...config,
    title: newTitle,
  };
  return { type: DUPLICATE_CONFIG, payload: newConfig };
};

export const setConfig = (title, config) => ({
  type: SET_CONFIG,
  payload: { config, title },
});

export const addConfig = (config) => ({
  type: ADD_CUSTOM_CONFIG,
  payload: config,
});

export const deleteConfig = (title) => (dispatch, getState) => {
  const tabs = getTabs(getState());
  if (tabs.find((tab) => tab.title === title)) {
    dispatch(
      setNotification({
        message: `Close all "${title}" tabs before removing config.`,
        impact: 'significant',
        autoDismiss: 0,
      })
    );
  } else {
    dispatch({ type: DELETE_CONFIG, payload: title });
  }
};

export const getCustomConfigs = (state) => state.config.custom;
