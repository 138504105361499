function callback(e, setFields, fields) {
  const { lat, lng } = e.latLng;
  const { waypoints } = fields;
  const oldValue = waypoints[0];

  const coords = `${lat.toFixed(6)},${lng.toFixed(6)}`;
  const value = { ...oldValue, coords };
  const newWaypoints = [value, ...waypoints.slice(1)];
  setFields({ waypoints: newWaypoints });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ setFields, fields }) {
  return {
    text: 'Route from',
    callback: (e) => callback(e, setFields, fields),
  };
}
