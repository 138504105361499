// Upgrading LUI to 2.0 makes lui-button, lui-overlay looks big different from older version.
// this function adjusts style of LUI component to make them look similar
// we need to upgrade from LUI to HDS to solve UI issues completely.
const adjustLUIStyle = function (elements, component) {
  if( component === 'lui-button' ) {
    elements.forEach(b => {
      const style = document.createElement( 'style' );
      style.innerHTML = '.lui-button__seed { background: #2dd5c9;}; '
  
      b.shadowRoot.appendChild( style );
    })
  } else if( component === 'lui-overlay' ) {
    elements.forEach( o => {
      const luiTextfields = o.querySelectorAll('.rf-input__field');
      luiTextfields.forEach(f => {
        const style = document.createElement( 'style' );
        style.innerHTML = '.lui-textfield__wrapper { background: hsla(0,0%,100%,.1); }';
        f.shadowRoot.appendChild( style );
      })

      const style = document.createElement( 'style' );
      style.innerHTML = '.lui-overlay__seed { background: #272d37; color: #fff; }';
  
      o.shadowRoot.appendChild( style );
    })
  }
};

export default adjustLUIStyle;
