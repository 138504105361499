import {
  ArtifactClient,
  SchemaDetailsRequest,
  SchemaRequest,
} from '@here/olp-sdk-dataservice-read';
import JSZip from 'jszip';
import protobuf from 'protobufjs';
import axios from 'axios';
import { OlpClientSettings, HRN } from '@here/olp-sdk-core';

const DECODER_CACHE = {};

export const HERE_MAP_HRN = HRN.fromString('hrn:here:data::olp-here:rib-2');

export async function getDecoder(hrn, settings) {
  if (DECODER_CACHE[hrn]) {
    return DECODER_CACHE[hrn];
  }
  // Get schema with protobuf files
  const artifactClient = new ArtifactClient(settings);
  const detailsRequest = new SchemaDetailsRequest().withSchema(hrn);
  let details = {};
  try {
    details = await artifactClient.getSchemaDetails(detailsRequest);
  } catch (e) {
    console.error(e);
  }

  if (details === undefined || details.variants === undefined) {
    return null;
  }

  const variant = details.variants.find((item) => item.id === 'ds');
  if (variant === undefined) {
    return null;
  }

  const request = new SchemaRequest().withVariant(variant);
  const archive = await artifactClient.getSchema(request);

  // Load schema as a ZIP archive
  const zip = new JSZip();
  await zip.loadAsync(archive);

  // Read all .proto file and parse them by Protobuf
  const protobufRoot = new protobuf.Root();
  Object.keys(zip.files).forEach(async (fileName) => {
    if (!fileName.endsWith('.proto')) {
      return;
    }

    const file = await zip.file(fileName).async('text');
    protobuf.parse(file, protobufRoot, { keepCase: true });
  });

  // Extract the manifest data.
  const manifestFile = await zip
    .file('META-INF/layer.manifest.json')
    .async('text');
  const manifest = JSON.parse(manifestFile);
  DECODER_CACHE[hrn] = protobufRoot.lookupType(manifest.main.message);

  return DECODER_CACHE[hrn];
}

export function getHorizontalTileIndex(lng, step) {
  return Math.floor((180 + lng) / step);
}

export function getVerticalTileIndex(lat, step) {
  return Math.floor((90 + lat) / step);
}

export function partitionFromXY(x, y, level) {
  const horizontalBinary = prepend0(x.toString(2), level);
  const verticalBinary = prepend0(y.toString(2), level);
  const interleaved = interleave(horizontalBinary, verticalBinary);
  const interleavedNumber = parseInt(interleaved, 2);
  const quadKey = `1${prepend0(interleavedNumber.toString(4), level)}`;
  return parseInt(quadKey, 4);
}

export function getPartitionId(latLng, tileLevel) {
  const { lat, lng } = latLng;
  const degreesForTileLevel = 360 / 2 ** tileLevel;
  const horizontalTileIndex = getHorizontalTileIndex(lng, degreesForTileLevel);
  const verticalTileLayer = getVerticalTileIndex(lat, degreesForTileLevel);
  return partitionFromXY(horizontalTileIndex, verticalTileLayer, tileLevel);
}

export const getOlpSettings = async (credentials) => {
  const { accessKeyId, proxyUrl, authEndpoint, accessKeySecret } = credentials;
  const storedToken = getToken(credentials.accessKeyId);
  if (isTokenValid(storedToken)) {
    return new OlpClientSettings({
      environment: 'here',
      getToken: () => Promise.resolve(storedToken),
    });
  }
  try {
    const res = await axios({
      url: `${proxyUrl.replace(/\/$/, '')}/hereAuth`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        key: accessKeyId,
        secret: accessKeySecret,
        url: authEndpoint,
      },
    });
    setToken(accessKeyId, res.data.accessToken);
    return new OlpClientSettings({
      environment: 'here',
      getToken: () => Promise.resolve(res.data.accessToken),
    });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to fetch token');
  }
};

function prepend0(val, length) {
  let newVal = val;
  while (newVal.length < length) {
    newVal = `0${newVal}`;
  }
  return newVal;
}

function interleave(x, y) {
  if (x.length !== y.length) {
    throw new Error('Input sizes do not match');
  }
  const res = [];
  for (let i = 0; i < y.length; i++) {
    res.push(y[i]);
    res.push(x[i]);
  }

  return res.join('');
}

const getLocalStorageKey = (accessKeyId) =>
  `inspectSegmentToken-${accessKeyId}`;

function getToken(accessKeyId) {
  return localStorage.getItem(getLocalStorageKey(accessKeyId));
}

function setToken(accessKeyId, token) {
  localStorage.setItem(getLocalStorageKey(accessKeyId), token);
}

function isTokenValid(token) {
  if (!token) {
    return false;
  }
  const [dataEncoded] = token.split('.');
  const data = JSON.parse(atob(dataEncoded));
  const now = new Date().getTime();
  return now < data.exp * 1000;
}
