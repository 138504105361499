import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap, HereMapContext } from './HereMapContext';
import { isEqual } from 'lodash';

class HereGroup extends React.Component {
  state = {
    group: null,
  };
  group = null;

  componentDidMount() {
    this.addGroup();
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (!isEqual(prevProps.options, options)) {
      if (prevProps.options.zIndex !== options.zIndex) {
        this.group.setZIndex(options.zIndex);
      }
    }
  }

  componentWillUnmount() {
    this.removeGroup();
  }

  addGroup = () => {
    const { map, group, options = {} } = this.props;

    const newGroup = new window.H.map.Group(options);
    if (group) {
      group.addObject(newGroup);
    } else {
      map.addObject(newGroup);
    }
    this.setState({ group: newGroup });
    this.group = newGroup;
  };

  removeGroup = () => {
    const { group } = this.props;
    const { group: currentGroup } = this.state;
    if (currentGroup) {
      currentGroup.removeAll();
    }
    if (currentGroup && group && group.contains(currentGroup)) {
      group.removeObject(currentGroup);
    }
  };

  render() {
    const { group } = this.state;
    if (!group) {
      return null;
    }

    const { children, map } = this.props;
    return (
      <HereMapContext.Provider value={{ map, group }}>
        {children}
      </HereMapContext.Provider>
    );
  }
}

HereGroup.propTypes = {
  map: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default withHereMap(HereGroup);
