import isFunction from 'lodash/isFunction';

export const addEventListeners = (props, el) => {
  const events = getEvents(props);
  Object.keys(events).forEach((key) => {
    if (events[key]) {
      el.addEventListener(key, events[key]);
    }
  });
};

export const removeEventListeners = (props, el) => {
  const events = getEvents(props);
  Object.keys(events).forEach((key) => {
    if (events[key] && el && isFunction(el.addEventListener)) {
      el.removeEventListener(key, events[key]);
    }
  });
};

function getEvents(props) {
  return Object.keys(props)
    .filter((key) => key.startsWith('on'))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key.replace(/^on/, '').toLowerCase()]: props[key],
      }),
      {}
    );
}
