const tabColors = [
  {
    primary: '#3a7bdb',
    primaryDarker: '#154a98',
    secondary: '#89b0e9',
    secondaryDarker: '#346ec5',
  },
  {
    primary: '#a743d4',
    primaryDarker: '#692a86',
    secondary: '#ca8ee5',
    secondaryDarker: '#963cbe',
  },
  {
    primary: '#e2484f',
    primaryDarker: '#9d1117',
    secondary: '#ee9195',
    secondaryDarker: '#cb4147',
  },
  {
    primary: '#f4732c',
    primaryDarker: '#ac4309',
    secondary: '#f8ab80',
    secondaryDarker: '#db6728',
  },
  {
    primary: '#fa9d00',
    primaryDarker: '#a56700',
    secondary: '#fcc466',
    secondaryDarker: '#e18d00',
  },
  {
    primary: '#c9cb03',
    primaryDarker: '#7a7b13',
    secondary: '#dfe068',
    secondaryDarker: '#b5b603',
  },
  {
    primary: '#5bad19',
    primaryDarker: '#387408',
    secondary: '#9dce75',
    secondaryDarker: '#529b16',
  },
  {
    primary: '#09a979',
    primaryDarker: '#0f7858',
    secondary: '#6bcbaf',
    secondaryDarker: '#08986d',
  },
];

export default tabColors;
