import { setFields } from 'state/tabs/tab/fields/reducer';
import { DEFAULT_WAYPOINT } from '../../formViewsPlugins/waypoints/constants';

function callback(e, dispatch, fields) {
  const { lat, lng } = e.latLng;
  const value = {
    ...DEFAULT_WAYPOINT,
    coords: `${lat.toFixed(6)},${lng.toFixed(6)}`,
  };
  const { waypoints } = fields;
  const newWaypoints = [
    ...waypoints.slice(0, -1),
    value,
    ...waypoints.slice(-1),
  ];
  setFields({ waypoints: newWaypoints });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ setFields, fields }) {
  return {
    text: 'Add waypoint',
    callback: (e) => callback(e, setFields, fields),
  };
}
