import get from 'lodash/get';

export const getSectionConsumption = (section) => {
  const travelSummaryConsumption = get(section, 'travelSummary.consumption');
  if (travelSummaryConsumption) {
    return travelSummaryConsumption;
  }

  const departureCharge = get(section, 'departure.charge', 0);
  const arrivalCharge = get(section, 'arrival.charge', 0);
  if (departureCharge - arrivalCharge) {
    return departureCharge - arrivalCharge;
  }

  const fuelConsumption = get(section, 'summary.consumption');
  if (fuelConsumption) {
    return fuelConsumption;
  }

  return get(section, 'spans', []).reduce(
    (sum, span) => sum + span.consumption,
    0
  );
};

export const getSectionCo2emission = (section) => {
  const co2Emission = get(section, 'summary.co2Emission');
  if (co2Emission) {
    return co2Emission;
  } else {
    return 0;
  }
};

export const getSectionTollCost = (section) => {
  const tollCost = get(section, 'travelSummary.tolls.total.value');
  const currency = get(section, 'travelSummary.tolls.total.currency');
  
  return {tollCost, currency};
};

