import React from 'react';
import PropTypes from 'prop-types';
import TransportTypeIcon from './TransportTypeIcon';
import classnames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import './routeSelector.scss';

class RouteSelector extends React.Component {
  getTransportIcons(sections) {
    return sections
      .map((section) => {
        if (section.type === 'pedestrian') {
          return 'walk';
        } else if (section.type === 'taxi') {
          return 'taxi';
        } else if (!section.transport) {
          return null;
        } else {
          let mode = section.transport.mode;
          if (section.type === 'rented') {
            mode += 'Share';
          }
          return mode;
        }
      })
      .map((mode, idx) => (
        <TransportTypeIcon
          key={`transport-type-icon_${idx}`}
          transportType={mode}
        />
      ));
  }

  getTableRows() {
    const { result, routeTimezone } = this.props;
    const currentRoute = result.state.currentRoute || 0;
    const highlightedRoute = result.state.highlightedRoute;

    return get(this.props, 'result.raw.data.routes', []).map((route, id) => {
      const isActive = currentRoute === id,
        isHighlighted = highlightedRoute === id,
        sections = route.sections,
        departure = sections[0].departure.time,
        arrival = sections[sections.length - 1].arrival.time,
        rowClasses = classnames({
          'rf-result-legend__table-row': true,
          'rf-result-legend__table-row-active': isActive || isHighlighted,
        });

      const color = this.getColor(isActive, isHighlighted);
      const additionalStyle = color ? { backgroundColor: color } : {};

      const duration = this.calculateDuration(sections, 'summary');
      const travelDuration = this.calculateDuration(sections, 'travelSummary');

      let departureAdapted = departure;
      let arrivalAdapted = arrival;
      switch (routeTimezone) {
        case 'LOCAL':
          departureAdapted = departure ? moment(departure).utcOffset(departure).format('HH:mm:ss') : 'N/A';
          arrivalAdapted = arrival ? moment(arrival).utcOffset(arrival).format('HH:mm:ss') : 'N/A';
          break;
        case 'DEPARTURE': 
          departureAdapted = departure ? moment(departure).utcOffset(departure).format('HH:mm:ss') : 'N/A';
          arrivalAdapted = arrival ? moment(arrival).utcOffset(departure).format('HH:mm:ss') : 'N/A';
          break;
        case 'ARRIVAL': 
          departureAdapted = departure ? moment(departure).utcOffset(arrival).format('HH:mm:ss') : 'N/A';
          arrivalAdapted = arrival ? moment(arrival).utcOffset(arrival).format('HH:mm:ss') : 'N/A';
          break;
        default: 
        // CLIENT_LOCAL
          departureAdapted = departure ? moment(departure).format('HH:mm:ss') : 'N/A';
          arrivalAdapted = arrival ? moment(arrival).format('HH:mm:ss') : 'N/A';
          break;
      }
      return (
        <tr
          key={`ResultRouteLegendTableRow${id}`}
          className={rowClasses}
          onClick={() => this.selectRoute(id)}
          onMouseEnter={() => this.highlightRoute(id)}
        >
          <td className="rf-result-legend__table-row-timecol">
            {this.formatDuration(duration, travelDuration)}
          </td>
          <td>{departureAdapted}</td>
          <td>{arrivalAdapted}</td>
          <td
            className="rf-result-legend__table-row-transportcol"
            style={additionalStyle}
          >
            {this.getTransportIcons(sections)}
          </td>
        </tr>
      );
    });
  }

  getColor(isActive, isHighlighted) {
    const palette = this.props.tabColorPalette;
    if (!palette) {
      return null;
    }

    if (isActive) {
      return palette.primary;
    } else if (isHighlighted) {
      return palette.secondaryDarker;
    }
    return palette.secondary;
  }

  calculateDuration(sections, summaryField) {
    return sections.reduce(
      (memo, section) => memo + (section[summaryField] || {}).duration,
      0
    );
  }

  formatDuration(duration, travelDuration) {
    const {
      refClient: { formatterPlugin },
    } = this.props;
    const positiveDurations = [duration, travelDuration].filter((d) => d > 0);
    const formattedDuration = formatterPlugin.get('time')(duration, 'short');
    const formattedTravelDuration = formatterPlugin.get('time')(
      travelDuration,
      'short'
    );
    switch (positiveDurations.length) {
      case 1:
        return formatterPlugin.get('time')(positiveDurations[0], 'short');
      case 2:
        return `${formattedDuration} (${formattedTravelDuration})`;
      default:
        return formatterPlugin.get('time')(0, 'short');
    }
  }

  highlightRoute = (highlightedRoute) => {
    const { setResultState } = this.props;

    setResultState({ highlightedRoute });
  };

  unHighlightRoute = () => {
    const { setResultState } = this.props;

    setResultState({ highlightedRoute: null });
  };

  selectRoute = (currentRoute) => {
    const { setResultState } = this.props;

    setResultState({ currentRoute });
  };

  render() {
    return (
      <table
        className="rf-result-legend__table"
        onMouseLeave={this.unHighlightRoute}
      >
        <tbody>
          <tr className="rf-result-legend__table-header">
            <th>Travel Time</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Transport</th>
          </tr>
          {this.getTableRows()}
        </tbody>
      </table>
    );
  }
}

RouteSelector.propTypes = {
  result: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  tabColorPalette: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default RouteSelector;
