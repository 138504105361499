import React from 'react';
import PropTypes from 'prop-types';

import { FormRow, Input } from '@here/ref-client-ui';
import { get } from 'lodash';

class Summary extends React.Component {
  render() {
    const {
      options,
      refClient: { formatterPlugin },
    } = this.props;
    const values = options.values.map((optionValue) => {
      const value = get(this.props, optionValue.path);
      if (!value) {
        return null;
      }
      const formatter = formatterPlugin.get(optionValue.format);
      const formattedValue = formatter
        ? formatter(get(this.props, optionValue.path))
        : get(this.props, optionValue.path);
      return (
        <FormRow key={optionValue.title}>
          <Input
            type="text"
            label={optionValue.title}
            isReadonly
            value={formattedValue}
          />
        </FormRow>
      );
    });

    return <div>{values}</div>;
  }
}

Summary.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default Summary;
