import { Loader } from '@here/ref-client-ui';
import './autoReload.scss';

function MatrixResResultPlugin({ result }) {
  const {
    state: { isMatrixLoading },
  } = result;

  if (!isMatrixLoading) {
    return null;
  }
  return (
    <>
      <h1>Response is not ready yet. Wait for autoreload.</h1>
      <div className="rf-auto-reload">
        <Loader />
      </div>
    </>
  );
}

export default MatrixResResultPlugin;
