import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import Polygon from './Polygon';
import ELeafletPolygon from '../polygonList/ELeafletPolygon';
import LeafletPolygonMarker from '../polygonList/LeafletPolygonMarker';
import HerePolygon from '../olsIsoline/HerePolygon';
import HerePolygonMarker from '../polygonList/HerePolygonMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Polygon(ELeafletPolygon, LeafletPolygonMarker),
  [TILE_LIBRARY_TYPES.JSLA]: Polygon(HerePolygon, HerePolygonMarker),
};
