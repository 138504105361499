import PropTypes from 'prop-types';
import React from 'react';
import utils from 'utils';
import { ExpandFormRow, FormRow, Input, Select } from '@here/ref-client-ui';
import './routingMode.scss';

const routeTypes = [
  { label: 'shortest', value: 'shortest' },
  { label: 'fastest', value: 'fastest' }
];

const vehicleTypes = [
  { label: 'car', value: 'car' },
  { label: 'pedestrian', value: 'pedestrian' },
  { label: 'truck', value: 'truck' },
  { label: 'bicycle', value: 'bicycle' },
  { label: 'motorcycle', value: 'motorcycle' },
  { label: 'bus', value: 'bus' },
  { label: 'carHOV', value: 'carHOV' },
  { label: 'emergency', value: 'emergency' },
  { label: 'roadtrain', value: 'roadtrain' },
  { label: 'taxi', value: 'taxi' },
];

const trafficOptions = [
  { label: 'enabled', value: 'enabled' },
  { label: 'disabled', value: 'disabled' },
  { label: 'default', value: 'default' }
];

class Mode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: null,
    };
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChangeMode = (idx, e) => {
    const {
      options: { key },
      setFields,
      fields,
    } = this.props;

    const mode = fields[key] || ';;';
    let value = utils.extractData(e);
    if (idx === 2) value = 'traffic:'+value;
    const modeValues = mode.split(';');
    modeValues[idx] = value;

    setFields( {[key]: modeValues.join(';')});
  };

  render() {
    const {
      options: { label, key, initialRouteType, initialVehicleType, initialTrafficOption },
      fields,
    } = this.props;
    const mode = fields[key] || ';;';
    const modeValues = mode.split(';')
    const routeType = modeValues[0] || initialRouteType;
    const vehicleType = modeValues[1] || initialVehicleType;
    const trafficOption = (modeValues[2] || '').split(':')[1] || initialTrafficOption;
    const additionalParams = modeValues[3];
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltipPlacement="right"
        className="rf-mode rf-grey-box"
      >
        <FormRow>
          <Select
            label="Route Type"
            options={routeTypes}
            value={routeType}
            onChange={this.onChangeMode.bind(this, 0)}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Vehicle Type"
            options={vehicleTypes}
            value={vehicleType}
            onChange={this.onChangeMode.bind(this, 1)}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Traffic Options"
            options={trafficOptions}
            value={trafficOption}
            onChange={this.onChangeMode.bind(this, 2)}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Additional optional parameters"
            value={additionalParams}
            onChange={this.onChangeMode.bind(this, 3)}
          />
        </FormRow>
        
      </ExpandFormRow>
    );
  }
}

Mode.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options, parsedParams }) => {
    const { key } = options;
    const mode = parsedParams[key]||'';
    return { mode }
  },

  defaultState: (options) => {
    const { key, initialRouteType, initialVehicleType, initialTrafficOption } = options;
    return { [key]: `${initialRouteType};${initialVehicleType};traffic:${initialTrafficOption}` };
  },

  getRequestOptions: (fields, options) => {
    const { key, initialRouteType, initialVehicleType, initialTrafficOption } = options;
    const mode = fields[key];
    
    return {
      params: { [key]: mode || `${initialRouteType};${initialVehicleType};traffic:${initialTrafficOption}` },
    };
  },
  Component: Mode,
};
