function callback(e, options, setFields, fields) {
  const { lat, lng } = e.latLng;
  const pointsArr = JSON.parse(fields[options.key] || '[]');
  pointsArr.push({
    lat: parseFloat(lat.toFixed(6)),
    lng: parseFloat(lng.toFixed(6)),
  });
  setFields({ [options.key]: JSON.stringify(pointsArr) });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, setFields, fields }) {
  return {
    text: options.text,
    callback: (e) => callback(e, options, setFields, fields),
  };
}
