function callback(e, addTab) {
  const { lat, lng } = e.latLng;
  const latLngStr = `${lat},${lng}`;
  addTab({ title: 'Inspect Segment', fields: { latLng: latLngStr } });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ options, addTab }) {
  return {
    text: options.text,
    callback: (e) => callback(e, addTab),
  };
}
