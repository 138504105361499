import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import RouteMatchingRouteRoute from './RouteMatchingRouteRoute';
import HereMarker from './HereMarker';
import LeafletMarker from './LeafletMarker';
import HerePolyline from 'shared/hereMap/HerePolyline';
import LeafletLine from './LeafletLine';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: RouteMatchingRouteRoute(LeafletMarker, LeafletLine),
  [TILE_LIBRARY_TYPES.JSLA]: RouteMatchingRouteRoute(HereMarker, HerePolyline),
};