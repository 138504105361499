import React from 'react';
import PropTypes from 'prop-types';
import { CopyButton, Button, ExpandFormRow, Tree } from '@here/ref-client-ui';
import classnames from 'classnames';
import './truckAttributes.scss';

class TruckAttributes extends React.Component {
  onSelectAnchor = (anchorIndex) => {
    const {
      setResultState,
      result: {
        state: { selectedAttribute },
      },
      layerId,
    } = this.props;
    if (selectedAttribute && selectedAttribute.index === anchorIndex) {
      setResultState({ selectedAttribute: null });
    } else {
      setResultState({ selectedAttribute: { index: anchorIndex, layerId } });
    }
  };

  render() {
    const {
      layerId,
      data,
      result: {
        state: { selectedAttribute },
      },
    } = this.props;
    const anchorEls = Object.keys(data).map((anchorIndex) => {
      const isSelected =
        selectedAttribute &&
        selectedAttribute.index === anchorIndex &&
        selectedAttribute.layerId === layerId;
      const labelClasses = classnames('rf-anchor-list__item__label', {
        'rf-anchor-list__item__label_selected': isSelected,
      });
      return (
        <li
          key={anchorIndex + isSelected}
          onClick={this.onSelectAnchor.bind(this, anchorIndex)}
          className="rf-anchor-list__item"
        >
          <div className={labelClasses}>
            Segment anchor index - {anchorIndex}
          </div>
          <div onClick={(e) => e.stopPropagation()} className="rf-tree-attributes">
            {isSelected && (
              <Tree data={data[anchorIndex]} shouldExpandNode={() => true} />
            )}
            {isSelected && (
              <CopyButton value={JSON.stringify(data[anchorIndex], null, 2)}>
                <Button title="Copy" />
              </CopyButton>
            )}
          </div>
        </li>
      );
    });

    return (
      <ExpandFormRow label={layerId} isExpanded className="rf-anchor-list">
        {anchorEls}
      </ExpandFormRow>
    );
  }
}

TruckAttributes.propTypes = {
  layerId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default TruckAttributes;
