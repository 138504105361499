import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const items = get(props, 'result.raw.data.items');
  const selected = get(props, 'result.state.selectedCustomizationSearchResult', {});
  if (!items) {
    return [];
  }
  const currentMarkers = [];
  items.forEach((item, index) => {
      const { lat, lng } = item.position;
      const text = index + 1;
      const color  =  selected.index === index ? {
        "primary": "#888",
        "primaryDarker": "#154a98",
        "secondary": "#89b0e9",
        "secondaryDarker": "#346ec5"
      } : (item.id && item.id.indexOf('here:')===0 ? null : {
        "primary": "#89b0e9",
        "primaryDarker": "#154a98",
        "secondary": "#89b0e9",
        "secondaryDarker": "#346ec5"
      });
      currentMarkers.push({
        value: `${lat},${lng}`,
        text: text,
        draggable: false,
        color
      });

      if (selected.index === index && item.access) {
        item.access.forEach((coord, idx) => {
          const { lat, lng } = coord;
          currentMarkers.push({
            value: `${lat},${lng}`,
            text: text,
            draggable: false,
            fontSize: 15,
            iconWidth: 16,
            iconHeight: 20,
            iconX:8,
            iconY:20,
            color
          });
        })
      }
    });

  return [...currentMarkers];
};
