import { get, merge } from 'lodash';
import { getTitle } from '../tabs/tab/title';

export const SET_SETTINGS_VALUE = 'settings/SET';

export const setSettingsValue = (title, preset, data) => ({
  type: SET_SETTINGS_VALUE,
  payload: { title, preset, data },
});

export const getSettings = (state) => {
  const title = getTitle(state);
  const settings = merge(
    {},
    get(state, 'settings.default'),
    get(state, 'settings.custom')
  );

  return settings[title];
};

export const getAllSettings = (state) =>
  merge({}, get(state, 'settings.default'), get(state, 'settings.custom'));
