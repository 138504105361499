import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MarkerIcon } from '@here/ref-client-ui';
import { withHereMap } from '../../shared/hereMap/HereMapContext';
import HereMarker from '../../shared/hereMap/HereMarker';

class SearchResults extends Component {
  componentDidUpdate(prevProps) {
    const {
      searchData: { suggestions, selected },
      map,
    } = this.props;
    if (selected !== null && selected !== prevProps.searchData.selected) {
      const [lat, lng] = suggestions[selected].position.split(',').map(Number);
      map.setCenter({ lat, lng });
    }
  }

  renderResults = (results) =>
    results.map((result, index) => {
      const [lat, lng] = result.position.split(',').map(Number);
      const iconSvg = MarkerIcon({
        color: 'green',
        strokeColor: 'black',
        opacity: 0.7,
        strokeOpacity: 0.7,
      });
      const iconUrl = `data:image/svg+xml;base64,${btoa(iconSvg)}`;
      const icon = new window.H.map.Icon(iconUrl, {
        anchor: { x: 11.5, y: 28 },
        size: { w: 23, h: 28 },
      });

      return (
        <HereMarker key={index} latLng={{ lat, lng }} options={{ icon }} />
      );
    });

  render() {
    const {
      searchData: { suggestions },
    } = this.props;
    if (!suggestions || suggestions.length < 1) {
      return null;
    }
    return <div>{this.renderResults(suggestions)}</div>;
  }
}

SearchResults.propTypes = {
  searchData: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
};

export default withHereMap(SearchResults);
