import RefClient from 'ref-client-core';
import avoidSegmentsPlugin from './avoidSegments';
import requestOptions from './requestOptions';
import matrix from './matrix';
import reverseOriginDestinationPlugin from './reverseOriginDestination';

RefClient.formViewsPlugin.register('matrix', matrix);
RefClient.formViewsPlugin.register(
  'reverseOriginDestination',
  reverseOriginDestinationPlugin
);
RefClient.formViewsPlugin.register('avoidSegments', avoidSegmentsPlugin);
RefClient.formViewsPlugin.register('requestOptions', requestOptions);
