import { SET } from './constants';
import { get } from 'lodash';

export function set(data) {
  return { type: SET, data };
}

export function alternativesIgnoredNotify() {
  return {
    type: SET,
    data: {
      message:
        'Alternative routes param will be ignored. Because intermediate waypoint is specified.',
      impact: 'significant',
      autoDismiss: 0,
    },
  };
}

export function textCopiedNotify(paramName) {
  return {
    type: SET,
    data: {
      message: `${paramName} copied to clipbard`,
      impact: 'positive',
      autoDismiss: 2,
    },
  };
}

export function requestErrorNotify(message) {
  return set({
    title: 'Request error',
    message,
    impact: 'negative',
    autoDismiss: 0,
  });
}

export function requestErrorNotifyDecorator(func, dispatch, detailsProp) {
  return function (...args) {
    let response = args[0];
    if (response.isError) {
      let { data = {} } = response;
      let details = detailsProp ? get(data, detailsProp) : data.details || data;
      dispatch(requestErrorNotify(details));
      return Promise.reject();
    }
    return func(...args);
  };
}
