import * as types from './constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_ACTIVE:
      return action.isActive;
    default:
      return state;
  }
};
