import './transportMode.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CloseButton, Tooltip } from '@here/ref-client-ui';
import {
  DrivingIcon,
  CarHovIcon,
  PublicTransportIcon,
  PublicTransportTimeTableIcon,
  WalkingIcon,
  TruckIcon,
  BicycleIcon,
  MotoscooterIcon,
  TaxiIcon,
  BusIcon,
  PrivateBusIcon,
} from './icons';

const TRANSPORT_ICON_MAP = {
  car: DrivingIcon,
  carHOV: CarHovIcon,
  publicTransport: PublicTransportIcon,
  publicTransportTimeTable: PublicTransportTimeTableIcon,
  pedestrian: WalkingIcon,
  truck: TruckIcon,
  vehicle: TruckIcon,
  bicycle: BicycleIcon,
  scooter: MotoscooterIcon,
  taxi: TaxiIcon,
  bus: BusIcon,
  privateBus: PrivateBusIcon,
};

class TransportMode extends React.Component {
  getLabel = (value) => {
    const { transports } = this.props;
    for (let i = 0; i < transports.length; i++) {
      if (transports[i].value === value) {
        return transports[i].label;
      }
    }
    return null;
  };

  render() {
    const { transports, value, onChange } = this.props;
    const label = this.getLabel(value);

    return (
      <div className="rf-transport-mode">
        <div className="rf-transport-mode__title">
          Transport:{' '}
          {label && (
            <span className="rf-transport-mode__title__value">
              {label}
              <CloseButton
                className="rf-transport-mode__clear"
                onClick={() => onChange(null)}
              />
            </span>
          )}
        </div>
        <div className="rf-transport-mode__items">
          {transports.map((transportType) => {
            let classes = classnames('rf-transport-mode__items__item', {
              'rf-transport-mode__items__item_active':
                transportType.value === value,
            });
            const Icon = TRANSPORT_ICON_MAP[transportType.value];
            return (
              <Tooltip
                tooltip={transportType.label}
                placement="top"
                key={transportType.value}
              >
                <span
                  className={classes}
                  onClick={() => onChange(transportType.value)}
                >
                  <Icon />
                </span>
              </Tooltip>
            );
          })}
        </div>
      </div>
    );
  }
}

TransportMode.propTypes = {
  transports: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TransportMode;
