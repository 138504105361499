import React from 'react';
import PropTypes from 'prop-types';
import refClient from '../../core/refClient';
import { FormRow, Input, Modal, ModalActions, Select } from '@here/ref-client-ui';
import adjustLUIStyle from '../../utils/adjustLUIStyle';

class SettingsModal extends React.Component {
  state = {
    type: '',
    name: '',
    isNameValid: true,
  };

  setType = (e) => {
    const { value } = e.target;
    this.setState({
      type: value,
    });
  };

  setName = (e) => {
    const { value } = e.target;
    const { settings } = this.props;
    let isNameValid = value && Object.keys(settings).indexOf(value) === -1;
    this.setState({
      name: value,
      isNameValid,
    });
  };

  onSave = () => {
    const { name, type, isNameValid } = this.state;
    const { onSave, onClose } = this.props;
    if (!isNameValid || !type || !name) {
      return;
    }
    onSave(name, type);
    onClose();
  };

  componentDidMount () {
    adjustLUIStyle(document.querySelectorAll('.rf-modal lui-overlay'), 'lui-overlay');
  }

  render() {
    const { open, onClose } = this.props;
    const { type, name, isNameValid } = this.state;
    const { settingsPlugin } = refClient;

    const options = [];
    settingsPlugin.components.forEach((value, key) => {
      options.push(key);
    });

    const saveProps = {
      onClick: this.onSave,
      secondary: true,
    };
    if (!isNameValid || !type || !name) {
      saveProps.disabled = true;
    }

    return (
      <Modal
        appendToBody
        title="Add New Environment"
        isShown={open}
        stopPropagation
      >
        <FormRow>
          <Input 
            label="Environment name"
            value={name} 
            onChange={this.setName} 
            isValid={isNameValid} 
          />
        </FormRow>
        <FormRow>
          <Select
            label="Settings type"
            addEmptyOption
            options={options}
            value={type}
            onChange={this.setType}
          />
        </FormRow>
        <ModalActions>
          <lui-button onClick={onClose} secondary>
            Cancel
          </lui-button>
          <lui-button {...saveProps}>Save</lui-button>
        </ModalActions>
      </Modal>
    );
  }
}

SettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.object,
};

export default SettingsModal;
