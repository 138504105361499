import React from 'react';
import {
  VersionedLayerClient,
  DataRequest,
  CatalogClient,
  CatalogRequest,
} from '@here/olp-sdk-dataservice-read';
import {
  getDecoder,
  getPartitionId,
  getOlpSettings,
  HERE_MAP_HRN,
} from './helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import get from 'lodash/get';
import { getDataFromCache, storeDataToCache } from './cache';
import parsers from './parsers';
import Layers from './Layers';
import isEqual from 'lodash/isEqual';
import isFinite from 'lodash/isFinite';
import { Buffer } from 'buffer';

const TOPOLOGY_GEOMETRY_LAYER_ID = 'topology-geometry';

class InspectSegment extends React.Component {
  async componentDidMount() {
    const { fields, setFields, setResultState, setNotification } = this.props;

    setFields({ isLoading: true });
    const layers = fields.layers || (await this.loadLayers());

    if (!fields.latLng) {
      if (layers && !fields.layers) {
        setFields({ layers, isLoading: false });
      }
      return;
    }

    if (fields.segmentId) {
      setFields({ isLoading: false });
      return;
    }

    const segmentId = await this.getSegmentId();
    if (segmentId === '') {
      setNotification({
        message: `Segment ID was not found.`,
        impact: 'significant',
        autoDismiss: 5,
      });
    }
    if (segmentId === null || layers === null) {
      setFields({ isLoading: false });
      return;
    }

    setFields({
      segmentId,
      layers,
      inspectSegmentData: await this.getInspectSegmentData(segmentId, layers),
      isLoading: false,
    });
    setResultState({ isResultPanelShown: true });
  }

  async componentDidUpdate(prevProps) {
    const { fields, setFields, setResultState } = this.props;

    if (fields.latLng !== prevProps.fields.latLng) {
      if (!fields.latLng) {
        setFields({ segmentId: '' });
        setResultState({ isResultPanelShown: false });
        return;
      }
      setFields({ isLoading: true });
      const segmentId = await this.getSegmentId();
      setFields({ segmentId, isLoading: false });
      if (!segmentId) {
        return;
      }

      setFields({ isLoading: true });
      setFields({
        inspectSegmentData: await this.getInspectSegmentData(
          segmentId,
          fields.layers
        ),
        isLoading: false,
      });
      setResultState({ isResultPanelShown: true });
    }

    if (
      fields.latLng &&
      !isEqual(fields.selectedLayers, prevProps.fields.selectedLayers)
    ) {
      setFields({ isLoading: true });
      setFields({
        inspectSegmentData: await this.getInspectSegmentData(
          fields.segmentId,
          fields.layers
        ),
        isLoading: false,
      });
      setResultState({ isResultPanelShown: true });
    }
  }

  getSegmentId = async () => {
    const { fields, settings, setNotification } = this.props;
    const params = {
      origin: fields.latLng,
      destination: fields.latLng,
      return: 'polyline',
      spans: 'segmentId',
      transportMode: 'car',
      apikey: settings.apikey,
    };
    try {
      const res = await axios.get('https://router.hereapi.com/v8/routes', {
        params,
      });
      return get(
        res,
        'data.routes[0].sections[0].spans[0].topologySegmentId',
        ''
      ).replace(/^\+|^-/, '');
    } catch (e) {
      setNotification({
        message: `Failed to fetch segmentId: "${e.message}"`,
        impact: 'negative',
        autoDismiss: 5,
      });
      return null;
    }
  };

  loadLayerData = async (layerId, version, layers) => {
    const { fields, setNotification } = this.props;

    const olpClientSettings = await this.getOlpSettings();
    if (!olpClientSettings) {
      return null;
    }

    const params = {
      catalogHrn: HERE_MAP_HRN,
      layerId,
      settings: olpClientSettings,
    };
    if (version && isFinite(+version)) {
      params.version = +version;
    }
    const versionedLayerClient = new VersionedLayerClient(params);

    const [lat, lng] = fields.latLng.split(',').map(Number);
    const partitionId = getPartitionId({ lat, lng }, 12);
    let data = getDataFromCache(layerId, version, partitionId);
    if (!data) {
      const layer = (layers || fields.layers).find(
        (layer) => layer.value === layerId
      );
      const dataRequest = new DataRequest().withPartitionId(partitionId);
      let partitions;
      try {
        partitions = await versionedLayerClient.getData(dataRequest);
      } catch (e) {
        setNotification({
          message: `Failed to fetch data for layer "${layer.value}"`,
          impact: 'negative',
          autoDismiss: 5,
        });
        return null;
      }

      let decoder;
      try {
        decoder = await getDecoder(layer.schemaHrn, olpClientSettings);
      } catch (e) {
        setNotification({
          message: `Failed to fetch decoder: "${e.message}"`,
          impact: 'negative',
          autoDismiss: 5,
        });
        return null;
      }
      const arrayBuffer = await partitions.arrayBuffer();
      const message = decoder.decode(Buffer.from(arrayBuffer));
      data = decoder.toObject(message, { defaults: true, enums: String });
      storeDataToCache(layerId, version, partitionId, data);
    }
    return data;
  };

  loadLayers = async () => {
    const settings = await this.getOlpSettings();
    if (!settings) {
      return null;
    }
    const catalogClient = new CatalogClient(HERE_MAP_HRN, settings);
    try {
      const config = await catalogClient.getCatalog(new CatalogRequest());
      const layers = [];
      config.layers.forEach((layer) => {
        if (get(layer, 'schema.hrn')) {
          layers.push({
            value: layer.id,
            label: layer.id,
            schemaHrn: layer.schema.hrn,
          });
        }
      });
      return layers;
    } catch (e) {
      const { setNotification } = this.props;
      setNotification({
        message: `Failed to fetch layers: "${e.message}"`,
        impact: 'negative',
        autoDismiss: 5,
      });
      return null;
    }
  };

  getInspectSegmentData = async (segmentId, layers) => {
    const {
      fields: { selectedLayers },
    } = this.props;

    const allData = await Promise.all(
      selectedLayers.map(({ layer, version }) =>
        this.loadLayerData(layer, version, layers)
      )
    );
    const topologyIndex = 0;
    const topologyData = {
      raw: allData[topologyIndex],
      parsed: allData[topologyIndex] ? parsers[TOPOLOGY_GEOMETRY_LAYER_ID](
        segmentId,
        allData[topologyIndex]
      ) : null,
    };
    return allData.reduce((acc, layerData, index) => {
      const { layer } = selectedLayers[index];
      if (index === topologyIndex) {
        return {
          ...acc,
          [TOPOLOGY_GEOMETRY_LAYER_ID]: topologyData,
        };
      }
      const parser = parsers[layer];
      return {
        ...acc,
        [layer]: {
          raw: layerData,
          parsed: parser && layerData && topologyData.parsed ? parser(topologyData, layerData) : null,
        },
      };
    }, {});
  };

  getOlpSettings = async () => {
    const { settings, setNotification } = this.props;
    try {
      return await getOlpSettings(settings);
    } catch (e) {
      console.error(e);
      setNotification({
        message: `Failed to fetch token: "${e.message}"`,
        impact: 'negative',
        autoDismiss: 5,
      });
      return null;
    }
  };

  render() {
    const { fields, setFields } = this.props;

    return (
      <Layers
        layers={fields.layers}
        setFields={setFields}
        selectedLayers={fields.selectedLayers}
      />
    );
  }
}

InspectSegment.propTypes = {
  setFields: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

const defaultSelectedLayers = () => [
  {
    layer: TOPOLOGY_GEOMETRY_LAYER_ID,
    version: null,
  },
  {
    layer: 'truck-attributes',
    version: null,
  },
  {
    layer: 'navigation-attributes',
    version: null,
  },
  {
    layer: 'advanced-navigation-attributes',
    version: null,
  },
  {
    layer: 'road-attributes',
    version: null,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({
    segmentId: '',
    selectedLayers: defaultSelectedLayers(),
  }),
  defaultState: () => ({
    segmentId: '',
    selectedLayers: defaultSelectedLayers(),
  }),
  getRequestOptions: () => ({}),
  Component: InspectSegment,
};
