import React from 'react';
import PropTypes from 'prop-types';
import HerePolyline from 'shared/hereMap/HerePolyline';
import { addOpacityToHexColor } from '../../utils';

class Lines extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    const { positions, strokeColor, lineWidth, zIndex, geometryIndex, onChange } = this.props;

    const style = { lineWidth };
    style.fillColor = addOpacityToHexColor(strokeColor, 0.4);
    style.strokeColor = strokeColor;
    
    return <>
      <HerePolyline
        latLngs={positions} 
        options={{ style, zIndex }}
        draggable={false}
        resizable={false}
        geometryIndex={geometryIndex}
        onChange={onChange}
        onTap={null}
        changeCursorOnHover={false}
        onContextMenu={null}
      />
    </>;
  }
}

Lines.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  strokeColor: PropTypes.string,
  zIndex: PropTypes.number,
};

export default Lines;
