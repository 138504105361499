import React from 'react';
import PropTypes from 'prop-types';
import Marker from 'shared/hereMap/HereMarker';
import { createCanvas, createIcon } from './icon';

function HereMarker({ position, color, text, selected }) {
  const canvas = createCanvas(color, text, selected);
  const icon = createIcon(canvas, selected);
  return <Marker 
    key={`marker${text}`}
    latLng={{lat: position.latitude, lng: position.longitude}} 
    options={{ icon }} 
  />;
}

HereMarker.propTypes = {
  position: PropTypes.object.isRequired,
  text: PropTypes.number.isRequired,
};

export default HereMarker;
