import React from 'react';
import { Marker } from 'react-leaflet';
import PropTypes from 'prop-types';
import { createLeafletIcon } from './icon';

function LeafletMarker({ position, color, text, selected }) {
  const icon = createLeafletIcon(color, text, selected);
  return <Marker
    key={`marker${text}`}
    position = {[position.latitude, position.longitude]}
    icon={icon}
  />;
}

LeafletMarker.propTypes = {
  position: PropTypes.object.isRequired,
  text: PropTypes.number.isRequired,
};

export default LeafletMarker;
