import fetch from 'utils/fetch';
import splunkLog from 'utils/splunkLog';

const getUserLocation = function() {
    let promise = new Promise(function(resolve, reject) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if ( timezone.indexOf('Asia') === 0 ) {
            fetch('https://get.geojs.io/v1/ip/country.json')
                .then(res => {
                    if( !res.isError && 
                        ( res.data.name === 'India' || res.data.country === 'IN' || res.data.country_3 === 'IND')
                    ) {
                        resolve({
                            vectorHARP: 'in',
                            vector: 'in',
                            rasterv3: 'IND',
                            raster: 'IND'
                        });
                    } else {
                        resolve({
                            vectorHARP: '-none-',
                            vector: '-none-',
                            rasterv3: '-none-',
                            raster: 'DEF'
                        });
                    }
                })
                .catch(function(error) {
                    splunkLog({ error }, 'error');
                    resolve({
                        vectorHARP: '-none-',
                        vector: '-none-',
                        rasterv3: '-none-',
                        raster: 'DEF'
                    });
                });
        } else {
            resolve({
                vectorHARP: '-none-',
                vector: '-none-',
                rasterv3: '-none-',
                raster: 'DEF'
            });
        }
    })
    
    return promise;

}

export default getUserLocation;