import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';

function Circle(CircleComponent) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      setFields: PropTypes.func.isRequired,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
      bounds: PropTypes.object.isRequired,
      paramsMapping: PropTypes.bool,
    };

    onChange = ({ center, radius }) => {
      const {
        fields,
        setFields,
        options: { onChangeFormatter, key },
        refClient: { formatterPlugin },
      } = this.props;
      const centerObj = {
        lat: +center.lat.toFixed(6),
        lng: +center.lng.toFixed(6),
      };
      const radiusRounded = Math.round(radius);
      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, centerObj, radiusRounded));
      } else if (key) {
        setFields({
          [key]: {
            center: centerObj,
            radius: radiusRounded,
          },
        });
      }
    };

    render() {
      const {
        options: {
          radiusPath,
          centerPath,
          showUnselected,
          radius: optionRadius,
          colorPath,
          draggable,
          resizable,
          adaptiveSize = false,
          centerOnChange,
        },
        tabIndex,
        selectedTab,
        paramsMapping,
      } = this.props;

      if (tabIndex !== selectedTab && !showUnselected) {
        return null;
      }
      let radius = optionRadius || get(this.props, radiusPath);
      if (typeof radius === 'string' && radius.indexOf('circle:')>-1) {
        radius = +radius.split(';r=')[1];
      }
      let center = get(this.props, centerPath);
      if (typeof center === 'string' && center.indexOf('circle:')>-1) {
        const [lat, lng] = center.split(';r=')[0].split('circle:')[1].split(',')
        center = {lat, lng};
      }

      if (
        !radius ||
        !isFinite(radius) ||
        radius <= 0 ||
        !center ||
        !validateLat(center.lat) ||
        !validateLon(center.lng)
      ) {
        return null;
      }

      if (adaptiveSize) {
        const eqCirc = 40075016.686;
        const defaultZoomLevel = 13;
        const defaultMPerPx =
          (eqCirc * Math.cos(center.lat)) / 2 ** (defaultZoomLevel + 8);
        const currentMPerPx =
          (eqCirc * Math.cos(center.lat)) / 2 ** (this.props.bounds.zoom + 8);
        const ratio = currentMPerPx / defaultMPerPx;
        radius *= ratio;
      }

      return (
        <CircleComponent
          radius={radius}
          center={center}
          draggable={draggable && paramsMapping}
          resizable={resizable && paramsMapping}
          color={get(this.props, colorPath)}
          onChange={this.onChange}
          centerOnChange={centerOnChange}
        />
      );
    }
  };
}

export default Circle;
