import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, withLeaflet } from 'react-leaflet';
import { MarkerIcon } from '@here/ref-client-ui';
import { icon as leafletIcon } from 'leaflet';

class SearchResults extends Component {
  componentDidUpdate(prevProps) {
    const {
      searchData: { suggestions, selected },
      leaflet: { map },
    } = this.props;
    if (selected !== null && selected !== prevProps.searchData.selected) {
      const [lat, lng] = suggestions[selected].position.split(',');
      map.setView([+lat, +lng]);
    }
  }

  renderResults = (results) =>
    results.map((result, index) => {
      const [lat, lng] = result.position.split(',');
      const iconSvg = MarkerIcon({
        color: 'green',
        strokeColor: 'black',
        opacity: 0.7,
        strokeOpacity: 0.7,
      });
      const iconUrl = `data:image/svg+xml;base64,${btoa(iconSvg)}`;
      const icon = leafletIcon({
        iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [12, 28],
        iconSize: [23, 28],
      });
      return (
        <Marker
          key={index}
          contextmenu
          position={[+lat, +lng]}
          icon={icon}
          color="green"
        />
      );
    });

  render() {
    const {
      searchData: { suggestions },
    } = this.props;
    if (!suggestions || suggestions.length < 1) {
      return null;
    }
    return <div>{this.renderResults(suggestions)}</div>;
  }
}

SearchResults.propTypes = {
  searchData: PropTypes.object.isRequired,
  leaflet: PropTypes.object.isRequired,
};

export default withLeaflet(SearchResults);
