import { encode } from 'ref-client-core/utils/flexPolyline';
import BigNumber from 'bignumber.js';

function callback(e, addTab, fields, setFields) {
  const { lat, lng } = e.latLng;
  const { mapAttributes } = fields;
  const mapAttrs = {
    flexiblePolyline: encode([[new BigNumber(+lat), new BigNumber(+lng), new BigNumber(0)], [new BigNumber(+lat), new BigNumber(+lng), new BigNumber(0)]]),
    type: "flexiblePolyline"
  };
  if ( mapAttributes && mapAttributes.type ) {
    setFields({mapAttributes: mapAttrs})
  } else {
    const { key, value } = fields.routeTime;
    const routeTime = (key==="departureTime" && value!== "any") ? {key, value} : {key:'', value:''};
    const trailersCount = fields['vehicle[trailerCount]'] > 3 ? '3' : fields['vehicle[trailerCount]'];
    const shippedHazardousGoods = JSON.parse(JSON.stringify(fields['vehicle[shippedHazardousGoods]']));
    const index = shippedHazardousGoods.values.indexOf('radioactive');
    if (index !== -1) {
      shippedHazardousGoods.values[index] = 'radioActive';
    }
    const mafields = {
      mapAttributes: mapAttrs,
      transportMode: fields.transportMode,
      routeTime,
      vehicleWeight: fields['vehicle[grossWeight]'],
      length: fields['vehicle[length]'],
      trailersCount,
      shippedHazardousGoods
    }
  
    addTab({ title: 'Map Attributes', fields: mafields });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (e) {
  const { fields, addTab, setFields } = e;
  return {
    text: 'Get Map Attributes',
    callback: (e) => callback(e, addTab, fields, setFields),
  };
}
