import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@here/ref-client-ui';
import { getOlsOptions, getOlsWaypoints, google } from './compareUtils';

class CompareGoogleButton extends Component {
  open() {
    const { fields } = this.props;
    const waypoints = getOlsWaypoints(fields);
    if (!waypoints) {
      throw new Error("Can't get waypoints");
    }
    const options = getOlsOptions(fields);
    google.open(waypoints, options.mode);
  }

  render() {
    return <Button onClick={this.open.bind(this)} title="Compare to Google" />;
  }
}

CompareGoogleButton.propTypes = {
  fields: PropTypes.object,
};

export default CompareGoogleButton;
