import React from 'react';
import PropTypes from 'prop-types';
import LeafletPolyline from 'shared/leafletPolyline';

function LeafletPolylines({
    positions, coatPositions, geometryIndex, draggable, resizable, onChange, strokeColor, fillColor, lineWidth, opacity
}) {
    return <LeafletPolyline       
      positions={positions}
      coatPositions={coatPositions}
      geometryIndex={geometryIndex}
      draggable={draggable}
      resizable={resizable}
      onChange={onChange}
      color={strokeColor}
      fillColor={fillColor}
      weight={lineWidth}
      opacity={opacity}
    />
}

LeafletPolylines.propTypes = {
  zIndex: PropTypes.number,
};

export default LeafletPolylines;
