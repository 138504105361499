import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles/settingsForm.scss';
import settingsPlugin from '../../core/settingsPlugin';
import { Actions, FormRow, Input, Select } from '@here/ref-client-ui';
import SettingsModal from './SettingsModal';
import SettingsErrorHandler from './SettingsErrorHandler';
import CustomTabs from '../shared/tabs';
import AppSettings from './AppSettings';
import { logError } from '../../utils/splunkLog';
import NewVersion from '../main/NewVersion';

export class SettingsFormContainer extends Component {
  state = {
    isModalOpen: false,
  };
  containerRef = React.createRef();

  onChangeEnv = (e) => {
    this.props.setPreset(e.target.value);
  };

  onClose = () => {
    this.props.close();
  };

  getDomNode = () => this.containerRef.current;

  onChange = (data) => {
    const { title, setSettingsValue, addSettings, preset, settings } = this.props;
    if (settings.hasOwnProperty(preset) && settings[preset].editable) {
      addSettings(title, preset, { ...settings[preset], editable: false });
    }
    setSettingsValue(title, preset, data);
  };

  onChangeName = (e) => {
    const { value } = e.target;
    const { changeSettingsName, title, preset } = this.props;

    if (value !== preset) {
      changeSettingsName(title, preset, value);
    }
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onAddSettings = (name, type) => {
    const { addSettings, title } = this.props;
    addSettings(title, name, { type, isCustom: true });
  };

  deleteSettings = () => {
    const { preset, title, deleteSettings, settings } = this.props;
    deleteSettings(title, preset, Object.keys(settings)[0]);
  };

  duplicateSettings = () => {
    const { preset, title, addSettings, settings } = this.props;
    let name = `${preset} copy`;
    const presetNames = Object.keys(settings);
    while (presetNames.indexOf(name) !== -1) {
      name = `${name} copy`;
    }
    addSettings(title, name, { ...settings[preset], isCustom: true });
  };

  onSaveDefault = () => {
    const { title, preset, setDefaultPreset, setNotification } = this.props;
    setDefaultPreset(title, preset);
    setNotification({
      message: `Default preset "${preset}" has been set for module "${title}"`,
      impact: 'positive',
      autoDismiss: 4,
    });
  };

  getTabSettings = () => {
    const { color, settings, preset, setNotification } = this.props;
    const { type, isCustom } = settings[preset];

    let SettingsComponent;
    let error;
    try {
      SettingsComponent = settingsPlugin.get(type).Component;
    } catch (e) {
      logError({...e, type});
      error = e;
    }
    const actions = [{ label: 'Duplicate', callback: this.duplicateSettings }];
    if (isCustom) {
      actions.push({ label: 'Delete', callback: this.deleteSettings });
    }

    return (
      <>
        <h3>
          <i
            className="rf-settings-form__color"
            style={{ background: color }}
          />
          Active Tab Settings
        </h3>
        <FormRow>
          <lui-button onClick={this.openModal}>Add new environment</lui-button>
        </FormRow>
        <FormRow className="rf-settings-form__environment">
          <Select
            label="Environment"
            value={preset}
            options={Object.keys(settings)}
            onChange={this.onChangeEnv}
          />
          <Actions position="left" actions={actions} />
        </FormRow>
        <FormRow isHidden={!isCustom}>
          <Input
            label="Environment name"
            value={preset}
            onBlur={this.onChangeName}
            blurOnEnter
          />
        </FormRow>
        <SettingsErrorHandler
          error={error}
          settingsConfig={{ [preset]: settings[preset] }}
        >
          {!error && (
            <SettingsComponent
              {...settings[preset]}
              onChange={this.onChange}
              setNotification={setNotification}
            />
          )}
        </SettingsErrorHandler>
        <div className="rf-settings-form__action-btn-group">
          <lui-button secondary onClick={this.onClose}>
            Close
          </lui-button>
          <lui-button onClick={this.onSaveDefault}>Set Default</lui-button>
        </div>
        <SettingsModal
          open={this.state.isModalOpen}
          onClose={this.closeModal}
          onSave={this.onAddSettings}
          settings={settings}
        />
      </>
    );
  };

  render() {
    const { close, isSettingsShown, isNewVersion, waitingWorker } = this.props;
    const formClasses = classnames({
      'rf-settings-form': true,
      'rf-settings-form_fadein': isSettingsShown,
    });

    return (
      <div className={formClasses} ref={this.containerRef}>
        {isNewVersion && <NewVersion worker={waitingWorker} />}
        <CustomTabs
          data={[
            { title: 'Tab settings', content: this.getTabSettings() },
            { title: 'App settings', content: <AppSettings close={close} /> },
          ]}
        />
      </div>
    );
  }
}

SettingsFormContainer.defaultPros = {
  canResetCredentials: true,
};

SettingsFormContainer.propTypes = {
  isSettingsShown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  setSettingsValue: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setPreset: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  preset: PropTypes.string.isRequired,
  addSettings: PropTypes.func.isRequired,
  deleteSettings: PropTypes.func.isRequired,
  changeSettingsName: PropTypes.func.isRequired,
  setDefaultPreset: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  isNewVersion: PropTypes.bool.isRequired,
  waitingWorker: PropTypes.object,
};

export default SettingsFormContainer;
