import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { DIRECTIONS } from './constants';

export class ScrollControls extends Component {
  render() {
    const { canScrollLeft, canScrollRight, scroll } = this.props;
    const leftClasses = classnames('rf-nav-tabs__scroll-controls__left', {
      'rf-nav-tabs__scroll-controls__left_disabled': !canScrollLeft,
    });
    const rightClasses = classnames('rf-nav-tabs__scroll-controls__right', {
      'rf-nav-tabs__scroll-controls__right_disabled': !canScrollRight,
    });
    return (
      <div className="rf-nav-tabs__scroll-controls">
        <div className={leftClasses} onClick={() => scroll(DIRECTIONS.LEFT)} />
        <div
          className={rightClasses}
          onClick={() => scroll(DIRECTIONS.RIGHT)}
        />
      </div>
    );
  }
}

ScrollControls.propTypes = {
  canScrollRight: PropTypes.bool.isRequired,
  canScrollLeft: PropTypes.bool.isRequired,
  scroll: PropTypes.func.isRequired,
};

export default ScrollControls;
