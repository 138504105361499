// eslint-disable-next-line import/no-anonymous-default-export
export default (via, setFields) =>
  via.map((value, index) => ({
    value: value.coords,
    text: index + 1,
    draggable: true,
    onChange({ coord: { value: coords } }) {
      const changedVia = { ...via[index], coords };
      if (coords === null) {
        setFields({
          via: [...via.slice(0, index), ...via.slice(index + 1)],
        });
      } else {
        setFields({
          via: [...via.slice(0, index), changedVia, ...via.slice(index + 1)],
        });
      }
    },
  }));
