import React from 'react';
import PropTypes from 'prop-types';
import { Pane, Polyline } from 'react-leaflet';
import get from 'lodash/get';
import { decode } from 'ref-client-core/utils/flexPolyline';
import { getCenterFromPolyline, getBBoxFromPolyline } from '../route/helpers';

function getPolyline(polyline) {
  return decode(polyline).map((latLng) => ({
    lat: +latLng[0],
    lng: +latLng[1],
  }));
}

class LeafletRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showIndicator = false;
  }

  selectPoly = (id) => {
    const { 
      setResultState,
      options: { selectedPath }
    } = this.props;
    const path = selectedPath.split('.')[2];
    const selected = get(this.props, selectedPath, null);
    setResultState({ [path]: selected === id ? null : id });
  };

  componentDidUpdate(prevProps) {
    const { 
      options: { selectedPath, routesArrayPath, pathPath },
      map
    } = this.props;

    const selected = get(this.props, selectedPath, null);
    const prevSelected = get(prevProps, selectedPath, null);

    if ( selected !== null && selected !== prevSelected ) {
      const polylines = get(this.props, routesArrayPath);
      const positions = getPolyline(get(polylines[selected], pathPath));
      const bound = getBBoxFromPolyline(positions);
      const bounds = [[bound.getBottom(), bound.getLeft()],[bound.getTop(), bound.getRight()]];

      if (map.getBounds().contains(bounds)) {
        map.setView(getCenterFromPolyline(positions));
      } else {
        map.fitBounds(bounds);
      }
    }
  }

  renderPolylines = (paths, colorPalette) => {
    const {
      options: { selectedPath, color },
    } = this.props;
    const selected = get(this.props, selectedPath, null);
    const polylines = [];
    let highlightedPane;
    paths.forEach((path, idx) => {
      const { positions, parent } = path;
      if ( parent ) {
        const { approachPathIndex, spanIndex } = parent;
        const parentPath = paths[approachPathIndex];
        const { spans, positions: parentPositions } = parentPath;
        const firstPoint = parentPositions[spans[spanIndex].offset];
        positions.push(firstPoint);
      }
      if (idx === selected) {
        highlightedPane = (
          <Pane key="highlightedRoute" style={{ zIndex: 430 }}>
            <Polyline
              key={idx}
              positions={positions}
              color={colorPalette[color+'Darker']}
              weight={8}
              onClick={() => this.selectPoly(idx)}
            />
          </Pane>
        );
      } else {
        polylines.push(
          <Polyline
            key={idx}
            positions={positions}
            color={colorPalette[color]}
            weight={6}
            onClick={() => this.selectPoly(idx)}
          />
        );
      }
    });

    return [...polylines, highlightedPane];
  };

  render() {
    const {
      options,
      colorPalette,
    } = this.props;
    const {
      routesArrayPath,
      pathPath
    } = options;
    const polylines = get(this.props, routesArrayPath);
    if (!polylines) {
      return null;
    }
    const decodedPolylines = polylines.map(polyline => {
      const positions = getPolyline(get(polyline, pathPath));
      return { ...polyline, positions }
    });

    return (
      <>
        {this.renderPolylines(
          decodedPolylines,
          colorPalette
        )}
      </>
    );
  }
}

LeafletRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default LeafletRoutes;
