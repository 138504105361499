import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import CustomMultiSelect from '../../../views/shared/multiSelect';
import FeaturesGroup from '../../../views/shared/featuresGroup/FeaturesGroup';
import { FormRow, Columns, Select, TabsControl } from '@here/ref-client-ui';
import TransportMode from '../../../views/shared/transportMode';
import './routeMode.scss';
import { find, forIn } from 'lodash';

const bicycleModes = {
  beta: 'betaBicycleRouting',
  enhanced: 'enhancedBicycleRouting',
};

const tabsData = [
  { label: 'Beta Bicycle Routing', name: 'betaBicycleRouting' },
  { label: 'Enhanced Bicycle Routing', name: 'enhancedBicycleRouting' },
];

class RouteMode extends React.Component {
  // Checks if the transport mode is a public transport
  isPublicTransport = (transportMode) => {
    const {
      options: { transportModes },
    } = this.props;
    return find(transportModes, { value: transportMode }).public;
  };

  // Converts a features value string to object
  featuresStrToObj = (featuresStr) => {
    // Intermediate object containing only feature keys and values
    const featuresValues = {};
    const featurePairs = featuresStr.split(',');
    featurePairs.forEach((featurePair) => {
      const [featureKey, featureValue] = featurePair.split(':');
      featuresValues[featureKey] = featureValue;
    });

    // Complete object containing data for all supported features
    const features = {};
    const {
      options: { supportedFeatures },
    } = this.props;
    supportedFeatures.forEach((supportedFeature) => {
      const featureKey = supportedFeature.value;
      let isChecked = false;
      let value = '-3';
      if (featuresValues[featureKey]) {
        isChecked = true;
        value = featuresValues[featureKey];
      }
      features[featureKey] = { value, isChecked };
    });
    return features;
  };

  // Converts a features value object to a string
  featuresObjToStr = (featuresObj) => {
    const checkedFeatures = [];
    forIn(featuresObj, (value, key) => {
      if (value.isChecked) {
        checkedFeatures.push(`${key}:${value.value}`);
      }
    });
    let featuresStr = checkedFeatures.join(',');
    featuresStr = featuresStr ? `;${featuresStr}` : '';
    return featuresStr;
  };

  // Converts a mode value string to an object
  modeStrToObj = (valueStr) => {
    const valueComponents = valueStr.split(';');
    const routeType = valueComponents[0];
    const transportMode = valueComponents[1];
    let trafficMode = '';
    let features = {};
    if (valueComponents.length === 3) {
      if (valueComponents[2].startsWith('traffic')) {
        trafficMode = valueComponents[2];
      } else {
        features = this.featuresStrToObj(valueComponents[2]);
      }
    } else if (valueComponents.length === 4) {
      trafficMode = valueComponents[2];
      features = this.featuresStrToObj(valueComponents[3]);
    }
    return { routeType, transportMode, trafficMode, features };
  };

  /*
   * Converts a mode value object to string. NOTE: If the transport mode is a public transport,
   * features should not be included in the mode value string
   */
  modeObjToStr = (valueObj) => {
    const { transportMode, routeType, trafficMode, features } = valueObj;
    const trafficModeStr = trafficMode ? `;${trafficMode}` : '';
    let featuresStr = '';
    if (!this.isPublicTransport(transportMode)) {
      featuresStr = this.featuresObjToStr(features);
    }
    return `${routeType};${transportMode}${trafficModeStr}${featuresStr}`;
  };

  onChangeBicycleMode = (bicycleMode) => {
    this.props.setFields({
      enhancedbicyclerouting:
        bicycleMode === bicycleModes.enhanced ? 'true' : '',
    });
  };

  onChangeAvoidTransportTypes = (options) => {
    const avoidTransportTypes = options.map((option) => option.value).join(',');
    this.props.setFields({ avoidTransportTypes });
  };

  onChangeFeatures = (data) => {
    const { fields, setFields } = this.props;
    const modeObj = this.modeStrToObj(fields.mode);
    modeObj.features = { ...modeObj.features, ...data };
    setFields({ mode: this.modeObjToStr(modeObj) });
  };

  onChangeMode = (attrKey, e) => {
    const { fields, setFields } = this.props;
    let modeStr = fields.mode;
    const modeObj = this.modeStrToObj(modeStr);
    const data = utils.extractData(e);
    modeObj[attrKey] = data;
    modeStr = this.modeObjToStr(modeObj);
    const fieldValues = { mode: modeStr };

    // If it's not public transport, remove param 'avoid transport types'
    if (!this.isPublicTransport(modeObj.transportMode)) {
      fieldValues.avoidTransportTypes = '';
    }

    // If it's not bicycle mode, remove param 'enhanced bicycle routing'
    if (modeObj.trafficMode !== 'bicycle') {
      fieldValues.enhancedbicyclerouting = '';
    }

    setFields(fieldValues);
  };

  getAvoidTransportTypes = () => {
    let {
      fields,
      options: { transportTypes },
    } = this.props;
    const avoidTransportTypes = fields.avoidTransportTypes
      ? fields.avoidTransportTypes.split(',')
      : [];
    return (
      <CustomMultiSelect
        values={avoidTransportTypes}
        options={transportTypes}
        label="Avoid transport types"
        onChange={this.onChangeAvoidTransportTypes}
      />
    );
  };

  getFeaturesGroup = () => {
    const {
      options: { supportedFeatures, featureSet },
      fields,
    } = this.props;
    const modeStr = fields.mode;
    const { features } = this.modeStrToObj(modeStr);
    return (
      <FeaturesGroup
        label="Features"
        features={features}
        supportedFeatures={supportedFeatures}
        featureSet={featureSet}
        onChange={this.onChangeFeatures}
      />
    );
  };

  render() {
    const {
      options: { label, transportModes, routeTypes, trafficModes },
      fields,
    } = this.props;
    const modeStr = fields.mode;
    const { routeType, transportMode, trafficMode } =
      this.modeStrToObj(modeStr);
    const enhancedbicyclerouting = fields.enhancedbicyclerouting;
    return (
      <div className="rf-mode rf-grey-box" id="mode">
        <h3>{label}</h3>
        <TransportMode
          transports={transportModes}
          value={transportMode}
          onChange={this.onChangeMode.bind(this, 'transportMode')}
        />
        <Columns>
          <FormRow>
            <Select
              label="Route Type"
              options={routeTypes}
              value={routeType}
              onChange={this.onChangeMode.bind(this, 'routeType')}
            />
          </FormRow>
          <FormRow>
            <Select
              label="Traffic routing"
              options={trafficModes}
              value={trafficMode || ''}
              addEmptyOption
              notSelectedLabel="Not set"
              onChange={this.onChangeMode.bind(this, 'trafficMode')}
            />
          </FormRow>
        </Columns>
        {transportMode === 'bicycle' && (
          <>
            <h4 className="rf-bicycle-mode__title">Bicycle mode:</h4>
            <TabsControl
              currentTabName={
                enhancedbicyclerouting === 'true'
                  ? bicycleModes.enhanced
                  : bicycleModes.beta
              }
              onChange={this.onChangeBicycleMode}
              tabsData={tabsData}
            />
          </>
        )}
        {this.isPublicTransport(transportMode)
          ? this.getAvoidTransportTypes()
          : this.getFeaturesGroup()}
      </div>
    );
  }
}

RouteMode.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams }) => {
    const { mode, avoidtransporttypes, enhancedbicyclerouting } = parsedParams;
    const res = {
      mode,
      avoidTransportTypes: avoidtransporttypes,
      enhancedbicyclerouting,
    };
    delete parsedParams.mode;
    delete parsedParams.avoidtransporttypes;
    delete parsedParams.enhancedbicyclerouting;
    return res;
  },
  defaultState: (options) => ({
    mode: options.initValue || 'fastest;car',
    avoidTransportTypes: '',
    enhancedbicyclerouting: '',
  }),
  getRequestOptions: (fields) => {
    const params = fields.mode ? { mode: fields.mode } : {};
    if (fields.avoidTransportTypes) {
      params.avoidTransportTypes = fields.avoidTransportTypes;
    }
    if (fields.enhancedbicyclerouting) {
      params.enhancedbicyclerouting = fields.enhancedbicyclerouting;
    }
    return { params };
  },
  Component: RouteMode,
};
