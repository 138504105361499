import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from '@here/ref-client-ui';
import './formViewErrorHandler.scss';
import { logError } from '../../utils/splunkLog';

class FormViewErrorHandler extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    logError(error);
    this.setState({ error });
  }

  render() {
    const { error: stateError } = this.state;
    const { children, inputConfig, error = stateError } = this.props;
    if (error) {
      return (
        <div className="rf-form-view-error">
          <h3>
            Failed to render:&nbsp;
            <b className="rf-form-view-error__message">
              &quot;{error.message}&quot;
            </b>
          </h3>
          <h4>Form view config:</h4>
          <Tree data={inputConfig} />
        </div>
      );
    }

    return children;
  }
}

FormViewErrorHandler.propTypes = {
  children: PropTypes.node,
  inputConfig: PropTypes.object.isRequired,
  error: PropTypes.object,
};

export default FormViewErrorHandler;
