import React from 'react';
import SpeedGroup from '../consumption/SpeedGroupComponent';
import PropTypes from 'prop-types';
import stringParseUrl from '../stringParseUrl';
import { ExpandFormRow } from '@here/ref-client-ui';

class ConsumptionSpeedTable extends React.Component {
  onChange = (value) => {
    const {
      setFields,
      options: { key },
    } = this.props;

    setFields({ [key]: value });
  };
  render() {
    const {
      fields,
      options: { key, label },
    } = this.props;

    return (
      <ExpandFormRow label={label}>
        <SpeedGroup
          speedArray={fields[key].split(',')}
          onChange={this.onChange}
        />
      </ExpandFormRow>
    );
  }
}

ConsumptionSpeedTable.propTypes = {
  fields: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: stringParseUrl,
  defaultState: ({ key, value }) => ({
    [key]: value || '',
  }),
  getRequestOptions: (fields, { key }) => {
    const params = fields[key] ? { [key]: fields[key] } : {};
    return { params };
  },
  Component: ConsumptionSpeedTable,
};
