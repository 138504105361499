import React, { Component } from 'react';
import { FormRow, Input, TabsControl, Button } from '@here/ref-client-ui';
import { isEqual } from 'lodash';

const tabsData = [
  { label: 'Mapillary', name: 'Mapillary' },
  { label: 'Map Creator', name: 'Map Creator' }
];
const tab = 'Mapillary';
const getLink = (coord, zoom, map)=> {
  const [lat,lng] = coord.split(',');
  const links = {
    'Mapillary': `https://www.mapillary.com/app/?lat=${lat}&lng=${lng}&z=${zoom}`,
    'Map Creator': `https://mapcreator.here.com/?l=${lat},${lng},${zoom},normal`
  };
  return links[map];
}

export default class OpenInForm extends Component {
  constructor(props) {
    super(props);
    let { openIn = {} } = this.props;
    let { lat, lng, zoom } = openIn;
    this.formRef = React.createRef();
    this.state = {
      currentTab: tab,
      coord: `${lat},${lng}`,
      zoom: zoom,
    }
  }
  state = {
    currentTab: 'Mapillary',
    coord: `0,0`,
    zoom: 0,
  }

  link = ''

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props, nextProps) || 
      !isEqual(this.state, nextState)
    )
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.checkBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.checkBlur);
  }

  checkBlur = (e) => {
    let { onBlur } = this.props;
    if (!this.formRef.current.contains(e.target)) {
      onBlur(e);
    }
  };

  onChange = (e, key) => {
    const value = e.target ? e.target.value : e;
    this.setState({
      [key]: value
    });
  }

  onChangeLink = (e) => {
    const value = e.target ? e.target.value : e;
    this.link = value;
  }

  show = () => {
    let { onBlur } = this.props;
    onBlur();
    window.open(this.link);
  }

  render() {
    const { currentTab, coord, zoom } = this.state;
    this.link = getLink(coord, zoom, currentTab);
    return (
      <div className="rf-map__openin-module" ref={this.formRef}>
        <h2>View position in </h2>
        <FormRow>
          <Input
            label="Coordinate (Lat, Lng)"
            value={`${coord}`}
            onChange={(e) => this.onChange(e, `coord`)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="number"
            min="0"
            label="Zoom"
            value={zoom}
            blurOnEnter
            onChange={(e) => this.onChange(e, `zoom`)}
          />
        </FormRow>
        <FormRow className='rf-map__openin-tabs'>
          <TabsControl
            tabsData={tabsData}
            currentTabName={currentTab}
            onChange={(e) => this.onChange(e, `currentTab`)}
          />
        </FormRow>
        <FormRow className="link">
          <Input
            label="Link"
            value={this.link}
            blurOnEnter
            onChange={(e) => this.onChangeLink(e)}
          />
        </FormRow>
        <FormRow >
          <Button onClick={() => this.show()} title="Show" />
        </FormRow>
      </div>
    );
  }
}
