import React from 'react';
import PropTypes from 'prop-types';
import { FormRow } from '@here/ref-client-ui';
import RfAutocomplete from '../shared/autocomplete';
import './inputSearch.scss';

function InputsSearch({ setSearch }) {
  const onSelect = (value) => {
    setSearch(value);
  };

  const data = [];
  document.querySelectorAll('.rf-searchable').forEach((node) => {
    node
      .getAttribute('data-key')
      .split(',')
      .forEach((item) => {
        data.push(item.trim());
      });
  });

  return (
    <FormRow className="rf-input-search">
      <RfAutocomplete
        placeholder="Parameters search"
        label="Parameters search"
        data={data}
        onSelect={onSelect}
        showListRegex={/[a-zA-Z0-9]{2}/}
      />
      <div className="rf-input-search__divider" />
    </FormRow>
  );
}

InputsSearch.propTypes = {
  setSearch: PropTypes.func.isRequired,
};

export default InputsSearch;
