class FormViewsPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported form view type: '${name}'.
          Please register with form views plugin 
          import formViewsPlugin from 'core/formViewsPlugin';
          
          formViewsPlugin.register('${name}', {
            Component: 'Component that represents form view block'
          })`
    );
  }
}

const formViewsPlugin = new FormViewsPlugin();
Object.freeze(formViewsPlugin);

export default formViewsPlugin;
