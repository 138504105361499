import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { latLng } from 'leaflet';
import './polygon.scss';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';

function Polygon(PolygonComponent, MarkerComponent) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      setFields: PropTypes.func.isRequired,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
    };

    onChangePoint = (index, pos) => {
      const {
        options: { path },
        setFields,
      } = this.props;
      
      let outer = get(this.props, path);
      const lng = pos.lng>180?180:(pos.lng<-180?-180:pos.lng);
      outer[index] = {lat: pos.lat.toFixed(6), lng: lng.toFixed(6)};
  
      setFields({
        regionDefinition: { type: "polygon", outer }
      });
    };

    onChangeMidPoint = (index, pos) => {
      const {
        options: { path },
        setFields,
      } = this.props;
      
      let outer = get(this.props, path);
      const lng = pos.lng>180?180:(pos.lng<-180?-180:pos.lng);
      outer = [
        ...outer.slice(0, index),
        {lat: pos.lat.toFixed(6), lng: lng.toFixed(6)},
        ...outer.slice(index)
      ]
      
      setFields({
        regionDefinition: { type: "polygon", outer }
      });
    };

    onChangeShape = (geometryIndex, shape) =>  {
      const {
        setFields,
      } = this.props;
      
      const newShape = shape.map(([a, b]) => { return{lat: (b>90?90:b).toFixed(6), lng: (a>180?180:(a<-180?-180:a)).toFixed(6)}});
      
      setFields({
        regionDefinition: { type: "polygon", outer: newShape }
      });
    }

    render() {
      const {
        options: { path, showUnselected, draggable, resizable, adjustMidPoint },
        selectedTab,
        tabIndex,
      } = this.props;
      if (tabIndex !== selectedTab && !showUnselected) {
        return null;
      }

      const markers = [];
      const positions = [];
      let positionsWithMiddle = [];
      const interpolatedPositions = [];
      let startLat, startLng;
      let isValid = true;
      get(this.props, path, []).forEach(({ lat, lng }, index) => {
        if (validateLat(lat) && validateLon(lng)) {
          const latLngValue = latLng(lat, lng);
          positions.push(latLngValue);
          positionsWithMiddle.push(latLngValue);
          markers.push(
            <MarkerComponent 
            key={index} 
            position={latLngValue} 
            index={index+1} 
            draggable={draggable} 
            resizable={resizable} 
            type={"polygonMarker"}
            onChange={this.onChangePoint.bind(this, index)}
          />);
        } else {
          isValid = false;
        }
      });
      if (markers.length === 0) {
        return null;
      }

      if (draggable || resizable) {
        const length = positions.length;
        let newMiddles = 0;
        positions.forEach(({lat, lng }, index) => {
          if (index > 0) {
            const midlatLngValue = latLng(lat/2 + startLat/2, lng/2 + startLng/2);
            positionsWithMiddle = [
              ...positionsWithMiddle.slice(0, newMiddles+index),
              midlatLngValue,
              ...positionsWithMiddle.slice(newMiddles+index)
            ];
            newMiddles++;
            markers.push(<MarkerComponent 
              key={`middlepoint${index}`}
              position={midlatLngValue}
              index={index}
              draggable={draggable}
              resizable={resizable}
              type={"polygonMarker"}
              middlePoint={true}
              onChange={this.onChangeMidPoint.bind(this, index)}
            />)
          } else if(length > 2) {
            const lastLat = positions[length-1].lat;
            const lastLng = positions[length-1].lng;
            if (validateLat(lastLat) && validateLon(lastLng)) {
              const midlatLngValue = latLng(lat/2 + lastLat/2, lng/2 + lastLng/2);
              positionsWithMiddle = [
                ...positionsWithMiddle.slice(0, newMiddles+index),
                midlatLngValue,
                ...positionsWithMiddle.slice(newMiddles+index)
              ];
              newMiddles++;
              markers.push(<MarkerComponent 
                key={`middlepoint${length}`}
                position={midlatLngValue}
                index={length}
                draggable={draggable}
                resizable={resizable}
                type={"polygonMarker"}
                middlePoint={true}
                onChange={this.onChangeMidPoint.bind(this, length)}
              />)
            }
          }
  
          startLat = lat;
          startLng = lng;
  
        })
        const diff = 0.5;
        const positionsWithMiddleLength = positionsWithMiddle.length;
        let lastPosition = positionsWithMiddle[positionsWithMiddleLength-1];
        for (let i = 0; i<positionsWithMiddleLength; i++) {
          const pos = positionsWithMiddle[i];

          for (let j=1; Math.abs(pos.lat-lastPosition.lat)>=j*diff; j++) {
            const p = Math.abs(pos.lat-lastPosition.lat)/(pos.lat-lastPosition.lat);
            const lat = lastPosition.lat + diff * p * j;
            const dlat = pos.lat - lastPosition.lat;
            const dlng = pos.lng - lastPosition.lng;
            
            let lng = dlng * diff * p * j / dlat + lastPosition.lng;

            interpolatedPositions.push({lat, lng});
          }
          interpolatedPositions.push(pos);
          lastPosition = pos;
        }
        positionsWithMiddle = interpolatedPositions;
      }

      return (
        <>
          {isValid && ( adjustMidPoint ? <PolygonComponent 
          positions={positions} 
          coatPositions={positionsWithMiddle}
          showCoatStroke={true}
          draggable={draggable} 
          resizable={resizable}
          onChange={this.onChangeShape.bind(this, 0)}
          /> : <PolygonComponent 
          positions={positions} 
          draggable={draggable} 
          resizable={resizable}
          onChange={this.onChangeShape.bind(this, 0)}
          />
          )}
          {markers}
        </>
      );
    }
  };
}

export default Polygon;
