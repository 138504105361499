import React from 'react';
import PropTypes from 'prop-types';
import TransportMode from '../../../views/shared/transportMode';
import stringParseUrl from '../stringParseUrl';
import stringGetRequestOptions from '../stringGetRequestOptions';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class TransportModePlugin extends React.Component {
  onChange = (mode) => {
    const {
      options: { key },
      setFields,
    } = this.props;
    setFields({ [key]: mode });
  };

  render() {
    const {
      options: { key, transports },
      fields,
    } = this.props;
    const value = fields[key];
    return (
      <Searchable searchKey={key}>
        <TransportMode
          transports={transports}
          value={value}
          onChange={this.onChange}
        />
      </Searchable>
    );
  }
}

TransportModePlugin.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: (data) => {
    const { options } = data;
    const { isPost, key } = options;
    const res = stringParseUrl(data);
    if (!res[key] && isPost) {
      return { [key]: options.initValue };
    }

    return res;
  },
  defaultState: (options) => ({
    [options.key]: options.initValue,
  }),
  getRequestOptions: stringGetRequestOptions,
  Component: TransportModePlugin,
};
