const routeHandle = {
  title: 'Route Handle',
  tabTitle: 'Handle',
  settings: {
    'Api Key': {
      type: 'apiKey',
      url: 'https://router.hereapi.com/v8/routes/{routeHandle}',
      apikey: process.env.REACT_APP_APIKEY,
    },
    'Api Key 2': {
      type: 'apiKey',
      url: 'https://router.hereapi.com/v8/routes/{routeHandle}',
      apikey: process.env.REACT_APP_APIKEY2,
    },
  },
  inputs: [
    {
      type: 'input',
      options: {
        key: 'routeHandle',
        label: 'Route Handle',
        isPathParam: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.routeHandle.description',
            },
          ],
        },
      },
    },
    {
      type: 'group',
      options: {
        cssClasses: 'rf-grey-box',
        isExpanded: true,
        inputs: [
        {
          type: 'olsWaypoint',
          options: {
            key: 'origin',
            label: 'Origin',
            openapiInfo: {
              urlPath: 'settings.url',
              urlRegex: '.*/v8/',
              urlSuffix: 'openapi',
              keys: [
                {
                  key: 'description',
                  path: 'components.parameters.origin.description',
                },
              ],
            },
            advancedOptions: {
              course: true,
              sideOfStreetHint: true,
              displayLocation: true,
              matchSideOfStreet: true,
              nameHint: true,
              radius: true,
              snapRadius: true,
              minCourseDistance: true,
              customizationIndex: true,
              passThrough: true,
              stopDuration: true,
              radiusPenalty: true,
              segmentIdHint: true,
              onRoadThreshold: true,
              extraParams: true
            }
          },
        }]
      }
    },
    {
      type: 'checkboxes',
      options: {
        key: 'return',
        label: 'Return',
        initState: 'polyline,summary,actions,instructions',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        labelMapping: [{
          potentialTimeDependentViolations: 'potentialViolations'
        }],
        loadParams: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          resultPath: 'components.schemas.Return.enum',
        },
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.return.description',
            },
          ],
        },
      },
    },
    {
      type: 'input',
      options: {
        key: 'currency',
        label: 'Currency',
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.currency.description',
            },
          ],
        },
      },
    },
    {
      type: 'group',
      options: {
        isExpanded: true,
        label: 'Rerouting',
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'select',
            options: {
              key: 'rerouting[mode]',
              label: 'Mode',
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.ReroutingMode.enum',
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.ReroutingMode.description',
                  },
                ],
              },
            },
          },
          {
            type: 'input',
            options: {
              key: 'rerouting[lastTraveledSectionIndex]',
              label: 'Last traveled section index',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Rerouting.properties.lastTraveledSectionIndex.description',
                  },
                ],
              },
            },
          },
          {
            type: 'input',
            options: {
              key: 'rerouting[traveledDistanceOnLastSection]',
              label: 'Traveled distance on last section',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Rerouting.properties.traveledDistanceOnLastSection.description',
                  },
                ],
              },
            },
          }
        ],
      },
    },
    {
      type: 'checkboxes',
      options: {
        key: 'spans',
        label: 'Spans',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        loadParams: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          resultPath: 'components.schemas.Spans.enum',
        },
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.spans.description',
            },
          ],
        },
      },
    },
    {
      type: 'transportMode',
      options: {
        key: 'transportMode',
        initValue: 'car',
        transports: [
          {
            label: 'Car',
            value: 'car',
          },
          {
            label: 'Pedestrian',
            value: 'pedestrian',
          },
          {
            label: 'Truck',
            value: 'truck',
          },
          {
            label: 'Bicycle',
            value: 'bicycle',
          },
          {
            label: 'Scooter',
            value: 'scooter',
          },
          {
            label: 'Bus',
            value: 'bus',
          },
          {
            label: 'Taxi',
            value: 'taxi',
          },
        ],
      },
    },
    {
      type: 'group',
      options: {
        label: 'Vehicle attributes',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        conditions: [
          { type: 'contains', key: 'fields.transportMode', value: 'car,truck,bus,taxi' },
        ],
        inputs: [
          {
            type: 'select',
            options: {
              key: 'taxi[allowDriveThroughTaxiRoads]',
              label: 'Allow Drive Through Taxi Roads',
              groupConditions: [
                { type: 'equals', key: 'fields.transportMode', value: 'taxi' }
              ],
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Taxi.properties.allowDriveThroughTaxiRoads.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[hovOccupancy]',
              label: 'HOV Occupancy',
              placeholder: 'HOV Occupancy (min 2)',
              inputType: 'number',
              groupConditions: [
                { type: 'equals', key: 'fields.transportMode', value: 'car' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.hovOccupancy.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.hovOccupancy.minimum'
                  }
                ]
              },
              validation: [
                {
                  type: 'min',
                  value: 2
                }
              ]
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[speedCap]',
              label: 'Speed cap (m/s)',
              placeholder: 'Speed cap (min 1, max 70)',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.speedCap.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.speedCap.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.speedCap.maximum'
                  }
                ]
              },
              validation: [
                {
                  type: 'min',
                  value: 1
                },
                {
                  type: 'max',
                  value: 70
                }
              ]
            }
          },
          {
            type: 'checkboxes',
            options: {
              key: 'vehicle[shippedHazardousGoods]',
              label: 'Shipped Hazardous Goods',
              isExpanded: false,
              params: [
                { value: 'explosive', label: 'explosive' },
                { value: 'gas', label: 'gas' },
                { value: 'flammable', label: 'flammable' },
                { value: 'combustible', label: 'combustible' },
                { value: 'organic', label: 'organic' },
                { value: 'poison', label: 'poison' },
                { value: 'radioactive', label: 'radioactive' },
                { value: 'corrosive', label: 'corrosive' },
                {
                  value: 'poisonousInhalation',
                  label: 'poisonousInhalation'
                },
                { value: 'harmfulToWater', label: 'harmfulToWater' },
                { value: 'other', label: 'other' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.shippedHazardousGoods.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Gross Weight (in kg.)',
              placeholder: 'Gross Weight (minimum 0)',
              key: 'vehicle[grossWeight]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.grossWeight.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.grossWeight.minimum'
                  }
                ]
              },
              validation: [{ type: 'min', value: 0 }]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Current Weight (in kg.)',
              placeholder: 'Current Weight (minimum 0)',
              key: 'vehicle[currentWeight]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.currentWeight.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.currentWeight.minimum'
                  }
                ]
              },
              validation: [{ type: 'min', value: 0 }]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Weight Per Axle (in kg.)',
              placeholder: 'Weight Per Axle (minimum 0)',
              key: 'vehicle[weightPerAxle]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.weightPerAxle.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.weightPerAxle.minimum'
                  }
                ]
              },
              validation: [{ type: 'min', value: 0 }]
            }
          },
          {
            type: 'inputObject',
            options: {
              label: 'Weight Per Axle Group',
              key: 'vehicle[weightPerAxleGroup]',
              inputType: 'number',
              isExpanded: false,
              groupConditions: [
                { type: 'contains', key: 'fields.transportMode', value: 'truck,bus' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.WeightPerAxleGroup.description'
                  }
                ]
              },
              inputs: [
                {
                  key: 'single',
                  label: 'Single',
                  placeholder: 'Single'
                }, {
                  key: 'tandem',
                  label: 'Tandem',
                  placeholder: 'Tandem'
                }, {
                  key: 'triple',
                  label: 'Triple',
                  placeholder: 'Triple'
                }, {
                  key: 'quad',
                  label: 'Quad',
                  placeholder: 'Quad'
                }, {
                  key: 'quint',
                  label: 'Quint',
                  placeholder: 'Quint'
                }
              ]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Height (in cm.)',
              placeholder: 'Height (min 0, max 5000)',
              key: 'vehicle[height]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.height.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.height.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.height.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 0 },
                { type: 'max', value: 5000 }
              ]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Width (in cm.)',
              placeholder: 'Width (min 0, max 5000)',
              key: 'vehicle[width]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.width.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.width.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.width.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 0 },
                { type: 'max', value: 5000 }
              ]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Length (in cm.)',
              placeholder: 'Length (min 0, max 30000)',
              key: 'vehicle[length]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.length.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.length.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.length.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 0 },
                { type: 'max', value: 30000 }
              ]
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[kpraLength]',
              label: 'Kingpin to rear axle length',
              groupConditions: [
                { type: 'contains', key: 'fields.transportMode', value: 'car,truck,taxi' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.kpraLength.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.kpraLength.minimum'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[payloadCapacity]',
              label: 'Payload Capacity',
              inputType: 'number',
              groupConditions: [
                { type: 'contains', key: 'fields.transportMode', value: 'car,truck,taxi' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.payloadCapacity.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.payloadCapacity.minimum'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'vehicle[tunnelCategory]',
              label: 'Tunnel Category',
              selectOptions: [
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.TunnelCategory.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Axle Count',
              placeholder: 'Axle Count (min 2)',
              key: 'vehicle[axleCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.axleCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.axleCount.minimum'
                  }
                ]
              },
              validation: [{ type: 'min', value: 2 }]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Trailer Axle Count',
              placeholder: 'Trailer Axle Count (min 1)',
              key: 'vehicle[trailerAxleCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.trailerAxleCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.trailerAxleCount.minimum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Tires Count',
              placeholder: 'Tires Count (min 1)',
              key: 'vehicle[tiresCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.tiresCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.tiresCount.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.tiresCount.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          },
          {
            type: 'select',
            options: {
              key: 'vehicle[type]',
              label: 'Truck type',
              groupConditions: [
                { type: 'contains', key: 'fields.transportMode', value: 'truck,bus' }
              ],
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.VehicleType.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.VehicleType.description'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'vehicle[category]',
              label: 'Category',
              groupConditions: [
                { type: 'equals', key: 'fields.transportMode', value: 'truck' }
              ],
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.Vehicle.properties.category.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.category.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Trailer count',
              placeholder: 'Trailer count (min 0, max 255)',
              key: 'vehicle[trailerCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.trailerCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.trailerCount.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.trailerCount.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 0 },
                { type: 'max', value: 255 }
              ]
            }
          },
          {
            type: 'licensePlate',
            options: {
              key: 'vehicle[licensePlate]',
              label: 'License Plate',
              placeholder: 'Last Character',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.licensePlate.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[occupancy]',
              label: 'Vehicle Occupancy',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.occupancy.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.occupancy.minimum'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'vehicle[engineType]',
              label: 'Engine Type',
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.Vehicle.properties.engineType.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.engineType.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Height Above First Axle',
              placeholder: 'Height Above First Axle (min 1)',
              key: 'vehicle[heightAboveFirstAxle]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          },
          {
            type: 'select',
            options: {
              label: 'Commercial',
              key: 'vehicle[commercial]',
              type: 'boolean',
              selectOptions: [
                { value: 'true', label: 'True' },
                { value: 'false', label: 'False' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.commercial.description'
                  }
                ]
              }
            }
          }
        ],
      },
    },
    {
      type: 'group',
      options: {
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        label: 'Vehicle attributes',
        conditions: [
          {
            type: 'equals',
            key: 'fields.transportMode',
            value: 'scooter',
          },
        ],
        inputs: [
          {
            type: 'input',
            options: {
              key: 'vehicle[speedCap]',
              label: 'Speed cap (m/s)',
              placeholder: 'Speed cap (min 1, max 70)',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.speedCap.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.speedCap.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.speedCap.maximum'
                  }
                ]
              },
              validation: [
                {
                  type: 'min',
                  value: 1
                },
                {
                  type: 'max',
                  value: 70
                }
              ]
            }
          },
          {
            type: 'select',
            options: {
              key: 'scooter[allowHighway]',
              label: 'Allow Highway',
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Scooter.properties.allowHighway.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              key: 'vehicle[occupancy]',
              label: 'Vehicle Occupancy',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.occupancy.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.occupancy.minimum'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Trailer Axle Count',
              placeholder: 'Trailer Axle Count (min 1)',
              key: 'vehicle[trailerAxleCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.trailerAxleCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.trailerAxleCount.minimum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          },
          {
            type: 'input',
            options: {
              label: 'Tires Count',
              placeholder: 'Tires Count (min 1)',
              key: 'vehicle[tiresCount]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.tiresCount.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.tiresCount.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.tiresCount.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          },
          {
            type: 'select',
            options: {
              key: 'vehicle[engineType]',
              label: 'Engine Type',
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.Vehicle.properties.engineType.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.engineType.description'
                  }
                ]
              }
            }
          },
          {
            type: 'input',
            options: {
              label: 'Height Above First Axle',
              placeholder: 'Height Above First Axle (min 1)',
              key: 'vehicle[heightAboveFirstAxle]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.description'
                  },
                  {
                    key: 'minimum',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.minimum'
                  },
                  {
                    key: 'maximum',
                    path: 'components.schemas.Vehicle.properties.heightAboveFirstAxle.maximum'
                  }
                ]
              },
              validation: [
                { type: 'min', value: 1 }
              ]
            }
          }
        ],
      },
    },
    {
      type: 'group',
      options: {
        label: 'Allow',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'select',
            options: {
              key: 'allow[hov]',
              label: 'Allow HOV lanes',
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Allow.properties.hov.description'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'allow[hot]',
              label: 'allow HOT lanes',
              selectOptions: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ],
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Allow.properties.hot.description'
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      type: 'routeTime',
      options: {
        label: 'Departure Time',
        cssClasses: 'rf-grey-box',
        isLocalSupported: true,
        supportAny: true,
        supportArrival: true,
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.departureTimeWithAny.description',
            },
          ],
        },
      },
    },
    {
      type: 'group',
      options: {
        label: 'Consumption',
        isExpanded: true,
        cssClasses: 'rf-grey-box rf-consumption',
        inputs: [
          {
            type: 'group',
            options: {
              label: 'Vehicle Parameters',
              isExpanded: false,
              inputs: [
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[frontalArea]',
                    label: 'Frontal Area',
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.frontalArea.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.frontalArea.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.Vehicle.properties.frontalArea.maximum'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[rollingResistanceCoefficient]',
                    label: 'Rolling Resistance Coefficient',
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.rollingResistanceCoefficient.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.rollingResistanceCoefficient.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.Vehicle.properties.rollingResistanceCoefficient.maximum'
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'input',
                  options: {
                    key: 'vehicle[airDragCoefficient]',
                    label: 'Air Drag Coefficient',
                    inputType: 'number',
                    openapiInfo: {
                      urlPath: 'settings.url',
                      urlRegex: '.*/v8/',
                      urlSuffix: 'openapi',
                      keys: [
                        {
                          key: 'description',
                          path: 'components.schemas.Vehicle.properties.airDragCoefficient.description'
                        },
                        {
                          key: 'minimum',
                          path: 'components.schemas.Vehicle.properties.airDragCoefficient.minimum'
                        },
                        {
                          key: 'maximum',
                          path: 'components.schemas.Vehicle.properties.airDragCoefficient.maximum'
                        }
                      ]
                    }
                  }
                }
              ]
            }
          },
          {
            type: 'olsEvConsumption'
          }
        ]
      }
    },
    {
      type: 'group',
      options: {
        label: 'Avoid',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'checkboxes',
            options: {
              key: 'avoid[features]',
              label: 'Avoid features',
              isExpanded: true,
              params: [
                { value: 'seasonalClosure', label: 'Seasonal Closure' },
                { value: 'tollRoad', label: 'Toll Road' },
                {
                  value: 'controlledAccessHighway',
                  label: 'Controlled Access Highway',
                },
                { value: 'ferry', label: 'Ferry' },
                { value: 'carShuttleTrain', label: 'Car Shuttle Train' },
                { value: 'tunnel', label: 'Tunnel' },
                { value: 'dirtRoad', label: 'Dirt Road' },
                { value: 'difficultTurns', label: 'Difficult Turns' },
                { value: 'uTurns', label: 'U-Turns' },
              ],
            },
          },
          {
            type: 'avoidExclude',
            options: {
              isPost: false,
            },
          },
          {
            type: 'input',
            options: {
              key: 'avoid[zoneIdentifiers]',
              label: 'Avoid zone identifiers',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Avoid.properties.zoneIdentifiers.description',
                  },
                ],
              },
            },
          },
          {
            type: 'input',
            options: {
              key: 'avoid[zoneCategories]',
              label: 'Avoid zone categories',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Avoid.properties.zoneCategories.description',
                  },
                ],
              },
            },
          },
          {
            type: 'avoidSegments',
          },
          {
            type: 'input',
            options: {
              key: 'avoid[tollTransponders]',
              label: 'Avoid toll transponders',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Avoid.properties.tollTransponders.description',
                  },
                ],
              },
            },
          },
        ],
      },
    },
    {
      type: 'group',
      options: {
        label: 'Exclude',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'avoidExclude',
            options: {
              isPost: false,
              mode: 'exclude'
            },
          },
          {
            type: 'input',
            options: {
              label: 'Exclude countries',
              placeholder: 'Exclude countries',
              key: 'exclude[countries]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Exclude.properties.countries.description'
                  }
                ]
              }
            }
          },
          {
            type: 'excludeStates',
            options: {
              label: 'Exclude states',
              placeholder: 'Country code',
              key: 'exclude[states]',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Exclude.properties.states.description'
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      type: 'segmentSpeedCap',
      options: {
        label: 'Segment Speed Cap',
        isExpanded: true,
        key: 'maxSpeedOnSegment'
      }
    },
    {
      type: 'extraParams',
    },
    {
      type: 'language',
      options: {
        key: 'lang',
        label: 'Language',
      },
    },
    {
      type: 'select',
      options: {
        label: 'Units',
        key: 'units',
        selectOptions: [
          { label: 'Metric', value: 'metric' },
          { label: 'Imperial/US', value: 'imperial' },
        ],
        openapiInfo: {
          urlPath: 'settings.url',
          urlRegex: '.*/v8/',
          urlSuffix: 'openapi',
          keys: [
            {
              key: 'description',
              path: 'components.parameters.units.description',
            },
          ],
        },
      },
    },
    {
      type: 'group',
      options: {
        label: 'Traffic',
        isExpanded: true,
        cssClasses: 'rf-grey-box',
        inputs: [
          {
            type: 'input',
            options: {
              key: 'traffic[overrideFlowDuration]',
              label: 'Traffic Override Flow Duration',
              inputType: 'number',
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Traffic.properties.overrideFlowDuration.description'
                  }
                ]
              }
            }
          },
          {
            type: 'select',
            options: {
              key: 'traffic[mode]',
              label: 'Traffic Mode',
              loadParams: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                resultPath: 'components.schemas.Traffic.properties.mode.enum'
              },
              openapiInfo: {
                urlPath: 'settings.url',
                urlRegex: '.*/v8/',
                urlSuffix: 'openapi',
                keys: [
                  {
                    key: 'description',
                    path: 'components.schemas.Traffic.properties.mode.description'
                  }
                ]
              }
            }
          }
        ]
      }
    }
  ],
  map: [
    {
      type: 'notices',
      options: {
        routesArrayPath: 'result.raw.data.routes',
        sectionsPath: 'sections',
        routePath: 'polyline',
        format: 'flexPolyline',
        selectedPath: 'result.state.currentRoute',
        contextMenuFormatter: 'openChargingStations',
        contextMenuLabel: 'Charging stations along the route',
        getMapAttributes: true
      }
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'setFields',
            options: {
              text: 'Set origin',
              key: 'origin',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true,
                },
              ],
            },
          },
          {
            type: 'avoidExcludeAreas',
            options: {
              text: 'Add Avoid/Exclude Areas'
            }
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon',
            },
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address',
            },
          },
        ],
      },
    },
    {
      type: 'marker',
      options: {
        text: 'A',
        coordsPath: 'fields.origin',
      },
    },
    {
      type: 'evRoute',
      options: {
        routesArrayPath: 'result.raw.data.routes',
        sectionsPath: 'sections',
        routePath: 'polyline',
        format: 'olsEvPolylines',
        selectedPath: 'result.state.currentRoute',
        hasNoEnergyColor: '#E60000',
        lowEnergyColor: '#F07D02',
      },
    },
    {
      type: 'routes',
      options: {
        routesArrayPath: 'result.raw.data.routes',
        sectionsPath: 'sections',
        routePath: 'polyline',
        format: 'flexPolyline',
        selectedPath: 'result.state.currentRoute',
        highlightedPath: 'result.state.highlightedRoute',
      },
    },
    {
      type: 'locationOriginalLocationDiffRoutes',
    },
    {
      type: 'markers',
      options: {
        format: 'routeDecodingMarkers',
      },
    },
    {
      type: 'markers',
      options: {
        format: 'advancedLocations',
        removable: true
      }
    },
    {
      type: 'pointsLocationPositions'
    },
    {
      type: 'rectangleList',
      options: {
        path: 'fields.avoid.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        color: '#ff0000',
        fillColor: 'rgba(255, 0, 0, 0.4)',
        exceptionColor: '#ffff00',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'polygonList',
      options: {
        path: 'fields.avoid.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffff00',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'corridorList',
      options: {
        path: 'fields.avoid.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffff00',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'rectangleList',
      options: {
        path: 'fields.exclude.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        color: '#ffffff',
        fillColor: 'rgba(255, 0, 0, 0.5)',
        exceptionColor: '#ffffff',
        exceptionFillColor: 'rgba(255, 255, 0, 0.5)',
        fillOpacity: 1,
        mode: 'exclude',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'polygonList',
      options: {
        path: 'fields.exclude.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffffff',
        exceptionFillColor: 'rgba(255, 255, 0, 0.5)',
        color: '#ffffff',
        fillColor: 'rgba(0, 0, 155, 0.5)',
        fillOpacity: 1,
        mode: 'exclude',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'corridorList',
      options: {
        path: 'fields.exclude.areas',
        onChangeFormatter: 'avoidAreaOnMapChange',
        onChangeExcludeFormatter: 'avoidExcludeArea',
        exceptionColor: '#ffffff',
        exceptionFillColor: 'rgba(255, 255, 0, 0.5)',
        color: '#ffffff',
        fillColor: 'rgba(0, 0, 155, 0.5)',
        fillOpacity: 1,
        mode: 'exclude',
        draggable: true,
        resizable: true
      }
    },
    {
      type: 'route',
      options: {
        routePath: 'result.state.selectedManeuver.maneuver.shape',
        colorPath: 'colorPalette.primaryDarker',
        zIndex: 1000,
        centerOnChange: true,
      },
    },
    {
      type: 'markers',
      options: {
        text: '&#x1f50b;',
        format: 'evMarkers',
      },
    },
    {
      type: 'circle',
      options: {
        centerPath: 'result.state.selectedManeuver.maneuver.circle',
        radius: 50,
        colorPath: 'colorPalette.primaryDarker',
      },
    },
    {
      type: 'circle',
      options: {
        centerPath: 'result.state.hoveredPoint',
        radius: 80,
        adaptiveSize: true,
        colorPath: 'colorPalette.primaryDarker',
      },
    },
    {
      type: 'circle',
      options: {
        centerPath: 'result.state.selectedPoint',
        radius: 80,
        adaptiveSize: true,
        colorPath: 'colorPalette.primaryDarker',
        centerOnChange: true
      }
    },
    {
      type: 'rorTooltip',
    },
    {
      type: 'centerMap',
      options: {
        path: 'result.state.selectedNotice.shape',
      },
    },
  ],
  resultPanel: [
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Summary',
            type: 'olsSummary',
          },
          {
            title: 'Instructions',
            type: 'maneuvers',
            options: {
              key: 'selectedManeuver',
              formatter: 'actionsToManeuvers',
            },
          },
          {
            title: 'TBT Actions',
            type: 'maneuvers',
            options: {
              key: 'selectedManeuver',
              formatter: 'tbtActionsToManeuvers',
            },
          },
          {
            title: 'Graphs',
            type: 'profiling',
          },
          {
            title: 'Spans',
            type: 'maneuvers',
            options: {
              key: 'selectedManeuver',
              formatter: 'spansToManeuvers',
            },
          },
          {
            title: 'Response',
            type: 'rawResponse',
          },
        ],
      },
    },
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'required',
        options: {
          path: 'fields.routeHandle',
        },
      },
    ],
  },
};

export default routeHandle;
