import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (waypoints) => {
  const { length } = waypoints;
  return waypoints.map((waypoint, index) => {
    let text = index === 0 ? 'A' : `${index}`;
    if (index + 1 === length) {
      text = 'B';
    }

    return {
      value: waypoint.coords || get(waypoint, 'displayPosition', ''),
      text,
      draggable: false,
    };
  });
};
