import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import Markers from './Markers';
import Marker from '../marker/LeafletMarker';
import HereMarker from '../marker/HereMarker';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Markers(Marker),
  [TILE_LIBRARY_TYPES.JSLA]: Markers(HereMarker),
};
