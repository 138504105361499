import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import axios from 'axios';
import { merge } from 'lodash';
import isHttpWithHttps, {
  isHttpWithHttpsNotification,
} from './isHttpWithHttps';

class AppIdAppCodeSettings extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    appId: PropTypes.string,
    appCode: PropTypes.string,
    isCustom: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    setNotification: PropTypes.func.isRequired,
  };

  onChange(key, { target: { value } }) {
    if (isHttpWithHttps(value)) {
      const { setNotification } = this.props;
      isHttpWithHttpsNotification(setNotification);
    }
    this.props.onChange({ [key]: value });
  }

  render() {
    const { url, appId, appCode, isCustom } = this.props;

    return (
      <div className="rf-app-id-code-form">
        <FormRow>
          <Input
            type="text"
            label="Url"
            value={url}
            isCompact
            onBlur={this.onChange.bind(this, 'url')}
            blurOnEnter
            isReadonly={!isCustom}
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            label="App ID"
            value={appId}
            onBlur={this.onChange.bind(this, 'appId')}
            blurOnEnter
            isReadonly={!isCustom}
          />
        </FormRow>
        <FormRow>
          <Input
            type="text"
            label="App Code"
            value={appCode}
            onBlur={this.onChange.bind(this, 'appCode')}
            blurOnEnter
            isReadonly={!isCustom}
          />
        </FormRow>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: (params) => {
    const res = {
      appId: params.app_id,
      appCode: params.app_code,
    };
    delete params.app_id;
    delete params.app_code;

    return res;
  },
  getDisplayUrl: (settings, fieldRequestOptions) => {
    const { appId, appCode, url = '' } = settings;
    const params = {
      app_id: appId,
      app_code: appCode,
    };
    return decodeURIComponent(
      axios.getUri(merge({ url, params }, fieldRequestOptions))
    );
  },
  getRequestOptions: (settings) => ({
    url: settings.url,
    params: {
      app_id: settings.appId,
      app_code: settings.appCode,
    },
  }),
  Component: AppIdAppCodeSettings,
};
