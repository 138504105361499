import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

function Route(RouteComponent) {
  return class Route extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      result: PropTypes.object,
      colorPalette: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
    };

    render() {
      const {
        options,
        colorPalette,
        refClient: { formatterPlugin },
      } = this.props;
      const { routePath, format, colorPath, weight, zIndex } = options;
      const route = get(this.props, routePath);
      if (!route) {
        return null;
      }

      let positions = route;
      if (format) {
        const formatter = formatterPlugin.get(format);
        positions = formatter(route);
      }

      let color = colorPalette.secondary;
      if (colorPath) {
        color = get(this.props, colorPath);
      }
      return (
        <RouteComponent
          centerOnChange={options.centerOnChange}
          positions={positions}
          color={color}
          weight={weight || 10}
          zIndex={zIndex}
        />
      );
    }
  };
}

export default Route;
