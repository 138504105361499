import React, { Component } from 'react';
import { connect } from 'react-redux';

import ViewInForm from './ViewInForm';
import { getIsOpenInShown } from '../../state/ui';

class OpenInContainer extends Component {

  onToggleForm = () => {
    const { setIsOpenInShown } = this.props;
    setIsOpenInShown(false);
  };


  renderForm() {
    const { isOpenInShown = {} } = this.props;
    const { openIn } = isOpenInShown;
    return (
      <ViewInForm
        onBlur={this.onToggleForm}
        openIn={openIn}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderForm()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenInShown: getIsOpenInShown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenInContainer);
