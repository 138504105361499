import React from 'react';
import PropTypes from 'prop-types';
import { ExpandFormRow } from '@here/ref-client-ui';
import classnames from 'classnames';
import './maneuvers.scss';
import { get } from 'lodash';
import { sectionTransportSummary } from "../../../utils/publicTransit";

class OlsPtManeuvers extends React.Component {
  getInstructions = (section) => {
    const listItemClasses = classnames('rf-maneuvers');
    const { type } = section;
    if (type === 'pedestrian' || type === 'vehicle' || type === 'rented' || type === 'taxi') {
      if (type === 'pedestrian' && !section.actions) {
        return [
          <li className={listItemClasses} key="indoor">
            Indoor section
          </li>,
        ];
      }
      return section.actions.map((action, actionIdx) => (
        <li className={listItemClasses} key={actionIdx}>
          {action.instruction}
        </li>
      ));
    } else {
      const departureType = get(section, 'departure.place.type');
      const departureName = get(section, 'departure.place.name');
      const arrivalType = get(section, 'arrival.place.type');
      const arrivalName = get(section, 'arrival.place.name');
      return [
        <li
          className={listItemClasses}
          key="departure"
        >{`Enter at ${departureType} ${departureName}`}</li>,
        <li
          className={listItemClasses}
          key="arrival"
        >{`Leave at ${arrivalType} ${arrivalName}`}</li>,
      ];
    }
  };

  render() {
    const { result } = this.props;
    const currentRoute = result.state.currentRoute || 0;
    const routes = get(result, `raw.data.routes`, []);
    const route = routes[currentRoute] || {};
    const sections = route.sections || [];

    const sectionsEls = sections.map((section, index) => {
      const label = `${index + 1} - ${sectionTransportSummary(section)}`;
      return (
        <ExpandFormRow label={label} key={label}>
          <ul style={{ marginTop: '10px' }}>{this.getInstructions(section)}</ul>
        </ExpandFormRow>
      );
    });

    return <div className="rf-ols-summary">{sectionsEls}</div>;
  }
}

OlsPtManeuvers.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default OlsPtManeuvers;
