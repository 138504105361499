import { getAppSettings } from '../appSettings';
import { search } from './api';
import { getMapBounds } from '../map/bounds/reducer';
import { set as setNotification } from 'state/notification/actions';

const getDefaultState = () => ({
  suggestions: [],
  selected: null,
  isLoading: false,
});

const SET_IS_LOADING = 'search/SET_IS_LOADING';
const SET_SUGGESTIONS = 'search/SET_SUGGESTIONS';
const SET_SELECTED = 'search/SET_SELECTED';
const CLEAR = 'search/CLEAR';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case SET_SUGGESTIONS:
      return {
        ...getDefaultState(),
        suggestions: [...action.payload],
        isLoading: false,
      };
    case SET_SELECTED:
      return { ...state, selected: action.payload };

    case CLEAR:
      return getDefaultState();
    case SET_IS_LOADING:
      return { ...getDefaultState(), isLoading: action.payload };
    default:
      return state;
  }
};

export const setSearchIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: isLoading,
});
export const clearSearch = () => ({ type: CLEAR });
export const setSearchSelected = (index) => ({
  type: SET_SELECTED,
  payload: index,
});
export const setSearchSuggestions = (suggestions) => ({
  type: SET_SUGGESTIONS,
  payload: suggestions,
});

export const requestSearchSuggestions = (searchStr) => (dispatch, getState) => {
  const state = getState();
  dispatch(setSearchIsLoading(true));
  search(searchStr, getAppSettings(state), getMapBounds(state))
    .then((suggestions) => {
      dispatch(setSearchSuggestions(suggestions));
    })
    .catch((err = 'Server error') => {
      dispatch(setSearchIsLoading(false));
      const message = err.toString();
      dispatch(
        setNotification({
          message: `Search failed: "${message}"`,
          impact: 'negative',
          autoDismiss: 5,
        })
      );
    });
};

export const getSearchData = (state) => state.search;
