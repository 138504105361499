import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Icons from './icons';

function PublicTransitMarkers(MarkerComponent) {
  return class PublicTransitRoutes extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      result: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
      selectedTab: PropTypes.number,
    };

    renderMarkers = (routes) => {
      const {
        options: { selectedPath },
        selectedTab,
        tabIndex,
      } = this.props;
      const selectedRoute = get(this.props, selectedPath, 0);
      const markers = [];
      routes.forEach((routeWrapper, routeIndex) => {
        if (routeIndex === selectedRoute) {
          const sections = get(routeWrapper, 'sections', []);
          const lastSectionIdx = sections.length - 1;
          sections.forEach((section, sectionIdx) => {
            if (section.type === 'transit') {
              const prevIsTransit = sectionIdx > 0 && sections[sectionIdx - 1].type === 'transit'
              const nextIsTransit = sectionIdx < lastSectionIdx && sections[sectionIdx + 1].type === 'transit'

              if (!prevIsTransit) {
                // Render 'enter' icon
                markers.push(
                  <MarkerComponent
                    key={`enter-${sectionIdx}`}
                    coords={section.departure.place.location}
                    iconSvg={Icons.ENTER}
                    options={{ draggable: false }}
                    selectedTab={selectedTab}
                    tabIndex={tabIndex}
                  />
                );
              } else {
                // Render 'switch' icon
                markers.push(
                  <MarkerComponent
                    key={`switch-${sectionIdx}`}
                    coords={section.departure.place.location}
                    iconSvg={Icons.SWITCH}
                    options={{ draggable: false }}
                    selectedTab={selectedTab}
                    tabIndex={tabIndex}
                  />
                );
              }

              if (!nextIsTransit) {
                markers.push(
                  <MarkerComponent
                    key={`leave-${sectionIdx}`}
                    coords={section.arrival.place.location}
                    iconSvg={Icons.LEAVE}
                    options={{ draggable: false }}
                    selectedTab={selectedTab}
                    tabIndex={tabIndex}
                  />
                );
              }
            }
          });
        }
      });
      return markers;
    };

    render() {
      const { options } = this.props;
      const routes = get(this.props, options.routesArrayPath);
      if (!routes) {
        return null;
      }
      return this.renderMarkers(routes);
    }
  };
}

export default PublicTransitMarkers;
