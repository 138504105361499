import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CopyButton, Tree } from '@here/ref-client-ui';
import './flexPolylineResult.scss';
import isFinite from 'lodash/isFinite';

function FlexPolylineResult({ result = {} }) {
  const { state: { polyline = [] } = {} } = result;
  const data = useMemo(() => {
    let hasElevation = true;
    const points = [];
    const elevationArr = [];
    polyline.forEach(({ lat, lng, elevation }) => {
      points.push([+lat, +lng]);
      if (!isFinite(+elevation)) {
        hasElevation = false;
      }
      elevationArr.push(+elevation);
    });
    return {
      points,
      elevation: hasElevation ? elevationArr : null,
    };
  }, [polyline]);

  return (
    <div className="rf-flex-polyline-result">
      <h4>
        Points{' '}
        <CopyButton value={JSON.stringify(data.points, null, 2)}>
          <div className="rf-input__btn-copy rf-api-url-copy-btn" />
        </CopyButton>
      </h4>
      <Tree data={data.points} shouldExpandNode={() => false} />
      {data.elevation && (
        <>
          <h4>
            Elevation{' '}
            <CopyButton value={JSON.stringify(data.elevation, null, 2)}>
              <div className="rf-input__btn-copy rf-api-url-copy-btn" />
            </CopyButton>
          </h4>
          <Tree data={data.elevation} shouldExpandNode={() => true} />
        </>
      )}
    </div>
  );
}

FlexPolylineResult.propTypes = {
  result: PropTypes.object,
};

export default FlexPolylineResult;
