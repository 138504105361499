import React, { useState } from 'react';
import { ExpandFormRow, TabsControl, Textarea } from '@here/ref-client-ui';

const TABS = {
  CUSTOM: 'custom',
  TEXT_AREA: 'textArea',
};

function PluginWithTextArea({
  value,
  onChange,
  children,
  label,
  className,
  tabName,
  isValid = true,
}) {
  const [selectedTab, setSelectedTab] = useState(TABS.CUSTOM);

  const tabsData = [
    { label: tabName || label, name: TABS.CUSTOM },
    { label: 'Text', name: TABS.TEXT_AREA },
  ];

  const onChangeTextArea = ({ target: { value } }) => {
    try {
      onChange(JSON.parse(value));
    } catch (err) {
      // Just ignore, the textarea will be cleaned
    }
  };

  let classes = 'rf-plugin-with-textarea';
  if (className) {
    classes += ` ${className}`;
  }

  return (
    <ExpandFormRow label={label} isExpanded className={classes}>
      <TabsControl
        tabsData={tabsData}
        currentTabName={selectedTab}
        onChange={setSelectedTab}
      />
      {selectedTab === TABS.CUSTOM ? (
        children
      ) : (
        <Textarea
          label={label}
          value={JSON.stringify(value)}
          onBlur={onChangeTextArea}
          isValid={isValid}
        />
      )}
    </ExpandFormRow>
  );
}

export default PluginWithTextArea;
