import React from 'react';
import PropTypes from 'prop-types';
import Marker from 'shared/hereMap/HereMarker';
import { isConditionsSatisfied } from '../../utils';
import { get, set } from 'lodash';
import { MarkerIcon } from '@here/ref-client-ui';
import { isCoordValidAndDefined } from '../../../utils/validation/coordsValidation';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';
import { withHereMap } from '../../../views/shared/hereMap/HereMapContext';

class HereMarker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.prevIconUrl = null;
  }

  onChange = (latLng) => {
    const { lat, lng } = latLng;
    const latLngStr = `${lat.toFixed(6)},${lng.toFixed(6)}`;
    this.setData(latLngStr);
  };

  onRemove(e) {
    e.stopPropagation();
    this.setData(null);
  }

  onExclude(e) {
    const {
      setFields,
      options: { 
        excludes = '',
        id
      },
    } = this.props;
    e.stopPropagation();
    const pos = excludes.indexOf(id);
    if ( pos === -1 ) {
      const data = {'ev[excludeChargingStations]': excludes === '' ? id : `${excludes},${id}`};
      setFields(data);
    }
    this.setState({ open: false });
  }

  setData(latLng) {
    const {
      setFields,
      options: { 
        coordsPath,
        mapAttributes,
        mapAttributesType,
        approachRouteType,
        approachRouteTypePath
      },
    } = this.props;
    if (mapAttributesType) {
      const coords = get(this.props, coordsPath).split(';');
      coords[mapAttributes] = latLng;
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), coords.join(';'));
      set(value, mapAttributesType.replace(/^fields\./, ''), 'routerOriginDest');
      setFields(value);
    } else if (approachRouteType) {
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), latLng);
      set(value, approachRouteTypePath.replace(/^fields\./, ''), 'waypoint');
      setFields(value);
    } else {
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), latLng);
      setFields(value);
    }
  }

  onContextMenu = (e) => {
    const { map, selectedTab, tabIndex } = this.props;
    if (selectedTab !== tabIndex) {
      return;
    }
    e.stopPropagation();
    const {
      originalEvent: { x, y },
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
      },
    });
    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  };

  close = () => {
    const { map } = this.props;
    this.setState({ open: false });
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };

  componentWillUnmount() {
    const { map } = this.props;

    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  }

  getIcon(iconUrl) {
    const { iconWidth, iconHeight, iconX, iconY } = this.props;
    if (!this.icon || iconUrl !== this.prevIconUrl) {
      this.icon = new window.H.map.Icon(iconUrl, {
        anchor: { x: iconX || 11.5, y: iconY || 28 },
        size: { w: iconWidth || 23, h: iconHeight || 28 },
      });
      this.prevIconUrl = iconUrl;
    }
    return this.icon;
  }

  render() {
    const {
      options,
      colorPalette,
      coords,
      iconSvg,
      selectedTab,
      tabIndex,
      paramsMapping = true,
    } = this.props;
    const {
      text,
      coordsPath,
      mapAttributes,
      mapAttributesType,
      fontSize,
      conditions = [],
      strokeColor = 'black',
      draggable = true,
      isCurrentRoute = true,
      removable = false,
      format,
      customEv,
    } = options;

    if (!isConditionsSatisfied(this.props, conditions)) {
      return null;
    }

    let latLng;
    if (coords) {
      latLng = coords;
    } else {
      let waypoint = get(this.props, coordsPath, "");
      if (mapAttributesType) {
        if(get(this.props, mapAttributesType) === 'routerOriginDest') {
          waypoint = waypoint.split(';')[mapAttributes];
        }
      }
      if (!isCoordValidAndDefined(waypoint)) {
        return null;
      }
      const [lat, lng] = waypoint.split(',').map(Number);
      latLng = { lat, lng };
    }

    const svg =
      iconSvg ||
      MarkerIcon({
        title: text,
        color: isCurrentRoute ? colorPalette.primary : colorPalette.secondary,
        strokeColor,
        fontSize,
      });
    const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    const icon = this.getIcon(iconUrl);

    const props = {};
    // ev markers but not custom ev markers
    if ((removable || (format === 'evMarkers' && !customEv)) && paramsMapping) {
      props.onContextMenu = this.onContextMenu;
    }

    return (
      <>
        <Marker
          key={`${selectedTab}${tabIndex}${paramsMapping}`}
          draggable={draggable && selectedTab === tabIndex && paramsMapping}
          latLng={latLng}
          options={{ icon }}
          onChange={this.onChange}
          {...props}
        />
        {this.state.open && (
          <div className="rf-context-menu" style={{ ...this.state.position }}>
            {removable && (
              <HereMapContextMenuItem
                label="Remove marker"
                onClick={this.onRemove.bind(this)}
              />
            )}
            {format === 'evMarkers' && (
              <HereMapContextMenuItem
                label="Exclude charging station"
                onClick={this.onExclude.bind(this)}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

HereMarker.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object.isRequired,
  colorPalette: PropTypes.object,
  setFields: PropTypes.func,
  coords: PropTypes.object,
  iconSvg: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  paramsMapping: PropTypes.bool,
};

export default withHereMap(HereMarker);
