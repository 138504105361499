import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { get } from 'lodash';
import { FormRow, Columns, Select } from '@here/ref-client-ui';

class IntermodalViaPlugin extends React.Component {
  onPlaceChange = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const { strategy } = fields[key];
    const place = utils.extractData(e);
    setFields({ [key]: { place, strategy } });
  };

  onStrategyChange = (e) => {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const { place } = fields[key];
    const strategy = utils.extractData(e);
    setFields({ [key]: { place, strategy } });
  };

  render() {
    const {
      options: { label, key, places, strategies },
      fields,
    } = this.props;
    const value = fields[key];
    return (
      <div className="rf-mode rf-grey-box" id="mode">
        <h3>{label}</h3>
        <Columns>
          <FormRow>
            <Select
              label="Place"
              options={places}
              value={value.place || ''}
              addEmptyOption
              onChange={this.onPlaceChange}
            />
          </FormRow>
          <FormRow>
            <Select
              label="Strategy"
              options={strategies}
              value={value.strategy || ''}
              addEmptyOption
              disabled={!value.place}
              notSelectedLabel="Not set"
              onChange={this.onStrategyChange}
            />
          </FormRow>
        </Columns>
      </div>
    );
  }
}

IntermodalViaPlugin.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

function asObject(viaStr) {
  if (!viaStr) return { place: '', strategy: '' };
  const place = viaStr.split(';')[0].split(':')[1];
  const strategyStr = viaStr.split(';')[1];
  const strategy = strategyStr ? strategyStr.split('=')[1] : '';
  return { place, strategy };
}

function asString(via) {
  if (!get(via, 'place')) return '';
  const placeStr = `place:${via.place}`;
  const strategyStr = via.strategy ? `;strategy=${via.strategy}` : '';
  return `${placeStr}${strategyStr}`;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options: { key }, parsedParams }) => {
    const keyLowerCase = key.toLowerCase();
    const viaStr = parsedParams[keyLowerCase];
    const res = asObject(viaStr);
    delete parsedParams[keyLowerCase];
    return { via: res };
  },
  defaultState: (options) => {
    const viaStr = options.initValue || '';
    return { via: asObject(viaStr) };
  },
  getRequestOptions: (fields, options) => {
    const via = fields[options.key];
    const viaStr = asString(via);
    const params = viaStr ? { [options.key]: viaStr } : {};
    return { params };
  },
  Component: IntermodalViaPlugin,
};
