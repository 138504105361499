// This functionality is copied from https://github.com/bbecquet/Leaflet.PolylineOffset
// With modifications:
//   * es5 -> es6
//   * removed leaflet dependency
//   * Fixed intersection and offsetPointLine methods
const polylineOffset = (latLngs, offset) => {
  const offsetSegments = offsetPointLine(latLngs, offset);
  return offsetSegments.length > 0
    ? joinLineSegments(offsetSegments, offset)
    : latLngs;
};
export default polylineOffset;

function translatePoint(pt, dist, radians) {
  return {
    lat: pt.lat + dist * Math.cos(radians),
    lng: pt.lng + dist * Math.sin(radians),
  };
}

function offsetPointLine(points, distance) {
  let l = points.length;
  if (l < 2) {
    throw new Error('Line should be defined by at least 2 points');
  }

  let a = points[0];
  let b;
  let xs;
  let ys;
  let dist;
  let offsetAngle;
  let segmentAngle;
  let offsetSegments = [];

  for (let i = 1; i < l; i++) {
    b = points[i];
    xs = b.lat - a.lat;
    ys = b.lng - a.lng;
    dist = Math.sqrt(xs * xs + ys * ys);
    // angle in (-PI, PI]
    segmentAngle = Math.atan2(a.lng - b.lng, a.lat - b.lat);
    // angle in (-1.5 * PI, PI/2]
    offsetAngle = segmentAngle - Math.PI / 2;

    if (dist > distance) {
      // store offset point and other information to avoid recomputing it later
      offsetSegments.push({
        angle: segmentAngle,
        offsetAngle,
        distance,
        original: [a, b],
        offset: [
          translatePoint(a, distance, offsetAngle),
          translatePoint(b, distance, offsetAngle),
        ],
      });
      a = b;
    }
  }

  return offsetSegments;
}

/**
 Return the intersection point of two lines defined by two points each
 Return null when there's no unique intersection
 */
function intersection(p0, p1, p2, p3) {
  let s10X = p1.lat - p0.lat;
  let s10Y = p1.lng - p0.lng;
  let s32X = p3.lat - p2.lat;
  let s32Y = p3.lng - p2.lng;

  let denom = s10X * s32Y - s32X * s10Y;
  if (denom === 0) {
    return null;
  }
  let denomPositive = denom > 0;

  let s02X = p0.lat - p2.lat;
  let s02Y = p0.lng - p2.lng;

  let sNumer = s10X * s02Y - s10Y * s02X;
  let check = sNumer < 0;
  if (check === denomPositive) {
    return null;
  }

  let tNumer = s32X * s02Y - s32Y * s02X;

  check = tNumer < 0;
  if (check === denomPositive) {
    return null;
  }

  check = sNumer > denom;
  const check2 = tNumer > denom;
  if (check === denomPositive || check2 === denomPositive) {
    return null;
  }

  let t = tNumer / denom;
  return { lat: p0.lat + t * s10X, lng: p0.lng + t * s10Y };
}

/**
 Join 2 line segments defined by 2 points each
 */
function joinSegments(s1, s2, offset) {
  return circularArc(s1, s2, offset).filter(Boolean);
}

function joinLineSegments(segments, offset) {
  let l = segments.length;
  let joinedPoints = [];
  let s1 = segments[0];
  let s2 = segments[0];
  joinedPoints.push(s1.offset[0]);

  if (s1 && s2) {
    joinedPoints.push(s1.offset[0]);

    for (let i = 1; i < l; i++) {
      s2 = segments[i];
      joinedPoints = joinedPoints.concat(joinSegments(s1, s2, offset));
      s1 = s2;
    }
    joinedPoints.push(s2.offset[1]);
  }

  return joinedPoints;
}

/**
 Interpolates points between two offset segments in a circular form
 */
function circularArc(s1, s2, distance) {
  if (s1.angle === s2.angle) {
    return [s1.offset[1]];
  }

  let center = s1.original[1];
  let points = [];

  let startAngle;
  let endAngle;
  if (distance < 0) {
    startAngle = s1.offsetAngle;
    endAngle = s2.offsetAngle;
  } else {
    // switch start and end angle when going right
    startAngle = s2.offsetAngle;
    endAngle = s1.offsetAngle;
  }

  if (endAngle < startAngle) {
    endAngle += Math.PI * 2; // the end angle should be bigger than the start angle
  }

  if (endAngle > startAngle + Math.PI) {
    return [
      intersection(s1.offset[0], s1.offset[1], s2.offset[0], s2.offset[1]),
    ];
  }

  // Step is distance dependent. Bigger distance results in more steps to take
  let step = Math.abs(8 / distance);
  for (let a = startAngle; a < endAngle; a += step) {
    points.push(translatePoint(center, distance, a));
  }
  points.push(translatePoint(center, distance, endAngle));

  if (distance > 0) {
    // reverse all points again when going right
    points.reverse();
  }

  return points;
}
