import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import Routes from './Route';
import HereRoute from '../routes/HereRoute';
import LeafletRoute from './LeafletRoute';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [TILE_LIBRARY_TYPES.LEAFLET]: Routes(LeafletRoute),
  [TILE_LIBRARY_TYPES.JSLA]: Routes(HereRoute),
};
