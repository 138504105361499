class MapPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported map plugin type: '${name}'.
          Please register with map plugin 
          import mapPlugin from 'core/mapPlugin'
          
          mapPlugin.register('${name}', {
            Component: 'Component that represents map element'
          })`
    );
  }
}

const mapPlugin = new MapPlugin();
Object.freeze(mapPlugin);

export default mapPlugin;
