import React from 'react';
import { FormRow, Button, Input, Select } from '@here/ref-client-ui';
import PropTypes from 'prop-types';
import axios from 'axios';
import isHttpWithHttps, {
  isHttpWithHttpsNotification,
} from './isHttpWithHttps';
import OAuth from 'oauth-1.0a';
import crypto from 'crypto';


const getLocalStorageKey = (appId) => `hereTokenSettings.v1.${appId}`;
const AUTH_ENDPOINTS = [
  'https://account.api.here.com/oauth2/token',
  'https://stg.account.api.here.com/oauth2/token',
];

class HereTokenSettings extends React.Component {
  state = {
    isTokenLoading: false,
  };

  componentDidMount() {
    const { onChange, consumerKey } = this.props;
    const creds = getCredsFromLocalStorage(consumerKey);
    if (creds) {
      onChange(creds);
    }
  }

  onChange(key, { target: { value } }) {
    if (isHttpWithHttps(value)) {
      const { setNotification } = this.props;
      isHttpWithHttpsNotification(setNotification);
    }
    this.props.onChange({ [key]: value });
  }

  getToken = async () => {
    const { onChange } = this.props;
    this.setState({ isTokenLoading: true });
    try {
      const updatedSettings = await preFetch(this.props, true);
      if (Object.keys(updatedSettings).length > 0) {
        onChange(updatedSettings);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isTokenLoading: false });
    }
  };

  render() {
    const {
      url,
      consumerKey,
      consumerSecret,
      consumerScope,
      token,
      tokenExpiry,
      isCustom,
      authEndpoint,
      projectScope,
      idToken,
    } = this.props;
    const { isTokenLoading } = this.state;
    const tokenExpiryDate = tokenExpiry
      ? new Date(+tokenExpiry).toISOString()
      : '';

    return (
      <div>
        <FormRow>
          <Input
            type="text"
            label="Url"
            value={url}
            isCompact
            onBlur={this.onChange.bind(this, 'url')}
            blurOnEnter
            isReadonly={!isCustom}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Auth endpoint"
            options={AUTH_ENDPOINTS}
            value={authEndpoint||''}
            onChange={this.onChange.bind(this, 'authEndpoint')}
            isReadonly={!isCustom}
            addEmptyOption
          />
        </FormRow>
        <FormRow className="rf-token-form">
          <Input
            type="text"
            label="Client Key"
            value={consumerKey}
            isCompact
            onBlur={this.onChange.bind(this, 'consumerKey')}
            blurOnEnter
            isReadonly={!isCustom}
          />
          <Input
            type="text"
            label="Client Secret"
            value={consumerSecret}
            isCompact
            onBlur={this.onChange.bind(this, 'consumerSecret')}
            blurOnEnter
            isReadonly={!isCustom}
          />
          <Input
            type="text"
            label="Scope"
            value={consumerScope}
            isCompact
            onBlur={this.onChange.bind(this, 'consumerScope')}
            blurOnEnter
            isReadonly={!isCustom}
          />
          <div className="rf-token-control">
            <Input
              type="text"
              label="HERE Token"
              value={token}
              isCompact
              isReadonly
            />
            <Button
              title={isTokenLoading ? '\u231B' : '\u27F3'}
              disabled={isTokenLoading}
              onClick={this.getToken}
            />
          </div>
          <Input
            type="text"
            label="Expiry Date"
            value={tokenExpiryDate}
            isCompact
            isReadonly
          />
          <Input
            type="text"
            label={idToken ? "ID Token" : "Project Scope"}
            value={projectScope ? projectScope : (idToken ? idToken : "")}
            isCompact
            isReadonly
          />
        </FormRow>
      </div>
    );
  }
}

HereTokenSettings.propTypes = {
  isCustom: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string,
  consumerKey: PropTypes.string,
  consumerSecret: PropTypes.string,
  consumerScope: PropTypes.string,
  token: PropTypes.string,
  tokenExpiry: PropTypes.number,
  authEndpoint: PropTypes.string,
  setNotification: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: () => ({}),
  getDisplayUrl,
  preFetch,
  getRequestOptions: (settings, fieldParams) => {
    return {
      url: getDisplayUrl(settings, fieldParams).split('?')[0],
      headers: {
        Authorization: `Bearer ${settings.token}`,
      },
    };
  },
  Component: HereTokenSettings,
};

function getDisplayUrl(settings, fieldRequestOptions) {
  const { pathParams = {} } = fieldRequestOptions;
  const url = Object.keys(pathParams).reduce(
    (acc, paramKey) =>
      acc.replace(new RegExp(`{${paramKey}}`, 'g'), pathParams[paramKey]),
    settings.url
  );
  return decodeURIComponent(
    axios.getUri({ url: url || '', ...fieldRequestOptions })
  );
}

async function preFetch(settings, getRemote) {
  const { consumerKey, consumerSecret, consumerScope, token, tokenExpiry, authEndpoint } =
    settings;
  const currTime = new Date().getTime();
  if (!getRemote && token && +tokenExpiry > currTime) {
    return {};
  }

  const creds = getCredsFromLocalStorage(consumerKey);
  if (!getRemote && creds && creds.token && +creds.tokenExpiry > currTime) {
    return creds;
  }

  const body = {
    grant_type: "client_credentials",
    ...(consumerScope? {scope: consumerScope} : {})
  }
  const oauth = OAuth({
    consumer: {
      key: consumerKey, //Access key
      secret: consumerSecret, //Secret key
    },
    signature_method: 'HMAC-SHA256',
    hash_function(base_string, key) {
      return crypto
        .createHmac('sha256', key)
        .update(base_string)
        .digest('base64')
    },
  });
  
  const request_data = {
    url: authEndpoint,
    method: 'POST',
    data: body,
  };

  const res = await axios({
    url: request_data.url,
    method: request_data.method,
    headers: {
      'Authorization': oauth.toHeader(oauth.authorize(request_data)).Authorization,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: `grant_type=client_credentials${consumerScope?('&scope='+consumerScope):''}`,
  });

  const accessToken = res.data.access_token || res.data.accessToken;
  const scope = res.data.scope || '';
  const id_token = res.data.id_token || '';
  const [data] = accessToken.split('.');
  const parsedData = JSON.parse(atob(data));

  const tokenRes = {
    token: accessToken,
    tokenExpiry: parsedData.exp * 1000,
    projectScope: scope || '',
    idToken: id_token || ''
  };

  localStorage.setItem(
    getLocalStorageKey(consumerKey),
    JSON.stringify(tokenRes)
  );

  return tokenRes;
}

function getCredsFromLocalStorage(consumerKey) {
  const credsStr = localStorage.getItem(getLocalStorageKey(consumerKey));
  if (credsStr) {
    try {
      const creds = JSON.parse(credsStr);
      if (creds.token && creds.tokenExpiry) {
        return {
          token: creds.token,
          tokenExpiry: creds.tokenExpiry,
        };
      }
    } catch (e) {
      // ignore exception
    }
  }

  return null;
}
