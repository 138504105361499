import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import MapFormToggler from './MapFormToggler';
import { debounce } from 'lodash';

export default class TrafficOverlaysForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render() {
    let { data = {}, onChange } = this.props;
    let { url, path, refreshInterval, apiKey } = data;
    return (
      <div className="rf-map__tooltip rf-map__to-module" ref={this.formRef}>
        <h2>Traffic Overlays</h2>
        <MapFormToggler
          getFormRef={() => this.formRef.current}
          {...this.props}
        />
        <FormRow>
          <Input
            label="Host"
            value={url}
            onBlur={onChange.bind(this, 'url')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="Path"
            value={path}
            onBlur={onChange.bind(this, 'path')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            label="Api Key"
            value={apiKey}
            onBlur={onChange.bind(this, 'apiKey')}
            blurOnEnter
          />
        </FormRow>
        <FormRow>
          <Input
            type="number"
            min="0"
            label="Refresh Interval (seconds)"
            value={refreshInterval}
            onChange={debounce(onChange, 300).bind(this, 'refreshInterval')}
            blurOnEnter
          />
        </FormRow>
      </div>
    );
  }
}

TrafficOverlaysForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};
