import React from 'react';
import { Button, FormRow } from '@here/ref-client-ui';
import './distanceMeasurement.scss';

class DistanceMeasurement extends React.Component {

  startMeasurement = () => {
    const {  
      setFields
    } = this.props;

    const ruler = [];
    setFields({ruler});
  }

  clearMeasurement = () => {
    const {  
      setFields,
    } = this.props;

    const ruler = null;
    setFields({ruler});
  }

  render() {
    const {  
      fields: { ruler }
    } = this.props;
    
    return (
      <>
        <FormRow className="rf-distance-measurement-form-view">
          { !ruler ? 
            (<Button className="distance-measurement-off" title="Measure Distance" onClick={this.startMeasurement} />) :
            (<Button className="distance-measurement-on" title="Clear Measurement" onClick={this.clearMeasurement} />)
          }
        </FormRow>
      </>
    );
  }
}

const DistanceMeasurementFormViewPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: DistanceMeasurement,
};

export default DistanceMeasurementFormViewPlugin;