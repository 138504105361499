function callback(e, fields, setFields) {
  const { lat, lng } = e.latLng;
  const { trace: traceStr, via } = fields;
  const trace = JSON.parse(traceStr);
  setFields({
    trace: JSON.stringify([
      ...trace,
      { lat: +lat.toFixed(6), lng: +lng.toFixed(6) },
    ]),
    via: [...via, { index: `${trace.length}`, stopDuration: '' }],
  });
}

const addViaPlugin = ({ setFields, fields }) => {
  return {
    text: 'Add Via',
    callback: (e) => callback(e, fields, setFields),
  };
};

export default addViaPlugin;
