import { SET_SETTINGS_VALUE } from '..';
import {
  SET_CONFIG,
  DUPLICATE_CONFIG,
  ADD_CUSTOM_CONFIG,
} from '../../config/custom/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case DUPLICATE_CONFIG:
    case ADD_CUSTOM_CONFIG:
      return {
        ...state,
        [action.payload.title]: action.payload.settings,
      };
    case SET_CONFIG: {
      const { title, config } = action.payload;
      return {
        ...state,
        [title]: { ...config.settings },
      };
    }
    case SET_SETTINGS_VALUE: {
      const { title, preset, data } = action.payload;
      const formSettings = state[title] || {};
      const presetSettings = formSettings[preset];
      if (presetSettings) {
        return {
          ...state,
          [title]: {
            ...formSettings,
            [preset]: {
              ...presetSettings,
              ...data,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};
