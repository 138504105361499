class SettingsPlugin {
  components = new Map();

  register(name, options) {
    this.components.set(name, options);
  }

  get(name) {
    const res = this.components.get(name);
    if (res) {
      return res;
    }

    throw new Error(
      `Unsupported settings type: '${name}'.
          Please register with settings plugin 
          import settingsUtils from 'views/settingsPanel/settings'
          
          settingsUtils.register('${name}', {
            stringify: 'function that stringifies output params to url',
            parse: 'function that parses url string into settings',
            Component: 'Component that represents settings block'
          })`
    );
  }
}

const settingsPlugin = new SettingsPlugin();
Object.freeze(settingsPlugin);

export default settingsPlugin;
