import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormRow } from '@here/ref-client-ui';

class CheckboxFormView extends React.Component {
  onChange({ target: { checked } }) {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    if (checked !== fields[key]) {
      setFields({ [key]: checked });
    }
  }

  render() {
    const {
      options: { key, label },
      fields,
    } = this.props;

    return (
      <FormRow>
        <Checkbox
          label={label}
          isChecked={fields[key]}
          onChange={this.onChange.bind(this)}
        />
      </FormRow>
    );
  }
}

CheckboxFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  refClient: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options: { initValue, key } }) => ({
    [key]: initValue || false,
  }),
  defaultState: (options) => {
    const { initValue, key } = options;
    return { [key]: initValue || false };
  },
  getRequestOptions: () => ({}),
  Component: CheckboxFormView,
};
