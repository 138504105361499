import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@here/ref-client-ui';

import './styles/routeTimezone.scss';
import { ROUTE_TIMEZONE } from '../../state/appSettings/constants';

const OPTIONS = [
  { label: 'Browser', value: ROUTE_TIMEZONE.CLIENT_LOCAL },
  { label: 'Origin', value: ROUTE_TIMEZONE.DEPARTURE },
  { label: 'Destination', value: ROUTE_TIMEZONE.ARRIVAL },
  { label: 'Per Maneuver', value: ROUTE_TIMEZONE.LOCAL },
];

class RouteTimezone extends Component {
  onChange = (evt) => {
    this.props.setSettings({ routeTimezone: evt.target.value });
  };

  render() {
    return (
      <div className="rf-app-timezone">
        <span className="rf-app-timezone__caption">Results Timezone:</span>
        <Select
          options={OPTIONS}
          value={this.props.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

RouteTimezone.propTypes = {
  value: PropTypes.string.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default RouteTimezone;
