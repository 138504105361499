import React from 'react';
import PropTypes from 'prop-types';
import { isCoordValid } from 'utils/validation/coordsValidation';
import { get } from 'lodash';
import geohash from 'latlon-geohash';
import * as icons from '../markers/icons';

function MatrixMarkers(MarkerComponent) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      result: PropTypes.object,
      colorPalette: PropTypes.object,
      bounds: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      selectedTab: PropTypes.number.isRequired,
      setFields: PropTypes.func.isRequired,
    };

    onChangeMarker = (index, key, { coord: { value } }) => {
      const { fields, setFields } = this.props;
      const pointsArr = JSON.parse(fields[key] || '[]');
      if (value === null) {
        setFields({
          [key]: JSON.stringify([
            ...pointsArr.slice(0, index),
            ...pointsArr.slice(index + 1),
          ]),
        });
        return;
      }
      const [lat, lng] = value.split(',').map(Number);
      const changedPoint = { lat, lng };

      setFields({
        [key]: JSON.stringify([
          ...pointsArr.slice(0, index),
          changedPoint,
          ...pointsArr.slice(index + 1),
        ]),
      });
    };

    render() {
      const {
        options,
        colorPalette,
        result,
        tabIndex,
        selectedTab,
        bounds: { zoom, top, right, bottom, left },
        result: {
          state: { selectedRoute },
        },
      } = this.props;

      const { origins_path, destinations_path, removable } = options;

      const origins = get(this.props, origins_path, '');
      const destinations = get(this.props, destinations_path, '');
      const originsArr = JSON.parse(origins || '[]');
      const destinationsArr = JSON.parse(destinations || '[]');

      const originPoints = [];
      const destinationPoints = [];
      if (zoom < 11 && originsArr.length + destinationsArr.length > 100) {
        // Clustering origin and destination points together
        const geohashes = {};
        const cluster =
          (type) =>
          ({ lat, lng }, index) => {
            const precision = zoom > 8 ? zoom - 5 : 4;
            const hash = geohash.encode(lat, lng, precision);
            if (!geohashes[hash]) {
              geohashes[hash] = { count: 0, lat, lng, index, type };
            }
            geohashes[hash].count++;
          };
        originsArr.forEach(cluster('origin'));
        destinationsArr.forEach(cluster('destination'));
        Object.keys(geohashes).forEach((hash) => {
          const { lat, lng, count, index, type } = geohashes[hash];
          let prefix = 'D';
          if (type === 'origin') {
            prefix = destinationsArr.length > 0 ? 'O' : 'O/D';
          }

          if (lat < top && lat > bottom && lng > left && lng < right) {
            if (count === 1) {
              originPoints.push({
                value: `${lat},${lng}`,
                text: `${prefix}${index + 1}`,
                index,
                fontSize: 8,
                draggable: true,
                removable,
              });
            } else {
              originPoints.push({
                value: `${lat},${lng}`,
                icon: icons.cluster(count, colorPalette.primary),
                draggable: false,
                removable: false,
              });
            }
          }
        });
        if (selectedRoute) {
          const [selectedOrigin, selectedDest] = selectedRoute.split(' -> ');
          const originIndex = +selectedOrigin.split(' ')[1] - 1;
          const destIndex = +selectedDest.split(' ')[1] - 1;
          const origin = originsArr[originIndex];
          const dest = destinationsArr[destIndex]
            ? destinationsArr[destIndex]
            : originsArr[originIndex];
          originPoints.push([
            {
              value: `${origin.lat},${origin.lng}`,
              text: selectedOrigin,
              fontSize: 8,
              draggable: true,
              removable,
              index: originIndex,
            },
            {
              value: `${dest.lat},${dest.lng}`,
              text: selectedDest,
              fontSize: 8,
              draggable: true,
              removable,
              index: destIndex,
            },
          ]);
        }
      } else {
        const originPrefix = destinationsArr.length > 0 ? 'O' : 'O/D';
        originsArr.forEach(({ lat, lng }, index) => {
          // Display markers in bbox
          if (lat < top && lat > bottom && lng > left && lng < right) {
            originPoints.push({
              value: `${lat},${lng}`,
              text: `${originPrefix}${index + 1}`,
              fontSize: 8,
              draggable: true,
              removable,
              index,
            });
          }
        });
        destinationsArr.forEach(({ lat, lng }, index) => {
          // Display markers in bbox
          if (lat < top && lat > bottom && lng > left && lng < right) {
            destinationPoints.push({
              value: `${lat},${lng}`,
              text: `D${index + 1}`,
              fontSize: 8,
              draggable: true,
              removable,
              index,
            });
          }
        });
      }

      const mapper = (key) => (coord) => {
        if (isCoordValid(coord.value)) {
          return (
            <MarkerComponent
              key={`${coord.text}${coord.value}`}
              options={{
                text: coord.text,
                coordsPath: 'fields.coord.value',
                fontSize: coord.fontSize,
                removable: coord.removable,
                draggable: coord.draggable,
              }}
              iconSvg={coord.icon}
              iconWidth={coord.icon ? 40 : null}
              iconHeight={coord.icon ? 40 : null}
              fields={{ coord }}
              result={result}
              colorPalette={colorPalette}
              tabIndex={tabIndex}
              selectedTab={selectedTab}
              setFields={this.onChangeMarker.bind(this, coord.index, key)}
            />
          );
        }

        return null;
      };

      return [
        ...originPoints.map(mapper('origins')),
        ...destinationPoints.map(mapper('destinations')),
      ];
    }
  };
}

export default MatrixMarkers;
