import React from 'react';
import PropTypes from 'prop-types';
import { CopyButton, Button, ExpandFormRow, Tree } from '@here/ref-client-ui';

export default function TopologyGeometry({ layerId, data }) {
  return (
    <ExpandFormRow label={layerId} isExpanded>
      <Tree data={data} />
      <CopyButton value={JSON.stringify(data, null, 2)}>
        <Button title="Copy" />
      </CopyButton>
    </ExpandFormRow>
  );
}

TopologyGeometry.propTypes = {
  layerId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
