import { assignTabDataAndDispatch } from '../../../actions';
import { SET_RESULT } from './constants';
import { SET_IS_LOADING } from '../../isLoading/constants';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = null, action) => {
  switch (action.type) {
    case SET_RESULT:
      return action.payload;
    case SET_IS_LOADING:
      return null;
    default:
      return state;
  }
};

export const setRawResult = (result, tabIndex) =>
  assignTabDataAndDispatch({ type: SET_RESULT, payload: result }, tabIndex);

export const getResult = (state, tabIndex) => {
  const selectedTab = tabIndex != null ? tabIndex : state.selectedTab;
  return state.tabs[selectedTab].result;
};
